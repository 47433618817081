import React from 'react';
import './stepsIndicator.scss';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useCurrentStep, usePreviousAndNextSteps } from './../../../libraries/hooks';
import { useSelector } from 'react-redux';
import OptionalLink from '../../../components/OptionalLink/OptionalLink';
import { selectOrderSteps, selectOrderTotalProducts, selectOrderTotalQuantityBreakdown } from './../../selectors';
import { t } from './../../../libraries/i18n';

function StepsIndicator(props) {
	const steps = useSelector(selectOrderSteps);
	const currentStep = useCurrentStep();
	const [previousStep, nextStep] = usePreviousAndNextSteps();
	const totalProductsInOrder = useSelector(selectOrderTotalProducts);
	const totalUnitsInOrder = useSelector(selectOrderTotalQuantityBreakdown).total;

	const getStep3Subtext = () => {
		return totalProductsInOrder > 0 ? t('order.productSelectionStepSubtext', totalUnitsInOrder, totalProductsInOrder) : '';
	};

	const renderStep = step => {
		let className = `progress__step progress__step--li progress__step-${step.id}`;
		className += currentStep?.route === step.route ? ' progress__step--active' : '';
		className += currentStep?.id > step.id ? ' progress__step--done' : '';

		// I know this shouldn't be here but I didn't want to put
		// the selectOrderSteps in selectors.js (it would depend on
		// selectOrderTotalQuantityBreakdown, which is in selectors.js).
		if (step.id === 2) step.subtext = getStep3Subtext();

		return (
			<OptionalLink key={step.route} className={className} to={step.canEnter && step.route}>
				<span className={'progress__step--name'}>{t(step.translation)}</span>
				<span className={'progress__step--subtext'}>{step.subtext || ''}</span>
			</OptionalLink>
		);
	};

	return (
		<div className={`StepsProgress ${props.size ? props.size : ''}`}>
			<div className="order__progress">
				<OptionalLink to={previousStep?.canEnter && previousStep?.route} className={'progress__step--li prev'} aria-label={'go to previous step'}>
					<FaArrowLeft />
				</OptionalLink>

				{steps.map(renderStep)}

				<OptionalLink to={nextStep?.canEnter && nextStep?.route} className={'progress__step--li next'}>
					<FaArrowRight />
				</OptionalLink>
			</div>
		</div>
	);
}

export default StepsIndicator;
