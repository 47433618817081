import React from 'react';
import './Orders.scss';
import {
	Switch,
	NavLink,
	useRouteMatch,
	Route,
	Redirect
} from 'react-router-dom';
import OrderedProducts from './OrderedProducts';
import OrderHistory from './OrderHistory';
import { CharlesTitle } from '@abm-international/react-components';
import { t } from '../../libraries/i18n';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';

export default function Orders() {
	useCustomerRoute();
	const match = useRouteMatch();

	const TABS = [
		{
			Component: OrderedProducts,
			title: t('orders.title_ordered_products'),
			location: `${match.path}/products`
		},
		{
			Component: OrderHistory,
			title: t('orders.title_order_history'),
			location: `${match.path}/history`
		}
	];

	return (
		<div className='Orders'>
			<CharlesTitle>{t('orders.title')}</CharlesTitle>

			<ul className='tabs'>
				{TABS.map(tab => (
					<li key={tab.location}>
						<NavLink
							className='item__link'
							activeClassName='item__link--active'
							to={tab.location}
						>
							{tab.title}
						</NavLink>
					</li>
				))}
			</ul>

			<Switch>
				<Route exact path={`${match.path}`} render={() => <Redirect to={TABS[0].location} />} />
				{TABS.map(tab => (
					<Route key={tab.location} path={tab.location}>
						<tab.Component />
					</Route>
				))}
			</Switch>
		</div>
	);
}
