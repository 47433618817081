import React, { useCallback, useEffect, useState } from 'react';
import './buylist.scss';
import { addProductToBuylist, removeProductFromBuylist } from '../../libraries/api';
import useWidth from '../../libraries/hooks/useWidth';
import ProductsView from '../../components/productsViews/productsView';
import ProductsFilters from '../../components/ProductsFilters/ProductsFilters';
import Modal from '../../components/modal/modal';
import ProductDetail from '../../components/productsViews/productDetail/productDetail';
import Spinner from '../../components/spinner/spinner';
import { t } from './../../libraries/i18n';
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectBuylistFilters,
	selectFilteredBuylistProducts,
	selectIsProductsFetching,
	selectBuylistView,
	selectShopcodes,
	selectBuylistQuery,
	selectActiveBuylistSortKey,
	selectBuylistSortDirection,
	selectBuylistDefaultFilters,
	selectIsCustomerProductsFetching,
	selectIsPromosFetching,
	selectIsMetadataFetching,
	selectCustomerId,
	selectIsProductHistoriesFetching
} from '../selectors';
import * as actions from './buylistActions';
import ProductHelper from '../../libraries/productHelper';
import * as Api from '../../libraries/api';
import { Button } from '@abm-international/react-components';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import {
	fetchCustomerProductsIfNeeded, fetchMetadataIfNeeded, fetchProductsIfNeeded, fetchPromosIfNeeded, fetchHistoryIfNeeded,
} from '../products/productsActions';

export default function Buylist() {
	useCustomerRoute();
	const dispatch = useDispatch();
	const toggleFilter = (...args) => dispatch(actions.toggleFilter(...args));
	const setFilter = (...args) => dispatch(actions.setFilter(...args));
	const clearFilters = (...args) => dispatch(actions.clearFilters(...args));
	const changeSort = (...args) => dispatch(actions.changeSorting(...args));
	const changeView = (...args) => dispatch(actions.changeProductView(...args));
	const handleSearch = useCallback(query => dispatch(actions.handleSearch(query)), [dispatch]);

	const [productModal, setProductModal] = useState(false);
	const [manageModal, setManageModal] = useState(undefined);
	const [allergensExcelUrl, setAllergensExcelUrl] = useState(null);
	//THIS IS NOT A GOOD SOLUTION, BUT IT WORKS FOR NOW... Customers call moet de juiste geven..
	function isNotUndefined(product) {
		return product.brand!==undefined;
	}
	const productsWithUndefineds = useSelector(selectFilteredBuylistProducts);
	const products = productsWithUndefineds.filter(isNotUndefined);

	const filters = useSelector(selectBuylistFilters);
	const isProductsFetching = useSelector(selectIsProductsFetching);
	const isCustomerProductsFetching = useSelector(selectIsCustomerProductsFetching);
	const isPromosFetching = useSelector(selectIsPromosFetching);
	const isMetadataFetching = useSelector(selectIsMetadataFetching);
	const view = useSelector(selectBuylistView);
	const shopcodes = useSelector(selectShopcodes);
	const query = useSelector(selectBuylistQuery);
	const sortKey = useSelector(selectActiveBuylistSortKey);
	const sortDirection = useSelector(selectBuylistSortDirection);
	const defaultFilters = useSelector(selectBuylistDefaultFilters);
	const customerId = useSelector(selectCustomerId);
	const isHistoryFetching = useSelector(selectIsProductHistoriesFetching);

	const [productsListRef, width] = useWidth();

	const forceGridView = width < 550;

	useEffect(() => {
		if (allergensExcelUrl || !customerId) return;
		Api.document('CAD-001', customerId, 'xlsx', {}, 'allergenen').then(({ url, error }) => {
			if (!url) return console.error(error);
			setAllergensExcelUrl(url);
		});
	}, [allergensExcelUrl, customerId]);

	// Fetch data for products list
	useEffect(() => {
		dispatch(fetchMetadataIfNeeded(customerId));
		dispatch(fetchPromosIfNeeded(customerId));
		dispatch(fetchProductsIfNeeded(customerId));
		dispatch(fetchCustomerProductsIfNeeded(customerId));
		dispatch(fetchHistoryIfNeeded(customerId));
	}, [customerId, dispatch]);

	const isFetching = () =>
		isCustomerProductsFetching ||
		isPromosFetching ||
		isMetadataFetching||isHistoryFetching;

	const closeManageModal = () => setManageModal(undefined);

	const confirmBuylistAction = async (e, action, productId) => {
		setManageModal(manageModal => ({ ...manageModal, loading: true }));
		const getAction = (action, productId, customerId) => {
			switch (action) {
				case 'remove': return () => removeProductFromBuylist(customerId, productId);
				case 'add': return () => addProductToBuylist(customerId, productId);
				default: return null;
			}
		};

		try {
			const result = await getAction(action, productId, customerId)();
			setManageModal({ success: result?.success });
		} catch (error) {
			setManageModal({ success: false, error });
		}
	};

	const renderManageModal = () => {

		const getModalData = (type, productId, loading) => {
			switch (type) {
				case 'add': return {
					text: 'buylist.label_buylist_add_confirm_text',
					actions: [
						{
							text: 'buylist.button_buylist_add_cancel',
							onClick: closeManageModal,
							disabled: loading,
						},
						{
							text: 'buylist.button_buylist_add_confirm',
							onClick: e => confirmBuylistAction(e, 'add', productId),
							disabled: loading,
							color: 'green'
						}

					]
				};
				case 'remove': return {
					text: 'buylist.label_buylist_remove_confirm_text',
					actions: [
						{
							text: 'buylist.button_buylist_remove_cancel',
							onClick: closeManageModal,
							disabled: loading,
						},
						{
							text: 'buylist.button_buylist_remove_confirm',
							onClick: e => confirmBuylistAction(e, 'remove', productId),
							disabled: loading,
							color: 'red'
						}
					]
				};
				case 'success': return {
					text: 'buylist.removeFromBuylistSuccessText',
					actions: [{
						text: 'buylist.button_buylist_action_ok',
						onClick: closeManageModal,
						primary: true
					}]
				};
				case 'fail': return {
					text: 'buylist.label_buylist_action_failed',
					actions: [{
						text: 'buylist.button_buylist_action_ok',
						onClick: closeManageModal,
						primary: true
					}]
				};
				default: return {};
			}
		};

		const type = manageModal.action ?? (manageModal.success ? 'success' : 'fail');
		const { text, actions } = getModalData(type, manageModal.productId, manageModal.loading);
		return (
			<div className={`buylist-action__modal buylist-action__modal--${type}`}>
				<div className={'modal__text'}>
					{t(text)}
				</div>
				<div className={'modal__options'}>
					{actions?.map(({ text, ...args }, index) => <Button key={index} {...args}>{t(text)}</Button>)}
				</div>
			</div>
		);
	};

	const renderModal = () => {
		if (productModal) {
			const product = products.find(p => p.id === productModal);

			return (
				<Modal show={true} close={() => setProductModal(false)}>
					<ProductDetail product={product} />
				</Modal>
			);
		} else if (manageModal) {
			return (
				<Modal show={true} close={closeManageModal}>
					{renderManageModal(manageModal)}
				</Modal>
			);
		}
	};

	const filteredShopcodes = ProductHelper.getParentShopcodes(shopcodes, products.map(p => p.shopcode));

	const removeFromBuylist = useCallback((productId) => {
		setManageModal({ productId, action: 'remove' });
	}, []);
	const addToBuylist = useCallback((productId) => {
		setManageModal({ productId, action: 'add' });
	}, []);

	return (
		<div className={'Buylist'} id={'scrollElement'}>
			<div className='header'>
				<h2>{t('buylist.title')}</h2>

				<div className={'download__buttons'}>
					<Button
						href={`${process.env.REACT_APP_PORTAL_URL}/index.php/bestel/kooplijst/download`}
						target={'__blank'}
						icon={<FaCloudDownloadAlt />}
					>
						Invullijst
					</Button>

					<Button
						href={allergensExcelUrl}
						disabled={!allergensExcelUrl}
						download={'allergenen.xlsx'}
						icon={<FaCloudDownloadAlt />}
					>
						{/* TODO: give these buttons their own translation */}
						{t('order.filtersAllergensTitle')} (XLSX)
					</Button>
				</div>
			</div>

			<div className={'products__body'}>
				<ProductsFilters
					filters={filters}
					toggleFilter={toggleFilter}
					setFilter={setFilter}
					clearFilters={clearFilters}
					shopcodes={filteredShopcodes}
					buylist={true}
					isProductsFetching={isProductsFetching}
				/>

				<div className={'products__list'} ref={productsListRef}>
					<ProductsHeader
						handleSearch={handleSearch}
						query={query}
						numProducts={products?.length}
						filters={filters}
						view={forceGridView ? 'grid' : view}
						changeSort={changeSort}
						changeView={changeView}
						sortKey={sortKey}
						direction={sortDirection}
						sortingDisabled={query && query !== ''}
						defaultFilters={defaultFilters}
						shopcodes={shopcodes}
						clearFilters={clearFilters}
						setFilter={setFilter}
						forceGridView={forceGridView}
					/>

					{isFetching() ? (
						<Spinner />
					) : (
						<ProductsView
							products={products}
							showModal={setProductModal}
							view={forceGridView ? 'grid' : view}
							hideQuantityInput={true}
							isInBuylist={true}
							query={query}
							removeFromBuylist={removeFromBuylist}
							addToBuylist={addToBuylist}
						/>
					)}
				</div>
			</div>

			{renderModal()}
		</div>
	);
}
