import React from 'react';
import './CharlesRoute.scss';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { t } from '../../libraries/i18n';
import { selectRouteVersion } from './../../modules/selectors';
import { Permission } from '../../types/permissions';

interface CharlesRouteProps {
	children: React.ReactNode;
	path: string;
	permission?: Permission;
}

function CharlesRoute(props: CharlesRouteProps) {

	const routeMinimumVersion = useSelector(state => selectRouteVersion(state, props.path));

	const currentVersion = parseInt(process.env.REACT_APP_APP_VERSION as string);

	const isCompatibleVersion = () => {
		if (!routeMinimumVersion) return true;
		if (!currentVersion) return true;
		if (routeMinimumVersion <= currentVersion) return true;
		return false;
	};

	const reload = () => document.location.reload();

	const renderVersionError = () => (
		<div className='error__version'>
			<div className='error__text'>
				{t('errors.module_incompatible_version_text1', currentVersion, routeMinimumVersion)}
			</div>
			<div className={'error__hint'}>
				<button className='link' onClick={reload}>
					{t('errors.module_incompatible_version_reload_button')}
				</button>
				{t('errors.module_incompatible_version_text2')}
			</div>
		</div>
	);

	if (!isCompatibleVersion()) return renderVersionError();

	return (
		<Route path={props.path}>
			{props.children}
		</Route>
	);
}

export default CharlesRoute;
