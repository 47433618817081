import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useCustomer from './../../libraries/hooks/useCustomer';
import { useAppSelector } from '../../libraries/hooks/redux';
import { savePreOrderIfNeeded } from '../PreOrders/preOrdersActions';
import {
	fetchCustomerProductsIfNeeded, fetchHistoryIfNeeded, fetchMetadataIfNeeded, fetchPointsIfNeeded, fetchProductsIfNeeded,
	fetchPromosIfNeeded
} from '../products/productsActions';
import { selectPreOrdersActions } from '../selectors';
import useBooths, { BEURS_2022_PREORDER_TYPE, BEURS_2024_PREORDER_TYPE } from './../../api/tradeFair/useBooths';
import useCustomerRoute from './../../libraries/hooks/useCustomerRoute';
import TradeFairBooth from './TradeFairBooth';
import TradeFairOverview from './TradeFairOverview';
import './TradeFair.scss';

export default function TradeFair(props: any) {
	useCustomerRoute();
	const match = useRouteMatch();
	const { booths } = useBooths();
	const dispatch = useDispatch();
	const preOrdersActions = useAppSelector(state => selectPreOrdersActions(state, BEURS_2024_PREORDER_TYPE));
	const { id: customerId } = useCustomer();

	useEffect(() => {
		if (!customerId || booths.length === 0) return;
		dispatch(fetchPromosIfNeeded(customerId));
		dispatch(fetchProductsIfNeeded(customerId));
		dispatch(fetchCustomerProductsIfNeeded(customerId));
		dispatch(fetchMetadataIfNeeded(customerId));
		dispatch(fetchPointsIfNeeded());
		dispatch(fetchHistoryIfNeeded(customerId));
	}, [customerId, dispatch, booths]);

	useEffect(() => {
		const SEND_ORDER_INTERVAL = 3000;
		const interval = setInterval(() => {
			preOrdersActions.forEach(poAction => {
				dispatch(savePreOrderIfNeeded(poAction, BEURS_2024_PREORDER_TYPE, false));
			});
		}, SEND_ORDER_INTERVAL);

		return () => clearInterval(interval);
	}, [dispatch, preOrdersActions]);

	return (
		<Switch>
			<Route exact path={match.path} component={TradeFairOverview} />

			<Route
				// key={booth.boothId}
				path={`${match.path}/:boothId`}
				// render={() => <TradeFairBooth key={booth.boothId} {...booth} />}
				component={TradeFairBooth}
			/>
			{/* <Route render={() => <TradeFairOverview />} /> */}
		</Switch>
	);
}
