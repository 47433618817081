import { fetcher } from './../index';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { selectCustomerId } from './../../modules/selectors';
import { OrderHistoryItem } from '../../types/orders/orderHistory';

const transformData = (data: Array<any>): Array<OrderHistoryItem> | undefined => {
	if (!data) return undefined;

	return data.map((item: any) => ({
		id: item.id,
		deliveryDate: item.deliveredOn ? new Date(item.deliveredOn) : undefined,
		submittedAt: item.submittedOn ? new Date(item.submittedOn) : undefined,
		customerRef: item.customerReference,
		reference: item.erpOrderNr,
		origin: item.origin,
		pdf: item.pdf,
		status: item.status
	}));
};

export default function useOrderHistory() {
	const customerId = useSelector(selectCustomerId);
	const { data, error } = useSWR(`/api/customer.order.history/${customerId}/orders`, fetcher);

	return {
		orderHistory: transformData(data),
		error
	};
}
