const MONTHS = {
	'nl': [
		'januari',
		'februari',
		'maart',
		'april',
		'mei',
		'juni',
		'juli',
		'augustus',
		'september',
		'oktober',
		'november',
		'december'
	],
	'fr': [
		'janvier',
		'février',
		'mars',
		'avril',
		'mai',
		'juin',
		'juillet',
		'août',
		'septembre',
		'octobre',
		'novembre',
		'décembre',
	],
	'en': [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
};

const DAYS = {
	'nl': [
		'maandag',
		'dinsdag',
		'woensdag',
		'donderdag',
		'vrijdag',
		'zaterdag',
		'zondag'
	],
	'fr': [
		'lundi',
		'mardi',
		'mercredi',
		'jeudi',
		'vendredi',
		'samedi',
		'dimanche',
	],
	'en': [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	]
};

const EXTRA = {
	'between': {
		'nl': 'tussen',
		'fr': 'entre',
		'en': 'between'
	},
	'and': {
		'nl': 'en',
		'fr': 'et',
		'en': 'and'
	}
};

const getExtra = (key, locale='nl') => {
	if (key === null || key === undefined) return null;
	return EXTRA[key][locale];
};

export const getDayName = (day, locale) => {
	if (day === null || day === undefined) return null;
	return DAYS[locale][day];
};

export const getActualDayOfWeek = (date) => {
	const americanWeekday = date.getDay();
	const dow = americanWeekday === 0 ? 7 : americanWeekday;
	return dow;
};

export const getPrettyDate = (date, locale = 'nl') => {
	if (!date) return null;
	const dayOfWeek = getDayName(getActualDayOfWeek(date) - 1, locale);
	const day = date.getDate();
	const month = getMonthName(date.getMonth(), locale);
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	return `${dayOfWeek} ${day} ${month} ${year} ${hours}:${minutes}`;
};

export const getPrettyDateStartEnd = (dateStart, dateEnd, locale = 'nl') => {
	if (!dateStart || !dateEnd) return null;

	if (dateStart.getUTCFullYear() !== dateEnd.getUTCFullYear() ||
    dateStart.getUTCMonth() !== dateEnd.getUTCMonth() ||
    dateStart.getUTCDate() !== dateEnd.getUTCDate()){return null;}

	const dayOfWeek = getDayName(getActualDayOfWeek(dateStart) - 1, locale);
	const day = dateStart.getDate();
	const month = getMonthName(dateStart.getMonth(), locale);
	const year = dateStart.getFullYear();
	const hoursStart = dateStart.getHours().toString().padStart(2, '0');
	const minutesStart = dateStart.getMinutes().toString().padStart(2, '0');
	const hoursEnd = dateEnd.getHours().toString().padStart(2, '0');
	const minutesEnd = dateEnd.getMinutes().toString().padStart(2, '0');

	return `${dayOfWeek} ${day} ${month} ${year} ${getExtra('between', locale)} ${hoursStart}:${minutesStart} ${getExtra('and', locale)} ${hoursEnd}:${minutesEnd}`;
};

/**
 * Format a Date like 'dd-mm-yyyy'
 * @param date Date
 * @returns {string|null} 'dd-mm-yyyy'
 */
export const getDateString = (date) => {
	if (!date) return null;
	const dateDay = (`0${date.getDate()}`).slice(-2);
	const dateMonth = (`0${date.getMonth() + 1}`).slice(-2);
	return `${dateDay}-${dateMonth}-${date.getFullYear()}`;
};

export const getReversedDateString = (date) => {
	if (!date) return null;
	const dateDay = (`0${date.getDate()}`).slice(-2);
	const dateMonth = (`0${date.getMonth() + 1}`).slice(-2);
	return `${date.getFullYear()}-${dateMonth}-${dateDay}`;
};

export const getMonthName = (month, locale = 'nl') => {
	if (month === null || month === undefined) return null;
	return MONTHS[locale][month];
};

export const getMonthOfYearFromDateString = (dateString) => {
	if (!dateString) return null;
	const year = dateString.substr(6, 9);
	const month = dateString.substr(3, 2);
	return `${year}-${month}`;
};

export const getDateFromDateString = (dateString) => {
	if (!dateString) return null;
	const year = dateString.substr(6, 9);
	const month = dateString.substr(3, 2);
	const day = dateString.substr(0, 2);

	return new Date(year, month - 1, day, 0, 0, 0, 0);
};

export const getDateFromReversedDateString = (dateString) => {
	if (!dateString) return null;
	const year = dateString.substr(0, 4);
	const month = dateString.substr(5, 2);
	const day = dateString.substr(8, 2);

	return new Date(year, month - 1, day, 0, 0, 0, 0);
};

export const getDateTimeFromReversedDateString = (dateString) => {
	if (!dateString) return null;
	return new Date(dateString);
};

/**
 * Formats 'yyyy-mm-dd' like 'dd-mm-yyyy'
 * @param reversedDateString 'yyyy-mm-dd'
 * @returns {string|null} 'dd-mm-yyyy'
 */
export const getDateStringFromReversedDateString = (reversedDateString) => {
	if (!reversedDateString) return null;
	const date = getDateFromReversedDateString(reversedDateString);
	return getDateString(date);
};

// Don't reinvent the wheel
// Source: https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
export const getWeekNumber = (d) => {
	if (!d) return null;

	// Copy date so don't modify original
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
	// Set to nearest Thursday: current date + 4 - current day number
	// Make Sunday's day number 7
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
	// Get first day of year
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	// Calculate full weeks to nearest Thursday
	const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
	// Return array of year and week number
	return [d.getUTCFullYear(), weekNo];
};

// Don't reinvent the wheel
// Source: https://stackoverflow.com/questions/16590500/javascript-calculate-date-from-week-number
export const getDateOfISOWeek =(w, y) => {
	const simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
	const dow = simple.getDay();
	const ISOweekStart = simple;
	if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
	else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
	return ISOweekStart;
};

export const isDateInBetweenDates = (date, start, end) => {
	return date >= start && date <= end;
};

export const isInRange = (date, start, end) => {
	return date >= start && date <= end;
};

export const getCountdownTimer = (date) => {
	const now = new Date().getTime();
	const delta = date.getTime() - now;
	if (delta <= 0) return ['00', '00', '00', '00'];
	const days = Math.floor(delta / (1000 * 60 * 60 * 24));
	// No leading zeros for days
	//days = (`00${days}`).slice(-2);
	let hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	hours = (`00${hours}`).slice(-2);
	let minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
	minutes = (`00${minutes}`).slice(-2);
	let seconds = Math.floor((delta % (1000 * 60)) / 1000);
	seconds = (`00${seconds}`).slice(-2);
	return [days, hours, minutes, seconds];
};

export const isCampaignActive = (campaign, promos) => {
	promos = Object.keys(promos).map(k => promos[k]);
	promos = promos.filter(p => p.campaign && p.campaign === campaign);
	promos = promos.filter(promo => {
		const visibleStart = new Date(promo.visible.start);
		const visibleEnd = new Date(promo.visible.end);

		return visibleStart <= Date.now()
			&& visibleEnd >= Date.now();
	});

	return promos.length > 0;
};

export const toReduxDateString = date => date?.toISOString() ?? null;

export const fromReduxDateString = string => string === null ? null : new Date(string);

export const isSelectedDateValid = selectedDate => {
	if (!selectedDate) return true;

	const today = new Date();
	const tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);
	tomorrow.setHours(0, 0, 0, 0);

	return selectedDate >= tomorrow;
};

export const isFuture = date => {
	return date > new Date();
};

export const isPast = date => {
	return date < new Date();
};

export const isValid = d => d instanceof Date && !isNaN(d);

export const isSameDay = (a, b) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
export const isToday = a => isSameDay(a, new Date());

export const dateRangeToDates = (a, b) => {
	if (!a || !b) return [];

	const start = new Date(a);
	const end = new Date(b);

	const dates = [];
	const currentDate = new Date(start.getTime());
	currentDate.setDate(currentDate.getDate() + 1);

	for (let dt = new Date(start); dt.getTime() <= end.getTime(); dt.setDate(dt.getDate() + 1)) {
		dates.push(new Date(dt));
	}

	return dates;
};

export default {
	getDateString,
	getMonthName,
	getMonthOfYearFromDateString,
	getDateFromDateString,
	getReversedDateString,
	getDateFromReversedDateString,
	getDateStringFromReversedDateString,
	getWeekNumber,
	isDateInBetweenDates,
	isInRange,
	getCountdownTimer,
	isCampaignActive,
	toReduxDateString,
	fromReduxDateString,
	isSelectedDateValid,
	isSameDay,
	isToday,
	isFuture,
	isPast,
	getDateOfISOWeek,
	isValid
};
