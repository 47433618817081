const LOCAL_STORAGE_KEY = 'portal.client.preferences';
const PREFERENCES = {
	PRODUCTS_VIEW: 'products-view',
	PRODUCT_SORT_KEY: 'products-sort-key',
	PRODUCT_SORT_DIRECTION: 'products-sort-direction',
	INVOICES_VIEW: 'invoices-view',
	LOCALE: 'locale',
	NAVIGATION_COLLAPSED: 'navigation-collapsed'
};

const getAll = () => {
	return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
};

const get = (key) => {
	return getAll()[key] || undefined;
};

const set = (key, value) => {
	const settings = getAll();
	settings[key] = value;
	localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings));
};

export default {
	PREFERENCES,
	getAll,
	get,
	set
};
