import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './../configureStore';
import App from './App';
import initialState from './initialState';
//import { saveState, loadState, lastUpdated } from './../localStorage';
//import { selectCustomerLastUpdated } from './selectors';
import { BrowserRouter as Router } from 'react-router-dom';

const preloadedState = {
	...initialState,
	//...loadState()
};

export const store = configureStore(preloadedState);

// store.subscribe(() => {
// 	const state = store.getState();
// 	if (selectCustomerLastUpdated(state) === lastUpdated()) return;

// 	saveState({
// 		customer: {
// 			...store.getState().customer,
// 			isFetching: false,
// 			didInvalidate: true
// 		}
// 	});
// });

window.store = store;

export default function RootContainer() {
	return (
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	);

}
