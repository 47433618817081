
import Api from './../../libraries/api';
import {
	selectCustomerId,
	selectIsCustomerFetching,
	selectDidCustomerInvalidate
} from './../selectors';
import * as i18n from './../../libraries/i18n';

export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST';
export const CUSTOMER_RECEIVE = 'CUSTOMER_RECEIVE';
export const CUSTOMER_INVALIDATE = 'CUSTOMER_INVALIDATE';
export const CUSTOMER_REQUEST_FAIL = 'CUSTOMER_REQUEST_FAIL';
export const UPDATE_CUSTOMER_LOCALE ='UPDATE_CUSTOMER_LOCALE';

export function setLocale(loc) {
	return async (dispatch) => {
		await i18n.setLocale(loc);
		return dispatch({
			type: UPDATE_CUSTOMER_LOCALE,
			payload: loc
		});
	};
}

export function invalidateCustomer() {
	return {
		type: CUSTOMER_INVALIDATE
	};
}

function requestCustomer() {
	return {
		type: CUSTOMER_REQUEST
	};
}

function receiveCustomer(data) {
	return {
		type: CUSTOMER_RECEIVE,
		customer: data,
		receivedAt: Date.now()
	};
}

function rejectPromiseCustomer(error) {
	return {
		type: CUSTOMER_REQUEST_FAIL,
		error: {
			message: `Fout bij het ophalen van klant informatie: ${error?.message}`,
			receivedAt: Date.now()
		}
	};
}

function fetchCustomer() {
	return async dispatch => {
		dispatch(requestCustomer());

		let data;

		try {
			localStorage.removeItem('portal.client.customer');
			data = await Api.getCustomer();
			localStorage.setItem('portal.client.customer', JSON.stringify(data));

		} catch (error) {
			return dispatch(rejectPromiseCustomer());
		}

		return dispatch(receiveCustomer(data));
	};
}

function shouldFetchCustomer(state) {
	const customerId = selectCustomerId(state);
	const isFetching = selectIsCustomerFetching(state);
	const didInvalidate = selectDidCustomerInvalidate(state);
	if (isFetching) return false;
	if (didInvalidate) return true;
	return !customerId;
}

export function fetchCustomerIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchCustomer(getState())) {
			return dispatch(fetchCustomer());
		}
	};
}
