import invoices from './Invoices/invoicesReducer';
import transportDocuments from './TransportDocuments/transportDocumentsReducer';

export default function documents(state = {}, action) {
	return {
		...state,
		invoices: invoices(state.invoices, action),
		transportDocuments: transportDocuments(state.transportDocuments, action)
	};
}
