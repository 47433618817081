import { useEffect, useState } from 'react';

export const useProductPoints = (allPoints?:
    {
        points: number;
        date: {
            start: string | Date;
            end: string | Date;
        };
        tradeFair?: boolean;
    }[]
    , deliveryDate?: Date | null, tradeFairDates?: {
        start: string | Date;
        end: string | Date;
    }) => {
    const [points, setPoints] = useState({
        standardPoints: 0,
        tradeFairPoints: 0,
    });

    useEffect(() => {

        if (!allPoints || !deliveryDate) {
            return;
        }

        setPoints(allPoints.reduce((acc, item) => {
            if (
                deliveryDate.getTime() >= new Date(item.date.start).getTime() &&
                deliveryDate.getTime() <= new Date(item.date.end).getTime()
            ) {
                if (!item.tradeFair) {
                    return {
                        ...acc,
                        standardPoints: acc.standardPoints + item.points
                    };
                }
                if (tradeFairDates && Date.now() <= new Date(tradeFairDates.end).getTime() && Date.now() >= new Date(tradeFairDates.start).getTime()) {
                    return {
                        ...acc,
                        tradeFairPoints: acc.tradeFairPoints + item.points
                    };
                }
                return acc;
            }
            return acc;
        }, {
            standardPoints: 0,
            tradeFairPoints: 0,
        }));

    }, [allPoints, deliveryDate]);

    return points;
};
