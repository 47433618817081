import { combineReducers } from 'redux';
import app from './appReducer';
import products from './products/productsReducer';
import order from './order/orderReducer';
import priceChanges from './PriceChanges/PriceChangesReducer';
import documents from './Documents/documentsReducer';
import customer from './customer/customerReducer';
import buylist from './buylist/buylistReducer';
import reports from './Reports/ReportsReducer';
import margins from './Margins/MarginsReducer';
import preOrders from './PreOrders/preOrdersReducer';

const rootReducer = combineReducers({
	app,
	products,
	order,
	priceChanges,
	documents,
	customer,
	buylist,
	reports,
	margins,
	preOrders
});

export default rootReducer;
