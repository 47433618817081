import React from 'react';
import './MainFilters.scss';
import { FaTruck, FaIndustry } from 'react-icons/fa';
import FilterGroup from '../FilterGroup';
import Checkbox from '../../checkbox/checkbox';
import { t } from './../../../libraries/i18n';
import { useSelector } from 'react-redux';
import { selectActiveCampaignFilters, selectLocale } from './../../../modules/selectors';

export default function MainFilters(props) {
	const mainFilters = props.filters.main;
	const campaignFilters = props.filters.campaigns;
	const mainFiltersNum = Object.keys(mainFilters).reduce((sum, key) => {
		if (key === 'buylist') return sum;
		if (mainFilters[key] !== 'off') sum += 1;
		return sum;
	}, 0);
	const campaignFiltersNum = Object.keys(campaignFilters).reduce((sum, key) => {
		if (key === 'buylist') return sum;
		if (campaignFilters[key] !== 'off') sum += 1;
		return sum;
	}, 0);
	const numFilters = mainFiltersNum + campaignFiltersNum;

	const campaigns = useSelector(selectActiveCampaignFilters);
	const locale = useSelector(selectLocale);

	const renderCampaignFilters = () => {
		if (campaigns.length <= 0) return null;

		return (
			<div className={'campaign__filters'}>
				{campaigns.map(campaign => (
					<div
						key={campaign.id}
						className={`
							filter__item
							filter__campaign
							filter__campaign--${campaign.id}
							filter--${props.filters.campaigns[campaign.id]}
						`}
						onClick={() => props.toggleFilter(`campaigns.${campaign.id}`)}
					>
						<Checkbox checked={props.filters.campaigns[campaign.id]} />
						{campaign.articles?.filter?.text?.[locale]}
						{campaign.articles?.badge?.text && (
							<span
								style={{
									color: campaign.articles?.badge?.color?.text,
									background: campaign.articles?.badge?.color?.background
								}}
							>
								{campaign.articles?.badge?.text?.[locale]}
							</span>
						)}
					</div>
				))}
			</div>
		);
	};

	const renderImportantFilters = () => (
		<div className={'items__group'}>
			{!props.buylist && (
				<div
					className={`filter__item filter__promo filter--${mainFilters.promo}`}
					onClick={() => props.toggleFilter('main.promo')}
				>
					<Checkbox checked={mainFilters.promo} />
					{t('order.filtersMainPromo')}
				</div>
			)}

			{!props.buylist && renderCampaignFilters()}
		</div>
	);

	const renderOtherFilters = () => (
		<>
			<div className={'items__group'}>
				<div
					className={`filter__item filter__eob filter--${mainFilters.eob}`}
					onClick={() => props.toggleFilter('main.eob')}
				>
					<Checkbox checked={mainFilters.eob} />
					{t('order.filtersMainEob')}
				</div>

				<div
					className={`filter__item filter__season filter--${mainFilters.season}`}
					onClick={() => props.toggleFilter('main.season')}
				>
					<Checkbox checked={mainFilters.season} />
					{t('order.filtersMainSeasonal')}
				</div>

				{!props.buylist && (
					<div
						className={`filter__item filter__timeSensitive filter--${mainFilters.timeSensitive}`}
						onClick={() => props.toggleFilter('main.timeSensitive')}
					>
						<Checkbox checked={mainFilters.timeSensitive} />
						{t('order.filtersMainTimeSensitive')}
					</div>
				)}
			</div>

			<div className={'items__group'}>
				{!props.buylist && (
					<div
						className={`filter__item filter__in-order filter--${mainFilters.inOrder}`}
						onClick={() => props.toggleFilter('main.inOrder')}
					>
						<Checkbox checked={mainFilters.inOrder} />
						{t('order.filtersMainInOrder')}
					</div>
				)}

				<div
					className={`filter__item filter__pending filter--${mainFilters.pending}`}
					onClick={() => props.toggleFilter('main.pending')}
				>
					<Checkbox checked={mainFilters.pending} />
					{t('order.filtersMainPending')} <FaTruck />
				</div>

				<div
					className={`filter__item filter__reserved filter--${mainFilters.reserved}`}
					onClick={() => props.toggleFilter('main.reserved')}
				>
					<Checkbox checked={mainFilters.reserved} />
					{t('order.filtersMainReserved')} <FaIndustry />
				</div>
			</div>
		</>
	);

	return (
		<FilterGroup
			title={'Filters'}
			className='MainFilters'
			numFilters={numFilters}
			clearFilters={props.clearFilters}
			id={'mainFilters'}

		>
			<FilterGroup.Title>{t('order.filtersMainTitle')}</FilterGroup.Title>
			<FilterGroup.Body>
				<FilterGroup.Collapsed>
					{renderImportantFilters()}
				</FilterGroup.Collapsed>

				<FilterGroup.Uncollapsed>
					{renderImportantFilters()}
					{renderOtherFilters()}
				</FilterGroup.Uncollapsed>
			</FilterGroup.Body>
		</FilterGroup>
	);
}
