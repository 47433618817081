export enum CRUDFieldTypes {
	Text = 'text',
	Number = 'number',
}

export interface CRUDListConfig {
	fetch: string,
	columns: Array<string>,
	rowAction?: any,
	gridTemplateColumns?: string
}

export interface CRUDDetailSection {
	title: string,
	fields: Array<string>
}

export interface CRUDDetailConfig {
	location: string,
	fields: Array<string>
	sections?: Array<CRUDDetailSection>,
	defaultNewValue: any
}

export interface CRUDField {
	title: string,
	name: string | Array<string>,
	type: CRUDFieldTypes | string,
	placeholder?: string,
	disabled?: boolean,
	options?: any
}

export interface CRUDConfig {
	fields: Array<CRUDField>
	list: CRUDListConfig,
	detail: CRUDDetailConfig,
	path: string
}
