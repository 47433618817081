class Logger {
	levels;
	transports;

	init(options) {
		this.levels = options.levels || {
			error: 0,
			warn: 1,
			info: 2,
			verbose: 3,
			debug: 4,
			silly: 5
		};
		this.transports = options.transports;
	}

	log(level, msg, meta) {
		this.transports.forEach(transport => {
			const logLevel = this.levels[level];
			const transportLevel = this.levels[transport.level];

			if (logLevel <= transportLevel) {
				transport.log(level, msg, meta);
			}
		});
	}

	error(msg, meta) {
		this.log('error', msg, meta);
	}

	warn(msg, meta) {
		this.log('warn', msg, meta);
	}

	info(msg, meta) {
		this.log('info', msg, meta);
	}

	verbose(msg, meta) {
		this.log('verbose', msg, meta);
	}

	debug(msg, meta) {
		this.log('debug', msg, meta);
	}

	silly(msg, meta) {
		this.log('silly', msg, meta);
	}
}

export const logger = new Logger();
