import {
	selectProductItems,
	selectCustomerProducts,
	selectDeliveryDate,
	selectCampaigns,
	selectPromos,
	selectProductPromosById,
	selectEobNetItems,
	selectEobDetails
} from './../selectors';
import ProductHelper from './../../libraries/productHelper';

const selectMissingData = (state, {
	products,
	customerProducts,
	deliveryDate,
	campaigns,
	promos,
	eobNet,
	eobDetails
}) => {
	if (!products) products = selectProductItems(state);
	if (!customerProducts) customerProducts = selectCustomerProducts(state);
	if (!deliveryDate) deliveryDate = selectDeliveryDate(state);
	if (!campaigns) campaigns = selectCampaigns(state);
	if (!promos) promos = selectPromos(state);
	if (!eobDetails) eobDetails = selectEobDetails(state);

	return {
		products,
		customerProducts,
		deliveryDate,
		campaigns,
		promos,
		eobNet: {
			...selectEobNetItems(state),
			...eobNet
		},
		eobDetails
	};
};

const getEobData = (product, eobNetData) => {
	if (!eobNetData || !eobNetData?.[product.id]) return product.eob;
	return eobNetData?.[product.id];
};

export const getOrderProducts = (state, data) => {
	const {
		products,
		customerProducts,
		deliveryDate,
		campaigns,
		promos,
		eobNet,
		eobDetails
	} = selectMissingData(state, data);

	if (!customerProducts) return null;

	if (data.partialProducts) {
		Object.keys(data.partialProducts).forEach(productId => {
			products[productId] = {
				...products[productId] || {},
				...data.partialProducts[productId]
			};
		});
	}

	let orderProducts = Object.keys(customerProducts).map(productId => {
		const customerProduct = customerProducts ? customerProducts[productId] : null;
		const product = products ? products[productId] : null;
		const productPromos = selectProductPromosById(state, productId);
		if (!customerProduct && !product) return null;

		const promo = promos && deliveryDate ? ProductHelper.getActivePromoByProductId(productId, promos, deliveryDate, productPromos) : null;
		const eobDetail = eobDetails ? ProductHelper.getEobDetailByProductId(productId, eobDetails) : null;
		const productCampaigns = campaigns && deliveryDate ? ProductHelper.getActiveCampaignsByProductId(productId, campaigns) : null;

		const productData = {
			...customerProduct,
			...product
		};

		const fullProduct = {
			...productData,
			promo: promo,
			campaigns: productCampaigns,
			eob: getEobData(productData, eobNet),
			eobDetail: eobDetail
		};

		return fullProduct;
	});

	orderProducts = orderProducts.filter(p => p !== null);

	return orderProducts;
};
