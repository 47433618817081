import React, { useEffect } from 'react';
import './Documents.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
	Switch,
	NavLink,
	useRouteMatch,
	useLocation,
	useHistory
} from 'react-router-dom';
import Invoices from './Invoices/Invoices';
import { t } from './../../libraries/i18n';
import TransportDocuments from './TransportDocuments/TransportDocuments';
import {
	selectCanViewInvoices,
	selectCanViewTransportDocuments,
	selectCustomerId
} from './../selectors';
import { fetchTransportDocumentsIfNeeded } from './TransportDocuments/transportDocumentsActions';
import { fetchInvoicesIfNeeded } from './Invoices/invoicesActions';
import Error from '../../components/Error/Error';
import { PERMISSIONS } from './../../libraries/permissions';
import RestrictedRoute from '../../components/RestrictedRoute/RestrictedRoute';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';

function Documents() {
	useCustomerRoute();
	const match = useRouteMatch();
	const location = useLocation();
	const history = useHistory();
	const canViewInvoices = useSelector(selectCanViewInvoices);
	const canViewTransportDocuments = useSelector(selectCanViewTransportDocuments);
	const customerId = useSelector(selectCustomerId);

	const dispatch = useDispatch();

	const tabs = [
		{
			location: `${match.path}/invoices`,
			title: t('invoices.title'),
			component: Invoices,
			hasPermission: canViewInvoices,
			requiredPermission: PERMISSIONS.VIEW_INVOICES
		},
		{
			location: `${match.path}/transport`,
			title: t('transportDocuments.title'),
			component: TransportDocuments,
			hasPermission: canViewTransportDocuments,
			requiredPermission: PERMISSIONS.VIEW_TRANSPORT_DOCUMENTS
		}
	];

	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			const urlParams = new URLSearchParams(window.location.search);
			const noPrefetch = urlParams.get('noPrefetch');
			if (noPrefetch === '') return;
		}

		if (canViewInvoices) dispatch(fetchInvoicesIfNeeded(customerId));
		if (canViewTransportDocuments) dispatch(fetchTransportDocumentsIfNeeded(customerId));
	}, [
		dispatch,
		customerId,
		canViewInvoices,
		canViewTransportDocuments
	]);

	const visibleTabs = tabs.filter(tab => tab.hasPermission || tab.location === location.pathname);
	if (visibleTabs.length <= 0) return <Error code={403} size={'full'} />;

	// Go to first subroute in visibleTabs if we're not in a subroute already
	if (match.path === location.pathname) history.replace(visibleTabs[0].location);

	return (
		<div className={'Documents'}>
			<ul className={'documents__tabs'}>
				{visibleTabs.map(tab => (
					<li key={tab.location} className={'tabs__item'}>
						<NavLink
							to={tab.location}
							className={`item__link ${tab.hasPermission ? '' : 'item__link--unauthorized'}`}
							isActive={(match, location) => location.pathname === tab.location}
							activeClassName={'item__link--active'}
						>
							{tab.title}
						</NavLink>
					</li>
				))}
			</ul>

			<Switch>
				{tabs.map(tab => (
					<RestrictedRoute key={tab.location} path={tab.location} component={tab.component} permission={tab.requiredPermission} />
				))}
			</Switch>
		</div>
	);
}


export default Documents;
