import React from 'react';
import './ShopcodeBrowser.scss';
import ProductHelper from '../../../libraries/productHelper';
import { useSelector } from 'react-redux';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { selectAvailableShopcodes } from './../../../modules/selectors';
import { getLocale } from '../../../libraries/i18n';

function ShopcodeBrowser(props) {
	const {
		filter,
		setShopcodeFilter
	} = props;

	const shopcodes = useSelector(selectAvailableShopcodes);

	const getShopcodes = (allShopcodes, level, code) => {
		code = code.toString();

		return Object.keys(allShopcodes).filter(shopId => {
			shopId = shopId.toString();

			switch (level) {
				case 1:
					return shopId.substr(-7, 8) === '0000000';
				case 2:
					return shopId.substr(0, 1) === code.substr(0, 1) && shopId.substr(1, 1) !== '0' && shopId.substr(-6, 8) === '000000';
				case 3:
					return shopId.substr(0, 2) === code.substr(0, 2) && shopId.substr(2, 2) !== '00' && shopId.substr(-4, 8) === '0000';
				case 4:
					return shopId.substr(0, 4) === code.substr(0, 4) && shopId.substr(4, 2) !== '00' && shopId.substr(-2, 8) === '00';
				case 5:
					return shopId.substr(0, 6) === code.substr(0, 6) && shopId.substr(6, 2) !== '00';
				default:
					return false;
			}
		}).map(shopId => allShopcodes[shopId]);
	};

	const doesShopcodeMatch = (code, filter, level) => {
		if (!code || !filter) return false;
		code = code.toString();
		filter = filter.toString();

		switch (level) {
			case 1:
				return code === `${filter.substr(0, 1)}0000000`;
			case 2:
				return code === `${filter.substr(0, 2)}000000`;
			case 3:
				return code === `${filter.substr(0, 4)}0000`;
			case 4:
				return code === `${filter.substr(0, 6)}00`;
			case 5:
				return code === filter;
			default:
				return false;
		}
	};

	const handleShopcodeFilter = (code, currentCode) => {
		let shopcode = code;

		if (code === currentCode) {
			const level = ProductHelper.getShopcodeLevel(String(code));
			shopcode = ProductHelper.convertShopcode(String(code), level - 1);
		}

		setShopcodeFilter(shopcode);
	};

	const renderShopcodesGroup = (allShopcodes, level, filter) => {
		const MAX_LEVEL = 4;
		const locale = getLocale();
		const shopcodes = getShopcodes(allShopcodes, level, filter);
		const filteredShopcodes = shopcodes.filter((shop, index) => {
			const isValid = shop && shop.id && shop.desc && shop.desc[locale];
			if (!isValid) {
				console.log({
					allShopcodes,
					shopcodes,
					shop,
					index
				});
			}
			return isValid;
		});

		return (
			<div className={`shopcodes__level-${level}`}>
				{filteredShopcodes.map(shop => (
					<div
						key={shop.id}
						className={`shopcode shopcode--level-${level} shopcode--${doesShopcodeMatch(shop.id, filter, level) ? 'active' : 'inactive'}`}
					>
						<div
							className={'shopcode__title'}
							onClick={() => handleShopcodeFilter(shop.id, filter)}
						>
							{level !== MAX_LEVEL && (
								doesShopcodeMatch(shop.id, filter, level) ? (
									<FaChevronDown />
								) : (
									<FaChevronRight />
								)
							)}
							{shop.desc[locale]}
						</div>
						{doesShopcodeMatch(shop.id, filter, level) && level < MAX_LEVEL && (
							<div className='shopcode__content'>
								{renderShopcodesGroup(allShopcodes, level + 1, filter)}
							</div>
						)}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className='ShopcodeBrowser'>
			{shopcodes && renderShopcodesGroup(shopcodes, 1, filter)}
		</div>
	);
}

export default ShopcodeBrowser;
