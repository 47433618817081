import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../libraries/i18n';
import {
	selectIsProductHistoriesFetching,
	selectLocale,
	selectProductHistories
} from '../../../modules/selectors';
import BuylistArticleContainer from './BuylistArticleContainer';

const BuylistArticleRow = props => {
	const {
		index,
		style,
		view,
		showModal,
		query,
		products,
		itemsPerRow,
		sizes,
		removeFromBuylist,
		addToBuylist
	} = props;

	const locale = useSelector(selectLocale);
	const histories = useSelector(selectProductHistories);
	const isHistoryFetching = useSelector(selectIsProductHistoriesFetching);
	const [rowItems, setRowItems] = useState([]);

	useLayoutEffect(() => {
		const fromIndex = index * itemsPerRow;
		const toIndex = Math.min(fromIndex + itemsPerRow, products.length);
		setRowItems(products.slice(fromIndex, toIndex));
	}, [itemsPerRow, index, products]);

	return (
		<div
			className='list__row'
			style={style}
		>
			{rowItems.map(product => (
				<BuylistArticleContainer
					key={product.id}
					view={view}
					product={product}
					showModal={showModal}
					imageSource={`${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles`}
					query={query}
					locale={locale}
					history={histories[product.id]}
					isHistoryFetching={isHistoryFetching}
					sizes={sizes}

					removeProductFromBuylist={removeFromBuylist}
					addProductToBuylist={addToBuylist}

					t={t}
				/>
			))}

		</div>
	);
};

export default BuylistArticleRow;
