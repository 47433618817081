export const tradeFairDates = {
    start: '2024-09-20',
    end: '2024-09-30',
} as const;

export type TradeFairDatesType = {
    start: string | Date;
    end: string | Date;
};


export const tradeFairWeeks = [
    //'2024.40',
    '2024.41',
    //'2024.42',
    '2024.43',
    //'2024.44',
    '2024.45',
    //'2024.46',
    '2024.47',
    // '2022.48',
] as const;

export const tradeFairWeekNumbers = [
    { weekNr: 41 },
    { weekNr: 43 },
    { weekNr: 45 },
    { weekNr: 47 },
    // { weekNr: 44 },
    // { weekNr: 45 },
    // { weekNr: 46 },
    // { weekNr: 47 },
    // { weekNr: 48 },
];
