import React, { Component } from 'react';
import './carousel.scss';
import ImageGallery from 'react-image-gallery';

class Carousel extends Component {
	handleCarouselClick = (e) => {
		if (e.target.nodeName === 'BUTTON') e.stopPropagation();
	};

	getCurrentIndex = () => {
		return this.imageGallery.getCurrentIndex();
	};

	render() {
		const singleImage = this.props.items.length === 1;

		return (
			<div className={'Carousel'} onClick={this.handleCarouselClick}>
				<ImageGallery
					ref={i => this.imageGallery = i}
					items={this.props.items}
					showThumbnails={false}
					showFullscreenButton={false}
					showPlayButton={false}
					showBullets={!singleImage}
					lazyLoad={true}
					disableArrowKeys={true}
					showNav={!singleImage}
				/>
			</div>
		);
	}
}

export default Carousel;
