import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectOrderSteps } from './../../modules/selectors';

function usePreviousAndNextStep() {
	const location = useLocation();
	const steps = useSelector(selectOrderSteps);
	const [currentIndex, setCurrentIndex] = useState(undefined);
	const [previousStep, setPreviousStep] = useState(undefined);
	const [nextStep, setNextStep] = useState(undefined);

	useEffect(() => {
		steps.forEach((step, index) => {
			if (step.route === location.pathname) setCurrentIndex(index);
		});
	}, [location, steps]);

	useEffect(() => {
		if (currentIndex > 0) setPreviousStep(steps[currentIndex - 1]);
		else setPreviousStep(undefined);

		if (currentIndex < steps.length) setNextStep(steps[currentIndex + 1]);
		else setNextStep(undefined);
	}, [currentIndex, steps]);

	return [previousStep, nextStep];
}

export default usePreviousAndNextStep;
