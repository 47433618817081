import React, { Component } from 'react';
import './spinner.scss';

class Spinner extends Component {
	renderSmall = () => {
		return (
			<div className={'Spinner Spinner--small'}>
				<div className='circle'></div>
			</div>
		);
	};

	renderDefault = () => {
		return (
			<div className={'Spinner'}>
				<div className='circle'></div>
				<span className={'text'}>{'Laden'}&hellip;</span>
			</div>
		);
	};

	render() {
		switch (this.props.size) {
			case 'small':
				return this.renderSmall();
			default:
				return this.renderDefault();
		}
	}
}

export default Spinner;
