import React from 'react';
//import EndOfYear2020ProductsFilter from './EndOfYear2020/ProductsFilter';

function CampaignProductsFilters(props) {
	return (
		<>
			{/* <EndOfYear2020ProductsFilter {...props} /> */}
		</>
	);
}

export default CampaignProductsFilters;
