import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './productDetail.scss';
import Carousel from '../../carousel/carousel';
import {
	FaCashRegister,
	FaCheck,
	FaCloudDownloadAlt,
	FaSpinner,
	FaTimes
} from 'react-icons/fa';
import ProductHelper from '../../../libraries/productHelper';
import { t } from './../../../libraries/i18n';
import { logger } from './../../../libraries/logger/logger';
import { Article, ButtonLink, Spinner } from '@abm-international/react-components';
import {
	selectCustomerId,
	selectPermissions,
	selectShopcodes,
	selectGroups,
	selectLocale
} from '../../../modules/selectors';
import { PERMISSIONS } from '../../../libraries/permissions';
import * as Api from '../../../libraries/api';
import ProductPromo from './../productPromo/productPromo';
import { Gtag } from '../../../modules/common/utils';
import placeholder from '../../../images/placeholder.png';
import { ReactComponent as simpleFlyer } from '../../../images/simpleFlyer.svg';
import { ReactComponent as simpleFlyerDouble } from '../../../images/simpleFlyerDouble.svg';
import config from '../../../config';
import ProductPromoPreOrder from '../../../modules/PreOrders/ProductItem/ProductPromo';

const ProductDetail = (props) => {
	const { product, deliveryDate, preOrderType } = props;

	const customerId = useSelector(selectCustomerId);
	const shopcodes = useSelector(selectShopcodes);
	const groups = useSelector(selectGroups);
	const permissions = useSelector(selectPermissions);
	const locale = useSelector(selectLocale);

	const hasMarginsPermissions = permissions.includes(PERMISSIONS.MARGINS);

	const carousel = useRef(null);
	const [flyersState, setFlyersState] = useState({});
	const [imageIndexNotLoadable, setImageIndexNotLoadable] = useState([]);

	useEffect(() => {
		Gtag.event({
			category: 'modal',
			action: `/order/products/${product.id}`
		});
	}, [product.id]);

	if (!product) {
		return (
			<Spinner />
		);
	}

	const getImages = (product) => {
		const images = [];

		if (product.images.SF.length === 0 && product.images.PS.length === 0) {
			images.push({
				original: placeholder
			});
		} else {
			product.images.SF.forEach(image => {
				images.push({
					big: `${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/0/${image}`,
					original: `${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/1200/${image}`,
					small: `${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/80/${image}`
				});
			});

			product.images.PS.forEach(image => {
				images.push({
					big: `${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/0/${image}`,
					original: `${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/1200/${image}`,
					small: `${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/80/${image}`
				});
			});
		}

		return images;
	};
	const renderImageDownloadButton = (img, key) => {
		if (imageIndexNotLoadable.includes(key)) return null;
		return (
			<a key={key} href={img.big} target={'_blank'} rel={'noopener noreferrer'} className={'download_button download_button__image'}>
				<img src={img.small} alt={''} onError={() => setImageIndexNotLoadable([...imageIndexNotLoadable, key])} />
				<FaCloudDownloadAlt />
			</a>
		);
	};

	const renderFlyerDownloadButton = (id) => {
		const flyerType = id.includes('001') ? simpleFlyer : simpleFlyerDouble;
		const flyerLanguage = id.slice(-2);
		const state = flyersState[id] ?? 'idle';
		const disabledStates = ['loading, error'];

		const downloadFile = async () => {
			setFlyersState({ ...flyersState, [id]: 'loading' });
			const response = await Api.document(id, customerId, 'pdf', { article: product.id }, `flyer ${id} ${product.name}`);
			if (response.download) {
				response.download(document);
				setFlyersState(flyersState => ({ ...flyersState, [id]: 'downloaded' }));
			} else {
				setFlyersState(flyersState => ({ ...flyersState, [id]: 'error' }));
			}
			setTimeout(() => setFlyersState(flyersState => ({ ...flyersState, [id]: 'idle' })), 4000);
		};

		const stateIcons = {
			loading: FaSpinner,
			error: FaTimes,
			downloaded: FaCheck,
			idle: FaCloudDownloadAlt
		};

		const Icon = stateIcons[state];

		return (
			<span
				onClick={disabledStates.includes(state) ? undefined : downloadFile}
				key={`flyer-${id}`}
				className={`download_button download_button__flyer download_button__flyer__${state}`}
			>
				<img src={flyerType} alt={''} />
				<span className={'download_button__language'}>{flyerLanguage}</span>
				<Icon />
			</span>
		);
	};

	const renderSpecificationDownloadButton = ([language, location]) => {
		return (
			<a
				key={language}
				href={`${config.API_URL}/resources/prodspecs/${location}`}
				target={'_blank'}
				rel={'noopener noreferrer'}
				className={'download_button download_button__specification'}
			>
				<span className={'download_button__language'}>{language.toUpperCase()}</span>
				<FaCloudDownloadAlt />
			</a>
		);
	};

	const renderVvoDocDownloadButton = ([language, location]) => {
		return (
			<a
				key={language}
				href={`${config.API_URL}/resources/prodspecs/${location}`}
				target={'_blank'}
				rel={'noopener noreferrer'}
				className={'download_button download_button__specification'}
			>
				<span className={'download_button__language'}>{language.toUpperCase()}</span>
				<FaCloudDownloadAlt />
			</a>
		);
	};

	const getDownloadButtonRenderer = (type) => {
		const renderers = {
			images: renderImageDownloadButton,
			flyers: renderFlyerDownloadButton,
			specifications: renderSpecificationDownloadButton,
			vvodocs: renderVvoDocDownloadButton,
		};

		return renderers[type] ?? (() => null);
	};

	const renderDownloadArea = (items, type) => {
		if (!items?.length) return null;

		return (
			<div className={`download__area download__area__${type}`}>
				<h3>{t(`order.productDetailDownloadTitle_${type}`)}</h3>
				<div>
					{items.map(getDownloadButtonRenderer(type))}
				</div>
			</div>
		);
	};

	const images = getImages(product);
	const shopcode = ProductHelper.getShopcode(shopcodes, product.shopcode);
	if (shopcode === false) logger.warn('Shopcode does not exist', { productId: product.id, shopcode: product.shopcode });
	const group = ProductHelper.getGroup(groups, product.group.main, product.group.sub);

	const getConsumerPrice = (margin, purchase, sales, weight, amount) => {
		if (!margin || !purchase?.unit || !purchase?.price || !sales?.unit || !sales?.vat || !weight || !amount) return null;
		const marginMultiplier = margin / 100;
		const vatMultiplier = 1 + (sales.vat / 100);

		const getPurchasePrice = (pUnit, sUnit, price, weight, amount) => {
			if (pUnit === 'K' && sUnit === 'K') {
				return price;
			}

			if (pUnit === 'S' && sUnit === 'S') {
				return price / amount;
			}

			if (pUnit === 'K' && sUnit === 'S') {
				return price * weight / amount;
			}

			if (pUnit === 'S' && sUnit === 'K') {
				return price / weight;
			}
		};

		return (getPurchasePrice(purchase.unit, sales.unit, purchase.price, weight, amount) * marginMultiplier * vatMultiplier).toFixed(2);
	};

	const consumerPrice = getConsumerPrice(
		+product?.margin?.value,
		product?.margin?.purchase,
		product?.margin?.sales,
		+product?.weight,
		product?.margin?.customerPackagedGoods
	);


	return (
		<Article
			product={product}
			t={t}
			locale={locale}
		// TODO: productHistory and productPromo don't work yet
		>
			{(A) => (
				<div className={'ProductDetail'}>
					<div className={'product__details'}>
						<div className={'product__id-and-brand'}>
							<A.Id />
							<A.Brand />
						</div>
						<A.Name />
						<div className={'product__group'}>
							{group.main} - {group.sub}
						</div>
						{shopcode &&
							<div className={'product__shopcode'}>
								{shopcode.map((item, key) => (
									<span key={key} className={'shopcode__level'}>
										{item}
									</span>
								))}
							</div>
						}
						<A.Badges />
						<A.Allergens />
						<A.OrderHistory />

						<div className={'product__price-unit-weight'}>
							{product.price && (
								<div className={'product__price'}>
									<span className={'price'}>&euro; {product.price}</span>
									{product.unit && (
										<span className={'unit'}>
											<span className={'unit__per'}>/</span>
											{product.unit === 'S' && t('order.productUnit')}
											{product.unit === 'K' && t('order.productKilo')}
										</span>
									)}
								</div>
							)}

							<div className={'product__weight'}>
								{t('order.productAvgWeightLabel')}: {product.weight} {t('order.productKilo')}
							</div>
						</div>


						{hasMarginsPermissions && consumerPrice && (
							<div className={'product__margin'}>
								<div className={'product__margin-value'}>
									{t('order.productMarginLabel')}: &euro; {consumerPrice} {t(`margins.by_${product.margin?.sales?.unit === 'K' ? 'weight' : 'piece'}`)}
								</div>
								<ButtonLink
									icon={<FaCashRegister />}
									iconPosition='right'
									to={`/margins/article/${product.id}`}
									className={'product__margin-edit'}
								>
									{t('order.productMarginEditLabel')}
								</ButtonLink>
							</div>
						)}

						{/* <A.Promo view={'detail'} /> */}
						{preOrderType && <ProductPromoPreOrder product={product} view='detail' deliveryDate={deliveryDate} preOrderType={preOrderType} />}
						{!preOrderType && <ProductPromo product={product} view='detail' />}

						<div className={'product__description'}>
							{product.description}
						</div>
					</div>

					<div className={'product__image'}>
						<Carousel
							ref={carousel}
							items={images}
						/>
					</div>
					<div className={'download__area__container'}>
						{renderDownloadArea(images.filter(img => img.original !== placeholder), 'images')}

						{renderDownloadArea(product.flyers, 'flyers')}
						{renderDownloadArea(Object.entries(product.specifications), 'specifications')}
						{product.vvodocs instanceof Object && renderDownloadArea(Object.entries(product.vvodocs), 'vvodocs')}

					</div>
				</div>
			)}
		</Article>
	);
};

export default ProductDetail;
