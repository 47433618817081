import Api from './../../libraries/api';
import { updateOrderProducts, doubleCheckInvalidEobNetDates } from './../order/orderActions';

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_RECEIVE = 'PRODUCTS_RECEIVE';
export const PRODUCTS_INVALIDATE = 'PRODUCTS_INVALIDATE';
export const PRODUCTS_REQUEST_FAIL = 'PRODUCTS_REQUEST_FAIL';

export const PRODUCTS_PROMOS_REQUEST = 'PRODUCTS_PROMOS_REQUEST';
export const PRODUCTS_PROMOS_RECEIVE = 'PRODUCTS_PROMOS_RECEIVE';
export const PRODUCTS_PROMOS_INVALIDATE = 'PRODUCTS_PROMOS_INVALIDATE';
export const PRODUCTS_PROMOS_REQUEST_FAIL = 'PRODUCTS_PROMOS_REQUEST_FAIL';

export const PRODUCTS_POINTS_REQUEST = 'PRODUCTS_POINTS_REQUEST';
export const PRODUCTS_POINTS_RECEIVE = 'PRODUCTS_POINTS_RECEIVE';
export const PRODUCTS_POINTS_INVALIDATE = 'PRODUCTS_POINTS_INVALIDATE';
export const PRODUCTS_POINTS_REQUEST_FAIL = 'PRODUCTS_POINTS_REQUEST_FAIL';

export const PRODUCTS_SAVED_POINTS_REQUEST = 'PRODUCTS_SAVED_POINTS_REQUEST';
export const PRODUCTS_SAVED_POINTS_RECEIVE = 'PRODUCTS_SAVED_POINTS_RECEIVE';
export const PRODUCTS_SAVED_POINTS_INVALIDATE = 'PRODUCTS_SAVED_POINTS_INVALIDATE';
export const PRODUCTS_SAVED_POINTS_REQUEST_FAIL = 'PRODUCTS_SAVED_POINTS_REQUEST_FAIL';

export const PRODUCTS_CUSTOMER_INVALIDATE = 'PRODUCTS_CUSTOMER_INVALIDATE';
export const PRODUCTS_CUSTOMER_REQUEST = 'PRODUCTS_CUSTOMER_REQUEST';
export const PRODUCTS_CUSTOMER_RECEIVE = 'PRODUCTS_CUSTOMER_RECEIVE';
export const PRODUCTS_CUSTOMER_REQUEST_FAIL = 'PRODUCTS_CUSTOMER_REQUEST_FAIL';

export const PRODUCTS_METADATA_INVALIDATE = 'PRODUCTS_METADATA_INVALIDATE';
export const PRODUCTS_METADATA_REQUEST = 'PRODUCTS_METADATA_REQUEST';
export const PRODUCTS_METADATA_RECEIVE = 'PRODUCTS_METADATA_RECEIVE';
export const PRODUCTS_METADATA_REJECT = 'PRODUCTS_METADATA_REJECT';

export const PRODUCTS_HISTORY_INVALIDATE = 'PRODUCTS_HISTORY_INVALIDATE';
export const PRODUCTS_HISTORY_REQUEST = 'PRODUCTS_HISTORY_REQUEST';
export const PRODUCTS_HISTORY_RECEIVE = 'PRODUCTS_HISTORY_RECEIVE';
export const PRODUCTS_HISTORY_REJECT = 'PRODUCTS_HISTORY_REJECT';

export const PRODUCTS_CAMPAIGNS_INVALIDATE = 'PRODUCTS_CAMPAIGNS_INVALIDATE';
export const PRODUCTS_CAMPAIGNS_REQUEST = 'PRODUCTS_CAMPAIGNS_REQUEST';
export const PRODUCTS_CAMPAIGNS_RECEIVE = 'PRODUCTS_CAMPAIGNS_RECEIVE';
export const PRODUCTS_CAMPAIGNS_REJECT = 'PRODUCTS_CAMPAIGNS_REJECT';

export const PRODUCTS_EOB_DETAILS_REQUEST = 'PRODUCTS_EOB_DETAILS_REQUEST';
export const PRODUCTS_EOB_DETAILS_RECEIVE = 'PRODUCTS_EOB_DETAILS_RECEIVE';
export const PRODUCTS_EOB_DETAILS_REQUEST_FAIL = 'PRODUCTS_EOB_DETAILS_REQUEST_FAIL';
export const PRODUCTS_EOB_DETAILS_INVALIDATE = 'PRODUCTS_EOB_DETAILS_INVALIDATE';

export function invalidateProducts() {
	return {
		type: PRODUCTS_INVALIDATE
	};
}

function requestProducts() {
	return {
		type: PRODUCTS_REQUEST
	};
}

function receiveProducts(data) {
	return {
		type: PRODUCTS_RECEIVE,
		products: data,
		receivedAt: Date.now()
	};
}

function rejectPromiseProducts(error) {
	return {
		type: PRODUCTS_REQUEST_FAIL,
		error: {
			message: `Fout bij het ophalen van de producten door de bestelmodule: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function fetchProducts() {
	return dispatch => {
		dispatch(requestProducts());

		return Api.getProducts()
			.then(data => {
				dispatch(receiveProducts(data));
				dispatch(updateOrderProducts({ products: data }));
				dispatch(doubleCheckInvalidEobNetDates(Object.values(data)));
			})
			.catch(res => dispatch(rejectPromiseProducts(res)));
	};
}

function shouldFetchProducts(state) {
	const products = state.products;
	if (products.isFetching) {
		return false;
	} else if (Object.keys(products.items).length <= 0) {
		return true;
	} else {
		return products.didInvalidate;
	}
}

export function fetchProductsIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchProducts(getState())) {
			return dispatch(fetchProducts(customerId));
		}
	};
}

export function invalidatePromos() {
	return {
		type: PRODUCTS_PROMOS_INVALIDATE
	};
}
export function invalidatePoints() {
	return {
		type: PRODUCTS_POINTS_INVALIDATE
	};
}

function requestPromos() {
	return {
		type: PRODUCTS_PROMOS_REQUEST
	};
}

function requestPoints() {
	return {
		type: PRODUCTS_POINTS_REQUEST
	};
}

function requestSavedPoints() {
	return {
		type: PRODUCTS_SAVED_POINTS_REQUEST
	};
}

function requestEobDetails() {
	return {
		type: PRODUCTS_EOB_DETAILS_REQUEST
	};
}

function receivePromos(data) {
	return {
		type: PRODUCTS_PROMOS_RECEIVE,
		promos: data,
		receivedAt: Date.now()
	};
}

function receivePoints(data) {
	return {
		type: PRODUCTS_POINTS_RECEIVE,
		points: data,
		receivedAt: Date.now()
	};
}

function receiveSavedPoints(data) {
	return {
		type: PRODUCTS_SAVED_POINTS_RECEIVE,
		savedPoints: data,
		receivedAt: Date.now()
	};
}

function receiveEobDetails(data) {
	return {
		type: PRODUCTS_EOB_DETAILS_RECEIVE,
		eobDetails: data,
		receivedAt: Date.now()
	};
}

function rejectPromisePromos(error) {
	return {
		type: PRODUCTS_PROMOS_REQUEST_FAIL,
		error: {
			message: `Fout bij het ophalen van de promos: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function rejectPromisePoints(error) {
	return {
		type: PRODUCTS_POINTS_REQUEST_FAIL,
		error: {
			message: `Fout bij het ophalen van de points: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function rejectPromiseSavedPoints(error) {
	return {
		type: PRODUCTS_SAVED_POINTS_REQUEST_FAIL,
		error: {
			message: `Fout bij het ophalen van de saved points: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}


function fetchPromos(customerId) {
	return dispatch => {
		dispatch(requestPromos());

		return Api.getPromos(customerId)
			.then(data => {
				dispatch(receivePromos(data));
				dispatch(updateOrderProducts({ promos: data }));
			})
			.catch(res => dispatch(rejectPromisePromos(res)));
	};
}

function fetchPoints(){
	return dispatch => {
		dispatch(requestPoints());

		return Api.getPoints()
			.then(data => {
				dispatch(receivePoints(data));
			})
			.catch(res => dispatch(rejectPromisePoints(res)));
	};
}

function fetchSavedPoints(){
	return dispatch => {
		dispatch(requestSavedPoints());

		return Api.getSavedPoints()
			.then(data => {
				dispatch(receiveSavedPoints(data));
			})
			.catch(res => dispatch(rejectPromiseSavedPoints(res)));
	};
}

function fetchEobDetails(customerId) {
	return dispatch => {
		dispatch(requestEobDetails());

		return Api.getEobDetails(customerId)
			.then(data => {
				dispatch(receiveEobDetails(data));
				dispatch(updateOrderProducts({ eobDetails: data }));
			})
			.catch(res => dispatch(rejectPromisePromos(res)));
	};
}

function shouldFetchPromos(state) {
	const promos = state.products.promos;
	if (promos.isFetching) {
		return false;
	} else if (promos.items.length <= 0) {
		return true;
	} else {
		return promos.didInvalidate;
	}
}

function shouldFetchPoints(state) {
	const points = state.products.points;
	if (points.isFetching) {
		return false;
	} else if (points.items.length <= 0) {
		return true;
	} else {
		return points.didInvalidate;
	}
}

function shouldFetchSavedPoints(state) {
	const savedPoints = state.products.savedPoints;
	if (savedPoints.isFetching) {
		return false;
	} else if (savedPoints.items.length <= 0) {
		return true;
	} else {
		return savedPoints.didInvalidate;
	}
}

function shouldFetchEobDetails(state) {
	const eobDetails = state.products.eobDetails;
	if (eobDetails.isFetching) {
		return false;
	} else if (eobDetails.items.length <= 0) {
		return true;
	} else {
		return eobDetails.didInvalidate;
	}
}

export function fetchPromosIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchPromos(getState())) {
			return dispatch(fetchPromos(customerId));
		}
	};
}

export function fetchPointsIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchPoints(getState())) {
			return dispatch(fetchPoints());
		}
	};
}

export function fetchSavedPointsIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchSavedPoints(getState())) {
			return dispatch(fetchSavedPoints());
		}
	};
}

export function fetchEobDetailsIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchEobDetails(getState())) {
			return dispatch(fetchEobDetails(customerId));
		}
	};
}

export function invalidateCustomerProducts() {
	return {
		type: PRODUCTS_CUSTOMER_INVALIDATE
	};
}

function requestCustomerProducts() {
	return {
		type: PRODUCTS_CUSTOMER_REQUEST
	};
}

function receiveCustomerProducts(data) {
	return {
		type: PRODUCTS_CUSTOMER_RECEIVE,
		receivedAt: Date.now(),
		data
	};
}

function rejectPromiseCustomerProducts(error) {
	return {
		type: PRODUCTS_CUSTOMER_REQUEST_FAIL,
		error: {
			message: `Fout bij het ophalen van de producten door de bestelmodule: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function fetchCustomerProducts(customerId) {
	return dispatch => {
		dispatch(requestCustomerProducts());

		return Api.getCustomerProducts(customerId)
			.then(data => {
				dispatch(receiveCustomerProducts(data));
				dispatch(updateOrderProducts({ customerProducts: data.customerProducts, partialProducts: data.products }));
				dispatch(doubleCheckInvalidEobNetDates(Object.values(data.customerProducts)));
			})
			.catch(res => dispatch(rejectPromiseCustomerProducts(res)));
	};
}

function shouldFetchCustomerProducts(state) {
	const products = state.products;

	if (products.isCustomerProductsFetching) {
		return false;
	} else if (!products.lastUpdatedCustomerProducts) {
		return true;
	} else {
		return products.didCustomerProductsInvalidate;
	}
}

export function fetchCustomerProductsIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchCustomerProducts(getState())) {
			return dispatch(fetchCustomerProducts(customerId));
		}
	};
}

export function invalidateMetadata() {
	return {
		type: PRODUCTS_METADATA_INVALIDATE
	};
}

function requestMetadata() {
	return {
		type: PRODUCTS_METADATA_REQUEST
	};
}

function receiveMetadata(data) {
	return {
		type: PRODUCTS_METADATA_RECEIVE,
		metadata: data,
		receivedAt: Date.now()
	};
}

function rejectMetadata(error) {
	return {
		type: PRODUCTS_METADATA_REJECT,
		error: {
			message: `Fout bij het ophalen van de metadata: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function fetchMetadata() {
	return dispatch => {
		dispatch(requestMetadata());

		return Api.getMetadata()
			.then(data => dispatch(receiveMetadata(data)))
			.catch(res => dispatch(rejectMetadata(res)));
	};
}

function shouldFetchMetadata(state) {
	const metadata = state.products.metadata;

	if (metadata.isFetching) {
		return false;
	} else if (!metadata.lastUpdated) {
		return true;
	} else {
		return metadata.didInvalidate;
	}
}

export function fetchMetadataIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchMetadata(getState())) {
			return dispatch(fetchMetadata(customerId));
		}
	};
}

export function invalidateHistory() {
	return {
		type: PRODUCTS_HISTORY_INVALIDATE
	};
}

function requestHistory() {
	return {
		type: PRODUCTS_HISTORY_REQUEST
	};
}

function receiveHistory(data) {
	return {
		type: PRODUCTS_HISTORY_RECEIVE,
		history: data,
		receivedAt: Date.now()
	};
}

function rejectHistory(error) {
	return {
		type: PRODUCTS_HISTORY_REJECT,
		error: {
			message: `Fout bij het ophalen van de history: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function fetchHistory(customerId) {
	return dispatch => {
		dispatch(requestHistory());

		return Api.getProductHistory(customerId)
			.then(data => dispatch(receiveHistory(data)))
			.catch(res => dispatch(rejectHistory(res)));
	};
}

function shouldFetchHistory(state) {
	const history = state.products.history;

	if (history.isFetching) {
		return false;
	} else if (!history.lastUpdated) {
		return true;
	} else {
		return history.didInvalidate;
	}
}

export function fetchHistoryIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchHistory(getState())) {
			return dispatch(fetchHistory(customerId));
		}
	};
}

export function invalidateCampaigns() {
	return {
		type: PRODUCTS_CAMPAIGNS_INVALIDATE
	};
}

function requestCampaigns() {
	return {
		type: PRODUCTS_CAMPAIGNS_REQUEST
	};
}

function receiveCampaigns(data) {
	return {
		type: PRODUCTS_CAMPAIGNS_RECEIVE,
		campaigns: data,
		receivedAt: Date.now()
	};
}

function rejectCampaigns(error) {
	return {
		type: PRODUCTS_CAMPAIGNS_REJECT,
		error: {
			message: `Fout bij het ophalen van de campaigns: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

function fetchCampaigns() {
	return dispatch => {
		dispatch(requestCampaigns());

		return Api.getCampaigns()
			.then(data => {
				dispatch(receiveCampaigns(data));
				dispatch(updateOrderProducts({ campaigns: data }));
			})
			.catch(res => dispatch(rejectCampaigns(res)));
	};
}

function shouldFetchCampaigns(state) {
	const campaigns = state.products.campaigns;

	if (campaigns.isFetching) {
		return false;
	} else if (!campaigns.lastUpdated) {
		return true;
	} else {
		return campaigns.didInvalidate;
	}
}

export function fetchCampaignsIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchCampaigns(getState())) {
			return dispatch(fetchCampaigns());
		}
	};
}
