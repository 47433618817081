const promoTypes = {
	NO_PROMO: 0,
	PERCENT_DISCOUNT: 1,
	FIXED_PRICE: 2,
	FREE_BY_UNIT: 3,
	FREE_BY_WEIGHT: 4,
	PRICE_PER_UNIT: 5,
	PRICE_PER_WEIGHT: 6,
	FREE: 7
};

export default promoTypes;
