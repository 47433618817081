import React from 'react';
import './Error.scss';
import error404circle from '../../images/Bord_404_circle.png';

export default function Error(props) {
	const renderDefaultTitle = () => (
		<h1>Krijg dat op je bord…</h1>
	);

	const renderImage = () => (
		<img src={error404circle} alt='Bord met een boterham' />
	);
	const render403 = error => (
		<div className={'error__content error__403'}>
			{renderDefaultTitle()}
			<div className={'error__message'}>
				Je hebt geen toestemming om deze pagina te bekijken.
			</div>
		</div>
	);

	const render404 = error => (
		<div className={'error__content error__403'}>
			{renderDefaultTitle()}
			<div className={'error__message'}>
				Deze pagina bestaat niet (meer).<br />
				{props.message ?? ''}
			</div>
		</div>
	);

	const renderUnknown = error => (
		<div className={'error__content error__unknown'}>
			{renderDefaultTitle()}
			<div className={'error__message'}>
				Er is een onbekende fout opgetreden.
			</div>
		</div>
	);

	let renderError;

	switch (props.code) {
		case 403:
			renderError = render403;
			break;
		case 404:
			renderError = render404;
			break;
		default:
			renderError = renderUnknown;
			break;
	}

	return (
		<div className={`Error Error--${props.size}`}>
			{props.size === 'medium' && renderImage()}
			{renderError(props)}
		</div>
	);
}
