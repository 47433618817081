import React from 'react';
import './Margins.scss';

import { t } from './../../libraries/i18n';
import { CharlesTitle } from '@abm-international/react-components';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Detail from './Detail';
import Overview from './Overview';
import { useRestrictedContent } from '../../libraries/hooks';
import ForbiddenError from '../../components/Error/ForbiddenError/ForbiddenError';
import DetailOneArticle from './DetailOneArticle';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';

function Margins(props) {
	useCustomerRoute();
	const match = useRouteMatch();
	const [RestrictedContent, RestrictedContentError] = useRestrictedContent(props.requiredPermission);
	return (
		<div className={'Margins'}>
			<RestrictedContent>
				<CharlesTitle>{t('margins.title')}</CharlesTitle>
				<Switch>
					<Route path={`${match.path}/category/:category/:marginType/:productType`}><Detail /></Route>
					<Route path={`${match.path}/article/:articleId`}><DetailOneArticle /></Route>
					<Route path={`${match.path}`}><Overview /></Route>
				</Switch>
			</RestrictedContent>
			<RestrictedContentError>
				<ForbiddenError permissionName={t('margins.MarginsPermission')} />
			</RestrictedContentError>
		</div>
	);
}

export default Margins;
