import * as Api from './../../../libraries/api';
import {
	selectIsTransportDocumentsFetching,
	selectTransportDocuments
} from './../../selectors';

export const TRANSPORT_DOCUMENTS_REQUEST = 'TRANSPORT_DOCUMENTS_REQUEST';
export const TRANSPORT_DOCUMENTS_RECEIVE = 'TRANSPORT_DOCUMENTS_RECEIVE';
export const TRANSPORT_DOCUMENTS_REJECT = 'TRANSPORT_DOCUMENTS_REJECT';
export const TRANSPORT_DOCUMENTS_HANDLE_SEARCH = 'TRANSPORT_DOCUMENTS_HANDLE_SEARCH';

export function handleSearch(query) {
	return {
		type: TRANSPORT_DOCUMENTS_HANDLE_SEARCH,
		query: query
	};
}

function fetchTransportDocuments(customerId) {
	return async dispatch => {
		dispatch({
			type: TRANSPORT_DOCUMENTS_REQUEST
		});

		let data;

		try {
			data = await Api.getTransportDocuments(customerId);
		} catch (error) {
			return dispatch({
				type: TRANSPORT_DOCUMENTS_REJECT
			});
		}

		return dispatch({
			type: TRANSPORT_DOCUMENTS_RECEIVE,
			items: data
		});
	};
}

function shouldFetchTransportDocuments(state) {
	const isFetching = selectIsTransportDocumentsFetching(state);
	const transportDocuments = selectTransportDocuments(state);

	if (isFetching) return false;
	return transportDocuments.length <= 0;
}

export function fetchTransportDocumentsIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchTransportDocuments(getState())) {
			return dispatch(fetchTransportDocuments(customerId));
		}
	};
}
