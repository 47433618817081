import {
	TRANSPORT_DOCUMENTS_REQUEST,
	TRANSPORT_DOCUMENTS_RECEIVE,
	TRANSPORT_DOCUMENTS_REJECT,
	TRANSPORT_DOCUMENTS_HANDLE_SEARCH,
} from './transportDocumentsActions';

export default function transportDocuments(
	state = {
		items: [],
		isFetching: false,
		query: ''
	},
	action
) {
	switch (action.type) {
		case TRANSPORT_DOCUMENTS_REQUEST:
			return {
				...state,
				isFetching: true,
				didFail: false
			};
		case TRANSPORT_DOCUMENTS_RECEIVE:
			return {
				...state,
				isFetching: false,
				items: action.items,
				lastUpdated: Date.now()
			};
		case TRANSPORT_DOCUMENTS_REJECT:
			return {
				...state,
				isFetching: false,
				didFail: true
			};
		case TRANSPORT_DOCUMENTS_HANDLE_SEARCH:
			return {
				...state,
				query: action.query
			};
		default:
			return state;
	}
}
