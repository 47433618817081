import './deliveryDateSelectionStep.scss';
import VacationReminder from './VacationReminder';
import Deadlines from './Deadlines';
import * as DateHelper from './../../../libraries/dateHelper';
import { t } from './../../../libraries/i18n';
import {
	continueWithOrderFromServer,
	createNewOrderAndDiscardOpenOrders,
} from './../orderActions';
import {
	selectDeliveryDate,
	selectIsOrderFromServer,
	selectOpenOrderModifiedBy,
} from '../../selectors';
import DeliveryDatePicker from './../../../components/datePicker/datePicker';
import { useAppDispatch, useAppSelector } from '../../../libraries/hooks/redux';
import { Gtag } from '../../common/utils';

const DeliveryDateSelectionStep = () => {
	const dispatch = useAppDispatch();
	const selectedDate = useAppSelector(selectDeliveryDate);
	const openOrderModifiedBy = useAppSelector(selectOpenOrderModifiedBy);
	const isOrderFromServer = useAppSelector(selectIsOrderFromServer);

	const handleCreateNewOrderAndDiscardOpenOrders = () => {
		Gtag.event({
			category: 'OpenOrder',
			action: 'Delete'
		});
		dispatch(createNewOrderAndDiscardOpenOrders());
	};

	const handleContinueWithOrderFromServer = () => {
		Gtag.event({
			category: 'OpenOrder',
			action: 'Continue'
		});
		dispatch(continueWithOrderFromServer());
	};

	return (
		<div className={'DeliveryDateSelectionStep'}>
			{isOrderFromServer && (
				<div className={'open-order__info'}>
					{openOrderModifiedBy
                            ? t('order.continueOpenOrderByUserQuestion', openOrderModifiedBy)
                            : t('order.continueOpenOrderQuestion')
					}
					<div className={'choices'}>
						{/* <span onClick={handleCreateNewOrderAndDiscardOpenOrders} className={'choice choice--secondary'}>{t('order.continueOpenOrderNo')}</span> */}
						<span onClick={handleContinueWithOrderFromServer} className={'choice choice--primary'}>{t('order.continueOpenOrderYes')}</span>
					</div>
				</div>
			)}

			<div className={`calendar-container ${isOrderFromServer ? 'disabled' : ''}`}>
				<DeliveryDatePicker
					selectedDate={selectedDate}
					disabled={isOrderFromServer}
				/>

				<div className="info__selected">
					<span>{t('order.deliveryDateLabel')}: </span><span className="selected__date">
						{DateHelper.getDateString(selectedDate)} {selectedDate && `(${t('order.weekLabel')} ${DateHelper.getWeekNumber(selectedDate)![1]})`}
					</span>
				</div>
			</div>

			<div className="calendar__info">
				<VacationReminder />
				<Deadlines />
			</div>
		</div>
	);
};

export default DeliveryDateSelectionStep;
