
import {
	DynamicForm,
	Button,
	ButtonLink,
	BackButton
} from '@abm-international/react-components';
import { CRUDDetailConfig, CRUDField } from './crud';
import useSWR from 'swr';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import {
	FaCheck,
	FaPencilAlt,
	FaTimes,
} from 'react-icons/fa';
import { useEffect, useState } from 'react';
import './CRUDDetail.scss';
import { post, get } from '../../../../libraries/api';
import useCustomer from '../../../../libraries/hooks/useCustomer';
import { toast } from 'react-toastify';


const getURLWithParams = (url: string | null, params: Record<string, string>): string | null => {
	if (!url) return null;
	const urlWithParams = (Object.keys(params)).reduce((newUrl: any, param: any) => {
		const p = `{${param}}`;
		return newUrl.replace(new RegExp(p, 'gi'), params[param]);
	}, url);

	return urlWithParams;
};


const getFields = (data: Array<string>, fields: Array<CRUDField>): any =>
	data.map((col: string) => fields.find((field: CRUDField) => field.name === col));


// const deleteItem = async (id: string, userId: any) => {
// 	try {
// 		const { data } = await post(`/api/v1/customers/${userId}/settings/permissions/${id}/delete`);

// 		return {
// 			success: true,
// 			data
// 		};
// 	} catch (error) {
// 		return {
// 			success: false,
// 			error
// 		};
// 	}
// };


const useDetailItem = (url: string | null, params: Record<string, string>) => {
	const urlWithParams = getURLWithParams(url, params);
	const { data, error, mutate } = useSWR(urlWithParams, get);

	return {
		data,
		error,
		mutate
	};
};

const MAINLOGIN: string = '0';
const SUBLOGIN: string = '1';

interface Props {
	mode: string,
	fields: Array<CRUDField>,
	path: string,
	config: CRUDDetailConfig,
	children?: any,
	t: (translationCode: string, params?: any) => string,
	defaultNewValue?: any
}

export default function CRUDDetail(props: Props) {
	const params = useParams<Record<string, string>>();
	const { id } = params;
	const { path } = useRouteMatch();
	const isNew = path.endsWith('/new');
	const isEditing = isNew || path.endsWith('/edit');
	const { data, mutate } = useDetailItem(isNew ? null : props.config.location, params);
	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [saveResult, setSaveResult] = useState<any>(undefined);
	//const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [newData, setNewData] = useState<any>();
	const { t } = props;
	const { defaultNewValue } = props.config;
	const [errors, setErrors] = useState({});
	const { id: userId } = useCustomer();
	const accountType = data?.type;

	const saveItem = async (id: string, item: any, userId: any) => {
		setErrors({});
		try {
			const data = await post(`/api/v1/customers/${userId}/settings/permissions/${id}`, JSON.stringify(item));
			//const { data2 } = await post(`/api/v1/customers/${userId}/settings/permissions/${id}`, JSON.stringify(item));

			setErrors(data.errors);
			if (!data.result) {
				return {
					success: false,
					error: 'Response not valid',
				};
			}
			return {
				success: true
			};
		} catch (error) {
			return {
				success: false,
				error
			};
		}
	};

	const fields: any = accountType !== undefined ? getFields(props.config.fields, props.fields).map((data: any) => {
		if (accountType === MAINLOGIN) {
			if (data.name === 'password') { return { ...data, disabled: false }; }
			return { ...data, disabled: true };
		}
		if (accountType === SUBLOGIN) {
			if (data.name === 'id') { return { ...data, disabled: true }; }
			return { ...data, disabled: false };
		}
		else {
			return { ...data, disabled: true };
		}
	}) : getFields(props.config.fields, props.fields);


	useEffect(() => {
		if (isNew && !newData) setNewData({ ...defaultNewValue });
	}, [isNew, newData, defaultNewValue]);

	useEffect(() => {
		if (isNew) return;

		if ((!hasChanges && !newData) || !isEditing) setNewData(data);
	}, [data, hasChanges, newData, isEditing, isNew]);

	const save = async () => {
		if (!newData) {
			return null;
		}

		setSaveResult(undefined);
		setIsSubmitting(true);
		const key = isNew ? 'new' : id;
		const result = await saveItem(key, newData, userId);
		setSaveResult(result);
		setIsSubmitting(false);

		if (result?.success) {
			toast.success(t('settings.toast_success_data_saved'));
			setTimeout(() => {
				mutate();
				history.push(props.path);
			}, 1000);
		} else {
			toast.error(t('settings.toast_error_data_saved'));
		}
	};

	// const confirmDelete = async () => {
	// 	if (!id) return null;

	// 	setIsSubmitting(true);
	// 	const result = await deleteItem(id, userId);
	// 	setIsSubmitting(false);

	// 	if (result?.success) {
	// 		mutate();
	// 		history.push(props.path);
	// 	}
	// };

	const renderActions = () => (
		<div className='actions'>
			{isEditing &&
				<Button
					color={'green'}
					icon={<FaCheck />}
					onClick={save}
					showSpinner={isSubmitting}
				>
					{t('settings.button_item_save')}
				</Button>
			}

			{!isEditing &&
				<ButtonLink
					to={`${props.path}/${id}/edit`}
					className='back'
					icon={<FaPencilAlt />}
				>
					{t('settings.button_item_edit')}
				</ButtonLink>
			}

			{/* {(!isNew && data?.account === 'main login') &&
				<>
					<Button
						color={'red'}
						icon={<FaTrashAlt />}
						onClick={() => setShowDeleteConfirmation(true)}
					>
						{t('crud.button_item_delete')}
					</Button>
				</>
			} */}

			{!isNew && isEditing &&
				<Button
					color={'orange'}
					icon={<FaTimes />}
					onClick={() => history.push(`${props.path}/${id}`)}
				>
					{t('crud.button_item_cancel_edit')}
				</Button>
			}

			{isNew &&
				<ButtonLink
					color={'orange'}
					icon={<FaTimes />}
					to={props.path}
				>
					{t('crud.button_item_cancel_new')}
				</ButtonLink>
			}
		</div>
	);

	// const renderDeleteConfirmation = () => showDeleteConfirmation && (
	// 	<PortalModal className='delete__confirmation' close={() => setShowDeleteConfirmation(false)}>
	// 		<div className='delete_modal'>
	// 			<div className='modal__text'>
	// 				{t('crud.label_delete_confirmation_question')}
	// 			</div>
	// 			<div className='actions'>
	// 				<Button
	// 					color={'red'}
	// 					onClick={confirmDelete}
	// 					showSpinner={isSubmitting}
	// 					disabled={isSubmitting}
	// 					icon={<FaTrashAlt />}
	// 				>
	// 					{t('crud.button_confirm_delete_item')}
	// 				</Button>
	// 				<Button
	// 					onClick={() => setShowDeleteConfirmation(false)}
	// 					icon={<FaTimes />}
	// 				>
	// 					{t('crud.button_cancel_delete_item')}
	// 				</Button>
	// 			</div>
	// 		</div>
	// 	</PortalModal>
	// );

	const renderSaveResult = () => {
		if (saveResult === undefined) return null;

		if (saveResult instanceof Error) {
			return (
				<div className='save-message error'>
					{t('crud.label_save_error', saveResult.message)}
				</div>
			);
		}

		if (!saveResult?.success) {
			return (
				<div className='save-message error'>
					{t('crud.label_save_error', saveResult.error)}
				</div>
			);
		}

		if (saveResult?.success) {
			return (
				<div className='save-message success'>
					{t('crud.label_save_success')}
				</div>
			);
		}

		return null;
	};

	const renderTitle = () => (
		<>
			<BackButton
				to={props.path}
				className='back'
			>
				{t(`crud.button_to_list${hasChanges && isEditing ? '_cancel' : ''}`)}
			</BackButton>
		</>
	);

	const renderForm = () => (

		<>
			<DynamicForm
				fields={fields}
				sections={props.config.sections ?? false}
				values={newData ?? {}}
				onChange={(d: any) => {
					setNewData(d);
					setHasChanges(true);
				}}
				disabled={!isEditing}
			/>
		</>
	);

	const renderHints = () => (
		<div className='hints'>
			{Object.values(errors)?.map((value: any, index) => {
				return (
					<p key={index}>{t(`settings.${value}`)}</p>
				);
			})}
			{renderSaveResult()}
		</div>
	);

	// const renderModals = () => (
	// 	<>
	// 		{renderDeleteConfirmation()}
	// 	</>
	// );

	if (props.children) {
		const components = [
			renderTitle,
			renderForm,
			renderHints,
			renderActions,
			//renderModals
		];

		return (
			<>
				{props.children(components)}
			</>
		);
	}

	return (
		<div className='CRUDDetail'>
			{renderTitle()}
			{renderForm()}
			{renderHints()}
			{renderActions()}
			{/* {renderModals()} */}
		</div>
	);
}
