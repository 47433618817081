import React, { useMemo, useState } from 'react';
import './TradeFairArticlesList.scss';
import type { Article } from '../../types/articles';
import type { PreOrderAction } from '../../types/preOrders';
import TradeFairArticle from './TradeFairArticle';


type Props = {
	preOrders: Array<PreOrderAction>,
	updateQuantity: any,
	deliveryWeek: string,
};

const useArticles = (preOrders: Props['preOrders']) => {
	const articles = useMemo(() => {
		const articles = preOrders.reduce((articles: Array<Article>, poAction: PreOrderAction) => {
			let newArticles = [...articles];

			poAction.articles.forEach(article => {

				newArticles = [
					...newArticles.filter(a => a.id !== article.id),
					{
						...article,
						sort: poAction?.sort_order,
						weeks: [
							...(articles.find(a => a.id === article.id)?.weeks ?? []),
							{
								week: poAction.delivery,
								//promo: article.promo,
								quantity: poAction.quantities?.[article.id] ?? 0,
								orderedQuantity: article.orderedQuantity,
								freeQuantity: article.freeQuantity,
								action: poAction
							}
						]
					}
				];
			});

			return newArticles;
		}, []);
		return articles.sort((a, b) => a?.sort_order - b?.sort_order);
	}, [preOrders]);

	return articles;
};

export default function TradeFairArticlesList(props: Props) {
	const articles = useArticles(props.preOrders);
	const [scrollPosition, setScrollPosition] = useState(0);

	return (
		<div className='TradeFairArticlesList'>
			{articles.map(article => (
				<TradeFairArticle
					key={article.id}
					article={article}
					updateQuantity={props.updateQuantity}
					scrollPosition={scrollPosition}
					updateScrollPosition={setScrollPosition}
					deliveryWeek={props.deliveryWeek}
				/>
			))}
		</div>
	);
}
