import React from 'react';

import './finishStep.scss';
import { t } from './../../../libraries/i18n';
import { useAppSelector } from '../../../libraries/hooks/redux';
import { useDispatch } from 'react-redux';
import { updateComment, updateReference } from '../orderActions';
import { selectOrderRemarks } from '../../selectors';

export default function FinishStep() {
	const { reference, comment } = useAppSelector(selectOrderRemarks);
	const dispatch = useDispatch();

	return (
		<div className="FinishStep">
			<div className={'info'}>
				{t('order.remarksStepDescription')}
			</div>

			<div className={'form'}>
				<div className={'form__control'}>
					<label htmlFor={'reference'}>{t('order.remarksReferenceLabel')}</label>
					<input
						type="text"
						name={'reference'}
						id={'reference'}
						placeholder={t('order.remarksReferencePlaceholder')}
						value={reference}
						onChange={(e) => dispatch(updateReference(e.target.value))}
					/>
				</div>
				<div className={'form__control'}>
					<label htmlFor={'comments'}>{t('order.remarksCommentLabel')}</label>
					<textarea
						name={'comments'}
						id={'comments'}
						cols={40}
						rows={6}
						placeholder={t('order.remarksCommentPlaceholder')}
						value={comment}
						onChange={(e) => dispatch(updateComment(e.target.value))}
					/>
				</div>
			</div>
		</div>
	);
}
