import React, { Fragment, useCallback, useMemo } from 'react';
import {
	ProjectArticle, Allergens, Badge, ArticleBadges, ArticleImage, ArticleOrderHistory, ArticlePromo
} from '@abm-international/react-components';
import './orderArticle.scss';
import TempNotAvailable from '../../../components/TempNotAvailable/TempNotAvailable';
import CommonQuantityInput from '../CommonQuantityInput';
import Highlight from '../../../components/highlight/highlight';
import { getDateFromReversedDateString, getWeekNumber } from '../../../libraries/dateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faIndustry, faShoppingBasket, faStar, faTruck
} from '@fortawesome/free-solid-svg-icons';


const ASSOONASPOSSIBLEDELIVERYDATE = '01-01-2500';

const ConditionalWrapper = (props) => {
	const { children, condition } = props;

	if (condition) {
		return <div className='bottom-container'>{children}</div>;
	}

	return <>{children}</>;
};

// TODO: Replace hardcorded color values

const getPendingForDeliveryWeek = (pending, deliveryDate) => {
	if (!pending) return 0;
	if (!deliveryDate) deliveryDate = new Date();
	const pendingByWeek = {};
	Object.keys(pending).forEach(datestamp => {
		const date = getDateFromReversedDateString(datestamp);
		const week = getWeekNumber(date)[1];
		pendingByWeek[week] = pending[datestamp] + (pendingByWeek[week] || 0);
	});

	const deliveryWeek = getWeekNumber(new Date(deliveryDate))[1];
	return pendingByWeek[deliveryWeek] || 0;
};

export const getHeight = (view, size, article) => {

	switch (view) {
		case 'grid':
			return 550;

		case 'list-compact':
			// if no promos and no reserved or pending and no quantity or awardedPromo -> return 56
			if (article.promo) return 80;
			if (article.pending && getPendingForDeliveryWeek(article.pending, article.deliveryDate) > 0) return 80;
			if (article.reserved) return 80;
			if (article.campaigns && article.campaigns.length > 0) return 80;
			return 52;

		case 'list':
		default:
			const sizes = {
				size935: 154,
				size770: 132,
				size720: 132,
				size630: 132
			};
			const maximumHeight = 168;
			return sizes[size] ?? maximumHeight;
	}
};

const getDerivedClasses = ({
	hasPromo, isPending, hasReserved, hasCampaigns, format
}) => {
	if (format !== 'list-compact' || hasPromo || isPending || hasReserved || hasCampaigns) {
		return '';
	}
	return 'without-bottom';
};


const OrderArticleList = (props) => {
	const {
		sizes,
		showModal,
		product,
		query,
		hideQuantityInput,
		locale,
		t,
		tempNotAvailable,
		backAvailableFrom,
		eobDetail,
		format,
		...restProps
	} = props;

	// TODO: conditional based on the view/format
	const isTiny = sizes.includes('size630');
	const isSmall = sizes.includes('size770');
	const isNormal = sizes.includes('size935');

	const openModal = useCallback(() => showModal(product), [product, showModal]);

	const totalReserved = useMemo(() => {
		if (!product?.reserved) {
			return undefined;
		}

		const total = Object.values(product.reserved).reduce((acc, reservedAtDate) => acc + reservedAtDate, 0);

		if (!total) {
			return undefined;
		}

		return Number(total);
	}, [product?.reserved]);

	const eobDetailsText = useMemo(() => {
		if (!eobDetail) {
			return '';
		}

		const text = eobDetail.map((eob) => {
			return `${t('order.productReservedEobDetails', eob.deliveryDate !== ASSOONASPOSSIBLEDELIVERYDATE ? eob.deliveryDate : t('order.asSoonAsPossible'), eob.amount)}`;
		}).join('\n');

		return text;
	}, [eobDetail, t]);

	const pendingThisWeek = useMemo(() => {
		return getPendingForDeliveryWeek(product.pending, props.deliveryDate);
	}, [product?.pending, props.deliveryDate]);

	const images = useMemo(() => {
		const _images = [];

		if (!product?.images) {
			return _images;
		}

		['PS', 'SF'].forEach(type => {
			if (product?.images[type]?.length) {
				_images.push({
					size: 400,
					file: product.images[type][0]
				});
			}
		});

		return _images;
	}, [product?.images]);

	const QuantityInput = props.QuantityInput || CommonQuantityInput;
	const Wrapper = format === 'list' ? <div /> : Fragment;

	const isPending = useMemo(() => {
		return product.pending && getPendingForDeliveryWeek(product.pending, props.deliveryDate) > 0;
	}, [product?.pending, props.deliveryDate]);


	// TODO: Flicker on image after change, because of render-function inside ArticleImage
	return (
	// hasPromo, isPending, hasReserved, hasCampaigns, format

		// hasPromo: !!promo,
		// isPending: pending && ProductHelper.getPendingForDeliveryWeek(pending, deliveryDate) > 0,
		// hasReserved: reserved,
		// hasCampaigns: campaigns && campaigns.length > 0
		<ProjectArticle
			className={`Article OrderArticle ${format} ${getDerivedClasses({
				format, hasPromo: !!product.promo, isPending, hasReserved: product?.reserved, hasCampaigns: !!product?.campaigns?.length
			})} ${sizes.join(' ')}`}
			key={`art-list-${ props.product.id}`}
		>
			{format !== 'list-compact' && <ArticleImage location={props.imageSource} alt={product.name} className={'Article__image'} onClick={openModal}>
				{Source => images.map(source =>
					<Source key={JSON.stringify(source)} file={source.file} size={source.size} />
				)}
			</ArticleImage>}

			{(format === 'grid' && (props.awardedPromo || props.quantity || props.awardedPromo?.quantity) ) && <Badge
				secondary={props.awardedPromo && t('order.productPromoAwarded')}
				className={'Article__in-order-indicator reverse'}
				size={format === 'list-compact' || isSmall ? '' : 'big'}
				color={{
					background: '#187D53',
					secondary: '#1c9462',
				}}
			>
				{(props.quantity || props.awardedPromo?.quantity) && <>
					<FontAwesomeIcon icon={faShoppingBasket} />
				</>}
			</Badge>}

			<div className={'detail'}>
				<div className={'top'}>
					<Highlight query={query} className={'Article__id'}>{product.code}</Highlight>
					<Highlight query={query} className={'Article__brand'}>{product.brand}</Highlight>
					<ArticleBadges
						t={t}
						size={isTiny ? 'small' : ''}
						eob={product.eob}
						season={product.season}
						start={product.start}
						end={product.end}
						className={'Article__badges'}
						{...props}
					/>
				</div>

				<div className={'middle'}>
					{product.inBuylist && <FontAwesomeIcon icon={faStar} className={'Article__buylistIndicator'} />}
					<Highlight query={query} className={'Article__name'} onClick={openModal}>{product.name}</Highlight>
				</div>

				<Allergens
					{...props}
					t={t}
					allergens={product.allergens}
					compact={format === 'compact' ? true : isNormal}
					view={format === 'compact' ? '' : isSmall ? 'grid' : ''}
				/>

				{format !== 'list-compact' && <ArticleOrderHistory
					size={format === 'grid' ? '' : 'compact'}
					history={props.history}
					isFetching={props.isHistoryFetching}
					t={t}
					maxWeeks={props.maxWeeks}
					className='Article__history'
				/>}
			</div>

			<ConditionalWrapper condition={format === 'list'}>
				<div className={'order'}>
					{format === 'list-compact' && <ArticleOrderHistory
						size='compact'
						history={props.history}
						isFetching={props.isHistoryFetching}
						t={t}
						maxWeeks={props.maxWeeks}
						className='Article__history'
					/>}

					{!hideQuantityInput &&
						<QuantityInput
							{...props}
							size={(format === 'list-compact' || isSmall) ? 'compact' : ''}
							t={t}
							quantity={props.quantity}
							quantityTimestamp={props.quantityTimestamp}
							awardedPromo={props.awardedPromo?.quantity}
							onQuantityChange={props.product.canOrder ? props.updateProductQuantity : null}
							disabled={!props.product.canOrder}
							orderAmount={props.product.orderAmount}
							madQuantityIndicator={props.showMadQuantityWarning}
							debounce={300}
						/>
					}

					{(hideQuantityInput && tempNotAvailable) && <TempNotAvailable backAvailableFrom={backAvailableFrom} compact={format === 'list-compact'} />}

					{(format !== 'grid' && (props.awardedPromo || props.quantity || props.awardedPromo?.quantity) ) && <Badge
						secondary={props.awardedPromo && t('order.productPromoAwarded')}
						className={'Article__in-order-indicator reverse'}
						size={format === 'list-compact' || isSmall ? '' : 'big'}
						color={{
							background: '#187D53',
							secondary: '#1c9462',
						}}
					>
						{(props.quantity || props.awardedPromo?.quantity) && <>
							<FontAwesomeIcon icon={faShoppingBasket} />
						</>}
					</Badge>}

					{format === 'grid' && <div>
						{!!pendingThisWeek && <Badge
							hint={t('order.productPendingLabel')}
							secondary={pendingThisWeek}
							className={'Article__pending'}
							color='#3885AE'
							size='big'
						>
							<FontAwesomeIcon icon={faTruck} />
						</Badge>}

						{/* TODO: has issues on list */}
						{!!totalReserved && <Badge
							hint={t('order.productReservedLabel') + (eobDetail ? '\n\n' : '') + eobDetailsText}
							secondary={totalReserved}
							className={'Article__reserved'}
							color='#3885AE'
							size='big'
							eobDetails={eobDetail}
							t={t}
						>
							<FontAwesomeIcon icon={faIndustry} />
						</Badge>}
					</div>}
				</div>

				{/* so far so good */}
				<div className={'bottom'}>
					<div>
						{!!product?.campaigns?.length &&
							<div className={'Article__campaigns'}>
								{product.campaigns.map(campaign => {
									if (!campaign?.articles?.badge) {
										return null;
									}

									return (
										<Badge
											key={campaign.id}
											className={'Article__campaigns__item'}
											color={{
												text: campaign?.articles?.badge.color.text,
												background: campaign?.articles?.badge.color.background,
											}}
											size={format === 'list-compact' || isNormal ? 'small' : ''}
										>
											{campaign?.articles?.badge?.text[locale]}
										</Badge>
									);
								})}
							</div>
						}

						{product.promo && <ArticlePromo
							deliveryDate={props.deliveryDate}
							promo={product.promo}
							t={t}
							productId={product.id}
							hideRibbon={format === 'list-compact' || props.hideRibbon}
						/>}
					</div>

					{format !== 'grid' && <div>
						{!!pendingThisWeek && <Badge
							hint={t('order.productPendingLabel')}
							secondary={pendingThisWeek}
							className={'Article__pending'}
							color='#3885AE'
							size={(format === 'list-compact' || isSmall) ? 'small' : ''}
						>
							<FontAwesomeIcon icon={faTruck} />
						</Badge>}

						{/* TODO: has issues on list-compact */}
						{!!totalReserved && <Badge
							hint={t('order.productReservedLabel') + (eobDetail ? '\n\n' : '') + eobDetailsText}
							secondary={totalReserved}
							className={'Article__reserved'}
							color='#3885AE'
							size={(format === 'list-compact' || isSmall) ? 'small' : ''}
							eobDetails={eobDetail}
							t={t}
						>
							<FontAwesomeIcon icon={faIndustry} />
						</Badge>}
					</div>}

					{format === 'list' && <div className={'bottom__right'}>
						{!!(product?.orderedQuantity && product?.freeQuantity) && (
							<div className='ordered'>
								{t('expo.orderedHere', product.orderedQuantity, product.freeQuantity)}
							</div>
						)}

						{(!!product?.orderedQuantity && !product?.freeQuantity) && (
							<div className='ordered'>
								{t('expo.orderedHereNoFree', product.orderedQuantity)}
							</div>
						)}
					</div>}
				</div>
			</ConditionalWrapper>
		</ProjectArticle>
	);
};

export default OrderArticleList;
