import React, { useState } from 'react';
import './SafeImg.scss';
import Spinner from '../spinner/spinner';
import placeholder from '../../images/placeholder.png';

export default function SafeImg({ sources, alt, className }){
	const [indexNotLoadable, setIndexNotLoadable] = useState(-1);
	const [loading, setLoading] = useState(true);

	if (indexNotLoadable >= sources.length - 1 ) {
		return (
			<span className={'SafeImg ImagePlaceholder'}>
				<img
					key={'./../../../images/placeholder.png'}
					className={className}
					src={placeholder}
					alt={alt ?? ''}
				/>
			</span>
		);
	}

	return (
		<span className={'SafeImg'}>
			<img
				key={sources[indexNotLoadable + 1]}
				hidden={loading}
				className={className}
				src={sources[indexNotLoadable + 1]}
				alt={alt ?? ''}
				onLoad={() => setLoading(false)}
				onError={() => setIndexNotLoadable(indexNotLoadable + 1)}
			/>
			{loading && <Spinner size={'small'} />}
		</span>
	);
}
