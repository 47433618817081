import React from 'react';
import PropTypes from 'prop-types';

import './orderArticle.scss';
import OrderArticleList from './list';
export { getHeight } from './list';

export const VIEWS = {
	GRID: 'grid',
	COMPACT: 'list-compact',
	LIST: 'list'
};

const OrderArticle = ({ view, ...props }) => {
	return <OrderArticleList format={view} {...props} />;

	// if (view === VIEWS.LIST) return <OrderArticleList format={view} {...props} />;
	// if (view === VIEWS.COMPACT) return <OrderArticleCompact {...props} />;
	// if (view === VIEWS.GRID) return <OrderArticleGrid {...props} />;
	// return <span>`view ${view} is not implemented`</span>;
};


// {renderItem(view, product, images, totalQuantity, !product.canOrder, history, isHistoryFetching)}
// {buylistActionModalVisible && renderBuylistActionModal()}
// {buylistActionSuccessModalVisible && renderBuylistActionSuccessModal()}
// {buylistActionFailModalVisible && renderBuylistActionFailModal()}

OrderArticle.propTypes = {
	showModal: PropTypes.func,
	updateProductQuantity: PropTypes.func,
	product: PropTypes.shape({
		id: PropTypes.string,
		code: PropTypes.string,
		brand: PropTypes.string,
		canOrder: PropTypes.bool,
		name: PropTypes.string,
		promo: PropTypes.any,
		eob: PropTypes.any,
		season: PropTypes.any,
		start: PropTypes.any,
		end: PropTypes.any,
		inBuylist: PropTypes.bool,
		canRemoveFromBuylist: PropTypes.bool,
		canAddToBuylist: PropTypes.bool,
		campaigns: PropTypes.arrayOf(PropTypes.any),
		madQuantity: PropTypes.number,
		images: PropTypes.shape({
			PS: PropTypes.arrayOf(PropTypes.string),
			SF: PropTypes.arrayOf(PropTypes.string)
		}),
		allergens: PropTypes.arrayOf(PropTypes.oneOf([1, 2, 3, 4, 5, 6, '7.1', '7.2', 8, 9, 10, 11, 12, 13, 14 ])),
	}),
	awardedPromo: PropTypes.any,
	quantity: PropTypes.number,
	view: PropTypes.oneOf(['list', 'list-compact', 'grid']),
	query: PropTypes.string,
	deliveryDate: PropTypes.instanceOf(Date),
	hideQuantityInput: PropTypes.bool,
	tempNotAvailable: PropTypes.bool,
	backAvailableFrom: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	locale: PropTypes.string,
	removeProductFromBuylist: PropTypes.func,
	addProductToBuylist: PropTypes.func,
	isInBuylist: PropTypes.bool,
	t: PropTypes.func,
	imageSource: PropTypes.string,
	history: PropTypes.objectOf(PropTypes.number),
	isHistoryFetching: PropTypes.bool,
	sizes: PropTypes.any,
	showMadQuantityWarning: PropTypes.bool,
	eobDetail: PropTypes.any,
};

export default OrderArticle;
