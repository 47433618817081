
import { FaInfoCircle } from 'react-icons/fa';
import { t } from '../../libraries/i18n';
import Modal from '../../components/modal/modal';
import { useState } from 'react';
import './Disclaimer.scss';

export default function Disclaimer() {
	const [show, setShow] = useState(false);
	return (
		<div className='Disclaimer-holder'>
			<Modal show={show} close={() => setShow(false)}>
				<p>{t('tradefair.disclaimer')}</p>
			</Modal>

			<div
				className='Disclaimer'
				onClick={() => setShow(true)}
			>
				<FaInfoCircle />

			</div>
		</div>
	);
}
