import React from 'react';
import './SplashScreen.scss';
import Spinner from '../spinner/spinner';

import { ReactComponent as CharlesLogo } from './../../images/CharlesLogo.svg';

function SplashScreen() {
	return (
		<div className={'SplashScreen'}>
			<div className={'splash__logo'}>
				<CharlesLogo />
			</div>
			<div className={'splash__spinner'}>
				<Spinner />
			</div>
		</div>
	);
}

export default SplashScreen;
