import { createSelector } from 'reselect';
import { t } from './../../libraries/i18n';
import DateHelper from './../../libraries/dateHelper';

export const selectQuantities = state => state.quantities || {};

export const selectQuantityTimestamps = state => state.quantityTimestamps || {};
export const selectQuantityByProductId = (state, id) => selectQuantities(state)[id];
export const selectQuantityTimestampByProductId = (state, id) => selectQuantityTimestamps(state)[id];
export const selectDeliveryDate = createSelector(
	state => state.deliveryDates.selected,
	DateHelper.fromReduxDateString
);
export const selectDeliveryDates = state => state.deliveryDates.items;
export const selectIsDeliveryDatesFetching = state => state.deliveryDates.isFetching;
const selectOrderComment = state => state.remarks.comment;
const selectOrderReference = state => state.remarks.reference;
export const selectOrderId = state => state.orderId;
export const selectOrderProducts = state => state.orderProducts;
const selectCompanyId = state => state.companyId;
export const selectDefaultFilters = state => state.productSelection.defaultFilters;
const selectCurrentFilters = state => state.productSelection.filters;
export const selectLastSaved = state => state.lastSaved;
export const selectLastUpdated = state => state.lastUpdated;
export const selectHasPendingChanges = state => state.lastSaved < state.lastUpdated;
export const selectSubmitSuccess = state => state.submitSuccess;
export const selectReminders = state => state.reminders;
export const selectAnswers = state => state.review.answers ?? [];
export const selectQuestions = state => state.review.questions;
export const selectInfo = state => state.review.info ?? [];
export const selectReviewError = state => state.review.error;
export const selectDidSaveFail = state => state.didSaveFail;
export const selectFailedSaveAttempts = state => state.failedSaveAttempts;
export const selectNotifications = state => state.review.warnings ?? {};
export const selectFinalSave = state => state.finalSave;
export const selectIsFromServer = state => state.fromServer;
export const selectIsOpenOrdersFetching = state => state.isOpenOrdersFetching;
export const selectEobNetItems = state => state.eob?.items ?? {};
export const selectIsEobFetching = state => state.eob?.isFetching ?? false;
export const selectModifiedBy = state => state.modifiedBy;
export const selectRemarks = state => state.remarks;
export const selectOrderReviewIssues = state => state.review?.issues || [];

export const selectDetails = createSelector(
	selectQuantities,
	selectDeliveryDate,
	selectOrderComment,
	selectOrderReference,
	selectOrderId,
	selectCompanyId,
	selectAnswers,
	(quantities, deliveryDate, comment, reference, orderId, companyId, answers) => {
		return {
			quantities,
			deliveryDate,
			comment,
			reference,
			orderId,
			companyId,
			answers
		};
	}
);

export const selectActiveFilters = createSelector(
	selectDefaultFilters,
	selectCurrentFilters,
	(defaultFilters, currentFilters) => {
		const filters = {};
		Object.keys(defaultFilters).forEach(filterGroup => {
			filters[filterGroup] = {};

			Object.keys(defaultFilters[filterGroup]).forEach(filterName => {
				filters[filterGroup][filterName] = (currentFilters[filterGroup] && currentFilters[filterGroup].hasOwnProperty(filterName))
					? currentFilters[filterGroup][filterName]
					: defaultFilters[filterGroup][filterName];
			});
		});

		return filters;
	}
);

export const selectQuery = state => state.productSelection.query;
export const selectSortKey = state => state.productSelection.sortKey;
export const selectSortDirection = state => state.productSelection.sortDirection;
export const selectIgnoreMadQuantity = state => state.ignoreMadQuantity;
export const selectIgnoreMadQuantityById = (state, id) => state.ignoreMadQuantity[id];

export const selectOrderTotalProducts = state => {
	const quantities = selectQuantities(state);
	return Object.keys(quantities).reduce((accumulator, id) => quantities[id] > 0 ? accumulator + 1 : accumulator, 0);
};

export const selectView = state => state.productSelection.view;
export const selectIsSaving = state => state.isSaving;
export const selectIsSaveFinal = state => state.finalSave;

export const selectAwardedPromos = state => state.awardedPromos;
export const selectAwardedPromoByProductId = (state, id) => selectAwardedPromos(state)[id] ?? undefined;

export const selectInOrderProductIds = createSelector(
	state => state.inOrderProductIds,
	selectAwardedPromos,
	(inOrder, awardedPromos) => [...(inOrder || []), ...Object.keys(awardedPromos)]
);

const selectOrderProductsObject = state => {
	// TODO: products key doesn't match .id of product
	// this code shouldn't exist
	const productsIdAsKey = {};
	const orderProducts = selectOrderProducts(state);
	if (!orderProducts) return {};
	Object.values(orderProducts).forEach(
		product => productsIdAsKey[product.id.toString()] = product
	);
	return productsIdAsKey;
};

export const selectOrderProductById = (state, id) => selectOrderProductsObject(state)[id] ?? {};

export const selectReviewOrderProducts = createSelector(
	selectOrderProductsObject,
	selectAwardedPromos,
	selectQuantities,
	selectQuantityTimestamps,
	selectIgnoreMadQuantity,
	(products, awardedPromos, quantities, quantityTimestamps, ignoreMQ) => {
		const orderProducts = {};

		Object.keys(awardedPromos).forEach(id => {
			if (awardedPromos[id]?.quantity) orderProducts[id] = products[id];
		});
		Object.keys(quantities).forEach(id => orderProducts[id] = products[id]);

		delete orderProducts['0'];

		return Object.values(orderProducts).filter(Boolean).map(product => {
			product.quantity = quantities[product.id];
			product.quantityTimestamps = quantityTimestamps[product.id];
			product.showMadQuantityWarning = product.madQuantity !== null && product.quantity >= product.madQuantity ? !ignoreMQ[product.id] : false;
			product.awardedPromo = awardedPromos[product.id] || undefined;
			return product;
		});
	}
);

export const selectClientNotifications = state => {

	const clientNotifications = [];
	let issue;
	if ((issue = selectIssueWithOneOrMoreLines(state))) clientNotifications.push(issue);
	if ((issue = selectClientLineIssues(state))) clientNotifications.push(issue);

	return clientNotifications;
};

const selectClientLineIssues = state => {
	const products = selectReviewOrderProducts(state);
	if (products.some(product => product.showMadQuantityWarning)) return t('order.confirmMadQuantity');
	return false;
};

const selectIssueWithOneOrMoreLines = state => {
	const ISSUE_NOT_IN_LINE = '0'; // product doesn't exist
	let count = Object.keys(selectNotifications(state)).length;
	if (selectNotifications(state)[ISSUE_NOT_IN_LINE]) count--;
	if (count) return t('order.issueWithOneOrMoreLines');
	return false;
};

export const selectCanEnterStep1 = state => true;
export const selectCanEnterStep2 = createSelector(
	selectCanEnterStep1,
	selectDeliveryDate,
	selectIsFromServer,
	(canEnterStep1, selectedDeliveryDate, fromServer) =>
		canEnterStep1 &&
		selectedDeliveryDate &&
		!fromServer &&
		DateHelper.isSelectedDateValid(selectedDeliveryDate)
);
export const selectCanEnterStep3 = createSelector(
	selectCanEnterStep2,
	selectQuantities,
	(canEnterStep2, quantities) => canEnterStep2 && Object.keys(quantities).length > 0
);
export const selectCanEnterStep4 = createSelector(
	selectCanEnterStep3,
	canEnterStep3 => canEnterStep3
);
export const selectCanEnterStepById = (state, stepId) => {
	if (stepId === 1) return selectCanEnterStep1(state);
	if (stepId === 2) return selectCanEnterStep2(state);
	if (stepId === 3) return selectCanEnterStep3(state);
	if (stepId === 4) return selectCanEnterStep4(state);
	return false;
};
const selectStep1Subtext = state => {
	const selected = selectDeliveryDate(state);
	const week = selected ? DateHelper.getWeekNumber(selected)[1] : '';
	return selected ? `${DateHelper.getDateString(selected)} (${t('order.weekLabel')} ${week})` : '';
};
const selectStep2Subtext = state => {
	//  const productsNum = selectOrderTotalProducts(state);
	// 	// I don't want to put this in selectors.js
	// 	const unitsNum = selectOrderTotalQuantity();
	// 	return productsNum > 0 ? t('order.productSelectionStepSubtext', unitsNum, productsNum) : '';
};
const selectStep3Subtext = state => {
	return selectOrderReference(state) ?? '';
};
const selectStep4Subtext = state => {

};
export const selectStepSubtextById = (state, stepId) => {
	if (stepId === 1) return selectStep1Subtext(state);
	if (stepId === 2) return selectStep2Subtext(state);
	if (stepId === 3) return selectStep3Subtext(state);
	if (stepId === 4) return selectStep4Subtext(state);
};
// export const selectSteps = state => state.steps.map(step => ({
// 	...step,
// 	canEnter: selectCanEnterStepById(state, step.id),
// 	subtext: selectStepSubtextById(state, step.id)
// }));

export const selectSteps = createSelector(
	state => state.steps,
	selectCanEnterStep1,
	selectCanEnterStep2,
	selectCanEnterStep3,
	selectCanEnterStep4,
	selectStep1Subtext,
	selectStep2Subtext,
	selectStep3Subtext,
	selectStep4Subtext,
	(
		steps,
		CanEnterStep1,
		CanEnterStep2,
		CanEnterStep3,
		CanEnterStep4,
		Step1Subtext,
		Step2Subtext,
		Step3Subtext,
		Step4Subtext
	) => {
		return [
			{
				...steps[0],
				canEnter: CanEnterStep1,
				subtext: Step1Subtext,
			},
			{
				...steps[1],
				canEnter: CanEnterStep2,
				subtext: Step2Subtext,
			},
			{
				...steps[2],
				canEnter: CanEnterStep3,
				subtext: Step3Subtext,
			},
			{
				...steps[3],
				canEnter: CanEnterStep4,
				subtext: Step4Subtext,
			},
		];
	}
);

export const selectLastVisitedStep = state => state.lastVisitedStep;
