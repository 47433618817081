import React from 'react';
import './Pillbox.scss';

function Pillbox(props){
	const { handleFilter, filter } = props;

	return (
		<div className={'Pillbox'}>
			{props.pills.map((pill) => (
				<button key={pill.id} onClick={() => handleFilter(pill.id)} className={(filter !== pill.id)?'':'selected'}>{pill.name}</button>
			))}
		</div>
	);

}

export default Pillbox;
