import React, { useEffect, useState } from 'react';
import './invoices.scss';
import Spinner from '../../../components/spinner/spinner';
import Search from '../../../components/Search/Search';
import Filter from './../../../libraries/filter';
import DateHelper from './../../../libraries/dateHelper';
import Highlight from '../../../components/highlight/highlight';
import Api from './../../../libraries/api';
import { FaDownload } from 'react-icons/fa';
import PdfModal from '../../../components/PdfModal/PdfModal';
import { useMediaQuery } from 'react-responsive';
import { t } from './../../../libraries/i18n';
import { useRestrictedContent } from './../../../libraries/hooks';
import ForbiddenError from './../../../components/Error/ForbiddenError/ForbiddenError';
import {
	handleSearch,
	fetchInvoicesIfNeeded,
} from './invoicesActions';
import {
	selectCustomerId,
	selectInvoicesData,
	selectLocale
} from './../../selectors';
import { useAppSelector, useWithDispatch } from '../../../libraries/hooks/redux';
import { useDispatch } from 'react-redux';


function Invoices(props) {
	const { requiredPermission } = props;
	const withDispatch = useWithDispatch();
	const dispatch = useDispatch();
	const customerId = useAppSelector(selectCustomerId);
	const invoicesData = useAppSelector(selectInvoicesData);
	const locale = useAppSelector(selectLocale);

	useEffect(() => {
		dispatch(fetchInvoicesIfNeeded(customerId));
	}, [dispatch, customerId]);

	const [pdfModal, setPdfModal] = useState({ open: false });
	const showSmallView = useMediaQuery({ query: '(max-width: 650px)' });
	const [RestrictedContent, RestrictedContentError] = useRestrictedContent(requiredPermission);

	const formattedAmount = amount => amount ? `€ ${Number(amount).toLocaleString()}` : '/';

	const getInvoicesGroups = (invoices) => {
		const groups = [];

		invoices.forEach(invoice => {
			if (!groups.includes(invoice.month)) groups.push(invoice.month);
		});

		return groups.map(group => invoices.filter(invoice => invoice.month === group));
	};

	const filterInvoices = (invoices) => {
		invoices = invoices.map(invoice => {
			const date = new Date(invoice.date);
			const month = DateHelper.getMonthName(date.getMonth(), locale);
			const year = date.getFullYear();
			invoice.month = `${month} ${year}`;
			return invoice;
		});

		invoices = Filter.filterItemsByQuery(
			invoicesData.query,
			invoices,
			[
				{ name: 'date' },
				{ name: 'number' },
				{ name: 'orderNumber' },
				{ name: 'month' }
			],
			true,
			'AND'
		);

		invoices = invoices.sort((a, b) => {
			const dateDiff = new Date(b.date) - new Date(a.date);
			if (dateDiff > 0) return 1;
			if (dateDiff < 0) return -1;
			return b.number - a.number;
		});
		return invoices;
	};

	const renderSearch = () => {
		return (
			<Search
				handleSearch={withDispatch(handleSearch)}
				query={invoicesData.query}
				placeholder={t('invoices.searchPlaceholder')}
			/>
		);
	};

	const openInvoicePdfModal = invoice => {
		setPdfModal({
			open: true,
			url: Api.getInvoicePdfUrl(customerId, invoice.number, invoice.date),
			downloadUrl: Api.getInvoicePdfUrl(customerId, invoice.number, invoice.date, true)
		});
	};

	const renderPdfModal = () => (
		<PdfModal
			file={pdfModal.url}
			downloadUrl={pdfModal.downloadUrl}
			close={() => setPdfModal({ open: false })}
		/>
	);

	const renderInvoice = invoice => {
		return (
			<div key={invoice.date + invoice.number} className={'list__item'} onClick={() => openInvoicePdfModal(invoice)}>
				<div className={'item__date'}>
					<Highlight text={invoice.date} string={invoicesData.query}> {invoice.date} </Highlight>
				</div>
				<div className={'item__number'}>
					<Highlight text={invoice.number} string={invoicesData.query} > {invoice.number}</Highlight>
				</div>
				<div className={'item__order-number'}>
					<Highlight text={invoice.orderNumber} string={invoicesData.query} >{invoice.orderNumber}</Highlight>
				</div>
				<div className={'item__amount'}>
					{formattedAmount(invoice.amount)}
				</div>
				<div className={'item__invoice'}>
					<a href={Api.getInvoicePdfUrl(customerId, invoice.number, invoice.date, true)} onClick={e => e.stopPropagation()}>
						<FaDownload />
					</a>
				</div>
			</div>
		);
	};

	const renderInvoicesGroups = (invoices) => {
		const groupedInvoices = getInvoicesGroups(invoices);

		return (
			groupedInvoices.map((invoices, key) => (
				<div key={key} className={'invoice__group'}>
					<div className={'group__header'}>
						<Highlight text={`${DateHelper.getMonthName(new Date(invoices[0].date).getMonth())} ${new Date(invoices[0].date).getFullYear()}`} string={invoicesData.query} >
							{`${DateHelper.getMonthName(new Date(invoices[0].date).getMonth())} ${new Date(invoices[0].date).getFullYear()}`}
						</Highlight>
						<span className={'group__num'}>
							({invoices.length})
						</span>
					</div>
					<div className={'invoices__list'}>
						{renderInvoicesList(invoices)}
					</div>
				</div>
			))
		);
	};

	const renderInvoicesList = (invoices) => {
		return (
			<ul className={'list__items'}>
				{invoices.map(renderInvoice)}
			</ul>
		);
	};

	const renderInvoicesSmallView = invoices => {
		return (
			<div className={'invoices__small-view'}>
				{invoices.map((invoice, key) => (
					<div key={key} className={'invoice__small-item'} onClick={() => openInvoicePdfModal(invoice)}>
						<div className={'item__left'}>
							<div className={'item__number'}>
								<Highlight text={invoice.number} string={invoicesData.query} />
							</div>

							<div className={'item__order-number'}>
								({t('invoices.orderReferenceLabelShort')} <Highlight text={invoice.orderNumber} string={invoicesData.query} />)
							</div>

							<div className={'item__date'}>
								<Highlight text={invoice.date} string={invoicesData.query} />
							</div>
						</div>
						<div className={'item__right'}>
							<div className={'item__amount'}>
								{formattedAmount(invoice.amount)}
							</div>

							<div className={'item__download'}>
								<a href={Api.getInvoicePdfUrl(customerId, invoice.number, invoice.date, true)}>
									<FaDownload />
								</a>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	const renderInvoicesNormalView = invoices => {
		return (
			<div className={'invoices__view'}>
				<div className={'view__details'}>
					<div className={'results'}>
						{t('invoices.numberOfInvoicesFound', invoices.length)}
					</div>
				</div>
				<div className={'list__header'}>
					<div className={'header__id'}>
						{t('invoices.dateLabel')}
					</div>
					<div className={'header__description'}>
						{t('invoices.invoiceNumberLabel')}
					</div>
					<div className={'header__order-number'}>
						{t('invoices.ourOrderReferenceLabel')}
					</div>
					<div className={'header__amount'}>
						{t('invoices.amountLabel')}
					</div>
				</div>

				<div className={'invoices__list'}>
					{renderInvoicesGroups(invoices)}
				</div>
			</div>
		);
	};

	const renderInvoices = () => {
		const invoices = filterInvoices(invoicesData.items);

		if (showSmallView) return renderInvoicesSmallView(invoices);
		else return renderInvoicesNormalView(invoices);
	};

	return (
		<div className={'Invoices'}>
			<RestrictedContentError>
				<ForbiddenError permissionName={t('invoices.viewInvoicesPermission')} />
			</RestrictedContentError>

			<RestrictedContent>
				{renderSearch()}

				{invoicesData.isFetching && (
					<Spinner />
				)}

				{invoicesData.didFail && (
					<p>{t('invoices.genericError')}</p>
				)}

				{!invoicesData.items && !invoicesData.isFetching && !invoicesData.didFail && (
					<p>{t('invoices.noInvoicesFound')}</p>
				)}

				{invoicesData.items && !invoicesData.isFetching && (
					renderInvoices()
				)}

				{pdfModal.open && renderPdfModal()}
			</RestrictedContent>
		</div>
	);
}

export default Invoices;
