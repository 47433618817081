import React from 'react';
import './auth.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import Login from './login/Login';
import { ReactComponent as CharlesLogo } from '../../images/CharlesLogo.svg';
import { Logout } from './logout/Logout';
import { useAppDispatch } from '../../libraries/hooks/redux';
import { setLocale } from '../customer/customerActions';
import useSWR from 'swr';
import { fetcher } from '../../api';
import { t } from '../../libraries/i18n';

const useGlobalMaintenanceMode = () => {
	const { data } = useSWR('/api/v1/global/maintenance', fetcher);
	//	const data = await Api.getPreOrdersData(customerId, preOrderType);

	return {
		data
	};
};
export const Auth = () => {
	const locales = ['nl', 'fr'];
	const dispatch = useAppDispatch();
	const { data }= useGlobalMaintenanceMode();

	const renderLocale = locale => (
		<button key={locale} onClick={() => dispatch(setLocale(locale))}>
			{locale}
		</button>
	);

	return (
		<div className={'Auth'}>
			<CharlesLogo className={'charles-logo'} />
			{data?.announcement==='1'&&(
				<>
					<div className='maintenance-mode-message'>
						<h1>{t('common.login_maintenance_announcement_title')}</h1>
						<h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('common.login_maintenance_announcement_body').replaceAll(/\n/gm, '<br>')) }} />
					</div>
				</>)
			}
			{data?.maintenance==='1'&&(
				<>
					<div className='maintenance-mode-message'>
						<h1>{t('common.maintenance_title')}</h1>
						<h2>{t('common.maintenance_subtitle')}</h2>
					</div>
				</>)
			}
			<div className={'auth-form'}>
				<Switch>
					<Route exact path='/auth/login' component={Login} />
					<Route exact path='/auth/logout' component={Logout} />
					<Route path='/' render={() => (
						<Redirect to='/auth/login' />
					)} />
				</Switch>


			</div>
			<div className={'auth__locale'}>
				{locales && locales.map(renderLocale)}
			</div>
			{/*<VersionNumber />*/}
		</div>
	);
};

export default Auth;
