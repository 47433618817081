import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCustomer, selectCustomerAccount } from '../../modules/selectors';

export default function useCustomerRoute() {
	const location = useLocation();
	const history = useHistory();
	const customer = useSelector(selectCustomer, shallowEqual);
	const account = useSelector(selectCustomerAccount);

	// Go to customer select if none is set
	useEffect(() => {
		if (customer === '') history.push(`/customers?from=${location.pathname}`);
	}, [customer, history, location, account]);
}
