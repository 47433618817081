export const PERMISSIONS = {
	BUYLIST_ADMIN: 2032,
	VIEW_INVOICES: 1021,
	VIEW_TRANSPORT_DOCUMENTS: 1022,
	ORDER: 2011,
	REPORTS: 1201,
	PRICE_CHANGES: 1020,
	MARGINS: 2031,
	PREORDERS: 2011
};
