import React, { Component } from 'react';
import './checkbox.scss';

class Checkbox extends Component {
	getClassesString = () => {
		const classes = ['OGCheckbox'];
		if (this.props.className) classes.push(this.props.className);
		if (this.props.radio) classes.push('radio');
		if (this.props.inverted) classes.push('inverted');
		if (typeof this.props.checked === 'string') classes.push(this.props.checked);
		return classes.join(' ');
	};

	onChange = (e) => {
		console.log('This checkbox has no onChange event. :\'(');
	};

	render() {
		const checkedState = typeof this.props.checked !== 'string' ? this.props.checked : false;

		return (
			<div className={this.getClassesString()}>
				<input
					type='checkbox'
					id={this.props.id}
					checked={checkedState}
					onChange={this.props.onToggle || this.onChange}
					disabled={this.props.disabled}
				/>
				<label className={`label state--${checkedState}`} htmlFor={this.props.id}>
					{this.props.radio ? (
						<div className={`radio state--${checkedState}`}>
							<span className={'outer'}></span>
							<span className={'inner'}></span>
						</div>
					) : (
						<div className={`checkbox state--${checkedState}`}>
							<span className={'box'}></span>
							<span className={'checkmark'}></span>
						</div>
					)}

					{this.props.children}
				</label>
			</div>
		);
	}
}

export default Checkbox;
