import UserPreferences from './../libraries/userPreferences';

// TODO: initialState is deprecated.
// The initial state should be set in the individual
// reducers responsible for their slice of the state.
//
// Do not add new things.
//
// Remove slices of the state in this file that get
// set by calling their reducers.
const initialState = {
	products: {
		isFetching: false,
		didInvalidate: false,
		items: {},
		promos: {
			isFetching: false,
			didInvalidate: false,
			items: []
		},
		points: {
			isFetching: false,
			didInvalidate: false,
			items: []
		},
		eobDetails: {
			isFetching: false,
			didInvalidate: false,
			items: []
		},
		metadata: {
			isFetching: false,
			didInvalidate: false
		},
		history: {
			isFetching: false,
			didInvalidate: false
		},
		campaigns: {
			isFetching: false,
			didInvalidate: false,
			items: []
		}
	},
	order: {
		orderId: null,
		fromServer: null,
		steps: [
			{
				translation: 'order.step1',
				route: '/order/delivery-date',
				id: 1
			},
			{
				translation: 'order.step2',
				route: '/order/products',
				id: 2
			},
			{
				translation: 'order.step3',
				route: '/order/remarks',
				id: 3
			},
			{
				translation: 'order.step4',
				route: '/order/review',
				id: 4
			}
		],
		productSelection: {
			currentPage: 1,
			itemsPerPage: 12,
			query: '',
			view: UserPreferences.get(UserPreferences.PREFERENCES.PRODUCTS_VIEW) || 'list',
			sortKey: UserPreferences.get(UserPreferences.PREFERENCES.PRODUCTS_SORT_KEY) || null,
			sortDirection: UserPreferences.get(UserPreferences.PREFERENCES.PRODUCTS_SORT_DIRECTION) || 'ascending',
			filters: {
				main: {},
				campaigns: {},
				allergens: {},
				brands: {},
				shopcodes: {},
				productRange: {},
				endofyear2020: {}
			},
			defaultFilters: {
				main: {
					eob: 'off',
					promo: 'off',
					season: 'off',
					inOrder: 'off',
					pending: 'off',
					reserved: 'off',
					timeSensitive: 'off'
				},
				campaigns: {},
				allergens: {
					1: false,
					2: false,
					3: false,
					4: false,
					5: false,
					6: false,
					'7.1': false,
					'7.2': false,
					8: false,
					9: false,
					10: false,
					11: false,
					12: false,
					13: false,
					14: false
				},
				brands: {
					'CHARLES': false,
				},
				productRange: {
					fullRange: false,
					shopcode: ''
				},
				endofyear2020: { group: null }
			}
		},
		review: {
			reviewProducts: [],
			warnings: {},
			questions: []
		},
		remarks: {
			comment: '',
			reference: ''
		},
		awardedPromos: {},
		isSaving: false,
		lastSaved: 0,
		reminders: [],
		showScores: false,
		orderProducts: {},
		failedSaveAttempts: 0
	},
	documents: {
		invoices: {
			items: null,
			query: ''
		}
	},
	customer: {
		isFetching: false,
		error: null,
	},
	buylist: {
		query: '',
		view: UserPreferences.get(UserPreferences.PREFERENCES.PRODUCTS_VIEW) || 'list',
		sortKey: UserPreferences.get(UserPreferences.PREFERENCES.PRODUCTS_SORT_KEY) || null,
		sortDirection: UserPreferences.get(UserPreferences.PREFERENCES.PRODUCTS_SORT_DIRECTION) || 'ascending',
		filters: {
			main: {},
			allergens: {},
			custom: {},
			productRange: {},
		},
		defaultFilters: {
			productRange: {
				fullRange: false,
				shopcode: ''
			},
			main: {
				eob: 'off',
				season: 'off',
				pending: 'off',
				reserved: 'off'
			},
			campaigns: {},
			allergens: {
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
				6: false,
				'7.1': false,
				'7.2': false,
				8: false,
				9: false,
				10: false,
				11: false,
				12: false,
				13: false,
				14: false
			},
			brands: {
				'CHARLES': false,
			}
		}
	}
};

export default initialState;
