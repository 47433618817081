/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
	DynamicForm
} from '@abm-international/react-components';
import { Button } from '@abm-international/react-components';
import { t } from '../../../libraries/i18n';
import api from '../../../libraries/api';
import useCustomer from '../../../libraries/hooks/useCustomer';
import { createOldNewObject, objectDiff } from '../../common/utils';
import { isEmpty } from 'lodash-es';
import { toast } from 'react-toastify';
import { getFields } from './fields';

type Props = {
	data: {
		nameInvoices: string,
		customerIdInvoices: string,
		vatNumberInvoices: string,
		addressInvoices: string,
		postalCodeInvoices: string,
		cityInvoices: string,
		nameDelivery: string,
		addressDelivery: string,
		postalCodeDelivery: string,
		cityDelivery: string,
		namePortal: string,
		phone1Portal: string,
		phone2Portal: string,
		emailPortal: string,
		accounting_sameAsPortalContact: boolean,
		accounting_name: string,
		accounting_phone1: string,
		accounting_phone2: string,
		accounting_fax: string,
		accounting_email: string,
		sales_sameAsPortalContact: boolean,
		sales_name: string,
		sales_phone1: string,
		sales_phone2: string,
		sales_fax: string,
		sales_email: string,
		marketing_sameAsPortalContact: boolean,
		marketing_name: string,
		marketing_phone1: string,
		marketing_phone2: string,
		marketing_fax: string,
		marketing_email: string,
	} | null
}

export default function ContactForm({ data }: Props) {

	const [newData, setNewData] = useState(data);
	const [hasChanges, setHasChanges] = useState(false);
	const { id } = useCustomer();

	const titleAndFields = (titleTranslation: string, fieldsInit: string[], fieldsExtra: string[], sameAsPortal: boolean): { title: string, fields: string[] } => {
		const title = t(titleTranslation);
		const fields = fieldsInit;

		if (!sameAsPortal) {
			fields.push(...fieldsExtra);
		}
		return {
			title,
			fields,
		};

	};

	const sections = () => ([
		{
			title: t('settings.contact_Facturatie'),
			fields: [
				'customerIdInvoices',
				'vatNumberInvoices',
				'nameInvoices',
				'addressInvoices',
				'postalCodeInvoices',
				'cityInvoices',
			]
		},
		{
			title: t('settings.contact_Levering'),
			fields: [
				'nameDelivery',
				'addressDelivery',
				'postalCodeDelivery',
				'cityDelivery',
			]
		},
		{
			title: t('settings.contact_Portal'),
			fields: [
				'namePortal',
				'phone1Portal',
				'phone2Portal',
				'emailPortal',
			]
		},
		titleAndFields('settings.contact_Boekhouding',
			['accounting_sameAsPortalContact', ],
			['accounting_name',
				'accounting_phone1',
				'accounting_phone2',
				'accounting_fax',
				'accounting_email', ],
			newData?.accounting_sameAsPortalContact || false),
		titleAndFields('settings.contact_Verkoop',
			['sales_sameAsPortalContact', ],
			['sales_name',
				'sales_phone1',
				'sales_phone2',
				'sales_fax',
				'sales_email'],
			newData?.sales_sameAsPortalContact || false),
		titleAndFields('settings.contact_Marketing',
			['marketing_sameAsPortalContact', ],
			['marketing_name',
				'marketing_phone1',
				'marketing_phone2',
				'marketing_fax',
				'marketing_email'],
			newData?.marketing_sameAsPortalContact || false),
	]);

	const parseDataNl = (d: Props['data']) => {
		return {
			facturatie_naam: d?.nameInvoices,
			facturatie_klant_nummer: d?.customerIdInvoices,
			facturatie_BTW_nummer: d?.vatNumberInvoices,
			facturatie_adres: d?.addressInvoices,
			facturatie_postcode: d?.postalCodeInvoices,
			facturatie_gemeente: d?.cityInvoices,
			levering_naam: d?.nameDelivery,
			levering_adres: d?.addressDelivery,
			levering_postcode: d?.postalCodeDelivery,
			levering_gemeente: d?.cityDelivery,
			portal_naam: d?.namePortal,
			portal_telefoon1: d?.phone1Portal,
			portal_telefoon12: d?.phone2Portal,
			portal_email: d?.emailPortal,
			boekhouding_naam: d?.accounting_name,
			boekhouding_telefoon1: d?.accounting_phone1,
			boekhouding_telefoon2: d?.accounting_phone2,
			boekhouding_fax: d?.accounting_fax,
			boekhouding_email: d?.accounting_email,
			verkoop_naam: d?.sales_name,
			verkoop_telefoon1: d?.sales_phone1,
			verkoop_telefoon2: d?.sales_phone2,
			verkoop_fax: d?.sales_fax,
			verkoop_email: d?.sales_email,
			marketing_naam: d?.marketing_name,
			marketing_telefoon1: d?.marketing_phone1,
			marketing_telefoon12: d?.marketing_phone2,
			marketing_fax: d?.marketing_fax,
			marketing_email: d?.marketing_email,
		};
	};

	const save = async () => {
		if (!data || !newData) return;
		const nlData = parseDataNl(data);

		const nlNewData = parseDataNl(newData);

		const changedValues: Partial<Props['data']> = objectDiff(nlData, nlNewData);

		if (isEmpty(changedValues)) {
			toast.info(t('settings.toast_no_changes'));
		} else {
			try {
				const changedData = createOldNewObject(changedValues, nlData);
				await api.post(`/api/v1/customers/${id}/settings/contact`, JSON.stringify({ changedData }));
				toast.success(t('settings.toast_success_mail_send'));
			} catch (error) {
				console.log(error);
				return;
			}
		}
		setHasChanges(false);
	};

	const checkIfSameAsPortal = (d: Props['data']) => {
		if (!data || !d) return;
		if (d?.accounting_sameAsPortalContact) {
			d.accounting_name = d.namePortal;
			d.accounting_phone1 = d.phone1Portal;
			d.accounting_phone2 = d.phone2Portal;
			d.accounting_email = d.emailPortal;
		}
		if (d?.sales_sameAsPortalContact) {
			d.sales_name = d.namePortal;
			d.sales_phone1 = d.phone1Portal;
			d.sales_phone2 = d.phone2Portal;
			d.sales_email = d.emailPortal;
		}
		if (d?.marketing_sameAsPortalContact) {
			d.marketing_name = d.namePortal;
			d.marketing_phone1 = d.phone1Portal;
			d.marketing_phone2 = d.phone2Portal;
			d.marketing_email = d.emailPortal;
		}
		return d;
	};

	return (
		<div className='ContactInvoicing card' id='invoicing'>
			<DynamicForm
				fields={getFields()}
				values={newData ?? {}}
				sections={sections()}
				onChange={(d: Props['data']) => {
					const newD = checkIfSameAsPortal(d);
					if (newD) {
						setNewData(newD);
					}
					setHasChanges(true);
				}}
			/>
			<div className='SaveAndChanges'>
				<Button primary onClick={save}>{t('settings.send')}</Button>
				{hasChanges && <p>{t('settings.unsaved_settings')}</p>}
			</div>

		</div>
	);
}
