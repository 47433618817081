import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../libraries/hooks/redux';
import useCustomer from '../../libraries/hooks/useCustomer';
import { fetchPreOrdersDataIfNeeded } from '../../modules/PreOrders/preOrdersActions';
import { selectPreOrdersActions } from '../../modules/selectors';
import type { Booth } from '../../types/tradeFair';
import { BEURS_2024_PREORDER_TYPE } from './useBooths';

export default function useBoothPreOrders(boothId: Booth['boothId']) {
    const preOrdersActions = useAppSelector(state => selectPreOrdersActions(state, BEURS_2024_PREORDER_TYPE));
    const dispatch = useDispatch();
    const { locale } = useCustomer();

    useEffect(() => {
        dispatch(fetchPreOrdersDataIfNeeded(BEURS_2024_PREORDER_TYPE));
    }, [dispatch]);

    return {
        preOrderType: BEURS_2024_PREORDER_TYPE,
        preOrders: preOrdersActions
            .filter(action => action.boothId === boothId)
            .map(poAction => ({
                ...poAction,
                name: poAction.description?.[locale as 'nl' | 'fr'] ?? ''
            })) ?? []
    };
}
