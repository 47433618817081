export const BUYLIST_HANDLE_SEARCH = 'BUYLIST_HANDLE_SEARCH';
export const BUYLIST_CHANGE_VIEW_TYPE = 'BUYLIST_CHANGE_VIEW_TYPE';
export const BUYLIST_TOGGLE_FILTER = 'BUYLIST_TOGGLE_FILTER';
export const BUYLIST_SET_FILTER = 'BUYLIST_SET_FILTER';
export const BUYLIST_CLEAR_FILTERS = 'BUYLIST_CLEAR_FILTERS';
export const BUYLIST_CHANGE_SORTING = 'BUYLIST_CHANGE_SORTING';

export function handleSearch(query) {
	return {
		type: BUYLIST_HANDLE_SEARCH,
		query: query
	};
}

export function changeProductView(view) {
	return {
		type: BUYLIST_CHANGE_VIEW_TYPE,
		view: view
	};
}

export function toggleFilter(filter) {
	return {
		type: BUYLIST_TOGGLE_FILTER,
		filter: filter
	};
}

export function setFilter(filter, state) {
	return {
		type: BUYLIST_SET_FILTER,
		filter: filter,
		state: state
	};
}

export function clearFilters(group) {
	return {
		type: BUYLIST_CLEAR_FILTERS,
		group: group
	};
}

export function changeSorting(sortKey, direction) {
	return {
		type: BUYLIST_CHANGE_SORTING,
		payload: {
			sortKey,
			direction
		}
	};
}
