import React from 'react';
import { List, ButtonLink } from '@abm-international/react-components';
import { CRUDField, CRUDListConfig } from './crud';
import useSWR from 'swr';
import { useHistory, useRouteMatch } from 'react-router';
import './CRUDList.scss';
import { FaPlus } from 'react-icons/fa';
import { get } from '../../../../libraries/api';
import { formatDistanceToNow } from 'date-fns';
import { nl, fr } from 'date-fns/locale';
import { getLocale } from '../../../../libraries/i18n';

const getColumnRenderer = (name: any | string): any => {

	const locales: { [key: string]: Locale } = { nl, fr };

	const currentLocal: string = getLocale();
	const locale = locales[currentLocal];
	switch (name) {
		case 'lastLoggedIn': return (item: any, key: string) => item[key] ? formatDistanceToNow(new Date(item[key]), { locale }) : '';
		default:
			return (item: any, key: string) => item[key];
	}
};

const getColumns = (data: Array<string>, fields: Array<CRUDField>): any =>
	data.map((col: string) => {
		const field = fields.find((field: CRUDField) => field.name === col);
		if (!field) return undefined;

		return {
			key: field.name,
			title: field.title,
			render: (item: any) => getColumnRenderer(field.name)(item, field.name)
		};
	});

const useListItems = (url: string) => {
	const { data, error } = useSWR(url, get);
	let errorData;

	if (data?.error_code) {
		errorData = data;
	}

	return {
		items: errorData ? undefined : data,
		error: errorData ?? error
	};
};

interface Props {
	fields: Array<CRUDField>,
	config: CRUDListConfig,
	t: (translationCode: string, params?: any) => string
}

export default function CRUDList(props: Props) {
	const { items, error } = useListItems(props.config.fetch);

	const history = useHistory();
	const { path } = useRouteMatch();
	const { t } = props;

	const renderList = () => (
		<List
			header={getColumns(props.config.columns, props.fields,)}
			content={items.users}
			action={(e: any, item: any) => {
				e.stopPropagation();
				history.push(`${path}/${item.id}`);
			}}
		/>
	);

	const renderError = () => (
		<div className='message error'>
			<span role='img' aria-label='error emoji'>💥</span>
			<p>{error?.error_message ?? t('crud.error_fetching_list_items')}</p>
		</div>
	);

	return (
		<div className='CRUDList'>
			<ButtonLink
				className={'new-item'}
				icon={<FaPlus />}
				to={`${path}/new`}
				primary
			>
				{t('crud.button_new_item')}
			</ButtonLink>

			{error && renderError()}
			{items && renderList()}
		</div>
	);
}
