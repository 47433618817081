import React, { useEffect, useState, useMemo } from 'react';
import './reviewOrderStep.scss';
import { Button } from '@abm-international/react-components';
import Spinner from '../../../components/spinner/spinner';
import { t } from './../../../libraries/i18n';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import ProductsSummary from '../../../components/productsViews/productsSummary/productsSummary';
import DateHelper from './../../../libraries/dateHelper';
import OrderReminder from './../OrderReminder/OrderReminder';
import { useDispatch, useSelector } from 'react-redux';
import { createNewOrder, fetchEobNetForProductsInOrderIfNeeded, setOrderAnswers } from './../orderActions';
import Modal from '../../../components/modal/modal';
import ProductDetail from '../../../components/productsViews/productDetail/productDetail';
import ProductPicker from '../../../components/ProductPicker/ProductPicker';
import { selectIsEobFetching } from '../../selectors';
import { useWithDispatch, useAppSelector } from '../../../libraries/hooks/redux';
import {
	saveOrderIfNeeded,
	updateProductQuantity,
	deleteProductFromOrder,
	confirmMadQuantity
} from './../orderActions';
import {
	selectPromos,
	selectOrderDetails,
	selectIsOrderSubmittable,
	selectOrderTotalQuantityBreakdown,
	selectOrderTotalProducts,
	selectOrderQuantities,
	selectReviewOrderProducts,
	selectDeliveryDate,
	selectClientNotifications,
	selectNotifications,
	selectOrderHasPendingChanges,
	selectOrderSubmitSuccess,
	selectOrderQuestions,
	selectOrderReminders,
	selectDeliveryAddress,
	selectInvoiceAddress,
	selectIsOrderSaving,
	selectOrderInfo
} from './../../selectors';
import { usePoints } from '../../../libraries/hooks/usePoints';
import { tradeFairDates } from '../../../constants';
import { Coin } from '@abm-international/react-components';

export default function ReviewOrderStep() {
	const dispatch = useDispatch();
	const withDispatch = useWithDispatch();
	const [showModal, setShowModal] = useState(false);
	const [modalProductId, setModalProductId] = useState();
	const isFetchingEobNet = useSelector(selectIsEobFetching);
	const [filterArticleIssues, setFilterArticleIssues] = useState(false);

	const lines = useAppSelector(selectReviewOrderProducts);
	const orderDetails = useAppSelector(selectOrderDetails);
	const isSubmittable = useAppSelector(selectIsOrderSubmittable);
	const hasPendingChanges = useAppSelector(selectOrderHasPendingChanges);
	const deliveryDate = useAppSelector(selectDeliveryDate);
	const notifications = useAppSelector(selectNotifications);
	const promos = useAppSelector(selectPromos);
	const deliveryAddress = useAppSelector(selectDeliveryAddress);
	const invoiceAddress = useAppSelector(selectInvoiceAddress);
	const quantities = useAppSelector(selectOrderQuantities);
	const totalProducts = useAppSelector(selectOrderTotalProducts);
	const totalItems2 = useAppSelector(selectOrderTotalQuantityBreakdown);
	const submitSuccess = useAppSelector(selectOrderSubmitSuccess);
	const reminders = useAppSelector(selectOrderReminders);
	const questions = useAppSelector(selectOrderQuestions);
	const info = useAppSelector(selectOrderInfo);
	const clientNotifications = useAppSelector(selectClientNotifications);
	const isSaving = useAppSelector(selectIsOrderSaving);

	const articleIssueFilter = l => l.showMadQuantityWarning;

	const articlesWithIssues = useMemo(() => lines.filter(articleIssueFilter), [lines]);
	const hasArticleIssues = useMemo(() => articlesWithIssues.length > 0, [articlesWithIssues]);

	const answers = orderDetails.answers;

	const { calculateTotalPoints } = usePoints(tradeFairDates, deliveryDate);

	useEffect(() => {
		dispatch(saveOrderIfNeeded(false, true));
		dispatch(fetchEobNetForProductsInOrderIfNeeded());
	}, [dispatch]);

	useEffect(() => {
		if (!hasArticleIssues && filterArticleIssues) setFilterArticleIssues(false);
	}, [hasArticleIssues, filterArticleIssues]);


	const getProducts = () => {
		if (filterArticleIssues) return articlesWithIssues;
		return lines;
	};
	const points = useMemo(() => calculateTotalPoints(getProducts(), quantities), [getProducts, quantities]);

	const submitOrder = () => {
		if (isSubmittable) dispatch(saveOrderIfNeeded(true));
	};

	const shouldShowNotifications = () => {
		if (hasPendingChanges) return false;
		return notifications?.[0] ?? false;
	};

	const renderDetails = () => {
		if (!orderDetails.reference && !orderDetails.comment) return;

		const comment = orderDetails.comment?.split('\n').map((line, key) => {
			return (
				<span key={key}>
					{line}<br />
				</span>
			);
		});

		return (
			<div className={'review__details'}>

				{orderDetails.reference &&
					<div className={'detail__item detail__item--reference'}>
						<h3 className={'detail__title'}>{t('order.remarksReferenceLabel')}</h3>
						<div className={'detail__content'}>
							{orderDetails.reference}
						</div>
					</div>
				}

				{orderDetails.comment &&
					<div className={'detail__item detail__item--comment'}>
						<h3 className={'detail__title'}>{t('order.remarksCommentLabel')}</h3>
						<div className={'detail__content'}>
							{comment}
						</div>
					</div>
				}

			</div>
		);
	};

	const renderDeliveryDetails = () => (
		<div className={'review__delivery'}>
			<div className={'detail__item'}>
				<h3 className={'detail__title'}>{t('order.deliveryDateLabel')}</h3>
				<div className={'detail__content detail__content--delivery-date'}>
					{DateHelper.getDateString(deliveryDate)}
				</div>
			</div>

			<div className={'detail__item'}>
				<h3 className={'detail__title'}>{t('order.reviewDeliveryAddressLabel')}</h3>
				<div className={'detail__content'}>
					<p className={'address__name'}>{deliveryAddress.name}</p>
					<p className={'address__street'}>{deliveryAddress['address.street']}</p>
					<p className={'address__city'}>{deliveryAddress['address.postalcode']} {deliveryAddress['address.city']}</p>
				</div>
			</div>

			<div className={'detail__item'}>
				<h3 className={'detail__title'}>{t('order.reviewInvoiceAddressLabel')}</h3>
				<div className={'detail__content'}>
					<p className={'address__name'}>{invoiceAddress.name}</p>
					<p className={'address__street'}>{invoiceAddress['address.street']}</p>
					<p className={'address__city'}>{invoiceAddress['address.postalcode']} {invoiceAddress['address.city']}</p>
				</div>
			</div>
		</div>
	);

	const renderLines = () => (
		<div className={'review__lines'}>
			<div className='title'>
				<h3>{t('order.reviewProductListTitle')}</h3>
				{hasArticleIssues && (
					<Button
						onClick={() => setFilterArticleIssues(!filterArticleIssues)}
						color={'red'}
						secondary={!filterArticleIssues}
						icon={<FaExclamationTriangle />}
					>
						{filterArticleIssues ? t('order.show_all_products') : t('order.show_products_with_problems')}
					</Button>
				)}
			</div>

			<ProductsSummary
				products={getProducts()}
				promos={promos}
				deliveryDate={deliveryDate}
				updateProductQuantity={withDispatch(updateProductQuantity)}
				removeProduct={withDispatch(deleteProductFromOrder)}
				issues={notifications}
				confirmMadQuantity={withDispatch(confirmMadQuantity)}
				quantities={quantities}
			/>

			<div className={'lines__total'}>
				<span className={'total__total'}>
					{t('order.reviewTotalLabel')}
				</span>
				<h4 className={'total__products'}>
					{totalProducts} {totalProducts === 1 ? t('order.reviewProductLabel') : t('order.reviewProductLabelMultiple')}
				</h4>

				{totalItems2.free === 0 ? (
					<div className={'total__quantity'}>
						{totalItems2.total}
						<span>{totalItems2.total === 1 ? t('order.reviewUnitLabel') : t('order.reviewUnitLabelMultiple')}</span>
					</div>
				) : (
					<div className={'total__quantity'}>
						<span className={'quantity__in-order'}>
							{totalItems2.inOrder}
						</span>
						<span>
							+
						</span>
						<span className={'quantity__free'}>
							{totalItems2.free}
							<span>gratis</span>
						</span>
						<span>
							=
						</span>
						<span className={'quantity__total'}>
							{totalItems2.total}
						</span>
						<span>{totalItems2.total === 1 ? t('order.reviewUnitLabel') : t('order.reviewUnitLabelMultiple')}</span>
					</div>
				)}
			</div>
			{/* {(points?.standardPoints>0 || points?.tradeFairPoints>0) && */}
			{(points?.standardPoints>0 ) &&
            <>
            	<div className={'lines__total'}>
                	<span className={'total__total'}>
                		{t('order.reviewTotalLabel')}
                	</span>
                	<div>
                		{/* <Coin points={[points.standardPoints+points.tradeFairPoints]} /> */}
            			<Coin points={[points.standardPoints]} />
                	</div>

                	<h4 className={'total__products saving_points'}>
                		{t('order.savingPoints')}
                	</h4>

            	</div>
            	<span className='disclaimer'>
            		<div >
                		<FaInfoCircle />
            		</div>
                		{t('tradefair.disclaimer')}
                	</span>
            </>
			}

		</div>
	);

	const renderIssues = (issues) => (
		<div className={'save__notifications'}>
			{issues.map((issue, key) => (
				<div key={key} className={'save__notification'}>
					<FaExclamationTriangle />
					{issue}
				</div>
			))}
		</div>
	);

	if (submitSuccess) {
		return (
			<div className={'ReviewOrderStep'}>
				<div className={'submit__success'}>
					<div className={'checkmark'}></div>
					<div className={'success__message'}>
						{t('order.reviewOrderSent')}
					</div>

					{reminders.map(reminder => <OrderReminder reminder={reminder} />)}
				</div>
			</div>
		);
	}



	const renderQuestions = questions => {
		return questions?.map(question => {
			switch (question.data?.type) {
				case 'product_select':
					return (
						<ProductPicker
							code={question.code}
							products={question.data.products}
							optional={question.data.optional}
							answer={answers?.[question.code]}
							select={(answer) => {
								dispatch(setOrderAnswers({
									...answers,
									[question.code]: answer
								}));
							}}
							unselect={() => {
								dispatch(setOrderAnswers({
									...answers,
									[question.code]: undefined
								}));
							}}
							openModal={(product) => {
								setShowModal(true);
								setModalProductId(product);
							}}
						/>
					);
				default:
					throw new Error(`the type "${question.data?.type}" is not a question type that has been implemented yet.`);
			}
		});
	};

	const renderInfo = info => {
		if (!info?.code) return null;
		switch (info.data?.type) {
			case 'minimum_not_reached':
				return (
					<ProductPicker
						key={info.code}
						code={info.code}
						products={info.data.products}
						disabled={true}
						disabledReasons={{ type: info.data.type, minimum: info.data.minimum, estimated: info.data.estimated }}
					/>);
			default:
				return (<p className={'info'}>{info.message}</p>);
		}
	};

	const renderEobHint = () => {
		const eobQuantity = lines.reduce((total, p) => total + (p.eob ? p.quantity : 0), 0);
		if (eobQuantity <= 0) return null;

		return (
			<div className='info eob-hint'>
				<div className='title-badge'>
					{t('product.only_on_order')}
				</div>
				{t('product.badgeEobHint')}
			</div>
		);
	};

	const renderModal = () => {
		if (!showModal) return null;
		return (
			<Modal show={true} close={() => setShowModal(false)}>
				<ProductDetail
					product={modalProductId}
				/>
			</Modal>
		);
	};

	return (
		<div className="ReviewOrderStep">
			<div className={'moduleReminder'}>
				{t('order.reviewStepDescription')}
			</div>
			<div className={'review'}>
				{renderDetails()}

				{renderDeliveryDetails()}

				{!isFetchingEobNet ? (
					renderLines()
				) : (
					<div className={'review__lines'}>
						<div className='title'>
							<h3>{t('order.reviewProductListTitle')}</h3>

						</div>
						<Spinner />
					</div>
				)}
			</div>

			{renderEobHint()}
			{renderQuestions(questions)}
			{info.map(renderInfo)}
			{renderIssues(clientNotifications)}
			{shouldShowNotifications() && renderIssues(notifications[0])}
			<div
				className={`complete__button ${!isSubmittable ? 'complete__button--disabled' : ''}`}
				onClick={submitOrder}
				disabled={!isSubmittable}
			>
				{isSaving ? (
					<Spinner size={'small'} />
				) : (
					<span>{t('order.reviewSendOrder')} ({DateHelper.getDateString(deliveryDate)})</span>
				)}
			</div>
			{renderModal()}
		</div>

	);

}
