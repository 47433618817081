import axios from 'axios';
import { get } from '../libraries/api';

type LoginResponse = {
	success: false;
	isAlreadyAuthenticated?: boolean;
	username: string | null;
} | {
	success: true;
	redirectTo: string
}

export const login = async (username: string, password: string, endpoint: string): Promise<LoginResponse> => {
	const res = await axios({
		url: endpoint,
		method: 'POST',
		data: {
			username,
			password
		},
		withCredentials: true
	});

	return res.data;
};

interface CheckAuthResponse {
	success: boolean;
	isAlreadyAuthenticated: boolean;
	username: string | null;
}

export const checkAuth = async (): Promise<CheckAuthResponse | undefined> => {
	const res = await get('/api/v1/auth/check');

	return res;
};
