import React from 'react';
import './ForbiddenError.scss';
import { t } from '../../../libraries/i18n';
import bord404 from '../../../images/Bord_404_circle.png';

function ForbiddenError(props) {
	return (
		<div className={'ForbiddenError'}>
			<div className={'error__image'}>
				<img src={bord404} alt='Bord met een boterham' />
			</div>
			<div className={'error__content'}>
				<div className={'error__title'}>
					!! {t('errors.needsPermission')} !!
				</div>
				<div className={'error_message'}>
					{t('errors.askPermissionFor', props.permissionName)}
				</div>
			</div>
		</div>
	);
}

export default ForbiddenError;
