import React, { useEffect, useState } from 'react';
import './OrderedProducts.scss';
import {
	List,
	Button
} from '@abm-international/react-components';
import ProductBadges from '../../components/productsViews/productBadges/productBadges';
import { t } from '../../libraries/i18n';
import {
	FaBoxes,
	FaDollyFlatbed,
	FaWarehouse
} from 'react-icons/fa';
import DateHelper from '../../libraries/dateHelper';
import { OrderedProduct, OrderedProductEntry } from '../../types/orders/orderedProducts';
import useOrderedProducts from './../../api/orders/useOrderedProducts';
import { useDispatch, useSelector } from 'react-redux';
import { DetailOrderdProducts } from './details/DetailOrderdProducts';
import { selectCustomerId } from '../selectors';
import {
	fetchEobDetailsIfNeeded
} from '../products/productsActions';

const ORDERED_PRODUCTS_FILTERS = [
	{
		value: 'all',
		Icon: FaBoxes
	},
	{
		value: 'eob',
		Icon: FaDollyFlatbed
	},
	{
		value: 'non-eob',
		Icon: FaWarehouse
	}
];

export default function OrderedProducts() {
	const [eobFilter, setEobFilter] = useState('all');
	const { orderedProducts } = useOrderedProducts();
	const customerId = useSelector(selectCustomerId);
	const dispatch = useDispatch();
	const ASAPdate = '01-01-2500';

	useEffect(() => {
		dispatch(fetchEobDetailsIfNeeded(customerId));
	}, [customerId, dispatch]);

	const getProducts = () => {
		if (!orderedProducts) return [];
		let items = [...orderedProducts];

		if (eobFilter !== 'all') items = items.filter(i => eobFilter === 'eob' ? i.article?.eob : !i.article?.eob);
		return items;
	};
	const renderDeliveryDate = (date?: Date | null) => {
		const dateString = DateHelper.getDateString(date);
		if (dateString === ASAPdate) return t('orders.label_asap');
		return dateString;
	};

	const renderFilters = () => (
		<div className='filters'>
			{ORDERED_PRODUCTS_FILTERS.map(filter => (
				<Button
					primary={filter.value === eobFilter}
					key={filter.value}
					className={`filter filter--${filter.value}`}
					onClick={() => setEobFilter(filter.value)}
					icon={<filter.Icon />}
				>
					{t(`orders.label_ordered_products_filter_${filter.value}`)}
				</Button>
			))}
		</div>
	);

	const renderEntries = (product: OrderedProduct) => (
		<div className='entries'>
			{product.outForDelivery ? product.outForDelivery
				.sort((a: OrderedProductEntry, b: OrderedProductEntry) => a.date.getTime() - b.date.getTime())
				.map((entry: OrderedProductEntry) => (
					<div className='entry'>
						{t('orders.label_out_for_delivery_quantity_on_date', renderDeliveryDate(entry.date))}
					</div>
				)) : null
			}
			{product.reserved ? product.reserved
				.sort((a: OrderedProductEntry, b: OrderedProductEntry) => a.date.getTime() - b.date.getTime())
				.map((entry: OrderedProductEntry) => (
					<div key={entry.date.toString()}>
						<DetailOrderdProducts orderDate={renderDeliveryDate(entry.date)} amount={entry.quantity} productId={product.id} />
					</div>
				)) : null
			}
			{
				(product.toBeDelivered && !product.reserved && !product.outForDelivery) ? product.toBeDelivered
					.sort((a: OrderedProductEntry, b: OrderedProductEntry) => new Date(a.date).getTime() - new Date(b.date).getTime())
					.map((entry: OrderedProductEntry) => (
						<div key={new Date(entry.date).toString()}>
							{t('orders.label_to_be_delivered_quantity_on_date', entry.date)}
						</div>
					)) : null
			}

		</div>
	);

	const renderQuantity = (product: OrderedProduct) => (
		<div className='quantity'>
			{product.toBeDelivered ? product.toBeDelivered
				.map((entry: OrderedProductEntry) => (
					<div className='entry'>
						{entry.quantity}
					</div>
				)) : null
			}
			{product.reserved ? product.reserved
				.map((entry: OrderedProductEntry) => (
					<div>
						{Math.trunc(entry.quantity)}
					</div>
				)) : null
			}
			{product.outForDelivery ? product.outForDelivery
				.map((entry: OrderedProductEntry) => (
					<div>
						{Math.trunc(entry.quantity)}
					</div>
				)) : null
			}
		</div>
	);

	const renderList = () => (
		<List
			header={[
				{
					title: t('orders.label_id'),
					key: 'id',
					render: (product: OrderedProduct) => product.article.code,
					sort: (a: OrderedProduct, b: OrderedProduct) => a.article.code.localeCompare(b.article.code)
				},
				{
					title: t('orders.label_name'),
					key: 'name',
					render: (product: OrderedProduct) =>
						<span>
							{product?.article?.name}
							<ProductBadges product={{ eob: product?.article?.eob }} showSecondaryOverride={false} />
						</span>,
					sort: (a: OrderedProduct, b: OrderedProduct) => a.article?.name.localeCompare(b.article?.name)
				},
				{
					title: '',
					key: 'entries',
					render: renderEntries,
					//sort: (a: OrderedProduct, b: OrderedProduct) => a.entries.length - b.entries.length
				},
				{
					title: t('orders.label_amount'),
					key: 'quantity',
					render: renderQuantity,
				}
			]}
			content={getProducts()}
			gridTemplateColumns={'10rem 1fr 45rem 5rem'}
		/>
	);

	return (
		<div className='OrderedProducts'>
			{renderFilters()}
			<div className='details'>
				{orderedProducts && t('orders.label_x_of_y_products', getProducts().length, orderedProducts?.length)}
			</div>
			{orderedProducts && renderList()}
		</div>
	);
}
