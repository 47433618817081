
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './modules/rootReducer';

export default function configureStore(preloadedState) {
	return createStore(
		rootReducer,
		preloadedState,
		composeWithDevTools(
			applyMiddleware(thunkMiddleware)
		)
	);
}
