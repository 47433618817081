import React, {
	useState,
	useEffect,
	useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './productSelectionStep.scss';
import { FaArrowUp } from 'react-icons/fa';
import useWidth from '../../../libraries/hooks/useWidth';

import {
	fetchProductsIfNeeded,
	fetchCustomerProductsIfNeeded
} from './../../products/productsActions';

import {
	handleSearch,
	changeProductView,
	toggleFilter,
	setFilter,
	clearFilters,
	setShopcodeFilter,
	changeSorting
} from './../orderActions';
import ProductsView from '../../../components/productsViews/productsView';
import ProductsFilters from '../../../components/ProductsFilters/ProductsFilters';
import Modal from '../../../components/modal/modal';
import ProductDetail from '../../../components/productsViews/productDetail/productDetail';
import {
	selectActiveOrderSortKey,
	selectOrderFilters,
	selectFilteredOrderProducts,
	selectLocale,
	selectIsProductsFetching,
	selectIsCustomerProductsFetching,
	selectIsPromosFetching,
	selectIsMetadataFetching,
	selectOrderQuery,
	selectOrderView,
	selectShopcodes,
	selectOrderSortDirection,
	selectCustomerCode,
	selectProductsError,
	selectOrderDefaultFilters,
	selectActiveCampaigns,
	selectCampaigns
} from './../../selectors';
import ProductsHeader from '../../../components/ProductsHeader/ProductsHeader';
import { HeroBanners } from '@abm-international/react-components';
import { t } from '../../../libraries/i18n';

function ProductSelectionStep() {
	const [productsListRef, width] = useWidth();

	const forceGridView = width < 550;

	const isProductsFetching = useSelector(selectIsProductsFetching);
	const isCustomerProductsFetching = useSelector(selectIsCustomerProductsFetching);
	const isPromosFetching = useSelector(selectIsPromosFetching);
	const isMetadataFetching = useSelector(selectIsMetadataFetching);
	const query = useSelector(selectOrderQuery);
	const view = useSelector(selectOrderView);
	const error = useSelector(selectProductsError);
	const customerId = useSelector(selectCustomerCode);
	const shopcodes = useSelector(selectShopcodes);
	const locale = useSelector(selectLocale);
	const sortKey = useSelector(selectActiveOrderSortKey);
	const sortDirection = useSelector(selectOrderSortDirection);
	const allProducts = useSelector(selectFilteredOrderProducts);
	const filters = useSelector(selectOrderFilters);
	const defaultFilters = useSelector(selectOrderDefaultFilters);
	const campaigns = useSelector(selectCampaigns);
	const activeCampaigns = useSelector(selectActiveCampaigns);

	const [showModal, setShowModal] = useState(false);

	const dispatch = useDispatch();
	const handleSearchCallback = useCallback(query => dispatch(handleSearch(query)), [dispatch]);
	const changeProductViewCallback = useCallback(view => dispatch(changeProductView(view)), [dispatch]);
	const toggleFilterCallback = useCallback(filter => dispatch(toggleFilter(filter)), [dispatch]);
	const setFilterCallback = useCallback((filter, state) => dispatch(setFilter(filter, state)), [dispatch]);
	const clearFiltersCallback = useCallback(group => dispatch(clearFilters(group)), [dispatch]);
	const setShopcodeFilterCallback = useCallback(shopcode => dispatch(setShopcodeFilter(shopcode)), [dispatch]);
	const changeSortCallback = useCallback((sortKey, direction) => dispatch(changeSorting(sortKey, direction)), [dispatch]);

	const inThePictureProductsList = [];
	const productsList = [];
	allProducts.filter((product) => {
		if ((product?.inBuylist === false && product.campaigns?.some(({ inThePicture }) => inThePicture === true)) && !inThePictureProductsList.includes(product)) {
			inThePictureProductsList.push(product);
		} else if (!productsList.includes(product)){
			productsList.push(product);
		}
	});

	const products = (query === '') && inThePictureProductsList.length ? productsList : allProducts;

	useEffect(() => {
		dispatch(fetchProductsIfNeeded);
		dispatch(fetchCustomerProductsIfNeeded(customerId));
	}, [customerId, dispatch]);

	const isFetching = () => {
		return isCustomerProductsFetching ||
			isPromosFetching ||
			isMetadataFetching;
	};

	const renderModal = () => {
		if (!showModal) return null;

		return (
			<Modal show={true} close={() => setShowModal(false)}>
				<ProductDetail
					product={showModal}
				/>
			</Modal>
		);
	};

	const renderToTop = () => {
		const scrollToTop = () => window.scrollTo(0, 0);
		return (
			<button
				onClick={scrollToTop}
				className={'scrollToTopButton'}
				aria-label={'scroll to the top of the page'}
			>
				<FaArrowUp />
			</button>
		);
	};

	if (error) {
		return (
			<div className={'panel error'}>
				<h1>Er is een fout opgetreden.</h1>
				<p className={'error__message'}>
					{error.message}
				</p>
			</div>
		);
	}

	return (
		<div className='ProductSelectionStep ProductSelectionStep--new'>
			<div className='products__body'>
				<HeroBanners
					campaigns={activeCampaigns}
					locale={locale}
					setFilter={setFilterCallback}
				/>

				<ProductsFilters
					filters={filters}
					toggleFilter={toggleFilterCallback}
					setFilter={setFilterCallback}
					clearFilters={clearFiltersCallback}
					setShopcodeFilter={setShopcodeFilterCallback}
					isProductsFetching={isProductsFetching}
				/>

				<div className={'products__list'} ref={productsListRef}>
					<ProductsHeader
						handleSearch={handleSearchCallback}
						query={query}
						numProducts={products?.length}
						filters={filters}
						view={forceGridView ? 'grid' : view}
						changeSort={changeSortCallback}
						changeView={changeProductViewCallback}
						sortKey={sortKey}
						direction={sortDirection}
						sortingDisabled={query && query !== ''}
						defaultFilters={defaultFilters}
						shopcodes={shopcodes}
						campaigns={campaigns}
						clearFilters={clearFiltersCallback}
						setFilter={setFilterCallback}
						forceGridView={forceGridView}
					/>
					{inThePictureProductsList?.length > 0 && query === '' && (
						<div className={'in-the-picture'}>
							<h3>{t('order.title_in-the-picture')}</h3>
							<ProductsView
								products={inThePictureProductsList}
								showModal={setShowModal}
								view={'list'}
								isFetching={isFetching()}
								locale={locale}
								query={query}
							/>
						</div>
					)}
					<ProductsView
						products={products}
						showModal={setShowModal}
						view={forceGridView ? 'grid' : view}
						isFetching={isFetching()}
						locale={locale}
						query={query}
					/>
					{renderToTop()}
				</div>
			</div>

			{renderModal()}
		</div>
	);
}


export default ProductSelectionStep;
