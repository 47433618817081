export const selectIsNavCollapsed = state => state.navigation.collapsed;
export const selectLoadedTranslations = state => state.loadedTranslations;
export const selectFetchingTranslations = state => state.fetchingTranslations;
export const selectLatestClientVersion = state => state.latestClientVersion;
const selectRoutes = state => state?.routes ?? [];
export const selectRouteVersion = (state, path) => {
	const routes = selectRoutes(state);
	const route = routes.find(route => route.href === path);
	return route?.version;
};
export const selectHolidays = state => state.holidays;
export const selectSettings = state => state.settings;
export const selectSetting = (state, setting) => state.settings?.[setting];
export const selectIsResourcesListFetching = (state) => state.resources?.isFetching;
export const selectDidResourcesListFail = (state) => state.resources?.didFail;
export const selectResourcesList = (state) => state.resources?.items;
export const selectEndpointById = (state, id) => {
	const resources = selectResourcesList(state);
	const resource = resources?.find(r => r.id === id);
	return resource?.location;
};
