import React, {
	useEffect,
	useState,
	useCallback,
	useMemo
} from 'react';
import './PriceChanges.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectPriceChanges,
	selectCustomerId,
	selectIsPriceChangesFetching,
	selectPriceChangesQuery
} from './../selectors';
import {
	fetchPriceChangesIfNeeded,
	handleSearch
} from './PriceChangesActions';
import {
	FaChevronUp,
	FaChevronDown
} from 'react-icons/fa';
import Currency from '../../components/Currency/Currency';
import Search from '../../components/Search/Search';
import { t } from './../../libraries/i18n';
import Filter from './../../libraries/filter';
import Spinner from '../../components/spinner/spinner';
import { useRestrictedContent } from './../../libraries/hooks';
import ForbiddenError from '../../components/Error/ForbiddenError/ForbiddenError';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';

function PriceChanges(props) {
	useCustomerRoute();
	const dispatch = useDispatch();
	const customerId = useSelector(selectCustomerId);
	const isFetching = useSelector(selectIsPriceChangesFetching);
	const priceChanges = useSelector(selectPriceChanges);
	const query = useSelector(selectPriceChangesQuery);

	const [openPriceChanges, setOpenPriceChanges] = useState([]);
	const [filteredPriceChanges, setFilteredPriceChanges] = useState([]);

	const handleQuery = useCallback(query => dispatch(handleSearch(query)), [dispatch]);

	const [RestrictedContent, RestrictedContentError] = useRestrictedContent(props.requiredPermission);

	const hasAnyConsumerPrice = useMemo(() => {
		return priceChanges.some(pc => pc.consumerPrice);
	}, [priceChanges]);

	useEffect(() => {
		dispatch(fetchPriceChangesIfNeeded(customerId));
	}, [customerId, dispatch]);

	// Filter pricechanges
	useEffect(() => {
		const filtered = Filter.filterItemsByQuery(
			query,
			priceChanges,
			[
				{ name: 'date' },
				{ name: 'product.code' },
				{ name: 'product.name' }
			],
			true,
			'AND'
		);
		setFilteredPriceChanges(filtered);
	}, [query, priceChanges]);

	// Open all price changes with results if we are searching
	useEffect(() => {
		if (query === '') {
			setOpenPriceChanges([]);
			return;
		}

		const openIds = {};
		filteredPriceChanges.forEach(priceChange => openIds[priceChange.date] = true);
		const openIdsArray = Object.keys(openIds);
		setOpenPriceChanges(openIdsArray);
	}, [query, filteredPriceChanges]);

	const handleOpenChange = id => {
		if (openPriceChanges.includes(id)) {
			const index = openPriceChanges.indexOf(id);
			setOpenPriceChanges([
				...openPriceChanges.slice(0, index),
				...openPriceChanges.slice(index + 1)
			]);
		} else {
			setOpenPriceChanges([
				...openPriceChanges,
				id
			]);
		}
	};

	const getGroupedPriceChanges = priceChanges => {
		const groups = {};

		priceChanges.forEach(change => {
			if (!groups[change.date]) groups[change.date] = [];
			groups[change.date].push(change);
		});

		return Object.keys(groups)
			.map(date => ({
				date: new Date(date),
				dateString: date,
				changes: groups[date]
			}))
			.sort((a, b) => {
				if (a.date > b.date) return -1;
				if (a.date < b.date) return 1;
				return 0;
			});
	};

	const renderProduct = product => {
		if (!product) return null;

		return (
			<div className={`change__product ${!product.code ? 'change__product--no-code' : ''}`}>
				<div className={'product__code'}>{product.code ?? product.id}</div>
				<div className={'product__name'}>{product.name}</div>
			</div>
		);
	};

	const renderPriceChange = priceChange => {
		const change = priceChange.newPrice - priceChange.oldPrice;

		return (
			<div key={priceChange.product.id} className={'changes__item'}>
				<div>{renderProduct(priceChange.product)}</div>
				<Currency value={priceChange.oldPrice} />
				<Currency value={priceChange.newPrice} />
				<div className={`change__amount ${change > 0 ? 'change__amount--positive' : 'change__amount--negative'}`}>
					<Currency value={change} />
				</div>
				{hasAnyConsumerPrice && <Currency value={priceChange.consumerPrice} />}
			</div>
		);
	};

	const renderPriceChanges = priceChanges => (
		<div className={'group__changes'}
			style={{
				'--price-change-columns': hasAnyConsumerPrice ? '1fr repeat(4, 10rem)' : '1fr repeat(3, 10rem)'
			}}
		>
			<div className={'changes__header'}>
				<div>{t('priceChanges.headerProduct')}</div>
				<div className={'header--numeric'}>{t('priceChanges.headerOldPrice')}</div>
				<div className={'header--numeric'}>{t('priceChanges.headerNewPrice')}</div>
				<div className={'header--numeric'}>{t('priceChanges.headerDifference')}</div>
				{hasAnyConsumerPrice && <div className={'header--numeric'}>{t('priceChanges.headerConsumerPrice')}</div>}
			</div>
			{priceChanges.map(renderPriceChange)}
		</div>
	);

	const renderGroup = group => (
		<div key={group.dateString} className={`changes__group ${openPriceChanges.includes(group.dateString) ? 'changes__group--expanded' : ''}`}>
			<div className={'list__item'} onClick={() => handleOpenChange(group.dateString)}>
				<div>
					{openPriceChanges.includes(group.dateString) ? (
						<FaChevronUp />
					) : (
						<FaChevronDown />
					)}
				</div>
				<div>{group.dateString}</div>
				<div>{group.changes.length === 1 ? t('priceChanges.oneChange') : t('priceChanges.nChanges', group.changes.length)}</div>
			</div>
			{openPriceChanges.includes(group.dateString) && renderPriceChanges(group.changes)}
		</div>
	);

	return (
		<div className={'PriceChanges'}>
			<h2>{t('priceChanges.title')}</h2>

			<RestrictedContentError>
				<ForbiddenError permissionName={t('priceChanges.viewPriceChangesPermission')} />
			</RestrictedContentError>

			<RestrictedContent>
				<Search
					handleSearch={handleQuery}
					query={query}
					placeholder={t('priceChanges.searchPlaceholder')}
				/>

				{isFetching && filteredPriceChanges.length <= 0 && <Spinner />}

				{!isFetching && filteredPriceChanges.length <= 0 && (
					<div>
						{t('priceChanges.noPriceChanges')}
					</div>
				)}

				{filteredPriceChanges.length > 0 && (
					<div className={'changes__list'}>

						<div className={'list__groups'}>
							{getGroupedPriceChanges(filteredPriceChanges).map(renderGroup)}
						</div>
					</div>
				)}
			</RestrictedContent>
		</div>
	);
}

export default PriceChanges;
