import {
	selectLoadedTranslations,
	selectFetchingTranslations,
	selectIsResourcesListFetching,
	selectResourcesList
} from './selectors';
import * as i18n from '../libraries/i18n';
import { AppDispatch, RootState } from './../types/redux';
import fetchResource from './../redux/fetchResource';
import { fetchAPIResources } from './../api';

export const APP_LOADED = 'APP_LOADED';
export const APP_OFFLINE = 'APP_OFFLINE';
export const APP_ONLINE = 'APP_ONLINE';
export const APP_NAVIGATION_TOGGLE_COLLAPSE = 'APP_NAVIGATION_TOGGLE_COLLAPSE';
export const APP_TRANSLATIONS_REQUEST = 'APP_TRANSLATIONS_REQUEST';
export const APP_TRANSLATIONS_RECEIVE = 'APP_TRANSLATIONS_RECEIVE';
export const APP_TRANSLATIONS_REJECT = 'APP_TRANSLATIONS_REJECT';

export function appLoaded() {
	return {
		type: APP_LOADED
	};
}

export function appOffline() {
	return {
		type: APP_OFFLINE,
		payload: Date.now()
	};
}

export function appOnline() {
	return {
		type: APP_ONLINE,
		payload: Date.now()
	};
}

export function appNavigationToggleCollapse(collapsed: boolean) {
	return {
		type: APP_NAVIGATION_TOGGLE_COLLAPSE,
		payload: typeof collapsed === 'boolean' ? collapsed : undefined
	};
}

function fetchTranslations(locale: string) {
	return async (dispatch: AppDispatch) => {
		dispatch({ type: APP_TRANSLATIONS_REQUEST, locale });

		try {
			await i18n.init(locale);
		} catch (error) {
			return dispatch({ type: APP_TRANSLATIONS_REJECT, locale });
		}

		return dispatch({ type: APP_TRANSLATIONS_RECEIVE, locale });
	};
}

function shouldFetchTranslations(locale: string, state: RootState) {
	const loadedTranslations = selectLoadedTranslations(state);
	const fetchingTranslations = selectFetchingTranslations(state);
	if (fetchingTranslations.includes(locale)) return false;
	return !loadedTranslations.includes(locale);
}

export function fetchTranslationsIfNeeded(locale: string) {
	return (dispatch: AppDispatch, getState: () => RootState) => {
		if (shouldFetchTranslations(locale, getState())) {
			return dispatch(fetchTranslations(locale));
		}
	};
}

export const FETCH_RESOURCES_LIST_START = 'FETCH_RESOURCES_LIST_START';
export const FETCH_RESOURCES_LIST_SUCCESS = 'FETCH_RESOURCES_LIST_SUCCESS';
export const FETCH_RESOURCES_LIST_FAILED = 'FETCH_RESOURCES_LIST_FAILED';

export const fetchResourcesListIfNeeded = () => {
	const config = {
		fetcher: async () => {
			localStorage.setItem('content-type', 'text/plain');
			const data = await fetchAPIResources();
			if (data.success === true && data.resources) {
				localStorage.setItem('content-type', 'application/json');
			}
			return data.resources;
		},
		shouldFetch: (state: RootState) => {
			const isFetching = selectIsResourcesListFetching(state);
			const resourcesList = selectResourcesList(state);

			return !isFetching && !resourcesList;
		},
		actionTypes: {
			start: FETCH_RESOURCES_LIST_START,
			success: FETCH_RESOURCES_LIST_SUCCESS,
			failed: FETCH_RESOURCES_LIST_FAILED
		}
	};

	return (dispatch: AppDispatch) => dispatch(fetchResource(config));
};
