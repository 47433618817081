import React, { Component } from 'react';
import './dropdown.scss';
import { FaChevronDown } from 'react-icons/fa';

class Dropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	handleOption = (value) => {
		this.props.onChange(value);
		this.setState({ open: false });
	};

	toggleOpen = () => {
		this.setState({ open: !this.state.open });
	};

	render() {
		const {
			value,
			options,
			disabled
		} = this.props;

		const selected = options.filter(option => option.value === value)[0];

		return (
			<div className={`Dropdown ${this.state.open ? 'dropdown--open' : ''} ${disabled ? 'Dropdown--disabled' : ''}`}>
				<div className={'dropdown__click'} onClick={() => !disabled && this.toggleOpen()}>

				</div>
				<div className={'dropdown__container'}>
					<div className={'dropdown__selected'} onClick={() => !disabled && this.toggleOpen()}>
						{selected.description}
						<FaChevronDown />
					</div>
					<div className={'dropdown__options'}>
						{options.map((option, key) => (
							<span
								key={key}
								className={`dropdown__option ${value === option.value ? 'option--selected' : ''}`}
								onClick={() => !disabled && this.handleOption(option.value)}
							>
								{option.description}
							</span>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default Dropdown;
