import { Button } from '@abm-international/react-components';
import React, { useMemo } from 'react';
import useUserSetting from './../../../api/useUserSetting';
import { Setting } from './../../../types/settings';
import { useHistory } from 'react-router-dom';
import * as DateHelper from './../../../libraries/dateHelper';
import useVacation from './../../../api/settings/useVacation';

const VacationReminder = () => {
	const history = useHistory();
	const [showVacationReminder, setShowVacationReminder] = useUserSetting(Setting.SHOW_VACATION_REMINDER, false);
	const { vacation } = useVacation();

	const hasVacationInNextThreeMonths = useMemo(() => {
		if (!vacation) return false;
		const today = new Date();
		const threeMonthsFromNow = new Date();
		threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
		const dates = DateHelper.dateRangeToDates(today, threeMonthsFromNow);
		const dateStrings = dates.map(DateHelper.getDateString);
		const vacationStrings = vacation.map(DateHelper.getDateString);
		return dateStrings.some(ds => vacationStrings.includes(ds));
	}, [vacation]);

	if (!showVacationReminder) return null;
	if (hasVacationInNextThreeMonths) return null;

	return (
		<div className='vacation-reminder'>
			<span className='title'>Heeft u al gedacht om uw vakantie-afwezigheid in te geven?</span>

			<div className='actions'>
				<Button
					color='blue'
					onClick={() => setShowVacationReminder(false)}
				>
					Niet meer tonen
				</Button>
				<Button
					color='blue'
					primary
					onClick={() => history.push('/settings/vacation')}
				>
					Vakantie-afwezigheid ingeven
				</Button>
			</div>
		</div>
	);
};

export default VacationReminder;
