export const filter = (items, filterState) => {
	if (!filterState?.group) return items;
	const activeMainGroupId = filterState.group.slice(0, 1);
	const activeSubGroupId = filterState.group.slice(1, 2);

	let productIdsInActiveGroup = [];

	const mainGroup = FILTER_GROUPS.find(mainGroup => mainGroup.groupId === activeMainGroupId);
	mainGroup.subgroups.forEach(subGroup => {
		if (subGroup.groupId === activeSubGroupId || activeSubGroupId === '') {
			productIdsInActiveGroup = productIdsInActiveGroup.concat(subGroup.products);
		}
	});

	return items.filter(item => productIdsInActiveGroup.includes(item.id));
};

export const FILTER_GROUPS = [
	{
		title: 'Feestelijk aperitieven',
		groupId: '1',
		icon: () => <span role='img' aria-label='Clinking Glasses'>🥂</span>,
		subgroups: [
			{
				title: 'Charcuterie & paté',
				groupId: 'a',
				products: [
					'1371',
					'24861',
					'6663',
					'30175',
					'19105',
					'23982',
					'22349',
					'22350',
					'23953',
					'24577',
					'23987',
					'23885',
					'7291',
					'24263',
					'1216',
					'1212',
					'7324',
					'1211',
					'23931',
					'7275',
					'22371',
					'22373',
					'23291',
					'23276',
				]
			},
			{
				title: 'Wildpaté & konfijten',
				groupId: 'b',
				products: [
					'25106',
					'23892',
					'19348',
					'1455',
					'21482',
					'6615',
					'23878',
					'1227',
					'6595',
					'21656',
					'1434',
					'7329',
					'19408',
					'1226',
					'6596',
					'21654',
					'7326',
					'1433',
					'6540',
					'1228',
					'1435',
					'6597',
					'21653',
					'7328',
					'1225',
					'6599',
					'7327',
					'6600',
					'7330',
					'6616',
					'25105',
					'1436',
					'23985',
					'1410',
					'25104',
					'18425',
					'1412',
					'1411',
					'2326',
					'31706',
					'23884',
					'31707',
					'23957',
					'31705',
					'23836',
					'23958',
					'31702',
				]
			},
			{
				title: 'Salades',
				groupId: 'c',
				products: [
					'21524',
					'24703',
					'2095',
					'24704',
					'2200',
					'2291',
					'21880',
					'22996',
					'24388',
					'25189',
					'2230',
					'2148',
					'2164',
					'19951',
					'23279',
					'23354',
					'21454',
				]
			},
			{
				title: 'Koude hapjes',
				groupId: 'd',
				products: [
					'24530',
					'24531',
					'24532',
					'24533',
					'24534',
					'24535',
					'24536',
					'24537',
					'24538',
					'24539',
					'24540',
					'24541',
					'24542',
					'24543',
					'23498',
					'23500',
					'23502',
					'25182',
					'3292',
					'24261',
					'4880',
					'4546',
					'4609',
					'23321',
					'4688',
				]
			},
			{
				title: 'Warme hapjes',
				groupId: 'e',
				products: [
					'32664',
					'31084',
					'7202',
					'24568',
					'24569',
					'32649',
					'7203',
					'4584',
					'32652',
					'4585',
					'6667',
					'7204',
					'19463',
					'7206',
					'19462',
					'19464',
					'19461',
					'7205',
					'32657',
				]
			}
		]
	},
	{
		title: 'Hemels warme gerechten voor koude winterdagen',
		groupId: '2',
		icon: () => <span role='img' aria-label='Hot Meal'>🍲</span>,
		subgroups: [
			{
				title: 'Voorgerechten',
				groupId: 'a',
				products: [
					'19665',
					'4583',
					'21904',
					'4590',
					'21905',
					'4591',
					'7234',
					'19453',
					'4738',
					'24578',
					'24117',
					'6653',
					'21888',
					'31170',
					'21944',
					'21945',
					'31177',
				]
			},
			{
				title: 'Visgerechten',
				groupId: 'b',
				products: [
					'4626',
					'4598',
					'4827',
					'7552',
					'7456',
					'23659',
					'4681',
					'23979',
				]
			},
			{
				title: 'Gevogeltegerechten',
				groupId: 'c',
				products: [
					'21543',
					'24575',
					'4843',
					'23980',
					'25263',
					'19465',
					'7345',
					'4699',
					'7463',
					'6655',
					'21515',
					'23423',
					'7569',
				]
			},
			{
				title: 'Vleesgerechten',
				groupId: 'd',
				products: [
					'4569',
					'4538',
					'4536',
					'4710',
					'24580',
					'22367',
					'64',
					'6671',
					'6672',
				]
			},
			{
				title: 'Wildgerechten',
				groupId: 'e',
				products: [
					'4795',
					'4606',
					'18541',
					'21462',
					'7465',
					'19449',
					'4752',
					'4790',
					'19933',
					'4794',
				]
			}
		]
	},
	{
		title: 'Creatief in uw atelier',
		groupId: '3',
		icon: () => <span role='img' aria-label='Knife'>🔪</span>,
		subgroups: [
			{
				title: 'Bladerdeeg',
				groupId: 'a',
				products: [
					'31357',
					'19820',
					'31374',
					'21738',
					'31378',
					'31379',
					'31380',
					'31363',
					'7242',
					'31372',
					'21737',
					'31373',
				]
			},
			{
				title: 'Viscomponenten',
				groupId: 'b',
				products: [
					'21717',
					'32705',
				]
			},
			{
				title: 'Gevogeltecomponenten',
				groupId: 'c',
				products: [
					'23895',
					'1090',
					'21645',
					'1098',
					'23288',
				]
			},
			{
				title: 'Groenten en fruit',
				groupId: 'e',
				products: [
					'6657',
					'23371',
					'7587',
					'21565',
					'6578',
					'21522',
					'4637',
					'7308',
					'4671',
					'6647',
					'25201',
					'25202',
					'25203',
					'25204',
					'21568',
					'21570',
					'21564',
					'21563',
					'21571',
					'21868',
					'24512',
				]
			},
			{
				title: 'Sauzen & boter ',
				groupId: 'f',
				products: [
					'20003',
					'8337',
					'7622',
					'7613',
					'8708',
					'7611',
					'19436',
					'19435',
					'19848',
					'7616',
					'20001',
					'7307',
					'19677',
					'20002',
					'24093',
					'7618',
					'25304',
					'33412',
					'21879',
				]
			}
		]
	},
	{
		title: 'Aardappelen in al hun glorie',
		groupId: '4',
		icon: () => <span role='img' aria-label='Potato'>🥔</span>,
		subgroups: [
			{
				title: 'Gratin & aardappelen',
				groupId: 'a',
				products: [
					'4402',
					'6632',
					'4405',
					'18668',
					'31306',
					'31305',
					'4674',
					'6649',
					'6590',
					'31307',
					'23351',
					'31309',
					'4670',
					'31320',
					'4529',
					'4416',
					'4425',
					'19324',
					'4421',
				]
			},
			{
				title: 'Kroketten',
				groupId: 'b',
				products: [
					'4408',
					'4409',
					'4407',
					'31298',
					'4412',
					'31301',
					'19089',
					'19090',
					'31303',
				]
			}
		]
	},
	{
		title: 'De afsluiter van een geslaagde avond ',
		groupId: '5',
		icon: () => <span role='img' aria-label='Dessert'>🧁</span>,
		subgroups: [
			{
				title: 'Verse desserten',
				groupId: 'a',
				products: [
					'22234',
					'22237',
					'22238',
					'22243',
					'22652',
					'23649',
					'25020',
					'25021',
					'22235',
					'22236',
					'22655',
					'22239',
					'22240',
					'22242',
					'25018',
					'25019',
				]
			},
			{
				title: 'Desserten diepvries',
				groupId: 'b',
				products: [
					'21913',
					'21768',
					'21914',
				]
			}
		]
	}
];
