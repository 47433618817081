import useDebounce from './useDebounce';
import useFullscreen from './useFullscreen';
import useRestrictedContent from './useRestrictedContent';
import usePageViews from './usePageViews';
import useCurrentStep from './useCurrentStep';
import usePreviousAndNextSteps from './usePreviousAndNextSteps';
import useErrorBoundary from './useErrorBoundary';
import usePreference from './usePreference';
import useCountdown from './useCountdown';

export default {
	useDebounce,
	useFullscreen,
	useRestrictedContent,
	usePageViews,
	useCurrentStep,
	usePreviousAndNextSteps,
	useErrorBoundary,
	usePreference,
	useCountdown
};

export {
	useDebounce,
	useFullscreen,
	useRestrictedContent,
	usePageViews,
	useCurrentStep,
	usePreviousAndNextSteps,
	useErrorBoundary,
	usePreference,
	useCountdown
};
