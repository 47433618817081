import React from 'react';
import './Brands.scss';
import Checkbox from '../../checkbox/checkbox';
import { t } from './../../../libraries/i18n';
import FilterGroup from '../FilterGroup';
import { selectUniqueFilteredBrands } from '../../../modules/selectors';
import { useAppSelector } from '../../../libraries/hooks/redux';

function Brands(props) {
	const {
		 filters,
		clearFilters,
		toggleFilter
	} = props;

	const numFilters = Object.keys(filters)?.reduce((sum, key) => {
		if (filters[key]) sum += 1;
		return sum;
	}, 0);

	const ALWAYS_SHOW_AMOUNT = 2;

	const BRANDS = useAppSelector(selectUniqueFilteredBrands);

	const renderBrand = brand => {
		const filterState = filters[brand.id];

		return (

			<div
				key={brand.id}
				className={`filter__item filter__allergen--${brand?.id?.replace('.', '_')}`}
				onClick={() => toggleFilter(`brands.${brand.id}`)}
			>
				<Checkbox checked={filterState} />
				<span className={'brand__number'}>{brand.id}   ({brand.amount})</span>
			</div>
		);
	};

	return (
		<FilterGroup
			className='BrandsFilter'
			numFilters={numFilters}
			clearFilters={clearFilters}
			id={'brandsFilter'}
		>
			<FilterGroup.Title>{t('order.filtersBrandsTitle')}</FilterGroup.Title>
			<FilterGroup.Body>
				<FilterGroup.Collapsed>
					{BRANDS?.slice(0, ALWAYS_SHOW_AMOUNT).map(renderBrand)}
				</FilterGroup.Collapsed>

				<FilterGroup.Uncollapsed>
					{BRANDS?.map(renderBrand)}
				</FilterGroup.Uncollapsed>
			</FilterGroup.Body>
		</FilterGroup>
	);
}

export default Brands;
