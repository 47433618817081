import ProductHelper from './../libraries/productHelper';
import DateHelper from './../libraries/dateHelper';
import { filter as endOfYear2020Filter } from './../modules/CustomCampaigns/EndOfYear2020/EndOfYearFilter';

// TODO: Give array of characters that should be interpreted the same
/**
 * Returns the items that contain the query
 * @param {string} query The search query
 * @param {Array} items The items the filter should be applied on
 * @param {Array} keys The keys on which to apply the filter
 * @param {Boolean} scoreItems Whether the items should be given a score
 * @returns {Array} Array of filtered items
 */
const filterItemsByQuery = (query = '', items, keys, scoreItems = false, searchOperator = 'AND', ignoredChars = ['\'']) => {
	if (query === '') return items;

	// Trim whitespaces around and in query
	query = query.toLowerCase().trim();
	query = query.replace(/ +/g, ' ');

	const filteredItems = items.map(item => {
		return filterItemByQuery(item, keys, query, scoreItems, searchOperator, ignoredChars);
	});

	return filteredItems.filter(item => item !== null);
};

const filterItemByQuery = (item, keys, query, scoreItems, searchOperator, ignoredChars) => {
	const queries = query.split(' ');

	let score = 0;

	// Check how many queries have a match
	// All queries should match to return true
	const queriesMap = queries.map(q => {

		// If at least one key has a match,
		// consider it a match for the query
		const keyMatch = keys.map(key => {
			const keyParts = key.name.split('.');
			let value = item;
			keyParts.forEach(k => {
				value = value[k];
			});

			if (!value) return false;
			value = value.toString().toLowerCase();
			const match = stringContainsQuery(value, q, ignoredChars);

			let scoreIncrease = 1;
			if (value === q) scoreIncrease = 3;
			if (key.multiplier) scoreIncrease = scoreIncrease * key.multiplier;

			score = match ? score + scoreIncrease : score;
			return match;
		});

		const matchesAtLeastOneKey = keyMatch.indexOf(true) !== -1;
		return matchesAtLeastOneKey;
	});

	let containsAllQueries = false;
	if (searchOperator === 'OR') {
		containsAllQueries = queriesMap.indexOf(true) !== -1;
	} else if (searchOperator === 'AND') {
		containsAllQueries = queriesMap.indexOf(false) === -1;
	}

	if (containsAllQueries) {
		if (scoreItems) item.score = score;
		return item;
	}

	return null;
};

const stringContainsQuery = (string, query, ignoredChars) => {
	query = query.replace(/[\\.+*?^$[\](){}/'#:!=|]/gi, '\\$&');

	ignoredChars.forEach(char => {
		query = query.replace(char, `${char}?`);
	});

	const regex = new RegExp(`(${query})`, 'gi');
	const contains = string.match(regex);
	return !!contains;
};

const sortItemsByKey = (a, b, direction, sortKey) => {
	if (direction === 'descending') {
		const buffer = a;
		a = b;
		b = buffer;
	}

	let aValue = a[sortKey];
	let bValue = b[sortKey];
	if (sortKey === 'code' || sortKey === 'shopcode') {
		aValue = parseInt(aValue);
		bValue = parseInt(bValue);
	}

	if (aValue > bValue) {
		return 1;
	} else if (aValue < bValue) {
		return -1;
	} else {
		return 0;
	}
};

const rankItems = (a, b) => {
	if (a.score === b.score) return sortItemsByKey(a, b, 'ascending', 'shopcode');
	return b.score - a.score;
};

const productFilterKeys = () => {
	return [
		{
			name: 'name',
			multiplier: 10,
		},
		{
			name: 'code',
			multiplier: 10,
		},
		{
			name: 'brand',
			multiplier: 5,
		},
		{
			name: 'shopcodeText',
			multiplier: 1
		},
		{
			name: 'groupText.main',
			multiplier: 1
		},
		{
			name: 'groupText.sub',
			multiplier: 1
		}
	];
};

const applyFilters = (items, filters, query, promos, deliveryDate, shopcodes, groups, sortKey, sortDirection, campaigns, inOrderProductIds) => {
	if (!items || items.length <= 0 ||items[0] === undefined) return [];
	items = items.filter(item => item !== undefined);
	items = filters.productRange?.fullRange ? items : items.filter(item => productRangeFilter(!filters.productRange.fullRange, item, 'inBuylist', promos, deliveryDate));
	items = customFilters(items, filters);
	items = filterUnavailableItems(items, deliveryDate);
	items = filters.productRange ? applyShopcodeFilter(items, filters.productRange.shopcode) : items;
	items = applyAllergenFilters(items, filters.allergens);
	items = applyBrandsFilters(items, filters.brands);
	items = applyMainFilters(items, filters.main, promos, deliveryDate, inOrderProductIds);
	items = applyCampaignFilters(items, filters.campaigns, campaigns);
	items = searchFilter(items, query, shopcodes, groups);
	items = defaultSortItems(items, query, sortKey, !filters?.productRange?.fullRange, sortDirection);
	return items;
};

const applyCampaignFilters = (items, filters, campaigns) => {
	Object.keys(filters).forEach(filterId => {
		const state = filters[filterId];
		if (state === 'off') return;
		items = items.filter(item => {
			const campaign = campaigns.find(c => c.id === filterId);
			const campaignHasItem = campaign?.articles?.ids?.includes(parseInt(item.id)) ?? false;
			return state === 'include' ? campaignHasItem : !campaignHasItem;
		});
	});
	return items;
};

const filterUnavailableItems = (items, deliveryDate) => {
	if (!deliveryDate) return items;
	return items.filter(item => DateHelper.isDateInBetweenDates(deliveryDate, new Date(item.start), new Date(item.end)));
};

const applyMainFilters = (items, filters, promos, deliveryDate, inOrderProductIds) => {
	return items.filter((item) => {
		const includeEob = filters.eob ? includeExcludeFilter(filters.eob, item, 'eob') : true;
		const includePromo = filters.promo ? promoFilter(filters.promo, item, promos, deliveryDate) : true;
		const includeSeason = filters.season ? includeExcludeFilter(filters.season, item, 'season') : true;
		const includeInOrder = filters.inOrder ? inOrderFilter(filters.inOrder, item, inOrderProductIds) : true;
		const includePending = filters.pending ? pendingFilter(filters.pending, item, 'pending', deliveryDate) : true;
		const includeReserved = filters.reserved ? reservedFilter(filters.reserved, item, 'reserved') : true;
		const includeTimeSensitive = filters.timeSensitive ? includeExcludeFilter(filters.timeSensitive, item, 'timeSensitive') : true;

		return includeEob && includePromo && includeSeason && includeInOrder && includePending && includeReserved && includeTimeSensitive;
	});
};

const applyAllergenFilters = (items, filters) => {
	const allergens = [1, 2, 3, 4, 5, 6, '7.1', '7.2', 8, 9, 10, 11, 12, 13, 14];
	allergens.forEach(a => {
		items = filters[a.toString()] ? items.filter(item => {
			return item.allergens ? !item.allergens.includes(a) : false;
		}) : items;
	});
	return items;
};

const applyBrandsFilters = (items, filters) => {
	if (!filters|| Object.values(filters).every(value => value === false)) return items;

	const filteredItems = items.filter((item) => {
		const brandKey = item?.brand?.toUpperCase();
		return filters[brandKey];
	});
	return filteredItems;
};

const applyShopcodeFilter = (items, filter) => {
	if (!filter || filter === '') return items;

	const match = filter.toString().match(/0+$/);
	const trailingZeros = match ? match[0].length : 0;
	return items.filter(item => (item.shopcode?.substr(0, 8 - trailingZeros) ?? '?') === filter.toString().substr(0, 8 - trailingZeros));
};

const searchFilter = (items, query, shopcodes, groups) => {
	items = items.map(i => {
		return {
			...i,
			shopcodeText: ProductHelper.getShopcode(shopcodes, i.shopcode),
			groupText: ProductHelper.getGroup(groups)
		};
	});

	return filterItemsByQuery(
		query,
		items,
		productFilterKeys(),
		true,
		'OR'
	);
};

const isInThePicture = (item) => item.campaigns?.some?.(({ inThePicture }) => inThePicture === true);

const defaultSortItems = (items, query, sortKey, buylistFilter, sortDirection = 'ascending') => {
	if (query !== '') return sortItemsWithQuery(items);

	if (buylistFilter || buylistFilter === undefined) {

		return items.sort((a, b) => {
			if (isInThePicture(a) && !isInThePicture(b)) return -1;
			if (!isInThePicture(a) && isInThePicture(b)) return 1;

			if (a.inBuylist && !b.inBuylist) return -1;
			if (!a.inBuylist && b.inBuylist) return 1;

			return sortItemsByKey(a, b, sortDirection, sortKey);
		});
	}

	return items.sort((a, b) => {
		if (isInThePicture(a) && !isInThePicture(b)) return -1;
		if (!isInThePicture(a) && isInThePicture(b)) return 1;

		return sortItemsByKey(a, b, sortDirection, sortKey);
	});
};

const sortItemsWithQuery = (items) => {
	items = boostItems(items);
	items = items.sort(rankItems);
	return items;
};

const boostItems = (items, promos, deliveryDate) => {
	const brands = [
		'COCK\'S',
		'CHARLES'
	];

	const BRAND_MULTIPLIER = 1.5;
	const PROMO_MULTIPLIER = 1.5;

	return items.map(item => {
		if (brands.indexOf(item.brand) !== -1) item.score = item.score * BRAND_MULTIPLIER;
		if (item.promo && item.promo.push !== 0) item.score = item.score * (PROMO_MULTIPLIER + (item.promo.weight / 10));
		return item;
	});
};

const includeExcludeFilter = (state, item, itemProp) => {
	if (state === 'off') return item;
	return state === 'include' ? item[itemProp] : !item[itemProp];
};

const productRangeFilter = (state, item, itemProp, promos, deliveryDate) => {
	const isInBuyList = item[itemProp];
	const isPushedPromoOutsideRange = !isInBuyList && parseInt(item.promo?.push, 10) === 1;

	/**
	 * Add item to own buylist when:
	 * - it's a pushed promo and not in the buylist
	 * - it's an item In The Picture
	 */
	if (isPushedPromoOutsideRange || isInThePicture(item)) {
		return true;
	}

	return isInBuyList;
};

const promoFilter = (state, item, promos, deliveryDate) => {
	if (state === 'off') return true;
	return state === 'include' ? item.promo : !item.promo;
};

const inOrderFilter = (state, item, inOrderProductIds) => {
	if (state === 'off') return true;

	return state === 'include' ? inOrderProductIds.includes(item.id) : false;
};

const pendingFilter = (state, item, itemProp, deliveryDate) => {
	if (state === 'off') return item;
	const pending = ProductHelper.getPendingForDeliveryWeek(item[itemProp], deliveryDate);
	return state === 'include' ? pending : !pending;
};

const reservedFilter = (state, item, itemProp) => {
	if (state === 'off') return item;
	if (!item[itemProp]) return state !== 'include';
	let reservedCount = 0;
	Object.keys(item[itemProp]).forEach(datestamp => {
		reservedCount += item[itemProp][datestamp];
	});
	return state === 'include' ? reservedCount : !reservedCount;
};

const customFilters = (items, filters) => {
	items = endOfYear2020Filter(items, filters.endofyear2020);
	return items;
};

export default {
	filterItemsByQuery,
	stringContainsQuery,
	sortItemsByKey,
	rankItems,
	productFilterKeys,
	applyFilters
};
