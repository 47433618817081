import {
	ORDER_DELIVERY_DATE_SELECT,
	ORDER_DELIVERY_DATES_REQUEST,
	ORDER_DELIVERY_DATES_RECEIVE,
	ORDER_DELIVERY_DATES_INVALIDATE,
	ORDER_DELIVERY_DATES_REJECT,
	ORDER_LOAD_EXISTING_ORDER,
	ORDER_NEW,
	ORDER_RESET
} from './orderActions';
import DateHelper from './../../libraries/dateHelper';

export default function deliveryDates(state = {
	isFetching: false,
	didInvalidate: false,
	defaults: [],
	items: [],
	selected: null
}, action) {
	switch (action.type) {
		case ORDER_DELIVERY_DATE_SELECT:
			return {
				...state,
				selected: DateHelper.toReduxDateString(action.payload)
			};
		case ORDER_DELIVERY_DATES_REQUEST:
			return {
				isFetching: true,
				didInvalidate: false,
				items: [],
				defaults: [],
				selected: null
			};
		case ORDER_DELIVERY_DATES_RECEIVE:
			return {
				...state,
				isFetching: false,
				didInvalidate: false,
				items: action.data.deliveryDates ?? [],
				lastUpdated: action.receivedAt
			};
		case ORDER_DELIVERY_DATES_INVALIDATE:
			return {
				...state,
				didInvalidate: true
			};
		case ORDER_DELIVERY_DATES_REJECT:
			return {
				...state,
				error: action.error,
				isFetching: false
			};
		case ORDER_LOAD_EXISTING_ORDER:
			return {
				...state,
				selected: DateHelper.toReduxDateString(action.payload.selected)
			};
		case ORDER_NEW:
			return {
				...state,
				selected: null,
			};
		case ORDER_RESET:
			return {
				...state,
				selected: null
			};
		default:
			return state;
	}
}
