import React, { useEffect, useState } from 'react';
import './order.scss';
import StepsIndicator from './stepsIndicator/stepsIndicator';
import ProductSelectionStep from './productSelectionStep/productSelectionStep';
import DeliveryDateSelectionStep from './deliveryDateSelectionStep/deliveryDateSelectionStep';
import FinishStep from './FinishStep/FinishStep';
import ReviewOrderStep from './ReviewOrderStep/ReviewOrderStep';
import { t } from './../../libraries/i18n';
import { useRestrictedContent } from './../../libraries/hooks';
import ForbiddenError from '../../components/Error/ForbiddenError/ForbiddenError';
import {
	Switch,
	Route,
	Redirect,
	useRouteMatch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectCanEnterOrderStep1,
	selectCanEnterOrderStep2,
	selectCanEnterOrderStep3,
	selectCanEnterOrderStep4,
	selectCustomerId,
	selectOrderId,
	selectOrderLastVisitedStep,
	selectOrderReviewError,
	selectOrderSteps,
	selectOrderSubmitSuccess
} from './../selectors';
import OrderRoute from './OrderRoute/OrderRoute';
import {
	createNewOrder,
	createOrLoadOrderIfNeeded,
	fetchDeliveryDatesIfNeeded,
	saveOrderIfNeeded,
	tryForOrderIdIfNeeded
} from './orderActions';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';
import {
	fetchCampaignsIfNeeded,
	fetchCustomerProductsIfNeeded,
	fetchHistoryIfNeeded,
	fetchMetadataIfNeeded,
	fetchProductsIfNeeded,
	fetchPromosIfNeeded,
	fetchEobDetailsIfNeeded,
	fetchPointsIfNeeded
} from '../products/productsActions';
import { Spinner } from '@abm-international/react-components';
import { toast } from 'react-toastify';
import { Button } from '@abm-international/react-components';


function Order(props) {
	useCustomerRoute();
	const dispatch = useDispatch();
	const [RestrictedContent, RestrictedContentError] = useRestrictedContent(props.requiredPermission);
	const match = useRouteMatch();
	const steps = useSelector(selectOrderSteps);
	const canEnterStep1 = useSelector(selectCanEnterOrderStep1);
	const canEnterStep2 = useSelector(selectCanEnterOrderStep2);
	const canEnterStep3 = useSelector(selectCanEnterOrderStep3);
	const canEnterStep4 = useSelector(selectCanEnterOrderStep4);
	const lastVisitedPath = useSelector(selectOrderLastVisitedStep);
	const error = useSelector(selectOrderReviewError);
	const customerId = useSelector(selectCustomerId);
	const [fallbackPath, setFallbackPath] = useState('/order/delivery-date');
	const orderId = useSelector(selectOrderId);
	const submitSuccess = useSelector(selectOrderSubmitSuccess);

	useEffect(() => {
		if (!customerId) return;

		// TODO: Look into why the order of these async calls matters.
		// -> Max amount of connections per tab to the same remote === 6
		// Can we fix the blocking of these calls?
		// -> Yes. Less calls. 6 is kinda crazy...
		dispatch(createOrLoadOrderIfNeeded(customerId));
		dispatch(fetchDeliveryDatesIfNeeded(customerId));
		dispatch(fetchCampaignsIfNeeded());
		dispatch(fetchMetadataIfNeeded(customerId));
		dispatch(fetchPromosIfNeeded(customerId));
		dispatch(fetchPointsIfNeeded());
		dispatch(fetchProductsIfNeeded(customerId));
		dispatch(fetchCustomerProductsIfNeeded(customerId));
		dispatch(fetchHistoryIfNeeded(customerId));
		dispatch(fetchEobDetailsIfNeeded(customerId));
	}, [customerId, dispatch]);

	useEffect(() => {
		const lastVisitedStep = steps.find(step => {
			return step.route === lastVisitedPath;
		});
		const path = lastVisitedStep?.canEnter ? lastVisitedStep.route : '/order/delivery-date';
		if (path !== fallbackPath) setFallbackPath(path);
	}, [steps, lastVisitedPath, fallbackPath]);

	useEffect(() => {
		const SEND_ORDER_INTERVAL = 3000;
		const interval = setInterval(() => {
			dispatch(saveOrderIfNeeded());
		}, SEND_ORDER_INTERVAL);

		return () => clearInterval(interval);
	}, [dispatch]);

	useEffect(() => {
		const TRY_FOR_ORDER_ID_INTERVAL = 3000;
		const interval = setInterval(() => {
			dispatch(tryForOrderIdIfNeeded());
		}, TRY_FOR_ORDER_ID_INTERVAL);

		return () => clearInterval(interval);
	}, [dispatch]);

	const renderError = (code) => {
		return (
			<div className={'order__error'}>
				<div className={'error__message'}>
					{t(`errors.${code}`)}
				</div>
			</div>
		);
	};

	const resetSubmitSuccess = () => {
		dispatch({ type: 'ORDER_RESET_SUBMIT_SUCCESS' });
	};

	return (
		<div className='Order'>
			{submitSuccess && <div style={{
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				background: 'rgba(255, 255, 255, 0.85)',
				zIndex: 1000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontSize: 25,
				fontWeight: 'bold',
			}}>
				<div className={'ReviewOrderStep'}>
					<div className={'submit__success'}>
						<div className={'checkmark'}></div>
						<div style={{ paddingBottom: 40 }}>
							{t('order.reviewOrderSent')}
						</div>
						<Button onClick={resetSubmitSuccess} color='green'>
							{t('order.start_new_order')}
						</Button>
					</div>
				</div>
			</div>}

			{(!orderId && !submitSuccess) && <div style={{
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				background: 'rgba(255, 255, 255, 0.85)',
				zIndex: 1000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<Spinner />
			</div>}

			<StepsIndicator
				size={'thin'}
			/>

			<RestrictedContentError>
				<ForbiddenError permissionName={t('order.viewOrderPermission')} />
			</RestrictedContentError>

			<RestrictedContent>
				<div className={`order__content ${error && error.type !== 'connection' ? 'order__content--error' : ''}`}>
					{error && error.code && renderError(error.code)}

					<Switch>
						{canEnterStep1 && <OrderRoute path={`${match.path}/delivery-date`} component={DeliveryDateSelectionStep} />}
						{canEnterStep2 && <OrderRoute path={`${match.path}/products`} component={ProductSelectionStep} />}
						{canEnterStep3 && <OrderRoute path={`${match.path}/remarks`} component={FinishStep} />}
						{canEnterStep4 && <OrderRoute path={`${match.path}/review`} component={ReviewOrderStep} />}
						<Route>
							<Redirect to={fallbackPath} />
						</Route>
					</Switch>
				</div>
			</RestrictedContent>
		</div>
	);
}

export default Order;
