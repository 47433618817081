import React from 'react';
import { Link } from 'react-router-dom';

function OptionalLink(props) {

	const {
		to,
		className,
		children,
		...restProps
	} = props;

	if (!to) {
		return (
			<div
			// The reason I don't just do {...props} is that I get the following error:
			// Warning: Received `false` for a non-boolean attribute `to`.
			// This might make more sense (no guarantees): https://github.com/styled-components/styled-components/issues/439
				className={className ?? ''}
			>
				{children}
			</div>
		);
	}

	return (
		<Link
			to={to}
			className={className ?? ''}
			{...restProps}
		>
			{children}
		</Link>
	);

}

export default OptionalLink;
