import { createSelector } from 'reselect';

import Filter from './../libraries/filter';
import DateHelper from './../libraries/dateHelper';
import ProductHelper from './../libraries/productHelper';

import * as fromApp from './AppSelectors';
import * as fromProducts from './products/productsSelectors';
import * as fromOrder from './order/orderSelectors';
import * as fromCustomer from './customer/customerSelectors';
import * as fromBuylist from './buylist/buylistSelectors';
import * as fromDocuments from './Documents/documentsSelectors';
import * as fromPriceChanges from './PriceChanges/PriceChangesSelectors';
import * as fromReports from './Reports/ReportsSelectors';
import * as fromMargins from './Margins/MarginsSelectors';
import * as fromPreOrders from './PreOrders/preOrdersSelectors';
import promoTypes from './products/promoTypes';


// fromApp
export const selectIsNavCollapsed = state => fromApp.selectIsNavCollapsed(state.app);
export const selectLoadedTranslations = state => fromApp.selectLoadedTranslations(state.app);
export const selectFetchingTranslations = state => fromApp.selectFetchingTranslations(state.app);
export const selectLatestClientVersion = state => fromApp.selectLatestClientVersion(state.app);
export const selectRouteVersion = (state, path) => fromApp.selectRouteVersion(state.app, path);
export const selectHolidays = state => fromApp.selectHolidays(state.app);
export const selectSettings = state => fromApp.selectSettings(state.app);
export const selectSetting = (state, setting) => fromApp.selectSetting(state.app, setting);
export const selectIsResourcesListFetching = state => fromApp.selectIsResourcesListFetching(state.app);
export const selectDidResourcesListFail = state => fromApp.selectDidResourcesListFail(state.app);
export const selectResourcesList = state => fromApp.selectResourcesList(state.app);
export const selectEndpointById = (state, endpointId) => fromApp.selectEndpointById(state.app, endpointId);

// fromProducts
export const selectProducts = state => fromProducts.selectProducts(state.products);
export const selectPromos = state => fromProducts.selectPromos(state.products);
export const selectEobDetails = state => fromProducts.selectEobDetails(state.products);
export const selectShopcodes = state => fromProducts.selectShopcodes(state.products);
export const selectGroups = state => fromProducts.selectGroups(state.products);
export const selectProductById = (state, id, force) => fromProducts.selectProductById(state.products, id, force);
export const selectProductPointsById = (state, id) => fromProducts.selectProductPointsById(state.products, id);
export const selectProductPoints= (state, id) => fromProducts.selectProductPoints(state.products);
export const selectUniqueFilteredBrands = (state) => fromProducts.selectUniqueFilteredBrands(state);
export const selectIsProductsFetching = state => fromProducts.selectIsProductsFetching(state.products);
export const selectIsPromosFetching = state => fromProducts.selectIsPromosFetching(state.products);
export const selectIsCustomerProductsFetching = state => fromProducts.selectIsCustomerProductsFetching(state.products);
export const selectIsMetadataFetching = state => fromProducts.selectIsMetadataFetching(state.products);
export const selectCampaignsByProductId = (state, id) => fromProducts.selectCampaignsByProductId(state.products, id);
export const selectCampaigns = state => fromProducts.selectCampaigns(state.products);
export const selectPromoById = (state, id) => fromProducts.selectPromoById(state.products, id);
export const selectProductsLastUpdated = state => fromProducts.selectProductsLastUpdated(state.products);
export const selectProductsError = state => fromProducts.selectError(state.products);
export const selectIsCampaignsFetching = state => fromProducts.selectIsCampaignsFetching(state.products);
export const selectProductItems = state => fromProducts.selectItems(state.products);
export const selectCustomerProducts = state => fromProducts.selectCustomerProducts(state.products);
export const selectProductPromos = state => fromProducts.selectProductPromos(state.products);
export const selectProductPromosById = (state, id) => fromProducts.selectProductPromosById(state.products, id);
export const selectEobDetailsById = (state, id) => fromProducts.selectEobDetailsById(state.products, id);
export const selectEobDetailsByIdAndOrderdDate = (state, id, orderdDate, amount) => fromProducts.selectEobDetailsByIdAndOrderdDate(state.products, id, orderdDate, amount);
export const selectProductHistoryById = (state, id) => fromProducts.selectProductHistoryById(state.products, id);
export const selectProductHistories = state => fromProducts.selectProductHistories(state.products);
export const selectIsProductHistoriesFetching = state => fromProducts.selectIsProductHistoriesFetching(state.products);
export const selectProductsById = state => fromProducts.selectProductsById(state.products);

// fromOrder
export const selectOrderSteps = state => fromOrder.selectSteps(state.order);
export const selectCanEnterOrderStep1 = state => fromOrder.selectCanEnterStep1(state.order);
export const selectCanEnterOrderStep2 = state => fromOrder.selectCanEnterStep2(state.order);
export const selectCanEnterOrderStep3 = state => fromOrder.selectCanEnterStep3(state.order);
export const selectCanEnterOrderStep4 = state => fromOrder.selectCanEnterStep4(state.order);

export const selectOrderDetails = state => fromOrder.selectDetails(state.order);
export const selectOrderFilters = state => fromOrder.selectActiveFilters(state.order);
export const selectOrderQuery = state => fromOrder.selectQuery(state.order);
export const selectOpenOrderModifiedBy = state => fromOrder.selectModifiedBy(state.order);
export const selectDeliveryDate = state => fromOrder.selectDeliveryDate(state.order);
export const selectDeliveryDates = state => fromOrder.selectDeliveryDates(state.order);
export const selectIsDeliveryDatesFetching = state => fromOrder.selectIsDeliveryDatesFetching(state.order);
export const selectOrderSortKey = state => fromOrder.selectSortKey(state.order);
export const selectOrderSortDirection = state => fromOrder.selectSortDirection(state.order);
export const selectOrderQuantities = state => fromOrder.selectQuantities(state.order);
export const selectInOrderProductIds = state => fromOrder.selectInOrderProductIds(state.order);
export const selectOrderQuantityByProductId = (state, id) => fromOrder.selectQuantityByProductId(state.order, id);
export const selectOrderQuantityTimestampByProductId = (state, id) => fromOrder.selectQuantityTimestampByProductId(state.order, id);
export const selectIgnoreMadQuantity = state => fromOrder.selectIgnoreMadQuantity(state.order);
export const selectIgnoreMadQuantityById = (state, id) => fromOrder.selectIgnoreMadQuantityById(state.order, id);
export const selectOrderTotalProducts = state => fromOrder.selectOrderTotalProducts(state.order);
export const selectOrderView = state => fromOrder.selectView(state.order);
export const selectIsOrderSaving = state => fromOrder.selectIsSaving(state.order);
export const selectIsOrderSaveFinal = state => fromOrder.selectIsSaveFinal(state.order);
export const selectDidOrderSaveFail = state => fromOrder.selectDidSaveFail(state.order);
export const selectOrderLastSaved = state => fromOrder.selectLastSaved(state.order);
export const selectOrderLastUpdated = state => fromOrder.selectLastUpdated(state.order);
export const selectOrderHasPendingChanges = state => fromOrder.selectHasPendingChanges(state.order);
export const selectOrderSubmitSuccess = state => fromOrder.selectSubmitSuccess(state.order);
export const selectOrderDefaultFilters = state => fromOrder.selectDefaultFilters(state.order);
export const selectAwardedPromos = state => fromOrder.selectAwardedPromos(state.order);
export const selectAwardedPromoByProductId = (state, id) => fromOrder.selectAwardedPromoByProductId(state.order, id);

export const selectAwardedPromoByProductIdForPreOrder = (state, preOrderType, group, id) => fromPreOrders.selectAwardedPromoByProductId(state.preOrders, preOrderType, group, id);

export const selectOrderReviewError = state => fromOrder.selectReviewError(state.order);

export const selectOrderProducts = state => fromOrder.selectOrderProducts(state.order);
export const selectReviewOrderProducts = state => fromOrder.selectReviewOrderProducts(state.order);
export const selectOrderReviewIssues = state => fromOrder.selectOrderReviewIssues(state.order);
export const selectClientNotifications = state => fromOrder.selectClientNotifications(state.order);
export const selectNotifications = state => fromOrder.selectNotifications(state.order);
export const selectOrderAnswers = state => fromOrder.selectAnswers(state.order);
export const selectOrderReminders = state => fromOrder.selectReminders(state.order);
export const selectIsOrderFromServer = state => fromOrder.selectIsFromServer(state.order);
export const selectIsOpenOrdersFetching = state => fromOrder.selectIsOpenOrdersFetching(state.order);
export const selectOrderId = state => fromOrder.selectOrderId(state.order);
export const selectEobNetItems = state => fromOrder.selectEobNetItems(state.order);
export const selectIsEobFetching = state => fromOrder.selectIsEobFetching(state.order);
export const selectOrderRemarks = state => fromOrder.selectRemarks(state.order);

export const selectOrderInfo = state => {
	const infos = fromOrder.selectInfo(state.order);
	return infos.map(info => {
		if (info.data?.type === 'minimum_not_reached') {
			return {
				...info,
				data: {
					...info.data,
					products: info.data.products
						.map(productId => {
							if (productId === '0') return { id: '0' };
							return selectProductById(state, productId);
						})
						.filter(product => product.id ?? false)
				}
			};
		}
		return info;
	});
};
export const selectOrderQuestions = state => {
	const questions = fromOrder.selectQuestions(state.order);
	return questions.map(question => {
		if (question.data?.type === 'product_select') {
			return {
				...question,
				data: {
					...question.data,
					products: question.data.products
						.map(productId => {
							if (productId === '0') return { id: '0' };
							return selectProductById(state, productId);
						})
						.filter(product => product.id ?? false)
				}
			};
		}
		return question;
	});
};
export const selectOrderFinalSave = state => fromOrder.selectFinalSave(state.order);
export const selectOrderProductById = (state, id) => fromOrder.selectOrderProductById(state.order, id);
export const selectFailedOrderSaveAttempts = state => fromOrder.selectFailedSaveAttempts(state.order);
export const selectOrderLastVisitedStep = state => fromOrder.selectLastVisitedStep(state.order);

// fromBuylist
export const selectBuylistFilters = (state) => fromBuylist.selectActiveFilters(state.buylist);
export const selectBuylistQuery = (state) => fromBuylist.selectQuery(state.buylist);
export const selectBuylistSortKey = state => fromBuylist.selectSortKey(state.buylist);
export const selectBuylistSortDirection = state => fromBuylist.selectSortDirection(state.buylist);
export const selectBuylistView = state => fromBuylist.selectView(state.buylist);
export const selectBuylistDefaultFilters = state => fromBuylist.selectDefaultFilters(state.buylist);

// fromCustomer
export const selectCustomerCompanyName = state => fromCustomer.selectCompanyName(state.customer);
export const selectCustomerId = state => fromCustomer.selectCustomerId(state.customer);
export const selectCustomerCode = state => fromCustomer.selectCustomerCode(state.customer);
export const selectCanManageBuylist = state => fromCustomer.selectCanManageBuylist(state.customer);
export const selectLocale = state => fromCustomer.selectLocale(state.customer);
export const selectNavigationItems = state => fromCustomer.selectNavigationItems(state.customer);
export const selectCustomer = state => fromCustomer.selectCustomer(state.customer);
export const selectDeliveryAddress = state => fromCustomer.selectDeliveryAddress(state.customer);
export const selectInvoiceAddress = state => fromCustomer.selectInvoiceAddress(state.customer);
export const selectCanViewInvoices = state => fromCustomer.selectCanViewInvoices(state.customer);
export const selectCanViewTransportDocuments = state => fromCustomer.selectCanViewTransportDocuments(state.customer);
export const selectPermissions = state => fromCustomer.selectPermissions(state.customer);
export const selectCurrentUser = state => fromCustomer.selectCurrentUser(state.customer);
export const selectCustomerLastUpdated = state => fromCustomer.selectLastUpdated(state.customer);
export const selectCustomerError = state => fromCustomer.selectError(state.customer);
export const selectHasCustomerError = state => fromCustomer.selectHasError(state.customer);
export const selectIsCustomerFetching = state => fromCustomer.selectIsFetching(state.customer);
export const selectDidCustomerInvalidate = state => fromCustomer.selectDidInvalidate(state.customer);
export const selectCustomerAccount = state => fromCustomer.selectAccount(state.customer);

// fromDocuments
export const selectInvoicesData = state => fromDocuments.selectInvoicesData(state.documents);
export const selectIsInvoicesFetching = state => fromDocuments.selectIsInvoicesFetching(state.documents);
export const selectInvoices = state => fromDocuments.selectInvoices(state.documents);

export const selectTransportDocuments = state => fromDocuments.selectTransportDocuments(state.documents);
export const selectIsTransportDocumentsFetching = state => fromDocuments.selectIsFetching(state.documents);
export const selectDidTransportDocumentsFail = state => fromDocuments.selectDidTransportDocumentsFail(state.documents);
export const selectTransportdocumentsQuery = state => fromDocuments.selectTransportdocumentsQuery(state.documents);

// fromMargins
export const selectIsMarginsMetadataFetching = state => fromMargins.selectIsMetadataFetching(state.margins);
export const selectIsMarginsFetching = state => fromMargins.selectIsFetching(state.margins);
export const selectMargins = state => fromMargins.selectItems(state.margins);
export const selectUnsavedMargins = state => fromMargins.selectUnsavedItems(state.margins);
export const selectIsMarginsSaving = state => fromMargins.selectIsSaving(state.margins);
export const selectMarginsCategories = state => fromMargins.selectCategories(state.margins);

export const selectUnsavedDetailMargins = (state, category, productType, marginType) => fromMargins.selectUnsavedDetailItems(state.margins, category, productType, marginType);
export const selectIsDetailMarginsFetching = (state, category, productType, marginType) => fromMargins.selectIsDetailFetching(state.margins, category, productType, marginType);
export const selectIsDetailMarginsSaving = (state, category, productType, marginType) => fromMargins.selectIsDetailSaving(state.margins, category, productType, marginType);
export const selectDetailMargins = (state, category, productType, marginType) => fromMargins.selectDetailItems(state.margins, category, productType, marginType);
export const selectDetailMarginsAreFresh = (state, category, productType, marginType) => fromMargins.selectDetailItemsAreFresh(state.margins, category, productType, marginType);

// fromPriceChanges
export const selectIsPriceChangesFetching = state => fromPriceChanges.selectIsFetching(state.priceChanges);
export const selectPriceChanges = state => fromPriceChanges.selectItems(state.priceChanges);
export const selectPriceChangesQuery = state => fromPriceChanges.selectQuery(state.priceChanges);

// fromReports
export const selectReports = state => fromReports.selectSortedReports(state.reports);
export const selectIsReportsFetching = state => fromReports.selectIsFetching(state.reports);
export const selectReportsQuery = state => fromReports.selectQuery(state.reports);
export const selectReportsFrequencyFilter = state => fromReports.selectFrequencyFilter(state.reports);

// fromPreOrders
export const selectIsPreOrdersDataFetching = (state, preOrderType) => fromPreOrders.selectIsFetching(state.preOrders?.[preOrderType]);
export const selectPreOrdersActions = (state, preOrderType) => fromPreOrders.selectActions(state.preOrders?.[preOrderType]);
export const selectPreOrderAction = (state, group, preOrderType) => fromPreOrders.selectAction(state.preOrders?.[preOrderType], group);
export const selectPreOrderActionQuantities = (state, group, preOrderType) => fromPreOrders.selectActionQuantities(state.preOrders?.[preOrderType], group);
export const selectPreOrdersHasChanges = (state, preOrderType) => fromPreOrders.selectHasChanges(state.preOrders?.[preOrderType]);
export const selectPreOrderActionHasChanges = (state, group, preOrderType) => fromPreOrders.selectActionHasChanges(state.preOrders?.[preOrderType], group);
export const selectHasPreOrderActionFinalSaved = (state, group, preOrderType) => fromPreOrders.selectHasActionFinalSaved(state.preOrders?.[preOrderType], group);
export const selectIsPreOrderActionSaving = (state, group, preOrderType) => fromPreOrders.selectIsActionSaving(state.preOrders?.[preOrderType], group);
export const selectCanPreOrderActionsSubmit = (state, preOrderType, actions) => fromPreOrders.selectCanActionsSubmit(state.preOrders?.[preOrderType], actions);
export const selectCanPreOrderActionSubmit = (state, group, preOrderType) => fromPreOrders.selectCanActionSubmit(state.preOrders?.[preOrderType], group);
export const selectPreOrderActionSaveError = (state, group, preOrderType) => fromPreOrders.selectActionSaveError(state.preOrders?.[preOrderType], group);
export const selectPreOrderActionDeliveryDate = (state, group, preOrderType) => fromPreOrders.selectActionDeliveryDate(state.preOrders?.[preOrderType], group);
export const selectShowPreOrderSubmittedWarning = (state, preOrderType) => fromPreOrders.selectShowSubmittedWarning(state.preOrders?.[preOrderType]);
export const selectPreOrderTypes = state => fromCustomer.selectPreOrderTypes(state.customer);
export const selectIsSubmittingAllPreOrdersByType = (state, preOrderType) => fromPreOrders.selectIsSubmittingAllPreOrdersByType(state.preOrders?.[preOrderType]);
export const selectHasSubmittedAllPreOrdersByType = (state, preOrderType) => fromPreOrders.selectHasSubmittedAllPreOrdersByType(state.preOrders?.[preOrderType]);
export const selectDidSaveAllFail = (state, preOrderType) => fromPreOrders.selectDidSaveAllFail(state.preOrders?.[preOrderType]);
export const selectPreOrderActionLastSaveAttempt = (state, group, preOrderType) => fromPreOrders.selectPreOrderActionLastSaveAttempt(state.preOrders?.[preOrderType], group);
export const selectPreOrderActionExponentialBackoff = (state, group, preOrderType) => fromPreOrders.selectPreOrderActionExponentialBackoff(state.preOrders?.[preOrderType], group);


export const selectIsTranslationsFetching = createSelector(
	selectLocale,
	selectFetchingTranslations,
	(locale, fetchingTranslations) => fetchingTranslations.includes(locale)
);

export const selectHasCorrectTranslations = createSelector(
	selectLocale,
	selectLoadedTranslations,
	(locale, loadedTranslations) => loadedTranslations.includes(locale)
);

export const selectActiveOrderSortKey = createSelector(
	selectCustomerCompanyName,
	selectOrderSortKey,
	(companyName, sortKey) => {
		//const defaultSortKey = companyName === 'Charles Distributie nv' ? 'code' : 'shopcode';
		const defaultSortKey = 'shopcode';
		return sortKey || defaultSortKey;
	}
);

export const selectActiveCampaignsByProductId = createSelector(
	selectDeliveryDate,
	selectCampaignsByProductId,
	(deliveryDate, campaigns) => {
		if (!campaigns) return false;
		const filteredCampaigns = campaigns.filter(campaign =>
			campaign.start <= deliveryDate
			&& campaign.end >= deliveryDate
			&& campaign.start <= new Date()
			&& campaign.end >= new Date()
		);
		return filteredCampaigns.length <= 0 ? false : filteredCampaigns;
	}
);

export const selectCampaignsByProductIdAndDeliveryDate = (state, productId, deliveryDate) => {
	const campaigns = selectCampaignsByProductId(state, productId);

	if (!campaigns) return false;

	const filteredCampaigns = campaigns.filter(campaign =>
		new Date(campaign.start) <= deliveryDate
		&& new Date(campaign.end) >= deliveryDate
		&& new Date(campaign.start) <= new Date()
		&& new Date(campaign.end) >= new Date()
	);
	return filteredCampaigns.length <= 0 ? false : filteredCampaigns;
};

export const selectDetailedOrderProductById = createSelector(
	selectOrderProductById,
	selectOrderQuantityByProductId,
	selectIgnoreMadQuantityById,
	selectActiveCampaignsByProductId,
	(
		product,
		quantity,
		ignoreMadQuantity,
		campaigns
	) => {
		if (!product) return {};

		return {
			...product,
			quantity,
			showMadQuantityWarning: product.madQuantity !== null && quantity >= product.madQuantity && !ignoreMadQuantity,
			campaigns
			// TODO: awarded promos
			// TODO: activePromo
		};
	}
);

export const selectActiveCampaigns = createSelector(
	selectCampaigns,
	(campaigns) => {
		return campaigns.filter(campaign => {
			const now = new Date();
			const start = DateHelper.fromReduxDateString(campaign.start);
			const end = DateHelper.fromReduxDateString(campaign.end);
			return start <= now && end >= now;
		});
	}
);

export const selectActiveCampaignFilters = createSelector(
	selectActiveCampaigns,
	(campaigns) => {
		const filtersArticles = {};
		const filterCampaigns = campaigns.filter(campaign => campaign.articles?.filter !== null && campaign.articles?.filter !== undefined);
		filterCampaigns.forEach(campaign => {
			const filter = campaign.articles?.filter;
			if (filter === null || filter === undefined) return;

			if (filtersArticles[filter?.text.nl] === undefined) filtersArticles[filter?.text.nl] = campaign.articles?.ids;
			else filtersArticles[filter?.text.nl] = filtersArticles[filter?.text.nl].concat(campaign.articles?.ids);
		});

		const combinedCampaigns = Object.keys(filtersArticles).map(filterName => {
			const campaign = filterCampaigns.filter(campaign => campaign.articles?.filter?.text.nl === filterName)[0];
			return {
				...campaign,
				articles: {
					...campaign.articles,
					ids: filtersArticles[filterName]
				}
			};
		});

		return combinedCampaigns;
	}
);

export const selectFilteredOrderProducts = createSelector(
	selectOrderProducts,
	selectOrderFilters,
	selectOrderQuery,
	selectPromos,
	selectDeliveryDate,
	selectShopcodes,
	selectGroups,
	selectActiveOrderSortKey,
	selectOrderSortDirection,
	selectActiveCampaignFilters,
	selectInOrderProductIds,
	Filter.applyFilters
);

export const selectActiveBuylistSortKey = createSelector(
	selectCustomerCompanyName,
	selectBuylistSortKey,
	(companyName, sortKey) => {
		const defaultSortKey = 'shopcode';
		return sortKey || defaultSortKey;
	}
);

const selectBuylistProducts = createSelector(
	selectProducts,
	selectCanManageBuylist,
	(products, canManageBuylist) => {
		products = products.map(p => ({
			...p,
			promos: null,
			canRemoveFromBuylist: p.inBuylist && canManageBuylist,
			canAddToBuylist: !p.inBuylist && canManageBuylist,
			eob: !!p.eob
		}));

		return products;
	}
);

export const selectFilteredBuylistProducts = createSelector(
	selectBuylistProducts,
	selectBuylistFilters,
	selectBuylistQuery,
	selectShopcodes,
	selectGroups,
	selectActiveBuylistSortKey,
	selectBuylistSortDirection,
	(
		products,
		filters,
		query,
		shopcodes,
		groups,
		sortKey,
		sortDirection
	) => Filter.applyFilters(products, filters, query, null, null, shopcodes, groups, sortKey, sortDirection)
);

export const selectOrderTotalQuantityBreakdown = state => {
	const isFetching = selectIsProductsFetching(state);
	if (isFetching) {
		return {
			inOrder: 0,
			free: 0,
			total: 0
		};
	}

	const awardedPromos = selectAwardedPromos(state);
	const freeAmount = Object.values(awardedPromos).reduce((accumulator, awardedPromo) => {
		if (awardedPromo?.quantity) return accumulator + awardedPromo.quantity;
		return accumulator;
	}, 0);

	const quantities = selectOrderQuantities(state);
	const inOrder = Object.keys(quantities).reduce((accumulator, id) => {
		const product = selectDetailedOrderProductById(state, id);
		return accumulator + (product.orderAmount * quantities[id]) || quantities[id];
	}, 0);

	return {
		inOrder: inOrder,
		free: freeAmount,
		total: inOrder + freeAmount
	};
};

export const selectOrderTotalQuantity = state => selectOrderTotalQuantityBreakdown(state).total;

export const selectHasMadQuantityWarnings = state => {
	const quantities = selectOrderQuantities(state);
	const mqWarnings = Object.keys(quantities).filter(productId => {
		const mqIgnore = selectIgnoreMadQuantityById(state, productId);
		if (mqIgnore) return false;
		const quantity = quantities[productId];
		const product = selectOrderProductById(state, productId);
		return product.madQuantity !== null && quantity >= product.madQuantity;
	});
	return mqWarnings.length > 0;
};

export const selectIsOrderSubmittable = createSelector(
	selectIsProductsFetching,
	selectIsPromosFetching,
	selectIsCustomerProductsFetching,
	selectOrderTotalQuantity,
	selectHasMadQuantityWarnings,
	selectIsOrderSaving,
	selectOrderQuestions,
	selectOrderAnswers,
	selectOrderReviewIssues,
	(
		isProductsFetching,
		isPromosFetching,
		isCustomerProductsFetching,
		orderTotalQuantity,
		hasMadQuantityWarnings,
		isSaving,
		questions,
		answers,
		orderReviewIssues,
	) => {
		const isFetching = isProductsFetching || isPromosFetching || isCustomerProductsFetching;
		const emptyOrder = orderTotalQuantity <= 0;
		const hasUnansweredQuestions = questions
			.filter(question => question?.data?.optional === false)
			.some(question => answers?.[question?.code] === null || answers?.[question?.code] === undefined);
		return !isFetching && !emptyOrder && !hasMadQuantityWarnings && !isSaving && !hasUnansweredQuestions && !orderReviewIssues.length;
	}
);

export const selectActivePromoByProductId = (state, productId, date) => {
	const productPromos = selectProductPromosById(state, productId);
	if (!productPromos) return null;

	const promos = productPromos.map(promoId => selectPromoById(state, promoId));
	const promoDate = date ?? selectDeliveryDate(state);
	const activePromos = promos.filter(promo => ProductHelper.isPromoActive(promo, promoDate));
	if (activePromos.length <= 0) return null;

	const activePromo = activePromos[0];
	const promoWithTarget = {
		...activePromo,
		target: {
			...activePromo.target,
			product: selectOrderProductById(state, activePromo.target.id)
		}
	};
	if (!activePromo.trigger.barrel) {
		const triggerProduct = selectOrderProductById(state, activePromo.trigger.id);
		return {
			...promoWithTarget,
			trigger: {
				...promoWithTarget.trigger,
				unit: triggerProduct?.unit,
				name: triggerProduct?.name
			}
		};
	}

	const triggerProducts = activePromo.trigger.barrel.products.map(id => selectOrderProductById(state, id));

	return {
		...promoWithTarget,
		trigger: {
			...promoWithTarget.trigger,
			productsData: triggerProducts
		}
	};
};

export const selectIsOrderFinalSaving = createSelector(
	selectIsOrderSaving,
	selectIsOrderSaveFinal,
	(isSaving, finalSave) => isSaving && finalSave
);

export const selectAvailableShopcodes = createSelector(
	selectShopcodes,
	selectOrderProducts,
	(shopcodes, products) => {
		if (!products || !shopcodes) return undefined;
		return ProductHelper.getParentShopcodes(shopcodes, products.map(p => p.shopcode));
	}
);

const isColliPromo = (promo) => promo.trigger.barrel;

const getColliValues = (promo, quantities) => {
	let colliNumOfUniqueProducts = 0;
	let colliQuantity = 0;

	promo.trigger.barrel.products.forEach(id => {
		const productQuantity = quantities[id];
		if (productQuantity > 0) {
			colliNumOfUniqueProducts++;
			colliQuantity += productQuantity;
		}
	});

	return [colliQuantity, colliNumOfUniqueProducts];
};

const colliPromoActivated = (promo, unique, totalQuantity) => {
	const enoughQuantity = totalQuantity >= promo.trigger.num;
	const enoughArticles = unique >= promo.trigger.barrel.num;
	return enoughQuantity && enoughArticles;
};

const promoActivated = (promo, quantity) => quantity >= promo.trigger.num;

const isTrigger = (promo, articleId) => {
	if (isColliPromo(promo)) return promo.trigger.barrel.products.includes(p => p.toString() === articleId.toString());

	return promo.trigger.id.toString() === articleId.toString();
};

const isTarget = (promo, articleId) => {
	if (promo.target.id.toString() === '0') return true;
	return promo.target.id.toString() === articleId.toString();
};

const freeByUnitPromo = (promo, articleId, quantity) => {
	if (isTarget(promo, articleId)) {
		return {
			quantity: Math.floor(quantity / promo.trigger.num) * parseInt(promo.target.num)
		};
	}

	if (isTrigger(promo, articleId)) return true;
	return false;
};


export const selectPreOrderActionAwardedPromo = (state, group, articleId, promo, preOrderType) => {
	if (!promo) return false;

	const quantities = selectPreOrderActionQuantities(state, group, preOrderType);

	if (isColliPromo(promo)) {
		const [colliQuantity, unique] = getColliValues(promo, quantities);

		if (colliPromoActivated(promo, unique, colliQuantity)) {
			if (promo.type === promoTypes.FREE_BY_UNIT) return freeByUnitPromo(promo, articleId, colliQuantity);
			return true;
		}
		return false;
	}

	const quantity = quantities?.[promo.trigger.id];

	if (promoActivated(promo, quantity)) {
		if (promo.type === promoTypes.FREE_BY_UNIT) return freeByUnitPromo(promo, articleId, quantity);

		return quantity >= promo.trigger.num;
	}

	return false;
};
