import * as DateHelper from '../../../libraries/dateHelper';
import { t } from '../../../libraries/i18n';
import React from 'react';
import { selectDeliveryDate, selectDeliveryDates } from '../../selectors';
import { useAppSelector } from '../../../libraries/hooks/redux';

type DeliveryDate = {
	date: string
	deadline: string
	isDivergentDeadline: boolean
};

const getDeadlineTextForDate = (deliveryDate: string, tomorrow: string, deadline: string) => {
	const ppDay = (dateString: string) => {
		const date = DateHelper.getDateFromDateString(dateString);
		const month = DateHelper.getMonthName(date?.getMonth());
		const day = date?.getDate();
		return `${day} ${month}`;
	};

	const ppDayTime = (dateString: string) => {
		const date = new Date(dateString);
		const hours = date.getHours();
		const minutes = `0${date.getMinutes()}`.slice(-2);
		return [ppDay(DateHelper.getDateString(date)!), hours, minutes];
	};

	const [deadlineDayText, deadlineHours, deadlineMinutes] = ppDayTime(deadline);
	if (deliveryDate === tomorrow) {
		// if the delivery date is tomorrow, show the hours and minutes of the deadline (that is today)
		return t('order.deliveryDateTomorrowDeadlineBeforeX', deadlineHours, deadlineMinutes);
	}

	const deliveryDayText = ppDay(deliveryDate);
	const isDeadlineToday = DateHelper.isToday(new Date(deadline));

	if (isDeadlineToday) {
		// delivery date is not tomorrow but deadline is today. Specify the (divergent) deadline
		return t('order.deliveryDateDivergentDeadlineBeforeTodayX', deliveryDayText, null, deadlineHours, deadlineMinutes);
	}

	// deadline not today. Specify the day and time of the deadline along with the deliveryDate.
	return t('order.deliveryDateDivergentDeadlineBeforeX', deliveryDayText, deadlineDayText, deadlineHours, deadlineMinutes);
};

const Deadlines = () => {
	const dates = useAppSelector(selectDeliveryDates);
	const selectedDate = useAppSelector(selectDeliveryDate);
	const tomorrow = new Date();
	tomorrow.setDate((new Date()).getDate() + 1);


	return (
		<div className={'deadlines'}>
			<ul>
				{dates.map(({ date, deadline, isDivergentDeadline }: DeliveryDate) => {
					const isSelected = date === DateHelper.getDateString(selectedDate);
					const isNear = new Date(deadline) <= tomorrow;
					const shouldDisplay = isDivergentDeadline || (isSelected && isNear);

					if (!shouldDisplay) return null;

					const deadlineText = getDeadlineTextForDate(date, DateHelper.getDateString(tomorrow)!, deadline);

					return (<li key={date} className={`deadline ${(isSelected && isNear) ? 'deadline__warning' : ''}`} dangerouslySetInnerHTML={{ __html: deadlineText }} />);
				})}
			</ul>
		</div>
	);
};

export default Deadlines;
