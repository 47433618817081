import React, { useEffect } from 'react';
import {
	Route,
	Switch,
	useHistory
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './promos.scss';
import {
	selectActiveCampaigns
} from './../selectors';
import {
	CampaignsOverview
} from '@abm-international/react-components';
import PromoDetail from './PromoDetail';
import { fetchCampaignsIfNeeded } from '../products/productsActions';

function Promos() {
	const campaigns = useSelector(selectActiveCampaigns);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCampaignsIfNeeded());
	}, [dispatch]);

	const handleClick = (campaign) => history.push(`/promos/${campaign.id}`);

	return (
		<div className={'Promos'}>
			<Switch>
				<Route exact path={'/promos'}><CampaignsOverview campaigns={campaigns} handleClick={handleClick} /></Route>
				<Route path={'/promos/:id'}><PromoDetail /></Route>
			</Switch>
		</div>
	);
}

export default Promos;
