export const selectItems = state => state.items ?? [];
export const selectUnsavedItems = state => state.unsavedItems;

export const selectCategories = state => Object.values(state.metadata.categories ?? {});
export const selectIsMetadataFetching = state => state.metadata.isFetching ?? false;

export const selectIsFetching = state => state.isFetching ?? false;
export const selectIsSaving = state => state.isSaving ?? false;



export const selectDetail = (state, category, productType, marginType) =>
	state.detail[`${category}${marginType ?? '-'}${productType}`];


export const selectUnsavedDetailItems = (state, category, productType, marginType) => {
	const detail = selectDetail(state, category, productType, marginType);
	if (!detail?.unsavedItems) return [];

	return detail.unsavedItems;
};

export const selectIsDetailFetching = (state, category, productType, marginType) =>
	selectDetail(state, category, productType, marginType)?.isFetching ?? false;

export const selectIsDetailSaving = (state, category, productType, marginType) =>
	selectDetail(state, category, productType, marginType)?.isSaving ?? false;

export const selectDetailItems = (state, category, productType, marginType) => {
	const detail = selectDetail(state, category, productType, marginType);
	if (detail){
		const { category, articles } = detail;
		return { category, articles };
	}
	return {};
};

export const selectDetailItemsAreFresh = (state, category, productType, marginType) => {
	const detail = selectDetail(state, category, productType, marginType);
	return !!detail?.fresh;
};
