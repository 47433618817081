import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import RootContainer from './modules/rootContainer';
import './index.scss';
import { logger } from './libraries/logger/logger';
import BrowserConsoleTransport from './libraries/logger/browserConsoleTransport';
import HttpTransport from './libraries/logger/httpTransport';
import config from './config';
import { Gtag } from './modules/common/utils';

//is asynchroon
if (config.ENV !== 'production') {
	const { worker } = require('./mocks/browser');
	worker.start();
}
//if async gives trouble try:
// function prepare() {
// 	if (process.env.NODE_ENV === 'development') {
// 		const { worker } = require('./mocks/browser');
// 		return worker.start();
// 	}
// 	return Promise.resolve();
// }
// prepare().then(() => {
// 	//HERE COMES EVERYTHING
// });

// Initialize analytics
if (config.ENV === 'production' && config.GA_ID) {
	Gtag.init(config.GA_ID);
}

logger.init({
	transports: [
		new BrowserConsoleTransport({
			level: process.env.REACT_APP_BROWSER_LOGGING_LEVEL || 'info',
		}),
		new HttpTransport({
			endpoint: process.env.REACT_APP_LOGGING_ENDPOINT,
			// Do NOT put this on SILLY; too much traffic!
			level: process.env.REACT_APP_HTTP_LOGGING_LEVEL || 'warn',
		}),
	],
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<RootContainer />);
