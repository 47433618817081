import UserPreferences from './userPreferences';
import DateHelper from './dateHelper';
import { logger } from './logger/logger';

const getShopcode = (shopcodes, shopcode) => {
	if (shopcode === '0' || shopcode === undefined) return [];
	const locale = UserPreferences.get(UserPreferences.PREFERENCES.LOCALE);

	let level1 = `${shopcode.substr(0, 1)}0000000`;
	let level2 = `${shopcode.substr(0, 2)}000000`;
	let level3 = `${shopcode.substr(0, 4)}0000`;
	let level4 = `${shopcode.substr(0, 6)}00`;
	let level5 = shopcode;

	level1 = shopcodes?.[level1]?.desc?.[locale] ?? '???';
	level2 = shopcodes?.[level2]?.desc?.[locale] ?? '???';
	level3 = shopcodes?.[level3]?.desc?.[locale] ?? '???';
	level4 = shopcodes?.[level4]?.desc?.[locale] ?? '???';
	level5 = shopcodes?.[level5]?.desc?.[locale] ?? '???';

	if (level1 === '???' || level2 === '???' || level3 === '???' || level4 === '???' || level5 === '???') {
		return false;
	}

	return [
		level1,
		level2,
		level3,
		level4,
		level5
	];
};

const getGroup = (groups, main = false, sub = false) => {
	try {
		if (!main || !sub) return { main: '', sub: '' };
		const locale = UserPreferences.get(UserPreferences.PREFERENCES.LOCALE);
		const mainGroup = groups[parseInt(main)];
		const subGroup = mainGroup.sub[parseInt(sub)];

		const group = {
			main: '',
			sub: ''
		};

		if (mainGroup && mainGroup.desc && mainGroup.desc[locale]) group.main = mainGroup.desc[locale];
		else logger.warn('Main group does not exist', { main, sub });

		if (subGroup && subGroup.desc && subGroup.desc[locale]) group.sub = subGroup.desc[locale];
		else logger.warn('Sub group does not exist', { main, sub });

		return group;
	} catch (error) {
		logger.warn('Can\'t get group', error);
		return {
			main: '',
			sub: ''
		};
	}
};

const getActivePromoByProductId = (productId, promos, deliveryDate, promoIds) => {
	let productPromos;
	if (promoIds === false) return null;
	if (promoIds) productPromos = promoIds.map(id => promos[id]);
	if (promoIds === null || promoIds === undefined) productPromos = getProductPromos(productId, promos);
	return getActivePromo(productPromos, deliveryDate);
};

const getEobDetailByProductId = (productId, eobDetails) => {
	if (!productId || !eobDetails) return null;
	return eobDetails[productId];
};


const getProductPromos = (productId, promos) => {
	if (!productId || !promos) return null;

	return Object.keys(promos).map(id => promos[id]).filter(promo => {
		if (promo.trigger.barrel) {
			if (promo.trigger.barrel.products.includes(parseInt(productId))) return true;
			return promo.target.id === parseInt(productId);
		}

		return promo.trigger.id === productId;
	});
};

const getActivePromo = (promos, deliveryDate) => {
	if (!promos || !deliveryDate) return null;

	let activePromo = null;
	promos.forEach(productPromo => {
		if (isPromoActive(productPromo, deliveryDate)) activePromo = productPromo;
	});

	return activePromo;
};

const isPromoActive = (promo, deliveryDate) => {
	if (!promo || !deliveryDate) return false;

	const now = new Date();
	const availableStart = new Date(promo.available.start);
	const availableEnd = new Date(promo.available.end);
	const visibleStart = new Date(promo.visible.start);
	const visibleEnd = new Date(promo.visible.end);

	return availableStart<= deliveryDate
		&& availableEnd >= deliveryDate
		&& visibleStart <= now
		&& visibleEnd >= now;
};

const getActiveCampaignsByProductId = (productId, campaigns) => {
	const productCampaigns = getProductCampaigns(productId, campaigns);
	return productCampaigns.filter(isCampaignActive);
};

const getProductCampaigns = (productId, campaigns) => {
	if (!productId || !campaigns) return null;

	return campaigns.filter(campaign => {
		if (!campaign.articles) return false;
		if (campaign.articles?.ids?.includes(parseInt(productId))) return true;
		return false;
	});
};

const isCampaignActive = (campaign) => {
	return DateHelper.fromReduxDateString(campaign.start) <= new Date()
		&& DateHelper.fromReduxDateString(campaign.end) >= new Date();
};

const getPendingForDeliveryWeek = (pending, deliveryDate) => {
	if (!pending) return 0;
	if (!deliveryDate) deliveryDate = new Date();
	const pendingByWeek = {};
	Object.keys(pending).forEach(datestamp => {
		const date = DateHelper.getDateFromReversedDateString(datestamp);
		const week = DateHelper.getWeekNumber(date)[1];
		pendingByWeek[week] = pending[datestamp] + (pendingByWeek[week] || 0);
	});

	const deliveryWeek = DateHelper.getWeekNumber(new Date(deliveryDate))[1];
	return pendingByWeek[deliveryWeek] || 0;
};

const getShopcodeLevel = (code) => {
	if (code.substr(-7, 8) === '0000000') return 1;
	else if (code.substr(1, 1) !== '0' && code.substr(-6, 8) === '000000') return 2;
	else if (code.substr(2, 2) !== '00' && code.substr(-4, 8) === '0000') return 3;
	else if (code.substr(4, 2) !== '00' && code.substr(-2, 8) === '00') return 4;
	else return 5;
};

const convertShopcode = (code, level) => {
	switch (level) {
		case 1:
			return `${code.substr(0, 1)}0000000`;
		case 2:
			return `${code.substr(0, 2)}000000`;
		case 3:
			return `${code.substr(0, 4)}0000`;
		case 4:
			return `${code.substr(0, 6)}00`;
		case 5:
			return `${code.substr(0, 8)}`;
		default:
			return '';
	}
};

const getParentShopcodes = (allShopcodes, level5Codes) => {
	const shopcodes = {};
	const invalidShopcodes = new Set();
	level5Codes = level5Codes.filter(code => {
		if (typeof code !== 'string') return false;
		if (code.length !== 8) return false;
		return true;
	});

	level5Codes.forEach(level5Code => {
		const level1Code = convertShopcode(level5Code, 1);
		const level2Code = convertShopcode(level5Code, 2);
		const level3Code = convertShopcode(level5Code, 3);
		const level4Code = convertShopcode(level5Code, 4);

		if (allShopcodes?.[level1Code]) shopcodes[level1Code] = allShopcodes?.[level1Code];
		else invalidShopcodes.add(level5Code);

		if (allShopcodes?.[level2Code]) shopcodes[level2Code] = allShopcodes?.[level2Code];
		else invalidShopcodes.add(level5Code);

		if (allShopcodes?.[level3Code]) shopcodes[level3Code] = allShopcodes?.[level3Code];
		else invalidShopcodes.add(level5Code);

		if (allShopcodes?.[level4Code]) shopcodes[level4Code] = allShopcodes?.[level4Code];
		else invalidShopcodes.add(level5Code);

		if (allShopcodes?.[level5Code]) shopcodes[level5Code] = allShopcodes?.[level5Code];
		else invalidShopcodes.add(level5Code);
	});

	// if (invalidShopcodes.size > 0) console.log({ invalidShopcodes });

	return shopcodes;
};

export default {
	getShopcode,
	getGroup,
	getActivePromo,
	getActivePromoByProductId,
	getActiveCampaignsByProductId,
	isPromoActive,
	getPendingForDeliveryWeek,
	getShopcodeLevel,
	convertShopcode,
	getParentShopcodes,
	getEobDetailByProductId
};
