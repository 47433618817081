import {
	PRODUCTS_CUSTOMER_INVALIDATE,
	PRODUCTS_CUSTOMER_RECEIVE,
	PRODUCTS_CUSTOMER_REQUEST,
	PRODUCTS_CUSTOMER_REQUEST_FAIL,
	PRODUCTS_HISTORY_INVALIDATE,
	PRODUCTS_HISTORY_RECEIVE,
	PRODUCTS_HISTORY_REJECT,
	PRODUCTS_HISTORY_REQUEST,
	PRODUCTS_INVALIDATE,
	PRODUCTS_METADATA_INVALIDATE,
	PRODUCTS_METADATA_RECEIVE,
	PRODUCTS_METADATA_REJECT,
	PRODUCTS_METADATA_REQUEST,
	PRODUCTS_PROMOS_INVALIDATE,
	PRODUCTS_PROMOS_RECEIVE,
	PRODUCTS_PROMOS_REQUEST,
	PRODUCTS_PROMOS_REQUEST_FAIL,
	PRODUCTS_POINTS_INVALIDATE,
	PRODUCTS_POINTS_RECEIVE,
	PRODUCTS_POINTS_REQUEST,
	PRODUCTS_POINTS_REQUEST_FAIL,
	PRODUCTS_RECEIVE,
	PRODUCTS_REQUEST,
	PRODUCTS_REQUEST_FAIL,
	PRODUCTS_CAMPAIGNS_INVALIDATE,
	PRODUCTS_CAMPAIGNS_REQUEST,
	PRODUCTS_CAMPAIGNS_RECEIVE,
	PRODUCTS_CAMPAIGNS_REJECT,
	PRODUCTS_EOB_DETAILS_INVALIDATE,
	PRODUCTS_EOB_DETAILS_RECEIVE,
	PRODUCTS_EOB_DETAILS_REQUEST,
	PRODUCTS_EOB_DETAILS_REQUEST_FAIL
} from './productsActions';
import { APP_LOADED } from './../appActions';
import initialState from './../initialState';

export default function products(
	state = initialState.products,
	action
) {
	switch (action.type) {
		case APP_LOADED:
			return { ...state };
		case PRODUCTS_INVALIDATE:
			return productsInvalidate(state, action);
		case PRODUCTS_REQUEST:
			return productsRequest(state, action);
		case PRODUCTS_RECEIVE:
			return productsReceive(state, action);
		case PRODUCTS_REQUEST_FAIL:
			return productsRequestFail(state, action);

		case PRODUCTS_PROMOS_INVALIDATE:
			return promosInvalidate(state, action);
		case PRODUCTS_PROMOS_REQUEST:
			return promosRequest(state, action);
		case PRODUCTS_PROMOS_RECEIVE:
			return promosReceive(state, action);
		case PRODUCTS_PROMOS_REQUEST_FAIL:
			return promosRequestFail(state, action);

		case PRODUCTS_POINTS_INVALIDATE:
			return pointsInvalidate(state, action);
		case PRODUCTS_POINTS_REQUEST:
			return pointsRequest(state, action);
		case PRODUCTS_POINTS_RECEIVE:
			return pointsReceive(state, action);
		case PRODUCTS_POINTS_REQUEST_FAIL:
			return pointsRequestFail(state, action);

		case PRODUCTS_EOB_DETAILS_INVALIDATE:
			return eobDetailsInvalidate(state, action);
		case PRODUCTS_EOB_DETAILS_REQUEST:
			return eobDetailsRequest(state, action);
		case PRODUCTS_EOB_DETAILS_RECEIVE:
			return eobDetailsReceive(state, action);
		case PRODUCTS_EOB_DETAILS_REQUEST_FAIL:
			return eobDetailsRequestFail(state, action);

		case PRODUCTS_CUSTOMER_INVALIDATE:
			return productsCustomerInvalidate(state, action);
		case PRODUCTS_CUSTOMER_REQUEST:
			return productsCustomerRequest(state, action);
		case PRODUCTS_CUSTOMER_RECEIVE:
			return productsCustomerReceive(state, action);
		case PRODUCTS_CUSTOMER_REQUEST_FAIL:
			return productsCustomerRequestFail(state, action);

		case PRODUCTS_METADATA_INVALIDATE:
			return productsMetadataInvalidate(state, action);
		case PRODUCTS_METADATA_REQUEST:
			return productsMetadataRequest(state, action);
		case PRODUCTS_METADATA_RECEIVE:
			return productsMetadataReceive(state, action);
		case PRODUCTS_METADATA_REJECT:
			return productsMetadataReject(state, action);

		case PRODUCTS_HISTORY_INVALIDATE:
			return productsHistoryInvalidate(state, action);
		case PRODUCTS_HISTORY_REQUEST:
			return productsHistoryRequest(state, action);
		case PRODUCTS_HISTORY_RECEIVE:
			return productsHistoryReceive(state, action);
		case PRODUCTS_HISTORY_REJECT:
			return productsHistoryReject(state, action);

		case PRODUCTS_CAMPAIGNS_INVALIDATE:
			return productsCampaignsInvalidate(state, action);
		case PRODUCTS_CAMPAIGNS_REQUEST:
			return productsCampaignsRequest(state, action);
		case PRODUCTS_CAMPAIGNS_RECEIVE:
			return productsCampaignsReceive(state, action);
		case PRODUCTS_CAMPAIGNS_REJECT:
			return productsCampaignsReject(state, action);

		default:
			return state;
	}
}

function productsInvalidate(state, action) {
	return {
		...state,
		didInvalidate: true
	};
}

function productsRequest(state, action) {
	return {
		...state,
		isFetching: true,
		didInvalidate: false
	};
}

function productsReceive(state, action) {
	const items = {};
	Object.keys(action.products).forEach(k => {
		items[k] = {
			...state.items[k],
			...action.products[k]
		};
	});

	return {
		...state,
		isFetching: false,
		didInvalidate: false,
		items: items,
		lastUpdated: action.receivedAt,
		error: undefined
	};
}

function productsRequestFail(state, action) {
	const gracefulTimeout = state.error && state.error.gracefulTimeout ? state.error.gracefulTimeout * 2 : 2;

	return {
		...state,
		isFetching: false,
		error: {
			...action.error,
			gracefulTimeout: gracefulTimeout
		}
	};
}

function promosInvalidate(state, action) {
	return {
		...state,
		promos: {
			...state.promos,
			didInvalidate: true
		}
	};
}

function pointsInvalidate(state, action) {
	return {
		...state,
		points: {
			...state.points,
			didInvalidate: true
		}
	};
}

function promosRequest(state, action) {
	return {
		...state,
		promos: {
			...state.promos,
			isFetching: true,
			didInvalidate: false
		}
	};
}

function pointsRequest(state, action) {
	return {
		...state,
		points: {
			...state.points,
			isFetching: true,
			didInvalidate: false
		}
	};
}

function promosReceive(state, action) {
	const productPromos = { ...state.productPromos } || {};

	Object.keys(action.promos).forEach(k => {
		const promo = action.promos[k];
		if (promo.trigger.barrel) {
			promo.trigger.barrel.products.forEach(id => {
				productPromos[id] = [
					...productPromos[id] || [],
					promo.id
				];
			});

			if (promo.target.id !== 0) {
				productPromos[promo.target.id] = [
					...productPromos[promo.target.id] || [],
					promo.id
				];
			}
		} else {
			productPromos[promo.trigger.id] = [
				...productPromos[promo.trigger.id] || [],
				promo.id
			];
		}

		// TODO: test this whacky fix
		// let's add the target as well
		productPromos[promo.target.id] = [
			...productPromos[promo.target.id] || [],
			promo.id
		];
	});

	return {
		...state,
		promos: {
			...state.promos,
			isFetching: false,
			didInvalidate: false,
			items: action.promos,
			lastUpdated: action.receivedAt,
			error: undefined
		},
		productPromos
	};
}

function pointsReceive(state, action) {
	const productPoints = { ...state.productPoints } || {};

	return {
		...state,
		points: {
			...state.points,
			isFetching: false,
			didInvalidate: false,
			items: action.points,
			lastUpdated: action.receivedAt,
			error: undefined
		},
		productPoints
	};
}

function promosRequestFail(state, action) {
	const gracefulTimeout = state.error && state.error.gracefulTimeout ? state.error.gracefulTimeout * 2 : 2;

	return {
		...state,
		promos: {
			...state.promos,
			isFetching: false,
			error: {
				...action.error,
				gracefulTimeout: gracefulTimeout
			}
		}
	};
}

function pointsRequestFail(state, action) {
	const gracefulTimeout = state.error && state.error.gracefulTimeout ? state.error.gracefulTimeout * 2 : 2;

	return {
		...state,
		points: {
			...state.points,
			isFetching: false,
			error: {
				...action.error,
				gracefulTimeout: gracefulTimeout
			}
		}
	};
}

function eobDetailsInvalidate(state, action) {
	return {
		...state,
		eobDetails: {
			...state.eobDetails,
			didInvalidate: true
		}
	};
}

function eobDetailsRequest(state, action) {
	return {
		...state,
		eobDetails: {
			...state.eobDetails,
			isFetching: true,
			didInvalidate: false
		}
	};
}

function eobDetailsReceive(state, action) {
	return {
		...state,
		eobDetails: {
			...state.eobDetails,
			isFetching: false,
			didInvalidate: false,
			items: action.eobDetails,
			lastUpdated: action.receivedAt,
			error: undefined
		}
	 };
}

function eobDetailsRequestFail(state, action) {
	const gracefulTimeout = state.error && state.error.gracefulTimeout ? state.error.gracefulTimeout * 2 : 2;

	return {
		...state,
		eobDetails: {
			...state.eobDetails,
			isFetching: false,
			error: {
				...action.error,
				gracefulTimeout: gracefulTimeout
			}
		}
	};
}

function productsCustomerInvalidate(state, action) {
	return {
		...state,
		didCustomerProductsInvalidate: true
	};
}

function productsCustomerRequest(state, action) {
	return {
		...state,
		isCustomerProductsFetching: true,
		didCustomerProductsInvalidate: false
	};
}

function productsCustomerReceive(state, action) {
	const items = {};
	Object.keys(action.data.products).forEach(k => {
		items[k] = {
			...state.items[k],
			...action.data.customerProducts[k],
			...action.data.products[k]
		};
	});

	return {
		...state,
		isCustomerProductsFetching: false,
		didCustomerProductsInvalidate: false,
		customerProducts: action.data.customerProducts,
		items: {
			...state.items,
			...items
		},
		lastUpdatedCustomerProducts: action.receivedAt,
		error: undefined
	};
}

function productsCustomerRequestFail(state, action) {
	const gracefulTimeout = state.error && state.error.gracefulTimeout ? state.error.gracefulTimeout * 2 : 2;

	return {
		...state,
		isCustomerProductsFetching: false,
		error: {
			...action.error,
			gracefulTimeout: gracefulTimeout
		}
	};
}

function productsMetadataInvalidate(state, action) {
	return {
		...state,
		metadata: {
			...state.metadata,
			didInvalidate: true
		}
	};
}

function productsMetadataRequest(state, action) {
	return {
		...state,
		metadata: {
			...state.metadata,
			isFetching: true
		}
	};
}

function productsMetadataReceive(state, action) {
	return {
		...state,
		metadata: {
			...state.metadata,
			isFetching: false,
			lastUpdated: action.receivedAt,
			...action.metadata
		}
	};
}

function productsMetadataReject(state, action) {
	return {
		...state,
		metadata: {
			...state.metadata,
			isFetching: false,
			error: action.error
		}
	};
}

function productsHistoryInvalidate(state, action) {
	return {
		...state,
		history: {
			...state.history,
			didInvalidate: true
		}
	};
}

function productsHistoryRequest(state, action) {
	return {
		...state,
		history: {
			...state.history,
			isFetching: true
		}
	};
}

function productsHistoryReceive(state, action) {
	return {
		...state,
		history: {
			...state.history,
			isFetching: false,
			lastUpdated: action.receivedAt,
			items: action.history
		}
	};
}

function productsHistoryReject(state, action) {
	return {
		...state,
		history: {
			...state.history,
			isFetching: false,
			error: action.error
		}
	};
}

function productsCampaignsInvalidate(state, action) {
	return {
		...state,
		campaigns: {
			...state.campaigns,
			didInvalidate: true
		}
	};
}

function productsCampaignsRequest(state, action) {
	return {
		...state,
		campaigns: {
			...state.campaigns,
			isFetching: true
		}
	};
}

function productsCampaignsReceive(state, action) {
	return {
		...state,
		campaigns: {
			...state.campaigns,
			isFetching: false,
			lastUpdated: action.receivedAt,
			items: action.campaigns
		}
	};
}

function productsCampaignsReject(state, action) {
	return {
		...state,
		campaigns: {
			...state.campaigns,
			isFetching: false,
			error: action.error
		}
	};
}
