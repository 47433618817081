import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Setting } from '../types/settings';
import { updateSetting } from '../libraries/api';
import { selectSetting } from '../modules/selectors';
import { invalidateCustomer, fetchCustomerIfNeeded } from '../modules/customer/customerActions';

export default function useUserSetting(key: Setting, fallbackValue: any) {
	const [value, setValue] = useState<any>(fallbackValue);
	const actualValue = useSelector((state) => selectSetting(state, key));
	const dispatch = useDispatch();

	const update = async (newValue: any) => {
		setValue(newValue);

		await updateSetting(key, newValue);

		dispatch(invalidateCustomer());
		dispatch(fetchCustomerIfNeeded());
	};

	useEffect(() => {
		setValue(actualValue);
	}, [actualValue]);

	return [
		value,
		update
	];
}
