import { rest } from 'msw';

export const getMe = [
	rest.get('http://localhost:4000/api/me', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'id': '10101444||BRARO',
					'company': {
						'name': "MOCKING Cock's Vleeswaren nv",
						'address.street': 'MOCKING Industriepark-Noord 14',
						'address.postalcode': 9100,
						'address.city': ' MOCKINGSint-Niklaas',
						'address': {
							'street': 'MOCKING Industriepark-Noord 14',
							'postalCode': 9100,
							'city': 'MOCKING Sint-Niklaas'
						},
						'sales.phone': '+32 (0)3 760.12.73',
						'sales.email': 'MOCKING sales@charles.eu',
						'phone': '+32 (0)3 760.12.73',
						'email': 'sales@charles.eu',
						'closed': [
							'2021-07-21',
							'2021-08-15',
							'2021-11-01',
							'2021-11-11',
							'2021-12-25',
							'2022-01-01',
							'2022-04-18',
							'2022-05-01',
							'2022-05-25',
							'2022-05-26',
							'2022-06-06',
							'2022-07-21',
							'2022-08-15',
							'2022-11-01',
							'2022-11-11'
						]
					},
					'account': 10101444,
					'customer': {
						'company': 1,
						'name': 'BRARO BVBA - MAURICE & TOM',
						'code': 1444,
						'id': 1444
					},
					'hasMultipleCustomersAvailable': 0,
					'customers': [],
					'username': 'BRARO',
					'routes': [
						{
							'href': 'http://staging.client.cocks.private/preorders/1',
							'title': 'nav_preorder',
							'icon': 'fas fa-shopping-cart',
							'application': 'customers.client',
							'version': null
						},
						{
							'href': 'http://staging.client.cocks.private/order',
							'title': 'nav_bestel',
							'icon': 'fas fa-shopping-basket',
							'application': 'customers.client',
							'version': null
						},
						{
							'href': 'http://staging.client.cocks.private/promos',
							'title': 'nav_promos',
							'icon': 'fas fa-bullseye',
							'application': 'customers.client',
							'version': null
						},
						{
							'href': 'http://staging.client.cocks.private/orders',
							'title': 'nav_bestelhist',
							'icon': 'fas fa-truck',
							'application': 'customers.client',
							'version': null
						},
						{
							'href': 'http://staging.client.cocks.private/buylist',
							'title': 'nav_kooplijst',
							'icon': 'fas fa-star',
							'application': 'customers.client',
							'version': 0
						},
						{
							'href': 'http://staging.client.cocks.private/documents',
							'title': 'nav_invoices',
							'icon': 'fas fa-receipt',
							'application': 'customers.client',
							'version': 0
						},
						{
							'href': 'http://staging.client.cocks.private/reports',
							'title': 'nav_rapport',
							'icon': 'fas fa-chart-bar',
							'application': 'customers.client',
							'version': null
						}
					],
					'roles': [
						1000,
						1001,
						1002,
						1021,
						1022,
						1201,
						2011,
						2021,
						2032,
						2033
					],
					'session': '5307766a982133a2437622cb8e0dd545',
					'language': 'nl',
					'clients': [
						{
							'application': 'customers.client',
							'version': '588'
						},
						{
							'application': 'internal.client',
							'version': '117'
						},
						{
							'application': 'suppliers.client',
							'version': '158'
						}
					],
					'settings': {
						'remind-vacation-input': 0
					}
				}
			),
		);
	}),
];
