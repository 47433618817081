import React from 'react';
import { Route, Switch } from 'react-router';
import { CRUDConfig } from './crud';
import { t } from '../../../../libraries/i18n';
import CRUDDetail from './CRUDDetail';
import CRUDList from './CRUDList';

const translate = (translationCode: string, params: any) => {
	const [, code] = translationCode.split('.');
	return t(`settings.${code}`, params);
};

export default function CRUDHandler(props: CRUDConfig) {
	const {
		fields,
		detail,
		list,
		path
	} = props;

	return (
		<Switch>
			<Route exact path={path} render={() => <CRUDList config={list} fields={fields} t={translate} />} />
			<Route exact path={`${path}/new`} render={() => <CRUDDetail path={path} config={detail} fields={fields} t={translate} mode='create' />} />
			<Route exact path={`${path}/:id`} render={() => <CRUDDetail path={path} config={detail} fields={fields} t={translate} mode='read' />} />
			<Route exact path={`${path}/:id/edit`} render={() => <CRUDDetail path={path} config={detail} fields={fields} t={translate} mode='update' />} />
		</Switch>
	);
}
