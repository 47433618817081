import React from 'react';
import './ProductRangeFilter.scss';
import { FaStar } from 'react-icons/fa';
import FilterGroup from '../FilterGroup';
import { t } from './../../../libraries/i18n';
import Checkbox from '../../checkbox/checkbox';
import Spinner from '../../spinner/spinner';
import ShopcodeBrowser from '../ShopcodeBrowser/ShopcodeBrowser';

export default function ProductRangeFilter(props) {
	const {
		setFilter,
		filter,
		isProductsFetching,
		clearFilters
	} = props;

	const setMyProductsFilter = () => {
		setFilter('productRange.fullRange', false);
		setFilter('productRange.shopcode', '');
	};

	const renderFilter = showShopcodeBrowser => (
		<>
			<div
				className={`filter__item filter__buylist filter--${!filter.fullRange}`}
				onClick={setMyProductsFilter}
			>
				<div className='item__title'>
					<Checkbox radio checked={!filter.fullRange} />
					{t('order.filtersBuylistMyProducts')}
					<span className={'buylist-indicator'}><FaStar /></span>
				</div>
			</div>

			<div
				className={`filter__item filter__buylist filter--${filter.fullRange} ${isProductsFetching && 'filter--disabled'}`}
				onClick={() => { !isProductsFetching && setFilter('productRange.fullRange', true); }}
			>
				<div className='item__title'>
					{props.isProductsFetching ? (
						<Spinner size={'small'} />
					) : (
						<Checkbox radio checked={filter.fullRange} />
					)}
					{t('order.filtersBuylistFullProductRange')}
				</div>
				{showShopcodeBrowser && !props.isProductsFetching && (
					<div className='item__subfilter'>
						<ShopcodeBrowser
							filter={filter.shopcode}
							clearFilters={clearFilters}
							setShopcodeFilter={shopcode => setFilter('productRange.shopcode', shopcode)}
						/>
					</div>
				)}
			</div>
		</>
	);

	return (
		<FilterGroup
			className='ProductRangeFilter'
			clearFilters={clearFilters}
			showClearFilters={filter?.shopcode !== ''}
			defaultCollapsed={false}
			id={'productRangeFilter'}
		>
			<FilterGroup.Title>{t('order.filtersBuylistTitle')}</FilterGroup.Title>
			<FilterGroup.Body>
				<FilterGroup.Collapsed>
					{renderFilter(false)}
				</FilterGroup.Collapsed>

				<FilterGroup.Uncollapsed>
					{renderFilter(true)}
				</FilterGroup.Uncollapsed>
			</FilterGroup.Body>
		</FilterGroup>
	);
}
