import { FaExclamationCircle } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../libraries/i18n';
import Spinner from '../spinner/spinner';
import { saveOrderIfNeeded } from '../../modules/order/orderActions';
import {
	selectDidOrderSaveFail,
	selectLatestClientVersion,
	selectOrderHasPendingChanges,
	selectOrderLastUpdated
} from './../../modules/selectors';

export default function VersionAlert() {
	const dispatch = useDispatch();
	const latestClientVersion = useSelector(selectLatestClientVersion);
	const orderHasPendingChanges = useSelector(selectOrderHasPendingChanges);
	const didSaveFail = useSelector(selectDidOrderSaveFail);
	const lastUpdated = useSelector(selectOrderLastUpdated);
	const currentClientVersion = process.env.REACT_APP_APP_VERSION;

	const [triedReloading, setTriedReloading] = useState(null);
	const [didReloadFail, setDidReloadFail] = useState(false);

	useEffect(() => {
		if (!triedReloading) return;

		if (lastUpdated > triedReloading) return setTriedReloading(false);

		if (!orderHasPendingChanges && !didSaveFail) return document.location.reload();

		if (didSaveFail) return setDidReloadFail(true);
	}, [didSaveFail, lastUpdated, orderHasPendingChanges, triedReloading]);

	useEffect(() => {
		if (didReloadFail && !didSaveFail) return setDidReloadFail(false);
	}, [didSaveFail, didReloadFail]);

	const reload = () => {
		dispatch(saveOrderIfNeeded());
		setTriedReloading(Date.now());
	};

	if (currentClientVersion >= latestClientVersion) return null;

	if (didReloadFail) {
		return (
			<div className='VersionAlert Alert error'>
				<FaExclamationCircle />
				{t('common.label_reload_failed')}
			</div>
		);
	}

	return (
		<div className='VersionAlert Alert'>
			{t('common.label_new_version_available')}
			<button className='link' onClick={reload}>
				{triedReloading ? <Spinner size='small' /> : t('common.button_refresh_to_update_app')}
			</button>
		</div>
	);
}
