import { rest } from 'msw';

export const getMetaData = [
	rest.get('http://localhost:4000/api/customers/1444/metadata', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'article_image_location': 'http://staging02.portal.cocks.private/resources/images/articles/',
					'deliveryDays': [
						'2022-09-09',
						'2022-09-16',
						'2022-09-23',
						'2022-09-30',
						'2022-10-07',
						'2022-10-14',
						'2022-10-21'
					],
					'defaultDeliveryDays': [
						'2022-09-09',
						'2022-09-16',
						'2022-09-23',
						'2022-09-30',
						'2022-10-07',
						'2022-10-14',
						'2022-10-21'
					],
					'deliveryDates': [
						{
							'deliveryDate': '2022-09-09',
							'orderSubmitDeadline': '2022-09-08T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						},
						{
							'deliveryDate': '2022-09-16',
							'orderSubmitDeadline': '2022-09-15T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						},
						{
							'deliveryDate': '2022-09-23',
							'orderSubmitDeadline': '2022-09-22T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						},
						{
							'deliveryDate': '2022-09-30',
							'orderSubmitDeadline': '2022-09-29T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						},
						{
							'deliveryDate': '2022-10-07',
							'orderSubmitDeadline': '2022-10-06T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						},
						{
							'deliveryDate': '2022-10-14',
							'orderSubmitDeadline': '2022-10-13T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						},
						{
							'deliveryDate': '2022-10-21',
							'orderSubmitDeadline': '2022-10-20T16:04:00+00:00',
							'isDefault': true,
							'isDivergentOrderSubmitDeadline': 0
						}
					],
					'minimumOrderAmount': '0.00',
					'invoiceAddress': {
						'name': 'BRARO BVBA - MAURICE & TOM',
						'address.street': 'HOOGSTRAAT 36/1',
						'address.postalcode': '9260',
						'address.city': 'SCHELLEBELLE',
						'address': {
							'street': 'HOOGSTRAAT 36/1',
							'postalCode': '9260',
							'city': 'SCHELLEBELLE'
						}
					},
					'deliveryAddress': {
						'name': 'BRARO BVBA - MAURICE & TOM',
						'address.street': 'HOOGSTRAAT 36/1',
						'address.postalcode': '9260',
						'address.city': 'SCHELLEBELLE',
						'address': {
							'street': 'HOOGSTRAAT 36/1',
							'postalCode': '9260',
							'city': 'SCHELLEBELLE'
						}
					},
					'holidays': [
						'2021-07-21',
						'2021-08-15',
						'2021-11-01',
						'2021-11-11',
						'2021-12-25',
						'2022-01-01',
						'2022-04-18',
						'2022-05-01',
						'2022-05-25',
						'2022-05-26',
						'2022-06-06',
						'2022-07-21',
						'2022-08-15',
						'2022-11-01',
						'2022-11-11'
					]
				}
			),
		);
	}),
];
