import { Article } from '../../types/articles';
import { PreOrderAction } from '../../types/preOrders';
import * as Api from '../../libraries/api';
import {
	selectCustomerId,
	selectIsPreOrderActionSaving,
	selectIsPreOrdersDataFetching,
	selectIsSubmittingAllPreOrdersByType,
	selectPreOrderActionExponentialBackoff,
	selectPreOrderActionHasChanges,
	selectPreOrderActionLastSaveAttempt,
	selectPreOrderActionSaveError,
	selectPreOrdersActions
} from '../selectors';

export const PREORDERS_DATA_FETCH = 'PREORDERS_DATA_FETCH';
export const PREORDERS_DATA_RECEIVE = 'PREORDERS_DATA_RECEIVE';
export const PREORDERS_DATA_REJECT = 'PREORDERS_DATA_REJECT';

export const PREORDERS_ACTION_ARTICLE_QUANTITY_UPDATE = 'PREORDERS_ACTION_ARTICLE_QUANTITY_UPDATE';

export const PREORDERS_ACTION_SUBMIT_START = 'PREORDERS_ACTION_SUBMIT_START';
export const PREORDERS_ACTION_SUBMIT_SUCCESS = 'PREORDERS_ACTION_SUBMIT_SUCCESS';
export const PREORDERS_ACTION_SUBMIT_REJECT = 'PREORDERS_ACTION_SUBMIT_REJECT';
export const PREORDERS_TYPE_SUBMIT_START = 'PREORDERS_TYPE_SUBMIT_START';
export const PREORDERS_TYPE_SUBMIT_SUCCESS = 'PREORDERS_TYPE_SUBMIT_SUCCESS';
export const PREORDERS_TYPE_SUBMIT_REJECT = 'PREORDERS_TYPE_SUBMIT_REJECT';
export const PREORDERS_ACTION_SUBMITTED_WARNING_DISMISS = 'PREORDERS_ACTION_SUBMITTED_WARNING_DISMISS';
export const PREORDERS_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE = 'PREORDERS_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE';


export const fetchPreOrdersDataIfNeeded = (preOrderType: string) => async (dispatch: any, getState: any) => {
	const state = getState();
	if (selectIsPreOrdersDataFetching(state, preOrderType)) return;
	if (selectPreOrdersActions(state, preOrderType).length) return;

	dispatch(fetchPreOrdersData(preOrderType));
};

export const fetchPreOrdersData = (preOrderType: string) => async (dispatch: any, getState: any) => {
	const customerId = selectCustomerId(getState());
	try {
		dispatch({ type: PREORDERS_DATA_FETCH, preOrderType });
		const data = await Api.getPreOrdersData(customerId, preOrderType);
		// TODO: Why the double dispatch????
		//dispatch({ type: PREORDERS_DATA_RECEIVE, payload: data, preOrderType });

		dispatch(dispatch({ type: PREORDERS_DATA_RECEIVE, payload: data, preOrderType }));
	} catch (error: any) {
		console.error(error);
		dispatch(dispatch({ type: PREORDERS_DATA_REJECT, payload: error?.body?.error_message?.toString?.() ?? '', preOrderType }));
	}
};

export const updatePreOrderActionArticleQuantity = (action: PreOrderAction, article: Article, quantity: number, preOrderType: string) => ({
	type: PREORDERS_ACTION_ARTICLE_QUANTITY_UPDATE,
	payload: {
		group: action.group,
		orderId: action.orderId,
		delivery: action.delivery,
		articleId: article.id,
		quantity
	},
	preOrderType
});

const shouldSavePreOrder = (state: any, action: PreOrderAction, preOrderType: string, final: boolean): boolean => {
	const isSaving = selectIsPreOrderActionSaving(state, action.group, preOrderType);
	if (isSaving) return false;

	const saveError = selectPreOrderActionSaveError(state, action.group, preOrderType);
	const MAX_BACKOFF = 300;

	if (saveError) {
		const lastAttempt = selectPreOrderActionLastSaveAttempt(state, action.group, preOrderType);
		const exponentialBackoff = selectPreOrderActionExponentialBackoff(state, action.group, preOrderType);
		const backoffTime = Math.min(Math.pow(2, exponentialBackoff), MAX_BACKOFF);
		const now = new Date();
		const nextAttemptNoEarlierThan = new Date(lastAttempt ?? 0);
		nextAttemptNoEarlierThan.setSeconds(nextAttemptNoEarlierThan.getSeconds() + backoffTime);

		const allowAttempt = now > nextAttemptNoEarlierThan;

		console.warn('Cancelled save attempt because of exponential backoff');
		console.table(
			[
				['backoff factor', exponentialBackoff],
				['backoff time', backoffTime],
				['next attempt no earlier than (date)', nextAttemptNoEarlierThan],
				['next attempt no earlier than (seconds)', (nextAttemptNoEarlierThan.getTime() - now.getTime()) / 1000],
				['allow this attempt', allowAttempt],
			]
		);

		return allowAttempt;
	}
	if (saveError?.error_code === 2500 || saveError?.error_code === 2501) return false;

	const hasChanges = selectPreOrderActionHasChanges(state, action.group, preOrderType);
	if (final) return true;
	return hasChanges;
};

export const savePreOrderIfNeeded = (action: PreOrderAction, preOrderType: string, final = false) => async (dispatch: any, getState: any) => {
	const state = getState();
	//const customerId = selectCustomerId(state);
	//console.log('action', action);
	//console.log('action', action);
	const customerId = action?.customNr;

	if (!shouldSavePreOrder(state, action, preOrderType, final)) return;

	try {
		const lastSaved = new Date();
		dispatch({ type: PREORDERS_ACTION_SUBMIT_START, payload: { group: action.group, final }, preOrderType });
		await Api.submitPreOrder(customerId, action, preOrderType, final);
		dispatch({ type: PREORDERS_ACTION_SUBMIT_SUCCESS, payload: { group: action.group, final, lastSaved }, preOrderType });
	} catch (response: any) {
		dispatch({
			type: PREORDERS_ACTION_SUBMIT_REJECT,
			payload: {
				group: action.group,
				final,
				error: response?.body,
				lastAttempt: new Date()
			},
			preOrderType
		});
	}
};

export const submitAllPreOrdersByType = (preOrderType: string) => async (dispatch: any, getState: any) => {
	const state = getState();
	const customerId = selectCustomerId(state);
	const actions = selectPreOrdersActions(state, preOrderType);
	const isSubmittingAllOrders = selectIsSubmittingAllPreOrdersByType(state, preOrderType);

	if (isSubmittingAllOrders) return;

	try {
		const lastSaved = new Date();
		dispatch({ type: PREORDERS_TYPE_SUBMIT_START, preOrderType });
		await Api.submitAllPreOrdersByType(customerId, actions, preOrderType);//TODO ASK ROBERT welke customerID wilde hier?? want actions kunnen verschillende customers hebben
		dispatch({ type: PREORDERS_TYPE_SUBMIT_SUCCESS, preOrderType, payload: { lastSaved } });
	} catch (response: any) {
		dispatch({ type: PREORDERS_TYPE_SUBMIT_REJECT, preOrderType });
	}
};

// TODO: Define Article type and Create Promo type
export function awardPromoIfEligible(product: Article, quantity: number, promo: any, preOrderType: string, group: string) {
	return {
		type: PREORDERS_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE,
		payload: {
			product,
			quantity,
			promo,
			group,
		},
		preOrderType
	};
}
