export const getUserDefinedMargin = (articleId, fallback, unsavedMargins) => {
	const unsavedMargin = unsavedMargins.find(item => {
		return ( item.article === articleId );
	});
	if ( unsavedMargin ) return unsavedMargin.value;
	if ( fallback ) return fallback;
};

export const getProductMargin = (articleId, fallback, unsavedMargins) => {
	const userMargin = getUserDefinedMargin(articleId, fallback, unsavedMargins);

	if (userMargin) return userMargin;
	return ''; // make sure that the input value is never undefined/uncontrolled.

};

export const getFinalMargin = (articleId, fallback, group, unsavedMargins) => {
	const userMargin = getUserDefinedMargin(articleId, fallback, unsavedMargins);

	if (userMargin) return userMargin;
	if ( group.value ) return group.value;
	if ( group.proposed ) return group.proposed;
	return ''; // make sure that the input value is never undefined/uncontrolled.
};

export const getConsumerPrice = ({
	id,
	value,
	purchase,
	sales,
	amount,
	group,
	unsavedMargins
}) => {
	const margin = getFinalMargin(id, value, group, unsavedMargins);
	const marginMultiplier = margin / 100 ;
	const vatMultiplier = 1 + ( sales?.vat / 100 );

	const getPurchasePrice = (pUnit, sUnit, price, weight, amount) => {
		if (pUnit === 'K' && sUnit === 'K') {
			return price;
		}

		if (pUnit === 'S' && sUnit === 'S') {
			return price / amount;
		}

		if (pUnit === 'K' && sUnit === 'S') {
			return price * weight / amount;
		}

		if (pUnit === 'S' && sUnit === 'K') {
			return price / weight;
		}
	};

	return (getPurchasePrice(purchase?.unit, sales?.unit, purchase?.price, purchase?.weight?.net, amount) * marginMultiplier * vatMultiplier).toFixed(2);
};

