import React, { useState, useEffect, useMemo } from 'react';
import './QuantityInput.scss';
import { FaExclamation, FaMinus, FaPlus } from 'react-icons/fa';
import PropTypes from 'prop-types';

function QuantityInput(props) {
	const {
		t,
		onQuantityChange
	} = props;

	const reduxQuantity = useMemo(() => ({
		quantity: props.quantity,
		timestamp: props.quantityTimestamp
	}), [props.quantity, props.quantityTimestamp]);

	const [internalQuantity, setInternalQuantity] = useState(reduxQuantity || { quantity: 0 });
	const quantityInput = React.createRef();
	const [debounce, setDebounce] = useState({});

	useEffect(() => {
		if (!reduxQuantity.timestamp) return;
		if (reduxQuantity.timestamp <= internalQuantity.timestamp) return;

		setInternalQuantity(reduxQuantity);
	}, [reduxQuantity, internalQuantity]);

	useEffect(() => {
		if (typeof onQuantityChange !== 'function') return;
		if (!internalQuantity.timestamp) return;
		if (reduxQuantity.timestamp >= internalQuantity.timestamp) return;
		if (debounce.timestamp >= internalQuantity.timestamp) return;

		clearTimeout(debounce.timeout);
		setDebounce({
			timeout: setTimeout(onQuantityChange, 500, internalQuantity),
			timestamp: internalQuantity.timestamp
		});
	}, [reduxQuantity, internalQuantity, debounce, onQuantityChange]);

	const focusQuantityInput = (e) => {
		e.stopPropagation();
		// @ts-ignore
		quantityInput.current.focus();
	};

	const increment = () => {
		if (disabled) return;
		const quantity = internalQuantity.quantity === undefined ? 0 : internalQuantity.quantity;
		handleQuantityChange(quantity + 1);
	};

	const decrement = () => {
		if (disabled) return;
		const quantity = internalQuantity.quantity === undefined ? 0 : internalQuantity.quantity;
		handleQuantityChange(quantity - 1);
	};

	const handleQuantityChange = input => {
		let quantity = input;
		if (typeof input === 'object') quantity = input.target.value === '' ? 0 : parseInt(input.target.value);
		if (quantity === null) quantity = 0;
		if (Number.isNaN(quantity)) quantity = internalQuantity.quantity;
		if (quantity < 0) quantity = 0;

		setInternalQuantity({ quantity, timestamp: Date.now() });
	};

	const isQuantityConsistent = (reduxQuantity, internalQuantity) => {
		return (reduxQuantity.timestamp === internalQuantity.timestamp);
	};

	const {
		min,
		max,
		awardedPromo,
		disabled,
		hidden,
		orderAmount,
		madQuantityIndicator,
		focus,
		size
	} = props;

	return (
		<div
			className={`
				QuantityInput
				${isQuantityConsistent(reduxQuantity, internalQuantity) ? 'consistent' : 'inconsistent'}
				${size ? size : ''}
				${madQuantityIndicator ? 'mq' : ''}
				${focus ? 'focus' : ''}
			`}
			onClick={(e) => e.stopPropagation()}
		>
			<span className={`quantity__button button--decrement ${disabled ? 'disabled' : ''} ${hidden ? 'hidden' : ''}`} onClick={decrement}>
				<FaMinus />
			</span>
			<input
				className={`quantity__input input--length-${(internalQuantity.quantity || 1).toString().length}`}
				type="number"
				min={min || 0}
				max={max || 10000}
				placeholder="0"
				onChange={handleQuantityChange}
				value={internalQuantity.quantity || 0}
				// @ts-ignore
				ref={quantityInput}
				disabled={disabled}
				// prevents scrolling inside number fields
				onWheel={(e) => e.target.blur()}
			/>

			{awardedPromo && (
				<span onClick={focusQuantityInput} className={'quantity__promo-quantity'} data-free={t('order.label_free')}>
					{awardedPromo}
				</span>
			)}

			{orderAmount && orderAmount !== 1 && (
				<span onClick={focusQuantityInput} className={'quantity__order-amount'}>
					<span className={'multiplication-sign'}>×</span>{orderAmount}
				</span>
			)}

			{madQuantityIndicator && (
				<div onClick={focusQuantityInput} className={'mad-quantity'}>
					<span className={'mad-quantity__indicator'}>
						<FaExclamation />
					</span>
				</div>
			)}

			<span onClick={focusQuantityInput} className={'quantity__unit'}>
				{t('order.productUnitPlural')}
			</span>

			<span className={`quantity__button button--increment ${disabled ? 'disabled' : ''} ${hidden ? 'hidden' : ''}`} onClick={increment}>
				<FaPlus />
			</span>
		</div>
	);
}

QuantityInput.propTypes = {
	quantity: PropTypes.number,
	quantityTimestamp: PropTypes.number,
	awardedPromo: PropTypes.number,
	onQuantityChange: PropTypes.func,
	disabled: PropTypes.bool,
	orderAmount: PropTypes.number,
	madQuantityIndicator: PropTypes.bool,
	focus: PropTypes.bool,
	size: PropTypes.oneOf(['compact', 'big', '']),
	debounce: PropTypes.number,
	t: PropTypes.func
};

export default QuantityInput;
