import React, {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import './Vacation.scss';
import { DatePicker, Button } from '@abm-international/react-components';
import {
	FaChevronLeft,
	FaChevronRight,
	FaLongArrowAltRight,
	FaTrashAlt
} from 'react-icons/fa';
import { submitVacationDates } from '../../../libraries/api';
import useVacation from './../../../api/settings/useVacation';
import Spinner from '../../../components/spinner/spinner';
import PortalModal from '../../../components/PortalModal/PortalModal';
import { useSelector } from 'react-redux';
import { selectCustomerId, selectHolidays } from '../../selectors';
import dateHelper from '../../../libraries/dateHelper';
import { t } from '../../../libraries/i18n';

const MONTHS = [
	'Januari',
	'Februari',
	'Maart',
	'April',
	'Mei',
	'Juni',
	'Juli',
	'Augustus',
	'September',
	'Oktober',
	'November',
	'December'
];

const DAYS = [
	'Maandag',
	'Dinsdag',
	'Woensdag',
	'Donderdag',
	'Vrijdag',
	'Zaterdag',
	'Zondag'
];

const getActualDayOfWeek = (date: Date) => {
	const americanWeekday = date.getDay();
	return americanWeekday === 0 ? 7 : americanWeekday;
};

const getPrettyDate = (date: Date | null) => {
	if (!date) return null;
	const dayOfWeek = DAYS[getActualDayOfWeek(date) - 1];
	const day = date.getDate();
	const month = MONTHS[date.getMonth()];
	const year = date.getFullYear();

	return `${dayOfWeek} ${day} ${month} ${year}`;
};

const dateRangeToDates = (a: Date | undefined, b: Date | undefined): Date[] => {
	if (!a || !b) return [];

	const start = new Date(a);
	const end = new Date(b);

	const dates = [];
	const currentDate = new Date(start.getTime());
	currentDate.setDate(currentDate.getDate() + 1);

	for (let dt = new Date(start); dt.getTime() <= end.getTime(); dt.setDate(dt.getDate() + 1)) {
		dates.push(new Date(dt));
	}

	return dates;
};

const getAllWeekendDays = (year: number) => {
	const end = new Date(year + 1, 0, 1);

	const weekendDays = [];

	for (let dt = new Date(year, 0, 1); dt.getTime() <= end.getTime(); dt.setDate(dt.getDate() + 1)) {
		const dow = getActualDayOfWeek(dt);
		if (dow === 6 || dow === 7) weekendDays.push(new Date(dt));
	}

	return weekendDays;
};

const getPastDaysOfYear = (year: number) => {
	const end = new Date(year + 1, 0, 1);

	const past = [];

	for (let dt = new Date(year, 0, 1); dt.getTime() <= end.getTime(); dt.setDate(dt.getDate() + 1)) {
		const today = new Date();
		if (today.getTime() > dt.getTime()) past.push(new Date(dt));
	}

	return past;
};

const isSameDate = (a: Date, b: Date) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

const sortDatesAscending = (a: Date, b: Date) => a.getTime() - b.getTime();

const datesToDateRange = (dates: Array<Date>) => {
	const sortedDates = dates.sort(sortDatesAscending);

	const ranges = [];

	let range: any = [];
	for (let index = 0; index < sortedDates.length; index++) {
		const date = new Date(sortedDates[index]);

		if (!range?.[0]) {
			range[0] = date;
		}

		const tomorrow = new Date(date);
		tomorrow.setDate(date.getDate() + 1);
		const nextDate = new Date(sortedDates[index + 1]);

		if (!isSameDate(tomorrow, nextDate)) {
			range[1] = date;
			ranges.push(range);
			range = [];
		}
	}

	return ranges;
};

const getRemovableDates = ([start, end]: [Date, Date]) => {
	const datesInRange = dateRangeToDates(start, end);
	return datesInRange.filter(d => d.getTime() > Date.now());
};

export default function Vacation() {
	const { vacation, isFetching, error: fetchError } = useVacation();
	const [vacationDates, setVacationDates] = useState<Array<Date> | undefined>(undefined);
	const [selected, setSelected] = useState<[Date, Date] | []>([]);
	const [hoverRange, setHoverRange] = useState<any>({ className: '', dates: [] });
	const [year, setYear] = useState((new Date()).getFullYear());
	const [submitError, setSubmitError] = useState(undefined);
	const [showChangesArePendingMessage, setShowChangesArePendingMessage] = useState(false);
	const customerId = useSelector(selectCustomerId);
	const holidays = useSelector(selectHolidays);

	const highlightedDates = useMemo(() => {
		const items = [
			{
				className: 'today',
				dates: [new Date()]
			},
			{
				className: 'weekend',
				dates: getAllWeekendDays(year)
			},
			{
				className: 'past',
				dates: getPastDaysOfYear(year)
			},
			{
				className: 'closed',
				dates: holidays?.map(dateHelper.fromReduxDateString)
			}
		];

		if (hoverRange) items.push(hoverRange);

		items.push({
			className: 'vacation',
			dates: vacationDates ?? []
		});

		return items;
	}, [hoverRange, vacationDates, year, holidays]);

	const canRemoveRange = ([, end]: [Date, Date]) => {
		const endIsBeforeToday = end.getTime() < Date.now();

		return !endIsBeforeToday;
	};

	const removeRange = useCallback(([start, end]: [Date, Date]) => {
		if (!vacationDates) return;
		if (!canRemoveRange([start, end])) return;

		const datesToRemove = dateRangeToDates(start, end);

		setVacationDates(vacationDates.filter((vacationDate) => {
			const isBeforeOrToday = vacationDate.getTime() < Date.now();
			if (isBeforeOrToday) return true;

			return !datesToRemove.find(dtr => isSameDate(dtr, vacationDate));
		}));
	}, [vacationDates]);

	const hasChanged = () => {
		const dates = JSON.stringify(vacation);
		const newDates = JSON.stringify(vacationDates);

		return dates !== newDates;
	};

	// Process if 2 dates are selected
	useEffect(() => {
		if (!vacationDates) return;

		// Don't do anything before to dates are selected
		const hasTwoDates = selected.every((date: Date) => typeof date?.getMonth === 'function');
		if (selected.length !== 2 || !hasTwoDates) return;

		// Is the range not (partialy) in the past
		const startIsBeforeToday = selected?.[0].getTime() < Date.now();
		if (startIsBeforeToday) {
			setSelected([]);
			return;
		}

		// Are both dates already vacation dates
		const isStartAlreadyVacationDate = vacationDates.find(vd => isSameDate(vd, selected[0]));
		const isEndAlreadyVacationDate = vacationDates.find(vd => isSameDate(vd, selected[1]));
		if (isStartAlreadyVacationDate && isEndAlreadyVacationDate) {
			setSelected([]);
			removeRange(selected);
			return;
		}

		// Add dates to vacationDates
		const datesToAdd = dateRangeToDates(selected[0], selected[1])
			.filter(d => !vacationDates.find(vd => isSameDate(d, vd)));

		setVacationDates([...vacationDates, ...datesToAdd].sort(sortDatesAscending));
		setSelected([]);

	}, [selected, vacationDates, removeRange]);

	useEffect(() => {
		if (vacation && !vacationDates) setVacationDates(vacation);
	}, [vacation, vacationDates]);

	const submitDates = async () => {
		try {
			await submitVacationDates(vacationDates?.map(dateHelper.getDateString), customerId);
			setSubmitError(undefined);
			setShowChangesArePendingMessage(true);
		} catch (error: any) {
			setSubmitError(error);
		}
	};

	const renderCalendar = () => (
		<>
			<h3>{year}</h3>
			<div className='legend'>
				<div className='legend__item today'>
					<div className='date'>
						{new Date().getDate()}
					</div>
					<div className='text'>
						{t('vacation.label_legend_today')}
					</div>
				</div>

				<div className='legend__item weekend'>
					<div className='date'>
						{new Date().getDate()}
					</div>
					<div className='text'>
						{t('vacation.label_legend_weekend')}
					</div>
				</div>

				<div className='legend__item past'>
					<div className='date'>
						{new Date().getDate()}
					</div>
					<div className='text'>
						{t('vacation.label_legend_past')}
					</div>
				</div>

				<div className='legend__item vacation'>
					<div className='date'>
						{new Date().getDate()}
					</div>
					<div className='text'>
						{t('vacation.label_legend_vacation')}
					</div>
				</div>

				<div className='legend__item delete'>
					<div className='date'>
						{new Date().getDate()}
					</div>
					<div className='text'>
						{t('vacation.label_legend_delete')}
					</div>
				</div>

				<div className='legend__item closed'>
					<div className='date'>
						{new Date().getDate()}
					</div>
					<div className='text'>
						{t('vacation.label_legend_closed')}
					</div>
				</div>
			</div>
			<div className='date-picker-container'>
				<div className='prev' onClick={() => setYear(year - 1)}>
					<FaChevronLeft />
				</div>

				<DatePicker
					translations={{ months: MONTHS, days: DAYS }}
					options={{
						single: false,
						hideWeekNumbers: false,
						hideDaysOfPrevAndNextMonth: true,
						hideSelectedDates: true,
						showSuggestions: false,
						highlightedDates,
						months: 12,
						hidePrevAndNextButtons: true
					}}
					value={selected}
					onChange={setSelected}
					currentMonth={[year, 0]}
				/>

				<div className='next' onClick={() => setYear(year + 1)}>
					<FaChevronRight />
				</div>
			</div>
		</>
	);

	const renderRanges = () => {
		if (!vacationDates) return null;

		return (
			<div className='ranges'>
				{datesToDateRange(vacationDates).filter(canRemoveRange).map((range) => (
					<div
						className='range'
						key={range.toString()}
					>
						<div
							className='dates'
							onMouseEnter={() => setHoverRange({ className: 'hover', dates: dateRangeToDates(range[0], range[1]) })}
							onMouseLeave={() => setHoverRange(undefined)}
						>
							{isSameDate(range[0], range[1]) ? (
								<span className='date'>{getPrettyDate(range[0])}</span>
							) : (
								<>
									<span className='date'>{getPrettyDate(range[0])}</span>
									<FaLongArrowAltRight />
									<span className='date'>{getPrettyDate(range[1])}</span>
								</>
							)}
						</div>
						{canRemoveRange(range) && (
							<Button
								icon={<FaTrashAlt />}
								color='red'
								secondary
								onClick={() => {
									removeRange(range);
									setHoverRange(undefined);
								}}
								onMouseEnter={() => setHoverRange({ className: 'hover--delete', dates: getRemovableDates(range) })}
								onMouseLeave={() => setHoverRange(undefined)}
							/>
						)}
					</div>
				))}
			</div>
		);
	};

	const renderActions = () => (
		<div className='actions'>
			{hasChanged() && (
				<Button
					onClick={submitDates}
					primary
				>
					{t('vacation.button_save')}
				</Button>
			)}
		</div>
	);

	const renderChangesArePendingModal = () => (
		<PortalModal close={() => setShowChangesArePendingMessage(false)} hideCloseButton={true}>
			<div className='vacation__modal'>
				<div className={'text'}>
					{t('vacation.label_changes_pending')}
				</div>

				<div className={'actions'}>
					<Button
						onClick={() => setShowChangesArePendingMessage(false)}
						primary
					>
						{t('vacation.button_understood')}
					</Button>
				</div>
			</div>
		</PortalModal>
	);

	// const renderDescription = () => (
	// 	<div className='description'>
	// 		{t('vacation.label_description_1')}<br />
	// 		<br />
	// 		{t('vacation.label_description_2')}
	// 	</div>
	// );

	return (
		<div className='Vacation'>
			{fetchError && (
				<div className='message error'>
					<span className='icon'>
						💥
					</span>
					<p>
						{t('vacation.label_error_fetching')}
					</p>
				</div>
			)}

			{/* {renderDescription()} */}

			{isFetching && <Spinner />}

			{vacationDates && renderCalendar()}
			{renderRanges()}

			{submitError && (
				<div className='message error error--submit'>
					<p>
						{t('vacation.label_error_saving')}
					</p>
				</div>
			)}

			{renderActions()}

			{showChangesArePendingMessage && renderChangesArePendingModal()}
		</div>
	);
}
