import React, { PureComponent, useState } from 'react';

class Try extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		return this.props.onDidCatch(error, errorInfo);
	}

	render() {
		if (this.state.hasError) return null;
		return this.props.children;
	}
}

const useErrorBoundary = ({ logger }) => {
	const [hasError, setHasError] = useState(false);
	const [error, setError] = useState(null);
	const [errorInfo, setErrorInfo] = useState(null);

	const onDidCatch = (error, errorInfo) => {
		setHasError(true);
		setError(error);
		setErrorInfo(errorInfo);
		logger.error(error.message, { error, errorInfo });
	};

	const WrappedTry = props => !hasError ? <Try onDidCatch={onDidCatch} {...props} /> : null;
	const Catch = props => hasError ? props?.children : null;

	return [
		WrappedTry,
		Catch,
		{
			error,
			hasError,
			errorInfo
		}
	];
};

export default useErrorBoundary;
