import React from 'react';
import './productsView.scss';
import { useSelector } from 'react-redux';
import ProductsList from './productsList/productsList';
import { t } from './../../libraries/i18n';
import { selectDeliveryDate, selectOrderFilters, selectPromos } from './../../modules/selectors';
import { useAppDispatch, useAppSelector } from './../../libraries/hooks/redux';
import { setFilter } from './../../modules/order/orderActions';
import { Button } from '@abm-international/react-components';

function ProductsView(props) {
	const dispatch = useAppDispatch();
	const promos = useSelector(selectPromos);
	const deliveryDate = useSelector(selectDeliveryDate);
	const filters = useAppSelector(selectOrderFilters);

	const {
		isFetching,
		products,
		view,
		showModal,
		hideQuantityInput,
		isInBuylist,
		query,
		removeFromBuylist,
		addToBuylist,
	} = props;

	const showFullProductRange = () => {
		dispatch(setFilter('productRange.fullRange', true));
	};

	if (isFetching && products.length <= 0) {
		return (
			<div className={'ProductsView'}>
				<div className={'no-products'}>
					{t('order.noProducts')}
				</div>
			</div>
		);
	}

	if (!isFetching && products.length === 0 && filters.productRange.fullRange === false) {
		return (
			<div className='ProductsView'>
				<div className='full-range-hint'>
					<p>{t('order.show_full_range_products_text')}</p>

					<Button onClick={showFullProductRange} primary>
						{t('order.show_full_range_products')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className={'ProductsView'}>
			<ProductsList
				isFetching={isFetching}
				products={products}
				view={view}
				showModal={showModal}
				hideQuantityInput={hideQuantityInput}
				promos={promos}
				isInBuylist={isInBuylist}
				query={query}
				removeFromBuylist={removeFromBuylist}
				addToBuylist={addToBuylist}
				deliveryDate={deliveryDate}
			/>
		</div>
	);

}

export default ProductsView;
