import React, {
	useState,
	useEffect,
	useRef,
	useMemo
} from 'react';
import './PdfModal.scss';
import PortalModal from '../PortalModal/PortalModal';
import { pdfjs, Document, Page } from 'react-pdf';
import {
	FaSearchMinus,
	FaSearchPlus,
	FaDownload,
	FaExpand,
	FaCompress
} from 'react-icons/fa';
import Spinner from './../spinner/spinner';
import useFullscreen from './../../libraries/hooks/useFullscreen';

// https://www.npmjs.com/package/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfModal(props) {
	const [totalPages, setTotalPages] = useState();
	const [scale, setScale] = useState(1.0);
	const [isLoading, setIsLoading] = useState(true);
	const [shouldAlignLeft, setShouldAlignLeft] = useState(false);

	const ZOOM_STEP = 0.25;

	const pdfViewerRef = useRef(null);
	const scrollElement = useRef(null);

	const [isFullscreen, setFullscreen] = useFullscreen(pdfViewerRef);

	const file = useMemo(() => ({
		url: props.file, withCredentials: true
	}), [props.file]);

	useEffect(() => {
		try {
			setShouldAlignLeft(scrollElement.current.scrollWidth > scrollElement.current.clientWidth);
		} catch (error) {
			// Do nothing
		}
	}, [scale, isFullscreen, scrollElement]);

	const onDocumentLoadSuccess = (pdf) => {
		setTotalPages(pdf.numPages);
		setIsLoading(false);
	};

	const zoomIn = () => {
		setScale(scale + ZOOM_STEP);
	};

	const zoomOut = () => {
		if (Math.round(scale * 100) / 100 <= ZOOM_STEP) return;
		setScale(scale - ZOOM_STEP);
	};

	const renderError = (
		<div className={'pdf__error'}>
			Er is iets fout gegaan bij het laden of het tonen van de PDF.
		</div>
	);

	const renderPdf = () => {
		const pages = [];
		for (let page = 1; page <= totalPages; page++) {
			pages.push((
				<div key={page} className={'pdf__page'}>
					<Page
						pageNumber={page}
						scale={scale}
					/>
				</div>
			));
		}

		return (
			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				loading={<Spinner />}
				error={renderError}
			>
				{pages}
			</Document>
		);
	};

	return (
		<PortalModal
			close={props.close}
			bare={true}
		>
			<div
				className={`PdfModal ${isLoading ? 'loading' : ''}`}
				ref={pdfViewerRef}
			>
				<div className={'PdfModal__controls'}>
					<div className={'controls__item'}>
						<FaSearchMinus onClick={zoomOut} />
					</div>

					<div className={'controls__item'}>
						<FaSearchPlus onClick={zoomIn} />
					</div>

					{props.downloadUrl && (
						<div className={'controls__item'}>
							<a href={props.downloadUrl}>
								<FaDownload />
							</a>
						</div>
					)}

					<div className={'controls__item'}>
						{isFullscreen ? (
							<FaCompress onClick={() => setFullscreen(false)} />
						) : (
							<FaExpand onClick={() => setFullscreen(true)} />
						)}
					</div>

					<div className={'controls__item close'} onClick={props.close}>
						sluiten
					</div>
				</div>
				<div
					className={`scroll-helper ${shouldAlignLeft ? 'align-left' : 'align-center'}`}
					ref={scrollElement}
				>
					<div className={'PdfModal__pdf'}>
						{renderPdf()}
					</div>
				</div>
			</div>
		</PortalModal>
	);
}

export default PdfModal;
