import React, { useEffect, useMemo, useState } from 'react';
import { t } from '../../libraries/i18n';
import { Coin } from '@abm-international/react-components';
import { getSavedPoints } from '../../libraries/api';
import useCustomer from '../../libraries/hooks/useCustomer';
import beursLogo from './../../images/Charles_koerse_DEF.jpg';
import SpaarsaldoImageNL from './../../images/spaarsaldo_nl.jpg';
import SpaarsaldoImageFR from './../../images/spaarsaldo_fr.jpg';
import rulesNL from '../../images/Charles_Spaaractie_reglement_NL.pdf';
import rulesFR from '../../images/Charles_Spaaractie_reglement_FR.pdf';
import './TradeFairPointsOverview.scss';

import IbizaNL from '../../images/spaarsaldo/Ibiza_NL.jpg';
import IbizaFR from '../../images/spaarsaldo/Ibiza_FR.jpg';
import MignonNL from '../../images/spaarsaldo/Charles-mignon_NL.jpg';
import MignonFR from '../../images/spaarsaldo/Charles-mignon_FR.jpg';
import MetejoorNL from '../../images/spaarsaldo/Metejoor_NL.jpg';
import MetejoorFR from '../../images/spaarsaldo/Metejoor_FR.jpg';
import DurbuyNL from '../../images/spaarsaldo/Durbuy_NL.jpg';
import DurbuyFR from '../../images/spaarsaldo/Durbuy_FR.jpg';


interface SpaarImage {
    src: string;
    alt: string,
}

const _images: {nl: SpaarImage[], fr: SpaarImage[]} = {
	nl: [
		{ src: IbizaNL, alt: 'Ibiza' },
		{ src: MignonNL, alt: 'Mignon' },
		{ src: MetejoorNL, alt: 'Metejoor' },
		{ src: DurbuyNL, alt: 'durbuy' },
	],
	fr: [
		{ src: IbizaFR, alt: 'Ibiza' },
		{ src: MignonFR, alt: 'Mignon' },
		{ src: MetejoorFR, alt: 'Metejoor' },
		{ src: DurbuyFR, alt: 'durbuy' },
	],
};

export default function TradeFairPointsOverview(props: any) {
	const { locale } = useCustomer();
	const [points, setPoints] = useState<any>();

	useEffect(() => {
		const fetchPoints = async () => {
			try {
				const savedPoints = await getSavedPoints();
				setPoints(savedPoints);
			} catch (error) {
				console.error('Error fetching saved points:', error);
			}
		};

		fetchPoints();
	}, []);

	const images: SpaarImage[] = useMemo(() => {
		return _images[locale as keyof typeof _images];
	}, [locale]);

	// All language-specific content
	const copy: {banner: {alt: string; src: string;}, rules: {src: string; text: string}} = useMemo(() => {
		return {
			banner: {
				alt: locale === 'nl' ? 'Charles Koerse - Spaaractie' : 'int frans',
				src: locale === 'nl' ? SpaarsaldoImageNL : SpaarsaldoImageFR,
			},
			rules: {
				src: locale === 'nl' ? rulesNL : rulesFR,
				text: locale === 'nl' ? 'Lees hier het spaarreglement' : 'Lisez ici le règlement de l\'action d\'épargne.'
			}
		};
	}, [locale]);

	return (
		<div className='TradeFairPointsOverview'>
			<img className='logo' src={beursLogo} alt='Charles Koerse' />
			<img className='spaarsaldo' src={copy.banner.src} alt={copy.banner.alt} />
			<h1>{t('tradefair.title')}</h1>
			<ul>
				<li>{t('tradefair.listitem1')}</li>
				<li>{t('tradefair.listitem2')}</li>
			</ul>
			<div className='points'>
				<span>{t('tradefair.saldo')}</span>

				<Coin
					koerses={props.koerses}
					points={[points?.points]}
					size='large'
					forceShow
				/>
			</div>

			<div className='carousel'>
				{images.map((image, index) => (
					<img key={`image-${ index}`} src={image.src} alt={image.alt} />
				))}
			</div>

			<div className='footer'>
				<p>{t('tradefair.warning')}</p>
				{/* <p>{t('tradefair.estimate')}</p> */}
				{/* <p>{t('tradefair.rules')}</p> */}
				<div className='rules'>
					<a href={copy.rules.src} target='_blank' rel='noreferrer'>{t(copy.rules.text)}</a>
				</div>
			</div>
		</div>
	);
}
