import { TradeFairDatesType } from '../../constants';
import { useAppSelector } from './redux';
import { selectAwardedPromos, selectProductPoints } from '../../modules/selectors';

export const getProductPoints = (deliveryDate: Date | string, allPoints?:
	{
		points: number;
		date: {
			start: string | Date;
			end: string | Date;
		};
		tradeFair?: boolean;
	}[], tradeFairDates?: TradeFairDatesType) => {

	return (allPoints && deliveryDate ? allPoints : []).reduce((acc, item) => {
		if (
			new Date(deliveryDate).getTime() >= new Date(item.date.start).getTime() &&
			(!item.date.end || new Date(deliveryDate).getTime() <= new Date(item.date.end).getTime())
		) {
			if (!item.tradeFair) {
				return {
					...acc,
					standardPoints: acc.standardPoints + item.points
				};
			}
			if (tradeFairDates && Date.now() <= new Date(tradeFairDates.end).getTime() && Date.now() >= new Date(tradeFairDates.start).getTime()) {
				return {
					...acc,
					tradeFairPoints: acc.tradeFairPoints + item.points
				};
			}
			return acc;
		}
		return acc;
	}, {
		standardPoints: 0,
		tradeFairPoints: 0,
	});
};

export const usePoints = (tradeFairDates: TradeFairDatesType, deliveryDate?: Date | string, isTradeFair?: boolean) => {
	const storePoints = useAppSelector(selectProductPoints);
	const awardedPromos = useAppSelector(selectAwardedPromos);

	const calculatePoints = (points: {
		standardPoints: number;
		tradeFairPoints: number;

	}, promo: any, amount: number) => {
		const totalPoints = {
			standardPoints: points.standardPoints * amount,
			tradeFairPoints: points.tradeFairPoints * amount,
		};
		return totalPoints;
	};

	const calculateTotalPoints = (articles: any[], quantities: { [index: number]: number }, _deliveryDate?: Date | string) => {
		const date = _deliveryDate || deliveryDate;

		if (!date) return 0;


		const totalPoints = articles.reduce((acc, article, index) => {
			const points = getProductPoints(date, storePoints[article.id], tradeFairDates);

			const allPoints = calculatePoints(points, article.promo, (quantities[Number(article.id)] || 0) + (isTradeFair ? 0 : (awardedPromos[Number(article.id)]?.quantity || 0)));

			return {
				standardPoints: acc.standardPoints + allPoints.standardPoints,
				tradeFairPoints: acc.tradeFairPoints + allPoints.tradeFairPoints,
			};
		}, {
			standardPoints: 0,
			tradeFairPoints: 0,
		});


		return totalPoints;
	};

	return { calculateTotalPoints };
};
