import { rest } from 'msw';

export const posts = [
	rest.post('http://localhost:4000/api/customers/1444/orders/593', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'reference': '',
					'comment': '',
					'lines': [
						{
							'articleCode': '1',
							'amount': 1
						},
						{
							'articleCode': '7524',
							'amount': 3
						}
					],
					'deliveryDate': '2022-09-16',
					'orderId': 593,
					'companyId': 1,
					'submitOrder': false,
					'confirmations': []
				}
			),
		);
	}),
];
