import config from '../../../config';

export class Gtag {
	private static _gtag: Gtag.Gtag | null = null;
	private static _isIntialized = false;

	static init(gaId: string) {
		if (typeof window === 'undefined' || typeof document === 'undefined') {
			return;
		}

		if (!this._isIntialized) {
			// Google tag (gtag.js)
			const script = document.createElement('script');
			script.async = true;
			script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;

			document.body.appendChild(script);

			window.dataLayer = window.dataLayer || [];
			window.gtag = function gtag() {
				window.dataLayer.push(arguments);
			};

			window.gtag('js', new Date());
			window.gtag('config', gaId, {
				appName: config.APP_NAME,
				appVersion: config.APP_VERSION,
			});

			this._gtag = window.gtag;
			this._isIntialized = true;
		}
	}

	static event({ action, category }: { action: string; category: string; }) {
		this._gtag?.('event', category, {
			action,
		});
	}

	static pageview(location: string) {
		this._gtag?.('event', 'page_view', {
			/* eslint-disable camelcase */
			page_location: location,
			/* eslint-enable camelcase */
		});
	}
}
