import React from 'react';
import './productBadges.scss';
import { FaCheckCircle } from 'react-icons/fa';
import { t } from './../../../libraries/i18n';
import DateHelper from '../../../libraries/dateHelper';
import { useSelector } from 'react-redux';
import { selectDeliveryDate } from '../../../modules/selectors';

export default function ProductBadges(props) {
	const product = props.product;
	const showSecondaryOverride = props?.showSecondaryOverride ?? true;
	const deliveryDate = useSelector(selectDeliveryDate);

	if (!product.eob && !product.season && !product.timeSensitive) return null;

	const season = {};
	if (product.season) {
		season.start = DateHelper.getWeekNumber(new Date(product.start))[1];
		season.end = DateHelper.getWeekNumber(new Date(product.end))[1];
	}

	const renderEob = () => {
		if (!product.eob) return null;

		let secondary = t('order.label_eob_net_unknown');
		let showSecondary = true;

		if (product.eob.net === undefined || product.eob.net === null) {
			showSecondary = false;
		} else {
			secondary = t('order.label_eob_net_with_charles_on', DateHelper.getDateStringFromReversedDateString(product.eob.net));
		}

		if (deliveryDate) {
			const netTimestamp = (new Date(product.eob.net)).getTime();
			const deliveryTimestamp = deliveryDate.getTime();
			if (netTimestamp < deliveryTimestamp) {
				secondary = (
					<>
						<FaCheckCircle />
						{t('order.label_eob_net_available')}
					</>
				);
			}
		}

		return (
			<li
				key={'eob'}
				className={'product__badge product__badge--eob badge--with-secondary'}
				title={t('product.badgeEobHint')}
			>
				<span className='badge'>
					{t('product.badgeEobLabel')}
				</span>
				{(showSecondary && showSecondaryOverride) &&
					<span className='secondary'>
						{secondary}
					</span>
				}
			</li>
		);
	};

	return (
		<ul className={'ProductBadges'}>
			{renderEob()}

			{product.season && (
				<li
					key={'season'}
					className={'product__badge product__badge--season'}
					title={t('product.badgeSeasonHint', season.start, season.end)}
				>
					{season.start} - {season.end}
				</li>
			)}
		</ul>
	);
}
