export const selectDefaultFilters = state => state.defaultFilters;
const selectCurrentFilters = state => state.filters;
export const selectQuery = state => state.query;

export const selectActiveFilters = (state) => {
	const defaultFilters = selectDefaultFilters(state);
	const currentFilters = selectCurrentFilters(state);
	const filters = {};

	Object.keys(defaultFilters).forEach(filterGroup => {
		filters[filterGroup] = {};

		Object.keys(defaultFilters[filterGroup]).forEach(filterName => {
			filters[filterGroup][filterName] = (currentFilters[filterGroup] && currentFilters[filterGroup].hasOwnProperty(filterName))
				? currentFilters[filterGroup][filterName]
				: defaultFilters[filterGroup][filterName];
		});
	});

	return filters;
};

export const selectSortKey = state => state.sortKey;
export const selectSortDirection = state => state.sortDirection;
export const selectView = state => state.view;
