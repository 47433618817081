import { useSelector } from 'react-redux';
import { selectCustomer, selectCustomerAccount, selectLocale } from '../../modules/selectors';
import useCustomerRoute from './useCustomerRoute';

export default function useCustomer() {
	useCustomerRoute();
	const customer = useSelector(selectCustomer);
	const account = useSelector(selectCustomerAccount);
	const locale = useSelector(selectLocale);

	return {
		...customer,
		account,
		locale
	};
}
