/* eslint-disable no-unused-vars */
import { getCampaigns } from './handlers/getCampaigns';
import { login } from './handlers/login';
import { posts } from './handlers/posts';
import { getProductPromos } from './handlers/getProductPromos';
import { getProducts } from './handlers/getProducts';
import { getProductsCustomers } from './handlers/getProductsCustomers';
import { getMetaData } from './handlers/getMetaData';
import { getHistorhy } from './handlers/getHistory';
import { getMe } from './handlers/getMe';
import { getNl } from './handlers/getNl';
import { getApiV1 } from './handlers/getApiV1';
import { getApiProductsMetaData } from './handlers/getApiProductsMetaData';
import { getCustomers } from './handlers/getCustomers';
import { getFoodEventTickets } from './handlers/getCustomCampaignsFoodEventTickets2022';
import { getApiV1CustomersIdSettingsContact } from './handlers/getApiV1CustomersIdSettingsContact';
import { getApiV1CustomersIdSettingsCommunication } from './handlers/getApiV1CustomersIdSettingsCommunication';
import { getApiV1CustomersIDSettingsPermissions } from './handlers/getApiV1CustomersIDSettingsPermissions';
import { getApiV1CustomersIDSettingsPermissionsId } from './handlers/getApiV1CustomersIDSettingsPermissionsId';
import { getApiV1CustomersIDSettingsPermissionsMetadata } from './handlers/getApiV1CustomersIDSettingsPermissionsMetadata';
import { getPreorders, getPreorders6 } from './handlers/getPreorders';
import { getEobDetails } from './handlers/getEobDetails';
import { getProductPoints } from './handlers/getProductPoints';

//Request handler is a function that determines whether an outgoing request should be mocked, and specifies its mocked response.
const URL = 'http://localhost:4000';

export const handlers = [
	// ...login,
	// ...getProductPromos(URL),
	// ...posts,
	// ...getCampaigns,
	// ...getProducts(URL),
	// ...getProductsCustomers(URL),
	// ...getMetaData,
	// ...getHistorhy,
	// ...getMe,
	// ...getNl,
	// ...getApiV1,
	// ...getApiProductsMetaData,
	// ...getCustomers(URL),
	//...getFoodEventTickets(URL),
	//...getApiV1CustomersIdSettingsContact(URL),
	//...getApiV1CustomersIdSettingsCommunication(URL),
	//...getApiV1CustomersIDSettingsPermissions(URL),
	//...getApiV1CustomersIDSettingsPermissionsMetadata(URL),
	// ...getApiV1CustomersIDSettingsPermissionsId (URL),
	// ...getPreorders(URL)
	// ...getEobDetails(URL)
	// ...getProductPoints(URL),
	//...getPreorders6(URL)
];
