import charlesLogo from '../../images/CharlesLogo.svg';
import React from 'react';
import './appError.scss';

const MaintenanceMessage = () => (
	<div className={'AppError'}>
		<img className={'charles-logo'} src={charlesLogo} alt="Charles Logo" />
		<div className={'error__message'}>
			<h1>De portal is in onderhoudsmodus</h1>
			<h2>Er worden momenteel wijzigingen aan de portal aangebracht op basis van uw feedback</h2>
		</div>
	</div>
);

export default MaintenanceMessage;
