import React from 'react';
import { FaCheck, FaPlus, FaTimes } from 'react-icons/fa';
import { t } from './../../libraries/i18n';
import './ProductPicker.scss';

const getProductPickerHeader = (code, disabled, disabledReasons) => {
	if (!disabled) {
		switch (code) {
			case 'black_friday_2020.free_samples':
				return { title: t('order.freeSampleTitleBF2020'), description: t('order.freeSampleDescriptionBF2020') };
			default:
				return { title: t('order.freeSampleTitle'), description: t('order.freeSampleDescription') };
		}
	} else {
		switch (code) {
			case 'black_friday_2020.free_samples': {
				switch (disabledReasons.type) {
					case 'minimum_not_reached':
						return { title: t('order.freeSampleTitleBF2020'), description: t('order.freeSampleDisabledDescriptionBF2020', disabledReasons.minimum, disabledReasons.estimated) };
					default: return { title: '', description: '' };
				}
			}
			default:
				return { title: t('order.freeSampleTitle'), description: t('order.freeSampleDisabledDescription') };
		}
	}

};

// eslint-disable-next-line object-curly-newline
const PickableProduct = ({ product, selected, nothingSelected, select, unselect, openModal, disabled }) => {
	if (product.id === '0') {
		return (
			<div
				className={`noSample${selected ? ' selected' : !nothingSelected ? ' notSelected' : ''}`}
				key={product.id}>
				<button className={'product'} onClick={select} disabled={disabled}>
					<span className={'none'}>{t('order.freeSampleNone')}</span>
				</button>
				{selected && !disabled && (
					<button className={'sampleAction unselect'} onClick={unselect}>
						<FaCheck />
						<FaTimes />
					</button>

				)}
			</div>
		);
	}
	return (
		<div
			className={`sample${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
		>
			<button
				className={'product'}
				onClick={select}
				disabled={disabled}
			>
				{product.images?.PS?.[0] && <img
					src={`${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/1200/${product.images.PS[0]}`}
					alt={product.name}
					onClick={openModal}
				/>}
				{product.id !== '0' && <span className={'id'}>{product.id}</span>}
				<span className={'name'}>{product.name}</span>
			</button>
			{selected && !disabled && (
				<button
					className={'sampleAction unselect'}
					onClick={unselect}
				>
					<FaCheck />
					<FaTimes />
				</button>

			)}
			{!selected && nothingSelected && !disabled && (
				<div
					className={'sampleAction select'}
					onClick={select}
				>
					<FaPlus />
				</div>
			)}

			{product.description && (
				<>
					<p className={'description'}>
						{product.description}
					</p>
					{!disabled && (
						<span
							onClick={openModal}
							className={'more'}
						>
							{t('order.freeSampleMore')}
						</span>
					)}
				</>
			)}
		</div>
	);
};

export default function ProductPicker({
	code, products, optional, answer, select, unselect, openModal, disabled, disabledReasons
}) {
	const header = getProductPickerHeader(code, disabled, disabledReasons);

	return (
		<div className={`ProductPicker${(!optional && answer === undefined && !disabled) ? ' important' : ''}`} key={code}>
			<h3>{header.title}</h3>
			<p>{header.description}</p>
			<div className={'productList'}>
				{products?.map(product => (
					<PickableProduct
						key={`${code}-${product.id}`}
						product={product}
						selected={product.id === answer}
						nothingSelected={answer === undefined}
						select={() => select?.(product.id)}
						unselect={(e) => {
							e.stopPropagation();
							return unselect?.();
						}}
						openModal={() => openModal?.(product)}
						disabled={disabled}
					/>
				))}
			</div>
		</div>
	);
}
