import { useEffect, useState } from 'react';
import { getVacationDates } from '../../libraries/api';
import { selectCustomerId } from '../../modules/selectors';
import { useAppSelector } from '../../libraries/hooks/redux';

export default function useVacation() {
	const customerId = useAppSelector(selectCustomerId);
	const [vacation, setVacation] = useState<Array<Date> | undefined>(undefined);
	const [error, setError] = useState<any | undefined>(undefined);

	useEffect(() => {
		const getDates = async () => {
			try {
				const dates: Array<Date> = await getVacationDates(customerId);
				setVacation(dates);
				setError(undefined);
			} catch (error: any) {
				setError(error);
			}
		};
		getDates();
	}, [customerId]);

	return {
		vacation,
		isFetching: !vacation && !error,
		error
	};
}
