import React from 'react';
import './Currency.scss';

function Currency(props) {
	const isValidNumber = ({ value }) => !!Number(value) || Number(value) === 0;

	const renderValue = ({
		value,
		currency = 'EUR'
	}) => {
		const numberFormat = Intl.NumberFormat('nl-BE', { style: 'currency', currency });
		const formattedValue = numberFormat.format(value);

		return (
			<span className={`Currency ${props.className ?? ''}`}>
				{formattedValue}
			</span>
		);
	};

	if (!isValidNumber(props)) return props.value || null;
	return renderValue(props);
}

export default Currency;
