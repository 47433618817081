import React from 'react';
import './ProductsHeader.scss';
import Search from './../Search/Search';
import {
	FaAlignJustify,
	FaSortNumericDown,
	FaSortNumericUp,
	FaSortAlphaDown,
	FaSortAlphaUp,
	FaThLarge,
	FaThList
} from 'react-icons/fa';
import Dropdown from './../dropdown/dropdown';
import { t } from './../../libraries/i18n';
import ActiveFilters from '../ProductsFilters/ActiveFilters/ActiveFilters';

export default function ProductsHeader(props) {
	const {
		handleSearch,
		query,
		numProducts,
		filters,
		defaultFilters,
		view,
		changeSort,
		changeView,
		sortKey,
		direction,
		sortingDisabled,
		shopcodes,
		campaigns,
		clearFilters,
		setFilter,
		forceGridView
	} = props;

	const renderListOptionsSorting = (changeSort, sortKey, direction, sortingDisabled) => {
		const sortType = sortKey === 'code' || sortKey === 'shopcode' ? 'numeric' : 'alpha';

		return (
			<div className={`list__sorting ${sortingDisabled ? 'list__sorting--disabled' : ''}`}>
				{t('order.filterSortingLabel')}
				<Dropdown
					value={sortKey}
					onChange={(value) => !sortingDisabled && changeSort(value, 'ascending')}
					options={[
						{
							value: 'shopcode',
							description: t('order.filterSortingShopcode')
						},
						{
							value: 'code',
							description: t('order.filterSortingCode')
						},
						{
							value: 'name',
							description: t('order.filterSortingDescription')
						}
					]}
					disabled={sortingDisabled}
				/>

				<div className={'sort__direction'}>
					{sortType === 'numeric' ? (
						<div className={'direction__numeric'}>
							<FaSortNumericDown
								className={`direction ${direction === 'ascending' ? 'direction--active' : ''}`}
								onClick={() => !sortingDisabled && changeSort(sortKey, 'ascending')}
							/>
							<FaSortNumericUp
								className={`direction ${direction === 'descending' ? 'direction--active' : ''}`}
								onClick={() => !sortingDisabled && changeSort(sortKey, 'descending')}
							/>
						</div>
					) : (
						<div className={'direction__alpha'}>
							<FaSortAlphaDown
								className={`direction ${direction === 'ascending' ? 'direction--active' : ''}`}
								onClick={() => !sortingDisabled && changeSort(sortKey, 'ascending')}
							/>
							<FaSortAlphaUp
								className={`direction ${direction === 'descending' ? 'direction--active' : ''}`}
								onClick={() => !sortingDisabled && changeSort(sortKey, 'descending')}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	const renderListOptionsView = (changeView, view) => {
		if (forceGridView) {
			return (
				<div className={'list__view'}>
					<span
						onClick={() => changeView('grid')}
						className={`view__button view--grid ${view === 'grid' ? 'view--active' : ''}`}
					>
						<FaThLarge />
					</span>
				</div>
			);
		}
		return (
			<div className={'list__view'}>
				<span
					onClick={() => changeView('list-compact')}
					className={`view__button view--list ${view === 'list-compact' ? 'view--active' : ''}`}
				>
					<FaAlignJustify />
				</span>
				<span
					onClick={() => changeView('list')}
					className={`view__button view--list ${view === 'list' ? 'view--active' : ''}`}
				>
					<FaThList />
				</span>
				<span
					onClick={() => changeView('grid')}
					className={`view__button view--grid ${view === 'grid' ? 'view--active' : ''}`}
				>
					<FaThLarge />
				</span>
			</div>
		);
	};

	return (
		<div className='ProductsHeader'>
			<div className='list-controls'>
				<Search
					handleSearch={handleSearch}
					query={query}
					placeholder={t('order.searchPlaceholder')}
				/>

				<div className={'list-options'}>
					{renderListOptionsSorting(changeSort, sortKey, direction, sortingDisabled)}
					{renderListOptionsView(changeView, view)}
				</div>
			</div>
			<div className='filter-info'>
				<span className='num-products'>
					{numProducts === 1 ? t('order.detailNumProducts') : t('order.detailNumProductsMultiple', numProducts)}
				</span>

				<ActiveFilters
					activeQuery={query}
					activeFilters={filters}
					defaultFilters={defaultFilters}
					allShopcodes={shopcodes}
					campaigns={campaigns}
					clearFilters={clearFilters}
					handleSearch={handleSearch}
					setFilter={setFilter}
				/>
			</div>
		</div>
	);
}
