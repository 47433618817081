const config = {
	APP_NAME: process.env.REACT_APP_APP_NAME,
	APP_VERSION: process.env.REACT_APP_APP_VERSION,
	API_URL: process.env.REACT_APP_PORTAL_URL,
	ENV: process.env.NODE_ENV,
	GA_ID: process.env.REACT_APP_GA_ID,
	LOGIN_URL: `${process.env.REACT_APP_PORTAL_URL}/index.php/login`,
	PORTAL_LOGIN_URL: process.env.REACT_APP_PORTAL_LOGIN_URL,
};

export default config;
