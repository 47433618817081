import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useSearchParams() {
	const [params, setParams] = useState({});
	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const search = Object.fromEntries(searchParams.entries());

		setParams(search);
	}, [location]);

	return params;
}
