import initialState from './../initialState';
import {
	BUYLIST_HANDLE_SEARCH,
	BUYLIST_CHANGE_VIEW_TYPE,
	BUYLIST_TOGGLE_FILTER,
	BUYLIST_SET_FILTER,
	BUYLIST_CLEAR_FILTERS,
	BUYLIST_CHANGE_SORTING
} from './buylistActions';
import UserPreferences from './../../libraries/userPreferences';
import { selectDefaultFilters } from './buylistSelectors';

export default function buylist(
	state = initialState.buylist,
	action
) {
	switch (action.type) {
		case BUYLIST_HANDLE_SEARCH:
			return buylistHandleSearch(state, action);
		case BUYLIST_CHANGE_VIEW_TYPE:
			return buylistChangeViewType(state, action);
		case BUYLIST_TOGGLE_FILTER:
			return {
				...state,
				filters: changeFilterState(state.filters, selectDefaultFilters(state), action.filter)
			};
		case BUYLIST_SET_FILTER:
			return {
				...state,
				filters: changeFilterState(state.filters, selectDefaultFilters(state), action.filter, action.state)
			};
		case BUYLIST_CLEAR_FILTERS:
			return buylistClearFilters(state, action);
		case BUYLIST_CHANGE_SORTING:
			return buylistChangeSorting(state, action);
		default:
			return { ...state };
	}
}

function buylistHandleSearch(state, action) {
	return {
		...state,
		currentPage: 1,
		query: action.query
	};
}

function nextIncludeExcludeState(state) {
	switch (state) {
		case 'off':
			return 'include';
		case 'include':
			return 'exclude';
		case 'exlude':
		default:
			return 'off';
	}
}

function changeFilterState(state, defaultFilters, filter, newFilterState) {
	// Get filter group
	filter = filter.split('.');
	const filterGroup = filter[0];
	filter.shift();
	const filterName = filter.join('.');

	// get current filter state
	const currentFilterState = state[filterGroup]?.[filterName] ?? defaultFilters[filterGroup]?.[filterName];

	// get next filter state
	let nextFilterState = newFilterState ?? !currentFilterState;
	if ((filterGroup === 'main' || filterGroup === 'campaigns') && filterName !== 'buylist') {
		nextFilterState = newFilterState || nextIncludeExcludeState(currentFilterState);
	}

	// set state
	return {
		...state,
		[filterGroup]: {
			...state[filterGroup],
			[filterName]: nextFilterState
		}
	};
}

function buylistChangeViewType(state, action) {
	UserPreferences.set(UserPreferences.PREFERENCES.PRODUCTS_VIEW, action.view);

	return {
		...state,
		view: action.view,
		currentPage: 1
	};
}

function buylistClearFilters(state, action) {
	let filters = state.filters;
	if (action.group) filters[action.group] = [];
	else filters = initialState.buylist.filters;

	return {
		...state,
		currentPage: 1,
		filters: filters
	};
}

function buylistChangeSorting(state, action) {
	let direction = action.payload.direction;
	if (action.payload.sortKey !== state.sortDirection) direction = 'ascending';

	UserPreferences.set(UserPreferences.PREFERENCES.PRODUCT_SORT_KEY, action.payload.sortKey);
	UserPreferences.set(UserPreferences.PREFERENCES.PRODUCT_SORT_DIRECTION, direction);

	return {
		...state,
		sortKey: action.payload.sortKey,
		sortDirection: action.payload.direction,
		currentPage: 1
	};
}
