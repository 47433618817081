import React from 'react';
import './FilterGroup.scss';
import { FaTrashAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { usePreference } from '../../libraries/hooks';

function FilterGroup(props) {
	const {
		numFilters,
		children,
		clearFilters,
		showClearFilters,
		className,
		defaultCollapsed,
		id
	} = props;
	const [hidden, setHidden] = usePreference(`${id}.hidden`, false);

	const incorrectChildrenError = () => new Error('FilterGroup should have exactly 2 children: FilterGroup.Title first and FilterGroup.Body second');

	if (!Array.isArray(children) || children?.length < 2) throw incorrectChildrenError();

	// Add id and defaultCollapsed to FilterGroupBody
	const childrenWithProps = React.Children.map(
		children,
		(child) => React.cloneElement(child, { defaultCollapsed, id })
	);

	const filterGroupTitle = childrenWithProps[0];
	const filterGroupBody = childrenWithProps[1];
	if (!filterGroupTitle || !filterGroupBody) throw incorrectChildrenError();

	const shouldShowClearFilters = () => showClearFilters ?? numFilters > 0;

	return (
		<div className={`FilterGroup filter__container ${className} hidden--${hidden}`}>
			<h3 className={'filter__title'}>
				<div className={'filter__name'}>
					{filterGroupTitle} {numFilters > 0 ? `(${numFilters})` : ''}

					{shouldShowClearFilters() && (
						<div className={'filter__clear'} onClick={clearFilters}>
							<FaTrashAlt />
						</div>
					)}
				</div>

				<div className={'filter__hide'} onClick={() => setHidden(!hidden)}>
					{hidden ? (
						<FaChevronDown />
					) : (
						<FaChevronUp />
					)}
				</div>
			</h3>

			{!hidden && filterGroupBody}
		</div>
	);
}

const FilterGroupTitle = props => props.children;
const FilterGroupBody = props => {
	const {
		id,
		defaultCollapsed,
		children
	} = props;

	const [collapsed, setCollapsed] = usePreference(`${id}.collapsed`, defaultCollapsed ?? true);

	const renderSimpleBody = () => (
		<div className={'filter__items'}>
			{props.children}
		</div>
	);

	if (!Array.isArray(children)) return renderSimpleBody();

	if (children.length !== 2) throw new Error('FilterGroup.Body should either have 1 child or 2 children of type FilterGroup.Collapsed and FilterGroup.Uncollapsed (in that order)');

	const filterGroupCollapsed = children[0];
	const filterGroupUncollapsed = children[1];
	if (!filterGroupCollapsed && !filterGroupUncollapsed) return renderSimpleBody();

	return (
		<>
			<div className={'filter__items'}>
				{collapsed && filterGroupCollapsed}
				{!collapsed && filterGroupUncollapsed}
			</div>

			{filterGroupUncollapsed && (
				<div className={'filter__more'} onClick={() => setCollapsed(!collapsed)}>
					{collapsed ? 'Meer filters tonen…' : 'Minder…'}
				</div>
			)}
		</>
	);
};
const FilterGroupCollapsed = props => props.children;
const FilterGroupUncollapsed = props => props.children;

FilterGroup.Title = FilterGroupTitle;
FilterGroup.Body = FilterGroupBody;
FilterGroup.Collapsed = FilterGroupCollapsed;
FilterGroup.Uncollapsed = FilterGroupUncollapsed;

export default FilterGroup;
