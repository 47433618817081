import React from 'react';
import useSWR from 'swr';
import useCustomer from './../../../libraries/hooks/useCustomer';
import { fetcher } from './../../../api';
import { t } from '../../../libraries/i18n';
import CommunicationFollowUpOrders from './CommunicationFollowUpOrders';
import CommunicationFromCharles from './CommunicationFromCharles';
import { Spinner } from '@abm-international/react-components';

export type CommunicationData = {
	follow_up_order: {
		confimation_order_recieved: boolean,
		confimation_order_prepared: boolean,
		confimation_order_prepared_with_order_form: boolean,
		confimation_order_is_done: boolean,
		confimation_order_is_done_with_order_form: boolean,
		confimation_truck_left: boolean,
		confimation_truck_left_with_order_form: boolean,
		confimation_order_recieved_after_delivery: boolean,
		confimation_order_recieved_after_delivery_with_order_form: boolean,
	};
	from_charles: {
		newsletter_recieve: boolean;
		product_specification_is_updated: boolean;
		new_statistics_online: boolean;
	}
}

const useCommunicationData = () => {
	const { id } = useCustomer();
	const { data, mutate } = useSWR(`/api/v1/customers/${id}/settings/communication`, fetcher);


	return {
		data,
		mutate
	};
};
export default function Communication() {
	const { data, mutate } = useCommunicationData();

	const updatePage = (key: keyof CommunicationData, newData: CommunicationData['follow_up_order'] | CommunicationData['from_charles']) => {
		mutate({ ...data, [key]: newData });
	 };

	return (
		<div className='Communication'>
			<p>{t('settings.communication_text')}</p>
			<h1>{t('settings.title_communication_follow_up_orders')}</h1>
			{data && <CommunicationFollowUpOrders updatePage={updatePage} data={data.follow_up_order} errors={data.errors} />}
			{!data && <Spinner></Spinner>}
			<h1>{t('settings.title_communication_from_charles')}</h1>
			{data && <CommunicationFromCharles updatePage={updatePage} data={data.from_charles} errors={data.errors} />}
			{!data && <Spinner></Spinner>}
		</div>
	);
}
