import Api from './../../libraries/api';
import {
	selectDetailMargins, selectDetailMarginsAreFresh,
	selectIsDetailMarginsFetching,
	selectIsMarginsFetching,
	selectIsMarginsMetadataFetching, selectMargins, selectUnsavedDetailMargins, selectUnsavedMargins
} from './../selectors';
import { v4 as uuidv4 } from 'uuid';


export const MARGINS_REQUEST = 'MARGINS_REQUEST';
export const MARGINS_REQUEST_RECEIVE = 'MARGINS_REQUEST_RECEIVE';
export const MARGINS_REQUEST_REJECT = 'MARGINS_REQUEST_REJECT';

export const MARGINS_METADATA_REQUEST = 'MARGINS_METADATA_REQUEST';
export const MARGINS_METADATA_REQUEST_RECEIVE = 'MARGINS_METADATA_REQUEST_RECEIVE';
export const MARGINS_METADATA_REQUEST_REJECT = 'MARGINS_METADATA_REQUEST_REJECT';

export const MARGINS_DETAIL_REQUEST = 'MARGINS_DETAIL_REQUEST';
export const MARGINS_DETAIL_REQUEST_RECEIVE = 'MARGINS_REQUEST_DETAIL_RECEIVE';
export const MARGINS_DETAIL_REQUEST_REJECT = 'MARGINS_REQUEST_DETAIL_REJECT';

export const MARGINS_SAVE_REQUEST = 'MARGINS_SAVE_REQUEST';
export const MARGINS_SAVE_REQUEST_RECEIVE = 'MARGINS_SAVE_REQUEST_RECEIVE';
export const MARGINS_SAVE_REQUEST_REJECT = 'MARGINS_SAVE_REQUEST_REJECT';

export const MARGINS_DETAIL_SAVE_REQUEST = 'MARGINS_DETAIL_SAVE_REQUEST';
export const MARGINS_DETAIL_SAVE_REQUEST_REJECT = 'MARGINS_DETAIL_SAVE_REQUEST_REJECT';
export const MARGINS_DETAIL_SAVE_REQUEST_RECEIVE = 'MARGINS_DETAIL_SAVE_REQUEST_RECEIVE';


export const MARGINS_CANCEL = 'MARGINS_CANCEL';

export const SET_MARGIN = 'SET_MARGIN';
export const SET_DETAIL_MARGIN = 'SET_DETAIL_MARGIN';
export const SET_DETAIL_MARGIN_BY_PRICE = 'SET_DETAIL_MARGIN_BY_PRICE';


function fetchMargins(customerId) {
	return async dispatch => {
		dispatch({
			type: MARGINS_REQUEST
		});

		let data;

		try {
			data = await Api.getMargins(customerId);
		} catch (error) {
			return dispatch({
				type: MARGINS_REQUEST_REJECT,
				error
			});
		}

		return dispatch({
			type: MARGINS_REQUEST_RECEIVE,
			data
		});
	};
}

const shouldFetchMargins = state => {
	const isFetching = selectIsMarginsFetching(state);
	const margins = selectMargins(state);
	if (isFetching) return false;
	return ! (margins?.length > 0);
};

export const fetchMarginsIfNeeded = (customerId) => {
	return (dispatch, getState) => {
		if (shouldFetchMargins(getState())) {
			dispatch(fetchMargins(customerId));
		}
	};
};



function fetchMarginsMetadata(customerId) {
	return async dispatch => {
		dispatch({
			type: MARGINS_METADATA_REQUEST
		});

		let data;

		try {
			data = await Api.getMarginsMetadata(customerId);
		} catch (error) {
			return dispatch({
				type: MARGINS_METADATA_REQUEST_REJECT,
				error
			});
		}

		return dispatch({
			type: MARGINS_METADATA_REQUEST_RECEIVE,
			data
		});
	};
}

const shouldFetchMarginsMetadata = state => {
	const isFetching = selectIsMarginsMetadataFetching(state);
	return !isFetching;
};

export const fetchMarginsMetadataIfNeeded = (customerId) => {
	return (dispatch, getState) => {
		if (shouldFetchMarginsMetadata(getState())) {
			dispatch(fetchMarginsMetadata(customerId));
		}
	};
};



function fetchDetailMargins(customerId, category, productType, marginType) {
	return async dispatch => {
		dispatch({
			type: MARGINS_DETAIL_REQUEST,
			category, productType, marginType
		});

		let data;

		try {
			if (category === 'article'){
				data = await Api.getDetailMarginsByProductId(customerId, productType );
			} else {
				data = await Api.getDetailMargins(customerId, category, marginType, productType );
			}
		} catch (error) {
			return dispatch({
				type: MARGINS_DETAIL_REQUEST_REJECT,
				category, productType, marginType,
				error
			});
		}

		return dispatch({
			type: MARGINS_DETAIL_REQUEST_RECEIVE,
			category, productType, marginType,
			data
		});
	};
}

const shouldFetchDetailMargins = ( state, category, productType, marginType) => {
	const isFetching = selectIsDetailMarginsFetching(state, category, productType, marginType);
	const detailMarginsAreFresh = selectDetailMarginsAreFresh(state, category, productType, marginType);
	if (isFetching) return false;
	return !detailMarginsAreFresh;
};

export const fetchDetailMarginsIfNeeded = (customerId, category, productType, marginType) => {
	return (dispatch, getState) => {
		if (shouldFetchDetailMargins(getState(), category, productType, marginType)) {
			dispatch(fetchDetailMargins(customerId, category, productType, marginType));
		}
	};
};


const createActions = (updates, current) => {
	return updates.map( (update) => {
		const version = current.find((c) => c.category === update.category && c.marginType === update.marginType && c.productType === update.productType )?.version;
		if (version){
			if (update.value !== ''){
				return {
					action: 'update',
					id: uuidv4(),
					data: {
						...update,
						value: +update.value,
						version
					}
				};
			}

			return {
				action: 'delete',
				id: uuidv4(),
				data: {
					...update,
					value: undefined,
					version
				}
			};
		}
		return {
			action: 'create',
			id: uuidv4(),
			data: {
				...update,
				value: +update.value,
			}
		};
	});
};

const createDetailActions = (updates, current) => {
	return updates.map( (update) => {
		const version = current.articles?.[update.article]?.version;
		if (version){
			if (update.value !== ''){
				return {
					action: 'update',
					id: uuidv4(),
					data: {
						...update,
						value: +update.value,
						version
					}
				};
			}

			return {
				action: 'delete',
				id: uuidv4(),
				data: {
					...update,
					value: undefined,
					version
				}
			};
		}
		return {
			action: 'create',
			id: uuidv4(),
			data: {
				...update,
				value: +update.value,
			}
		};
	});
};

export const saveMargins = (customer) => async (dispatch, getState) => {
	const state = getState();
	const unsavedMargins = selectUnsavedMargins(state);
	const margins = selectMargins(state);

	dispatch({
		type: MARGINS_SAVE_REQUEST
	});

	let data;

	try {
		data = await Api.saveMargins(customer, createActions(unsavedMargins, margins));
	} catch (error) {
		return dispatch({
			type: MARGINS_SAVE_REQUEST_REJECT,
			error
		});
	}

	return dispatch({
		type: MARGINS_SAVE_REQUEST_RECEIVE,
		data
	});
};

export const saveDetailMargins = (customer, category, productType, marginType) => async (dispatch, getState) => {
	const state = getState();
	const unsavedMargins = selectUnsavedDetailMargins(state, category, productType, marginType);
	const margins = selectDetailMargins(state, category, productType, marginType);

	dispatch({
		type: MARGINS_DETAIL_SAVE_REQUEST,
		data: {
			category,
			marginType,
			productType
		}
	});

	let response;

	try {
		response = await Api.saveMargins(customer, createDetailActions(unsavedMargins, margins));
	} catch (error) {
		return dispatch({
			type: MARGINS_DETAIL_SAVE_REQUEST_REJECT,
			data: {
				category,
				marginType,
				productType
			},
			error
		});
	}

	return dispatch({
		type: MARGINS_DETAIL_SAVE_REQUEST_RECEIVE,
		data: {
			category,
			marginType,
			productType,
			response
		}
	});
};

export const cancelUnsavedMargins = () => (dispatch) => {
	dispatch({
		type: MARGINS_CANCEL
	});
};

export const setMargin = (value, category, marginType, productType) => async dispatch => {
	dispatch({
		type: SET_MARGIN,
		data: {
			value,
			category,
			marginType,
			productType
		}
	});
};

export const setDetailMargin = (value, article, category, productType, marginType) => async dispatch => {
	dispatch({
		type: SET_DETAIL_MARGIN,
		data: {
			value,
			article,
			category,
			productType,
			marginType
		}
	});
};

export const setDetailMarginByPrice = (value, article, category, productType, marginType) => async dispatch => {
	dispatch({
		type: SET_DETAIL_MARGIN_BY_PRICE,
		data: {
			value,
			article,
			category,
			productType,
			marginType
		}
	});
};


