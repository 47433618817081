import { useState, useLayoutEffect } from 'react';

export default function useFullscreen(elRef) {
	const [isFullscreen, setIsFullscreen] = useState(browserHasFullscreenElement());

	const setFullscreen = shouldFullscreen => {
		if (elRef.current === null) return;

		if (!shouldFullscreen) {
			document.exitFullscreen().then(() => {
				setIsFullscreen(browserHasFullscreenElement());
			}).catch(() => {
				setIsFullscreen(true);
			});
		}

		elRef.current
			.requestFullscreen()
			.then(() => {
				setIsFullscreen(true);
			})
			.catch(() => {
				setIsFullscreen(false);
			});
	};

	useLayoutEffect(() => {
		document.onfullscreenchange = () => setIsFullscreen(browserHasFullscreenElement());

		return () => (document.onfullscreenchange = undefined);
	});

	return [isFullscreen, setFullscreen];
}

function browserHasFullscreenElement() {
	let fullscreenElement = null;
	if (typeof document.fullscreenElement !== 'undefined') fullscreenElement = document.fullscreenElement;
	else if (typeof document.mozFullScreenElement !== 'undefined') fullscreenElement = document.mozFullScreenElement;
	else if (typeof document.msFullscreenElement !== 'undefined') fullscreenElement = document.msFullscreenElement;
	else if (typeof document.webkitFullscreenElement !== 'undefined') fullscreenElement = document.webkitFullscreenElement;
	else console.error('fullscreenElement is not supported by this browser');

	return fullscreenElement !== null;
}
