import React, { useState, useEffect } from 'react';
import './search.scss';
import useDebounce from './../../libraries/hooks/useDebounce';

function Search({
	placeholder, query, resync, handleSearch
}) {
	const [localQuery, setLocalQuery] = useState(query);
	const debouncedQuery = useDebounce(localQuery, 300);

	// If you're confused why handleSearch is getting called a bunch of times,
	// you should probably memoize whatever you pass to handleSearch.
	// e.g.: wrap it in useCallback (example: PriceChanges.js)
	// If you pass a function to handleSearch that gets reinitialized on every render,
	// useEffect will think it has changed and execute the effect.
	useEffect(() => {
		if (typeof handleSearch === 'function') handleSearch(debouncedQuery);
	}, [ debouncedQuery, handleSearch ]);

	// Sync with query prop when parent sets resync. The parent needs to control this because we
	// can't be sure if the query is delayed (due to the deboucne) or changed from outside.
	useEffect(() => {
		if (resync && localQuery !== query) setLocalQuery(query);
	}, [localQuery, query, resync]);

	return (
		<div className="Search">
			<input
				type="text"
				onChange={e => setLocalQuery(e.target.value)}
				placeholder={placeholder}
				value={localQuery}
			/>
		</div>
	);
}

export default Search;
