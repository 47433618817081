export default class HttpTransport {
	constructor(options = {}) {
		this.name = options.name || 'HttpTransport';
		this.level = options.level || 'info';
		this.endpoint = options.endpoint;
	}

	log(level, msg, meta) {
		const user = JSON.parse(localStorage.getItem('portal.client.customer'));

		let data = {
			'fileset.name': level,
			'abm.severity': level,
			'abm.timestamp': (new Date()).toISOString(),
			'abm.application': 'portal.client',
			'abm.useragent': window.navigator.userAgent,
			message: `[portal.client][${level}] ${msg}`,
			'abm.data': JSON.stringify(meta)
		};

		if (user) {
			data = {
				...data,
				'abm.session': user.session || '',
				'abm.user-account': user.account || '',
				'abm.user-name': user.username || ''
			};
		}

		fetch(`${this.endpoint}/log`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		});
	}
}
