import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Navigation from './Navigation';
import {
	appNavigationToggleCollapse
} from './../appActions';
import {
	selectLocale,
	selectNavigationItems
} from './../selectors';

function NavigationContainer(props) {

	return (
		<Navigation {...props} />
	);
}

function mapStateToProps(state) {
	return {
		company: state.customer.company,
		customer: state.customer.customer,
		multipleUsers: state.customer.hasMultipleCustomersAvailable,
		isInternalUser: state.customer.account < 10000,
		username: state.customer.username,
		locale: selectLocale(state),
		collapsed: state.app.navigation.collapsed,
		navItems: selectNavigationItems(state)
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleCollapse: (collapsed) => dispatch(appNavigationToggleCollapse(collapsed))
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationContainer));
