import React, { useCallback } from 'react';
import { Article } from '@abm-international/react-components';

import './BuylistArticle.scss';


export const getHeight = () => {
	return 550;
};

const BuylistArticleGrid = (props) => {

	const {
		showModal,
		product,
		query,
		locale,
		t,
		...restProps
	} = props;

	const openModal = useCallback(() => showModal(product.id), [product.id, showModal]);

	return (
		<Article
			{...restProps}
			product={product}
			t={t}
			query={query}
			locale={locale}
		>
			{(A) => (
				<div
					className={'Article grid'}
				>
					<A.Image onClick={openModal} />
					<div className={'detail'}>
						<div className={'top'}>
							<A.Id /><A.Brand /><A.Badges />
						</div>
						<div className={'middle'}>
							<A.BuylistIndicator /><A.Name onClick={openModal} />
						</div>
						<A.Allergens />
						<A.OrderHistory />
					</div>
					<div className={'order'}>
						<A.BuylistAction />
						<div>
							<A.Pending size={'big'} />
							<A.Reserved size={'big'} />
						</div>
					</div>
					<div className={'bottom'}>
						<div>
							<A.Campaigns />
							<A.Promo />
						</div>
					</div>
				</div>
			)}
		</Article>
	);
};

export default BuylistArticleGrid;
