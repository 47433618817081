import React, { useCallback, useEffect, useState } from 'react';
import { OrderArticle } from '@abm-international/react-components';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectOrderQuantityByProductId,
	selectAwardedPromoByProductId,
	selectDeliveryDate,
	selectIgnoreMadQuantityById,
	selectOrderQuantityTimestampByProductId,
	selectActivePromoByProductId,
	selectEobDetailsById,
	selectProductPointsById
} from '../../selectors';
import { updateProductQuantity } from '../../order/orderActions';
import QuantityInput from '../CommonQuantityInput';
import { de } from 'date-fns/locale';
import { tradeFairDates } from '../../../constants';
import { useProductPoints } from '../../../libraries/hooks/useProductPoints';



function OrderArticleContainer(props) {
	const { product: { id } } = props;
	const dispatch = useDispatch();
	const awardedPromo = useSelector(state => selectAwardedPromoByProductId(state, id));
	const eobDetail = useSelector(state => selectEobDetailsById(state, id));
	const quantity = useSelector(state => selectOrderQuantityByProductId(state, id));
	const quantityTimestamp = useSelector(state => selectOrderQuantityTimestampByProductId(state, id));
	const deliveryDate = useSelector(selectDeliveryDate, (a, b) => a.getTime() === b.getTime());
	const ignoreMadQuantity = useSelector(state => selectIgnoreMadQuantityById(state, id));
	const [showMadQuantityWarning, setShowMadQuantityWarning] = useState(false);
	const promo = useSelector(state => selectActivePromoByProductId(state, id, props.deliveryDate));
	const tempNotAvailable= props?.product?.tempNotAvailable;
	const updateQuantity = useCallback(quantity => {
		dispatch(updateProductQuantity(props.product, quantity, promo));
	}, [dispatch, props.product, promo]);

	const points = useSelector(state => selectProductPointsById(state, id));
	const productPoints = useProductPoints(
		points,
		deliveryDate,
		tradeFairDates,
	);

	useEffect(() => {
		const getShowMadQuantity = (amount, tolerated, ignore) => {
			if (!amount) return;
			if (tolerated === null) return;
			if (ignore) return;
			if (quantity < tolerated) return;
			if (Boolean(ignore)) return;
			return true;
		};

		const mq = Boolean(getShowMadQuantity(quantity, +props.product.madQuantity, ignoreMadQuantity));
		if (mq !== showMadQuantityWarning) setShowMadQuantityWarning(mq);
	}, [props.product.madQuantity, ignoreMadQuantity, quantity, showMadQuantityWarning]);

	return (
		<OrderArticle
			{...props}
			updateProductQuantity={updateQuantity}
			deliveryDate={deliveryDate}
			awardedPromo={awardedPromo}
			showMadQuantityWarning={showMadQuantityWarning}
			quantity={quantity}
			quantityTimestamp={quantityTimestamp}
			QuantityInput={QuantityInput}
			hideQuantityInput={tempNotAvailable ? tempNotAvailable: false}
			tempNotAvailable={tempNotAvailable}
			backAvailableFrom={props.product?.backAvailableFrom}
			product={{
				...props.product,
				// TODO: props.product.allergens can be "false", should be array or undefined -> where is allergens set??
				allergens: props.product.allergens || [],
				madQuantity: props.product.madQuantity ? Number(props.product.madQuantity) : 1000,
				promo,
				name: props.product.name,
			}}
			eobDetail={eobDetail}
			points={{
				normal: productPoints.standardPoints,
				// tradeFair: productPoints.tradeFairPoints
			}}
		/>
	);
}

export default React.memo(OrderArticleContainer, (a, b) => {
	if (a.product.id !== b.product.id) return false;
	if (a.view !== b.view) return false;
	if (a.sizes.length !== b.sizes.length) return false;
	if (a.history === undefined && b.history !== undefined) return false;
	return true;
});
