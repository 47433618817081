import { createSelector } from 'reselect';

export const selectIsFetching = state => state?.isFetching;
const selectReports = state => state?.items;
export const selectSortedReports = createSelector(
	selectReports,
	items => items.sort((a, b) => {
		return b.sortDate - a.sortDate;
	})
);
export const selectQuery = state => state?.query;
export const selectFrequencyFilter = state => state?.frequencyFilter;
