import React, { useEffect } from 'react';
import './modal.scss';

export default function Modal(props) {
	const { show, close, children } = props;

	useEffect(() => {
		if (show) document.body.style.overflow = 'hidden';
		if (!show) document.body.style.overflow = 'unset';

		return () => document.body.style.overflow = 'unset';
	}, [show]);

	if (!show) return null;
	return (
		<div className={'Modal'}>
			<div className={'modal__backdrop'} onClick={close}></div>
			<div className={'modal__body'}>
				{close && (
					<div className={'modal__close'} onClick={close}>
						&#10005;
					</div>
				)}
				<div className={'modal__content'}>
					{children}
				</div>
			</div>
		</div>
	);
}
