import { Coin } from '@abm-international/react-components';
import './TradeFairPoints.scss';
import Disclaimer from './Disclaimer';


interface TradeFairPointsProps {
    points: number;
}

export const TradeFairPoints = (props: TradeFairPointsProps) => {
    const { points } = props;

    if (!points) return null;

    return (
        <div className="trade-fair-points">
            <div className="disclaimer">
                <span className='meta'>Punten op deze bestelling:</span>
                <Disclaimer />
            </div>
            <Coin points={[points]} />
        </div>
    );
};
