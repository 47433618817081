/* eslint-disable camelcase */
import React, { useReducer, useState } from 'react';
import { Button } from '@abm-international/react-components';
import { t } from '../../../libraries/i18n';
import { Checkbox } from '@abm-international/react-components';
import api from '../../../libraries/api';
import useCustomer from '../../../libraries/hooks/useCustomer';
import { CommunicationData } from './Communication';
import { toast } from 'react-toastify';


type Props = {
	data: {
		confimation_order_recieved: boolean,
		confimation_order_prepared: boolean,
		confimation_order_prepared_with_order_form: boolean,
		confimation_order_is_done: boolean,
		confimation_order_is_done_with_order_form: boolean,
		confimation_truck_left: boolean,
		confimation_truck_left_with_order_form: boolean,
		confimation_order_recieved_after_delivery: boolean,
		confimation_order_recieved_after_delivery_with_order_form: boolean,
	},
	errors: any,
	updatePage: (key: keyof CommunicationData, newData: CommunicationData['follow_up_order'] | CommunicationData['from_charles']) => void
}

const reducer = (state: any, id: string) => {
	return {
		...state,
		[id]: !state?.[id] ?? false
	};
};

const CONFIRMATIONS = [
	{
		id: 'confimation_order_recieved',
		orderForm: '',
		disabled: true,
	},
	{
		id: 'confimation_order_prepared',
		orderForm: 'confimation_order_prepared_with_order_form',
		disabled: false,
	},
	{
		id: 'confimation_order_is_done',
		orderForm: 'confimation_order_is_done_with_order_form',
		disabled: false,
	},
	{
		id: 'confimation_truck_left',
		orderForm: 'confimation_truck_left_with_order_form',
		disabled: false,
	},
	{
		id: 'confimation_order_recieved_after_delivery',
		orderForm: 'confimation_order_recieved_after_delivery_with_order_form',
		disabled: false,
	},
];

export default function CommunicationFollowUpOrders(props: Props) {
	const [hasChanges, setHasChanges] = useState(false);
	const [saveSucces, setSaveSucces] = useState<boolean>(false);
	const [confirmations, setConfirmations] = useReducer(reducer, props.data);
	const [errors, setErrors] = useState(props.errors);
	const { id } = useCustomer();

	const save = async () => {
		try {
			const followUpOrder = {
				confimation_order_recieved: confirmations.confimation_order_recieved,
				confimation_order_prepared: confirmations.confimation_order_prepared,
				confimation_order_prepared_with_order_form: confirmations.confimation_order_prepared_with_order_form,
				confimation_order_is_done: confirmations.confimation_order_is_done,
				confimation_order_is_done_with_order_form: confirmations.confimation_order_is_done_with_order_form,
				confimation_truck_left: confirmations.confimation_truck_left,
				confimation_truck_left_with_order_form: confirmations.confimation_truck_left_with_order_form,
				confimation_order_recieved_after_delivery: confirmations.confimation_order_recieved_after_delivery,
				confimation_order_recieved_after_delivery_with_order_form: confirmations.confimation_order_recieved_after_delivery_with_order_form,
			};
			const savePost = await api.post(`/api/v1/customers/${id}/settings/communication`, JSON.stringify(
				{
					follow_up_order: followUpOrder
				}
			));
			setErrors({
				...errors,
				...savePost.errors
			});

			if (JSON.stringify(savePost.errors) === '{}') {
				setSaveSucces(true);
				props.updatePage('follow_up_order', followUpOrder);
				toast.success(t('settings.toast_success_data_saved'));
			} else {
				toast.error(t('settings.toast_error_data_saved'));
				setSaveSucces(false);
			}
		} catch (error) {
			setErrors([error]);
			console.log(error);
			return;
		}
		setHasChanges(false);
	};

	const toggle = (id: any) => {
		setConfirmations(id);
		setHasChanges(true);
		setSaveSucces(false);
	};

	return (
		<div>
			<table>
				<tbody>
					{CONFIRMATIONS.map(c => (
						<tr key={c.id}>
							<td>
								<div>{t(`settings.table_${c.id}`)}</div>
								{c.orderForm !== '' && <div>{t(`settings.table_${c.orderForm}`)}</div>}
							</td>
							<td>
								<div><Checkbox id={c.id} checked={confirmations?.[c.id]} disabled={c.disabled} onToggle={() => toggle(c.id)} ></Checkbox></div>
								{c.orderForm !== '' && <Checkbox id={c.orderForm} checked={confirmations?.[c.orderForm]} onToggle={() => toggle(c.orderForm)} ></Checkbox>}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{/* <RenderObjectErrors errors={errors}></RenderObjectErrors> */}
			{Object.values(errors)?.map((value: any, index) => {
				return (
					<p key={index}>{value}</p>
				);
			})}

			<div className='SaveAndChanges'>
				<Button primary onClick={save}>{t('settings.save')}</Button>
				{hasChanges && <p>{t('settings.unsaved_settings')}</p>}
				{saveSucces && <div className='green'>
					<p >{t('settings.saved_succes')}</p>
				</div>}
				{Object.values(errors)?.map((value: any, index) => {
					return (
						<p key={index}>{value}</p>
					);
				})}
			</div>
		</div>
	);
}
