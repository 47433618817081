/* eslint-disable camelcase */
// import { logger } from './logger/logger';
import * as Api from './api';
import UserPreferences from './../libraries/userPreferences';


const translations = {
	nl: {
		alert: {
			expired_session: 'Uw sessie is verlopen. Gelieve opnieuw in te loggen.',
			forbidden_route: 'U hebt geen toegang tot deze route.'
		},
	},
	en: {
		alert: {
			expired_session: 'You\'re sessions has expired. Please log in again.',
			forbidden_route: 'You do not have persmission to access this route.'
		},
	},
	fr: {
		alert: {
			expired_session: 'Votre session a expiré. Veuillez vous reconnecter',
			forbidden_route: 'Vous n\'avez pas la permission d\'accéder à cet itinéraire.'
		},
	},
};

export const defaultLocale = 'nl';
const varRegex = /\{(.+?)\}/g;

export function init(locale) {
	return new Promise((resolve, reject) => {
		fetchTranslations(locale)
			.then(data => {
				translations[locale] = data;

				// logger.debug('TRANSLATIONS: Translations have been set', {
				// 	locale: locale,
				// 	translations: translations
				// });

				resolve();
			})
			.catch(reject);
	});
}

function fetchTranslations(locale) {
	return new Promise((resolve, reject) => {
		Api.get(`/api/translations/applications/portal.client/${locale}`)
			.then(data => {
				// logger.debug('TRANSLATIONS: Received translations', { translations: data, locale: locale });
				resolve(data);
			})
			.catch(reject);
	});
}

export function t(stringId, ...vars) {
	const localeString = findTranslation(stringId, getLocale());
	let string = localeString ?? stringId;
	string = replaceVarsIfAny(string, vars);

	return string;
}

function findTranslation(stringId, locale) {
	// logger.silly('TRANSLATIONS: findTranslation START', { locale: locale, stringId: stringId });

	// Split the id
	const split = stringId.split('.');
	const module = split[0];
	const id = split[1];
	const moduleStrings = translations?.[locale]?.[module];

	// Module not available/found
	if (!moduleStrings) {
		// logger.debug('TRANSLATIONS: Could not find a module', {
		// 	locale: locale,
		// 	stringId: stringId,
		// 	module: module,
		// 	id: id,
		// 	moduleStrings: moduleStrings
		// });
		return null;
	}

	// Get the ids of the strings
	const ids = Object.keys(moduleStrings).filter(s => {
		return s.startsWith(id);
	});

	// Get the strings of the ids
	const strings = {};
	ids.forEach(id => {
		const count = id.replace(`${split[1]}_`, '');
		strings[count] = moduleStrings[id];
	});

	// Only return the whole array if there are plurals
	let string = null;
	if (Object.keys(strings).includes('other')) {
		string = strings;
	} else {
		string = strings[id];
	}

	// Return the string
	// logger.silly('TRANSLATIONS: findTranslation END', {
	// 	strings: strings,
	// 	string: string,
	// 	stringId: stringId
	// });
	return string;
}

function replaceVarsIfAny(string, vars) {
	// logger.silly('TRANSLATIONS: replaceVarsIfAny', {
	// 	string: string,
	// 	vars: vars
	// });

	if (!vars.length) return string;
	if (typeof string === 'object') {
		string = getPluralString(string, vars[0]);
	}

	const matches = string.match(varRegex);
	if (matches === null) return string;

	if (matches.length > vars.length) {
		throw new Error(`There are ${matches.length} placeholders in the translation string "${string}" and only ${vars.length} parameters are given. There should be exactly one parameter for each placeholder.`);
	}

	matches.forEach(match => {
		let index = match.substr(1, match.length - 1);
		index = parseInt(index);
		index -= 1;

		string = string.replace(match, vars[index]);
	});

	return string;
}

function getPluralString(string, count) {
	let pluralString = string.other;

	if ((string['0'] || string['0'] === '') && count === 0) {
		pluralString = string['0'];
	} else if ((string['1'] || string['1'] === '') && count === 1) {
		pluralString = string['1'];
	}

	return pluralString;
}

export function setLocale(loc) {
	return new Promise((resolve, reject) => {

		UserPreferences.set(UserPreferences.PREFERENCES.LOCALE, loc);

		if (!translations[loc]) {
			fetchTranslations(loc).then(translation => {
				translations[loc] = translation;
				resolve();
			}).catch(() => {
				reject();
			});
		}
		resolve();
	});
}

export function getLocale() {
	const searchParams = new URLSearchParams(window.location.search);
	const urlLocale = searchParams.get('locale');
	if (urlLocale) {
		UserPreferences.set(UserPreferences.PREFERENCES.LOCALE, urlLocale);
		searchParams.delete('locale');
		window.location.search = searchParams;
	}
	return urlLocale || UserPreferences.get(UserPreferences.PREFERENCES.LOCALE) || defaultLocale;
}

export function getAvailableLocales() {
	return new Promise((resolve, reject) => {
		Api.get('/api/translations/applications/portal.client')
			.then(data => {
				// logger.debug('TRANSLATIONS: getAvailableLocales: Received application translation data', { data: data });
				if (data.languages) {
					return resolve(data.languages);
				} else {
					// logger.error('TRANSLATIONS: Error while fetching Could not find available languages in application translation data', {
					// 	endpoint: `/api/translations/applications/portal.client`,
					// 	data: data
					// });
					return reject('languages not available in application translation data');
				}
			})
			.catch(reject);
	});
}
