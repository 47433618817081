import React, { useState } from 'react';
import { getLocale, t } from '../../../libraries/i18n';
import { Spinner } from '@abm-international/react-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { fetchCustomerIfNeeded } from '../../customer/customerActions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import config from './../../../config';
import { useIsLoginAPIAvailable } from './../../../api';
import { checkAuth, login } from './../../../api/auth';
import { useAppSelector } from './../../../libraries/hooks/redux';
import { selectEndpointById } from './../../../modules/selectors';
import { post } from '../../../libraries/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const Login = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState([]);
	const [ authException ] = useState(localStorage.getItem('auth-exception'));
	const [isAlreadyAuthenticated, setIsAlreadyAuthenticated] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const isAPIAvailable = useIsLoginAPIAvailable();
	const loginEndpoint = useAppSelector(state => selectEndpointById(state, 'login'));

	// Show (and remove) auth-exception warnings
	useEffect(() => {
		if (!localStorage.getItem('auth-exception')) return;
		setTimeout(() => {
			localStorage.removeItem('auth-exception');
		}, 100);
	}, [authException]);

	// Check if API is available
	useEffect(() => {
		if (isAPIAvailable === false) window.location = config.LOGIN_URL;
	}, [isAPIAvailable]);

	// Check if user is already authenticated
	useEffect(() => {
		setLoading(true);

		const checkIfAlreadyAuthenticated = async () => {
			try {
				const res = await checkAuth();
				const newIsAlreadyAuthenticated = (res?.success && res.isAuthenticated) ?? false;

				setIsAlreadyAuthenticated(newIsAlreadyAuthenticated);
				setAuthenticatedUser(res.username);
			} catch (error) {
				console.error('Auth check failed', error);
			}

			setLoading(false);
		};

		checkIfAlreadyAuthenticated();
	}, []);

	const authenticate = async e => {
		e.preventDefault();
		setLoading(true);
		setErrors([]);
		try {
			const res = await login(username, password, loginEndpoint);
			const locale = getLocale();
			await post(`/api/language/${locale}`); // old php this cann be removed when fullly migrated to NODEjs
			await post(`/api/v1/language/${locale}`); // new NODEjs

			if (res.maintenanceMode){
				setLoading(false);
				setErrors([{ message: t('auth.maintenance_mode_message') }]);
				return;
			}
			if (res.isAlreadyAuthenticated) {
				setIsAlreadyAuthenticated(true);
				setAuthenticatedUser(res.username);
				setLoading(false);
				return;
			}
			if (res.redirectTo) window.location = `${res.redirectTo}?locale=${getLocale()}`;
			dispatch(fetchCustomerIfNeeded());
			const urlParams = new URLSearchParams(location.search);
			history.push(urlParams.get('from') ?? '/customers?from=/order');
		} catch (e) {
			const isBadRequest = e.response.status === 400 || e.message.includes('400');
			const errorMessage = isBadRequest ? 'auth.error_message_fail_post_login_php' : 'auth.error_message';

			setErrors([{ message: t(errorMessage) }]);
		}
		setLoading(false);
	};

	const renderErrors = () => (
		<ul className={'form__errors'}>
			{errors.map((error, key) => (
				<li key={key}>
					{t(error.message)}
				</li>
			))}
		</ul>
	);

	return (
		<form className={'form'} onSubmit={authenticate}>
			{authException && <span className='auth-exception'>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{authException}
			</span>}

			{isAlreadyAuthenticated ? (
				<p>
					{authenticatedUser ? t('auth.already_logged_in_as', authenticatedUser) : t('auth.already_logged_in')}
					<br />
					<Link to="/auth/logout">{t('auth.click_here')}</Link> {t('auth.to_log_out')}
				</p>
			) : (
				<>
					<label>
						{t('auth.username_placeholder')}

						<input
							name="username"
							type="text"
							value={username}
							onChange={e => setUsername(e.target.value)}
						/>
					</label>

					<label>
						{t('auth.password_placeholder')}

						<input
							name="password"
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</label>

					{errors && renderErrors()}

					<button disabled={!username || !password || loading}>
						{!loading && t('auth.login_button')}
						{loading && (<Spinner size='small' />)}
					</button>
				</>
			)}
		</form>
	);
};

export default Login;
