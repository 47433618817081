import {
	INVOICES_REQUEST,
	INVOICES_RECEIVE,
	INVOICES_REJECT,
	INVOICES_HANDLE_SEARCH,
	INVOICES_CHANGE_VIEW
} from './invoicesActions';
import UserPreferences from './../../../libraries/userPreferences';

export default function invoices(state = {}, action) {
	switch (action.type) {
		case INVOICES_REQUEST:
			return {
				...state,
				isFetching: true,
				didFail: false
			};
		case INVOICES_RECEIVE:
			return {
				...state,
				isFetching: false,
				lastUpdated: Date.now(),
				items: action.items
			};
		case INVOICES_REJECT:
			return {
				...state,
				isFetching: false,
				didFail: true
			};
		case INVOICES_HANDLE_SEARCH:
			return {
				...state,
				query: action.query
			};
		case INVOICES_CHANGE_VIEW:
			UserPreferences.set(UserPreferences.PREFERENCES.INVOICES_VIEW, action.view);
			return {
				...state,
				view: action.view
			};
		default:
			return { ...state };
	}
}
