import { rest } from 'msw';

export const getApiV1 = [
	rest.get('http://localhost:4000/api/v1', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'success': true,
					'resources': [
						{
							'id': '/',
							'location': 'http://localhost:4000/api/v1',
							'methods': [
								'GET'
							]
						},
						{
							'id': 'login',
							'location': 'http://localhost:4000/api/v1/auth/login',
							'methods': [
								'POST'
							]
						},
						{
							'id': 'logout',
							'location': 'http://localhost:4000/api/v1/auth/logout',
							'methods': [
								'POST'
							]
						},
						{
							'id': 'me',
							'location': 'http://localhost:4000/api/v1/users/me',
							'methods': [
								'GET',
								'POST'
							]
						}
					]
				}
			),
		);
	}),
];
