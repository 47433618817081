import { AppDispatch, RootState } from './../types/redux';

export type FetchResourceConfig = {
	fetcher: () => any,
	shouldFetch: (state: RootState) => boolean,
	actionTypes: {
		start: string,
		failed: string,
		success: string
	}
};

const fetchResource = ({ fetcher, shouldFetch, actionTypes }: FetchResourceConfig) => {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();
		if (!shouldFetch(state)) return;

		dispatch({ type: actionTypes.start });

		let data;
		try {
			data = await fetcher();
		} catch (error) {
			dispatch({ type: actionTypes.failed, payload: data });
			return;
		}

		dispatch({ type: actionTypes.success, payload: data });
	};
};

export default fetchResource;
