import { t } from '../../libraries/i18n';
import { Currency, SlowInput } from '@abm-international/react-components';
import { setDetailMargin, setDetailMarginByPrice } from './MarginsActions';
import React from 'react';
import SafeImg from '../../components/SafeImg/SafeImg';

// TODO: safeImage
export const getImgRenderer = () => ({ article, id }) => (
	<SafeImg
		sources={[`${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/${80}/${article.img.PS[0]}`]}
		alt={`article ${id}`}
	/>
);

export const getArticleRenderer = () => ({ article, id }) => (
	<span><span>{id}</span>{' '}<span>{article.desc}</span></span>
);

export const getPurchasePriceRenderer = (BY_UNIT) => ({ purchase }) => (`€ ${ purchase.price } ${BY_UNIT[purchase.unit]}`);

export const getVatRenderer = () => ({ sales }) => (`${ sales?.vat }%`);

export const getQuantitiesRenderer = () => ({ purchase, sales, consumerPackageGoods }) => {
	if (purchase.unit === 'K' && sales.unit === 'K') return '-';
	if (purchase.unit === 'K' && sales.unit === 'S') return `${consumerPackageGoods.quantity} ${t('margins.pieces')} ${t('margins.by_weight')}`;
	if (purchase.unit === 'S' && sales.unit === 'K') return `${purchase.weight.net} kg ${t('margins.by_piece')}`;
	if (purchase.unit === 'S' && sales.unit === 'S') return `${consumerPackageGoods.quantity} ${t('margins.pieces')}`;
};

// TODO: use category margin instead of '-'
export const getUsedMarginRenderer = (getProductMargin, unsavedMargins) => ({ value, id }) => {
	const userMargin = getProductMargin(id, value, unsavedMargins);
	if (userMargin === '') return '-';
	return `${userMargin} %`;
};

export const getUserMarginRenderer = (getProductMargin, dispatch, categoryId, productType, marginType, setErrorsById, unsavedMargins) => ({ value, id }) => {
	const lastValue = getProductMargin(id, value, unsavedMargins);
	return (
		<>
			<SlowInput
				className={((value && lastValue !== value) || (!value && lastValue !== '')) ? 'edited' : ''}
				type={'number'}
				value={lastValue}
				onChange={e => dispatch(setDetailMargin(e.target.value, id, categoryId, productType, marginType))}
				tests={{
					cases: [
						{
							case: (value) => value > 100,
							error: t('margins.error_margin_not_below_100')
						}
					],
					setErrors: (errors) => setErrorsById(id, errors)
				}}
			/>
			%
		</>
	);
};

export const getEditableSalesPriceRenderer = (getConsumerPrice, dispatch, categoryId, productType, marginType, BY_UNIT, group, unsavedMargins) => ({
	value,
	id,
	purchase,
	sales,
	consumerPackageGoods
}) => (
	<>
		€
		<SlowInput
			type={'number'}
			value={getConsumerPrice({
				id,
				value,
				purchase,
				sales,
				amount: consumerPackageGoods?.quantity,
				group,
				unsavedMargins
			})}
			onChange={e => dispatch(setDetailMarginByPrice(Number(e.target.value.replace(',', '.')), id, categoryId, productType, marginType))}
		/>
		{BY_UNIT[sales?.unit]}
	</>
);

export const getSalesPriceRenderer = (getConsumerPrice, BY_UNIT, group, unsavedMargins) => ({
	value,
	id,
	purchase,
	sales,
	consumerPackageGoods
}) => (
	<>
		<Currency
			value={getConsumerPrice({
				id,
				value,
				purchase,
				sales,
				amount: consumerPackageGoods?.quantity,
				group,
				unsavedMargins
			})}
		/> {BY_UNIT[sales?.unit]}
	</>
);
