import React, {
	useRef,
	useEffect,
	useState,
	useCallback
} from 'react';
import './productsList.scss';
import { getHeight as getArticleHeight } from '../../../modules/common/OrderArticle';
import useWidth from '../../../libraries/hooks/useWidth';
import OrderArticleRow from '../../../modules/common/OrderArticleContainer/OrderArticleRow';
import BuyListArticleRow from '../../../modules/common/BuylistArticleContainer/BuylistArticleRow';
import {
	WindowScroller,
	AutoSizer,
	List
} from 'react-virtualized';
import 'react-virtualized/styles.css';

const GRID_ITEM_SIZE = 300;
const containerQuery = {
	size935: {
		maxWidth: 935,
	},
	size770: {
		maxWidth: 770,
	},
	size720: {
		maxWidth: 720,
	},
	size630: {
		maxWidth: 640,
	},
};

const getSizes = width => Object.entries(containerQuery).filter(([_, { maxWidth }]) => maxWidth >= width).map(([key]) => key);

export default function ProductsList(props) {
	const {
		isFetching,
		products,
		hideQuantityInput,
		isInBuylist,
		query,
		showModal,
		removeFromBuylist,
		addToBuylist,
		view,
		deliveryDate
	} = props;

	const [ref, width] = useWidth();
	const listRef = useRef(null);
	const windowScrollerRef = useRef(null);

	const itemsPerRow = view === 'grid' ? Math.floor(width / GRID_ITEM_SIZE) || 1 : 1;
	const rowCount = Math.ceil(products.length / itemsPerRow);

	const [sizes, setSizes] = useState(getSizes(width));
	const rowHeight = useCallback(({ index }) => {
		const fromIndex = index * itemsPerRow;
		const toIndex = Math.min(fromIndex + itemsPerRow, products.length);
		const articles = products
			.slice(fromIndex, toIndex)
			.map(a => ({
				...a,
				deliveryDate
			}));
		return Math.max(...articles.map(article => getArticleHeight(view, sizes[sizes.length - 1], article) + (view !== 'list-compact' ? 5 : 2)));
	}, [view, sizes, products, itemsPerRow, deliveryDate]);

	useEffect(() => {
		const widths = getSizes(width);
		if (widths.length !== sizes.length) setSizes(widths);
	}, [width, sizes.length]);

	useEffect(() => {
		setInterval(() => {
			if (windowScrollerRef.current) windowScrollerRef.current.updatePosition();
		}, 1000);
	}, []);

	useEffect(() => {
		if (listRef.current) {
			listRef.current.recomputeRowHeights();
			listRef.current.forceUpdateGrid();
		}
	});

	const rowRenderer = useCallback(
		(params) => {
			if (isInBuylist) {
				return (
					<BuyListArticleRow
						{...params}
						{...{
							view,
							showModal,
							itemsPerRow,
							query,
							sizes,
							products,
							removeFromBuylist,
							addToBuylist
						}}
					/>
				);
			}
			return (
				<OrderArticleRow
					{...params}
					{...{
						view,
						showModal,
						hideQuantityInput,
						itemsPerRow,
						query,
						sizes,
						products
					}}
				/>
			);
		},
		[
			view,
			showModal,
			hideQuantityInput,
			isInBuylist,
			itemsPerRow,
			query,
			products,
			sizes,
			removeFromBuylist,
			addToBuylist
		]
	);

	const renderList = () => {
		return (
			<WindowScroller
				ref={windowScrollerRef}
			>
				{({ height, scrollTop }) => (
					<AutoSizer disableHeight>
						{({ width }) => (
							<List
								autoHeight
								height={height}
								width={width}
								scrollTop={scrollTop}
								rowHeight={rowHeight}
								rowRenderer={rowRenderer}
								rowCount={rowCount}
								overscanRowCount={5}
								ref={listRef}
								isScrollingOptOut
							/>
						)}
					</AutoSizer>
				)}
			</WindowScroller>
		);
	};

	const renderLoadingList = items => (
		[...Array(items)].map((i, number) => (
			<div key={number} className={`list-item__loading loading-view__${view}`}>
				<div className={'breathing loading__image'} />
				<div className={'container__id-desc'}>
					<div className={'breathing loading__id'} />
					<div className={'breathing loading__desc'} />
				</div>
				<div className={'breathing loading__history'} />
				<div className={'breathing loading__quantity-input'} />
			</div>
		))
	);

	return (
		<div className={`ProductsList view--${view}`} ref={ref}>
			{isFetching ? (
				renderLoadingList(12)
			) : (
				renderList()
			)}
		</div>
	);
}
