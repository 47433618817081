/* eslint-disable camelcase */
import React, { useReducer, useState } from 'react';
import { Button } from '@abm-international/react-components';
import { t } from '../../../libraries/i18n';
import { Checkbox } from '@abm-international/react-components';
import api from '../../../libraries/api';
import useCustomer from '../../../libraries/hooks/useCustomer';
import { CommunicationData } from './Communication';
import { toast } from 'react-toastify';



type Props = {
	data: {
	},
	updatePage: (key: keyof CommunicationData, newData: CommunicationData['follow_up_order'] | CommunicationData['from_charles']) => void
	errors: any;
}

const reducer = (state: any, id: string) => {
	return {
		...state,
		[id]: !state?.[id] ?? false
	};
};

const CONFIRMATIONS = [
	{
		id: 'newsletter_recieve',
		disabled: false,
	},
	{
		id: 'product_specification_is_updated',
		disabled: false,
	},
	{
		id: 'new_statistics_online',
		disabled: false,
	}
];

export default function CommunicationFromCharles(props: Props) {
	const [hasChanges, setHasChanges] = useState(false);
	const [saveSucces, setSaveSucces] = useState<boolean>(false);
	const [confirmations, setConfirmations] = useReducer(reducer, props.data);
	const [errors, setErrors] = useState(props.errors);
	const { id } = useCustomer();

	const save = async () => {
		try {
			const fromCharles = {
				newsletter_recieve: confirmations.newsletter_recieve,
				product_specification_is_updated: confirmations.product_specification_is_updated,
				new_statistics_online: confirmations.new_statistics_online,
			};
			const savePost = await api.post(`/api/v1/customers/${id}/settings/communication`, JSON.stringify(
				{
					from_charles: fromCharles
				}
			));
			setErrors({
				...errors,
				...savePost.errors
			});

			if (JSON.stringify(savePost.errors) === '{}') {
				setSaveSucces(true);
				props.updatePage('from_charles', fromCharles );
				toast.success(t('settings.toast_success_data_saved'));
			} else {
				setSaveSucces(false);
				toast.error(t('settings.toast_error_data_saved'));
			}
		} catch (error) {
			console.log(error);
			return;
		}
		setHasChanges(false);
	};

	const toggle = (id: any) => {
		setConfirmations(id);
		setHasChanges(true);
		setSaveSucces(false);
	};

	return (
		<div>
			<table>
				<tbody>
					{CONFIRMATIONS.map(c => (
						<tr key={c.id}>
							<td>
								<div>{t(`settings.table_${c.id}`)}</div>
							</td>
							<td>
								<div><Checkbox id={c.id} checked={confirmations?.[c.id]} disabled={c.disabled} onToggle={() => toggle(c.id)} ></Checkbox></div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className='SaveAndChanges'>
				<Button primary onClick={save}>{t('settings.save')}</Button>
				{hasChanges && <p>{t('settings.unsaved_settings')}</p>}
				{saveSucces && <div className='green'>
					<p >{t('settings.saved_succes')}</p>
				</div>}
				{Object.values(errors)?.map((value: any, index) => {
					return (
						<p key={index}>{value}</p>
					);
				})}
			</div>
		</div>
	);
}
