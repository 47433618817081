import {
	PRICE_CHANGES_REQUEST,
	PRICE_CHANGES_RECEIVE,
	PRICE_CHANGES_REJECT,
	PRICE_CHANGES_HANDLE_SEARCH
} from './PriceChangesActions';

export default function priceChanges(
	state = {
		isFetching: false,
		items: null
	},
	action
) {
	switch (action.type) {
		case PRICE_CHANGES_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case PRICE_CHANGES_RECEIVE:
			return {
				...state,
				isFetching: false,
				lastUpdated: Date.now(),
				items: action.data
			};
		case PRICE_CHANGES_REJECT:
			return {
				...state,
				isFetching: false,
				didFail: true
			};
		case PRICE_CHANGES_HANDLE_SEARCH:
			return {
				...state,
				query: action.query
			};
		default:
			return { ...state };
	}
}
