import { useSelector } from 'react-redux';
import { selectCustomerId } from './../../modules/selectors';
import useSWR from 'swr';
import { fetcher } from './../index';
import { OrderedProduct, OrderedProductEntry } from '../../types/orders/orderedProducts';
import { transformProduct } from './../../libraries/api';

const transformEntries = (entries: Array<OrderedProductEntry>) => entries?.map((entry: OrderedProductEntry) => ({
	...entry,
	date: new Date(entry.date)
}));

const transformData = (data: Array<OrderedProduct>): Array<OrderedProduct> | undefined => {
	if (!data) return undefined;

	return data.map(item => ({
		...item,
		reserved: transformEntries(item.reserved),
		outForDelivery: transformEntries(item.outForDelivery),
		article: transformProduct(item.id, item.article)
	}));
};

export default function useOrderedProducts() {
	const customerId = useSelector(selectCustomerId);
	const { data, error } = useSWR(`/api/customer.order.history/${customerId}/products`, fetcher);

	return {
		orderedProducts: transformData(data),
		error
	};
}
