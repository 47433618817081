import { useEffect } from 'react';
import './app.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import {
	//selectCustomerId,
	selectIsNavCollapsed,
	selectIsTranslationsFetching,
	selectLocale,
	selectHasCorrectTranslations,
	selectPreOrderTypes,
	selectCustomerAccount,
	//selectCustomerLastUpdated
} from './selectors';
import {
	appLoaded,
	fetchResourcesListIfNeeded,
	fetchTranslationsIfNeeded
} from './appActions';
// import {
// 	fetchProductsIfNeeded,
// 	fetchPromosIfNeeded,
// 	fetchMetadataIfNeeded,
// 	fetchHistoryIfNeeded,
// 	fetchCustomerProductsIfNeeded,
// 	fetchCampaignsIfNeeded
// } from './products/productsActions';
// import {
// 	createOrLoadOrder,
// 	fetchDeliveryDatesIfNeeded
// } from './order/orderActions';
import {
	Route,
	Switch,
	useHistory
} from 'react-router-dom';
import NavigationContainer from './Navigation/NavigationContainer';
import Order from './order/Order';
import Reports from './Reports/Reports';
import PriceChanges from './PriceChanges/PriceChanges';
import Documents from './Documents/Documents';
import Promos from './Promos/Promos';
import Disclaimer from './Disclaimer/Disclaimer';
import { PERMISSIONS } from '../libraries/permissions';
import SplashScreen from '../components/SplashScreen/SplashScreen';
import { fetchCustomerIfNeeded, setLocale } from './customer/customerActions';
import { usePageViews, useErrorBoundary } from './../libraries/hooks';
import Error from '../components/Error/Error';
import { logger } from '../libraries/logger/logger';
import CustomCampaigns from './CustomCampaigns/CustomCampaigns';
import Alerts from './../components/Alerts/Alerts';
import CharlesRoute from './../components/CharlesRoute/CharlesRoute';
import Buylist from './buylist/buylist';
import Orders from './Orders/Orders';
import Margins from './Margins/Margins';
import PreOrders from './PreOrders/PreOrders';
import Customers from './Customers/Customers';
import { fetchPreOrdersDataIfNeeded } from './PreOrders/preOrdersActions';
import Settings from './Settings/Settings';
import MaintenanceMessage from './AppErrors/MaintenanceMessage';
import Auth from './Auth/Auth';
import TradeFair from './TradeFair/TradeFair';
import { selectHasError, selectIsFetching } from './customer/customerSelectors';
import { defaultLocale, getLocale } from '../libraries/i18n';
import TradeFairRegistrations from './TradeFairRegistrations/TradeFairRegistrations';
import TradeFairPointsOverview from './TradeFair/TradeFairPointsOverview';

function App() {
	const dispatch = useDispatch();
	const history = useHistory();
	//const customerId = useSelector(selectCustomerId);
	const account = useSelector(selectCustomerAccount);
	//const hasAccountFetched = useSelector(selectCustomerLastUpdated);
	const isNavCollapsed = useSelector(selectIsNavCollapsed);
	const isFetchingUser = useSelector(selectIsFetching);
	const hasUserError = useSelector(selectHasError);
	const isTranslationsFetching = useSelector(selectIsTranslationsFetching);
	const hasCorrectTranslations = useSelector(selectHasCorrectTranslations);
	const locale = useSelector(selectLocale);
	const preOrderTypes = useSelector(selectPreOrderTypes, (a, b) => a.every((a, index) => a === b[index]));
	const [Try, Catch] = useErrorBoundary({ logger });
	usePageViews();

	useEffect(() => {
		dispatch(fetchResourcesListIfNeeded());
	}, [dispatch]);

	// App has loaded
	useEffect(() => {
		dispatch(appLoaded());
		dispatch(fetchCustomerIfNeeded());
	}, [dispatch]);

	// Fetch translations before and after account has loaded
	useEffect(() => {
		dispatch(fetchTranslationsIfNeeded(locale));
	}, [locale, account, dispatch]);

	useEffect(() => {
		const localStorageLocale = getLocale();

		if (!localStorageLocale){
			dispatch(setLocale(defaultLocale));
		}
	}, [dispatch]);

	// // Prefetch data after customer has loaded
	// useEffect(() => {
	// 	if (!customerId) return;

	// 	if (process.env.NODE_ENV !== 'production') {
	// 		const urlParams = new URLSearchParams(window.location.search);
	// 		const noPrefetch = urlParams.get('noPrefetch');
	// 		if (noPrefetch === '') return;
	// 	}

	// 	// TODO: Look into why the order of these async calls matters.
	// 	// -> Max amount of connections per tab to the same remote === 6
	// 	// Can we fix the blocking of these calls?
	// 	// -> Yes. Less calls. 6 is kinda crazy...
	// 	dispatch(createOrLoadOrder(customerId));
	// 	dispatch(fetchDeliveryDatesIfNeeded(customerId));
	// 	dispatch(fetchCampaignsIfNeeded());
	// 	dispatch(fetchMetadataIfNeeded(customerId));
	// 	dispatch(fetchPromosIfNeeded(customerId));
	// 	dispatch(fetchProductsIfNeeded(customerId));
	// 	dispatch(fetchCustomerProductsIfNeeded(customerId));
	// 	dispatch(fetchHistoryIfNeeded(customerId));
	// }, [customerId, dispatch]);

	useEffect(() => {
		//if (process.env.NODE_ENV !== 'production') {
		const urlParams = new URLSearchParams(window.location.search);
		const noPrefetch = urlParams.get('noPrefetch');
		if (noPrefetch === '') return;
		//}

		//preOrderTypes.forEach(type => dispatch(fetchPreOrdersDataIfNeeded(type)));
	}, [dispatch, preOrderTypes]);

	// Redirect to login if user is not authenticated
	useEffect(() => {
		if (!isFetchingUser && hasUserError) {
			history.push('/auth/login');
		}
	}, [isFetchingUser, hasUserError, history]);

	// useEffect(() => {
	// 	if (hasAccountFetched && !account && !window.location.href.includes('auth')) {
	// 		window.location.href = '/auth/logout';
	// 	}
	// }, [account, hasAccountFetched]);

	const isMaintenanceMode = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const maintenance = urlParams.get('maintenance');
		return maintenance || maintenance === '';
	};

	const shouldShowSplashScreen = () => isTranslationsFetching || !hasCorrectTranslations;

	if (isMaintenanceMode()) return <MaintenanceMessage />;
	// if (hasCustomerError && !customerId) return <CustomerError />;
	if (shouldShowSplashScreen()) return <SplashScreen />;



	return (
		<div className={`App ${isNavCollapsed ? 'nav--collapsed' : 'nav--full'}`}>
			<Switch>
				<Route path={'/auth'} component={Auth} />
				<Route path={'/'} >
					{!account ? <SplashScreen /> : (
						<>
							<NavigationContainer />
							<main>
								<Alerts />
								<Try>
									<Switch>
										<CharlesRoute path={'/trade-fairs/:name'}><TradeFairRegistrations /></CharlesRoute>
										<CharlesRoute path={'/spaaractie/spaarsaldo'}><TradeFairPointsOverview /></CharlesRoute>
										<CharlesRoute path={'/trade-fair-charles-koerse'}><TradeFair requiredPermission={PERMISSIONS.ORDER} /></CharlesRoute>
										<CharlesRoute path={'/preorders/:type'}><PreOrders requiredPermission={PERMISSIONS.ORDER} /></CharlesRoute>
										<CharlesRoute path={'/order'}><Order requiredPermission={PERMISSIONS.ORDER} /></CharlesRoute>
										<CharlesRoute path={'/orders'}><Orders /></CharlesRoute>
										<CharlesRoute path={'/margins'}><Margins requiredPermission={PERMISSIONS.MARGINS} /></CharlesRoute>
										<CharlesRoute path={'/reports'}><Reports requiredPermission={PERMISSIONS.REPORTS} /></CharlesRoute>
										<CharlesRoute path={'/pricechanges'}><PriceChanges requiredPermission={PERMISSIONS.PRICE_CHANGES} /></CharlesRoute>
										<CharlesRoute path={'/documents'}><Documents /></CharlesRoute>
										<CharlesRoute path={'/buylist'}><Buylist /></CharlesRoute>
										<CharlesRoute path={'/promos'}><Promos /></CharlesRoute>
										<CharlesRoute path={'/disclaimer'}><Disclaimer /></CharlesRoute>
										<CharlesRoute path={'/customers'}><Customers /></CharlesRoute>
										<CharlesRoute path={'/campaigns'}><CustomCampaigns /></CharlesRoute>
										<CharlesRoute path={'/settings'}><Settings /></CharlesRoute>
										<Route path={'/error/forbidden'} render={() => <Error code={403} />} />
										<Route path={'/*'} render={() => <Error code={404} />} />
									</Switch>
								</Try>
								<Catch>
									<Error />
								</Catch>
							</main>
						</>
					)}
				</Route>
			</Switch>

			<ToastContainer
				position={toast.POSITION.TOP_RIGHT}
				closeOnClick={false}
				draggable={false}
				className={'c-alert__container'}
				toastClassName={'c-alert'}
				bodyClassName={'c-alert__body'}
				progressClassName={'c-alert__progress'}
			/>
		</div>
	);
}

export default App;
