import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './../../types/redux';

// typed useDispatch and useSelector for this app
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useWithDispatch = (action: any) => {
	const dispatch = useAppDispatch();

	return (actionFunction: any) => {
		return (...params: any) => dispatch(actionFunction(...params));
	};
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
