import React from 'react';
import './Allergens.scss';
import Checkbox from '../../checkbox/checkbox';
import { t } from './../../../libraries/i18n';
import FilterGroup from '../FilterGroup';

import { ReactComponent as Allergen1 } from './../../../images/allergens/1.svg';
import { ReactComponent as Allergen2 } from './../../../images/allergens/2.svg';
import { ReactComponent as Allergen3 } from './../../../images/allergens/3.svg';
import { ReactComponent as Allergen4 } from './../../../images/allergens/4.svg';
import { ReactComponent as Allergen5 } from './../../../images/allergens/5.svg';
import { ReactComponent as Allergen6 } from './../../../images/allergens/6.svg';
// eslint-disable-next-line
import { ReactComponent as Allergen7_1 } from './../../../images/allergens/7_1.svg';
// eslint-disable-next-line
import { ReactComponent as Allergen7_2 } from './../../../images/allergens/7_2.svg';
import { ReactComponent as Allergen8 } from './../../../images/allergens/8.svg';
import { ReactComponent as Allergen9 } from './../../../images/allergens/9.svg';
import { ReactComponent as Allergen10 } from './../../../images/allergens/10.svg';
import { ReactComponent as Allergen11 } from './../../../images/allergens/11.svg';
import { ReactComponent as Allergen12 } from './../../../images/allergens/12.svg';
import { ReactComponent as Allergen13 } from './../../../images/allergens/13.svg';
import { ReactComponent as Allergen14 } from './../../../images/allergens/14.svg';

function Allergens(props) {
	const {
		filters,
		clearFilters,
		toggleFilter
	} = props;

	const numFilters = Object.keys(filters).reduce((sum, key) => {
		if (filters[key]) sum += 1;
		return sum;
	}, 0);

	const ALWAYS_SHOW_AMOUNT = 2;
	const ALLERGENS = [
		{ id: '1', Img: Allergen1 },
		// eslint-disable-next-line
		{ id: '7.1', Img: Allergen7_1 },
		// eslint-disable-next-line
		{ id: '7.2', Img: Allergen7_2 },
		{ id: '2', Img: Allergen2 },
		{ id: '3', Img: Allergen3 },
		{ id: '4', Img: Allergen4 },
		{ id: '5', Img: Allergen5 },
		{ id: '6', Img: Allergen6 },
		{ id: '9', Img: Allergen9 },
		{ id: '8', Img: Allergen8 },
		{ id: '10', Img: Allergen10 },
		{ id: '11', Img: Allergen11 },
		{ id: '12', Img: Allergen12 },
		{ id: '13', Img: Allergen13 },
		{ id: '14', Img: Allergen14 },
	];

	const renderAllergen = allergen => {
		const filterState = filters[allergen.id];
		return (
			<div
				key={allergen.id}
				className={`filter__item filter__allergen--${allergen.id.replace('.', '_')} filter--${!filterState}`}
				onClick={() => toggleFilter(`allergens.${allergen.id}`)}
			>
				<Checkbox checked={!!filterState} inverted />
				<allergen.Img alt={t(`order.filtersAllergens${allergen.id}`)} />
				<span className={'allergen__number'}>{allergen.id}.</span>{t(`order.filtersAllergens${allergen.id.replace('.', '_')}`)}
			</div>
		);
	};

	return (
		<FilterGroup
			className='AllergensFilter'
			numFilters={numFilters}
			clearFilters={clearFilters}
			id={'allergensFilter'}
		>
			<FilterGroup.Title>{t('order.filtersAllergensTitle')}</FilterGroup.Title>
			<FilterGroup.Body>
				<FilterGroup.Collapsed>
					{ALLERGENS.slice(0, ALWAYS_SHOW_AMOUNT).map(renderAllergen)}
				</FilterGroup.Collapsed>

				<FilterGroup.Uncollapsed>
					{ALLERGENS.map(renderAllergen)}
				</FilterGroup.Uncollapsed>
			</FilterGroup.Body>
		</FilterGroup>
	);
}

export default Allergens;
