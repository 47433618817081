import { rest } from 'msw';

export const getHistorhy = [
	rest.get('http://localhost:4000/api/products/', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'1': {
						'202227': 0,
						'202228': 100,
						'202229': 0,
						'202230': 0,
						'202231': 0,
						'202232': 0,
						'202233': 0,
						'202234': 0,
						'202235': 0,
						'202236': 0
					},
				}
			),
		);
	}),
];
