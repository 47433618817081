import './highlight.scss';

export default function Highlight(props) {
	const {
		children: text,
		query,
		className,
		...restProps
	} = props;



	const replaceIgnoredCharacters = (string) => {
		const ignoredChars = ['\'', '-'];

		ignoredChars.forEach(char => {
			string = string.replace(char, `${char}?`);
		});

		return string;
	};

	const makeRegExp = (strings) => {
		let words = strings.map(w => {
			w = w.replace(/[\\.+*?^$[\](){}/#:!=|]/gi, '\\$&');
			w = replaceIgnoredCharacters(w);
			return w;
		});
		words = words.join('|');

		const regexp = new RegExp(`(${words})`, 'gi');
		return regexp;
	};

	const partMatches = (part, string) => {
		const regexp = makeRegExp(string.split(' '));
		const contains = !!part.match(regexp);
		return contains;
	};

	const highlight = () => {
		if (query === '' || !query || text === '' || !text) return text;

		const regexp = makeRegExp(query.trim().replace(/ +/g, ' ').split(' '));

		const parts = text.toString().split(regexp);

		return (
			<span>
				{parts.map((part, key) => {
					if (partMatches(part.trim(), query.replace(/ +/g, ' ').trim())){
						return (
							<mark key={key} className={'highlight'}>
								{part}
							</mark>
						);
					}

					return part;
				})}
			</span>
		);
	};

	const highlighted = highlight();

	return (
		<span
			{...restProps}
			className={`Highlight ${highlighted === text ? 'not-highlighted' : 'highlighted'} ${className}`}
		>
			{highlighted}
		</span>
	);
}
