import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ReactComponent as Arrow } from './../../images/arrow.svg';

import {
	selectCustomerId,
	selectDetailMargins,
	selectIsDetailMarginsFetching,
	selectIsDetailMarginsSaving,
	selectIsMarginsMetadataFetching,
	selectMarginsCategories,
	selectUnsavedDetailMargins,
} from './../selectors';

import {
	cancelUnsavedMargins,
	fetchDetailMarginsIfNeeded,
	fetchMarginsMetadataIfNeeded,
	saveDetailMargins,
} from './MarginsActions';

import {
	getLocale,
	t
} from './../../libraries/i18n';

import {
	Button,
	List,
	Spinner,
	TextFilter,
	BackButton,
} from '@abm-international/react-components';

import {
	FaPencilAlt,
	FaSave,
	FaTimes
} from 'react-icons/fa';
import { getConsumerPrice, getProductMargin } from './MarginHelpers';
import {
	getArticleRenderer,
	getEditableSalesPriceRenderer,
	getImgRenderer,
	getPurchasePriceRenderer,
	getQuantitiesRenderer,
	getSalesPriceRenderer, getUsedMarginRenderer,
	getUserMarginRenderer,
	getVatRenderer
} from './ColumnRenderers';
import { useHistory } from 'react-router';


function Detail() {
	const match = useRouteMatch();
	const { category: categoryId, productType, marginType } = match.params;

	const locale = getLocale();
	const dispatch = useDispatch();

	const customerId = useSelector(selectCustomerId);

	const isMetadataFetching = useSelector(selectIsMarginsMetadataFetching);
	const categories = useSelector(selectMarginsCategories);

	const isFetching = useSelector(state => selectIsDetailMarginsFetching(state, categoryId, productType, marginType));
	const { category: group, articles } = useSelector(state => selectDetailMargins(state, categoryId, productType, marginType));
	const unsavedMargins = useSelector(state => selectUnsavedDetailMargins(state, categoryId, productType, marginType));
	const isSaving = useSelector(state => selectIsDetailMarginsSaving(state, categoryId, productType, marginType));

	const [isEditingState, setIsEditing] = useState(false);
	const isEditing = unsavedMargins.length > 0 || isEditingState;

	const [errors, setErrors] = useState({});

	const [needHint, setNeedHint] = useState(false);

	const history = useHistory();

	const setErrorsById = (id, error) => {
		setErrors({
			...errors,
			[id]: error
		});
	};

	const BY_UNIT = {
		S: t('margins.by_piece'),
		K: t('margins.by_weight')
	};

	useEffect(() => {
		dispatch(fetchDetailMarginsIfNeeded(customerId, categoryId, productType, marginType));
		dispatch(fetchMarginsMetadataIfNeeded(customerId));
	}, [customerId, dispatch, categoryId, productType, marginType, isSaving]);


	const header = [
		{
			render: getImgRenderer(),
			key: 1
		},
		{
			render: getArticleRenderer(),
			title: t('margins.product_title'),
			key: 2,
			sort: (a, b) => a.id - b.id,
			filter: {
				test: ({ article, id }, value) => article.desc.includes(value.toUpperCase()) || id.includes(value),
				component: TextFilter
			}
		},
		{
			render: getPurchasePriceRenderer(BY_UNIT),
			title: t('margins.purchase_price_title'),
			key: 3
		},
		{
			render: getVatRenderer(),
			title: t('margins.vat_title'),
			key: 4
		},
		{
			render: getQuantitiesRenderer(),
			title: t('margins.sales_packaged_goods_title'),
			key: 5
		}
	];
	const editHeader = [

		{
			render: getUserMarginRenderer(getProductMargin, dispatch, categoryId, productType, marginType, setErrorsById, unsavedMargins),
			title: t('margins.your_margin_title'),
			key: 6
		},
		{
			render: getEditableSalesPriceRenderer(getConsumerPrice, dispatch, categoryId, productType, marginType, BY_UNIT, group, unsavedMargins),
			title: t('margins.sales_price_title'),
			key: 7
		},
		// {
		// 	render: ({id}) => (errors?.[id]?.length ? <ul className={'error'}>{errors?.[id]?.map(e => <li>{e}</li>)}</ul> : null),
		// 	title: '',
		// 	key: 8
		// }
	];
	const displayHeader = [
		{
			render: getUsedMarginRenderer(getProductMargin, unsavedMargins),
			title: t('margins.your_margin_title'),
			key: 6
		},
		{
			render: getSalesPriceRenderer(getConsumerPrice, BY_UNIT, group, unsavedMargins),
			title: t('margins.sales_price_title'),
			key: 7
		},
	];

	const category = categories.find(c => c.id === group?.category);

	const renderNav = () => {
		if (!isEditing){
			return (
				<nav className={'ProductNavigator'}>
					<BackButton to={'/margins'} className='back'>
						{t('margins.go_back')}
					</BackButton>
				</nav>
			);
		}

		return (
			<nav className={'ProductNavigator'}>
				<Button
					className='back'
					icon={<Arrow className={'arrow arrow-left'} />}
					onClick={() => {
						setIsEditing(false);
						dispatch(cancelUnsavedMargins());
						history.pushState('/margins');
					}}
				>
					{t('margins.cancel_and_go_back')}
				</Button>
			</nav>
		);
	};

	const renderTitle = () => (
		<>
			<h3 style={{ fontSize: '2.5rem' }}>
				<span>{category?.title[locale]}</span>
				<span>{'>'}</span>
				<span>{category?.productTypes[productType][locale]}</span>
				<span>{'>'}</span>
				<span>{category?.marginTypes[marginType][locale]}</span>
			</h3>
			<h4>
				<span>
					{ group?.value ?
						`${t('margins.your_margin_label')}: ${group.value}%`
						:
						`${t('margins.charles_margin_label')}: ${group?.proposed}%`
					}
				</span>
			</h4>
		</>
	);

	const renderActions = () => (
		<div className={'actions'}>
			<Button
				icon={<FaTimes />}
				onClick={() => {
					setIsEditing(false);
					dispatch(cancelUnsavedMargins());
				}}
				color='orange'
			>
				{t('margins.cancel')}
			</Button>
			<Button
				icon={<FaSave />}
				onClick={() => {
					setIsEditing(false);
					dispatch(saveDetailMargins(customerId, categoryId, productType, marginType));
				}}
				disabled={!unsavedMargins.length}
				color='green'
				primary
			>
				{t('margins.save')}
			</Button>
		</div>
	);

	return (
		<>
			{renderNav()}

			<div className={'Detail'}>

				{(isMetadataFetching || isFetching) && <Spinner />}

				{!isMetadataFetching && !isFetching && Object.keys(articles ?? {}).length <= 0 && (
					<div>
						{t('margins.noArticles')}
					</div>
				)}
				{Object.keys(articles ?? {}).length > 0 && (
					<section className={'categoryArticles'}>
						{renderTitle()}

						{!isEditing && (
							<Button
								className={needHint ? 'shake' : ''}
								onClick={() => setIsEditing(true)}
								icon={<FaPencilAlt />}
								primary
							>
								{t('margins.edit')}
							</Button>
						)}

						<List
							header={[...header, ...(isEditing || isSaving ? editHeader : displayHeader)]}
							content={Object.values(articles)}
							gridTemplateColumns='6rem 5fr 2fr 1fr 2fr 2fr 2fr'
							action={() => {
								setNeedHint(true);
								setTimeout(() => setNeedHint(false), 1000);
							}}
						/>
						{isEditing && renderActions()}
					</section>
				)}
			</div>
		</>
	);
}

export default Detail;
