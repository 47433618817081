import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Gtag } from '../../modules/common/utils';

const usePageViews = () => {
	const location = useLocation();

	useEffect(() => {
		Gtag.pageview(location.pathname);
	}, [location]);
};

export default usePageViews;
