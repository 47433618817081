import React, { useEffect } from 'react';
import { Spinner } from '@abm-international/react-components';
import * as api from './../../../libraries/api';
import { useSelector } from 'react-redux';
import { selectCustomerAccount, selectCustomerLastUpdated } from '../../selectors';
import { Redirect } from 'react-router-dom';
// import { config } from 'cypress/types/bluebird';
import config from '../../../config';

export const Logout = () => {
	const isAuthenticated = useSelector(selectCustomerAccount);
	const hasFetchedAccount = useSelector(selectCustomerLastUpdated);

	useEffect(() => {
		(async () => {
			try {
				await api.logout();
				window.location = `${config.PORTAL_LOGIN_URL}`;
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);


	if (hasFetchedAccount && !isAuthenticated ) return <Redirect to={config.PORTAL_LOGIN_URL} />;
	return <Spinner />;
};
