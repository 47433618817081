import { useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';

const BATCH_TIME = 200;
const SIZE_OVERLOAD = 10;
export default function useWidth(){
	const supportsResizeObserver = Boolean(window.ResizeObserver);
	const ROUnsupportedRef = useRef(null);
	const [ref, { width: measuredWidth }] = useMeasure();

	const [debouncedWidth, setWidth] = useState(measuredWidth);
	const [lastUpdate, setLastUpdate] = useState(0);


	useEffect(() => {
		const width = supportsResizeObserver ? measuredWidth : ROUnsupportedRef.current?.getBoundingClientRect().width;
		if (!width) return;
		if (width === debouncedWidth) return;
		const now = (new Date()).getTime();
		if (
			now > lastUpdate + BATCH_TIME
			|| Math.abs(debouncedWidth - width) > SIZE_OVERLOAD
		){
			setWidth(width);
			setLastUpdate(now);
		}
	}, [measuredWidth, debouncedWidth, lastUpdate, supportsResizeObserver, ROUnsupportedRef]);

	return [supportsResizeObserver ? ref : ROUnsupportedRef, debouncedWidth];
}
