import {
	REPORTS_REQUEST,
	REPORTS_RECEIVE,
	REPORTS_REJECT,
	REPORTS_HANDLE_SEARCH,
	REPORTS_HANDLE_FREQUENCYFILTER
} from './ReportsActions';
import FREQUENCY_FILTER from './frequencyFilters';

export default function reports(
	state = {
		isFetching: false,
		items: [],
		query: '',
		frequencyFilter: FREQUENCY_FILTER.ALL
	},
	action
) {
	switch (action.type) {
		case REPORTS_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case REPORTS_RECEIVE:
			return {
				...state,
				isFetching: false,
				lastUpdated: Date.now(),
				items: action.data
			};
		case REPORTS_REJECT:
			return {
				...state,
				isFetching: false,
				didFail: true
			};
		case REPORTS_HANDLE_SEARCH:
			return {
				...state,
				query: action.query
			};
		case REPORTS_HANDLE_FREQUENCYFILTER:
			return {
				...state,
				frequencyFilter: action.frequencyFilter
			};
		default:
			return { ...state };
	}
}
