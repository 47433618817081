import Api from './../../../libraries/api';
import {
	selectIsInvoicesFetching,
	selectInvoices
} from './../../selectors';

export const INVOICES_REQUEST = 'INVOICES_REQUEST';
export const INVOICES_RECEIVE = 'INVOICES_RECEIVE';
export const INVOICES_REJECT = 'INVOICES_REJECT';
export const INVOICES_HANDLE_SEARCH = 'INVOICES_HANDLE_SEARCH';
export const INVOICES_CHANGE_VIEW = 'INVOICES_CHANGE_VIEW';

function requestInvoices() {
	return {
		type: INVOICES_REQUEST
	};
}

function receiveInvoices(data) {
	return {
		type: INVOICES_RECEIVE,
		items: data
	};
}

function rejectInvoices() {
	return {
		type: INVOICES_REJECT
	};
}

export function handleSearch(query) {
	return {
		type: INVOICES_HANDLE_SEARCH,
		query: query
	};
}

export function changeView(view) {
	return {
		type: INVOICES_CHANGE_VIEW,
		view: view
	};
}

function fetchInvoices(customerId) {
	return async dispatch => {
		dispatch(requestInvoices());

		let data;

		try {
			data = await Api.getInvoices(customerId);
		} catch (error) {
			return dispatch(rejectInvoices());
		}

		return dispatch(receiveInvoices(data));
	};
}

function shouldFetchInvoices(state) {
	const isFetching = selectIsInvoicesFetching(state);
	const invoices = selectInvoices(state);

	if (isFetching) return false;
	return invoices.length <= 0;
}

export function fetchInvoicesIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchInvoices(getState())) return dispatch(fetchInvoices(customerId));
	};
}
