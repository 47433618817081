import './PreOrder.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PreOrderAction } from './../../../types/preOrders';
import * as DateHelper from './../../../libraries/dateHelper';
import {
	selectCanPreOrderActionSubmit,
	selectHasPreOrderActionFinalSaved,
	selectIsPreOrderActionSaving,
	selectLocale,
	selectPreOrderActionSaveError,
	selectIsProductHistoriesFetching,
	selectProductHistories,
} from './../../selectors';
import { VIEWS } from './../../common/OrderArticle';
import { savePreOrderIfNeeded } from './../preOrdersActions';
import { Button, Spinner } from '@abm-international/react-components';
import { t } from './../../../libraries/i18n';
import Modal from '../../../components/modal/modal';
import PreOrderArticleContainer from '../../common/OrderArticleContainer/PreOrderArticleContainer';
import ProductDetail from '../../../components/productsViews/productDetail/productDetail';
import { Article } from '~/types/articles';

interface Props {
	action: PreOrderAction;
	preOrderType: string;
}

const SEND_ORDER_INTERVAL = 3000;

export default function PreOrder(props: Props) {
	const { preOrderType, action } = props;

	const dispatch = useDispatch();
	const [productModal, setProductModal] = useState<Article>();

	const locale: 'nl' | 'fr' = useSelector(selectLocale);
	const [year, week] = useMemo(() => action.delivery.split('.'), [action?.delivery]);
	const finalSaved = useSelector(state => selectHasPreOrderActionFinalSaved(state, action.group, preOrderType));
	const isSaving = useSelector(state => selectIsPreOrderActionSaving(state, action.group, preOrderType));
	const canSubmit = useSelector(state => selectCanPreOrderActionSubmit(state, action.group, preOrderType));
	const saveError = useSelector(state => selectPreOrderActionSaveError(state, action.group, preOrderType));
	const histories = useSelector(selectProductHistories);
	const isHistoryFetching = useSelector(selectIsProductHistoriesFetching);
	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(savePreOrderIfNeeded(action, preOrderType, false));
		}, SEND_ORDER_INTERVAL);

		return () => clearInterval(interval);
	}, [dispatch, action, preOrderType]);
	return (
		<div className={`PreOrder ${finalSaved ? 'submitted' : ''}`}>
			<div className='title'>
				<div className='details'>
					<h3>{action.description[locale]}</h3>
					<div className='deadline'>
						{t('preorders.label_deadline', DateHelper.getPrettyDate(DateHelper.getDateTimeFromReversedDateString(action.available.end), locale))}
					</div>
					{(action?.section_info && action.section_info[locale]) && <div className='section_info'>{action.section_info[locale]}</div>}
				</div>
				<div className='delivery-week'>{t('preorders.label_delivery_week', week)}</div>
			</div>

			<div className='articles'>
				{action.articles.map(art => (
					<PreOrderArticleContainer
						action={action}
						key={art.id}
						deliveryDate={DateHelper.getDateOfISOWeek(week, year)}
						view={VIEWS.LIST}
						product={art}
						showModal={(prod: Article) => setProductModal(prod)}
						imageSource={`${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles`}
						locale={locale}
						history={histories[art.id]}
						isHistoryFetching={isHistoryFetching}
						sizes={[]}
						t={t}
						group={action.group}
						preOrderType={preOrderType}
					/>
				))}
			</div>

			{saveError && (
				<div className='error'>
					{t(`errors.${saveError?.error_code}`)}
				</div>
			)}

			<div className='bottom'>
				<Button
					color='gold'
					primary
					disabled={!canSubmit}
					onClick={() => dispatch(savePreOrderIfNeeded(action, preOrderType, true))}
					className='submit'
				>
					{isSaving && <Spinner size='small' />}
					{!isSaving && <>
						{t('preorders.button_submit')}
						{' '}- {action.description[locale]}
					</>}
				</Button>

				{finalSaved &&
					<div className='saved'>
						{t('preorders.label_save_success')}
					</div>
				}
			</div>

			<Modal show={!!productModal} close={() => setProductModal(undefined)}>
				<ProductDetail
					product={productModal}
					deliveryDate={DateHelper.getDateOfISOWeek(week, year)}
					preOrderType={preOrderType}
				/>
			</Modal>
		</div>
	);
}
