import type { PreOrderAction, PreOrdersState } from '../../types/preOrders';
import * as DateHelper from './../../libraries/dateHelper';

export const selectIsFetching = (state: PreOrdersState) => state?.isFetching ?? false;
export const selectActions = (state: PreOrdersState) => state?.actions ?? [];
export const selectAction = (state: PreOrdersState, group: string): PreOrderAction | undefined => selectActions(state).find(a => a.group === group);
export const selectActionQuantities = (state: PreOrdersState, group: string) => selectAction(state, group)?.quantities;
const selectActionLastSaved = (state: PreOrdersState, group: string) => DateHelper.fromReduxDateString(selectAction(state, group)?.lastSaved);
const selectActionLastChanged = (state: PreOrdersState, group: string) => DateHelper.fromReduxDateString(selectAction(state, group)?.lastChanged);
export const selectActionHasChanges = (state: PreOrdersState, group: string) => {
	const lastSaved = selectActionLastSaved(state, group) ?? 0;
	const lastChanged = selectActionLastChanged(state, group) ?? 0;

	return lastSaved < lastChanged;
};
export const selectHasChanges = (state: PreOrdersState) => {
	const quantities = selectActions(state).map(a => a.quantities);
	const orderedQuantities = selectActions(state).map(a => a.orderedQuantities);

	return JSON.stringify(quantities) !== JSON.stringify(orderedQuantities);
};
export const selectHasActionFinalSaved = (state: PreOrdersState, group: string) => selectAction(state, group)?.finalSaved;
export const selectIsActionSaving = (state: PreOrdersState, group: string) => selectAction(state, group)?.isSaving;
export const selectCanActionSubmit = (state: PreOrdersState, group: string) => {
	const finalSaved = selectHasActionFinalSaved(state, group);
	if (finalSaved) return false;

	const quantities = selectActionQuantities(state, group);
	if (!quantities) return false;
	return Object.values(quantities).reduce((value, total) => total + value, 0) > 0;
};
export const selectCanActionsSubmit = (state: PreOrdersState, actions: Array<PreOrderAction>) => {
	return actions.map(({ group }) => ({ group, canSubmit: selectCanActionSubmit(state, group) }));
};
export const selectActionSaveError = (state: PreOrdersState, group: string) => selectAction(state, group)?.saveError;
export const selectActionDeliveryDate = (state: PreOrdersState, group: string) => {
	const action = selectAction(state, group);
	if (!action) return undefined;
	const [year, week] = action?.delivery.split('.');

	return DateHelper.getDateOfISOWeek(week, year);
};
export const selectShowSubmittedWarning = (state: PreOrdersState) => state?.showSubmittedWarning ?? false;
export const selectIsSubmittingAllPreOrdersByType = (state: PreOrdersState) => state?.isSubmittingAll ?? false;
export const selectHasSubmittedAllPreOrdersByType = (state: PreOrdersState) => state?.hasSubmittedAll ?? false;
export const selectDidSaveAllFail = (state: PreOrdersState) => state?.didFail ?? false;
export const selectPreOrderActionLastSaveAttempt = (state: PreOrdersState, group: string) => DateHelper.fromReduxDateString(selectAction(state, group)?.lastAttempt ?? null);
export const selectPreOrderActionExponentialBackoff = (state: PreOrdersState, group: string) => selectAction(state, group)?.exponentialBackoff ?? 0;

export const selectAwardedPromoByProductId = (state: {[index: string|number]: PreOrdersState}, preOrderType: number, groupId: string, id: number) => {
	const preOrderAction = state[preOrderType]?.actions.find(action => action.group === groupId);
	return (preOrderAction?.awardedPromos || {})[id];
};
