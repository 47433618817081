import React from 'react';
import { connect } from 'react-redux';
import './tinyProductItem.scss';

function TinyProductItem(props){
	const renderId = (id) => {
		return (
			<div className={'product__id'}>
				{id}
			</div>
		);
	};

	const renderBrand = (brand) => {
		return (
			<div className={'product__brand'}>
				{brand}
			</div>
		);
	};

	const renderName = (name) => {
		return (
			<div className={'product__name'}>
				{name}
			</div>
		);
	};

	const product = props.product;
	if (!product) return null;

	return (
		<div className={'ProductItem'}>
			<div className={'product__tiny-item'}>
				<div className={'product__top'}>
					<div className={'product__body'}>
						<div className={'body__top'}>
							{renderId(product.code)}
							{renderBrand(product.brand)}
						</div>
						<div className={'body__bottom'}>
							{renderName(product.name)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(state, ownProps) {
	return {
		product: ownProps.product,
	};
}


export default connect(mapStateToProps)(TinyProductItem);
