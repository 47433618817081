import { rest } from 'msw';

export const getApiProductsMetaData = [
	rest.get('http://localhost:4000/api/products/metadata', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'article_image_location': 'http://staging02.portal.cocks.private/resources/images/articles/',
					'shopcodes': {
						'10000000': {
							'id': 10000000,
							'desc': {
								'nl': 'Mocking, PRODUCTEN IN BEDIENING',
								'fr': 'SERVICE RÉFRIGÉRÉ'
							}
						},
						'11000000': {
							'id': 11000000,
							'desc': {
								'nl': 'Mocking, CHARCUTERIE',
								'fr': 'CHARCUTERIE'
							}
						},
						'11010000': {
							'id': 11010000,
							'desc': {
								'nl': 'Mocking, HAM',
								'fr': 'JAMBON'
							}
						},
						'11010100': {
							'id': 11010100,
							'desc': {
								'nl': 'KOOKHAM',
								'fr': 'JAMBON CUIT'
							}
						},
						'11010101': {
							'id': 11010101,
							'desc': {
								'nl': 'Beenderham',
								'fr': "Jambon cuit à l'os"
							}
						},
						'11010102': {
							'id': 11010102,
							'desc': {
								'nl': 'Gekookte ham z.been',
								'fr': 'Jambon cuit sans os'
							}
						},
						'11010103': {
							'id': 11010103,
							'desc': {
								'nl': 'Ontvette ham',
								'fr': 'Jambon dégraissé'
							}
						},
						'11010104': {
							'id': 11010104,
							'desc': {
								'nl': 'Grill-Bbq ham',
								'fr': 'Jambon grill-bbq'
							}
						},
						'11010105': {
							'id': 11010105,
							'desc': {
								'nl': 'Gebakken ham',
								'fr': 'Jambon cuit'
							}
						},
						'11010106': {
							'id': 11010106,
							'desc': {
								'nl': 'Ham met smaakvariant',
								'fr': 'Jambon avec goût différent'
							}
						},
						'11010107': {
							'id': 11010107,
							'desc': {
								'nl': 'Picnic-schouderham',
								'fr': 'Jambon pique-nique'
							}
						},
						'11010109': {
							'id': 11010109,
							'desc': {
								'nl': 'Hamdelen',
								'fr': 'Parties de jambon'
							}
						},
						'11010110': {
							'id': 11010110,
							'desc': {
								'nl': 'Lamsham',
								'fr': 'Jambon'
							}
						},
						'11010200': {
							'id': 11010200,
							'desc': {
								'nl': 'GEDROOGDE HAM',
								'fr': 'JAMBON SEC'
							}
						},
						'11010201': {
							'id': 11010201,
							'desc': {
								'nl': 'Gedroogde ham België',
								'fr': "Jambon cru d'origine belge"
							}
						},
						'11010202': {
							'id': 11010202,
							'desc': {
								'nl': 'Gedroogde ham Italië',
								'fr': "Jambon cru d'origine italienne"
							}
						},
						'11010203': {
							'id': 11010203,
							'desc': {
								'nl': 'Parma - San Daniele',
								'fr': "Jambon cru d'origine française"
							}
						},
						'11010204': {
							'id': 11010204,
							'desc': {
								'nl': 'Gedroogde ham Spanje',
								'fr': "Jambon cru d'origine espagnole"
							}
						},
						'11010205': {
							'id': 11010205,
							'desc': {
								'nl': 'Gedroogde ham Frankrijk',
								'fr': 'Jambon sec divers'
							}
						},
						'11010300': {
							'id': 11010300,
							'desc': {
								'nl': 'GEROOKTE HAM',
								'fr': 'JAMBON FUMÉ'
							}
						},
						'11010301': {
							'id': 11010301,
							'desc': {
								'nl': 'Gerookte cobourg',
								'fr': 'Cobourg fumé'
							}
						},
						'11010302': {
							'id': 11010302,
							'desc': {
								'nl': 'Diverse gerookte ham',
								'fr': 'Jambon fumé divers'
							}
						},
						'11010304': {
							'id': 11010304,
							'desc': {
								'nl': 'Ham Duitsland-Oostenrijk',
								'fr': 'Jambon Allemand - Autrichien'
							}
						},
						'11010305': {
							'id': 11010305,
							'desc': {
								'nl': 'Gerookte hamdelen',
								'fr': 'Parties de jambon fumées'
							}
						},
						'11020000': {
							'id': 11020000,
							'desc': {
								'nl': 'SPEK - RUG',
								'fr': 'LARD DOS'
							}
						},
						'11020100': {
							'id': 11020100,
							'desc': {
								'nl': 'SPEK',
								'fr': 'LARD POITRINE'
							}
						},
						'11020101': {
							'id': 11020101,
							'desc': {
								'nl': 'Gezouten spek',
								'fr': 'Lard salé'
							}
						},
						'11020102': {
							'id': 11020102,
							'desc': {
								'nl': 'Gerookt spek',
								'fr': 'Lard fumé'
							}
						},
						'11020103': {
							'id': 11020103,
							'desc': {
								'nl': 'Gestoomd spek',
								'fr': 'Lard préparé'
							}
						},
						'11020104': {
							'id': 11020104,
							'desc': {
								'nl': 'Pancetta',
								'fr': 'Lard sec'
							}
						},
						'11020105': {
							'id': 11020105,
							'desc': {
								'nl': 'Mosterdspek',
								'fr': 'Lard à moutarde'
							}
						},
						'11020106': {
							'id': 11020106,
							'desc': {
								'nl': 'Kruidenspek',
								'fr': 'Lard aux épices'
							}
						},
						'11020200': {
							'id': 11020200,
							'desc': {
								'nl': 'VARKENSRUG',
								'fr': 'DOS DE PORC'
							}
						},
						'11020201': {
							'id': 11020201,
							'desc': {
								'nl': 'Gezouten bacon',
								'fr': 'Bacon salé'
							}
						},
						'11020202': {
							'id': 11020202,
							'desc': {
								'nl': 'Gerookte bacon',
								'fr': 'Bacon fumé'
							}
						},
						'11020203': {
							'id': 11020203,
							'desc': {
								'nl': 'Bereide bacon',
								'fr': 'Bacon préparé'
							}
						},
						'11020204': {
							'id': 11020204,
							'desc': {
								'nl': 'Filet de Saks/York',
								'fr': 'Filet de Saxe/York'
							}
						},
						'11020205': {
							'id': 11020205,
							'desc': {
								'nl': 'Hespenspek',
								'fr': 'Lard au jambon salé'
							}
						},
						'11020207': {
							'id': 11020207,
							'desc': {
								'nl': 'Gedroogde bacon',
								'fr': 'Bacon sec'
							}
						},
						'11020208': {
							'id': 11020208,
							'desc': {
								'nl': 'Coppa- spiering',
								'fr': 'Coppa'
							}
						},
						'11030000': {
							'id': 11030000,
							'desc': {
								'nl': 'GEDROOGDE WORST',
								'fr': 'SAUCISSON SEC'
							}
						},
						'11030100': {
							'id': 11030100,
							'desc': {
								'nl': 'DROGE WORST/SNIJGOED',
								'fr': 'SAUCISSE SÈCHE COUPE'
							}
						},
						'11030101': {
							'id': 11030101,
							'desc': {
								'nl': 'Salami z.look',
								'fr': 'Salami sans ail'
							}
						},
						'11030102': {
							'id': 11030102,
							'desc': {
								'nl': 'Salami met look',
								'fr': 'Salami avec ail'
							}
						},
						'11030103': {
							'id': 11030103,
							'desc': {
								'nl': 'Fijnkost',
								'fr': 'Charcuterie fine'
							}
						},
						'11030104': {
							'id': 11030104,
							'desc': {
								'nl': 'Boerenworst/-ringworst',
								'fr': 'Salami en collier'
							}
						},
						'11030105': {
							'id': 11030105,
							'desc': {
								'nl': 'Zigeunersalami',
								'fr': 'Salami tzigane'
							}
						},
						'11030106': {
							'id': 11030106,
							'desc': {
								'nl': 'Boulogne',
								'fr': 'Boulogne'
							}
						},
						'11030107': {
							'id': 11030107,
							'desc': {
								'nl': 'Ardense salami',
								'fr': "Salami d'Ardennes"
							}
						},
						'11030108': {
							'id': 11030108,
							'desc': {
								'nl': 'Salami minder vet/zout',
								'fr': 'Salami pauvre en graisses/sel'
							}
						},
						'11030109': {
							'id': 11030109,
							'desc': {
								'nl': 'Gevogeltesalami',
								'fr': 'Salami de volaille'
							}
						},
						'11030110': {
							'id': 11030110,
							'desc': {
								'nl': 'Witschimmelsalami',
								'fr': 'Salami à moisissure blanche'
							}
						},
						'11030111': {
							'id': 11030111,
							'desc': {
								'nl': 'Pepersalami',
								'fr': 'Salami au poivre'
							}
						},
						'11030112': {
							'id': 11030112,
							'desc': {
								'nl': 'Chorizo',
								'fr': 'Chorizo'
							}
						},
						'11030113': {
							'id': 11030113,
							'desc': {
								'nl': 'Salami met kruiden',
								'fr': 'Salami avec des herbes'
							}
						},
						'11030114': {
							'id': 11030114,
							'desc': {
								'nl': 'Salami met noten',
								'fr': 'Salami avec des noix'
							}
						},
						'11030115': {
							'id': 11030115,
							'desc': {
								'nl': 'Salami met truffel',
								'fr': 'Salami avec des truffes'
							}
						},
						'11030119': {
							'id': 11030119,
							'desc': {
								'nl': 'Diverse buitenlandse spec.',
								'fr': 'Spécialités de salami/formes'
							}
						},
						'11030200': {
							'id': 11030200,
							'desc': {
								'nl': 'DROGE WORST/STUKGOED',
								'fr': 'SAUCISSE SÈCHE À LA PIÈCE'
							}
						},
						'11030201': {
							'id': 11030201,
							'desc': {
								'nl': 'Varkensworstjes',
								'fr': 'Saucissons de porc'
							}
						},
						'11030202': {
							'id': 11030202,
							'desc': {
								'nl': 'Varkensringen',
								'fr': 'Colliers de porc'
							}
						},
						'11030203': {
							'id': 11030203,
							'desc': {
								'nl': 'Salami stukgoed',
								'fr': 'Salami à la pièce'
							}
						},
						'11030204': {
							'id': 11030204,
							'desc': {
								'nl': 'Salami stukgoed - witschimmel',
								'fr': 'Salami à la pièce blanc'
							}
						},
						'11040000': {
							'id': 11040000,
							'desc': {
								'nl': 'KOOKWORST',
								'fr': 'SAUCISSON CUIT'
							}
						},
						'11040100': {
							'id': 11040100,
							'desc': {
								'nl': 'SNIJWORST',
								'fr': 'SAUCISSON COUPÉ'
							}
						},
						'11040101': {
							'id': 11040101,
							'desc': {
								'nl': 'Hespenworst',
								'fr': 'Saucisson au jambon'
							}
						},
						'11040102': {
							'id': 11040102,
							'desc': {
								'nl': 'Kalfsworst',
								'fr': 'Saucisson de veau'
							}
						},
						'11040103': {
							'id': 11040103,
							'desc': {
								'nl': 'Ring/lunchworst',
								'fr': 'Saucisson de lunch/collier'
							}
						},
						'11040104': {
							'id': 11040104,
							'desc': {
								'nl': 'Gevogelteworst',
								'fr': 'Saucisson de volaille'
							}
						},
						'11040105': {
							'id': 11040105,
							'desc': {
								'nl': 'Parijse- en jachtworst',
								'fr': 'Saucisson de Paris/de chasse'
							}
						},
						'11040106': {
							'id': 11040106,
							'desc': {
								'nl': 'Champignonworst',
								'fr': 'Saucisson aux champignons'
							}
						},
						'11040107': {
							'id': 11040107,
							'desc': {
								'nl': 'Goldworst',
								'fr': 'Saucisson gold'
							}
						},
						'11040108': {
							'id': 11040108,
							'desc': {
								'nl': 'Worst met tekening',
								'fr': 'Saucisson avec dessin'
							}
						},
						'11040109': {
							'id': 11040109,
							'desc': {
								'nl': 'Snijworst variëteiten',
								'fr': 'Variétés de saucissons coupés'
							}
						},
						'11040110': {
							'id': 11040110,
							'desc': {
								'nl': 'Rollades',
								'fr': 'Roulades'
							}
						},
						'11040200': {
							'id': 11040200,
							'desc': {
								'nl': 'STUKWORST',
								'fr': 'SAUCISSON À LA PIÈCE'
							}
						},
						'11040201': {
							'id': 11040201,
							'desc': {
								'nl': 'Lookworst',
								'fr': "Saucisson à l'ail"
							}
						},
						'11040202': {
							'id': 11040202,
							'desc': {
								'nl': 'Grillworst',
								'fr': 'Saucisse grillée'
							}
						},
						'11040203': {
							'id': 11040203,
							'desc': {
								'nl': 'Boterhamworstjes',
								'fr': 'Saucissons tartines'
							}
						},
						'11050000': {
							'id': 11050000,
							'desc': {
								'nl': 'LEVERPRODUCTEN',
								'fr': 'PRODUIT DE FOIE'
							}
						},
						'11050100': {
							'id': 11050100,
							'desc': {
								'nl': 'CREME PATE',
								'fr': 'PÂTÉ CRÊME'
							}
						},
						'11050101': {
							'id': 11050101,
							'desc': {
								'nl': 'Crèmepaté',
								'fr': 'Pâté en terrine'
							}
						},
						'11050102': {
							'id': 11050102,
							'desc': {
								'nl': 'Blokpaté',
								'fr': 'Pâté en bloc'
							}
						},
						'11050103': {
							'id': 11050103,
							'desc': {
								'nl': 'Leverworst',
								'fr': 'Pâté de foie'
							}
						},
						'11050105': {
							'id': 11050105,
							'desc': {
								'nl': 'Fijne paté- fantaisie',
								'fr': 'Paté fin'
							}
						},
						'11050109': {
							'id': 11050109,
							'desc': {
								'nl': 'Sandwichspread',
								'fr': 'Pâté fantaisie raffiné'
							}
						},
						'11050200': {
							'id': 11050200,
							'desc': {
								'nl': 'GROVE PATE',
								'fr': 'GROS PÂTÉ'
							}
						},
						'11050201': {
							'id': 11050201,
							'desc': {
								'nl': 'Boeren- Ardense paté',
								'fr': 'Pâté fermier-Ardennais'
							}
						},
						'11050202': {
							'id': 11050202,
							'desc': {
								'nl': 'Gebakken paté',
								'fr': 'Pâté cuit'
							}
						},
						'11050203': {
							'id': 11050203,
							'desc': {
								'nl': 'Grove paté-diverse merken',
								'fr': 'Pâté de gibier'
							}
						},
						'11050205': {
							'id': 11050205,
							'desc': {
								'nl': 'In korst gebakken paté',
								'fr': 'Pâté en croute'
							}
						},
						'11050209': {
							'id': 11050209,
							'desc': {
								'nl': 'Grove paté fantaisie',
								'fr': 'Gros pâté fantaisie'
							}
						},
						'11050300': {
							'id': 11050300,
							'desc': {
								'nl': 'GEVOGELTEPATE',
								'fr': 'FOIE CUIT'
							}
						},
						'11050301': {
							'id': 11050301,
							'desc': {
								'nl': 'Fijne gevogeltepaté',
								'fr': 'Foie cuit en bloc'
							}
						},
						'11050302': {
							'id': 11050302,
							'desc': {
								'nl': 'Grove gevogeltepaté',
								'fr': 'Foie cuit'
							}
						},
						'11050400': {
							'id': 11050400,
							'desc': {
								'nl': 'THEMAPATE',
								'fr': 'PATE DE THEME'
							}
						},
						'11050401': {
							'id': 11050401,
							'desc': {
								'nl': 'Themapaté',
								'fr': 'Paté de thème'
							}
						},
						'11050402': {
							'id': 11050402,
							'desc': {
								'nl': 'Seizoenspaté',
								'fr': 'Paté de saison'
							}
						},
						'11050500': {
							'id': 11050500,
							'desc': {
								'nl': 'WILDPATE',
								'fr': ''
							}
						},
						'11050501': {
							'id': 11050501,
							'desc': {
								'nl': 'Wildpaté Charles',
								'fr': 'Paté de gibier Charles'
							}
						},
						'11050502': {
							'id': 11050502,
							'desc': {
								'nl': 'Wildpaté De Beauvoorder',
								'fr': 'Paté de gibier De Beauvoorder'
							}
						},
						'11050503': {
							'id': 11050503,
							'desc': {
								'nl': "Wildpaté Le Cochon d'Or",
								'fr': "Paté de gibier Le Cochon d'or"
							}
						},
						'11050504': {
							'id': 11050504,
							'desc': {
								'nl': 'Wildpaté De Belie',
								'fr': 'Paté de gibier De Belie'
							}
						},
						'11050509': {
							'id': 11050509,
							'desc': {
								'nl': 'Wildpaté Diverse merken',
								'fr': 'Wildpate Diverse merken'
							}
						},
						'11050600': {
							'id': 11050600,
							'desc': {
								'nl': 'GEKOOKTE LEVER',
								'fr': ''
							}
						},
						'11050601': {
							'id': 11050601,
							'desc': {
								'nl': 'Gekookte lever',
								'fr': 'Gekoookte lever'
							}
						},
						'11060000': {
							'id': 11060000,
							'desc': {
								'nl': 'GEVOGELTE',
								'fr': 'VOLAILLE'
							}
						},
						'11060100': {
							'id': 11060100,
							'desc': {
								'nl': 'KALKOEN',
								'fr': 'DINDE'
							}
						},
						'11060101': {
							'id': 11060101,
							'desc': {
								'nl': 'Kalkoenfilet en -ham',
								'fr': 'Filet et jambon de dinde'
							}
						},
						'11060102': {
							'id': 11060102,
							'desc': {
								'nl': 'Kalkoen Gerookt',
								'fr': 'Dinde fumée'
							}
						},
						'11060103': {
							'id': 11060103,
							'desc': {
								'nl': 'Kalkoen aspic',
								'fr': 'Dinde aspic'
							}
						},
						'11060109': {
							'id': 11060109,
							'desc': {
								'nl': 'Kalkoen-fantaisie',
								'fr': 'Dinde fantaisie'
							}
						},
						'11060110': {
							'id': 11060110,
							'desc': {
								'nl': 'Kalkoen seizoen-thema',
								'fr': ''
							}
						},
						'11060200': {
							'id': 11060200,
							'desc': {
								'nl': 'KIP',
								'fr': 'POULET'
							}
						},
						'11060201': {
							'id': 11060201,
							'desc': {
								'nl': 'Kipfilet en kippenham',
								'fr': 'Filet et jambon de poulet'
							}
						},
						'11060202': {
							'id': 11060202,
							'desc': {
								'nl': 'Kip diverse kruiden',
								'fr': 'Poulet fines herbes'
							}
						},
						'11060203': {
							'id': 11060203,
							'desc': {
								'nl': 'Kip aspic',
								'fr': 'Poulet aspic'
							}
						},
						'11060209': {
							'id': 11060209,
							'desc': {
								'nl': 'Kip fantaisie',
								'fr': 'Poulet fantaisie'
							}
						},
						'11060210': {
							'id': 11060210,
							'desc': {
								'nl': 'Kip seizoen-thema',
								'fr': 'Saison Poulet theme'
							}
						},
						'11060300': {
							'id': 11060300,
							'desc': {
								'nl': 'EEND',
								'fr': ''
							}
						},
						'11060301': {
							'id': 11060301,
							'desc': {
								'nl': 'Eend',
								'fr': ''
							}
						},
						'11070000': {
							'id': 11070000,
							'desc': {
								'nl': 'KOPPRODUCTEN',
								'fr': 'PRODUIT DE TÊTE'
							}
						},
						'11070100': {
							'id': 11070100,
							'desc': {
								'nl': 'VARKENSKOP',
								'fr': 'TÊTE DE PORC'
							}
						},
						'11070101': {
							'id': 11070101,
							'desc': {
								'nl': 'Fijne kip kap en kop',
								'fr': 'Tête pressée et Tête'
							}
						},
						'11070102': {
							'id': 11070102,
							'desc': {
								'nl': 'Kop met tong',
								'fr': 'Tête avec langue'
							}
						},
						'11070103': {
							'id': 11070103,
							'desc': {
								'nl': 'Breugelkop/Hure',
								'fr': 'Tête pressée/Hure'
							}
						},
						'11070104': {
							'id': 11070104,
							'desc': {
								'nl': 'Kop in saus',
								'fr': 'Tête en sauce'
							}
						},
						'11070105': {
							'id': 11070105,
							'desc': {
								'nl': 'Kop in tomaat',
								'fr': 'Tête à la tomate'
							}
						},
						'11070200': {
							'id': 11070200,
							'desc': {
								'nl': 'KALFSKOP',
								'fr': 'TÊTE DE VEAU'
							}
						},
						'11070201': {
							'id': 11070201,
							'desc': {
								'nl': 'Kalfskop in tomaat',
								'fr': 'Tête de veau en tomate'
							}
						},
						'11070300': {
							'id': 11070300,
							'desc': {
								'nl': 'TONG',
								'fr': ''
							}
						},
						'11070301': {
							'id': 11070301,
							'desc': {
								'nl': 'Rundertong',
								'fr': 'Rundertong'
							}
						},
						'11070302': {
							'id': 11070302,
							'desc': {
								'nl': 'Varkenstong',
								'fr': 'Varkenstong'
							}
						},
						'11080000': {
							'id': 11080000,
							'desc': {
								'nl': 'GEHAKTPRODUCTEN',
								'fr': 'PRODUIT HACHÉ'
							}
						},
						'11080100': {
							'id': 11080100,
							'desc': {
								'nl': 'VLEESBROOD',
								'fr': 'PAIN DE VIANDE'
							}
						},
						'11080101': {
							'id': 11080101,
							'desc': {
								'nl': 'Vleesbrood natuur',
								'fr': 'Pain de viande'
							}
						},
						'11080102': {
							'id': 11080102,
							'desc': {
								'nl': 'Strasbourg',
								'fr': 'Strasbourg'
							}
						},
						'11080103': {
							'id': 11080103,
							'desc': {
								'nl': 'Gevogeltevleesbrood',
								'fr': 'Pain de viande de volaille'
							}
						},
						'11080109': {
							'id': 11080109,
							'desc': {
								'nl': 'Vleesbrood fantaisie',
								'fr': 'Pain de viande fantaisie'
							}
						},
						'11080110': {
							'id': 11080110,
							'desc': {
								'nl': 'Vleesbrood seizoen-thema',
								'fr': ''
							}
						},
						'11080200': {
							'id': 11080200,
							'desc': {
								'nl': 'GEHAKTBAL',
								'fr': 'BOULETTE DE HACHIS'
							}
						},
						'11080201': {
							'id': 11080201,
							'desc': {
								'nl': 'Gehaktbal-boulet',
								'fr': 'Boulette de hachis-boulette'
							}
						},
						'11090000': {
							'id': 11090000,
							'desc': {
								'nl': 'PAARDEN- EN RUNDVLEES',
								'fr': 'VIANDE DE CHEVAL - DE BOEUF'
							}
						},
						'11090100': {
							'id': 11090100,
							'desc': {
								'nl': 'PAARDENROOKVLEES',
								'fr': 'VIANDE DE CHEVAL FUMÉE'
							}
						},
						'11090101': {
							'id': 11090101,
							'desc': {
								'nl': 'Gerookt paardenvlees Charles',
								'fr': 'Viande de cheval fumée'
							}
						},
						'11090102': {
							'id': 11090102,
							'desc': {
								'nl': 'Gerookt paardenvlees Equinox',
								'fr': 'Viande de cheval fumée édulc.'
							}
						},
						'11090103': {
							'id': 11090103,
							'desc': {
								'nl': 'Gerookt paardenvlees DeNil',
								'fr': ''
							}
						},
						'11090105': {
							'id': 11090105,
							'desc': {
								'nl': 'Gerookt paardenvlees div. merk',
								'fr': ''
							}
						},
						'11090200': {
							'id': 11090200,
							'desc': {
								'nl': 'RUNDERROOKVLEES',
								'fr': 'VIANDE DE BOEUF FUMÉE'
							}
						},
						'11090201': {
							'id': 11090201,
							'desc': {
								'nl': 'Gerookt rundvlees Charles',
								'fr': 'Viande de boeuf fumée'
							}
						},
						'11090204': {
							'id': 11090204,
							'desc': {
								'nl': 'Gerookt rundvlees div. merk',
								'fr': 'Viande de boeuf séchée'
							}
						},
						'11090209': {
							'id': 11090209,
							'desc': {
								'nl': 'Roken filets',
								'fr': ''
							}
						},
						'11090300': {
							'id': 11090300,
							'desc': {
								'nl': 'GEDROOGD RUNDVLEES',
								'fr': 'VIANDE DE BOEUF PRÉPARÉE'
							}
						},
						'11090301': {
							'id': 11090301,
							'desc': {
								'nl': 'Gedroogd rundvlees',
								'fr': 'Viande de boeuf préparée'
							}
						},
						'11090400': {
							'id': 11090400,
							'desc': {
								'nl': 'BEREID RUNVLEES',
								'fr': ''
							}
						},
						'11090401': {
							'id': 11090401,
							'desc': {
								'nl': 'Bereid rundvlees',
								'fr': 'Bereid rundsvlees'
							}
						},
						'11190000': {
							'id': 11190000,
							'desc': {
								'nl': 'DIVERSEN - KONFIJTEN',
								'fr': 'DIVERS'
							}
						},
						'11190100': {
							'id': 11190100,
							'desc': {
								'nl': 'DIVERSEN',
								'fr': 'DIVERS'
							}
						},
						'11190101': {
							'id': 11190101,
							'desc': {
								'nl': 'Aspicproducten',
								'fr': 'Produits aspic'
							}
						},
						'11190102': {
							'id': 11190102,
							'desc': {
								'nl': 'Rillette',
								'fr': 'Rillette'
							}
						},
						'11190103': {
							'id': 11190103,
							'desc': {
								'nl': 'Uier',
								'fr': 'Uier'
							}
						},
						'11190200': {
							'id': 11190200,
							'desc': {
								'nl': 'KONFIJTEN',
								'fr': 'CONFITS'
							}
						},
						'11190201': {
							'id': 11190201,
							'desc': {
								'nl': 'Konfijten Charles',
								'fr': 'Confits Charles'
							}
						},
						'11190202': {
							'id': 11190202,
							'desc': {
								'nl': 'Konfijten Didden',
								'fr': 'Confits Belberry'
							}
						},
						'11190500': {
							'id': 11190500,
							'desc': {
								'nl': 'VEGETARISCHE PRODUCTEN',
								'fr': ''
							}
						},
						'11190501': {
							'id': 11190501,
							'desc': {
								'nl': 'Vegetarische producten',
								'fr': ''
							}
						},
						'12000000': {
							'id': 12000000,
							'desc': {
								'nl': 'KAAS',
								'fr': 'FROMAGE'
							}
						},
						'12010000': {
							'id': 12010000,
							'desc': {
								'nl': 'VERSE KAAS',
								'fr': 'FROMAGE FRAIS'
							}
						},
						'12010100': {
							'id': 12010100,
							'desc': {
								'nl': 'VERSE KAAS',
								'fr': 'FORMAGE FRAIS'
							}
						},
						'12010101': {
							'id': 12010101,
							'desc': {
								'nl': 'Verse kaas natuur',
								'fr': 'Fromage frais nature'
							}
						},
						'12010102': {
							'id': 12010102,
							'desc': {
								'nl': 'Verse kaas kruiden',
								'fr': 'Fromage frais f.h.'
							}
						},
						'12010103': {
							'id': 12010103,
							'desc': {
								'nl': 'Verse kaas chocolade',
								'fr': 'Fromage frais au chocola'
							}
						},
						'12010104': {
							'id': 12010104,
							'desc': {
								'nl': 'Verse kaas fruit',
								'fr': 'Fromage frais aux fruits'
							}
						},
						'12010109': {
							'id': 12010109,
							'desc': {
								'nl': 'Verse kaas diverse smaken',
								'fr': 'Fromage frais goûts divers'
							}
						},
						'12010300': {
							'id': 12010300,
							'desc': {
								'nl': 'YOGHURT',
								'fr': 'YAOURT'
							}
						},
						'12010301': {
							'id': 12010301,
							'desc': {
								'nl': 'Yoghurt Natuur',
								'fr': 'Yaourt nature'
							}
						},
						'12020000': {
							'id': 12020000,
							'desc': {
								'nl': 'ZACHTE KAAS',
								'fr': 'FROMAGE DOUX'
							}
						},
						'12020100': {
							'id': 12020100,
							'desc': {
								'nl': 'WITTE KORST KAAS',
								'fr': 'FROMAGE À CROÛTE BLANCHE'
							}
						},
						'12020101': {
							'id': 12020101,
							'desc': {
								'nl': 'Brie',
								'fr': 'Brie'
							}
						},
						'12020102': {
							'id': 12020102,
							'desc': {
								'nl': 'Witschimmelkaas',
								'fr': 'From.à croûte blanche special.'
							}
						},
						'12020105': {
							'id': 12020105,
							'desc': {
								'nl': 'Camembert',
								'fr': ''
							}
						},
						'12020200': {
							'id': 12020200,
							'desc': {
								'nl': 'GEWASSEN KORST KAAS',
								'fr': 'FROMAGE À CROÛTE LAVÉE'
							}
						},
						'12020201': {
							'id': 12020201,
							'desc': {
								'nl': 'Gewassen korst kaas',
								'fr': 'Fromage à croûte lavée'
							}
						},
						'12020300': {
							'id': 12020300,
							'desc': {
								'nl': 'GE-ADERDE KAAS',
								'fr': 'FROMAGE VEINÉ'
							}
						},
						'12020301': {
							'id': 12020301,
							'desc': {
								'nl': 'Blauw ge-aderde kaas',
								'fr': 'Fromage veiné de bleu'
							}
						},
						'12020302': {
							'id': 12020302,
							'desc': {
								'nl': 'Blauwe kaas zout-/vetarm',
								'fr': 'From.veiné de bl.pauvre sel/gr'
							}
						},
						'12020400': {
							'id': 12020400,
							'desc': {
								'nl': 'ZACHTE KAAS SPECIALITEITEN',
								'fr': 'FROMAGE DOUX SPÉCIALITÉS'
							}
						},
						'12020401': {
							'id': 12020401,
							'desc': {
								'nl': 'Zachte kaas specialiteiten',
								'fr': 'Fromage doux spécialités'
							}
						},
						'12030000': {
							'id': 12030000,
							'desc': {
								'nl': 'HALF-HARDE KAAS',
								'fr': 'FROMAGE SEMI-DURE'
							}
						},
						'12030100': {
							'id': 12030100,
							'desc': {
								'nl': 'TYPE GOUDA KAAS',
								'fr': 'FROMAGE TYPE GOUDA'
							}
						},
						'12030101': {
							'id': 12030101,
							'desc': {
								'nl': 'Type gouda - jong',
								'fr': 'Type gouda - jeune'
							}
						},
						'12030102': {
							'id': 12030102,
							'desc': {
								'nl': 'Type gouda - belegen',
								'fr': 'Type gouda - mi-vieux'
							}
						},
						'12030103': {
							'id': 12030103,
							'desc': {
								'nl': 'Type gouda - oud/brokkel',
								'fr': 'Type gouda - vieux/trés vieux'
							}
						},
						'12030104': {
							'id': 12030104,
							'desc': {
								'nl': 'Type gouda - zout-/vetarm',
								'fr': 'Type gouda - pauvre en sel/gr.'
							}
						},
						'12030105': {
							'id': 12030105,
							'desc': {
								'nl': 'Type gouda - diverse smaken',
								'fr': 'Type gouda - goûts divers'
							}
						},
						'12030200': {
							'id': 12030200,
							'desc': {
								'nl': 'TYPE ABDIJKAAS',
								'fr': 'TYPE ABBYE'
							}
						},
						'12030201': {
							'id': 12030201,
							'desc': {
								'nl': 'Type abdijkaas',
								'fr': 'Type abbye'
							}
						},
						'12030202': {
							'id': 12030202,
							'desc': {
								'nl': 'Type abdijkaas zout-/vetarm',
								'fr': 'Type abbye pauvre en sel/gr.'
							}
						},
						'12030203': {
							'id': 12030203,
							'desc': {
								'nl': 'Type abdijkaas diverse smaken',
								'fr': 'Type abbye goûts divers'
							}
						},
						'12030300': {
							'id': 12030300,
							'desc': {
								'nl': 'HALF-HARDE KAAS SPECIALITEITEN',
								'fr': 'FROMAGE SEMI-DURE SPÉCIALITÉS'
							}
						},
						'12030301': {
							'id': 12030301,
							'desc': {
								'nl': 'Half harde kaas specialiteiten',
								'fr': 'Fromage semi-dure spécialités'
							}
						},
						'12030302': {
							'id': 12030302,
							'desc': {
								'nl': 'Half harde kaas zout-/vetarm',
								'fr': 'Fromage s.-dure pauvre sel/gr.'
							}
						},
						'12030303': {
							'id': 12030303,
							'desc': {
								'nl': 'Half harde kaas diverse smaken',
								'fr': 'Fromage semi-dure goûts divers'
							}
						},
						'12030304': {
							'id': 12030304,
							'desc': {
								'nl': 'Half harde kaas blauw-geaderd',
								'fr': 'Fromage semi-dure viené'
							}
						},
						'12030305': {
							'id': 12030305,
							'desc': {
								'nl': 'Half harde kaas gewassen korst',
								'fr': ''
							}
						},
						'12030306': {
							'id': 12030306,
							'desc': {
								'nl': 'Raclette',
								'fr': ''
							}
						},
						'12040000': {
							'id': 12040000,
							'desc': {
								'nl': 'HARDE KAAS',
								'fr': 'FROMAGE À PÂTE DURE'
							}
						},
						'12040100': {
							'id': 12040100,
							'desc': {
								'nl': 'HARDE KAAS',
								'fr': 'FROMAGE À PÂTE DURE'
							}
						},
						'12040101': {
							'id': 12040101,
							'desc': {
								'nl': 'Emmental',
								'fr': 'Emmental'
							}
						},
						'12040102': {
							'id': 12040102,
							'desc': {
								'nl': 'Gruyère',
								'fr': 'Gruyère'
							}
						},
						'12040103': {
							'id': 12040103,
							'desc': {
								'nl': 'Diverse harde kaas',
								'fr': 'Fromages dures diverses'
							}
						},
						'12050000': {
							'id': 12050000,
							'desc': {
								'nl': 'SMELTKAAS',
								'fr': 'FROMAGE FONDU'
							}
						},
						'12050100': {
							'id': 12050100,
							'desc': {
								'nl': 'SMELTKAAS',
								'fr': 'FROMAGE FONDU'
							}
						},
						'12050101': {
							'id': 12050101,
							'desc': {
								'nl': 'Smeltkaas',
								'fr': 'Fromage fondu'
							}
						},
						'12060000': {
							'id': 12060000,
							'desc': {
								'nl': 'GEITENKAAS-SCHAPENKAAS',
								'fr': 'FROMAGE DE CHÈVRE/DE BREBIS'
							}
						},
						'12060100': {
							'id': 12060100,
							'desc': {
								'nl': 'GEITENKAAS',
								'fr': 'FROMAGE DE CHÈVRE'
							}
						},
						'12060101': {
							'id': 12060101,
							'desc': {
								'nl': 'Geitenkaas zacht - natuur',
								'fr': 'Fromage de chèvre doux nature'
							}
						},
						'12060102': {
							'id': 12060102,
							'desc': {
								'nl': 'Geitenkaas zacht - fantaisie',
								'fr': 'From.de chèvre doux fantaisie'
							}
						},
						'12060103': {
							'id': 12060103,
							'desc': {
								'nl': 'Geitenkaasjes zacht per stuk',
								'fr': 'From.de chèvr.semi-dure nature'
							}
						},
						'12060104': {
							'id': 12060104,
							'desc': {
								'nl': 'Geitenkaas halfhard - natuur',
								'fr': 'From.de chèvr.semi-dure fantai'
							}
						},
						'12060105': {
							'id': 12060105,
							'desc': {
								'nl': 'Geitenkaas halfhard - fantasie',
								'fr': ''
							}
						},
						'12060200': {
							'id': 12060200,
							'desc': {
								'nl': 'SCHAPENKAAS',
								'fr': 'FROMAGE DE BREBIS'
							}
						},
						'12060201': {
							'id': 12060201,
							'desc': {
								'nl': 'Schapenkaas',
								'fr': 'Fromage de brebis'
							}
						},
						'12060300': {
							'id': 12060300,
							'desc': {
								'nl': 'Feta',
								'fr': ''
							}
						},
						'12060301': {
							'id': 12060301,
							'desc': {
								'nl': 'Feta',
								'fr': ''
							}
						},
						'13000000': {
							'id': 13000000,
							'desc': {
								'nl': 'TRAITEUR',
								'fr': 'TRAITEUR'
							}
						},
						'13010000': {
							'id': 13010000,
							'desc': {
								'nl': 'HAPJES - SNACKS  - TAPAS',
								'fr': 'BOUCHÉES/AMUSE-GUEULE/TAPAS'
							}
						},
						'13010100': {
							'id': 13010100,
							'desc': {
								'nl': 'HAPJES',
								'fr': 'BOUCHÉES'
							}
						},
						'13010101': {
							'id': 13010101,
							'desc': {
								'nl': 'Bladerdeeghapjes',
								'fr': 'Bouchées pâte feuilletée'
							}
						},
						'13010102': {
							'id': 13010102,
							'desc': {
								'nl': 'Krokethapje',
								'fr': 'Amuse-guele croquette'
							}
						},
						'13010103': {
							'id': 13010103,
							'desc': {
								'nl': 'Kiphapjes',
								'fr': 'Bouchées au poulet'
							}
						},
						'13010104': {
							'id': 13010104,
							'desc': {
								'nl': 'Balletjes',
								'fr': ''
							}
						},
						'13010105': {
							'id': 13010105,
							'desc': {
								'nl': 'Hapjes vis',
								'fr': ''
							}
						},
						'13010109': {
							'id': 13010109,
							'desc': {
								'nl': 'Worstjes',
								'fr': 'Bouchées divers'
							}
						},
						'13010200': {
							'id': 13010200,
							'desc': {
								'nl': 'SNACKS',
								'fr': 'AMUSE-GUEULE'
							}
						},
						'13010201': {
							'id': 13010201,
							'desc': {
								'nl': 'Loempia',
								'fr': 'Pâte impérial'
							}
						},
						'13010202': {
							'id': 13010202,
							'desc': {
								'nl': 'Groentetaart - Quiche',
								'fr': 'Tarte aux légumes - Quiche'
							}
						},
						'13010203': {
							'id': 13010203,
							'desc': {
								'nl': 'Pizza',
								'fr': ''
							}
						},
						'13010209': {
							'id': 13010209,
							'desc': {
								'nl': 'Diverse snacks',
								'fr': 'Coupe-faim divers'
							}
						},
						'13010300': {
							'id': 13010300,
							'desc': {
								'nl': 'TAPAS',
								'fr': 'TAPAS'
							}
						},
						'13010301': {
							'id': 13010301,
							'desc': {
								'nl': 'Tapas groenten',
								'fr': 'Tapas légumes'
							}
						},
						'13010302': {
							'id': 13010302,
							'desc': {
								'nl': 'Tapas charcuterie',
								'fr': 'Tapas charcuterie'
							}
						},
						'13010303': {
							'id': 13010303,
							'desc': {
								'nl': 'Tapas  kaas',
								'fr': 'Tapas fromage'
							}
						},
						'13010304': {
							'id': 13010304,
							'desc': {
								'nl': 'Tapas  vis',
								'fr': 'Tapas poisson'
							}
						},
						'13010305': {
							'id': 13010305,
							'desc': {
								'nl': 'Tapas Olijven',
								'fr': 'Tapas Olives'
							}
						},
						'13010306': {
							'id': 13010306,
							'desc': {
								'nl': 'Tapenades',
								'fr': ''
							}
						},
						'13010307': {
							'id': 13010307,
							'desc': {
								'nl': 'Houmous',
								'fr': ''
							}
						},
						'13020000': {
							'id': 13020000,
							'desc': {
								'nl': 'VOORGERECHTEN',
								'fr': ''
							}
						},
						'13020100': {
							'id': 13020100,
							'desc': {
								'nl': 'SOEPEN',
								'fr': ''
							}
						},
						'13020101': {
							'id': 13020101,
							'desc': {
								'nl': 'Soepen',
								'fr': ''
							}
						},
						'13040000': {
							'id': 13040000,
							'desc': {
								'nl': 'MAALTIJDCOMPONENTEN',
								'fr': 'PRODUITS VÉGÉTARIENS'
							}
						},
						'13040100': {
							'id': 13040100,
							'desc': {
								'nl': 'GEGAARD VARKENVLEES',
								'fr': ''
							}
						},
						'13040101': {
							'id': 13040101,
							'desc': {
								'nl': 'Gegaarde varkensdelen',
								'fr': ''
							}
						},
						'13040103': {
							'id': 13040103,
							'desc': {
								'nl': 'Hamburgers',
								'fr': ''
							}
						},
						'13040104': {
							'id': 13040104,
							'desc': {
								'nl': 'Balletjes en soepballetjes',
								'fr': ''
							}
						},
						'13040105': {
							'id': 13040105,
							'desc': {
								'nl': 'Vogelnestjes',
								'fr': ''
							}
						},
						'13040200': {
							'id': 13040200,
							'desc': {
								'nl': 'GEGAARDE WORSTEN - PENSEN',
								'fr': ''
							}
						},
						'13040201': {
							'id': 13040201,
							'desc': {
								'nl': 'Zwarte pensen',
								'fr': ''
							}
						},
						'13040202': {
							'id': 13040202,
							'desc': {
								'nl': 'Witte en Luikse worst',
								'fr': ''
							}
						},
						'13040203': {
							'id': 13040203,
							'desc': {
								'nl': 'Barbecueworsten',
								'fr': ''
							}
						},
						'13040204': {
							'id': 13040204,
							'desc': {
								'nl': 'Frankfurters - Poolse worst',
								'fr': ''
							}
						},
						'13040209': {
							'id': 13040209,
							'desc': {
								'nl': 'Diverse worst',
								'fr': ''
							}
						},
						'13040300': {
							'id': 13040300,
							'desc': {
								'nl': 'GEGAARD KIPPENVLEES',
								'fr': ''
							}
						},
						'13040301': {
							'id': 13040301,
							'desc': {
								'nl': 'Gegaarde kipdelen',
								'fr': ''
							}
						},
						'13040303': {
							'id': 13040303,
							'desc': {
								'nl': 'Gevulde kiprollade',
								'fr': ''
							}
						},
						'13040304': {
							'id': 13040304,
							'desc': {
								'nl': 'Krokante kip',
								'fr': ''
							}
						},
						'13040305': {
							'id': 13040305,
							'desc': {
								'nl': 'Gepaneerd kipproducten',
								'fr': ''
							}
						},
						'13040306': {
							'id': 13040306,
							'desc': {
								'nl': 'Gepaneerde gevulde kip',
								'fr': ''
							}
						},
						'13040308': {
							'id': 13040308,
							'desc': {
								'nl': 'Kipvlees balletjes',
								'fr': ''
							}
						},
						'13040400': {
							'id': 13040400,
							'desc': {
								'nl': 'GEGAARD KALKOENVLEES',
								'fr': ''
							}
						},
						'13040401': {
							'id': 13040401,
							'desc': {
								'nl': 'Gegaarde kalkoendelen',
								'fr': ''
							}
						},
						'13040402': {
							'id': 13040402,
							'desc': {
								'nl': 'Kalkoenschnitzels',
								'fr': ''
							}
						},
						'13040404': {
							'id': 13040404,
							'desc': {
								'nl': 'Gevulde kalkoenproducten',
								'fr': ''
							}
						},
						'13040405': {
							'id': 13040405,
							'desc': {
								'nl': 'Kalkoen balletjes',
								'fr': ''
							}
						},
						'13040500': {
							'id': 13040500,
							'desc': {
								'nl': 'GEGAARD KONIJNENVLEES',
								'fr': ''
							}
						},
						'13040501': {
							'id': 13040501,
							'desc': {
								'nl': 'Gegaarde konijndelen',
								'fr': ''
							}
						},
						'13040600': {
							'id': 13040600,
							'desc': {
								'nl': 'GEGAARDE EENDENVLEES',
								'fr': ''
							}
						},
						'13040601': {
							'id': 13040601,
							'desc': {
								'nl': 'Gegaarde eendenborst',
								'fr': ''
							}
						},
						'13040700': {
							'id': 13040700,
							'desc': {
								'nl': 'PASTA',
								'fr': ''
							}
						},
						'13040701': {
							'id': 13040701,
							'desc': {
								'nl': "Gevulde pasta's",
								'fr': ''
							}
						},
						'13040800': {
							'id': 13040800,
							'desc': {
								'nl': 'BEREIDE GROENTEN',
								'fr': ''
							}
						},
						'13040801': {
							'id': 13040801,
							'desc': {
								'nl': 'Gekookte aardappelen',
								'fr': ''
							}
						},
						'13040802': {
							'id': 13040802,
							'desc': {
								'nl': 'Puree natuur',
								'fr': ''
							}
						},
						'13040804': {
							'id': 13040804,
							'desc': {
								'nl': 'Aardappelkroketten',
								'fr': ''
							}
						},
						'13040805': {
							'id': 13040805,
							'desc': {
								'nl': 'Voorgebakken verse frieten',
								'fr': ''
							}
						},
						'13040810': {
							'id': 13040810,
							'desc': {
								'nl': 'Diverse gegaarde groenten',
								'fr': ''
							}
						},
						'13040900': {
							'id': 13040900,
							'desc': {
								'nl': 'BEREID FRUIT',
								'fr': 'PRODUITS VÉGÉTARIENS'
							}
						},
						'13040901': {
							'id': 13040901,
							'desc': {
								'nl': 'Bereid fruit',
								'fr': 'Hamburgers végétariens'
							}
						},
						'13041500': {
							'id': 13041500,
							'desc': {
								'nl': 'VEGETARISCHE PRODUCTEN',
								'fr': ''
							}
						},
						'13041501': {
							'id': 13041501,
							'desc': {
								'nl': 'Vegetarische vleesvervangers',
								'fr': ''
							}
						},
						'13050000': {
							'id': 13050000,
							'desc': {
								'nl': 'BEREIDE GERECHTEN',
								'fr': 'PLATS PRÉPARÉS'
							}
						},
						'13050100': {
							'id': 13050100,
							'desc': {
								'nl': 'GEVOGELTEGERECHT',
								'fr': 'PLAT PRÉPARÉ AVEC VOLAILLE'
							}
						},
						'13050101': {
							'id': 13050101,
							'desc': {
								'nl': 'Gerecht met kip',
								'fr': 'Plat préparé avec poulet'
							}
						},
						'13050102': {
							'id': 13050102,
							'desc': {
								'nl': 'Videevulling',
								'fr': 'Plat préparé avec dinde'
							}
						},
						'13050103': {
							'id': 13050103,
							'desc': {
								'nl': 'Gerecht met kalkoen',
								'fr': 'Plat préparé avec canard'
							}
						},
						'13050104': {
							'id': 13050104,
							'desc': {
								'nl': 'Gerecht met eend',
								'fr': ''
							}
						},
						'13050200': {
							'id': 13050200,
							'desc': {
								'nl': 'VISGERECHT',
								'fr': 'PLAT PRÉPARÉ AVEC POISSON'
							}
						},
						'13050201': {
							'id': 13050201,
							'desc': {
								'nl': 'Gerecht met zalm',
								'fr': 'Plat préparé avec saumon'
							}
						},
						'13050202': {
							'id': 13050202,
							'desc': {
								'nl': 'gerecht met kabeljauw',
								'fr': 'Plat préparé avec cabillaud'
							}
						},
						'13050203': {
							'id': 13050203,
							'desc': {
								'nl': 'gerecht met tong',
								'fr': 'Plat préparé avec sole'
							}
						},
						'13050204': {
							'id': 13050204,
							'desc': {
								'nl': 'gerecht met schaaldieren',
								'fr': 'Plat préparé avec fruits d.mer'
							}
						},
						'13050205': {
							'id': 13050205,
							'desc': {
								'nl': 'Visgerecht mix',
								'fr': 'Plat préparé avec poisson mix'
							}
						},
						'13050209': {
							'id': 13050209,
							'desc': {
								'nl': 'Gerecht met diverse vis',
								'fr': 'Plat préparé avec poiss.divers'
							}
						},
						'13050300': {
							'id': 13050300,
							'desc': {
								'nl': 'VLEESGERECHT',
								'fr': 'PLAT PRÉPARÉ AVEC VIANDE'
							}
						},
						'13050301': {
							'id': 13050301,
							'desc': {
								'nl': 'Gerecht met varkenvlees',
								'fr': 'Plat préparé avec porc'
							}
						},
						'13050302': {
							'id': 13050302,
							'desc': {
								'nl': 'Gerecht met kalf-rundvlees',
								'fr': 'Plat préparé avec veau-boeuf'
							}
						},
						'13050303': {
							'id': 13050303,
							'desc': {
								'nl': 'Gerecht met konijnvlees',
								'fr': 'Plat préparé avec lapin'
							}
						},
						'13050304': {
							'id': 13050304,
							'desc': {
								'nl': 'Gerecht met lams-schapenvlees',
								'fr': 'Plat préparé avec agneau-mout.'
							}
						},
						'13050305': {
							'id': 13050305,
							'desc': {
								'nl': 'Gerecht met balletjes',
								'fr': 'volaille'
							}
						},
						'13050400': {
							'id': 13050400,
							'desc': {
								'nl': 'PASTAGERECHT Warm',
								'fr': 'PLAT PRÉPARÉ AVEC PÂTES-CHAUD'
							}
						},
						'13050401': {
							'id': 13050401,
							'desc': {
								'nl': 'Lasagne',
								'fr': 'Lasagne'
							}
						},
						'13050402': {
							'id': 13050402,
							'desc': {
								'nl': 'Diverse pastagerechten',
								'fr': 'Plat préparé avec pâtes divers'
							}
						},
						'13050500': {
							'id': 13050500,
							'desc': {
								'nl': 'RIJSTGERECHT',
								'fr': 'PLAT PRÉPARÉ AVEC RIZ'
							}
						},
						'13050501': {
							'id': 13050501,
							'desc': {
								'nl': 'Rijstgerecht',
								'fr': 'Plat préparé avec riz'
							}
						},
						'13050600': {
							'id': 13050600,
							'desc': {
								'nl': 'GROENTEGERECHT',
								'fr': 'PLAT PRÉPARÉ AVEC LÉGUMES'
							}
						},
						'13050601': {
							'id': 13050601,
							'desc': {
								'nl': 'Aardappelgratins',
								'fr': 'Plat préparé avec pom.de terre'
							}
						},
						'13050603': {
							'id': 13050603,
							'desc': {
								'nl': 'Puree met groenten',
								'fr': ''
							}
						},
						'13050609': {
							'id': 13050609,
							'desc': {
								'nl': 'Diverse groentegerechten',
								'fr': ''
							}
						},
						'13050700': {
							'id': 13050700,
							'desc': {
								'nl': 'WILD en EXOTISCH GERECHT',
								'fr': 'PLAT PRÉP. AVEC GIBIER/EXOTIQ.'
							}
						},
						'13050701': {
							'id': 13050701,
							'desc': {
								'nl': 'Wildgerecht',
								'fr': 'Plat préparé avec gibier'
							}
						},
						'13050800': {
							'id': 13050800,
							'desc': {
								'nl': 'FRUITGERECHT',
								'fr': 'PLAT PRÉPARÉ AVEC FRUITS'
							}
						},
						'13050801': {
							'id': 13050801,
							'desc': {
								'nl': 'Fruitgerecht',
								'fr': 'Plat préparé avec fruits'
							}
						},
						'13050900': {
							'id': 13050900,
							'desc': {
								'nl': 'MAALTIJDSALADES MET PASTA',
								'fr': 'SALADES-REPAS'
							}
						},
						'13050901': {
							'id': 13050901,
							'desc': {
								'nl': 'Pasta-salade met vlees',
								'fr': 'Salade-repas avec viande'
							}
						},
						'13050902': {
							'id': 13050902,
							'desc': {
								'nl': 'Pasta-salade met vis',
								'fr': 'Salade-repas avec poisson'
							}
						},
						'13050903': {
							'id': 13050903,
							'desc': {
								'nl': 'Pasta-salade met kip',
								'fr': 'Salade-repas avec poulet'
							}
						},
						'13050904': {
							'id': 13050904,
							'desc': {
								'nl': 'Pasta-salade zonder vlees',
								'fr': 'Salade-repas avec légumes'
							}
						},
						'13090000': {
							'id': 13090000,
							'desc': {
								'nl': 'DESSERTS',
								'fr': 'CONFISERIE'
							}
						},
						'13090100': {
							'id': 13090100,
							'desc': {
								'nl': 'DESSERTS',
								'fr': 'DESSERT'
							}
						},
						'13090101': {
							'id': 13090101,
							'desc': {
								'nl': 'Dessert assorti',
								'fr': 'Pâtisserie'
							}
						},
						'13090102': {
							'id': 13090102,
							'desc': {
								'nl': 'Rijstepap',
								'fr': 'Riz au lait'
							}
						},
						'13090103': {
							'id': 13090103,
							'desc': {
								'nl': 'Choco',
								'fr': "Choco's - mousses"
							}
						},
						'14000000': {
							'id': 14000000,
							'desc': {
								'nl': 'BEENHOUWERIJ',
								'fr': 'VIANDE - FRAÎCHE & CUITE'
							}
						},
						'14010000': {
							'id': 14010000,
							'desc': {
								'nl': 'VARKENVLEES',
								'fr': 'VIANDE DE PORC'
							}
						},
						'14010100': {
							'id': 14010100,
							'desc': {
								'nl': 'RAUW VARKENVLEES',
								'fr': 'VIANDE DE PORC CRUE'
							}
						},
						'14010101': {
							'id': 14010101,
							'desc': {
								'nl': 'Rauwe varkensdelen',
								'fr': 'Parties de porc crues'
							}
						},
						'14010200': {
							'id': 14010200,
							'desc': {
								'nl': 'RAUW BEREID VARKENVLEES',
								'fr': 'VIANDE DE PORC CRUE PRÉPARÉE'
							}
						},
						'14010201': {
							'id': 14010201,
							'desc': {
								'nl': 'Rauw bereide varkensdelen',
								'fr': 'Parties de porc crues prép.'
							}
						},
						'14020000': {
							'id': 14020000,
							'desc': {
								'nl': 'KIPPENVLEES',
								'fr': 'VIANDE DE POULET'
							}
						},
						'14020100': {
							'id': 14020100,
							'desc': {
								'nl': 'RAUW KIPPENVLEES',
								'fr': 'VIANDE DE POULET CRUE'
							}
						},
						'14020102': {
							'id': 14020102,
							'desc': {
								'nl': 'Rauwe versneden kipdelen',
								'fr': 'Parties de poulet crues'
							}
						},
						'14020200': {
							'id': 14020200,
							'desc': {
								'nl': 'RAUW BEREID KIPPENVLEES',
								'fr': 'VIANDE DE POULET CRUE PRÉPARÉE'
							}
						},
						'14020202': {
							'id': 14020202,
							'desc': {
								'nl': 'Rauw bereid versn. kippenvlees',
								'fr': 'Viande de poul.coup.crue prép.'
							}
						},
						'14020203': {
							'id': 14020203,
							'desc': {
								'nl': 'Rauw bereid kipgehaktproducten',
								'fr': 'Prod.de haché poul.crus prép.'
							}
						},
						'14030000': {
							'id': 14030000,
							'desc': {
								'nl': 'KALKOENVLEES',
								'fr': 'VIANDE DE DINDE'
							}
						},
						'14030100': {
							'id': 14030100,
							'desc': {
								'nl': 'RAUW KALKOENVLEES',
								'fr': 'VIANDE DE DINDE CRUE'
							}
						},
						'14030102': {
							'id': 14030102,
							'desc': {
								'nl': 'Rauwe kalkoendelen',
								'fr': 'Parties de dinde crues'
							}
						},
						'14040000': {
							'id': 14040000,
							'desc': {
								'nl': 'PAARDENVLEES',
								'fr': 'VIANDE DE CHEVAL'
							}
						},
						'14040100': {
							'id': 14040100,
							'desc': {
								'nl': 'RAUW PAARDENVLEES',
								'fr': 'VIANDE DE CHEVAL CRUE'
							}
						},
						'14040101': {
							'id': 14040101,
							'desc': {
								'nl': 'Rauw paardenvlees',
								'fr': 'Parties de cheval crue'
							}
						},
						'14050000': {
							'id': 14050000,
							'desc': {
								'nl': 'RUNDVLEES',
								'fr': 'VIANDE DE BOEUF'
							}
						},
						'14050100': {
							'id': 14050100,
							'desc': {
								'nl': 'RAUW RUNDVLEES',
								'fr': 'VIANDE DE BOEUF CRUE'
							}
						},
						'14050102': {
							'id': 14050102,
							'desc': {
								'nl': 'Verse entrecôte',
								'fr': 'Part.de viande de boeuf crue'
							}
						},
						'14050103': {
							'id': 14050103,
							'desc': {
								'nl': 'Entrecôte dry aged',
								'fr': ''
							}
						},
						'14050105': {
							'id': 14050105,
							'desc': {
								'nl': 'Rundvlees filet pur',
								'fr': 'Entrecote'
							}
						},
						'14050200': {
							'id': 14050200,
							'desc': {
								'nl': 'RAUW BEREID RUNDVLEES',
								'fr': 'VIANDE DE BOEUF CRUE PRÉPARÉE'
							}
						},
						'14050201': {
							'id': 14050201,
							'desc': {
								'nl': 'Hamburgervlees',
								'fr': ''
							}
						},
						'14070000': {
							'id': 14070000,
							'desc': {
								'nl': 'LAM/SCHAPENVLEES',
								'fr': "VIANDE D'AGNEAU/MOUTON"
							}
						},
						'14070100': {
							'id': 14070100,
							'desc': {
								'nl': 'RAUW LAM/SCHAPENVLEES',
								'fr': "VIANDE D'AGNEAU/MOUTON CRUE"
							}
						},
						'14070101': {
							'id': 14070101,
							'desc': {
								'nl': 'Rauw lams-schapenvlees',
								'fr': "Parties d'agneau/mouton crues"
							}
						},
						'15000000': {
							'id': 15000000,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'15010000': {
							'id': 15010000,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'15010200': {
							'id': 15010200,
							'desc': {
								'nl': 'BEREIDE VIS',
								'fr': 'POISSON PRÉPARÉ'
							}
						},
						'15010204': {
							'id': 15010204,
							'desc': {
								'nl': 'Opgelegde haring',
								'fr': 'Poisson en conserve-mariné'
							}
						},
						'15010205': {
							'id': 15010205,
							'desc': {
								'nl': 'Haring in saus',
								'fr': 'Poisson en conserve + sauce'
							}
						},
						'15010206': {
							'id': 15010206,
							'desc': {
								'nl': 'Visterrine',
								'fr': 'Terrine de poisson'
							}
						},
						'16000000': {
							'id': 16000000,
							'desc': {
								'nl': 'ATELIER',
								'fr': 'ATELIER'
							}
						},
						'16010000': {
							'id': 16010000,
							'desc': {
								'nl': 'VLEESWAREN',
								'fr': 'SALAISONS'
							}
						},
						'16010100': {
							'id': 16010100,
							'desc': {
								'nl': 'GESNEDEN CHARCUTERIE',
								'fr': 'CHARCUTERIE'
							}
						},
						'16010101': {
							'id': 16010101,
							'desc': {
								'nl': 'Gesneden ham',
								'fr': 'Jambon'
							}
						},
						'16010102': {
							'id': 16010102,
							'desc': {
								'nl': 'Gesneden spek',
								'fr': 'Lard'
							}
						},
						'16010103': {
							'id': 16010103,
							'desc': {
								'nl': 'Gesneden salami',
								'fr': ''
							}
						},
						'16010104': {
							'id': 16010104,
							'desc': {
								'nl': 'Gesneden kip & kalkoen',
								'fr': ''
							}
						},
						'16010105': {
							'id': 16010105,
							'desc': {
								'nl': 'Gesneden paardenrookvlees',
								'fr': ''
							}
						},
						'16010200': {
							'id': 16010200,
							'desc': {
								'nl': 'GEGAARD VLEES',
								'fr': 'VIANDE PRÉPARÉE'
							}
						},
						'16010201': {
							'id': 16010201,
							'desc': {
								'nl': 'Gekookte varkensdelen',
								'fr': 'Viande de porc cuite'
							}
						},
						'16010202': {
							'id': 16010202,
							'desc': {
								'nl': 'Gegaard versneden kipvlees',
								'fr': 'Viande de poulet cuit'
							}
						},
						'16010203': {
							'id': 16010203,
							'desc': {
								'nl': 'Pita/Kebabvlees',
								'fr': 'Viande de dinde cuite'
							}
						},
						'16010204': {
							'id': 16010204,
							'desc': {
								'nl': 'Gekookte tong',
								'fr': ''
							}
						},
						'16020000': {
							'id': 16020000,
							'desc': {
								'nl': 'ZUIVEL - SPREADS - EIEREN',
								'fr': 'PRODUIT LAITIERS'
							}
						},
						'16020100': {
							'id': 16020100,
							'desc': {
								'nl': 'KAAS',
								'fr': 'FROMAGE'
							}
						},
						'16020101': {
							'id': 16020101,
							'desc': {
								'nl': 'Gesneden kaas',
								'fr': 'Fromage tranché'
							}
						},
						'16020102': {
							'id': 16020102,
							'desc': {
								'nl': 'Geraspte kaas',
								'fr': 'Fromage rapé'
							}
						},
						'16020103': {
							'id': 16020103,
							'desc': {
								'nl': 'Hotelblok',
								'fr': 'Fromage bloc'
							}
						},
						'16020104': {
							'id': 16020104,
							'desc': {
								'nl': 'Verse Kaas',
								'fr': 'Fromage frais'
							}
						},
						'16020105': {
							'id': 16020105,
							'desc': {
								'nl': 'Mozzarella - Feta',
								'fr': 'Mozzarella - feta'
							}
						},
						'16020106': {
							'id': 16020106,
							'desc': {
								'nl': 'Smeerkaas',
								'fr': 'Fromage fondu'
							}
						},
						'16020200': {
							'id': 16020200,
							'desc': {
								'nl': 'ROOM - BOTER - MARGARINE',
								'fr': 'CRÈME FRAÎCHE'
							}
						},
						'16020201': {
							'id': 16020201,
							'desc': {
								'nl': 'Room',
								'fr': 'Crème fraîche'
							}
						},
						'16020202': {
							'id': 16020202,
							'desc': {
								'nl': 'Boter blok en miniporties',
								'fr': ''
							}
						},
						'16020203': {
							'id': 16020203,
							'desc': {
								'nl': 'Margarine',
								'fr': 'Lait'
							}
						},
						'16020300': {
							'id': 16020300,
							'desc': {
								'nl': 'EIEREN',
								'fr': ''
							}
						},
						'16020301': {
							'id': 16020301,
							'desc': {
								'nl': 'Verse eieren',
								'fr': ''
							}
						},
						'16020302': {
							'id': 16020302,
							'desc': {
								'nl': 'Vloeibare eieren',
								'fr': ''
							}
						},
						'16020303': {
							'id': 16020303,
							'desc': {
								'nl': 'Gekookte eieren',
								'fr': ''
							}
						},
						'16020800': {
							'id': 16020800,
							'desc': {
								'nl': 'SPREADS',
								'fr': ''
							}
						},
						'16020805': {
							'id': 16020805,
							'desc': {
								'nl': 'Choco',
								'fr': ''
							}
						},
						'16020806': {
							'id': 16020806,
							'desc': {
								'nl': 'Konfituur- siroop',
								'fr': ''
							}
						},
						'16030000': {
							'id': 16030000,
							'desc': {
								'nl': 'GROENTEN EN FRUIT',
								'fr': 'LÉGUMES ET FRUITS'
							}
						},
						'16030100': {
							'id': 16030100,
							'desc': {
								'nl': 'GROENTEN',
								'fr': 'LÉGUMES'
							}
						},
						'16030101': {
							'id': 16030101,
							'desc': {
								'nl': 'Diepvries Greenyard-Bonduelle',
								'fr': 'Légumes crus'
							}
						},
						'16030102': {
							'id': 16030102,
							'desc': {
								'nl': 'Diepvriesgroenten Ardo',
								'fr': 'Légumes cuisinés'
							}
						},
						'16030103': {
							'id': 16030103,
							'desc': {
								'nl': 'Opgelegde groenten-kondimenten',
								'fr': 'Légumes en conserve-condiments'
							}
						},
						'16030104': {
							'id': 16030104,
							'desc': {
								'nl': 'Vers bereide groenten',
								'fr': 'Légumes préparés'
							}
						},
						'16040000': {
							'id': 16040000,
							'desc': {
								'nl': 'SAUSEN',
								'fr': 'SAUCES'
							}
						},
						'16040100': {
							'id': 16040100,
							'desc': {
								'nl': 'KOUDE SAUSEN',
								'fr': 'SAUCES FROIDES'
							}
						},
						'16040101': {
							'id': 16040101,
							'desc': {
								'nl': 'Mayonaise',
								'fr': 'Mayonaise'
							}
						},
						'16040102': {
							'id': 16040102,
							'desc': {
								'nl': 'Dressing',
								'fr': 'Dressing'
							}
						},
						'16040103': {
							'id': 16040103,
							'desc': {
								'nl': 'Looksaus',
								'fr': "Sauce à base de l'huile"
							}
						},
						'16040104': {
							'id': 16040104,
							'desc': {
								'nl': 'Preparaat',
								'fr': 'Sauce préparée'
							}
						},
						'16040105': {
							'id': 16040105,
							'desc': {
								'nl': 'Basis tomaat',
								'fr': 'Sauce à base de tomate'
							}
						},
						'16040106': {
							'id': 16040106,
							'desc': {
								'nl': 'Barbecuesaus',
								'fr': 'Sauce Barbecue'
							}
						},
						'16040107': {
							'id': 16040107,
							'desc': {
								'nl': 'Béarnaisesaus',
								'fr': 'Sauce vinaigrette'
							}
						},
						'16040110': {
							'id': 16040110,
							'desc': {
								'nl': 'Tartaarsaus',
								'fr': 'Sauce tartare'
							}
						},
						'16040111': {
							'id': 16040111,
							'desc': {
								'nl': 'Currysaus',
								'fr': 'Sauce curry'
							}
						},
						'16040112': {
							'id': 16040112,
							'desc': {
								'nl': 'Cocktailsaus',
								'fr': 'Sauce cocktail'
							}
						},
						'16040113': {
							'id': 16040113,
							'desc': {
								'nl': 'Mosterd',
								'fr': ''
							}
						},
						'16040114': {
							'id': 16040114,
							'desc': {
								'nl': 'Ketchup',
								'fr': ''
							}
						},
						'16040115': {
							'id': 16040115,
							'desc': {
								'nl': 'Martinosaus',
								'fr': ''
							}
						},
						'16040120': {
							'id': 16040120,
							'desc': {
								'nl': 'Mini-cups',
								'fr': 'Mini-cups'
							}
						},
						'16040121': {
							'id': 16040121,
							'desc': {
								'nl': 'Sausen in knijpfles',
								'fr': ''
							}
						},
						'16040126': {
							'id': 16040126,
							'desc': {
								'nl': 'Vinaigrette',
								'fr': ''
							}
						},
						'16040130': {
							'id': 16040130,
							'desc': {
								'nl': 'Diverse sausen',
								'fr': 'Sauces divers'
							}
						},
						'16040200': {
							'id': 16040200,
							'desc': {
								'nl': 'CULINAIRE SAUSEN',
								'fr': 'SAUCES CULINAIRES'
							}
						},
						'16040201': {
							'id': 16040201,
							'desc': {
								'nl': 'Culinaire sausen - Charles',
								'fr': "Sauces culinaires Cock's"
							}
						},
						'16040202': {
							'id': 16040202,
							'desc': {
								'nl': 'Culinaire sausen - Maiski',
								'fr': 'Sauces culinaires Maiski'
							}
						},
						'16040203': {
							'id': 16040203,
							'desc': {
								'nl': 'Culinaire sausen - Jermayo',
								'fr': 'Sauces culinaires Jermayo'
							}
						},
						'16040204': {
							'id': 16040204,
							'desc': {
								'nl': 'Culinaire sausen - Verstegen',
								'fr': 'Sauces culinaires Verstegen'
							}
						},
						'16040205': {
							'id': 16040205,
							'desc': {
								'nl': 'Culinaire sausen - Rejo-Degens',
								'fr': 'Sauces cul.Degens-Intertaste'
							}
						},
						'16040206': {
							'id': 16040206,
							'desc': {
								'nl': 'Culinaire sausen - Hamal-Salsa',
								'fr': 'Sauces culinaires Salsa'
							}
						},
						'16040207': {
							'id': 16040207,
							'desc': {
								'nl': 'Culinaire sausen - M.Mathieu',
								'fr': 'Sauces culinaires Manna'
							}
						},
						'16040208': {
							'id': 16040208,
							'desc': {
								'nl': 'Culinaire sausen - Knorr',
								'fr': 'Sauces culinaires Culinor'
							}
						},
						'16040209': {
							'id': 16040209,
							'desc': {
								'nl': 'Culinaire sausen - Cookking',
								'fr': 'Sauces culinaires Rejo'
							}
						},
						'16040210': {
							'id': 16040210,
							'desc': {
								'nl': 'Culinaire sausen - Delino',
								'fr': 'Sauces culinaires Delino'
							}
						},
						'16040213': {
							'id': 16040213,
							'desc': {
								'nl': 'Culinaire sausen - Altoni',
								'fr': ''
							}
						},
						'16040214': {
							'id': 16040214,
							'desc': {
								'nl': 'Culinaire saus - Smiling cook',
								'fr': ''
							}
						},
						'16040220': {
							'id': 16040220,
							'desc': {
								'nl': 'Culinaire saus - Diverse',
								'fr': ''
							}
						},
						'16050000': {
							'id': 16050000,
							'desc': {
								'nl': 'DEEGWAREN - GRANEN',
								'fr': 'PÂTES'
							}
						},
						'16050100': {
							'id': 16050100,
							'desc': {
								'nl': 'DEEGWAREN',
								'fr': 'PÂTES'
							}
						},
						'16050101': {
							'id': 16050101,
							'desc': {
								'nl': 'Deegwaren DV Foods',
								'fr': 'Pâtes'
							}
						},
						'16050102': {
							'id': 16050102,
							'desc': {
								'nl': 'Deegwaren Noirhomme',
								'fr': 'Pâtes'
							}
						},
						'16050104': {
							'id': 16050104,
							'desc': {
								'nl': 'Toast',
								'fr': ''
							}
						},
						'16050105': {
							'id': 16050105,
							'desc': {
								'nl': 'Bodems - pittabroodjes',
								'fr': ''
							}
						},
						'16050106': {
							'id': 16050106,
							'desc': {
								'nl': 'Pasta Altoni',
								'fr': ''
							}
						},
						'16050107': {
							'id': 16050107,
							'desc': {
								'nl': 'Pasta The smiling Cook',
								'fr': ''
							}
						},
						'16050108': {
							'id': 16050108,
							'desc': {
								'nl': 'Pasta Knorr',
								'fr': ''
							}
						},
						'16050109': {
							'id': 16050109,
							'desc': {
								'nl': 'Pasta diverse leverancier',
								'fr': ''
							}
						},
						'16050200': {
							'id': 16050200,
							'desc': {
								'nl': 'Granen - gewassen',
								'fr': ''
							}
						},
						'16050201': {
							'id': 16050201,
							'desc': {
								'nl': 'Granen',
								'fr': ''
							}
						},
						'16050202': {
							'id': 16050202,
							'desc': {
								'nl': 'Gewassen',
								'fr': ''
							}
						},
						'16060000': {
							'id': 16060000,
							'desc': {
								'nl': 'BAKKERIJGRONDSTOFFEN',
								'fr': 'OEUFS'
							}
						},
						'16060200': {
							'id': 16060200,
							'desc': {
								'nl': 'Bakkerijgrondstoffen',
								'fr': ''
							}
						},
						'16060201': {
							'id': 16060201,
							'desc': {
								'nl': 'Dessert',
								'fr': ''
							}
						},
						'16070000': {
							'id': 16070000,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'16070100': {
							'id': 16070100,
							'desc': {
								'nl': 'DIVERSE VIS',
								'fr': 'POISSON CUIT'
							}
						},
						'16070101': {
							'id': 16070101,
							'desc': {
								'nl': 'Surimi',
								'fr': 'Surimi'
							}
						},
						'16070102': {
							'id': 16070102,
							'desc': {
								'nl': 'Tonijn',
								'fr': 'Thon'
							}
						},
						'16070103': {
							'id': 16070103,
							'desc': {
								'nl': 'Tong',
								'fr': 'FILET DE SOLE'
							}
						},
						'16070104': {
							'id': 16070104,
							'desc': {
								'nl': 'Krab',
								'fr': ''
							}
						},
						'16070105': {
							'id': 16070105,
							'desc': {
								'nl': 'Zalm',
								'fr': ''
							}
						},
						'16070106': {
							'id': 16070106,
							'desc': {
								'nl': 'Kabeljauw',
								'fr': ''
							}
						},
						'16070110': {
							'id': 16070110,
							'desc': {
								'nl': 'Schaaldieren',
								'fr': ''
							}
						},
						'16070111': {
							'id': 16070111,
							'desc': {
								'nl': 'Mosselen',
								'fr': ''
							}
						},
						'16070112': {
							'id': 16070112,
							'desc': {
								'nl': 'Ansjovis',
								'fr': ''
							}
						},
						'16080000': {
							'id': 16080000,
							'desc': {
								'nl': 'VETSTOFFEN',
								'fr': 'ADDIT.DE FABRIC.DEGENS-WYCKO'
							}
						},
						'16080100': {
							'id': 16080100,
							'desc': {
								'nl': 'VETSTOFFEN',
								'fr': 'BASE POUR SAUCE/SOUPE/BOUILLON'
							}
						},
						'16080101': {
							'id': 16080101,
							'desc': {
								'nl': 'Plantaardige Olie',
								'fr': 'Base pour sauce'
							}
						},
						'16080102': {
							'id': 16080102,
							'desc': {
								'nl': 'Vetstof',
								'fr': 'Fonds de soupe'
							}
						},
						'16090000': {
							'id': 16090000,
							'desc': {
								'nl': 'HULPSTOFFEN VERSTEGEN',
								'fr': 'ADDIT.DE FABRIC.DIV.FOURNISS.'
							}
						},
						'16090100': {
							'id': 16090100,
							'desc': {
								'nl': 'VERSTEGEN',
								'fr': 'VERSTEGEN'
							}
						},
						'16090101': {
							'id': 16090101,
							'desc': {
								'nl': 'Marinades',
								'fr': 'Marinades,sauces,art.liquides'
							}
						},
						'16090102': {
							'id': 16090102,
							'desc': {
								'nl': 'World grill mix',
								'fr': 'Herbes,mélanges,articles div.'
							}
						},
						'16090103': {
							'id': 16090103,
							'desc': {
								'nl': 'Spice mix',
								'fr': ''
							}
						},
						'16090104': {
							'id': 16090104,
							'desc': {
								'nl': 'Specerijen-kruidenmix',
								'fr': ''
							}
						},
						'16090107': {
							'id': 16090107,
							'desc': {
								'nl': 'decoratiemix',
								'fr': ''
							}
						},
						'16090108': {
							'id': 16090108,
							'desc': {
								'nl': 'budgetmix',
								'fr': ''
							}
						},
						'16090109': {
							'id': 16090109,
							'desc': {
								'nl': 'Schnitzelmix',
								'fr': ''
							}
						},
						'16090110': {
							'id': 16090110,
							'desc': {
								'nl': 'Worstmix',
								'fr': ''
							}
						},
						'16090111': {
							'id': 16090111,
							'desc': {
								'nl': 'Mix voor gerechten',
								'fr': ''
							}
						},
						'16090112': {
							'id': 16090112,
							'desc': {
								'nl': 'Diverse mix voor vlees',
								'fr': ''
							}
						},
						'16090113': {
							'id': 16090113,
							'desc': {
								'nl': 'Charcuteriekruiden',
								'fr': ''
							}
						},
						'16090115': {
							'id': 16090115,
							'desc': {
								'nl': "Kumar's",
								'fr': ''
							}
						},
						'16090120': {
							'id': 16090120,
							'desc': {
								'nl': 'Enkelvoudige kruiden',
								'fr': ''
							}
						},
						'16090121': {
							'id': 16090121,
							'desc': {
								'nl': 'Vriesgedroogde kruiden',
								'fr': ''
							}
						},
						'16090125': {
							'id': 16090125,
							'desc': {
								'nl': 'Vero',
								'fr': ''
							}
						},
						'16090130': {
							'id': 16090130,
							'desc': {
								'nl': 'Paneermeel-mix',
								'fr': ''
							}
						},
						'16090135': {
							'id': 16090135,
							'desc': {
								'nl': 'Overige',
								'fr': ''
							}
						},
						'16100000': {
							'id': 16100000,
							'desc': {
								'nl': 'HULPSTOFFEN KNORR',
								'fr': 'GRAISSES ET HUILES'
							}
						},
						'16100100': {
							'id': 16100100,
							'desc': {
								'nl': 'SOEPEN',
								'fr': 'GRAISSES ANIMALES'
							}
						},
						'16100101': {
							'id': 16100101,
							'desc': {
								'nl': 'Soepen',
								'fr': 'Saindoux'
							}
						},
						'16100102': {
							'id': 16100102,
							'desc': {
								'nl': '1-2-3 soepen',
								'fr': ''
							}
						},
						'16100103': {
							'id': 16100103,
							'desc': {
								'nl': 'Soepen superieur',
								'fr': ''
							}
						},
						'16100104': {
							'id': 16100104,
							'desc': {
								'nl': 'Soep cuisine - zak',
								'fr': ''
							}
						},
						'16100106': {
							'id': 16100106,
							'desc': {
								'nl': 'Soep blik',
								'fr': ''
							}
						},
						'16100200': {
							'id': 16100200,
							'desc': {
								'nl': 'SAUSEN',
								'fr': ''
							}
						},
						'16100201': {
							'id': 16100201,
							'desc': {
								'nl': 'Basissausen',
								'fr': ''
							}
						},
						'16100202': {
							'id': 16100202,
							'desc': {
								'nl': 'Afgeleide sausen',
								'fr': ''
							}
						},
						'16100203': {
							'id': 16100203,
							'desc': {
								'nl': 'Mixen',
								'fr': ''
							}
						},
						'16100204': {
							'id': 16100204,
							'desc': {
								'nl': 'Gourmet sausen',
								'fr': ''
							}
						},
						'16100210': {
							'id': 16100210,
							'desc': {
								'nl': 'Jus',
								'fr': ''
							}
						},
						'16100212': {
							'id': 16100212,
							'desc': {
								'nl': 'Koude basis sausen',
								'fr': ''
							}
						},
						'16100300': {
							'id': 16100300,
							'desc': {
								'nl': 'ROUX',
								'fr': ''
							}
						},
						'16100301': {
							'id': 16100301,
							'desc': {
								'nl': 'Roux',
								'fr': ''
							}
						},
						'16100302': {
							'id': 16100302,
							'desc': {
								'nl': 'Fonds',
								'fr': ''
							}
						},
						'16100306': {
							'id': 16100306,
							'desc': {
								'nl': 'Sausbinders',
								'fr': ''
							}
						},
						'16100400': {
							'id': 16100400,
							'desc': {
								'nl': 'SMAAKMAKERS',
								'fr': ''
							}
						},
						'16100401': {
							'id': 16100401,
							'desc': {
								'nl': 'Primerba',
								'fr': ''
							}
						},
						'16100402': {
							'id': 16100402,
							'desc': {
								'nl': 'Specerijen purees',
								'fr': ''
							}
						},
						'16100403': {
							'id': 16100403,
							'desc': {
								'nl': 'Aromat',
								'fr': ''
							}
						},
						'16100404': {
							'id': 16100404,
							'desc': {
								'nl': 'Glacering voor groenten',
								'fr': ''
							}
						},
						'16100405': {
							'id': 16100405,
							'desc': {
								'nl': 'Bereidingen',
								'fr': ''
							}
						},
						'16100406': {
							'id': 16100406,
							'desc': {
								'nl': 'Intensive flavours',
								'fr': ''
							}
						},
						'16100500': {
							'id': 16100500,
							'desc': {
								'nl': 'BOULLIONS',
								'fr': ''
							}
						},
						'16100501': {
							'id': 16100501,
							'desc': {
								'nl': 'Bouillons in pasta',
								'fr': ''
							}
						},
						'16100502': {
							'id': 16100502,
							'desc': {
								'nl': 'Bouillons in poeder',
								'fr': ''
							}
						},
						'16100503': {
							'id': 16100503,
							'desc': {
								'nl': 'Bouillons tablet of gelei',
								'fr': ''
							}
						},
						'16100504': {
							'id': 16100504,
							'desc': {
								'nl': 'Bouillons essentials',
								'fr': ''
							}
						},
						'16100505': {
							'id': 16100505,
							'desc': {
								'nl': 'Bouillons geconcentreerd',
								'fr': ''
							}
						},
						'16100600': {
							'id': 16100600,
							'desc': {
								'nl': 'COLLEZIONE ITALIA',
								'fr': ''
							}
						},
						'16100602': {
							'id': 16100602,
							'desc': {
								'nl': 'Tomaatproducten',
								'fr': ''
							}
						},
						'16100700': {
							'id': 16100700,
							'desc': {
								'nl': 'SALADES',
								'fr': ''
							}
						},
						'16100703': {
							'id': 16100703,
							'desc': {
								'nl': "Hellman's sandwich",
								'fr': ''
							}
						},
						'16100704': {
							'id': 16100704,
							'desc': {
								'nl': 'Salad mix',
								'fr': ''
							}
						},
						'16100800': {
							'id': 16100800,
							'desc': {
								'nl': 'ALSA DESSERT',
								'fr': ''
							}
						},
						'16120000': {
							'id': 16120000,
							'desc': {
								'nl': 'HULPSTOFFEN SOLINA DEGENS',
								'fr': ''
							}
						},
						'16120100': {
							'id': 16120100,
							'desc': {
								'nl': 'AROMA',
								'fr': ''
							}
						},
						'16120101': {
							'id': 16120101,
							'desc': {
								'nl': 'Aroma',
								'fr': ''
							}
						},
						'16120200': {
							'id': 16120200,
							'desc': {
								'nl': 'MARINADES',
								'fr': ''
							}
						},
						'16120201': {
							'id': 16120201,
							'desc': {
								'nl': 'Marinade op oliebasis',
								'fr': ''
							}
						},
						'16120202': {
							'id': 16120202,
							'desc': {
								'nl': 'Marinade op waterbasis',
								'fr': ''
							}
						},
						'16120300': {
							'id': 16120300,
							'desc': {
								'nl': 'PANEERMEEL',
								'fr': ''
							}
						},
						'16120301': {
							'id': 16120301,
							'desc': {
								'nl': 'Paneermeel',
								'fr': ''
							}
						},
						'16120400': {
							'id': 16120400,
							'desc': {
								'nl': 'SAUSEN',
								'fr': ''
							}
						},
						'16120401': {
							'id': 16120401,
							'desc': {
								'nl': 'Sausen basis',
								'fr': ''
							}
						},
						'16120500': {
							'id': 16120500,
							'desc': {
								'nl': 'KRUIDEN',
								'fr': ''
							}
						},
						'16120501': {
							'id': 16120501,
							'desc': {
								'nl': 'Enkelvoudige kruiden',
								'fr': ''
							}
						},
						'16120502': {
							'id': 16120502,
							'desc': {
								'nl': 'Kruiden basis',
								'fr': ''
							}
						},
						'16120503': {
							'id': 16120503,
							'desc': {
								'nl': 'Diverse kruiden',
								'fr': ''
							}
						},
						'16120504': {
							'id': 16120504,
							'desc': {
								'nl': 'Gehaktkruiden',
								'fr': ''
							}
						},
						'16120505': {
							'id': 16120505,
							'desc': {
								'nl': 'Gevogeltekruiden',
								'fr': ''
							}
						},
						'16120510': {
							'id': 16120510,
							'desc': {
								'nl': 'Zakjes kruiden',
								'fr': ''
							}
						},
						'16120600': {
							'id': 16120600,
							'desc': {
								'nl': 'PRODUCTIE-HULPSROFFEN',
								'fr': ''
							}
						},
						'16120601': {
							'id': 16120601,
							'desc': {
								'nl': 'Pekelmix',
								'fr': ''
							}
						},
						'16120602': {
							'id': 16120602,
							'desc': {
								'nl': 'Kleurstabilisatie',
								'fr': ''
							}
						},
						'16120603': {
							'id': 16120603,
							'desc': {
								'nl': 'Diverse hulpstoffen',
								'fr': ''
							}
						},
						'16130000': {
							'id': 16130000,
							'desc': {
								'nl': 'HULPSTOFFEN SOLINA REJO',
								'fr': ''
							}
						},
						'16130100': {
							'id': 16130100,
							'desc': {
								'nl': 'BIJGERECHTEN',
								'fr': ''
							}
						},
						'16130101': {
							'id': 16130101,
							'desc': {
								'nl': 'Aardappelpoeder',
								'fr': ''
							}
						},
						'16130103': {
							'id': 16130103,
							'desc': {
								'nl': 'Rijst',
								'fr': ''
							}
						},
						'16130104': {
							'id': 16130104,
							'desc': {
								'nl': 'Poedersoepen',
								'fr': ''
							}
						},
						'16130105': {
							'id': 16130105,
							'desc': {
								'nl': 'Dessert',
								'fr': ''
							}
						},
						'16130200': {
							'id': 16130200,
							'desc': {
								'nl': 'BOUILLON',
								'fr': ''
							}
						},
						'16130201': {
							'id': 16130201,
							'desc': {
								'nl': 'Pasta',
								'fr': ''
							}
						},
						'16130202': {
							'id': 16130202,
							'desc': {
								'nl': 'Poeder',
								'fr': ''
							}
						},
						'16130300': {
							'id': 16130300,
							'desc': {
								'nl': 'PRODUCTIE HULPSTOFFEN',
								'fr': ''
							}
						},
						'16130301': {
							'id': 16130301,
							'desc': {
								'nl': 'Cutter- en menghulpstoffen',
								'fr': ''
							}
						},
						'16130302': {
							'id': 16130302,
							'desc': {
								'nl': 'Darmen',
								'fr': ''
							}
						},
						'16130303': {
							'id': 16130303,
							'desc': {
								'nl': 'Gelatine',
								'fr': ''
							}
						},
						'16130304': {
							'id': 16130304,
							'desc': {
								'nl': 'Zout',
								'fr': ''
							}
						},
						'16130305': {
							'id': 16130305,
							'desc': {
								'nl': 'Suikers',
								'fr': ''
							}
						},
						'16130306': {
							'id': 16130306,
							'desc': {
								'nl': 'Vegetarisch',
								'fr': ''
							}
						},
						'16130307': {
							'id': 16130307,
							'desc': {
								'nl': 'Zetmeel & vezels',
								'fr': ''
							}
						},
						'16130308': {
							'id': 16130308,
							'desc': {
								'nl': 'smaakversterkers',
								'fr': ''
							}
						},
						'16130309': {
							'id': 16130309,
							'desc': {
								'nl': 'Pekelen-roken',
								'fr': ''
							}
						},
						'16130400': {
							'id': 16130400,
							'desc': {
								'nl': 'KRUIDEN',
								'fr': ''
							}
						},
						'16130401': {
							'id': 16130401,
							'desc': {
								'nl': 'Culinaire kruiden',
								'fr': ''
							}
						},
						'16130402': {
							'id': 16130402,
							'desc': {
								'nl': 'Dipper kruiden',
								'fr': ''
							}
						},
						'16130403': {
							'id': 16130403,
							'desc': {
								'nl': 'Diverse kruiden',
								'fr': ''
							}
						},
						'16130404': {
							'id': 16130404,
							'desc': {
								'nl': 'Kruiden basis',
								'fr': ''
							}
						},
						'16130405': {
							'id': 16130405,
							'desc': {
								'nl': 'Gevogelte kruiden',
								'fr': ''
							}
						},
						'16130406': {
							'id': 16130406,
							'desc': {
								'nl': 'Charcuterie kruiden',
								'fr': ''
							}
						},
						'16130407': {
							'id': 16130407,
							'desc': {
								'nl': 'Vlees kruiden',
								'fr': ''
							}
						},
						'16130408': {
							'id': 16130408,
							'desc': {
								'nl': 'Wereldsmaken',
								'fr': ''
							}
						},
						'16130409': {
							'id': 16130409,
							'desc': {
								'nl': 'Worst-gehaktkruiden',
								'fr': ''
							}
						},
						'16130410': {
							'id': 16130410,
							'desc': {
								'nl': 'Zakjes kruiden',
								'fr': ''
							}
						},
						'16130500': {
							'id': 16130500,
							'desc': {
								'nl': 'PANEERMEEL',
								'fr': ''
							}
						},
						'16130501': {
							'id': 16130501,
							'desc': {
								'nl': 'Batter',
								'fr': ''
							}
						},
						'16130502': {
							'id': 16130502,
							'desc': {
								'nl': 'Gekruid paneermeel',
								'fr': ''
							}
						},
						'16130503': {
							'id': 16130503,
							'desc': {
								'nl': 'Gekruid paneermeel + deco',
								'fr': ''
							}
						},
						'16130504': {
							'id': 16130504,
							'desc': {
								'nl': 'Instant paneermeel',
								'fr': ''
							}
						},
						'16130505': {
							'id': 16130505,
							'desc': {
								'nl': 'Paneermeel',
								'fr': ''
							}
						},
						'16130506': {
							'id': 16130506,
							'desc': {
								'nl': 'Paneermeel+deco',
								'fr': ''
							}
						},
						'16130507': {
							'id': 16130507,
							'desc': {
								'nl': 'Glutenvrij',
								'fr': ''
							}
						},
						'16130600': {
							'id': 16130600,
							'desc': {
								'nl': 'MARINADES',
								'fr': ''
							}
						},
						'16130601': {
							'id': 16130601,
							'desc': {
								'nl': 'Marinades oliebasis',
								'fr': ''
							}
						},
						'16130602': {
							'id': 16130602,
							'desc': {
								'nl': 'Marinades waterbasis',
								'fr': ''
							}
						},
						'16130700': {
							'id': 16130700,
							'desc': {
								'nl': 'SAUSEN',
								'fr': ''
							}
						},
						'16130701': {
							'id': 16130701,
							'desc': {
								'nl': 'Basissaus',
								'fr': ''
							}
						},
						'16130702': {
							'id': 16130702,
							'desc': {
								'nl': 'Concentraat',
								'fr': ''
							}
						},
						'16130704': {
							'id': 16130704,
							'desc': {
								'nl': 'dressing',
								'fr': ''
							}
						},
						'16130705': {
							'id': 16130705,
							'desc': {
								'nl': 'Geëmulgeerde sausen',
								'fr': ''
							}
						},
						'16130706': {
							'id': 16130706,
							'desc': {
								'nl': 'Poeder sausen',
								'fr': ''
							}
						},
						'16130707': {
							'id': 16130707,
							'desc': {
								'nl': 'Glaze',
								'fr': ''
							}
						},
						'16130708': {
							'id': 16130708,
							'desc': {
								'nl': 'Wereldsausen',
								'fr': ''
							}
						},
						'16130800': {
							'id': 16130800,
							'desc': {
								'nl': 'KLEUR - BEWAARMIDDELEN',
								'fr': ''
							}
						},
						'16130801': {
							'id': 16130801,
							'desc': {
								'nl': 'Enkelvoudige kleurmiddelen',
								'fr': ''
							}
						},
						'16130802': {
							'id': 16130802,
							'desc': {
								'nl': 'Mix bewaarmiddelen',
								'fr': ''
							}
						},
						'16130803': {
							'id': 16130803,
							'desc': {
								'nl': 'Mix kleurmiddelen',
								'fr': ''
							}
						},
						'16130804': {
							'id': 16130804,
							'desc': {
								'nl': 'Mix kleur- en bewaarmiddelen',
								'fr': ''
							}
						},
						'16130900': {
							'id': 16130900,
							'desc': {
								'nl': 'DELICATESSEN & CONDIMENTEN',
								'fr': ''
							}
						},
						'16130901': {
							'id': 16130901,
							'desc': {
								'nl': 'Delicatessen & Condimenten',
								'fr': ''
							}
						},
						'16150000': {
							'id': 16150000,
							'desc': {
								'nl': 'HULPSTOFFEN DIV. LEVERANCIERS',
								'fr': ''
							}
						},
						'16150100': {
							'id': 16150100,
							'desc': {
								'nl': 'MARINADES',
								'fr': ''
							}
						},
						'16150101': {
							'id': 16150101,
							'desc': {
								'nl': 'Marinades Jermayo',
								'fr': ''
							}
						},
						'16150200': {
							'id': 16150200,
							'desc': {
								'nl': 'ZOUT',
								'fr': ''
							}
						},
						'16150201': {
							'id': 16150201,
							'desc': {
								'nl': 'Zout',
								'fr': ''
							}
						},
						'16150400': {
							'id': 16150400,
							'desc': {
								'nl': 'PANEERMEEL',
								'fr': ''
							}
						},
						'16150401': {
							'id': 16150401,
							'desc': {
								'nl': 'Paneermeel',
								'fr': ''
							}
						},
						'16150900': {
							'id': 16150900,
							'desc': {
								'nl': 'DIERLIJKE DARMEN',
								'fr': ''
							}
						},
						'16150901': {
							'id': 16150901,
							'desc': {
								'nl': 'Varkensdarmen',
								'fr': ''
							}
						},
						'16150902': {
							'id': 16150902,
							'desc': {
								'nl': 'Schapendarmen',
								'fr': ''
							}
						},
						'16150903': {
							'id': 16150903,
							'desc': {
								'nl': 'Rundsdarmen',
								'fr': ''
							}
						},
						'17000000': {
							'id': 17000000,
							'desc': {
								'nl': 'SMEERSALADES',
								'fr': 'SALADES'
							}
						},
						'17010000': {
							'id': 17010000,
							'desc': {
								'nl': 'SMEERSALADES',
								'fr': 'SALADES FROIDES'
							}
						},
						'17010100': {
							'id': 17010100,
							'desc': {
								'nl': 'VLEESSALADE',
								'fr': 'SALADE DE VIANDE'
							}
						},
						'17010101': {
							'id': 17010101,
							'desc': {
								'nl': 'Hamsalade',
								'fr': 'Salade de jambon'
							}
						},
						'17010102': {
							'id': 17010102,
							'desc': {
								'nl': 'Vleessalade',
								'fr': 'Salade de viande'
							}
						},
						'17010103': {
							'id': 17010103,
							'desc': {
								'nl': 'Salade met balletjes',
								'fr': 'Salade avec boulettes'
							}
						},
						'17010104': {
							'id': 17010104,
							'desc': {
								'nl': 'Salade met gebraad',
								'fr': 'Salade avec rôti'
							}
						},
						'17010105': {
							'id': 17010105,
							'desc': {
								'nl': 'Pitasalade',
								'fr': 'Salade pita-kebab'
							}
						},
						'17010106': {
							'id': 17010106,
							'desc': {
								'nl': 'Préparé & spread',
								'fr': ''
							}
						},
						'17010200': {
							'id': 17010200,
							'desc': {
								'nl': 'VISSALADE',
								'fr': 'SALADE DE POISSON'
							}
						},
						'17010201': {
							'id': 17010201,
							'desc': {
								'nl': 'Surimi - krabsalade',
								'fr': 'Salade de surimi-crabe'
							}
						},
						'17010202': {
							'id': 17010202,
							'desc': {
								'nl': 'Tonijnsalade natuur',
								'fr': 'Salade de thon'
							}
						},
						'17010203': {
							'id': 17010203,
							'desc': {
								'nl': 'Tonijnsalade cocktail',
								'fr': 'Salade de saumon'
							}
						},
						'17010204': {
							'id': 17010204,
							'desc': {
								'nl': 'Tonijnsalade pikant',
								'fr': 'Salade de hareng'
							}
						},
						'17010205': {
							'id': 17010205,
							'desc': {
								'nl': 'Diverse tonijnsalade',
								'fr': 'Salade de poisson mix'
							}
						},
						'17010206': {
							'id': 17010206,
							'desc': {
								'nl': 'Zalmsalade',
								'fr': 'Salade de crustacé'
							}
						},
						'17010207': {
							'id': 17010207,
							'desc': {
								'nl': 'Haringsalade',
								'fr': 'Salade Haring'
							}
						},
						'17010208': {
							'id': 17010208,
							'desc': {
								'nl': 'Garnaal-scampi salade',
								'fr': 'Crevette-Scampi salade'
							}
						},
						'17010209': {
							'id': 17010209,
							'desc': {
								'nl': 'Grijzegarnaalsalade',
								'fr': 'Salade de poisson divers'
							}
						},
						'17010210': {
							'id': 17010210,
							'desc': {
								'nl': 'Kreeftsalade',
								'fr': 'Kreeftsalade'
							}
						},
						'17010211': {
							'id': 17010211,
							'desc': {
								'nl': 'Mosselsalade',
								'fr': ''
							}
						},
						'17010212': {
							'id': 17010212,
							'desc': {
								'nl': 'Diverse Vissalade',
								'fr': ''
							}
						},
						'17010213': {
							'id': 17010213,
							'desc': {
								'nl': 'Vismix salade',
								'fr': ''
							}
						},
						'17010300': {
							'id': 17010300,
							'desc': {
								'nl': 'GEVOGELTESALADE',
								'fr': 'SALADE DE VOLAILLE'
							}
						},
						'17010301': {
							'id': 17010301,
							'desc': {
								'nl': 'Kipsalade natuur',
								'fr': 'Salade de poulet'
							}
						},
						'17010302': {
							'id': 17010302,
							'desc': {
								'nl': 'Kipcurrysalade',
								'fr': 'Salade poulet curry'
							}
						},
						'17010303': {
							'id': 17010303,
							'desc': {
								'nl': 'Kip Hawaï',
								'fr': 'Salade de dinde'
							}
						},
						'17010304': {
							'id': 17010304,
							'desc': {
								'nl': 'Chop-choy - Thaï salade',
								'fr': ''
							}
						},
						'17010305': {
							'id': 17010305,
							'desc': {
								'nl': 'Pikante kipsalade',
								'fr': 'Salade de poulet picante'
							}
						},
						'17010306': {
							'id': 17010306,
							'desc': {
								'nl': 'Kip zigeuner - kip andalouse',
								'fr': ''
							}
						},
						'17010309': {
							'id': 17010309,
							'desc': {
								'nl': 'Diverse kipsalade',
								'fr': ''
							}
						},
						'17010310': {
							'id': 17010310,
							'desc': {
								'nl': 'Kipsalade balletjes',
								'fr': ''
							}
						},
						'17010400': {
							'id': 17010400,
							'desc': {
								'nl': 'GROENTESALADE',
								'fr': 'SALADE DE LÉGUMES'
							}
						},
						'17010401': {
							'id': 17010401,
							'desc': {
								'nl': 'Aardappelsalade',
								'fr': 'Salade de pommes de terre'
							}
						},
						'17010402': {
							'id': 17010402,
							'desc': {
								'nl': 'Champignonsalade',
								'fr': 'Salade de champignons'
							}
						},
						'17010403': {
							'id': 17010403,
							'desc': {
								'nl': 'Komkommersalade',
								'fr': 'Salade de comcombre'
							}
						},
						'17010404': {
							'id': 17010404,
							'desc': {
								'nl': 'Selder en wortel-salade',
								'fr': 'Salade de céleri-carotte'
							}
						},
						'17010405': {
							'id': 17010405,
							'desc': {
								'nl': 'Groentemix',
								'fr': 'Mix de légumes'
							}
						},
						'17010406': {
							'id': 17010406,
							'desc': {
								'nl': 'Koolsalade',
								'fr': ''
							}
						},
						'17010500': {
							'id': 17010500,
							'desc': {
								'nl': 'MIXSALADE',
								'fr': 'SALADE MIX'
							}
						},
						'17010501': {
							'id': 17010501,
							'desc': {
								'nl': 'Eiersalade',
								'fr': "Salade d'oeuf"
							}
						},
						'17010502': {
							'id': 17010502,
							'desc': {
								'nl': 'Salade met kaas',
								'fr': 'Salade de feta'
							}
						},
						'17010600': {
							'id': 17010600,
							'desc': {
								'nl': 'VEGETARISCHE SALADE',
								'fr': 'SPREAD / PREPARE'
							}
						},
						'17010601': {
							'id': 17010601,
							'desc': {
								'nl': 'Vegetarische salade',
								'fr': 'Spread / prepare'
							}
						},
						'17010800': {
							'id': 17010800,
							'desc': {
								'nl': 'THEMA-SEIZOENSALADE',
								'fr': 'SALADES DE THEME'
							}
						},
						'17010801': {
							'id': 17010801,
							'desc': {
								'nl': 'Themasalade',
								'fr': 'Salades de thème'
							}
						},
						'17010802': {
							'id': 17010802,
							'desc': {
								'nl': 'Seizoensalade',
								'fr': ''
							}
						},
						'18000000': {
							'id': 18000000,
							'desc': {
								'nl': 'GROENTEN EN FRUIT - NOTEN',
								'fr': 'FRUITS ET LÉGUMES'
							}
						},
						'18010000': {
							'id': 18010000,
							'desc': {
								'nl': 'GROENTEN',
								'fr': 'LÉGUMES'
							}
						},
						'18010200': {
							'id': 18010200,
							'desc': {
								'nl': 'RAUW BEREIDE GROENTEN',
								'fr': 'LÉGUMES CRUS PRÉPARÉS'
							}
						},
						'18010201': {
							'id': 18010201,
							'desc': {
								'nl': 'Zuurkool',
								'fr': 'Légumes en marinade'
							}
						},
						'18010202': {
							'id': 18010202,
							'desc': {
								'nl': 'Rauwkost',
								'fr': 'Crudités'
							}
						},
						'18020000': {
							'id': 18020000,
							'desc': {
								'nl': 'FRUIT',
								'fr': 'FRUITS'
							}
						},
						'18020300': {
							'id': 18020300,
							'desc': {
								'nl': 'GEDROOGD FRUIT',
								'fr': 'Fruits séchés & exotiques'
							}
						},
						'18020301': {
							'id': 18020301,
							'desc': {
								'nl': 'Gedroogd fruit',
								'fr': 'Fruits séchés'
							}
						},
						'18030000': {
							'id': 18030000,
							'desc': {
								'nl': 'NOTEN',
								'fr': ''
							}
						},
						'18030100': {
							'id': 18030100,
							'desc': {
								'nl': 'NOTEN',
								'fr': ''
							}
						},
						'18030101': {
							'id': 18030101,
							'desc': {
								'nl': 'Noten',
								'fr': 'Noix'
							}
						},
						'19000000': {
							'id': 19000000,
							'desc': {
								'nl': 'BAKKERIJPRODUCTEN IN BEDIENING',
								'fr': 'MATÉR.PROMOT.ET POUR COMPTOIR'
							}
						},
						'19010000': {
							'id': 19010000,
							'desc': {
								'nl': 'BROOD',
								'fr': 'PRÉSENTOIRS'
							}
						},
						'19010100': {
							'id': 19010100,
							'desc': {
								'nl': 'ONVERPAKT BROOD',
								'fr': 'PRÉSENTOIRS'
							}
						},
						'19010101': {
							'id': 19010101,
							'desc': {
								'nl': 'Broodjes',
								'fr': 'Étiquettes de prix'
							}
						},
						'19010102': {
							'id': 19010102,
							'desc': {
								'nl': 'Pistolets',
								'fr': 'Fournitures de coupe'
							}
						},
						'19010103': {
							'id': 19010103,
							'desc': {
								'nl': 'Sandwich',
								'fr': 'Cache-pots'
							}
						},
						'19010104': {
							'id': 19010104,
							'desc': {
								'nl': 'Stokbrood',
								'fr': 'Cuillers et couteaus'
							}
						},
						'19010105': {
							'id': 19010105,
							'desc': {
								'nl': 'Speciale broden',
								'fr': 'Intermédiaires et dessous'
							}
						},
						'19020000': {
							'id': 19020000,
							'desc': {
								'nl': 'VIENNOISERIE',
								'fr': 'MATÉRIEL PROMOTIONNEL'
							}
						},
						'19020100': {
							'id': 19020100,
							'desc': {
								'nl': 'VIENNOISERIE',
								'fr': 'MATÉRIEL POS'
							}
						},
						'19020101': {
							'id': 19020101,
							'desc': {
								'nl': 'Koffiekoeken',
								'fr': 'Affiches'
							}
						},
						'19020102': {
							'id': 19020102,
							'desc': {
								'nl': 'Cake',
								'fr': 'A4 présentation'
							}
						},
						'19020105': {
							'id': 19020105,
							'desc': {
								'nl': 'Chocolade fantaisie',
								'fr': ''
							}
						},
						'19030000': {
							'id': 19030000,
							'desc': {
								'nl': 'PATISSERIE',
								'fr': ''
							}
						},
						'19030100': {
							'id': 19030100,
							'desc': {
								'nl': 'PATISSERIE',
								'fr': 'PATISSERIE'
							}
						},
						'19030101': {
							'id': 19030101,
							'desc': {
								'nl': 'Taarten',
								'fr': ''
							}
						},
						'19030102': {
							'id': 19030102,
							'desc': {
								'nl': 'Gebak',
								'fr': ''
							}
						},
						'20000000': {
							'id': 20000000,
							'desc': {
								'nl': 'ZELFBEDIENING - GEKOELD',
								'fr': 'SELF-SERV.RÉFRIGÉR.'
							}
						},
						'21000000': {
							'id': 21000000,
							'desc': {
								'nl': 'CHARCUTERIE',
								'fr': 'CHARCUTERIE'
							}
						},
						'21010000': {
							'id': 21010000,
							'desc': {
								'nl': 'CHARCUTERIE',
								'fr': 'CHARCUTERIE'
							}
						},
						'21010400': {
							'id': 21010400,
							'desc': {
								'nl': 'CHARLES VOORVERPAKKING',
								'fr': 'CHARLES PE'
							}
						},
						'21010401': {
							'id': 21010401,
							'desc': {
								'nl': 'Charles vvp - ham',
								'fr': 'Jambon'
							}
						},
						'21010402': {
							'id': 21010402,
							'desc': {
								'nl': 'Charles vvp - spek',
								'fr': 'Lard - bacon'
							}
						},
						'21010403': {
							'id': 21010403,
							'desc': {
								'nl': 'Charles vvp - salami',
								'fr': 'Salami'
							}
						},
						'21010404': {
							'id': 21010404,
							'desc': {
								'nl': 'Charles vvp - paté',
								'fr': 'Paté'
							}
						},
						'21010405': {
							'id': 21010405,
							'desc': {
								'nl': 'Charles vvp - kookworst',
								'fr': 'Saucisson'
							}
						},
						'21010406': {
							'id': 21010406,
							'desc': {
								'nl': 'Charles vvp - gevogelte',
								'fr': 'Volaille'
							}
						},
						'21010408': {
							'id': 21010408,
							'desc': {
								'nl': 'Charles  vvp - vleesbrood',
								'fr': 'Pain de viande - boulet'
							}
						},
						'21010409': {
							'id': 21010409,
							'desc': {
								'nl': 'Charles vvp - rookvlees',
								'fr': 'Viande fumée'
							}
						},
						'21010500': {
							'id': 21010500,
							'desc': {
								'nl': "BELGIUM'S FINEST",
								'fr': "BELGIUM'S FINEST"
							}
						},
						'21010501': {
							'id': 21010501,
							'desc': {
								'nl': "Belgium's Finest - Flinterdun",
								'fr': "Belgium's Finest-fines tranche"
							}
						},
						'21010502': {
							'id': 21010502,
							'desc': {
								'nl': "Belgium's Finest - Assortiment",
								'fr': "Belgium's Finest -assortiments"
							}
						},
						'21010503': {
							'id': 21010503,
							'desc': {
								'nl': "Belgium's Finest - Chiffonade",
								'fr': "Belgium's Finest - chiffonades"
							}
						},
						'21010504': {
							'id': 21010504,
							'desc': {
								'nl': "Belgium's Finest - Paté",
								'fr': "Belgium's Finest - patés"
							}
						},
						'21010700': {
							'id': 21010700,
							'desc': {
								'nl': 'CHARLES FRESH',
								'fr': 'CHARLES FRESH PACK'
							}
						},
						'21010701': {
							'id': 21010701,
							'desc': {
								'nl': 'Charles Fresh - ham',
								'fr': 'Jambon'
							}
						},
						'21010702': {
							'id': 21010702,
							'desc': {
								'nl': 'Charles Fresh - spek',
								'fr': 'Lard - bacon'
							}
						},
						'21010703': {
							'id': 21010703,
							'desc': {
								'nl': 'Charles Fresh - salami',
								'fr': 'Salami'
							}
						},
						'21010704': {
							'id': 21010704,
							'desc': {
								'nl': 'Charles Fresh - paté',
								'fr': 'Paté'
							}
						},
						'21010705': {
							'id': 21010705,
							'desc': {
								'nl': 'Charles Fresh - kookworst',
								'fr': 'Saucisson'
							}
						},
						'21010706': {
							'id': 21010706,
							'desc': {
								'nl': 'Charles Fresh - gevogelte',
								'fr': 'Volaille'
							}
						},
						'21010708': {
							'id': 21010708,
							'desc': {
								'nl': 'Charles Fresh - vleesbrood',
								'fr': 'Pain de viande - boulet'
							}
						},
						'21010709': {
							'id': 21010709,
							'desc': {
								'nl': 'Charles Fresh - rookvlees',
								'fr': 'Viande fumée'
							}
						},
						'21010710': {
							'id': 21010710,
							'desc': {
								'nl': 'Charles Fresh - kop',
								'fr': 'Tête pressée'
							}
						},
						'21010900': {
							'id': 21010900,
							'desc': {
								'nl': 'MALETTI VOORVERPAKKING',
								'fr': ''
							}
						},
						'21010901': {
							'id': 21010901,
							'desc': {
								'nl': 'Assortiment Maletti',
								'fr': ''
							}
						},
						'21011000': {
							'id': 21011000,
							'desc': {
								'nl': 'DIVERSE MERKEN  VOORVERPAKKING',
								'fr': ''
							}
						},
						'21011001': {
							'id': 21011001,
							'desc': {
								'nl': 'Diverse vvp - ham',
								'fr': ''
							}
						},
						'21011002': {
							'id': 21011002,
							'desc': {
								'nl': 'Diverse vvp - spek',
								'fr': ''
							}
						},
						'21011003': {
							'id': 21011003,
							'desc': {
								'nl': 'Diverse vvp - droge salami',
								'fr': ''
							}
						},
						'21011004': {
							'id': 21011004,
							'desc': {
								'nl': 'Diverse vvp - paté',
								'fr': ''
							}
						},
						'21011005': {
							'id': 21011005,
							'desc': {
								'nl': 'Diverse vvp - kop',
								'fr': ''
							}
						},
						'21011006': {
							'id': 21011006,
							'desc': {
								'nl': 'Diverse vvp - gevogelte',
								'fr': ''
							}
						},
						'21011007': {
							'id': 21011007,
							'desc': {
								'nl': 'Diverse vvp - kookworst',
								'fr': ''
							}
						},
						'21011008': {
							'id': 21011008,
							'desc': {
								'nl': 'Diverse vvp - vleesbrood',
								'fr': ''
							}
						},
						'21011009': {
							'id': 21011009,
							'desc': {
								'nl': 'Diverse vvp - gehaktbal',
								'fr': ''
							}
						},
						'21011015': {
							'id': 21011015,
							'desc': {
								'nl': 'Diverse vvp - rillette',
								'fr': ''
							}
						},
						'22000000': {
							'id': 22000000,
							'desc': {
								'nl': 'ZUIVEL-SPREADS - EIEREN',
								'fr': 'PROD.LAITIERS-SPREADS-OEUFS'
							}
						},
						'22010000': {
							'id': 22010000,
							'desc': {
								'nl': 'KAAS',
								'fr': 'FROMAGE'
							}
						},
						'22010100': {
							'id': 22010100,
							'desc': {
								'nl': 'VERSE KAAS',
								'fr': 'FROMAGE FRAIS'
							}
						},
						'22010101': {
							'id': 22010101,
							'desc': {
								'nl': 'Verse kaas natuur',
								'fr': 'Fromage frais nature'
							}
						},
						'22010102': {
							'id': 22010102,
							'desc': {
								'nl': 'Mozzarella',
								'fr': 'Mozzarella'
							}
						},
						'22010103': {
							'id': 22010103,
							'desc': {
								'nl': 'Paschka - Chomelka',
								'fr': 'Fromage frais au chocolat'
							}
						},
						'22010104': {
							'id': 22010104,
							'desc': {
								'nl': 'Verse kaas met kruiden',
								'fr': 'Fromage frais aux fines herbes'
							}
						},
						'22010109': {
							'id': 22010109,
							'desc': {
								'nl': 'Verse kaas met fruit',
								'fr': 'Fromage frais aux fruits'
							}
						},
						'22010200': {
							'id': 22010200,
							'desc': {
								'nl': 'ZACHTE KAAS',
								'fr': 'FROMAGE DOUX'
							}
						},
						'22010201': {
							'id': 22010201,
							'desc': {
								'nl': 'Witte korst kaas',
								'fr': 'Fromage à croûte blanche'
							}
						},
						'22010202': {
							'id': 22010202,
							'desc': {
								'nl': 'Diverse zachte kaas',
								'fr': 'Fromages doux divers'
							}
						},
						'22010203': {
							'id': 22010203,
							'desc': {
								'nl': 'Gewassen korst kaas',
								'fr': ''
							}
						},
						'22010209': {
							'id': 22010209,
							'desc': {
								'nl': 'Zachte kaas met div. smaken',
								'fr': 'Fromage doux fantaisie'
							}
						},
						'22010300': {
							'id': 22010300,
							'desc': {
								'nl': 'HALF-HARDE KAAS',
								'fr': 'FROMAGE SEMI-DURE'
							}
						},
						'22010301': {
							'id': 22010301,
							'desc': {
								'nl': 'Charles vvp kaas',
								'fr': 'Charles PE fromage'
							}
						},
						'22010302': {
							'id': 22010302,
							'desc': {
								'nl': 'Charles Fresh vvp kaas',
								'fr': 'Style Gouda'
							}
						},
						'22010303': {
							'id': 22010303,
							'desc': {
								'nl': 'Type gouda kaas',
								'fr': 'Style Gouda light'
							}
						},
						'22010304': {
							'id': 22010304,
							'desc': {
								'nl': 'Type gouda kaas light',
								'fr': "Fromage d'abbaye"
							}
						},
						'22010305': {
							'id': 22010305,
							'desc': {
								'nl': 'Type abdijkaas',
								'fr': 'Charles Fresh fromage'
							}
						},
						'22010309': {
							'id': 22010309,
							'desc': {
								'nl': 'Half-harde kaas diverse smaken',
								'fr': 'Fromage demi dur-fantaisie'
							}
						},
						'22010400': {
							'id': 22010400,
							'desc': {
								'nl': 'HARDE KAAS',
								'fr': 'FROMAGE À PÂTE DURE'
							}
						},
						'22010401': {
							'id': 22010401,
							'desc': {
								'nl': 'Geraspte kaas',
								'fr': 'Fromage râpé'
							}
						},
						'22010500': {
							'id': 22010500,
							'desc': {
								'nl': 'GEITENKAAS - SCHAPENKAAS',
								'fr': 'FROMAGE DE CHÈVRE/BREBIS'
							}
						},
						'22010501': {
							'id': 22010501,
							'desc': {
								'nl': 'Verse geitenkaas',
								'fr': 'Fromage de chèvre'
							}
						},
						'22010502': {
							'id': 22010502,
							'desc': {
								'nl': 'Schapenkaas',
								'fr': 'Fromage de brebis'
							}
						},
						'22010505': {
							'id': 22010505,
							'desc': {
								'nl': 'Feta',
								'fr': ''
							}
						},
						'22010600': {
							'id': 22010600,
							'desc': {
								'nl': 'SMELTKAAS',
								'fr': 'FROMAGE FONDUE'
							}
						},
						'22010601': {
							'id': 22010601,
							'desc': {
								'nl': 'Smeltkaas',
								'fr': 'Fromage fondue'
							}
						},
						'22020000': {
							'id': 22020000,
							'desc': {
								'nl': 'ZUIVEL',
								'fr': 'PRODUITS LAITIERS'
							}
						},
						'22020100': {
							'id': 22020100,
							'desc': {
								'nl': 'BOTER - ROOM',
								'fr': 'BEURRE - CRÈME'
							}
						},
						'22020101': {
							'id': 22020101,
							'desc': {
								'nl': 'Boter',
								'fr': 'Beurre'
							}
						},
						'22020102': {
							'id': 22020102,
							'desc': {
								'nl': 'Bereide boter',
								'fr': 'Beurre préparé'
							}
						},
						'22020105': {
							'id': 22020105,
							'desc': {
								'nl': 'Room',
								'fr': 'Crème'
							}
						},
						'22020200': {
							'id': 22020200,
							'desc': {
								'nl': 'YOGHURT',
								'fr': 'YAOURT'
							}
						},
						'22020202': {
							'id': 22020202,
							'desc': {
								'nl': 'Yoghurt natuur',
								'fr': 'Yaourt nature'
							}
						},
						'22020203': {
							'id': 22020203,
							'desc': {
								'nl': 'Yoghurt fruit',
								'fr': 'Yaourt fruit'
							}
						},
						'22030000': {
							'id': 22030000,
							'desc': {
								'nl': 'SPREADS',
								'fr': 'GRAISSES TARTINABLES'
							}
						},
						'22030100': {
							'id': 22030100,
							'desc': {
								'nl': 'MARGARINE',
								'fr': 'MARGARINE'
							}
						},
						'22030101': {
							'id': 22030101,
							'desc': {
								'nl': 'Smeermargarine',
								'fr': 'Margarine à tartiner'
							}
						},
						'22030102': {
							'id': 22030102,
							'desc': {
								'nl': 'Bakmargarine',
								'fr': 'Margarine de cuisson'
							}
						},
						'22030103': {
							'id': 22030103,
							'desc': {
								'nl': 'Dierlijk vet',
								'fr': 'Graisse animale'
							}
						},
						'22040000': {
							'id': 22040000,
							'desc': {
								'nl': 'EIEREN',
								'fr': 'OEUF'
							}
						},
						'22040100': {
							'id': 22040100,
							'desc': {
								'nl': 'EIEREN',
								'fr': 'OEUF'
							}
						},
						'22040101': {
							'id': 22040101,
							'desc': {
								'nl': 'Verse Eieren',
								'fr': 'Oeufs frais'
							}
						},
						'23000000': {
							'id': 23000000,
							'desc': {
								'nl': 'TRAITEUR',
								'fr': 'TRAITEUR'
							}
						},
						'23010000': {
							'id': 23010000,
							'desc': {
								'nl': 'HAPJES - SNACKS - TAPAS',
								'fr': 'BOUCHÉES/AMUSE-GUEULE/TAPAS'
							}
						},
						'23010100': {
							'id': 23010100,
							'desc': {
								'nl': 'HAPJES',
								'fr': 'BOUCHÉES'
							}
						},
						'23010101': {
							'id': 23010101,
							'desc': {
								'nl': 'Bladerdeeghapjes',
								'fr': 'Bouchées pâte feuilletée'
							}
						},
						'23010102': {
							'id': 23010102,
							'desc': {
								'nl': 'Krokethapje',
								'fr': 'Amuse-guele croquette'
							}
						},
						'23010104': {
							'id': 23010104,
							'desc': {
								'nl': 'Kiphapjes',
								'fr': 'Bouchées au poulet'
							}
						},
						'23010105': {
							'id': 23010105,
							'desc': {
								'nl': 'Hapjes met vis',
								'fr': 'Amuse-gueules au poisson'
							}
						},
						'23010106': {
							'id': 23010106,
							'desc': {
								'nl': 'Hapjes met kaas',
								'fr': ''
							}
						},
						'23010109': {
							'id': 23010109,
							'desc': {
								'nl': 'Diverse hapjes',
								'fr': 'Bouchées divers'
							}
						},
						'23010200': {
							'id': 23010200,
							'desc': {
								'nl': 'SNACKS',
								'fr': 'AMUSE-GUEULE'
							}
						},
						'23010201': {
							'id': 23010201,
							'desc': {
								'nl': 'Pizza',
								'fr': 'Pizza'
							}
						},
						'23010202': {
							'id': 23010202,
							'desc': {
								'nl': 'Quiche',
								'fr': 'Quiche'
							}
						},
						'23010203': {
							'id': 23010203,
							'desc': {
								'nl': 'Bladerdeegsnack',
								'fr': 'Feuilleté'
							}
						},
						'23010204': {
							'id': 23010204,
							'desc': {
								'nl': 'Slaatjes',
								'fr': ''
							}
						},
						'23010208': {
							'id': 23010208,
							'desc': {
								'nl': 'Pannenkoeken',
								'fr': ''
							}
						},
						'23010209': {
							'id': 23010209,
							'desc': {
								'nl': 'Diverse snacks',
								'fr': 'Amuse-gueule divers'
							}
						},
						'23010300': {
							'id': 23010300,
							'desc': {
								'nl': 'TAPAS',
								'fr': 'TAPAS'
							}
						},
						'23010301': {
							'id': 23010301,
							'desc': {
								'nl': 'Tapas mix',
								'fr': 'Tapas mix'
							}
						},
						'23010302': {
							'id': 23010302,
							'desc': {
								'nl': 'Tapenade',
								'fr': 'Tapenade'
							}
						},
						'23010303': {
							'id': 23010303,
							'desc': {
								'nl': 'Olijven',
								'fr': 'Olives'
							}
						},
						'23010304': {
							'id': 23010304,
							'desc': {
								'nl': 'Vis',
								'fr': ''
							}
						},
						'23010305': {
							'id': 23010305,
							'desc': {
								'nl': 'Tapas Charles',
								'fr': 'Tapas Charles'
							}
						},
						'23020000': {
							'id': 23020000,
							'desc': {
								'nl': 'MAALTIJDCOMPONENTEN',
								'fr': ''
							}
						},
						'23020100': {
							'id': 23020100,
							'desc': {
								'nl': 'GEGAARD VARKENSVLEES',
								'fr': ''
							}
						},
						'23020101': {
							'id': 23020101,
							'desc': {
								'nl': 'Ribbetjes',
								'fr': ''
							}
						},
						'23020102': {
							'id': 23020102,
							'desc': {
								'nl': 'Schenkel',
								'fr': ''
							}
						},
						'23020103': {
							'id': 23020103,
							'desc': {
								'nl': 'Gebraad',
								'fr': ''
							}
						},
						'23020104': {
							'id': 23020104,
							'desc': {
								'nl': 'Balletjes en soepballetjes',
								'fr': ''
							}
						},
						'23020105': {
							'id': 23020105,
							'desc': {
								'nl': 'Pensen',
								'fr': ''
							}
						},
						'23020106': {
							'id': 23020106,
							'desc': {
								'nl': 'Barbecue- en grillworst',
								'fr': ''
							}
						},
						'23020200': {
							'id': 23020200,
							'desc': {
								'nl': 'GEGAARD GEVOGELTEVLEES',
								'fr': ''
							}
						},
						'23020201': {
							'id': 23020201,
							'desc': {
								'nl': 'Gepanneerde kipproducten',
								'fr': ''
							}
						},
						'23020202': {
							'id': 23020202,
							'desc': {
								'nl': 'Kipballetjes',
								'fr': ''
							}
						},
						'23020203': {
							'id': 23020203,
							'desc': {
								'nl': 'Gepaneerde kalkoenproducten',
								'fr': ''
							}
						},
						'23020300': {
							'id': 23020300,
							'desc': {
								'nl': 'BEREIDE GROENTEN',
								'fr': ''
							}
						},
						'23020301': {
							'id': 23020301,
							'desc': {
								'nl': 'Purees',
								'fr': ''
							}
						},
						'23020302': {
							'id': 23020302,
							'desc': {
								'nl': 'Aardappelkroketten',
								'fr': ''
							}
						},
						'23020303': {
							'id': 23020303,
							'desc': {
								'nl': 'Aardappelen',
								'fr': ''
							}
						},
						'23020304': {
							'id': 23020304,
							'desc': {
								'nl': 'Frieten',
								'fr': ''
							}
						},
						'23020305': {
							'id': 23020305,
							'desc': {
								'nl': 'Diverse gegaarde groenten',
								'fr': ''
							}
						},
						'23030000': {
							'id': 23030000,
							'desc': {
								'nl': 'BEREIDE GERECHTEN',
								'fr': 'PLATS PRÉPARÉS'
							}
						},
						'23030100': {
							'id': 23030100,
							'desc': {
								'nl': 'VOORGERECHTEN',
								'fr': 'ENTRÉES'
							}
						},
						'23030101': {
							'id': 23030101,
							'desc': {
								'nl': 'Verse soep 95 cl BCS',
								'fr': 'Potage frais'
							}
						},
						'23030102': {
							'id': 23030102,
							'desc': {
								'nl': 'Verse soep 30 cl BCS',
								'fr': 'Entrée avec poisson'
							}
						},
						'23030103': {
							'id': 23030103,
							'desc': {
								'nl': 'Verse soep 1 lit Cookking',
								'fr': ''
							}
						},
						'23030104': {
							'id': 23030104,
							'desc': {
								'nl': 'Voorgerecht met vis',
								'fr': ''
							}
						},
						'23030105': {
							'id': 23030105,
							'desc': {
								'nl': 'Verse soep Padifood',
								'fr': 'Verse soep Padifood'
							}
						},
						'23030200': {
							'id': 23030200,
							'desc': {
								'nl': 'DAGSCHOTELS',
								'fr': 'PLATS PRÉPARÉS'
							}
						},
						'23030201': {
							'id': 23030201,
							'desc': {
								'nl': 'Dagschotels Charles',
								'fr': 'Plat préparé avec viande'
							}
						},
						'23030202': {
							'id': 23030202,
							'desc': {
								'nl': 'Dagschotels Padifood',
								'fr': 'Plat préparé avec poisson'
							}
						},
						'23030203': {
							'id': 23030203,
							'desc': {
								'nl': 'Dagschotels Lunchtime',
								'fr': 'Plat préparé avec volaille'
							}
						},
						'23030204': {
							'id': 23030204,
							'desc': {
								'nl': 'Lasagne-spagetti',
								'fr': 'Plat préparé avec pâtes'
							}
						},
						'23030205': {
							'id': 23030205,
							'desc': {
								'nl': 'Dagschotels diverse merken',
								'fr': 'Croquet.frites,puree,gratin'
							}
						},
						'23030206': {
							'id': 23030206,
							'desc': {
								'nl': 'Maaltijdsalades BCS',
								'fr': 'Plat préparé au riz'
							}
						},
						'23030207': {
							'id': 23030207,
							'desc': {
								'nl': 'Pastasalade',
								'fr': 'Légumes préparées'
							}
						},
						'23030300': {
							'id': 23030300,
							'desc': {
								'nl': 'BEREIDE GERECHTEN',
								'fr': 'ASSIETTE FROIDE'
							}
						},
						'23030301': {
							'id': 23030301,
							'desc': {
								'nl': 'Gerechten Comme Maison',
								'fr': 'Assiette froide de poisson'
							}
						},
						'23030302': {
							'id': 23030302,
							'desc': {
								'nl': 'Gerechten met vis',
								'fr': 'Assiette froide de légumes'
							}
						},
						'23080000': {
							'id': 23080000,
							'desc': {
								'nl': 'DESSERTS',
								'fr': ''
							}
						},
						'23080100': {
							'id': 23080100,
							'desc': {
								'nl': 'DESSERTS',
								'fr': ''
							}
						},
						'23080101': {
							'id': 23080101,
							'desc': {
								'nl': 'Desserts Charles',
								'fr': ''
							}
						},
						'23080102': {
							'id': 23080102,
							'desc': {
								'nl': 'Mousses',
								'fr': ''
							}
						},
						'23080103': {
							'id': 23080103,
							'desc': {
								'nl': 'Kaastaart',
								'fr': ''
							}
						},
						'23080104': {
							'id': 23080104,
							'desc': {
								'nl': 'Rijstpap',
								'fr': ''
							}
						},
						'23090000': {
							'id': 23090000,
							'desc': {
								'nl': 'DIVERSE GEKOELDE PRODUCTEN',
								'fr': ''
							}
						},
						'23090100': {
							'id': 23090100,
							'desc': {
								'nl': 'DIVERSE PRODUCTEN',
								'fr': ''
							}
						},
						'23090101': {
							'id': 23090101,
							'desc': {
								'nl': 'Gist',
								'fr': ''
							}
						},
						'23090102': {
							'id': 23090102,
							'desc': {
								'nl': 'Joppiesaus',
								'fr': ''
							}
						},
						'24000000': {
							'id': 24000000,
							'desc': {
								'nl': 'BEENHOUWERIJ',
								'fr': 'VIANDE'
							}
						},
						'24010000': {
							'id': 24010000,
							'desc': {
								'nl': 'VARKENVLEES',
								'fr': 'VIANDE DE PORC'
							}
						},
						'24020000': {
							'id': 24020000,
							'desc': {
								'nl': 'KIPVLEES',
								'fr': 'VIANDE DE POULET'
							}
						},
						'24030000': {
							'id': 24030000,
							'desc': {
								'nl': 'KALKOENVLEES',
								'fr': 'VIANDE DE DINDE'
							}
						},
						'24040000': {
							'id': 24040000,
							'desc': {
								'nl': 'PAARDENVLEES',
								'fr': 'VIANDE DE CHEVAL'
							}
						},
						'24040100': {
							'id': 24040100,
							'desc': {
								'nl': 'Rauw paardenvlees',
								'fr': 'VIANDE DE CHEVAL CRUE'
							}
						},
						'24040101': {
							'id': 24040101,
							'desc': {
								'nl': 'Verse paardenfilet vvp',
								'fr': 'Viande de cheval coupée crue'
							}
						},
						'24050000': {
							'id': 24050000,
							'desc': {
								'nl': 'RUNDSVLEES',
								'fr': 'BOEUF'
							}
						},
						'24050100': {
							'id': 24050100,
							'desc': {
								'nl': 'Entrecôte',
								'fr': 'BOEUF CRU'
							}
						},
						'24050101': {
							'id': 24050101,
							'desc': {
								'nl': 'Verse entrecote',
								'fr': ''
							}
						},
						'24050102': {
							'id': 24050102,
							'desc': {
								'nl': 'Entrecote Dry Aged',
								'fr': ''
							}
						},
						'25000000': {
							'id': 25000000,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'25030000': {
							'id': 25030000,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'25030200': {
							'id': 25030200,
							'desc': {
								'nl': 'BEREIDE VIS',
								'fr': 'POISSON PRÉPARÉ'
							}
						},
						'25030201': {
							'id': 25030201,
							'desc': {
								'nl': 'Gerookte vis',
								'fr': 'Poisson fumé'
							}
						},
						'25030300': {
							'id': 25030300,
							'desc': {
								'nl': 'VISCONSERVEN',
								'fr': 'CONSERVES DE POISSON'
							}
						},
						'25030301': {
							'id': 25030301,
							'desc': {
								'nl': 'Opgelegde vis in glas',
								'fr': 'Poisson en conserve bocal'
							}
						},
						'27000000': {
							'id': 27000000,
							'desc': {
								'nl': 'SMEERSALADES',
								'fr': 'SALADES'
							}
						},
						'27010000': {
							'id': 27010000,
							'desc': {
								'nl': 'SMEERSALADES',
								'fr': 'SALADES FROIDES'
							}
						},
						'27010100': {
							'id': 27010100,
							'desc': {
								'nl': 'SALADES CHARLES',
								'fr': 'SALADES FROIDES'
							}
						},
						'27010101': {
							'id': 27010101,
							'desc': {
								'nl': 'Salades Charles met vlees',
								'fr': 'Salades sans marque 6x160gr'
							}
						},
						'27010102': {
							'id': 27010102,
							'desc': {
								'nl': 'Salades Charles met vis',
								'fr': 'Salades Délio'
							}
						},
						'27010103': {
							'id': 27010103,
							'desc': {
								'nl': 'Salades Charles met gevogelte',
								'fr': 'Salades 500gr'
							}
						},
						'27010104': {
							'id': 27010104,
							'desc': {
								'nl': 'Salades Charles met groenten',
								'fr': 'Salades de légumes'
							}
						},
						'27010200': {
							'id': 27010200,
							'desc': {
								'nl': 'SALADES MERKLOOS (Charles)',
								'fr': 'SPREAD'
							}
						},
						'27010201': {
							'id': 27010201,
							'desc': {
								'nl': 'Salades merkloos met vlees',
								'fr': 'Spread'
							}
						},
						'27010202': {
							'id': 27010202,
							'desc': {
								'nl': 'Salades merkloos met vis',
								'fr': 'Hoemoes'
							}
						},
						'27010203': {
							'id': 27010203,
							'desc': {
								'nl': 'Salades merkloos met gevogelte',
								'fr': ''
							}
						},
						'27010204': {
							'id': 27010204,
							'desc': {
								'nl': 'Salades merkloos met groenten',
								'fr': ''
							}
						},
						'27010300': {
							'id': 27010300,
							'desc': {
								'nl': 'FRESH PACK (Hamal)',
								'fr': 'SALADES CHEZ CHARLES'
							}
						},
						'27010301': {
							'id': 27010301,
							'desc': {
								'nl': 'Fresh pack met vlees',
								'fr': 'Salades de viande'
							}
						},
						'27010302': {
							'id': 27010302,
							'desc': {
								'nl': 'Fresh pack met vis',
								'fr': 'Salades de poisson'
							}
						},
						'27010303': {
							'id': 27010303,
							'desc': {
								'nl': 'Fresh pack met gevogelte',
								'fr': 'Salades de volaille'
							}
						},
						'27010304': {
							'id': 27010304,
							'desc': {
								'nl': 'Fresh pack met groenten',
								'fr': 'Salades de légumes'
							}
						},
						'27010305': {
							'id': 27010305,
							'desc': {
								'nl': 'Fresh pack  thema-seizoen',
								'fr': 'Salades mix'
							}
						},
						'27010400': {
							'id': 27010400,
							'desc': {
								'nl': 'SALADES MERKLOOS (Jebo)',
								'fr': 'BAKKERSBELEG'
							}
						},
						'27010401': {
							'id': 27010401,
							'desc': {
								'nl': 'Salades merkloos (Jebo)',
								'fr': 'SALADES ET SPREADS'
							}
						},
						'27010500': {
							'id': 27010500,
							'desc': {
								'nl': 'Salades diverse merken',
								'fr': ''
							}
						},
						'27010505': {
							'id': 27010505,
							'desc': {
								'nl': 'Groentesalades',
								'fr': ''
							}
						},
						'28000000': {
							'id': 28000000,
							'desc': {
								'nl': 'GROENTEN EN FRUIT',
								'fr': 'LÉGUMES ET FRUITS'
							}
						},
						'28010000': {
							'id': 28010000,
							'desc': {
								'nl': 'GROENTEN',
								'fr': 'LÉGUMES'
							}
						},
						'28010300': {
							'id': 28010300,
							'desc': {
								'nl': 'RAUWKOST',
								'fr': 'CRUDITÉS'
							}
						},
						'28010301': {
							'id': 28010301,
							'desc': {
								'nl': 'Assortiment Bieze',
								'fr': 'Crudités Bieze'
							}
						},
						'28010302': {
							'id': 28010302,
							'desc': {
								'nl': 'Rauwkost diverse leveranciers',
								'fr': 'Crudités Paasfoods'
							}
						},
						'28020000': {
							'id': 28020000,
							'desc': {
								'nl': 'FRUIT',
								'fr': ''
							}
						},
						'30000000': {
							'id': 30000000,
							'desc': {
								'nl': 'ZELFBEDIENING - DIEPGEKOELD',
								'fr': 'SELF-SERV.SURGELÉ'
							}
						},
						'31000000': {
							'id': 31000000,
							'desc': {
								'nl': 'DIEPVRIESEILAND',
								'fr': 'GONDOLE SURGELÉE'
							}
						},
						'31010000': {
							'id': 31010000,
							'desc': {
								'nl': 'HAPJES - SNACKS',
								'fr': 'AMUSE-GUEULE'
							}
						},
						'31010200': {
							'id': 31010200,
							'desc': {
								'nl': 'HAPJES',
								'fr': ''
							}
						},
						'31010201': {
							'id': 31010201,
							'desc': {
								'nl': 'Diverse hapjes',
								'fr': ''
							}
						},
						'31020000': {
							'id': 31020000,
							'desc': {
								'nl': 'AARDAPPELPRODUCTEN',
								'fr': 'PRODUITS DE POMMES DE TERRE'
							}
						},
						'31020100': {
							'id': 31020100,
							'desc': {
								'nl': 'AARDAPPELPRODUCTEN LUTOSA',
								'fr': 'PROD.DE POMMES DE TERRE LUTOSA'
							}
						},
						'31020101': {
							'id': 31020101,
							'desc': {
								'nl': 'Frieten Lutosa',
								'fr': 'Frites Lutosa'
							}
						},
						'31020102': {
							'id': 31020102,
							'desc': {
								'nl': 'Kroketten Lutosa',
								'fr': 'Croquettes Lutosa'
							}
						},
						'31020103': {
							'id': 31020103,
							'desc': {
								'nl': 'Purees Lutosa',
								'fr': 'Purées Lutosa'
							}
						},
						'31030000': {
							'id': 31030000,
							'desc': {
								'nl': 'VLEES-GEVOGELTEPRODUCTEN',
								'fr': 'PRODUITS DE VIANDE'
							}
						},
						'31030100': {
							'id': 31030100,
							'desc': {
								'nl': 'VLEESPRODUCTEN',
								'fr': 'PRODUITS DE VIANDE'
							}
						},
						'31030101': {
							'id': 31030101,
							'desc': {
								'nl': 'Gehaktballetjes',
								'fr': 'Boulettes'
							}
						},
						'31030200': {
							'id': 31030200,
							'desc': {
								'nl': 'GEVOGELTEPRODUCTEN',
								'fr': ''
							}
						},
						'31030201': {
							'id': 31030201,
							'desc': {
								'nl': 'Kip- en kalkoenproducten',
								'fr': ''
							}
						},
						'31040000': {
							'id': 31040000,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'31040100': {
							'id': 31040100,
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							}
						},
						'31040101': {
							'id': 31040101,
							'desc': {
								'nl': 'Zeevis',
								'fr': 'Poisson de mer'
							}
						},
						'31040102': {
							'id': 31040102,
							'desc': {
								'nl': 'Schaaldieren',
								'fr': 'Crustacés'
							}
						},
						'31050000': {
							'id': 31050000,
							'desc': {
								'nl': 'BEREIDE GERECHTEN',
								'fr': 'PLATS PRÉPARÉS'
							}
						},
						'31050100': {
							'id': 31050100,
							'desc': {
								'nl': 'VOORGERECHTEN',
								'fr': 'ENTRÉES'
							}
						},
						'31050101': {
							'id': 31050101,
							'desc': {
								'nl': 'Soep',
								'fr': 'Soupe'
							}
						},
						'31060000': {
							'id': 31060000,
							'desc': {
								'nl': 'DESSERTS',
								'fr': ''
							}
						},
						'31060100': {
							'id': 31060100,
							'desc': {
								'nl': 'DESSERTS',
								'fr': ''
							}
						},
						'31060101': {
							'id': 31060101,
							'desc': {
								'nl': 'Mousses',
								'fr': ''
							}
						},
						'31090000': {
							'id': 31090000,
							'desc': {
								'nl': 'GROENTEN & KRUIDEN',
								'fr': ''
							}
						},
						'31090100': {
							'id': 31090100,
							'desc': {
								'nl': 'GROENTEN & KRUIDEN',
								'fr': ''
							}
						},
						'31090101': {
							'id': 31090101,
							'desc': {
								'nl': 'Groenten',
								'fr': ''
							}
						},
						'31090102': {
							'id': 31090102,
							'desc': {
								'nl': 'Kruiden',
								'fr': ''
							}
						},
						'40000000': {
							'id': 40000000,
							'desc': {
								'nl': 'ZELFBEDIENING - NIET GEKOELD',
								'fr': 'SELF-SERV.NON RÉFRIG.'
							}
						},
						'41000000': {
							'id': 41000000,
							'desc': {
								'nl': 'NIET- GEKOELDE PRODUCTEN',
								'fr': 'ALIMENTATION NON RÉFRIGÉRÉE'
							}
						},
						'41010000': {
							'id': 41010000,
							'desc': {
								'nl': 'GROENTEN/FRUIT',
								'fr': 'LÉGUMES/FRUITS'
							}
						},
						'41010100': {
							'id': 41010100,
							'desc': {
								'nl': 'GROENTECONSERVEN',
								'fr': 'CONSERVES DE LÉGUMES'
							}
						},
						'41010101': {
							'id': 41010101,
							'desc': {
								'nl': 'Groenteconserven Hak 21cl',
								'fr': 'Conserves de légumes Hak 21cl'
							}
						},
						'41010102': {
							'id': 41010102,
							'desc': {
								'nl': 'Groenteconserven Hak 37cl',
								'fr': 'Conserves de légumes Hak 37cl'
							}
						},
						'41010103': {
							'id': 41010103,
							'desc': {
								'nl': 'Groenteconserven Hak 72cl',
								'fr': 'Conserv.de légumes Hak 55/72cl'
							}
						},
						'41010106': {
							'id': 41010106,
							'desc': {
								'nl': 'Groenteconserven - K- 32-37cl',
								'fr': 'Conserves légumes -K- 37cl'
							}
						},
						'41010107': {
							'id': 41010107,
							'desc': {
								'nl': 'Groenteconserven - K - 72cl',
								'fr': 'Conserves légumes-K- 72cl'
							}
						},
						'41010108': {
							'id': 41010108,
							'desc': {
								'nl': 'Groentecons.St-Antoine 1/2',
								'fr': 'Cons.légumes culinair 1/2'
							}
						},
						'41010109': {
							'id': 41010109,
							'desc': {
								'nl': 'Groentecons.St-Antoine 1/1',
								'fr': 'Cons.légumes culinair 1/1'
							}
						},
						'41010110': {
							'id': 41010110,
							'desc': {
								'nl': 'Conserven Elvea',
								'fr': 'Conserves de légumes Elvea'
							}
						},
						'41010115': {
							'id': 41010115,
							'desc': {
								'nl': 'Zuurkool',
								'fr': ''
							}
						},
						'41010200': {
							'id': 41010200,
							'desc': {
								'nl': 'FRUITCONSERVEN',
								'fr': 'CONSERVES DE FRUITS'
							}
						},
						'41010201': {
							'id': 41010201,
							'desc': {
								'nl': 'Fruitconserven Hak',
								'fr': 'Conserves de fruits Hak 21cl'
							}
						},
						'41010206': {
							'id': 41010206,
							'desc': {
								'nl': 'Fruitconserven Dole-Delmonte',
								'fr': 'Conserves fruits Delmonte 1/2'
							}
						},
						'41010208': {
							'id': 41010208,
							'desc': {
								'nl': 'Fruitconserven -K',
								'fr': 'Conserves fruits -K- 37cl'
							}
						},
						'41010210': {
							'id': 41010210,
							'desc': {
								'nl': 'Fruitconserven St.-Antoine',
								'fr': 'Conserves fruits culinair 1/2'
							}
						},
						'41010211': {
							'id': 41010211,
							'desc': {
								'nl': 'Fruitconserven diverse merken',
								'fr': 'Conserves fruits divers'
							}
						},
						'41010300': {
							'id': 41010300,
							'desc': {
								'nl': 'CONFITUUR',
								'fr': ''
							}
						},
						'41010301': {
							'id': 41010301,
							'desc': {
								'nl': 'Konfituur suikerarm Rabeko',
								'fr': ''
							}
						},
						'41010500': {
							'id': 41010500,
							'desc': {
								'nl': 'GEDROOGD FRUIT',
								'fr': 'Légumes séchées'
							}
						},
						'41010501': {
							'id': 41010501,
							'desc': {
								'nl': 'Gedroogd fruit',
								'fr': 'Légumes séchées'
							}
						},
						'41020000': {
							'id': 41020000,
							'desc': {
								'nl': 'VLEESWAREN',
								'fr': 'PRODUITS DE VIANDE'
							}
						},
						'41020100': {
							'id': 41020100,
							'desc': {
								'nl': 'WORST',
								'fr': 'SAUCISSON'
							}
						},
						'41020101': {
							'id': 41020101,
							'desc': {
								'nl': 'Gedroogde worst',
								'fr': 'Saucisson sec'
							}
						},
						'41020102': {
							'id': 41020102,
							'desc': {
								'nl': 'Gekookte worst',
								'fr': 'Saucisson cuit'
							}
						},
						'41020103': {
							'id': 41020103,
							'desc': {
								'nl': 'Salami',
								'fr': 'Salami'
							}
						},
						'41020200': {
							'id': 41020200,
							'desc': {
								'nl': 'GERECHTEN',
								'fr': 'PLATS'
							}
						},
						'41020201': {
							'id': 41020201,
							'desc': {
								'nl': 'Conserven gerechten',
								'fr': 'Plats conserves'
							}
						},
						'41030000': {
							'id': 41030000,
							'desc': {
								'nl': 'ZUIVEL- SPREADS',
								'fr': 'PRODUITS LAITIERS'
							}
						},
						'41030200': {
							'id': 41030200,
							'desc': {
								'nl': 'MELK',
								'fr': 'LAIT'
							}
						},
						'41030201': {
							'id': 41030201,
							'desc': {
								'nl': 'Melk UHT',
								'fr': 'Lait UHT'
							}
						},
						'41030202': {
							'id': 41030202,
							'desc': {
								'nl': 'Chocomelk',
								'fr': 'Babeurre'
							}
						},
						'41030203': {
							'id': 41030203,
							'desc': {
								'nl': 'Melk met aardbei',
								'fr': ''
							}
						},
						'41030300': {
							'id': 41030300,
							'desc': {
								'nl': 'ROOM',
								'fr': 'CRÈME'
							}
						},
						'41030301': {
							'id': 41030301,
							'desc': {
								'nl': 'Room UHT',
								'fr': 'Crème UHT'
							}
						},
						'41030400': {
							'id': 41030400,
							'desc': {
								'nl': 'DESSERTS',
								'fr': 'DESSERTS'
							}
						},
						'41030401': {
							'id': 41030401,
							'desc': {
								'nl': 'Puddings',
								'fr': 'Puddings'
							}
						},
						'41030402': {
							'id': 41030402,
							'desc': {
								'nl': 'Melkdesserts',
								'fr': 'Desserts divers'
							}
						},
						'41030800': {
							'id': 41030800,
							'desc': {
								'nl': 'SPREADS',
								'fr': ''
							}
						},
						'41030801': {
							'id': 41030801,
							'desc': {
								'nl': 'Choco',
								'fr': ''
							}
						},
						'41040000': {
							'id': 41040000,
							'desc': {
								'nl': 'SAUSEN',
								'fr': 'SAUCES'
							}
						},
						'41040100': {
							'id': 41040100,
							'desc': {
								'nl': 'KOUDE SAUSEN',
								'fr': 'SAUCES FROIDES'
							}
						},
						'41040101': {
							'id': 41040101,
							'desc': {
								'nl': 'Jermayo tubes 400 ml',
								'fr': 'Mayonaise-dressing'
							}
						},
						'41040102': {
							'id': 41040102,
							'desc': {
								'nl': 'Jermayo pot 500ml',
								'fr': 'Base tomate'
							}
						},
						'41040103': {
							'id': 41040103,
							'desc': {
								'nl': 'Didden squeezers 300ml',
								'fr': 'Vinaigrette'
							}
						},
						'41040104': {
							'id': 41040104,
							'desc': {
								'nl': 'Didden grand-mère',
								'fr': ''
							}
						},
						'41040105': {
							'id': 41040105,
							'desc': {
								'nl': 'Didden doypack',
								'fr': 'Dressing'
							}
						},
						'41040106': {
							'id': 41040106,
							'desc': {
								'nl': 'Delino pot',
								'fr': 'Sauces diverses'
							}
						},
						'41040107': {
							'id': 41040107,
							'desc': {
								'nl': 'Maurice Mathieu',
								'fr': 'Confits'
							}
						},
						'41040108': {
							'id': 41040108,
							'desc': {
								'nl': 'Ketchup Zeisner',
								'fr': 'TOPPINGS'
							}
						},
						'41040110': {
							'id': 41040110,
							'desc': {
								'nl': 'Guilty Free Verstegen',
								'fr': "Assortiment Daily's 500g"
							}
						},
						'41040200': {
							'id': 41040200,
							'desc': {
								'nl': 'VINAIGRETTE-DRESSING',
								'fr': 'SAUCES CULINAIRES'
							}
						},
						'41040201': {
							'id': 41040201,
							'desc': {
								'nl': 'Vinaigrettes Delino',
								'fr': 'Sauces culinaires - diverses'
							}
						},
						'41040202': {
							'id': 41040202,
							'desc': {
								'nl': 'Vinaigrettes Jermayo',
								'fr': 'Sauce culinaire Verstegen 80ml'
							}
						},
						'41040203': {
							'id': 41040203,
							'desc': {
								'nl': 'Dressings Didden',
								'fr': 'Sauce culin.Verstegen warm-up'
							}
						},
						'41040204': {
							'id': 41040204,
							'desc': {
								'nl': 'Dressings diverse merken',
								'fr': 'Sauce culin.Verstegen divers'
							}
						},
						'41040300': {
							'id': 41040300,
							'desc': {
								'nl': 'KONFIJTEN',
								'fr': ''
							}
						},
						'41040301': {
							'id': 41040301,
							'desc': {
								'nl': 'Konfijten Didden',
								'fr': ''
							}
						},
						'41040400': {
							'id': 41040400,
							'desc': {
								'nl': 'CULINAIRE SAUSEN',
								'fr': ''
							}
						},
						'41040402': {
							'id': 41040402,
							'desc': {
								'nl': 'Diverse culinaire sausen',
								'fr': ''
							}
						},
						'41040403': {
							'id': 41040403,
							'desc': {
								'nl': 'Sausbekers 365ml',
								'fr': ''
							}
						},
						'41040404': {
							'id': 41040404,
							'desc': {
								'nl': 'Saus tube  80ml',
								'fr': ''
							}
						},
						'41050000': {
							'id': 41050000,
							'desc': {
								'nl': 'KRUIDEN',
								'fr': 'FINES HERBES'
							}
						},
						'41050200': {
							'id': 41050200,
							'desc': {
								'nl': 'KRUIDEN VERSTEGEN',
								'fr': 'FINES HERBES VERSTEGEN'
							}
						},
						'41050201': {
							'id': 41050201,
							'desc': {
								'nl': 'Handzakje',
								'fr': 'Petits sticks liquide'
							}
						},
						'41050202': {
							'id': 41050202,
							'desc': {
								'nl': 'VSZ zipper sachet',
								'fr': 'Petits sticks sec'
							}
						},
						'41050204': {
							'id': 41050204,
							'desc': {
								'nl': 'VSZ sachet',
								'fr': 'Saupoudreuse maxi'
							}
						},
						'41050205': {
							'id': 41050205,
							'desc': {
								'nl': 'Sachet droog',
								'fr': 'Super Jar'
							}
						},
						'41050210': {
							'id': 41050210,
							'desc': {
								'nl': 'Glazen strooier',
								'fr': 'Verre'
							}
						},
						'41050211': {
							'id': 41050211,
							'desc': {
								'nl': 'Glazen molen',
								'fr': 'Sauproudeuses ovales'
							}
						},
						'41050212': {
							'id': 41050212,
							'desc': {
								'nl': 'Glazen pot',
								'fr': ''
							}
						},
						'41050213': {
							'id': 41050213,
							'desc': {
								'nl': 'Superjar',
								'fr': ''
							}
						},
						'41050215': {
							'id': 41050215,
							'desc': {
								'nl': 'Mini strooier',
								'fr': ''
							}
						},
						'41060000': {
							'id': 41060000,
							'desc': {
								'nl': 'BAKKERIJ-PRODUCTEN',
								'fr': 'PRODUITS DE BOULANGERIE'
							}
						},
						'41060100': {
							'id': 41060100,
							'desc': {
								'nl': 'BROOD',
								'fr': 'PAIN'
							}
						},
						'41060101': {
							'id': 41060101,
							'desc': {
								'nl': 'Brood voorverpakt',
								'fr': 'Pain'
							}
						},
						'41060102': {
							'id': 41060102,
							'desc': {
								'nl': 'Broodjes voorverpakt',
								'fr': 'Pain à la pièce'
							}
						},
						'41060105': {
							'id': 41060105,
							'desc': {
								'nl': 'Toast',
								'fr': 'Toast'
							}
						},
						'41060200': {
							'id': 41060200,
							'desc': {
								'nl': 'KOEKEN',
								'fr': 'BISCUITS'
							}
						},
						'41060201': {
							'id': 41060201,
							'desc': {
								'nl': 'Wafels',
								'fr': 'Gauffres'
							}
						},
						'41070000': {
							'id': 41070000,
							'desc': {
								'nl': "PASTA'S",
								'fr': ''
							}
						},
						'41070100': {
							'id': 41070100,
							'desc': {
								'nl': 'Pasta',
								'fr': ''
							}
						},
						'41070101': {
							'id': 41070101,
							'desc': {
								'nl': 'Pasta Buonsenso',
								'fr': 'Oeufs frais'
							}
						},
						'41080000': {
							'id': 41080000,
							'desc': {
								'nl': 'OLIEN',
								'fr': 'HUILES'
							}
						},
						'41080100': {
							'id': 41080100,
							'desc': {
								'nl': 'OLIEN',
								'fr': 'HUILES'
							}
						},
						'41080101': {
							'id': 41080101,
							'desc': {
								'nl': 'Fondueolie',
								'fr': 'Huile à fondue'
							}
						},
						'41080102': {
							'id': 41080102,
							'desc': {
								'nl': 'Olijfolie',
								'fr': "Huile d'olive"
							}
						},
						'41090000': {
							'id': 41090000,
							'desc': {
								'nl': 'SNACKING',
								'fr': 'CONFISERIE'
							}
						},
						'41090100': {
							'id': 41090100,
							'desc': {
								'nl': 'SNACKING',
								'fr': 'PÂTE-À-TARTINER'
							}
						},
						'41090101': {
							'id': 41090101,
							'desc': {
								'nl': 'Assortiment Pernoix',
								'fr': 'Choco'
							}
						},
						'41090102': {
							'id': 41090102,
							'desc': {
								'nl': 'Chips',
								'fr': 'stroop'
							}
						},
						'41090103': {
							'id': 41090103,
							'desc': {
								'nl': 'Spekchips',
								'fr': ''
							}
						},
						'41100000': {
							'id': 41100000,
							'desc': {
								'nl': 'DRANKEN',
								'fr': 'BOISSONS'
							}
						},
						'41100200': {
							'id': 41100200,
							'desc': {
								'nl': 'DRANKEN',
								'fr': 'BOISSONS'
							}
						},
						'41100201': {
							'id': 41100201,
							'desc': {
								'nl': 'Waters',
								'fr': 'Jus de fruits'
							}
						},
						'41100202': {
							'id': 41100202,
							'desc': {
								'nl': 'Frisdrank',
								'fr': 'Frisdrank'
							}
						},
						'41100203': {
							'id': 41100203,
							'desc': {
								'nl': 'Alcoholhoudende dranken',
								'fr': 'Boissons alcoolisées'
							}
						},
						'50000000': {
							'id': 50000000,
							'desc': {
								'nl': 'ZELFBEDIENING NON - FOOD',
								'fr': 'NON FOOD'
							}
						},
						'51000000': {
							'id': 51000000,
							'desc': {
								'nl': 'DIVERSEN - NON FOOD',
								'fr': 'NON FOOD DIVERS'
							}
						},
						'51010000': {
							'id': 51010000,
							'desc': {
								'nl': 'DIVERSEN - NON FOOD',
								'fr': 'NON FOOD DIVERS'
							}
						},
						'51010100': {
							'id': 51010100,
							'desc': {
								'nl': 'BARBECUE',
								'fr': 'NON FOOD DIVERS'
							}
						},
						'51010101': {
							'id': 51010101,
							'desc': {
								'nl': 'Houtskool',
								'fr': 'Barbecue'
							}
						},
						'91000000': {
							'id': 91000000,
							'desc': {
								'nl': 'UITRUSTING',
								'fr': ''
							}
						},
						'91010000': {
							'id': 91010000,
							'desc': {
								'nl': 'Materialen',
								'fr': ''
							}
						},
						'91010100': {
							'id': 91010100,
							'desc': {
								'nl': 'VERPAKKING',
								'fr': ''
							}
						},
						'91010101': {
							'id': 91010101,
							'desc': {
								'nl': 'Inpakpapier',
								'fr': ''
							}
						},
						'91010102': {
							'id': 91010102,
							'desc': {
								'nl': 'eierdoosjes',
								'fr': ''
							}
						},
						'91010103': {
							'id': 91010103,
							'desc': {
								'nl': 'potjes en deksels',
								'fr': ''
							}
						},
						'91010104': {
							'id': 91010104,
							'desc': {
								'nl': 'krimpfolie',
								'fr': ''
							}
						},
						'91010200': {
							'id': 91010200,
							'desc': {
								'nl': 'TOONBANKUITRUSTING',
								'fr': ''
							}
						},
						'91010201': {
							'id': 91010201,
							'desc': {
								'nl': 'salade sierpotten',
								'fr': ''
							}
						},
						'91010202': {
							'id': 91010202,
							'desc': {
								'nl': 'decoratiemateriaal',
								'fr': ''
							}
						},
						'91010203': {
							'id': 91010203,
							'desc': {
								'nl': 'snijgerief',
								'fr': ''
							}
						},
						'91010300': {
							'id': 91010300,
							'desc': {
								'nl': 'PRODUCT-TOEBEHOREN',
								'fr': ''
							}
						},
						'91010301': {
							'id': 91010301,
							'desc': {
								'nl': 'Brochettestokjes',
								'fr': ''
							}
						},
						'91010302': {
							'id': 91010302,
							'desc': {
								'nl': 'Papieren onderleggers',
								'fr': ''
							}
						},
						'91010500': {
							'id': 91010500,
							'desc': {
								'nl': 'WINKELUITRUSTING',
								'fr': ''
							}
						},
						'91010501': {
							'id': 91010501,
							'desc': {
								'nl': 'schapuitrusting',
								'fr': ''
							}
						},
						'91010800': {
							'id': 91010800,
							'desc': {
								'nl': 'BESCHERMINGSMATERIAAL',
								'fr': ''
							}
						},
						'91010801': {
							'id': 91010801,
							'desc': {
								'nl': 'Mondmaskers',
								'fr': ''
							}
						},
						'91010802': {
							'id': 91010802,
							'desc': {
								'nl': 'Handschoenen',
								'fr': ''
							}
						},
						'92000000': {
							'id': 92000000,
							'desc': {
								'nl': 'Promotiemateriaal',
								'fr': ''
							}
						},
						'92010000': {
							'id': 92010000,
							'desc': {
								'nl': 'Promotiemateriaal',
								'fr': ''
							}
						},
						'92010100': {
							'id': 92010100,
							'desc': {
								'nl': 'PROMOTIEMATERIAAL',
								'fr': ''
							}
						},
						'92010101': {
							'id': 92010101,
							'desc': {
								'nl': 'Gadgets & Gifts',
								'fr': ''
							}
						},
						'92010102': {
							'id': 92010102,
							'desc': {
								'nl': 'Productdisplays',
								'fr': ''
							}
						},
						'92010103': {
							'id': 92010103,
							'desc': {
								'nl': 'Affiches',
								'fr': ''
							}
						}
					},
					'groups': {
						'1': {
							'desc': {
								'nl': 'HAMMEN',
								'fr': 'JAMBONS'
							},
							'id': 1,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'HAMMEN GOUD',
										'fr': 'JAMBONS DORES'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'HAMMEN GROEN',
										'fr': 'JAMBONS VERTS'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'HAMMEN ROOD',
										'fr': 'JAMBONS ROUGES'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'SEIZOENSHAMMEN',
										'fr': 'JAMBONS SAISONNIERS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'5': {
							'desc': {
								'nl': 'HAMMEN GEROOKT-GEZOUTEN',
								'fr': 'JAMBONS FUMES-SALES'
							},
							'id': 5,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'COBOURG EIGEN PRODUCTIE',
										'fr': 'COBOURG PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'COBOURG ALGEMEEN',
										'fr': 'COBOURG GENERAL'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'RAUWE HAMMEN',
										'fr': 'JAMBONS CRUS'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'SPAANSE HAMMEN',
										'fr': 'JAMBONS ESPAGNOLS'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'ITALIAANSE HAMMEN',
										'fr': 'JAMBONS ITALIENS'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'DUITSE HAMMEN',
										'fr': 'JAMBONS ALLEMANDS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'10': {
							'desc': {
								'nl': 'GEKOOKTE BACON EN SPEK',
								'fr': 'BACON ET LARD CUIT'
							},
							'id': 10,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'15': {
							'desc': {
								'nl': 'RUNDSFILET',
								'fr': "FILET D'ANVERS"
							},
							'id': 15,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'20': {
							'desc': {
								'nl': 'PAARDENVLEES',
								'fr': 'VIANDE DE CHEVAL'
							},
							'id': 20,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'PAARD EXTRA ZOET',
										'fr': 'VIANDE CHEVAL EXTRA DOUCE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'PAARDENVLEES ZOET',
										'fr': 'VIANDE CHEVAL DOUCE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PAARD BOON 3',
										'fr': 'GROSSE TETE DE CHEVAL'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'PAARD BLOKVORM + ONDERBIL',
										'fr': 'BLOC + PLATE CUISSE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'25': {
							'desc': {
								'nl': 'BACON GER./GEZ.',
								'fr': 'BACON FUME/SALE'
							},
							'id': 25,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'30': {
							'desc': {
								'nl': 'SPEK GEROOKT-GEZOUTEN',
								'fr': 'LARD FUME - SALE'
							},
							'id': 30,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'35': {
							'desc': {
								'nl': 'FILET DE SAX GEROOKT-GEZO',
								'fr': 'FILET DE SAXE FUME-SALE'
							},
							'id': 35,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'40': {
							'desc': {
								'nl': 'SALAMIS',
								'fr': 'SALAMIS'
							},
							'id': 40,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'44': {
							'desc': {
								'nl': 'BOULOGNE',
								'fr': 'BOULOGNE'
							},
							'id': 44,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'45': {
							'desc': {
								'nl': 'BOEREWORST',
								'fr': 'COLLIER DE FERME'
							},
							'id': 45,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'50': {
							'desc': {
								'nl': 'FIJNKOST',
								'fr': 'GOURMET'
							},
							'id': 50,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'55': {
							'desc': {
								'nl': 'PEPERSALAMI',
								'fr': 'SALAMI AU POIVRE'
							},
							'id': 55,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'59': {
							'desc': {
								'nl': 'BOLZANO',
								'fr': 'BOLZANO'
							},
							'id': 59,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'60': {
							'desc': {
								'nl': 'SALAMI LIGHT',
								'fr': 'SALAMI LIGHT'
							},
							'id': 60,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TER BEKE',
										'fr': 'TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'65': {
							'desc': {
								'nl': 'BUITENLANDSE SALAMI EN AN',
								'fr': 'SALAMI ETRANGER ET AUTRES'
							},
							'id': 65,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ITALIAANSE SALAMI',
										'fr': 'SALAMI ITALIEN'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SPAANSE SALAMI',
										'fr': 'SALAMI ESPAGNOL'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'DUITSE SALAMI',
										'fr': 'SALAMI ALLEMAND'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'69': {
							'desc': {
								'nl': 'DROGE WORSTEN',
								'fr': 'SAUCISSONS SECS'
							},
							'id': 69,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'DE WULF',
										'fr': 'DE WULF'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SINT-JORIS',
										'fr': 'SINT-JORIS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'70': {
							'desc': {
								'nl': 'KALKOENSALAMI / KIPSALAMI',
								'fr': 'SALAMI DE DINDE/POULET'
							},
							'id': 70,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'73': {
							'desc': {
								'nl': 'PUR PORC',
								'fr': 'PUR PORC'
							},
							'id': 73,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'74': {
							'desc': {
								'nl': 'ARDENSE SALAMI',
								'fr': 'SALAMI ARDENNAIS'
							},
							'id': 74,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'REMY',
										'fr': 'REMY'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'75': {
							'desc': {
								'nl': 'GEVOGELTE KIP',
								'fr': 'VOLAILLE POULET'
							},
							'id': 75,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'KIP EIGEN PRDOUCTIE',
										'fr': 'POULET PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'KIP TER BEKE',
										'fr': 'POULET TER BEKE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'KIP VOLYS',
										'fr': 'POULET VOLYS'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'KIP IMPERIAL',
										'fr': 'POULET IMPERIAL'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'MAISKI',
										'fr': 'MAISKI'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'KIP ANDERE',
										'fr': 'AUTRES'
									}
								},
								'55': {
									'id': 55,
									'desc': {
										'nl': 'VERSE KIP BEREIDINGEN',
										'fr': 'PREP.DE POULET FRAICHES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'77': {
							'desc': {
								'nl': 'MAISKI',
								'fr': 'MAISKI'
							},
							'id': 77,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'LABEL KIP BULK',
										'fr': 'POULET LABEL VRAC'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': 'LABEL KIP VOORVERPAKT',
										'fr': 'POULET LABEL PRE EMBALLE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'KIPPENDELEN BULK',
										'fr': 'DECOUPES POULET CRUS VRAC'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'RAUWE KIPBEREIDINGEN BULK',
										'fr': 'POULET PROD.CRUS VRAC'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'KIP VOORGEBAKKEN,GEPANEER',
										'fr': 'POULET PANEES, PRECUITES'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'VOORGEBAK.,GEPANEERD VVP',
										'fr': 'POUL.PANEES PREC.PREEMB.'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'HAPJES, SPECIAL.VOORGEBAK',
										'fr': 'SPECIAL.AMUSES PRECUITS'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'VEGET.VOORGEBAK.GEPANEERD',
										'fr': 'VEGETARIEN PANEES PRECUIT'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'KANT EN KLARE PASTA',
										'fr': 'PLATS PREPARES PATES'
									}
								},
								'55': {
									'id': 55,
									'desc': {
										'nl': 'KANT EN KLAAR VISGERECHT',
										'fr': 'PLATS PREPARES POISSON'
									}
								},
								'60': {
									'id': 60,
									'desc': {
										'nl': 'KANT EN KLAAR GEVOGELTEGE',
										'fr': 'PLATS PREPARES VOLAILLE'
									}
								},
								'65': {
									'id': 65,
									'desc': {
										'nl': 'KANT EN KLAAR VLEESGERECH',
										'fr': 'PLATS PREPARES VIANDE'
									}
								},
								'70': {
									'id': 70,
									'desc': {
										'nl': 'KANT EN KLAAR FAMILY PACK',
										'fr': 'PLATS PREPARES FAMILY'
									}
								},
								'75': {
									'id': 75,
									'desc': {
										'nl': 'KANT EN KLAAR ASIATISCH',
										'fr': 'PLATS PREPARES ASIATIQUES'
									}
								},
								'80': {
									'id': 80,
									'desc': {
										'nl': 'KANT EN KLAAR KOUDE GEREC',
										'fr': 'PLATS PREPARES PLATS FROI'
									}
								},
								'100': {
									'id': 100,
									'desc': {
										'nl': 'MAALTIJDCOMPONENTEN',
										'fr': 'COMPOSANTS DE REPAS'
									}
								},
								'110': {
									'id': 110,
									'desc': {
										'nl': 'WEIGHTWATCHERS',
										'fr': 'WEIGHTWATCHERS'
									}
								},
								'120': {
									'id': 120,
									'desc': {
										'nl': 'SAUZEN',
										'fr': 'SAUCE'
									}
								},
								'125': {
									'id': 125,
									'desc': {
										'nl': 'DIEPVRIES',
										'fr': 'PRODUITS CONGELES'
									}
								}
							}
						},
						'80': {
							'desc': {
								'nl': 'GEVOGELTE KALKOEN',
								'fr': 'VOLAILLE DINDE'
							},
							'id': 80,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'KALKOEN EIGEN PRODUCTIE',
										'fr': 'DINDE PROPRE  PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'KALKOENWORST',
										'fr': 'SAUCISSON DE DINDE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'KALKOEN TER BEKE',
										'fr': 'DINDE TER BEKE'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'KALKOEN VOLYS',
										'fr': 'DINDE VOLYS'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'KALKOEN IMPERIAL',
										'fr': 'DINDE IMPERIAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'KALKOEN ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'85': {
							'desc': {
								'nl': 'FRICANDON',
								'fr': 'PAIN DE VIANDE'
							},
							'id': 85,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': "OMA'S KOEK",
										'fr': 'PAIN "GRAND-MERE"'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'VLAAMSE FRICANDON',
										'fr': 'PAIN DE VIANDE FLAMAND'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PROVENCAALSE FRICANDON',
										'fr': 'PAIN DE VIANDE PROVENCAL'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'KIPPENFRICANDON',
										'fr': 'PAIN DE VOLAILLE'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'STRASBOURG',
										'fr': 'PAIN DE STRASBOURG'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'SEIZOENSPRODUCTEN',
										'fr': 'PRODUITS DE SAISON'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'KALFSFRICANDON',
										'fr': 'PAIN DE VEAU'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'90': {
							'desc': {
								'nl': 'WORSTEN',
								'fr': 'SAUCISSONS'
							},
							'id': 90,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'KALFSWORST',
										'fr': 'SAUCISSON DE VEAU'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'KALFSWORST ANDERE',
										'fr': 'AUTRES SCSSONS DE VEAU'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'HESPENWORST',
										'fr': 'SAUCISSON DE JAMBON'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'HESPENWORST ANDERE',
										'fr': 'AUTRES SCSSNS DE JAMBON'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'PETIT PARIS',
										'fr': 'PETIT PARIS'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'PETIT PARIS ANDERE',
										'fr': 'AUTRE PETIT PARIS'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'LUNCHWORST',
										'fr': 'SAUCISSON DE PRAGUE'
									}
								},
								'35': {
									'id': 35,
									'desc': {
										'nl': 'LUNCHWORST ANDERE',
										'fr': 'AUTRES SCSSNS DE PRAGUE'
									}
								},
								'40': {
									'id': 40,
									'desc': {
										'nl': 'LOOKWORSTEN',
										'fr': 'CERVELAS'
									}
								},
								'45': {
									'id': 45,
									'desc': {
										'nl': 'LOOKWORSTEN ANDERE',
										'fr': 'AUTRES CERVELAS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'GROENTENWORST',
										'fr': 'SAUCISSON AUX LEGUMES'
									}
								},
								'55': {
									'id': 55,
									'desc': {
										'nl': 'GROENTENWORST ANDERE',
										'fr': 'AUTRES SCSSNS AUX LEGUMES'
									}
								},
								'60': {
									'id': 60,
									'desc': {
										'nl': 'FIGUURWORST',
										'fr': 'SAUCISSON FIGURE'
									}
								},
								'65': {
									'id': 65,
									'desc': {
										'nl': 'FIGUURWORST ANDERE',
										'fr': 'AUTRES SCSSNS FIGURE'
									}
								},
								'70': {
									'id': 70,
									'desc': {
										'nl': 'BBQ WORSTEN',
										'fr': 'SAUCISSON BBQ'
									}
								},
								'75': {
									'id': 75,
									'desc': {
										'nl': 'BBQ WORSTEN ANDERE',
										'fr': 'AUTRES SAUCISSONS BBQ'
									}
								},
								'80': {
									'id': 80,
									'desc': {
										'nl': 'BAKWORST',
										'fr': 'SAUCISSON A CUIRE'
									}
								},
								'85': {
									'id': 85,
									'desc': {
										'nl': 'BAKWORST ANDERE',
										'fr': 'AUTRES SCSSNS A CUIRE'
									}
								},
								'90': {
									'id': 90,
									'desc': {
										'nl': 'CHAMPIGNONWORST',
										'fr': 'SAUCISSON AUX CHAMPIGN.'
									}
								},
								'95': {
									'id': 95,
									'desc': {
										'nl': 'CHAMPIGNONWORST ANDERE',
										'fr': 'AUTRES SCSSNS AUX CHAMP.'
									}
								},
								'100': {
									'id': 100,
									'desc': {
										'nl': 'LANDJAEGERWORST',
										'fr': 'SAUCISSON CHASSEUR'
									}
								},
								'105': {
									'id': 105,
									'desc': {
										'nl': 'LANDJAEGERWORST ANDERE',
										'fr': 'AUTRES SCSSNS CHASSEUR'
									}
								},
								'110': {
									'id': 110,
									'desc': {
										'nl': 'SEIZOENSWORSTEN',
										'fr': 'SAUCISSONS SAISONNIERS'
									}
								},
								'115': {
									'id': 115,
									'desc': {
										'nl': 'SEIZOENSWORSTEN ANDERE',
										'fr': 'AUTRES SCSSNS SAISONNIERS'
									}
								},
								'120': {
									'id': 120,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'125': {
									'id': 125,
									'desc': {
										'nl': 'ZWARTE BEULINGEN',
										'fr': 'BOUDINS NOIRS'
									}
								},
								'130': {
									'id': 130,
									'desc': {
										'nl': 'WITTE BEULINGEN',
										'fr': 'BOUDINS BLANCS'
									}
								},
								'135': {
									'id': 135,
									'desc': {
										'nl': 'KLEINE/VERPAKTE WORSTEN',
										'fr': 'SAUCISSON PETITS/EMBALLES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'95': {
							'desc': {
								'nl': 'CREMEPATE',
								'fr': 'PATE CREME'
							},
							'id': 95,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'VRUCHTENPATE EIGEN PRODUC',
										'fr': 'PATE FRUITS PROPRE PROD.'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'CREME PATE PEPER EIGEN PR',
										'fr': 'PATE CREME POIVRE PR.PROD'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'SEIZOENSPATE',
										'fr': 'PATE SAISONNIER'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'100': {
							'desc': {
								'nl': 'BOERENPATE',
								'fr': 'PATE DE FERME'
							},
							'id': 100,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'PATE DU CHEF',
										'fr': 'PATE DU CHEF'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'PROVENCAALSE PATE',
										'fr': 'PATE PROVENCAL'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'VRUCHTENPATE',
										'fr': 'PATE AUX FRUITS'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'WILDPATE',
										'fr': 'PATE DE GIBIER'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'SEIZOENSPATE',
										'fr': 'PATE SAISONNIER'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'105': {
							'desc': {
								'nl': 'SNIJPATE',
								'fr': 'PATE A DECOUPER'
							},
							'id': 105,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'BLOKPATE',
										'fr': 'PATE EN BLOC'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'LEVERWORST',
										'fr': 'SAUCISSON DE FOIE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'GEBAKKEN PATE',
										'fr': 'PATE AU FOUR'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'110': {
							'desc': {
								'nl': 'SALADES',
								'fr': 'SALADES'
							},
							'id': 110,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'KIPSALADES',
										'fr': 'SALADES DE POULET'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'KIPSALADES ANDERE',
										'fr': 'AUTRES SALADES DE POULET'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'VISSALADES',
										'fr': 'SALADES DE POISSON'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'VISSALADES ANDERE',
										'fr': 'AUTRES SALADES DE POISSON'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'GROENTENSALADES',
										'fr': 'SALADES AUX LEGUMES'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'GROENTENSALADES ANDERE',
										'fr': 'AUTRES SAL.AUX LEGUMES'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'SEIZOENSALADES',
										'fr': 'SALADES SAISONNIERES'
									}
								},
								'35': {
									'id': 35,
									'desc': {
										'nl': 'SEIZOENSALADES ANDERE',
										'fr': 'AUTRES SAL.SAISONNIERES'
									}
								},
								'40': {
									'id': 40,
									'desc': {
										'nl': 'VLEESSALADES',
										'fr': 'SALADES DE VIANDE'
									}
								},
								'45': {
									'id': 45,
									'desc': {
										'nl': 'VLEESSALADES ANDERE',
										'fr': 'AUTRES SALADES DE VIANDE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'PREPARE',
										'fr': 'PREPARE'
									}
								},
								'52': {
									'id': 52,
									'desc': {
										'nl': 'PREPARE ANDERE',
										'fr': 'PREPARE AUTRES'
									}
								},
								'55': {
									'id': 55,
									'desc': {
										'nl': 'PASTASALADES',
										'fr': 'SALADES DE PATE'
									}
								},
								'60': {
									'id': 60,
									'desc': {
										'nl': 'PASTASALADES ANDERE',
										'fr': 'AUTRES SALADES DE PATE'
									}
								},
								'65': {
									'id': 65,
									'desc': {
										'nl': 'HOEMOES',
										'fr': 'HOEMOES'
									}
								},
								'100': {
									'id': 100,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'115': {
							'desc': {
								'nl': 'TRAITEUR PASTAGERECHT',
								'fr': 'TRAITEUR PATES'
							},
							'id': 115,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'117': {
							'desc': {
								'nl': 'TRAITEUR COMPONENTEN',
								'fr': 'TRAITEUR COMPOSANTS'
							},
							'id': 117,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'118': {
							'desc': {
								'nl': 'CHARLES DAGSCHOTELS',
								'fr': 'CHARLES PLATS DE JOUR'
							},
							'id': 118,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'120': {
							'desc': {
								'nl': 'TRAITEUR TAARTEN',
								'fr': 'TRAITEUR TARTES'
							},
							'id': 120,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'122': {
							'desc': {
								'nl': 'CHARLES OVENSCHOTELS',
								'fr': 'CHARLES OVENSCHOTELS'
							},
							'id': 122,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'123': {
							'desc': {
								'nl': 'CHARLES TRAITEUR 900GR',
								'fr': 'CHARLES TRAITEUR 900GR'
							},
							'id': 123,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'124': {
							'desc': {
								'nl': 'TRAITEUR WILDBEREIDINGEN',
								'fr': 'TRAITEUR PLATS DE GIBIER'
							},
							'id': 124,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'125': {
							'desc': {
								'nl': 'TRAITEUR VLEESBEREIDINGEN',
								'fr': 'TRAITEUR PLATS DE VIANDE'
							},
							'id': 125,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'ARTIFRESH',
										'fr': 'ARTIFRESH'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'126': {
							'desc': {
								'nl': 'TRAITEUR VEGETARISCH',
								'fr': 'TRAITEUR VEGETARIEN'
							},
							'id': 126,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'127': {
							'desc': {
								'nl': 'TRAITEUR LUNCH',
								'fr': 'TRAITEUR LUNCH'
							},
							'id': 127,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'LUNCHTIME',
										'fr': 'LUNCHTIME'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'128': {
							'desc': {
								'nl': 'TRAITEUR GEVOGELTE BEREID',
								'fr': 'TRAITEUR PLATS DE VOLAILL'
							},
							'id': 128,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'129': {
							'desc': {
								'nl': 'TRAITEUR VISBEREIDINGEN',
								'fr': 'TRAITEUR PLATS DE POISSON'
							},
							'id': 129,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'130': {
							'desc': {
								'nl': 'TRAITEUR SNACKS',
								'fr': 'TRAITEUR SNACKS'
							},
							'id': 130,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'132': {
							'desc': {
								'nl': 'TRAITEUR GROENTENCOMPON.',
								'fr': 'TRAITEUR COMPOS.LEGUMES'
							},
							'id': 132,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'135': {
							'desc': {
								'nl': 'TRAITEUR PIZZA',
								'fr': 'TRAITEUR PIZZA'
							},
							'id': 135,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'137': {
							'desc': {
								'nl': 'TRAITEUR SOEPEN',
								'fr': 'TRAITEUR SOUPES'
							},
							'id': 137,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'138': {
							'desc': {
								'nl': 'TRAITEUR RIJST',
								'fr': 'TRAITEUR RIZ'
							},
							'id': 138,
							'type': 'A',
							'sub': {
								'5': {
									'id': 5,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'140': {
							'desc': {
								'nl': 'TRAITEUR BLADERDEEG',
								'fr': 'TRAITEUR PATE FEUILLETEE'
							},
							'id': 140,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'143': {
							'desc': {
								'nl': 'TRAITEUR SAUZEN',
								'fr': 'TRAITEUR SAUCES'
							},
							'id': 143,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'145': {
							'desc': {
								'nl': 'SPECIALITEITEN',
								'fr': 'SPECIALITES'
							},
							'id': 145,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'VLEESWAREN',
										'fr': 'CHARCUTERIE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'PATES EN MOUSSES',
										'fr': 'PATES ET MOUSSES'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'GEVULDE DIEREN',
										'fr': 'ANIMAUX FARCIS'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'ITALIAANSE PRODUCTEN',
										'fr': 'PRODUITS ITALIENS'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'RILLETTES',
										'fr': 'RILLETTES'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'VIS',
										'fr': 'POISSON'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'HALAL',
										'fr': 'HALAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'147': {
							'desc': {
								'nl': 'BBQ ASSORTIMENT',
								'fr': 'ASSORTIMENT BBQ'
							},
							'id': 147,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'150': {
							'desc': {
								'nl': 'GEKOOKTE TONG',
								'fr': 'LANGUE DE BOEUF CUIT'
							},
							'id': 150,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'GEPELDE TONG',
										'fr': 'LANGUE DE BOEUF SANS PEAU'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'GEROOKTE TONG',
										'fr': 'LANGUE DE BOEUF FUME'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'WITTE TONG',
										'fr': 'LANGUE DE BOEUF BLANCHE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'155': {
							'desc': {
								'nl': 'KOPPRODUCTEN',
								'fr': 'PRODUITS TETES PRESSEES'
							},
							'id': 155,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'GENTSE KOP EIGEN MERK',
										'fr': 'TTE PRES.GANT.PR.MARQUE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'GENTSE KOP ANDERE',
										'fr': 'AUTRE TETE PRESSEE GANT.'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'TETE DE VEAU EIGEN MERK',
										'fr': 'TETE DE VEAU PR.MARQUE'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'TETE DE VEAU ANDERE',
										'fr': 'AUTRE TETE DE VEAU'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'BREUGHELKOP EIGEN MERK',
										'fr': 'TETE BREUGHEL PR.MARQUE'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'BREUGHELKOP ANDERE',
										'fr': 'AUTRE TETE PRESSEE BREUGH'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'ZURE FRUT EN PRESKOP',
										'fr': 'KIP KAP ET TETE PRESSEE'
									}
								},
								'35': {
									'id': 35,
									'desc': {
										'nl': 'POTJESVLEES',
										'fr': 'POTJEVLEESCH'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'160': {
							'desc': {
								'nl': 'ROSBIEF',
								'fr': 'ROSBIF'
							},
							'id': 160,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'VARKEN',
										'fr': 'PORC'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'RUND',
										'fr': 'BOEUF'
									}
								}
							}
						},
						'165': {
							'desc': {
								'nl': 'UIER EN CORNED BEEF',
								'fr': 'PIS DE VACHE/CORNED BEEF'
							},
							'id': 165,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'UIER',
										'fr': 'PIS DE VACHE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'CORNED BEEF',
										'fr': 'CORNED BEEF'
									}
								}
							}
						},
						'170': {
							'desc': {
								'nl': 'KALFSROL',
								'fr': 'ROULEAU DE VEAU'
							},
							'id': 170,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'KALFSROL',
										'fr': 'ROULEAU DE VEAU'
									}
								}
							}
						},
						'175': {
							'desc': {
								'nl': 'GEKOOKTE LEVER',
								'fr': 'FOIE CUIT'
							},
							'id': 175,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'VARKEN',
										'fr': 'PORC'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'RUND',
										'fr': 'BOEUF'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'KALFS',
										'fr': 'VEAU'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'177': {
							'desc': {
								'nl': 'KEURSLAGERS K-LABEL',
								'fr': 'KEURSLAGERS K-LABEL'
							},
							'id': 177,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'VLEESWAREN',
										'fr': 'CHARCUTERIE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'ZUIVEL',
										'fr': 'PRODUITS LAITIERS'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'CONSERVEN',
										'fr': 'CONSERVES'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'WIJN',
										'fr': 'VIN'
									}
								}
							}
						},
						'180': {
							'desc': {
								'nl': 'SAUZEN',
								'fr': 'SAUCES'
							},
							'id': 180,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'MAYONAISE',
										'fr': 'MAYONNAISE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'TOMATENSAUZEN',
										'fr': 'SAUCE AUX TOMATES'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PREPARE SAUS',
										'fr': 'SAUCE PREPARE'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'PROVENCAALSE SAUS',
										'fr': 'SAUCE PROVENCALE'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'CURRY SAUS',
										'fr': 'SAUCE AU CURRY'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'TARTAAR SAUS',
										'fr': 'SAUCE TARTARE'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'MOSTERDSAUS',
										'fr': 'SAUCE A LA MOUTARDE'
									}
								},
								'35': {
									'id': 35,
									'desc': {
										'nl': 'MARTINO',
										'fr': 'MARTINO'
									}
								},
								'40': {
									'id': 40,
									'desc': {
										'nl': 'DRESSINGS',
										'fr': 'ASSAISONNEMENT'
									}
								},
								'45': {
									'id': 45,
									'desc': {
										'nl': 'COCKTAILSAUS',
										'fr': 'SAUCE COCKTAIL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'185': {
							'desc': {
								'nl': 'CONFIJTEN',
								'fr': 'CONFITS'
							},
							'id': 185,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'190': {
							'desc': {
								'nl': 'VERS VLEES',
								'fr': 'VIANDE FRAICHE'
							},
							'id': 190,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'VARKEN',
										'fr': 'PORC'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'RUND',
										'fr': 'BOEUF'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PAARD',
										'fr': 'CHEVAL'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'GEVOGELTE',
										'fr': 'VOLAILLE'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'LAMS',
										'fr': 'AGNEAU'
									}
								}
							}
						},
						'195': {
							'desc': {
								'nl': 'KRUIDEN/SAUZEN VOOR BEREI',
								'fr': 'EPICES/SAUCES PREPARATION'
							},
							'id': 195,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'UNIFINE',
										'fr': 'UNIFINE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'UNILEVER',
										'fr': 'UNILEVER'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'VERSTEGEN',
										'fr': 'VERSTEGEN'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'REJO',
										'fr': 'REJO'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'200': {
							'desc': {
								'nl': 'VAGRO MIGRATIE',
								'fr': 'VAGRO MIGRATIE'
							},
							'id': 200,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'PATE EN MOUSSE',
										'fr': 'PATE ET MOUSSE'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': 'SALAMI',
										'fr': 'SALAMI'
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'KOOKWORSTEN',
										'fr': 'KOOKWORSTEN'
									}
								},
								'4': {
									'id': 4,
									'desc': {
										'nl': 'RUGFILET',
										'fr': 'RUGFILET'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SPEK',
										'fr': 'SPEK'
									}
								}
							}
						},
						'205': {
							'desc': {
								'nl': 'UNILEVER',
								'fr': 'UNILEVER'
							},
							'id': 205,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'UNILEVER',
										'fr': 'UNILEVER'
									}
								}
							}
						},
						'210': {
							'desc': {
								'nl': 'AARDAPPELPRODUCTEN',
								'fr': 'PRODUITS POMMES DE TERRE'
							},
							'id': 210,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'KELDERMAN',
										'fr': 'KELDERMAN'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PEKA',
										'fr': 'PEKA'
									}
								},
								'13': {
									'id': 13,
									'desc': {
										'nl': 'CORNELIS-ADRIAENSSEN',
										'fr': 'CORNELIS-ADRIAENSSEN'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'VERSE FRIETEN',
										'fr': 'POMMES FRITES FRAIS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'300': {
							'desc': {
								'nl': 'HOLLANDSE KAAS',
								'fr': 'FROMAGES HOLLANDAIS'
							},
							'id': 300,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'NH JONG',
										'fr': 'NH JEUNE'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': 'NH BELEGEN',
										'fr': 'NH MI-VIEUX'
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'NH OUD',
										'fr': 'NH VIEUX'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SLAGERSKAAS JONG',
										'fr': 'FROM.DU BOUCHER JEUNE'
									}
								},
								'6': {
									'id': 6,
									'desc': {
										'nl': 'SLAGERSKAAS BELEGEN',
										'fr': 'FROM.DU BOUCHER MI-VIEUX'
									}
								},
								'7': {
									'id': 7,
									'desc': {
										'nl': 'SLAGERSKAAS OUD',
										'fr': 'FROM.DU BOUCHER VIEUX'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'BEEMSTERKAAS JONG',
										'fr': 'FROM.BEEMSTER JEUNE'
									}
								},
								'11': {
									'id': 11,
									'desc': {
										'nl': 'BEEMSTERKAAS BELEGEN',
										'fr': 'FROM.BEEMSTER MI-VIEUX'
									}
								},
								'12': {
									'id': 12,
									'desc': {
										'nl': 'BEEMSTERKAAS OUD',
										'fr': 'FROM.BEEMSTER VIEUX'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'HB JONG',
										'fr': 'HB JEUNE'
									}
								},
								'16': {
									'id': 16,
									'desc': {
										'nl': 'HB BELEGEN',
										'fr': 'HB MI-VIEUX'
									}
								},
								'17': {
									'id': 17,
									'desc': {
										'nl': 'HB OUD',
										'fr': 'HB VIEUX'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'305': {
							'desc': {
								'nl': 'HOTELBLOK',
								'fr': 'HOTELBLOK'
							},
							'id': 305,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'JONG',
										'fr': 'JEUNE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BELEGEN',
										'fr': 'MI-VIEUX'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'310': {
							'desc': {
								'nl': 'LIGHT KAZEN',
								'fr': 'FROMAGES LIGHT'
							},
							'id': 310,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'WESTLAND',
										'fr': 'WESTLAND'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BEEMSTER',
										'fr': 'BEEMSTER'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'315': {
							'desc': {
								'nl': 'BELGISCHE KAZEN',
								'fr': 'FROMAGES BELGES'
							},
							'id': 315,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'PASSENDALE',
										'fr': 'PASSENDALE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'MAREDSOUS',
										'fr': 'MAREDSOUS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'320': {
							'desc': {
								'nl': 'FRANSE KAZEN',
								'fr': 'FROMAGES FRANCAIS'
							},
							'id': 320,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'BONGRAIN',
										'fr': 'BONGRAIN'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'325': {
							'desc': {
								'nl': 'ZWITSERSE KAZEN',
								'fr': 'FROMAGES SUISSES'
							},
							'id': 325,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EMMENTAL',
										'fr': 'EMMENTAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'326': {
							'desc': {
								'nl': 'OOSTENRIJKSE KAZEN',
								'fr': 'FROMAGES AUSTRICHIENS'
							},
							'id': 326,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'ALGEMEEN'
									}
								}
							}
						},
						'328': {
							'desc': {
								'nl': 'DEENSE KAZEN',
								'fr': 'FROMAGES DANOIS'
							},
							'id': 328,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'330': {
							'desc': {
								'nl': 'FINSE KAZEN',
								'fr': 'FROMAGES FINNOIS'
							},
							'id': 330,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EMMENTAL',
										'fr': 'EMMENTAL'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'335': {
							'desc': {
								'nl': 'DUITSE KAZEN',
								'fr': 'FROMAGES ALLEMANDS'
							},
							'id': 335,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'336': {
							'desc': {
								'nl': 'ENGELSE KAZEN',
								'fr': 'FROMAGES ANGLAIS'
							},
							'id': 336,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'337': {
							'desc': {
								'nl': 'AMERIKAANSE KAZEN',
								'fr': 'FROMAGES AMERICAIN'
							},
							'id': 337,
							'type': 'Z',
							'sub': {
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'340': {
							'desc': {
								'nl': 'PASCHKA KAZEN',
								'fr': 'FROMAGES PASCHKA'
							},
							'id': 340,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'PASTA',
										'fr': 'PATE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'CHOCA',
										'fr': 'CHOCA'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PASCHKA',
										'fr': 'PASCHKA'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'CHOMELKA',
										'fr': 'CHOMELKA'
									}
								}
							}
						},
						'345': {
							'desc': {
								'nl': 'BRIE',
								'fr': 'BRIE'
							},
							'id': 345,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'FRANSE BRIE',
										'fr': 'BRIE FRANCAIS'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BELGISCHE BRIE',
										'fr': 'BRIE BELGE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'350': {
							'desc': {
								'nl': 'CAMEMBERT',
								'fr': 'CAMEMBERT'
							},
							'id': 350,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'355': {
							'desc': {
								'nl': 'GEITENKAAS',
								'fr': 'FROMAGE DE CHEVRE'
							},
							'id': 355,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'360': {
							'desc': {
								'nl': 'ITALIAANSE KAZEN',
								'fr': 'FROMAGES ITALIENS'
							},
							'id': 360,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'GALBANI',
										'fr': 'GALBANI'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'KOE',
										'fr': 'VACHE'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'365': {
							'desc': {
								'nl': 'SPAANSE KAZEN',
								'fr': 'FROMAGES ESPAGNOLS'
							},
							'id': 365,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'370': {
							'desc': {
								'nl': 'GRIEKSE KAZEN',
								'fr': 'FROMAGES GRECS'
							},
							'id': 370,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'FETA',
										'fr': 'FETA'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'375': {
							'desc': {
								'nl': 'SMEERKAAS',
								'fr': 'FROMAGE FONDU'
							},
							'id': 375,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'SANTANE',
										'fr': 'SANTANE'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'EFFI',
										'fr': 'EFFI'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'LA VACHE QUI RIT',
										'fr': 'LA VACHE QUI RIT'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'MAREDSOUS',
										'fr': 'MAREDSOUS'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'PHILADELPHIA',
										'fr': 'PHILADELPHIA'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'380': {
							'desc': {
								'nl': 'PLATTE KAAS',
								'fr': 'FROMAGE FRAIS'
							},
							'id': 380,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'MAGERE PLATTE KAAS',
										'fr': 'FROMAGE FRAIS MAIGRE'
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'HALFVETTE PLATTE KAAS',
										'fr': 'FROMAGE FRAIS MAT.GRAS'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'VETTE PLATTE KAAS',
										'fr': 'FROMAGE FRAIS GRAS'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'MET FRUIT',
										'fr': 'FROMAGE FRAIS AU FRUIT'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'385': {
							'desc': {
								'nl': 'KAAS VOORVERPAKT',
								'fr': 'FROMAGE PRE TRANCHE'
							},
							'id': 385,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'400': {
							'desc': {
								'nl': 'ROOM',
								'fr': 'CREME'
							},
							'id': 400,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'DEBIC',
										'fr': 'DEBIC'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'INEX',
										'fr': 'INEX'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'405': {
							'desc': {
								'nl': 'BOTER',
								'fr': 'BEURRE'
							},
							'id': 405,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'CREMEBOTER',
										'fr': 'BEURRE A LA CREME'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BAKBOTER',
										'fr': 'BEURRE A CUIRE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'SMEERBOTER',
										'fr': 'BEURRE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'410': {
							'desc': {
								'nl': 'EIEREN',
								'fr': 'OEUFS'
							},
							'id': 410,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'415': {
							'desc': {
								'nl': 'MELK',
								'fr': 'LAIT'
							},
							'id': 415,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'INEX',
										'fr': 'INEX'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'FRUITMELK',
										'fr': 'LAIT FRUITIER'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'CHOCOMELK',
										'fr': 'LAIT AU CHOCOLAT'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'420': {
							'desc': {
								'nl': 'VERS BROOD',
								'fr': 'PAIN FRAIS'
							},
							'id': 420,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'450': {
							'desc': {
								'nl': 'DESSERTEN',
								'fr': 'DESSERTS'
							},
							'id': 450,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'YOGHURT',
										'fr': 'YAOURT'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'RIJSTPAP',
										'fr': 'RIZ AU LAIT'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'TAARTEN',
										'fr': 'GATEAUX'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'FLAN',
										'fr': 'FLAN'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'455': {
							'desc': {
								'nl': 'CHOCO',
								'fr': 'PATE A TARTINER CHOCOLAT'
							},
							'id': 455,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BOERINNEKE',
										'fr': 'BOERINNEKE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'460': {
							'desc': {
								'nl': 'DRANKEN',
								'fr': 'BOISSONS'
							},
							'id': 460,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'FRUITSAP',
										'fr': 'JUS DE FRUITS'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SEIZOENSDRANKEN',
										'fr': 'BOISSONS SAISONNIERES'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'GEESTRIJKE',
										'fr': 'SPIRITUEL'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'FRISDRANK EN WATER',
										'fr': 'BOISSONS FRAIS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'500': {
							'desc': {
								'nl': 'CHARLES SLICING',
								'fr': 'SLICING CHARLES'
							},
							'id': 500,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'CHARLES VVP',
										'fr': 'CHARLES PE'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': "BELGIUM'S FINEST",
										'fr': "BELGIUM'S FINEST"
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'CHARLES FRESH',
										'fr': 'CHARLES FRESH'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'HELE WORSTEN',
										'fr': 'SAUCISSES ENTIERES'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'SEIZOENS',
										'fr': 'DE SAISON'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'BAKKERSBELEG',
										'fr': 'BAKKERSBELEG'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': '4X250GR EIGEN PRODUCTIE',
										'fr': '4X250GR PROPRE PRODUCTION'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'GROOTVERPAKKING EP',
										'fr': 'VRAC PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'505': {
							'desc': {
								'nl': 'CHARLES KAAS',
								'fr': 'FROMAGE CHARLES'
							},
							'id': 505,
							'type': 'F',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'BEEMSTERKAAS',
										'fr': 'FROMAGE BEEMSTER'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': 'CHARLES VVP',
										'fr': 'CHARLES PE'
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'CHARLES FRESH',
										'fr': 'CHARLES FRESH'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'BAKKERSBELEG',
										'fr': 'BAKKERSBELEG'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'510': {
							'desc': {
								'nl': 'CHARLES SALADES',
								'fr': 'SALADES CHARLES'
							},
							'id': 510,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': 'EIGEN PRODUCTIE 120GR',
										'fr': 'PROPRE PRODUCTION 120GR'
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'EIGEN PRODUCTIE 185GR',
										'fr': 'PROPRE PRODUCTION 185GR'
									}
								},
								'4': {
									'id': 4,
									'desc': {
										'nl': 'EIGEN PRODUCTIE 150GR',
										'fr': 'PROPRE PRODUCTION 150GR'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'SEIZOENS',
										'fr': 'SAISONNIERES'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'BAKKERSBELEG',
										'fr': 'BAKKERSBELEG'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'BLANCO SALADES 160GR',
										'fr': 'BLANCO SALADES 160GR'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'70': {
									'id': 70,
									'desc': {
										'nl': 'ALBERT HEIJN 150GR',
										'fr': 'ALBERT HEIJN 150GR'
									}
								}
							}
						},
						'515': {
							'desc': {
								'nl': 'CHARLES STUKGOED VVP',
								'fr': 'CHARLES STUKGOED PE'
							},
							'id': 515,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'517': {
							'desc': {
								'nl': 'CHARLES TAPAS',
								'fr': 'CHARLES TAPAS'
							},
							'id': 517,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'520': {
							'desc': {
								'nl': 'CHARLES TRAITEUR',
								'fr': 'TRAITEUR CHARLES'
							},
							'id': 520,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'EIGEN PRODUCTIE',
										'fr': 'PROPRE PRODUCTION'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'SEIZOENS',
										'fr': 'SAISONNIER'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'525': {
							'desc': {
								'nl': 'CHARLES MATERIAAL',
								'fr': 'MATERIAUX CHARLES'
							},
							'id': 525,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'550': {
							'desc': {
								'nl': 'CHARLES AD WENDUINE',
								'fr': 'CHARLES AD WENDUINE'
							},
							'id': 550,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'570': {
							'desc': {
								'nl': "DAILY'S CHARCUTERIE VVP",
								'fr': "DAILY'S CHARCUTERIE P.EMB"
							},
							'id': 570,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SEIZOEN',
										'fr': 'SAISON'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'575': {
							'desc': {
								'nl': "DAILY'S KAAS VVP",
								'fr': "DAILY'S FROMAGE PR.EMB."
							},
							'id': 575,
							'type': 'Z',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SEIZOEN',
										'fr': 'SAISON'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'580': {
							'desc': {
								'nl': 'VVP CHARCUTERIE NEP',
								'fr': 'VVP CHARCUTERIE NEP'
							},
							'id': 580,
							'type': 'V',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'MALETTI',
										'fr': 'MALETTI'
									}
								},
								'2': {
									'id': 2,
									'desc': {
										'nl': 'ZWANEBERG',
										'fr': 'ZWANEBERG'
									}
								},
								'3': {
									'id': 3,
									'desc': {
										'nl': 'ERNELL',
										'fr': 'ERNELL'
									}
								},
								'4': {
									'id': 4,
									'desc': {
										'nl': 'IMPERIAL',
										'fr': 'IMPERIAL'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'GANDA/CORMA',
										'fr': 'GANDA/CORMA'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'SALAMI',
										'fr': 'SALAMI'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'KOOKWAREN',
										'fr': 'SAUCISSONS'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'KOP/ASPIC',
										'fr': 'TETES PRESSEES'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'HAM GEKOOKT',
										'fr': 'JAMBONS'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'HAM GEDROOGD',
										'fr': 'JAMBONS FUMES-SALES'
									}
								},
								'35': {
									'id': 35,
									'desc': {
										'nl': 'RUGFILET',
										'fr': 'RUGFILET'
									}
								},
								'40': {
									'id': 40,
									'desc': {
										'nl': 'SPEK',
										'fr': 'BACON'
									}
								},
								'45': {
									'id': 45,
									'desc': {
										'nl': 'GEVOGELTE',
										'fr': 'VOLAILLE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'PAARD',
										'fr': 'CHEVAL'
									}
								},
								'55': {
									'id': 55,
									'desc': {
										'nl': 'RUND',
										'fr': 'BOEUF'
									}
								},
								'60': {
									'id': 60,
									'desc': {
										'nl': 'LEVERPRODUCTEN',
										'fr': 'FOIE PRODUITS'
									}
								},
								'65': {
									'id': 65,
									'desc': {
										'nl': 'SPECIALITEITEN',
										'fr': 'SPECIALITES'
									}
								}
							}
						},
						'585': {
							'desc': {
								'nl': 'MY SALADS LE COBOURG VVP',
								'fr': 'MY SALADS LE COBOURG P.EM'
							},
							'id': 585,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'590': {
							'desc': {
								'nl': 'VVP SALADES NEP',
								'fr': 'P.EMB SALADES NEP'
							},
							'id': 590,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'HAMAL',
										'fr': 'HAMAL'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'JEBO',
										'fr': 'JEBO'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'STEEN',
										'fr': 'STEEN'
									}
								}
							}
						},
						'591': {
							'desc': {
								'nl': 'SALSA SALADES VVP',
								'fr': 'SALADES SALSA P.EMB.'
							},
							'id': 591,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'600': {
							'desc': {
								'nl': 'DIEPVRIES',
								'fr': 'PRODUITS SURGELES'
							},
							'id': 600,
							'type': 'C',
							'sub': {
								'5': {
									'id': 5,
									'desc': {
										'nl': 'LAMS',
										'fr': 'AGNEAU'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'VLEES',
										'fr': 'VIANDE'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'SCHNITZELS',
										'fr': 'SCHNITZELS'
									}
								},
								'17': {
									'id': 17,
									'desc': {
										'nl': 'SNACKS',
										'fr': 'SNACKS'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'SOEPBALLETJES',
										'fr': 'BOULETTES DE VIANDE'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'FRIETEN',
										'fr': 'POMMES FRITES'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'KROKETTEN & CO',
										'fr': 'CROQUETTES & CO'
									}
								},
								'32': {
									'id': 32,
									'desc': {
										'nl': 'AARDAPPELEN',
										'fr': 'POMMES DE TERRE'
									}
								},
								'33': {
									'id': 33,
									'desc': {
										'nl': 'PASTA',
										'fr': 'PâTES'
									}
								},
								'35': {
									'id': 35,
									'desc': {
										'nl': 'GROENTEN',
										'fr': 'LEGUMES'
									}
								},
								'40': {
									'id': 40,
									'desc': {
										'nl': 'VIS',
										'fr': 'POISSON'
									}
								},
								'45': {
									'id': 45,
									'desc': {
										'nl': 'BROOD',
										'fr': 'DU PAIN'
									}
								},
								'47': {
									'id': 47,
									'desc': {
										'nl': 'DEEGWAREN',
										'fr': 'PâTES'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'DESSERTS',
										'fr': 'DESSERTS'
									}
								},
								'55': {
									'id': 55,
									'desc': {
										'nl': 'PASTA',
										'fr': 'PATE'
									}
								},
								'60': {
									'id': 60,
									'desc': {
										'nl': 'RIJST',
										'fr': 'RIZ'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'690': {
							'desc': {
								'nl': 'TAPAS',
								'fr': 'TAPAS'
							},
							'id': 690,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'RIDDERHEIM',
										'fr': 'RIDDERHEIM'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'SPECIALITEITEN',
										'fr': 'SPECIALITES'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'695': {
							'desc': {
								'nl': 'VIS',
								'fr': 'POISSON'
							},
							'id': 695,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'HARING',
										'fr': 'HARENG'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'ANSJOVIS',
										'fr': 'ANCHOIS'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'700': {
							'desc': {
								'nl': 'CONSERVEN',
								'fr': 'CONSERVES'
							},
							'id': 700,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': "CONFITUREN L'OURTHE",
										'fr': "CONFITURES L'OURTHE"
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'CONFITUREN ANDERE',
										'fr': 'AUTRES CONFITURES'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': "CONSERVEN COCK'S MERK",
										'fr': "CONSERVES MARQUE COCK'S"
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'CONSERVEN TOPKLASSE',
										'fr': 'CONSERVES TOPKLASSE'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'CONSERVEN CULINAIR',
										'fr': 'CONSERVES CULINAIRES'
									}
								},
								'25': {
									'id': 25,
									'desc': {
										'nl': 'CONSERVEN ALLERLEI',
										'fr': 'TOUTES SORTES DE CONSERV.'
									}
								},
								'30': {
									'id': 30,
									'desc': {
										'nl': 'CONSERVEN DIDDEN',
										'fr': 'CONSERVES DIDDEN'
									}
								}
							}
						},
						'705': {
							'desc': {
								'nl': 'OLIE',
								'fr': 'HUILE'
							},
							'id': 705,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'OLIJFOLIE',
										'fr': "HUILE D'OLIVE"
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'FONDUE OLIE',
										'fr': 'HUILE A FONDUE'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'710': {
							'desc': {
								'nl': 'PASTA',
								'fr': 'PATE'
							},
							'id': 710,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMEEN',
										'fr': 'GENERAL'
									}
								}
							}
						},
						'720': {
							'desc': {
								'nl': 'VERSE GROENTEN EN FRUIT',
								'fr': 'LEGUMES ET FRUITS FRAIS'
							},
							'id': 720,
							'type': 'C',
							'sub': {
								'5': {
									'id': 5,
									'desc': {
										'nl': 'FRUIT',
										'fr': 'FRUITS'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'GROENTEN',
										'fr': 'LEGUMES'
									}
								}
							}
						},
						'730': {
							'desc': {
								'nl': 'COLLI',
								'fr': 'COLLI'
							},
							'id': 730,
							'type': 'C',
							'sub': {
								'5': {
									'id': 5,
									'desc': {
										'nl': 'CHARCUTERIE',
										'fr': 'CHARCUTERIE'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'ZUIVEL',
										'fr': 'ZUIVEL'
									}
								}
							}
						},
						'750': {
							'desc': {
								'nl': 'DARMEN',
								'fr': 'BOYAUX'
							},
							'id': 750,
							'type': 'C',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'SCHAAP',
										'fr': 'MOUTON'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'VARKEN',
										'fr': 'PORC'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								}
							}
						},
						'800': {
							'desc': {
								'nl': 'VERPAKKING/MATERIAAL',
								'fr': 'EMBALLAGE/MATERIAUX'
							},
							'id': 800,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'PAPIER',
										'fr': 'PAPIER'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'POTJES',
										'fr': 'POTS'
									}
								},
								'10': {
									'id': 10,
									'desc': {
										'nl': 'PLASTIEK',
										'fr': 'PLASTIC'
									}
								},
								'15': {
									'id': 15,
									'desc': {
										'nl': 'DOOSJES',
										'fr': 'PETITES BOITES'
									}
								},
								'20': {
									'id': 20,
									'desc': {
										'nl': 'KERAMIEK',
										'fr': 'KERAMIEK'
									}
								},
								'50': {
									'id': 50,
									'desc': {
										'nl': 'ANDERE',
										'fr': 'AUTRES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'850': {
							'desc': {
								'nl': 'HOUTSKOOL',
								'fr': 'CHARBON DE BOIS'
							},
							'id': 850,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'HOUTSKOOL',
										'fr': 'CHARBON DE BOIS'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BRICETTEN',
										'fr': 'BRICETTES'
									}
								}
							}
						},
						'900': {
							'desc': {
								'nl': 'LEEGGOED',
								'fr': 'VIDANGES'
							},
							'id': 900,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'LEEGGOED',
										'fr': 'VIDANGES'
									}
								},
								'5': {
									'id': 5,
									'desc': {
										'nl': 'BAKKEN',
										'fr': 'CRATES'
									}
								},
								'999': {
									'id': 999,
									'desc': {
										'nl': 'VAGRO MIGRATIE',
										'fr': 'VAGRO MIGRATIE'
									}
								}
							}
						},
						'998': {
							'desc': {
								'nl': 'KORTINGEN',
								'fr': 'REMISES'
							},
							'id': 998,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALGEMENE KORTINGEN',
										'fr': 'REMISES GENERALES'
									}
								}
							}
						},
						'999': {
							'desc': {
								'nl': 'DIVERS',
								'fr': 'DIVERS'
							},
							'id': 999,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'ALLERLEI',
										'fr': 'GENERAL'
									}
								},
								'11': {
									'id': 11,
									'desc': {
										'nl': 'SNIJMACHINES',
										'fr': 'MACHINES A COUPER'
									}
								}
							}
						},
						'9000': {
							'desc': {
								'nl': 'DIVERSEN',
								'fr': '?'
							},
							'id': 9000,
							'type': 'A',
							'sub': {
								'1': {
									'id': 1,
									'desc': {
										'nl': 'DIVERSEN',
										'fr': 'DIVERS'
									}
								}
							}
						}
					}
				}
			),
		);
	}),
];
