import React, { useEffect, useState } from 'react';
import './Reports.scss';
import { t } from './../../libraries/i18n';
import Search from '../../components/Search/Search';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReportsIfNeeded, handleFrequencyFilter, handleSearch } from './ReportsActions';
import Filter from '../../libraries/filter';
import {
	selectCustomerId,
	selectReports,
	selectIsReportsFetching,
	selectReportsQuery,
	selectReportsFrequencyFilter
} from './../selectors';
import List from './../../components/List/List';
import Pillbox from './../../components/Pillbox/Pillbox';
import Spinner from './../../components/spinner/spinner';
import FREQUENCY_FILTER from './frequencyFilters';
import { getReportPdfUrl } from '../../libraries/api';
import PdfModal from '../../components/PdfModal/PdfModal';
import Highlight from '../../components/highlight/highlight';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';
import { FaDownload } from 'react-icons/fa';

function Reports() {
	useCustomerRoute();
	const dispatch = useDispatch();
	const reports = useSelector(selectReports);
	const customerId = useSelector(selectCustomerId);
	const isFetching = useSelector(selectIsReportsFetching);
	const query = useSelector(selectReportsQuery);
	const frequencyFilter = useSelector(selectReportsFrequencyFilter);
	const [filteredReports, setFilteredReports] = useState([]);
	const [pdfModal, setPdfModal] = useState({ open: false });

	useEffect(() => {
		dispatch(fetchReportsIfNeeded(customerId));
	}, [customerId, dispatch]);

	useEffect(() => {
		let filteredReports = Filter.filterItemsByQuery(
			frequencyFilter,
			reports,
			[
				{ name: 'frequency' }
			],
			false,
			'OR'
		);

		filteredReports = Filter.filterItemsByQuery(
			query,
			filteredReports,
			[
				{ name: 'name' },
				{ name: 'period' }
			],
			true,
			'AND'
		);
		setFilteredReports(filteredReports);
	}, [query, frequencyFilter, reports]);

	const handleFilter = (filter) => dispatch(handleFrequencyFilter(filter));
	const handleSearchInput = (query) => dispatch(handleSearch(query));

	const openReportPdfModal = report => {
		setPdfModal({
			open: true,
			url: getReportPdfUrl(customerId, report.id),
			downloadUrl: getReportPdfUrl(customerId, report.id, true)
		});
	};

	const renderPdfModal = () => (
		<PdfModal
			file={pdfModal.url}
			downloadUrl={pdfModal.downloadUrl}
			close={() => setPdfModal({ open: false })}
		/>
	);
	const renderNoList = () => {
		if (isFetching) return (<Spinner />);
		if (reports.length === 0) {
			return (
				<p className={'no-reports'}>
					{t('reports.no_reports')}
				</p>
			);
		}
		return (
			<p className={'no-reports'}>
				{t('reports.no_reports_filtered')}
			</p>
		);
	};

	const renderCellName = item => (
		<p className={'report__name'}>
			<Highlight text={item.name} string={query}>{item.name}</Highlight>
		</p>
	);
	const renderCellDescription = item => (
		<p className={'report__description'}>
			<Highlight text={item.description} string={query} >{item.description}</Highlight>
		</p>
	);
	const renderCellDownload = item => (
		<a href={getReportPdfUrl(customerId, item.id, true)} onClick={(e) => e.stopPropagation()}>
			<FaDownload />
		</a>
	);

	const columns = [
		{
			title: t('reports.report_name'),
			key: 'name',
			render: renderCellName
		},
		{
			title: t('reports.report_period'),
			key: 'description',
			render: renderCellDescription
		},
		{
			title: t('reports.report_download'),
			key: 'download',
			render: renderCellDownload
		}
	];

	const pills = [
		{ name: t('reports.frequencyFilter_month'), id: FREQUENCY_FILTER.MONTH },
		{ name: t('reports.frequencyFilter_year'), id: FREQUENCY_FILTER.YEAR },
		{ name: t('reports.frequencyFilter_all'), id: FREQUENCY_FILTER.ALL }
	];

	return (
		<div className='Reports'>
			<h2>{t('reports.title')}</h2>
			<div className={'filter__area'}>
				<Search
					handleSearch={handleSearchInput}
					query={query}
					placeholder={t('reports.searchPlaceholder')}
				/>
				<Pillbox
					pills={pills}
					filter={frequencyFilter}
					handleFilter={handleFilter}
				/>
			</div>
			{filteredReports.length === reports.length &&
				<div className={'view__details'}>
					<div className={'results'}>
						{t('reports.xReportsFound', filteredReports.length)}
					</div>
				</div>
			}
			{filteredReports.length !== reports.length &&
				<div className={'view__details'}>
					<div className={'results'}>
						{t('reports.xOfYReportsFound', filteredReports.length, reports.length)}
					</div>
				</div>
			}
			{filteredReports.length === 0 && renderNoList()}
			{filteredReports.length !== 0 &&
				<List
					header={columns}
					content={filteredReports}
					action={(e, item) => {
						openReportPdfModal(item);
					}}
					gridTemplateColumns={'1fr 1fr 24rem'}
				/>
			}
			{pdfModal.open && renderPdfModal()}

		</div>
	);
}

export default Reports;
