import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectOrderSteps } from './../../modules/selectors';

function useCurrentStep() {
	const location = useLocation();
	const steps = useSelector(selectOrderSteps);
	const [current, setCurrent] = useState(undefined);

	useEffect(() => {
		steps.forEach(step => {
			if (step.route === location.pathname) setCurrent(step);
		});
	}, [location, steps]);

	return current;
}

export default useCurrentStep;
