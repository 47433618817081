import React from 'react';
import {
	Switch,
	Route,
	Redirect,
	useRouteMatch
} from 'react-router-dom';
import FoodEventTickets from './FoodEvent2022/FoodEventTickets';

export default function CustomCampaigns() {
	const match = useRouteMatch();
	const ACTIVE_CAMPAIGN = `${match.path}/foodevent-tickets`;
	const defaultRoute = ACTIVE_CAMPAIGN ?? '/promos';

	return (
		<Switch>
			<Route path={`${match.path}/foodevent-tickets`} component={FoodEventTickets} />
			<Route render={() => <Redirect to={defaultRoute} />} />
		</Switch>
	);
}
