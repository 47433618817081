import React, { useCallback } from 'react';
import { Article } from '@abm-international/react-components';
import './BuylistArticle.scss';

export const getHeight = (size) => {
	const sizes = {
		size935: 154,
		size770: 132,
		size720: 132,
		size630: 132
	};
	const maximumHeight = 168;
	return sizes[size] ?? maximumHeight;
};

const BuylistArticleList = (props) => {
	const {
		sizes,
		showModal,
		product,
		query,
		locale,
		t,
		...restProps
	} = props;

	const isTiny = sizes.includes('size630');
	const isSmall = sizes.includes('size770');
	const isNormal = sizes.includes('size935');

	const openModal = useCallback(() => showModal(product.id), [product.id, showModal]);

	const listLayout = useCallback((A) => (
		<div
			className={`Article list ${sizes.join(' ')}`}
		>
			<A.Image onClick={openModal} />
			<div className={'detail'}>
				<div className={'top'}>
					<A.Id /><A.Brand /><A.Badges size={isTiny ? 'small' : ''} />
				</div>
				<div className={'middle'}>
					<A.BuylistIndicator /><A.Name onClick={openModal} />
				</div>
				<A.Allergens
					compact={isNormal}
					view={isSmall ? 'grid' : ''}
				/>
				<A.OrderHistory size={'compact'} />

			</div>
			<div className={'order'}>
				<A.BuylistAction />
			</div>
			<div className={'bottom'}>
				<div>
					{!isTiny && <A.Campaigns size={isNormal ? 'small' : ''} />}
					<A.Promo />
				</div>
				<div>
					<A.Pending size={isSmall ? 'small' : ''} />
					<A.Reserved size={isSmall ? 'small' : ''} />
				</div>
			</div>
		</div>
	), [sizes, isTiny, isNormal, isSmall, openModal]);

	return (
		<Article
			{...restProps}
			product={product}
			t={t}
			query={query}
			locale={locale}
		>
			{listLayout}
		</Article>
	);
};

export default BuylistArticleList;
