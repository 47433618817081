import { PERMISSIONS } from './../../libraries/permissions';
import { getLocale } from './../../libraries/i18n';
import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';

export const selectCurrentUser = state => state.id;
export const selectCompanyName = state => state.company.name;
export const selectCustomer = state => state.customer ?? {};
export const selectCustomerId = state => selectCustomer(state).id;
export const selectCustomerCode = state => selectCustomer(state).code;
export const selectPermissions = state => state.permissions;
export const selectLocale = state => state.locale || getLocale();
export const selectCanManageBuylist = state => selectPermissions(state).includes(PERMISSIONS.BUYLIST_ADMIN);
export const selectNavigationItems = state => state.routes; // Should actually be in te app state (not customer)

export const selectPreOrderTypes = createSelector(
	selectNavigationItems,
	(navItems) => navItems?.map(({ href, application }) => {
		if (application !== process.env.REACT_APP_APP_NAME) return null;
		const match = matchPath(href, {
			path: '/preorders/:id',
			exact: true
		});
		return match?.params?.id;
	})?.filter(Boolean) ?? []
);


export const selectDeliveryAddress = state => state.addresses.delivery;
export const selectInvoiceAddress = state => state.addresses.invoice;
export const selectCanViewInvoices = state => selectPermissions(state).includes(PERMISSIONS.VIEW_INVOICES);
export const selectCanViewTransportDocuments = state => selectPermissions(state).includes(PERMISSIONS.VIEW_TRANSPORT_DOCUMENTS);
export const selectLastUpdated = state => state.lastUpdated;
export const selectError = state => state.error;
export const selectHasError = state => !!selectCustomer(state).error;
export const selectIsFetching = state => selectCustomer(state).isFetching;
export const selectDidInvalidate = state => state.didInvalidate;
export const selectAccount = state => state.account;
