import React from 'react';
import { Route } from 'react-router-dom';

function RestrictedRoute(props) {
	const Component = props.component;
	return <Route path={props.path}>
		<Component requiredPermission={props.permission} /> {/* implement useParams hook where routing props are accessed */}
	</Route>;
}

export default RestrictedRoute;
