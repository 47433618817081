/* eslint-disable camelcase */

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import useSWR from 'swr';
import { t } from '../../../libraries/i18n';
import { fetcher } from './../../../api';
import useCustomer from './../../../libraries/hooks/useCustomer';
import ContactForm from './ContactForm';

interface ContactData {
	success: boolean,
	errors: {},
	invoicing: {
		name: string,
		customerId: string,
		vatNumber: string,
		address: string,
		postalCode: string,
		city: string,
	},
	delivery: {
		name: string,
		address: string,
		postalCode: string,
		city: string,
	},
	portal: {
		name: string,
		phone1: string,
		phone2: string,
		email: string,
	},
	accounting: {
		accounting_name: string,
		accounting_phone1: string,
		accounting_phone2: string,
		accounting_fax: string,
		accounting_email: string,
	},
	sales: {
		sales_name: string,
		sales_phone1: string,
		sales_phone2: string,
		sales_fax: string,
		sales_email: string,
	},
	marketing: {
		marketing_name: string,
		marketing_phone1: string,
		marketing_phone2: string,
		marketing_fax: string,
		marketing_email: string,
	}
}

const useContactData = (): { data: ContactData | undefined } => {
	const { id } = useCustomer();
	const { data } = useSWR<ContactData | undefined>(`/api/v1/customers/${id}/settings/contact`, fetcher);


	return {
		data
	};
};

const formatContactData = (data: ContactData | undefined) => {
	if (!data) return null;
	const accountingIsSameAsPortal =
		(data.portal?.name === data.accounting?.accounting_name) &&
		(data.portal?.phone1 === data.accounting?.accounting_phone1) &&
		(data.portal?.phone2 === data.accounting?.accounting_phone2) &&
		(data.portal?.email === data.accounting?.accounting_email);

	const salesIsSameAsPortal =
		(data.portal.name === data.sales.sales_name) &&
		(data.portal.phone1 === data.sales.sales_phone1) &&
		(data.portal.phone2 === data.sales.sales_phone2) &&
		(data.portal.email === data.sales.sales_email);

	const marketingIsSameAsPortal =
		(data.portal.name === data.marketing.marketing_name) &&
		(data.portal.phone1 === data.marketing.marketing_phone1) &&
		(data.portal.phone2 === data.marketing.marketing_phone2) &&
		(data.portal.email === data.marketing.marketing_email);

	const invoicingTransformed = {
		nameInvoices: data.invoicing.name,
		customerIdInvoices: data.invoicing.customerId,
		vatNumberInvoices: data.invoicing.vatNumber,
		addressInvoices: data.invoicing.address,
		postalCodeInvoices: data.invoicing.postalCode,
		cityInvoices: data.invoicing.city,
	};

	const deliveryTransformed = {
		nameDelivery: data.delivery.name,
		addressDelivery: data.delivery.address,
		postalCodeDelivery: data.delivery.postalCode,
		cityDelivery: data.delivery.city,
	};

	const portalTransformed = {
		namePortal: data.portal.name,
		phone1Portal: data.portal.phone1,
		phone2Portal: data.portal.phone2,
		emailPortal: data.portal.email,
	};

	return {
		...invoicingTransformed, ...deliveryTransformed, ...portalTransformed, ...data.accounting, accounting_sameAsPortalContact: accountingIsSameAsPortal,
		...data.sales, sales_sameAsPortalContact: salesIsSameAsPortal, ...data.marketing, marketing_sameAsPortalContact: marketingIsSameAsPortal
	};
};



export default function Contact() {

	const { data } = useContactData();
	const formData = formatContactData(data);

	return (
		<div className='Contact'>
			<div className='menu sticky'>
				<HashLink to='#invoicing' className='menu-item Button'>{t('settings.contact_Facturatie')}</HashLink>
				<HashLink to='#delivery' className='menu-item Button'>{t('settings.contact_Levering')}</HashLink>
				<HashLink to='#contact-portal' className='menu-item Button'>{t('settings.contact_Portal')}</HashLink>
				<HashLink to='#accounting' className='menu-item Button'>{t('settings.contact_Boekhouding')}</HashLink>
				<HashLink to='#sales' className='menu-item Button'>{t('settings.contact_Verkoop')}</HashLink>
				<HashLink to='#marketing' className='menu-item Button'>{t('settings.contact_Marketing')}</HashLink>
			</div>
			<div className='cards'>
				<p>{t('settings.contact_text')}</p>
				{data && <ContactForm data={formData} />}
			</div>
		</div>
	);
}
