import { rest } from 'msw';

export const getNl = [
	rest.get('http://localhost:4000/api/translations/applications/portal.client/nl', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(
				{
					'buylist': {
						'button_add_to_buylist': 'Mocking, Toevoegen aan mijn assortiment',
						'button_buylist_action_ok': 'Mocking, Oke',
						'button_buylist_add_cancel': 'Mocking, Annuleren',
						'button_buylist_add_confirm': 'Mocking, Ja, toevoegen',
						'button_buylist_remove_cancel': 'Mocking, Annuleren',
						'button_buylist_remove_confirm': 'Mocking, Ja, verwijderen',
						'label_buylist_action_failed': 'Mocking, Er is iets fout gegaan bij het registreren van uw aanvraag. Gelieve later nog eens opnieuw te proberen.',
						'label_buylist_add_confirm_text': 'Mocking, Bent u zeker dat u dit product aan "mijn assortiment" wilt toevoegen?',
						'label_buylist_remove_confirm_text': 'Mocking, Bent u zeker dat u dit product uit "mijn assortiment" wilt verwijderen?',
						'removeFromBuylistButtonLabel': 'Mocking, Schrappen uit mijn assortiment',
						'removeFromBuylistConfirmNoLabel': 'Mocking, Neen, behouden',
						'removeFromBuylistConfirmText': 'Mocking, Bent u zeker dat u dit product uit "mijn assortiment" wilt verwijderen?',
						'removeFromBuylistConfirmYesLabel': 'Ja, verwijderen',
						'removeFromBuylistSuccessOkLabel': 'Oke',
						'removeFromBuylistSuccessText': 'Uw aanvraag werd geregistreerd in onze Charles servers en zal zo spoedig mogelijk behandeld worden.',
						'title': 'Mocking, Assortiment'
					},
					'campaign': {
						'rednose': 'Charles Rode Neuzen Dag actie',
						'rednose_brochure': 'Brochure',
						'rednose_termsAndConditions': 'Wedstrijdreglement',
						'rednose_video': 'Promotie - Video',
						'spring2021_almostThere': 'Je bent er bijna!',
						'spring2021_amountOfHam': 'hammen',
						'spring2021_amountOfSalami': 'kg salami',
						'spring2021_balance': 'Jouw spaarsaldo',
						'spring2021_buyHam': 'Koop Charles hammen',
						'spring2021_buyHamAndGet': '& ontvang binnenkort een bbq van yakiniku of openfire',
						'spring2021_buyHamAndSalami': 'Koop Charles hammen en salami',
						'spring2021_buyHamAndSalamiAndGet': '& je barbecue seizoen is meteen goed gestart',
						'spring2021_buyMoreHam': 'Koop {1} extra hammen voor',
						'spring2021_buyMoreSalami': 'Koop nog {1} extra kg salami voor',
						'spring2021_buySalami': 'Koop Charles salami',
						'spring2021_buySalamiAndGet': '& geniet van heerlijks Les Jolies Filles rosé bij de barbecue',
						'spring2021_buySalamiAndGetBottles': '6 flessen Les Jolies Filles rosé wijn bij aankoop van 50kg salami',
						'spring2021_flyer': 'Lees de folder',
						'spring2021_rules': 'Bekijk het reglement',
						'spring2021_wellDone': 'proficiat!',
						'spring2021_youHaveBons': 'U heeft reeds bonnen ter waarde van €{1}.',
						'spring2021_youHaveBottles': 'U heeft reeds {1} flessen Les Jolies rosé wijn.',
						'spring2021_youWillGet': 'en U ontvangt bonnen ter waarde van €{1}.',
						'spring2021_youWillGetBottles': 'en ontvang 6 flessen Les Jolies Fille rosé wijn.',
						'tavola2022_change': 'Wijzigen',
						'tavola2022_drag_hint': 'Sleep naar hier',
						'tavola2022_email_label': 'Naar welk emailadres mogen de waardebons gestuurd worden?',
						'tavola2022_email_label_saved': 'De waardebons zullen naar dit emailadres worden gestuurd.',
						'tavola2022_email_placeholder': 'e-mail adres',
						'tavola2022_no_coupons': 'Jammer genoeg heb je onvoldoende gespaard of niet deelgenomen aan onze Tavola spaaractie.\nWe zien je graag terug bij een volgende actie!',
						'tavola2022_saved_intro': 'Proficiat! \nJe hebt tijdens de Tavola-actie voor <span>€{1}</span> aan waardebonnen gespaard.\n \nKies – voor elke waardebon – bij welke partner je hem wil besteden.\nDit kan heel eenvoudig door de waardebon(nen) naar de partner(s) van jouw keuze te slepen.\nHeb je jouw keuze gemaakt? Geef nadien nog even het juiste mailadres in en klik onderaan op ‘opslaan’.\n \nGelieve vóór 1/06/2022 jouw keuze definitief te bevestigen (of wijzigen).\nDe waardebon(nen) mag je in je mailbox verwachten ten laatste 30/6/2022.  ',
						'tavola2022_save_error': 'Er ging iets fout bij het opslaan.',
						'tavola2022_save_success': 'Opslaan gelukt.',
						'tavola2022_submit': 'Opslaan',
						'tavola2022_total': 'Totaal',
						'weekend_webshop_2020.free_samples': 'U hebt reeds een gratis staal toegekend gekregen bij een vorige bestelling.\n'
					},
					'common': {
						'button_refresh_to_update_app': 'Klik hier om te herladen.',
						'label_new_version_available': 'Er is een nieuwe versie van de Charles Portal beschikbaar.',
						'label_reload_failed': 'Het is niet gelukt om uw bestelling voorlopig op te slaan alvorens te herladen.'
					},
					'customers': {
						'customers_title': 'Kies een klant',
						'label_city': 'Gemeente',
						'label_code': 'Nummer',
						'label_name': 'Naam',
						'label_open_orders': 'Open bestellingen',
						'label_street': 'Straat',
						'label_vat': 'btw nummer',
						'label_zip': 'Postcode'
					},
					'datetime': {
						'dayLabel': 'dag',
						'dayOfWeekShort1': 'ma',
						'dayOfWeekShort2': 'di',
						'dayOfWeekShort3': 'wo',
						'dayOfWeekShort4': 'do',
						'dayOfWeekShort5': 'vr',
						'dayOfWeekShort6': 'za',
						'dayOfWeekShort7': 'zo',
						'daysLabel': 'dagen',
						'hourLabel': 'uur',
						'hoursLabel': 'uren',
						'minuteLabel': 'minuut',
						'minutesLabel': 'minuten',
						'month1': 'januari',
						'month10': 'oktober',
						'month11': 'november',
						'month12': 'december',
						'month2': 'februari',
						'month3': 'maart',
						'month4': 'april',
						'month5': 'mei',
						'month6': 'juni',
						'month7': 'juli',
						'month8': 'augustus',
						'month9': 'september'
					},
					'errors': {
						'403': 'Deze sessie is verlopen. Gelieve opnieuw aan te melden. Verder werken aan deze bestelling is niet mogelijk!',
						'500': 'Er heeft zich een technisch probleem voorgedaan op onze server. Gelieve later opnieuw te proberen. Bij aanhoudende problemen gelieve ons te contacteren.',
						'600': 'Geen internet verbinding! U kan verder de bestelling ingeven en later doorsturen. Indien u een ander menu-item van Portal gebruikt, verliest u de ingegeven bestelling!',
						'2500': 'Sorry maar u probeert verder te werken aan een bestelling die ondertussen reeds doorgestuurd werd. Dit kan eventueel gebeurd zijn via ander toestel (pc, laptop, tablet, …) of via een andere internet browser.\n\nU kan op dit punt enkel starten met een nieuwe bestelling.\n',
						'2501': 'Sorry maar u probeert verder te werken aan een bestelling die ondertussen reeds verwijderd werd. Dit kan eventueel gebeurd zijn via ander toestel (pc, laptop, tablet,...) of via een andere internet browser.\n\nU kan op dit punt enkel starten met een nieuwe bestelling.\n',
						'2502': 'Er worden verschillende bewaaracties uitgevoerd op deze bestelling. Dit kan eventueel gebeurd zijn via ander toestel (pc, laptop, tablet, …) of via een andere internet browser.\n',
						'askPermissionFor': 'Vraag toestemming aan uw account-beheerder om U de functionaliteit **{1}** toe te kennen.',
						'module_incompatible_version_reload_button': 'Klik hier om de pagina te herladen.',
						'module_incompatible_version_text1': 'De versie waarmee u werkt (v{1}) is niet compatibel met de minimum versie (v{2}).',
						'module_incompatible_version_text2': 'Als u deze foutmelding daarna nog steeds ziet, probeer te herladen door op CTRL-F5 te duwen.',
						'needsPermission': 'U heeft geen toestemming om deze pagina te bezichtigen'
					},
					'expo': {
						'checkOrderButton': 'Bestelling afronden',
						'checkOrderDescription': 'Kijk je bestelling goed na voor je hem indient.',
						'clickHere': 'klik hier.',
						'continueOrderButton': 'Verder bestellen',
						'description': 'Bestel nu voor onderstaande uitlever weken. Voor meer spelregels,',
						'fetchFailed': 'Het ophalen van de data is niet gelukt.',
						'newOrderButton': 'Nieuwe bestelling',
						'noProductsLabel': 'Geen producten gevonden voor week {1}.',
						'orderButton': 'Plaats bestelling voor {1} ( + {2} gratis ) stuks',
						'orderedHere': 'reeds: {1} + {2} gratis',
						'orderingNoProductsLabel': 'Er zitten geen producten voor week {1} in uw bestelling.',
						'productPendingLabel': 'Aantal stuks van dit artikel dat u reeds besteld heeft voor deze week via de 40 - 48 kortingen module',
						'saveFailed1005': 'De bestelling die u bent aan het bewerken is al ingediend.',
						'submitFailed': 'Het plaatsen van je bestelling is niet gelukt.',
						'submitFailed1005': 'De bestelling die u probeert te plaatsen is al ingediend.',
						'submitSuccess': 'Bestelling successvol geplaatst.',
						'title': '40 - 48 Kortingen',
						'weekTitle': 'Week {1}'
					},
					'invoices': {
						'amountLabel': 'Bedrag',
						'dateLabel': 'Datum',
						'genericError': 'Er ging iets fout bij het ophalen van de facturen.',
						'invoiceNumberLabel': 'Factuur nummer',
						'noInvoicesFound': 'Geen facturen gevonden',
						'numberOfInvoicesFound': '{1} facturen gevonden',
						'orderReferenceLabelShort': 'bestelref.',
						'ourOrderReferenceLabel': 'Onze bestelref.',
						'searchPlaceholder': 'Zoek op datum, maand of factuurnummer...',
						'title': 'Facturen'
					},
					'margins': {
						'by_piece': '/ stuk',
						'by_weight': '/ kg',
						'cancel': 'wijzigingen annuleren',
						'cancel_and_go_back': 'wijzigingen annuleren en terug naar overzicht',
						'changed_title': 'aangepaste marge',
						'charles_margin_label': 'Adviesmarge voor deze categorie',
						'edit': 'bewerken',
						'go_back': 'terug naar overzicht',
						'margin_title': 'marge',
						'no_categories': 'geen categorieen gevonden.',
						'pieces': 'stuks',
						'product_title': 'product',
						'proposed_title': 'adviesmarge',
						'purchase_price_title': 'aankoopprijs',
						'sales_packaged_goods_title': 'verkoop eenheden',
						'sales_price_title': 'verkoopprijs',
						'save': 'opslaan',
						'title': 'adviesprijzen',
						'type_title': 'type',
						'vat_title': 'BTW',
						'your_margin_label': 'Jouw aangepaste marge voor deze categorie',
						'your_margin_title': 'uw aangepaste marge'
					},
					'nav': {
						'buylist': 'Mijn assortiment',
						'contact': 'Contacteer ons',
						'dashboard': 'Overzicht',
						'logout': 'Afmelden',
						'nav_adviesprijzen': 'Adviesprijzen',
						'nav_bestel': 'Bestelling invoeren',
						'nav_bestelhist': 'Besteloverzicht',
						'nav_contact': 'Contacteer ons',
						'nav_dashboard': 'Overzicht',
						'nav_expo': '40-48 Kortingen',
						'nav_invoices': 'Facturen/VVD',
						'nav_kooplijst': 'Assortiment',
						'nav_manage_internal_accounts': 'Interne Accounts',
						'nav_marketing_campaigns': 'Marketing Campagnes',
						'nav_preorder': 'Voorverkoop',
						'nav_pricechanges': 'Prijswijzigingen',
						'nav_promos': 'Folders & promoties',
						'nav_rapport': 'Rapporten',
						'nav_spring': 'Barbecue seizoen',
						'nav_summer2020': 'Zomeractie',
						'nav_suppliers_manage_sublogins': 'Gebruikersbeheer',
						'nav_suppliers_master_accounts': 'Leverancieraccounts',
						'nav_suppliers_products_commercial': 'Commerciële gegevens',
						'nav_suppliers_products_commercial_approve': 'Goedkeuren Com.Geg.',
						'nav_suppliers_products_commercial_approve_archive': 'Goedkeuring archief',
						'nav_suppliers_products_technical': 'Technische gegevens',
						'nav_tavola2022': 'Tavola 2022',
						'nav_tml': 'Tomorrowland',
						'nav_topdeals': 'Topdeals',
						'order': 'Bestelling invoeren',
						'orders': 'Bestellingen',
						'promos': 'Folders & promoties',
						'reports': 'Rapporten',
						'terms': 'Algemene voorwaarden'
					},
					'order': {
						'allergensUnavailable': 'Allergenen niet beschikbaar',
						'buylist': 'Mijn assortiment',
						'confirmMadQuantity': 'Bevestig (of wijzig) sterk afwijkend aantal',
						'confirmUnansweredQuestions': 'Beantwoord de gestelde vragen',
						'continueOpenOrderByUserQuestion': 'Verdergaan met bestelling ingegeven door {1}?',
						'continueOpenOrderNo': 'Neen, nieuwe bestelling',
						'continueOpenOrderQuestion': 'Verdergaan met openstaande bestelling?',
						'continueOpenOrderYes': 'Ja, verdergaan',
						'defaultDeliveryDateLabel': 'Vaste leverdag',
						'deliveryDateDeadlineBeforeX': 'Bestellingen voor levering morgen moeten ons voor {1}:{2} bereiken.',
						'deliveryDateDeadlineLabel1': 'Bestellingen voor levering morgen moeten ons voor 17:45 bereiken',
						'deliveryDateDeadlineLabel2': 'Sommige producten dienen voor 10:00 besteld te worden om morgen een uitlevering te kunnen garanderen.',
						'deliveryDateDeadlineLabel2Possible': '???',
						'deliveryDateDeadlineLabelNotPossible': 'U zult deze producten niet kunnen bestellen.',
						'deliveryDateDivergentDeadlineBeforeTodayX': 'Bestellingen voor levering op <b>{1}</b> moeten ons bereiken vandaag vóór <b>{3}:{4}</b>.',
						'deliveryDateDivergentDeadlineBeforeX': 'Bestellingen voor levering op <b>{1}</b> moeten ons bereiken vóór <b>{2} {3}:{4}</b>.',
						'deliveryDateInvalidLabel': 'Het is niet mogelijk om te leveren op de geselecteerde datum.',
						'deliveryDateInvalidSelectFirstAvailable': 'Selecteer eerst mogelijke',
						'deliveryDateLabel': 'Leveringsdatum',
						'deliveryDateSelectionStepTitle': 'Leveringsdatum',
						'deliveryDateTomorrowDeadlineBeforeX': 'Bestellingen voor levering <b>morgen</b> moeten ons vóór <b>{1}:{2}</b> bereiken.',
						'detailNumFiltersActive': '{1} filters actief',
						'detailNumProducts': '1 product',
						'detailNumProductsForSearch': '1 product gevonden voor "{1}"',
						'detailNumProductsForSearchMultiple': '{1} producten gevonden voor "{2}"',
						'detailNumProductsMultiple': '{1} producten',
						'fetchingCustomerProducts': 'mijn assortiment',
						'fetchingMetadata': 'Shopcodes & groepen',
						'fetchingProducts': 'producten',
						'fetchingPromos': 'promos',
						'filtersAllergens1': 'Gluten',
						'filtersAllergens10': 'Mosterd',
						'filtersAllergens11': 'Sesamzaad',
						'filtersAllergens12': 'Zwaveldioxide en sulfieten',
						'filtersAllergens13': 'Lupine',
						'filtersAllergens14': 'Weekdieren',
						'filtersAllergens2': 'Schaaldieren',
						'filtersAllergens3': 'Eieren',
						'filtersAllergens4': 'Vis',
						'filtersAllergens5': "Aardnoten (Pinda's)",
						'filtersAllergens6': 'Soja',
						'filtersAllergens7': 'Melk',
						'filtersAllergens7_1': 'Melk',
						'filtersAllergens7_2': 'Lactose',
						'filtersAllergens8': 'Noten',
						'filtersAllergens9': 'Selderij',
						'filtersAllergensTitle': 'Allergenen',
						'filtersAllergens_contains': 'Dit product bevat',
						'filtersBuylistFullProductRange': 'Volledig assortiment',
						'filtersBuylistMyProducts': 'Mijn assortiment',
						'filtersBuylistTitle': 'Assortiment',
						'filtersFall2019': 'Lunchgerechten',
						'filtersMainEob': 'Enkel op bestelling',
						'filtersMainInOrder': 'Huidige bestelling',
						'filtersMainPending': 'Reeds besteld',
						'filtersMainPromo': 'Promo',
						'filtersMainReserved': 'Gereserveerd',
						'filtersMainSeasonal': 'Seizoensproducten',
						'filtersMainTimeSensitive': 'Voor 10u',
						'filtersMainTitle': 'Filters',
						'filterSortingCode': 'code',
						'filterSortingDescription': 'naam',
						'filterSortingLabel': 'Sorteer op:',
						'filterSortingShopcode': 'shopcode',
						'filtersRedNose': 'Rode Neuzen Dag',
						'filtersShopcodesTitle': 'assortiment',
						'freeSampleDescription': 'Kies hieronder jouw favoriete product. U zal dit product GRATIS ontvangen.',
						'freeSampleDescriptionBF2020': 'Naar aanleiding van Black Friday geeft Charles - van 26 tem 29 november - een gratis product weg als je bestelt via onze portal. Kies hieronder jouw favoriete product. U zal dit product GRATIS ontvangen in de loop van week 50.',
						'freeSampleDescriptionWW2020': 'Dit weekend geeft Charles een gratis product weg als je bestelt via onze portal (portal.charles.eu).   Kies hieronder jouw favoriete product.\nU zal dit product GRATIS ontvangen in de loop van week 44.\n',
						'freeSampleDisabledDescriptionBF2020': 'Naar aanleiding van Black Friday geeft Charles - van 26 t.e.m. 29 november - een gratis product weg als je voor minstens €{1} bestelt via onze portal (portal.charles.eu). U zal dit product GRATIS ontvangen in de loop van week 50. De geschatte bestelwaarde van uw winkelmandje is momenteel €{2}.',
						'freeSampleMore': 'meer',
						'freeSampleNone': 'ik wens geen gratis staal',
						'freeSampleTitle': 'kies een gratis staal',
						'freeSampleTitleBF2020': 'Black Friday actie - Kies je gratis staal',
						'freeSampleTitleWW2020': 'kies een gratis staal voor het weekend van de webshop',
						'issueWithOneOrMoreLines': 'Een of meerdere artikelen kunnen niet besteld worden',
						'label_eob_net_available': 'Wordt meegeleverd',
						'label_eob_net_unknown': 'Termijn onbekend',
						'label_eob_net_with_charles_on': 'bij Charles op {1}',
						'label_free': 'gratis',
						'noProducts': 'Geen producten gevonden',
						'productAvgWeightLabel': 'Gem. Gew.',
						'productDetailDownloadTitle_flyers': 'Flyers',
						'productDetailDownloadTitle_images': 'Afbeeldingen',
						'productDetailDownloadTitle_specifications': 'Specificaties',
						'productDetailImage': 'afbeelding',
						'productDetailSpecification': 'Specificatie',
						'productHistoryHint': 'Hoeveelheid besteld voor de voorbije weken',
						'productKilo': 'kg',
						'productMarginEditLabel': 'wijzig',
						'productMarginLabel': 'Adviesprijs',
						'productPendingLabel': 'Aantal stuks van dit artikel dat u reeds besteld heeft voor deze week, maar nog moeten uitgeleverd worden',
						'productPromoAwarded': 'Promo toegekend',
						'productReservedLabel': 'Aantal stuks van dit Enkel-Op-Bestelling artikel dat u reeds besteld heeft, maar nog moeten uitgeleverd worden',
						'productSelectionStepSubtext': '{1} stk / {2} prod',
						'productSelectionStepTitle': 'Producten',
						'productUnit': 'stuk',
						'productUnitPlural': 'stuks',
						'productUnitShort': 'st.',
						'promo4048available': 'Bestel nu onze knalpromoties voor week {1}',
						'promo4048availableCounter': 'U heeft nog',
						'promoColli': 'Bij aankoop van {1} stuks van minstens {2} deelnemende producten, krijg {3}x {4} gratis!',
						'promoFixedPrice': '{1} euro/stuk',
						'promoFixedPriceK': '{1} euro/kg',
						'promoFixedPriceMultiple': '{1} euro/stuk vanaf {2} stuks',
						'promoFixedPriceMultipleK': '{1} euro/kg vanaf {2} stuks',
						'promoFixedPriceMultipleS': '{1} euro/stuk vanaf {2} stuks',
						'promoFixedPriceMultipleundefined': '{1} euro/eenheid vanaf {2} stuks',
						'promoFixedPriceS': '{1} euro/stuk',
						'promoFixedPriceundefined': '{1} euro/eenheid',
						'promoFree': 'U krijgt 1 stuk gratis!',
						'promoFreeByUnit': '{1}+{2} gratis',
						'promoFreeByUnitDifferentTarget': 'Bij aankoop van 1 stuk, krijg {1}x {2} gratis!',
						'promoFreeByUnitDifferentTargetMultiple': 'Bij aankoop van {1} stuks, krijg {2}x {3} gratis!',
						'promoFreeMultiple': 'U krijgt {1} gratis!',
						'promoLabel': 'promo',
						'promoParticipatingProducts': 'Deelnemende producten:',
						'promoPercentDiscount': '{1}% korting',
						'promoPercentDiscountMultiple': '{1}% korting vanaf {2} stuks',
						'promoPricePerUnit': 'Bij aankoop van 1 stuk {1} euro korting per stuk',
						'promoPricePerUnitMultiple': 'Bij aankoop van {1} stuks {2} euro korting per stuk',
						'promoPricePerWeight': 'Bij aankoop van 1 stuk {1} euro korting per kilo',
						'promoPricePerWeightMultiple': 'Bij aankoop van {1} stuks {2} euro korting per kilo',
						'question_rednose_header': 'Wenst u gratis een Charles Rode Neuzen visibiliteitskit te ontvangen? ',
						'question_rednose_text': 'Eén visibiliteitskit bevat 1 totem, 1 wobbler, 1 affiche en 5 prijsprikkers.',
						'remarksCommentLabel': 'Extra commentaar',
						'remarksCommentPlaceholder': 'Extra commentaar',
						'remarksReferenceLabel': 'Uw referentie',
						'remarksReferencePlaceholder': 'Uw referentie',
						'remarksStepDescription': 'Geef eventueel uw eigen bestelreferentie in zoals u deze op de factuur wenst te zien verschijnen.\nDe commentaar is volledig vrij invulbaar, en zal bij verzenden van de bestelling doorgegeven worden aan onze klantendienst.',
						'remarksStepTitle': 'Opmerkingen',
						'reminder_rednose_text': 'Charles kaapt Workalicious op Qmusic. </br>Geef jouw favoriete nummer door via qmusic.be in de sectie Workalicious. </br>Laat weten waarom je deelneemt aan de Charles Rode Neuzen Dag actie en vermeld zeker #CharlesRodeNeuzenDag. </br>Tussen 4 en 15 november belt Qmusic elke werkdag één van onze klanten op. </br></br>Wie weet kom jij dus met jouw ‘all time favorite’ op de radio!',
						'reminder_rednose_title': 'KOM MET JOUW WINKEL OP DE RADIO',
						'reviewDeliveryAddressLabel': 'Leveringsadres',
						'reviewInvoiceAddressLabel': 'Facturatieadres',
						'reviewMadQuantityNo': 'Nee',
						'reviewMadQuantityQuestion': 'Sterk afwijkend aantal. Wilt u echt {1} stuks bestellen?',
						'reviewMadQuantityYes': 'Ja',
						'reviewNewOrder': 'Nog een bestelling ingeven',
						'reviewOrderSent': 'Uw bestelling werd verstuurd',
						'reviewProductLabel': 'Product',
						'reviewProductLabelMultiple': 'Producten',
						'reviewProductListTitle': 'Producten',
						'reviewSendOrder': 'Bestelling versturen',
						'reviewStepDescription': 'CONTROLEER en VERSTUUR onderaan',
						'reviewStepTitle': 'Controleren',
						'reviewTotalLabel': 'Totaal',
						'reviewUnitLabel': 'Stuk',
						'reviewUnitLabelMultiple': 'Stuks',
						'saveStateFail': 'Fout bij opslaan',
						'saveStateHasChanges': 'Wordt opgeslaan...',
						'saveStateSaving': 'Bezig met opslaan...',
						'searchPlaceholder': 'Zoek op productnaam, -code, -groep of -shopcode...',
						'showMoreProducts': 'Meer producten',
						'step1': 'Leveringsdatum',
						'step2': 'Producten',
						'step3': 'Opmerkingen',
						'step4': 'Controleren',
						'title_in-the-picture': 'In de kijker',
						'weekLabel': 'Week'
					},
					'orders': {
						'label_asap': 'z.s.m.',
						'label_customer_ref': 'Uw referentie',
						'label_delivery_date': 'Leveringsdatum',
						'label_id': 'Code',
						'label_name': 'Naam',
						'label_amount': 'Amount',
						'label_new_order': 'Naar bestelling invoeren',
						'label_open_order': 'U heeft een openstaande bestelling voor levering op {1}',
						'label_open_order_continue': 'Verdergaan met deze bestelling',
						'label_open_order_details_hide': 'Samenvatting verbergen',
						'label_open_order_details_show': 'Samenvatting tonen',
						'label_ordered_products_filter_all': 'Alle producten',
						'label_ordered_products_filter_eob': 'Enkel-op-bestelling producten',
						'label_ordered_products_filter_non-eob': 'Gewone producten',
						'label_origin': 'Oorsprong',
						'label_origin_APP': "Cock's Mobile app",
						'label_origin_BEU': 'Beursbestelling',
						'label_origin_BIN': 'Binnendienst',
						'label_origin_EDI': 'EDI',
						'label_origin_PRT': 'Portaalsite',
						'label_origin_RAM': 'Reiziger',
						'label_origin_WEB': 'Online',
						'label_out_for_delivery_quantity_on_date': 'Levering op {1}:',
						'label_pdf': 'PDF',
						'label_reference': 'Onze Bestelref.',
						'label_reserved_quantity_on_date': 'Gereserveerd, bestelling klant ontvangen op: {1}',
						'label_status_-1': 'Nog niet verzonden',
						'label_status_0': 'Ontvangen, wacht op behandeling',
						'label_status_10': 'In verwerking magazijn',
						'label_status_15': 'Samengevoegd bij andere bestelling',
						'label_status_20': 'Geladen op vrachtwagen',
						'label_status_25': 'Levering onderweg (verwachte aankomst om {1}) ',
						'label_status_29': 'Onderweg / Geleverd',
						'label_status_30': 'Geleverd',
						'label_status_40': 'Gefactureerd',
						'label_status_5': 'Bevestigd, wacht op verwerking',
						'label_status_60': 'Geschrapt',
						'label_status_null': 'Verzonden, nog niet in behandeling',
						'label_submitted_at': 'Verstuurd',
						'label_x_of_y_products': '{1} van de {2} producten',
						'title': 'Besteloverzicht',
						'title_ordered_products': 'Bestelde producten',
						'title_order_history': 'Geplaatste bestellingen'
					},
					'preorders': {
						'button_submit': 'Bestelling plaatsen',
						'button_submitted_warning_ok': 'Oke, begrepen',
						'label_deadline': 'Loopt af op {1}',
						'label_delivery_week': 'Levering week {1}',
						'label_no_items_found': 'Er zijn geen actieve verkoopacties of er is iets fout gegaan bij het ophalen van de acties.',
						'label_save_error': 'Er is iets fout gegaan bij het opslaan van deze bestelling: {1}',
						'label_save_success': 'Uw bestelling werd succesvol doorgestuurd',
						'label_submitted_warning_1': 'U heeft zonet de bestelling doorgestuurd voor {1}.',
						'label_submitted_warning_2': 'Er is echter nog minstens 1 andere openstaande bestelling op deze pagina. Deze moet nog worden doorgestuurd:',
						'title': 'Voorverkoop'
					},
					'priceChanges': {
						'headerConsumerPrice': 'Consument',
						'headerDifference': 'Verschil',
						'headerNewPrice': 'Nieuwe prijs',
						'headerOldPrice': 'Oude prijs',
						'headerProduct': 'Product',
						'nChanges': '{1} prijswijzigingen',
						'noPriceChanges': 'Geen prijswijzigingen gevonden.',
						'oneChange': '1 prijswijziging',
						'searchPlaceholder': 'Zoek op datum, productcode of productnaam...',
						'title': 'Prijswijzigingen',
						'viewPriceChangesPermission': 'Prijswijzigingen bekijken'
					},
					'product': {
						'badgeEobHint': "Dit product nemen wij niet op voorhand in stock en wordt enkel door ons aangekocht wanneer u hiervan een bestelling plaatst. Dit product kan vervolgens uiteraard ook niet meer door ons teruggenomen worden aangezien het specifiek voor u besteld werd. De leveringsdatum van het product is afhankelijk van levering door onze leverancier, en zal na ontvangst bij ons zo snel mogelijk en bij voorkeur samen met een 'gewone' bestelling aan u geleverd worden.",
						'badgeEobLabel': 'eob',
						'badgeSeasonHint': 'Dit product is enkel beschikbaar van week {1} tot week {2}.',
						'badgeTimeHint': 'Om dit product morgen te kunnen leveren dient het voor 10u te worden besteld.'
					},
					'promo': {
						'4048': '40-48',
						'4048Superpromos': '40-48 Knalpromoties',
						'belgiumsFinest': 'Nieuwe chiffonades',
						'biweekly': 'De 2-wekelijkse folder kan je hier bekijken',
						'bullseye': 'Voltreffers',
						'colliShortDescription': 'Kolie promo (klik voor details)',
						'consumerPosterWeekX': 'Poster voor eindconsumenten week {1}',
						'duration': "Geldig wanneer leveringsdatum tussen <span class='duration__date'>{1}</span> en <span class='duration__date'>{2}</span>",
						'durationDelayedDeliveryDate': "leveringsdatum tussen <span class='duration__date'>{1}</span> en <span class='duration__date'>{2}</span>",
						'durationDelayedOrderDate': "Geldig voor bestelling tussen <span class='duration__date'>{1}</span> en <span class='duration__date'>{2}</span> met",
						'endofyear': 'Ontdek hier onze Eindejaarscatalogus',
						'everyWeek': 'Elke week',
						'filter4048Off': 'Enkel knalpromoties zien - uitzetten',
						'filter4048On': 'Enkel knalpromoties zien',
						'label_promo_colli_1': 'Koop {2} verschillende artikels uit de lijst van deelnemende producten en krijg {3}% korting!',
						'label_promo_colli_2': 'Bij aankoop van {1} stuks van minstens {2} deelnemende producten, krijg {4} aan €{3}/stuk!',
						'label_promo_colli_3': 'Bij aankoop van {1} stuks van minstens {2} deelnemende producten, krijg {3}x {4} gratis!',
						'label_promo_colli_5': 'Bij aankoop van {1} stuks van minstens {2} deelnemende producten, krijg €{3} korting per stuk op {4}!',
						'label_promo_colli_6': 'Bij aankoop van {1} stuks van minstens {2} deelnemende producten, krijg €{3} korting per kg op {4}!',
						'new': 'Nieuw',
						'promosTitle': 'Folders & promoties',
						'rulesAndWeekXPromotions': 'Spelregels & week {1} Knalpromoties',
						'superpromos': 'Knalpromoties'
					},
					'promos': {
						'actions': ' Acties',
						'flyer': 'Flyers',
						'folder': ' Folders',
						'new': ' Nieuwigheden',
						'no_promos': ' Geen active promos of folders gevonden.',
						'recipes': 'Recepten',
						'start_date_future': ' Vanaf {1}',
						'start_date_past': 'Sinds {1}',
						'start_date_today': 'Vanaf vandaag'
					},
					'reports': {
						'frequencyFilter_all': 'Alles',
						'frequencyFilter_month': 'Maand-evoluties',
						'frequencyFilter_week': 'Week-evoluties',
						'frequencyFilter_year': 'Jaaroverzicht',
						'no_reports': 'Er zijn geen rapporten',
						'no_reports_filtered': 'Er zijn geen rapporten voor uw zoekopdracht/periode',
						'reportName_BUY_EVOLUTION_MONTH': 'Evolutie aankoop {2} {1}',
						'reportName_BUY_EVOLUTION_WEEK': 'Evolutie aankoop week {3} van {1}',
						'reportName_BUY_EVOLUTION_YEAR': 'Evolutie aankoop {1}',
						'reportName_RUNNING_YEAR': 'Evolutie aankoop {1} (lopend)',
						'report_download': 'Download',
						'report_name': 'Naam',
						'report_period': 'Periode',
						'searchPlaceholder': 'Zoek op naam en datum...',
						'title': 'Rapporten',
						'unknownReport': 'Onbekend rapport',
						'xOfYReportsFound': '{1} van de {2} rapporten',
						'xReportsFound': '{1} rapporten'
					},
					'settings': {
						'title': 'Wijzig uw gegevens',
						'title_communication': 'Communicatie',
						'title_contact': 'Contactgegevens',
						'title_logins_sublogins': 'Logins en sublogins',
						'title_vacation': 'Vakantie-afwezigheid'
					},
					'transportDocuments': {
						'dateLabel': 'datum',
						'documentNumberLabel': 'document nummer',
						'genericError': 'Er ging iets fout bij het ophalen van de vervoerdocumenten.',
						'noInvoicesFound': 'Geen vervoerdocumenten gevonden',
						'numberOfDocumentsFound': '{1} documenten gevonden',
						'ourOrderReferenceLabel': 'Onze bestelref.',
						'searchPlaceholder': 'Zoek op datum, maand of documentnummer...',
						'title': 'Vervoerdocumenten'
					},
					'vacation': {
						'button_save': 'Opslaan',
						'button_understood': 'Oke, begrepen',
						'label_changes_pending': 'Uw vakantie-periode(s) werd(en) goed doorgestuurd. \nHet kan even duren voor de wijziging reflecteren op deze pagina.',
						'label_description_1': 'Hier kan u uw uitzonderlijke sluitingsdagen ingeven (bijvoorbeeld een welverdiende vakantie, verbouwingen, ...). Op de datums die u hier ingeeft zullen wij niet proberen leveren.',
						'label_description_2': 'U kan een periode ingeven door eerst de startdatum en daarna de einddatum aan te duiden.',
						'label_error_fetching': 'Er is iets fout gegaan bij het ophalen van uw vakantie-periodes',
						'label_error_saving': 'Er is iets fout gegaan bij het opslaan van uw vakantie-periodes',
						'label_legend_closed': 'Feestdag = Charles gesloten',
						'label_legend_delete': 'Verwijderen',
						'label_legend_past': 'Verleden',
						'label_legend_today': 'Vandaag',
						'label_legend_vacation': 'Afwezig',
						'label_legend_weekend': 'Weekend'
					}
				}
			),
		);
	}),
];
