import React from 'react';
import './orderReminder.scss';
import OrderReminderEan from './OrderReminderEan/OrderReminderEan';

export default function OrderReminder(props) {
	if (props.reminder.code === 'charles_prepackaged.new_ean') {
		return (
			<div className={'OrderReminder'}>
				<OrderReminderEan {...props} />
			</div>
		);
	}

	return null;
}
