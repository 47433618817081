import Api from './../../libraries/api';
import { selectIsReportsFetching } from './../selectors';

export const REPORTS_REQUEST = 'REPORTS_REQUEST';
export const REPORTS_RECEIVE = 'REPORTS_RECEIVE';
export const REPORTS_REJECT = 'REPORTS_REJECT';
export const REPORTS_HANDLE_SEARCH = 'REPORTS_HANDLE_SEARCH';
export const REPORTS_HANDLE_FREQUENCYFILTER = 'REPORTS_HANDLE_FREQUENCYFILTER';

export function handleSearch(query) {
	return {
		type: REPORTS_HANDLE_SEARCH,
		query
	};
}

export function handleFrequencyFilter(frequencyFilter) {
	return {
		type: REPORTS_HANDLE_FREQUENCYFILTER,
		frequencyFilter
	};
}

function fetchReports(customerId) {
	return async dispatch => {
		dispatch({
			type: REPORTS_REQUEST
		});

		let data;

		try {
			data = await Api.getReports(customerId);
		} catch (error) {
			return dispatch({
				type: REPORTS_REJECT,
				error
			});
		}

		return dispatch({
			type: REPORTS_RECEIVE,
			data
		});
	};
}

const shouldFetchReports = state => {
	const isFetching = selectIsReportsFetching(state);
	if (isFetching) return false;
	return true;
};

export const fetchReportsIfNeeded = (customerId) => {
	return (dispatch, getState) => {
		if (shouldFetchReports(getState())) {
			dispatch(fetchReports(customerId));
		}
	};
};
