import React from 'react';
import { t } from './../../libraries/i18n';
import './List.scss';

function List(props) {
	const { header, content, action } = props;

	const renderHeaderColumn = column => (
		<div key={column.key} className={`header__column ${column.key}`}>
			{t(column.title)}
		</div>
	);

	const renderItemCell = (column, listItem) => (
		<div key={column.key} className={`item__cell ${column.key}`}>
			{column.render(listItem)}
		</div>
	);

	const renderListItem = (listItem) => (
		<div
			key={listItem.id}
			className={'list__item'}
			onClick={e => action(e, listItem)}
			style={{ gridTemplateColumns: props.gridTemplateColumns }}
		>
			{header.map(column => renderItemCell(column, listItem))}
		</div>
	);

	return (
		<div className={'List'}>
			<div className={'list__header'} style={{ gridTemplateColumns: props.gridTemplateColumns }}>
				{header.map(renderHeaderColumn)}
			</div>
			<div className={'list__body'}>
				{content.map(renderListItem)}
			</div>
		</div>
	);
}

export default List;
