import { useAppSelector } from './../libraries/hooks/redux';
import { selectIsResourcesListFetching, selectResourcesList } from './../modules/selectors';
import { get } from './../libraries/api';

export const fetcher = get;

export const fetchAPIResources = () => fetcher('/api/v1');

// Remove when only new backend is available
export const useIsLoginAPIAvailable = () => {
	const isFetching = useAppSelector(selectIsResourcesListFetching);
	const resourcesList = useAppSelector(selectResourcesList);

	if (resourcesList === null || isFetching) return undefined;
	return resourcesList?.find((r: any) => r.id === 'login');
};
