import React, {
	useEffect,
	useState,
	useMemo,
	useCallback
} from 'react';
import './ActiveFilters.scss';
import { FaTrashAlt } from 'react-icons/fa';
import ProductHelper from './../../../libraries/productHelper';
import { useSelector } from 'react-redux';
import { selectLocale } from './../../../modules/selectors';

export default function ActiveFilters(props) {
	const {
		activeQuery,
		activeFilters,
		defaultFilters,
		allShopcodes,
		campaigns,
		clearFilters,
		handleSearch,
		setFilter
	} = props;
	const maxLength = 24;
	const active = useMemo(() => ({ activeQuery, activeFilters }), [activeQuery, activeFilters]);
	const [activeFilterItems, setActiveFilterItems] = useState([]);
	const locale = useSelector(selectLocale);

	const renderActiveShopcodeTree = useCallback((activeShopcode) => {
		if (activeShopcode === '') return null;
		const level = ProductHelper.getShopcodeLevel(activeShopcode.toString());
		const shopcodeFull = ProductHelper.getShopcode(allShopcodes, activeShopcode.toString());

		const getShopcodeOneLevelUp = (level) => {
			const shopcode = ProductHelper.convertShopcode(activeShopcode.toString(), level);
			setFilter('productRange.shopcode', shopcode.toString());
		};

		if (!shopcodeFull) return null;

		return (
			<span className='shopcode'>
				{shopcodeFull.map((item, index) => index <= level - 1 ? (
					<span key={index} onClick={() => getShopcodeOneLevelUp(index + 1)} className={'shopcode__level'}>
						{item}
					</span>
				) : null)}
			</span>
		);
	}, [allShopcodes, setFilter]);

	const renderSearchQuery = activeQuery => activeQuery ? (
		<span className='query'>
			<span className='title'>Zoekterm: </span>
			{activeQuery.length >= maxLength ? `${activeQuery.slice(0, maxLength) }...` : activeQuery}
		</span>
	) : null;

	const handleShopcodeClear = useCallback(e => {
		if (e.target.classList.contains('shopcode__level')) return;
		setFilter('productRange.shopcode', '');
	}, [setFilter]);

	// All possible filter indicator items
	const initialFilterItems = useMemo(() => ([
		{
			id: 'query',
			title: ({ activeQuery }) => renderSearchQuery(activeQuery),
			handleClear: () => handleSearch(''),
			isActive: ({ activeQuery }) => !!activeQuery,
			getValue: ({ activeQuery }) => activeQuery
		},
		{
			id: 'allergens',
			title: 'Allergenen',
			handleClear: () => clearFilters('allergens'),
			isActive: ({ activeFilters }) => Object.values(activeFilters.allergens).includes(true),
			getValue: ({ activeFilters }) => activeFilters.allergens
		},
		{
			id: 'brands',
			title: 'Brands',
			handleClear: () => clearFilters('brands'),
			isActive: ({ activeFilters }) => Object.values(activeFilters.brands).includes(true),
			getValue: ({ activeFilters }) => activeFilters.brands
		},
		{
			id: 'promo',
			title: 'Promo',
			handleClear: () => setFilter('main.promo', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main?.promo !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main?.promo
		},
		{
			id: 'eob',
			title: 'Enkel op bestelling',
			handleClear: () => setFilter('main.eob', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main.eob !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main.eob
		},
		{
			id: 'season',
			title: 'Seizoensproducten',
			handleClear: () => setFilter('main.season', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main.season !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main.season
		},
		{
			id: 'inOrder',
			title: 'Huidige bestelling',
			handleClear: () => setFilter('main.inOrder', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main?.inOrder !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main?.inOrder
		},
		{
			id: 'pending',
			title: 'Reeds besteld',
			handleClear: () => setFilter('main.pending', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main.pending !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main.pending
		},
		{
			id: 'reserved',
			title: 'Gereserveerd',
			handleClear: () => setFilter('main.reserved', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main.reserved !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main.reserved
		},
		{
			id: 'timeSensitive',
			title: 'Voor 10u',
			handleClear: () => setFilter('main.timeSensitive', 'off'),
			isActive: ({ activeFilters }) => activeFilters.main.timeSensitive !== 'off',
			getValue: ({ activeFilters }) => activeFilters.main.timeSensitive
		},
		{
			id: 'shopcode',
			title: ({ activeFilters }) => renderActiveShopcodeTree(activeFilters.productRange.shopcode),
			handleClear: handleShopcodeClear,
			isActive: ({ activeFilters }) => activeFilters.productRange.shopcode !== '',
			getValue: ({ activeFilters }) => activeFilters.productRange.shopcode
		},
		{
			id: 'endofyear2020',
			title: 'Eindejaar',
			handleClear: () => clearFilters('endofyear2020'),
			isActive: ({ activeFilters }) => activeFilters.endofyear2020?.group !== null,
			getValue: ({ activeFilters }) => activeFilters.endofyear2020
		}
	]), [clearFilters, handleSearch, handleShopcodeClear, renderActiveShopcodeTree, setFilter]);

	const [filterItems, setFilterItems] = useState(initialFilterItems);

	// Add or remove the filters that have a value in the state
	// to the items that should render an "active filter indicator"
	useEffect(() => {
		filterItems.forEach(filterItem => {
			const activeFilter = activeFilterItems.find(activeItem => activeItem.id === filterItem.id) ?? false;
			filterItem = {
				...filterItem,
				value: JSON.stringify(filterItem.getValue(active))
			};

			if (filterItem.value === activeFilter.value) return;

			if (filterItem.isActive(active) && !activeFilter) {
				// Filter is active but not yet rendered (newly active)
				setActiveFilterItems([...activeFilterItems, filterItem]);
			} else if (!filterItem.isActive(active) && activeFilter) {
				// Filter is active but in the rendered items (newly inactive)
				setActiveFilterItems([
					...activeFilterItems.filter(activeItem => activeItem.id !== filterItem.id)
				]);
			} else if (filterItem.isActive(active) && activeFilter && filterItem.value !== activeFilter.value) {
				// Filter is active and rendered, but value has updated (filter text might need to update)
				setActiveFilterItems([
					...activeFilterItems.map(activeItem => activeItem.id === filterItem.id ? filterItem : activeItem)
				]);
			}
		});
	}, [active, activeFilterItems, filterItems]);

	// Add dynamic campaign filters to filter items
	useEffect(() => {
		let newFilterItems = [
			...initialFilterItems
		];

		Object.keys(defaultFilters.campaigns).forEach(campaignId => {
			const filterItemsHasCampaign = filterItems.find(item => item.id === `campaigns ${campaignId}`);
			if (filterItemsHasCampaign) return;

			const campaign = campaigns.find(c => c.id === campaignId);

			newFilterItems = [
				...newFilterItems,
				{
					id: `campaigns ${campaign.id}`,
					title: campaign.articles?.filter?.text?.[locale],
					handleClear: () => setFilter(`campaigns.${campaign.id}`, 'off'),
					isActive: ({ activeFilters }) => activeFilters.campaigns[campaign.id] !== 'off',
					getValue: ({ activeFilters }) => activeFilters.campaigns[campaign.id]
				}
			];

			setFilterItems(newFilterItems);
		});
	}, [activeFilters.campaigns, campaigns, defaultFilters.campaigns, filterItems, initialFilterItems, setFilter, locale]);

	const renderItem = (title, className, handleClear) => (
		<div key={className} className={`ActiveFilters__item ${className}`} onClick={handleClear}>
			{typeof title === 'function' ? title(active) : title}
			<FaTrashAlt />
		</div>
	);

	const renderFilterInfo = () => {
		if (activeFilterItems.length <= 0) return null;

		return (
			<span className='ActiveFilters__info clear-filters' onClick={() => { clearFilters(); handleSearch(''); }}>
				Wis alle filters
			</span>
		);
	};

	return (
		<>
			{activeFilterItems.map(({ title, id, handleClear }) => renderItem(title, id, handleClear))}
			{renderFilterInfo()}
		</>
	);
}
