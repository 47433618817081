import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import api from '../../libraries/api';
import {
	selectActiveCampaigns,
	selectLocale
} from './../selectors';
import {
	CampaignDetail
} from '@abm-international/react-components';
import { Gtag } from '../common/utils';

export default function PromoDetail() {
	const locale = useSelector(selectLocale);
	const campaigns = useSelector(selectActiveCampaigns);
	const { id } = useParams();

	const campaign = useMemo(() => campaigns.find(c => c.id === id), [campaigns, id]);

	const onCampaignFolderClick =async (campaign, url) => {
		try {
			const description = campaign?.name?.nl;
			api.post('/api/v1/statistics/folders/onClick', JSON.stringify(
				{
					folderId: url,
					description: description,
					type: 'folder'
				}
			));
		} catch (error) {
			console.error(error);
		}
	};

	return <CampaignDetail
		campaign={campaign}
		locale={locale}
		textBack={'Terug naar overzicht'}
		to='/promos'
		onFolderClick={onCampaignFolderClick}
	/>;
}
