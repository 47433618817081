import { Spinner } from '@abm-international/react-components';
import useSWR from 'swr';
import useCustomer from './../../../libraries/hooks/useCustomer';
import { t } from '../../../libraries/i18n';
import { fetcher } from './../../../api';
import './FoodEventTickets.scss';
import Error from '../../../components/Error/Error';

const useTickets = () => {
	const { id: customerId } = useCustomer();
	const { data, error } = useSWR(`/api/customers/${customerId}/foodevent/tickets`, fetcher);

	return {
		data,
		error,
		isLoading: !data && !error
	};
};

export default function FoodEventTickets() {
	const { data, isLoading } = useTickets();
	const getTicketClassName = (ticket: string) => {
		if (data.winningTickets.length === 0) return 'ticket';
		if (data.winningTickets.includes(ticket)) return 'ticket winning';
		return 'ticket losing';
	};

	if (isLoading) return <Spinner />;

	if (data?.numTickets === 0 && data?.weight === 0) return <Error code={404} />;

	return (
		<div className='FoodEventTickets'>
			<div className='container'>
				<div className="BannerContainer">
					<img className='BannerImage' alt='' src={t('tradefair.ticketsBanner')}></img>
				</div>
				<div>
					<p>
						{t('tradefair.ticketsGreeting')}
					</p>

					<p>
						{t('tradefair.ticketsText0')}
						<br />
						{t('tradefair.ticketsText1')}
						<span className='bold'>{data?.weight}kg</span>
						{t('tradefair.ticketsText2')}
						<span className='bold'>{data?.numTickets}
							{t('tradefair.ticketsText3')}
						</span>
					</p>
				</div>
				{data?.numTickets > 0 &&
					<div>
						<h2>
							{t('tradefair.ticketsTitleTickets')}
						</h2>

						{isLoading && <Spinner />}

						{(!isLoading && data) && (
							<div className='tickets'>
								{[...new Array(data.numTickets)].map((_, i) => {
									const prefix = `00000${data.ticketPrefix}`.slice(-5);
									const ticketNumber = `000${i + 1}`.slice(-3);
									const ticket = `${prefix}${ticketNumber}`;

									return (
										<div className={getTicketClassName(ticket)} key={i}>
											{ticket}
										</div>
									);
								})}
							</div>
						)}
					</div>}
				<div>

					<h2>
						{t('tradefair.ticketsTitleDraw')}
					</h2>

					<p>
						{t('tradefair.ticketsTextDraw')}
					</p>
				</div>

				<div>
					<h2>
						{t('tradefair.ticketsTitleWinners')}
					</h2>

					<p>
						{t('tradefair.ticketsTextWinners')}
					</p>
				</div>

				<div>
					<p>
						{t('tradefair.ticketsSucces')}
					</p>

					<p>
						{t('tradefair.ticketsTeamCharles')}
					</p>
				</div>
			</div>
		</div>
	);
}
