/* eslint-disable no-useless-concat */
export default class BrowserConsoleTransport {
	constructor(options = {}) {
		this.name = options.name || 'BrowserConsoleTransport';
		this.level = options.level || 'info';
		this.colors = {
			blue: '#61AFEF',
			red: '#E06C75',
			orange: '#D19A66',
			yellow: '#FFD399',
			green: '#98C379',
			gray: 'gray',
			white: 'white',
			lightgray: 'lightgray',
			background: 'black'
		};
	}

	log(level, msg, meta) {
		const data = {
			level: level,
			msg: msg,
			userAgent: window.navigator.userAgent,
			meta: meta
		};

		const messageStart = `%c[${data.level.toUpperCase()}] %c${data.msg}`;
		let levelCSS = `color: ${this.colors.lightgray}`;
		const messageCSS = `color: ${this.colors.gray}`;
		// eslint-disable-next-line
		let logToConsole = console.log;

		switch (level) {
			case 'error':
				levelCSS = `color: ${this.colors.red}`;
				// eslint-disable-next-line
				logToConsole = console.error;
				break;
			case 'warn':
				levelCSS = `color: ${this.colors.yellow}`;
				// eslint-disable-next-line
				logToConsole = console.warn;
				break;
			case 'info':
				levelCSS = `color: ${this.colors.green}`;
				// eslint-disable-next-line
				logToConsole = console.info;
				break;
			case 'verbose':
				levelCSS = `color: ${this.colors.blue}`;
				// eslint-disable-next-line
				logToConsole = console.debug;
				break;
			case 'debug':
				levelCSS = `color: ${this.colors.lightgray}`;
				// eslint-disable-next-line
				logToConsole = console.debug;
				break;
			case 'silly':
				levelCSS = `color: ${this.colors.gray}`;
				// eslint-disable-next-line
				logToConsole = console.debug;
				break;
			default:
				levelCSS = `color: ${this.colors.lightgray}`;
				// eslint-disable-next-line
				logToConsole = console.log;
		}

		if (data.meta) {
			logToConsole(messageStart, levelCSS, messageCSS, data.meta);
		} else {
			logToConsole(messageStart, levelCSS, messageCSS);
		}
	}
}
