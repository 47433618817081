import {
	CUSTOMER_INVALIDATE,
	CUSTOMER_REQUEST,
	CUSTOMER_RECEIVE,
	CUSTOMER_REQUEST_FAIL,
	UPDATE_CUSTOMER_LOCALE,
} from './customerActions';
import {
	ORDER_DELIVERY_DATES_RECEIVE
} from './../order/orderActions';
import initialState from './../initialState';

export default function customer(
	state = initialState.customer,
	action
) {
	switch (action.type) {
		case CUSTOMER_INVALIDATE:
			return customerInvalidate(state, action);
		case CUSTOMER_REQUEST:
			return customerRequest(state, action);
		case CUSTOMER_RECEIVE:
			return customerReceive(state, action);
		case CUSTOMER_REQUEST_FAIL:
			return customerRequestFail(state, action);
		case UPDATE_CUSTOMER_LOCALE:
			return {
				...state,
				customer: {
					...state.customer,
					locale: action.payload
				}
			};
		case ORDER_DELIVERY_DATES_RECEIVE:
			return customerReceiveAddress(state, action);
		default:
			return state;
	}
}

function customerInvalidate(state, action) {
	return {
		...state,
		didInvalidate: true
	};
}

function customerRequest(state, action) {
	return {
		...state,
		isFetching: true,
		didInvalidate: false
	};
}

function customerReceive(state, action) {
	if (state.customer && state.customer.id !== action?.customer?.customer?.id) {
		// warn the user before reload
		// log that something went wrong
		// log the state/save it to localstorage to reinstate the current state when customerID matches
		document.location.reload();
	}
	const customer = {
		...state,
		...action.customer,
		isFetching: false,
		didInvalidate: false,
		lastUpdated: action.receivedAt,
		error: undefined
	};
	//UserPreferences.set(UserPreferences.PREFERENCES.LOCALE, action.customer.locale);

	return customer;
}

function customerRequestFail(state, action) {
	const gracefulTimeout = state.error && state.error.gracefulTimeout ? state.error.gracefulTimeout * 2 : 2;

	return {
		...state,
		isFetching: false,
		error: {
			...action.error,
			gracefulTimeout: gracefulTimeout
		}
	};
}

function customerReceiveAddress(state, action) {
	return {
		...state,
		addresses: {
			invoice: action.data.invoiceAddress,
			delivery: action.data.deliveryAddress
		}
	};
}
