import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { selectLocale, selectProducts } from './../../../selectors';
import TinyProductItem from './TinyProductItem';
import './orderReminderEan.scss';
import { ReactComponent as generatiedenkersImage } from '../../../../images/generatiedenkers.svg';

function OrderReminderEan(props) {
	const headerText = {
		nl: (
			<div>
				<h1><FaExclamationTriangle /> Opgelet</h1>
				<div className={'reminder__description'}>
					Volgende producten zijn van gewicht <em>en</em> ean code gewijzigd.<br />
					Voor meer info kan u <a target='_blank' rel='noopener noreferrer' href='https://portal.charles.eu/resources/campaigns/vvp/CHARLES-FactSheet_VVP_BFBC_NL_LR.pdf'>dit bestand</a> raadplegen.
				</div>
			</div>
		),
		fr: (
			<div>
				<h1><FaExclamationTriangle /> Attention</h1>
				<div className={'reminder__description'}>
					Les produits suivants ont changé de poids <em>et</em> de code EAN.<br />
					Pour plus d'informations vous pouvez consulter <a target='_blank' rel='noopener noreferrer' href='https://portal.charles.eu/resources/campaigns/vvp/CHARLES-FactSheet_VVP_BFBC_FR_LR.pdf'>ce fichier</a>.
				</div>
			</div>
		)
	};
	return (
		<div className={`reminder reminder--${formatCssClass(props.reminder.code)}`}>
			<img src={generatiedenkersImage} alt={'generatiedenkers'} />
			{headerText[props.locale]}
			{props.reminder.data.map(id => (
				<TinyProductItem
					key={id}
					product={props.products.filter(p => p.id === id.toString())[0]}
				/>
			))}
		</div>
	);
}

function formatCssClass(string){
	return string.replace(/\./g, '-');
}


function mapStateToProps(state) {
	return {
		products: selectProducts(state),
		locale: selectLocale(state)
	};
}

export default connect(mapStateToProps)(OrderReminderEan);
