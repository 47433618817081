import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissions } from './../../modules/selectors';
import Error from '../../components/Error/Error';

export default function useRestrictedContent(permission) {
	const permissions = useSelector(selectPermissions);
	const hasPermission = permission === false || permissions.includes(permission);

	const RestrictedContent = useCallback((props) => {
		if (!hasPermission) return null;
		return props.children;
	}, [hasPermission]);

	const RestrictedContentError = useCallback((props) => {
		if (hasPermission) return null;
		if (props.children) return props.children;

		return <Error code={403} size={'medium'} />;
	}, [hasPermission]);

	return [RestrictedContent, RestrictedContentError];
}
