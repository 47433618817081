import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
	selectCustomerId,
	selectIsMarginsFetching,
	selectIsMarginsMetadataFetching, selectIsMarginsSaving,
	selectMargins,
	selectMarginsCategories,
	selectUnsavedMargins
} from '../selectors';
import React, { useEffect, useState } from 'react';
import {
	cancelUnsavedMargins,
	fetchMarginsIfNeeded,
	fetchMarginsMetadataIfNeeded,
	//saveDetailMargins,
	saveMargins,
	setMargin
} from './MarginsActions';
import { Button, List, Spinner } from '@abm-international/react-components';
import { getLocale, t } from '../../libraries/i18n';
import {
	FaPencilAlt,
	FaSave,
	FaTimes,
} from 'react-icons/fa';

function Overview() {
	const locale = getLocale();
	const dispatch = useDispatch();
	const history = useHistory();
	const match = useRouteMatch();
	const customerId = useSelector(selectCustomerId);
	const isFetching = useSelector(selectIsMarginsFetching);
	const isMetadataFetching = useSelector(selectIsMarginsMetadataFetching);
	const margins = useSelector(selectMargins);
	const categories = useSelector(selectMarginsCategories);
	const unsavedMargins = useSelector(selectUnsavedMargins);
	const isSaving = useSelector(selectIsMarginsSaving);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		dispatch(fetchMarginsIfNeeded(customerId));
		dispatch(fetchMarginsMetadataIfNeeded(customerId));
	}, [customerId, dispatch]);

	const getMargin = (category, marginType, productType, fallback) => {
		const unsavedMargin = unsavedMargins.find(item => {
			return ( item.category === category && item.marginType === marginType && item.productType === productType );
		});
		if ( unsavedMargin ) return unsavedMargin.value;
		if ( fallback ) return fallback;
		return ''; // make sure that the input value is never undefined/uncontrolled.
	};

	const editHeader = [
		{
			render: ({ name }) => name[locale],
			title: t('margins.type_title'),
			key: 1
		},
		{
			render: ({
				value,
				category,
				marginType,
				productType,
				proposed
			}) => (
				<span className={getMargin(category, marginType, productType, value) !== '' ? 'proposed overwritten': 'proposed'}>{proposed} %</span>
			),
			title: t('margins.proposed_title'),
			key: 2
		},
		{
			render: ({
				value,
				category,
				marginType,
				productType
			}) => (
				<>
					<input
						type={'number'}
						value={getMargin(category, marginType, productType, value)}
						onChange={e => dispatch(setMargin(e.target.value, category, marginType, productType))}
						onClick={e => e.stopPropagation()}
						disabled={isSaving}
					/>
					%
				</>
			),
			title: t('margins.changed_title'),
			key: 3
		},
	];

	const displayHeader = [
		{ render: ({ name }) => name[locale], title: t('margins.type_title'), key: 1 },
		{
			render: ({ value, proposed }) => {
				if (value) return `${value} %`;
				return `${ proposed } %`;
			},
			title: t('margins.margin_title'),
			key: 2
		}
	];

	const getListContent = (categoryId, productTypeId, marginTypes) => {
		if (isFetching) return undefined;
		return Object.entries(marginTypes).map(([id, name]) => {
			const margin = margins.find(
				({ category, marginType, productType }) =>
					category === categoryId && marginType === id && productType === productTypeId
			);
			return {
				id,
				name,
				...margin
			};
		});
	};

	const goToDetail = (e, { category, marginType, productType }) => {
		history.push(`${ match.path }/category/${ category }/${ marginType }/${ productType }`);
	};

	const renderActions = () => (
		<div className={'actions'}>
			<Button
				icon={<FaTimes />}
				onClick={() => {
					setIsEditing(false);
					dispatch(cancelUnsavedMargins());
				}}
				color='orange'
			>
				{t('margins.cancel')}
			</Button>
			<Button
				icon={<FaSave />}
				onClick={() => {
					setIsEditing(false);
					dispatch(saveMargins(customerId));
				}}
				disabled={!unsavedMargins.length}
				primary
				color='green'
			>
				{t('margins.save')}
			</Button>
		</div>
	);


	if (isMetadataFetching) return <Spinner />;
	if (!isFetching && margins?.length <= 0) return <div>{ t('margins.no_categories') }</div>;

	return (
		<div className={'Overview'}>
			{!isEditing && (
				<Button
					onClick={() => setIsEditing(true)}
					icon={<FaPencilAlt />}
					primary
				>
					{ t('margins.edit')}
				</Button>
			)}

			{categories.map(({
				id,
				title,
				productTypes,
				marginTypes
			}) => (
				<section key={id}>
					<h3>{ title[locale] }</h3>
					{Object.entries(productTypes).map(([productTypeId, productType]) => (
						<section key={productTypeId} className='productType'>
							<h4>{productType[locale]}</h4>
							<List
								header={isEditing || isSaving ? editHeader : displayHeader}
								content={getListContent(id, productTypeId, marginTypes)}
								pagination={{ itemsPerPage: 6, page: 0 }}
								action={isEditing ? undefined : goToDetail}
								gridTemplateColumns={isEditing ? '3fr 1fr 1fr' : '3fr 2fr'}
							/>
						</section>
					))}
				</section>
			))}

			{isEditing && renderActions()}
		</div>
	);
}

export default Overview;
