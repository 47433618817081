import { useCallback } from 'react';
import './ProductsFilters.scss';
import ProductRangeFilter from './ProductRangeFilter/ProductRangeFilter';
import CampaignProductsFilters from '../../modules/CustomCampaigns/CampaignProductsFilters';
import MainFilters from './MainFilters/MainFilters';
import Allergens from './Allergens/Allergens';
import { Gtag } from '../../modules/common/utils';
import Brands from './Brands/Brands';

function ProductsFilters(props) {
	const {
		clearFilters,
		setFilter
	} = props;
	const clearFiltersGA = useCallback((filterGroup) => {
		Gtag.event({
			category: 'ClearFilters',
			action: filterGroup
		});
		clearFilters(filterGroup);
	}, [clearFilters]);

	const clearMainAndCampaignFilters = useCallback(() => {
		clearFiltersGA('main');
		clearFiltersGA('campaigns');
	}, [clearFiltersGA]);

	const clearProductRangeFilters = useCallback(() => {
		setFilter('productRange.shopcode', '');
	}, [setFilter]);

	const clearAllergensFilters = useCallback(() => {
		clearFiltersGA('allergens');
	}, [clearFiltersGA]);

	const clearBrandsFilters = useCallback(() => {
		clearFiltersGA('brands');
	}, [clearFiltersGA]);

	return (
		<div className={'ProductsFilters'}>
			<ProductRangeFilter
				filter={props.filters.productRange}
				clearFilters={clearProductRangeFilters}
				setFilter={setFilter}
				isProductsFetching={props.isProductsFetching}
			/>
			<CampaignProductsFilters
				filters={props.filters}
				clearFilters={clearFiltersGA}
				toggleFilter={props.toggleFilter}
				setFilter={setFilter}
			/>
			<MainFilters
				filters={{ main: props.filters.main, campaigns: props.filters.campaigns }}
				clearFilters={clearMainAndCampaignFilters}
				toggleFilter={props.toggleFilter}
				buylist={props.buylist}
			/>
			<Brands
				filters={props.filters.brands}
				clearFilters={clearBrandsFilters}
				toggleFilter={props.toggleFilter}
			/>
			<Allergens
				filters={props.filters.allergens}
				clearFilters={clearAllergensFilters}
				toggleFilter={props.toggleFilter}
			/>
		</div>
	);
}

export default ProductsFilters;
