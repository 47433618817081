import { rest } from 'msw';

export const login = [
	rest.post('http://localhost:4000/api/v1/auth/login', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				'username': 'braro',
				'password': 'demo'
			}),
		);
	})
];
