import {
	ORDER_CONTINUE_WITH_ORDER_FROM_SERVER,
	ORDER_DELIVERY_DATE_SELECT,
	ORDER_DELIVERY_DATES_INVALIDATE,
	ORDER_DELIVERY_DATES_RECEIVE,
	ORDER_DELIVERY_DATES_REJECT,
	ORDER_DELIVERY_DATES_REQUEST,
	ORDER_LOAD_EXISTING_ORDER,
	ORDER_MODULE_LOADED,
	ORDER_NEW,
	ORDER_NEW_DETAILS,
	ORDER_OPEN_RECEIVE,
	ORDER_OPEN_REQUEST,
	ORDER_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE,
	ORDER_PRODUCT_SELECTION_CHANGE_SORTING,
	ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE,
	ORDER_PRODUCT_SELECTION_CLEAR_FILTERS,
	ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT,
	ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION,
	ORDER_PRODUCT_SELECTION_HANDLE_SEARCH,
	ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED,
	ORDER_PRODUCT_SELECTION_SET_FILTER,
	ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER,
	ORDER_PRODUCT_SELECTION_TOGGLE_FILTER,
	ORDER_REMARKS_UPDATE_COMMENT,
	ORDER_REMARKS_UPDATE_REFERENCE,
	ORDER_RESET,
	ORDER_SAVE_RECEIVE,
	ORDER_SAVE_REJECT,
	ORDER_SAVE_REQUEST,
	ORDER_SET_ANSWERS,
	ORDER_TOGGLE_SHOW_SCORES,
	ORDER_UPDATE_PRODUCT_QUANTITY,
	ORDER_DELETE_PRODUCT_FROM_ORDER,
	ORDER_CONFIRM_MAD_QUANTITY,
	ORDER_UPDATE_ORDER_PRODUCTS,
	ORDER_UPDATE_LAST_VISITED_STEP,
	ORDER_NEW_REJECT,
	ORDER_EOB_NET_REQUEST,
	ORDER_EOB_NET_RECEIVE,
	ORDER_EOB_NET_REJECT
} from './orderActions';
import { APP_LOADED } from './../appActions';
import { PRODUCTS_CAMPAIGNS_RECEIVE } from './../products/productsActions';
import deliveryDates from './deliveryDatesReducer';
import productSelection from './productSelectionReducer';

import UserPreferences from './../../libraries/userPreferences';
import initialState from './../initialState';
import promoTypes from './../products/promoTypes';
import { logger } from './../../libraries/logger/logger';
import { selectOrderQuantities } from './../selectors';
import { selectFailedSaveAttempts } from './orderSelectors';

export default function order(
	state = initialState.order,
	action
) {
	switch (action.type) {
		case ORDER_NEW:
			return orderNewOrder(state, action);
		case ORDER_NEW_DETAILS:
			return orderNewOrderDetails(state, action);
		case ORDER_NEW_REJECT:
			return orderNewReject(state, action);
		case 'ORDER_RESET_SUBMIT_SUCCESS':
			return orderResetSubmitSuccess(state, action);
		case ORDER_REMARKS_UPDATE_REFERENCE:
			return orderRemarksUpdateReference(state, action);
		case ORDER_REMARKS_UPDATE_COMMENT:
			return orderRemarksUpdateComment(state, action);
		case ORDER_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE:
			return orderProductSelectionAwardPromoIfEligible(state, action);
		case ORDER_LOAD_EXISTING_ORDER:
			return orderLoadExistingOrder(state, action);
		case ORDER_CONTINUE_WITH_ORDER_FROM_SERVER:
			return orderContinueWithOrderFromServer(state, action);
		case ORDER_SAVE_REQUEST:
			return orderSaveRequest(state, action);
		case ORDER_SAVE_RECEIVE:
			return orderSaveReceive(state, action);
		case ORDER_SAVE_REJECT:
			return orderSaveReject(state, action);
		case ORDER_RESET:
			return orderReset(state, action);
		case ORDER_TOGGLE_SHOW_SCORES:
			return orderToggleShowScores(state, action);
		case ORDER_OPEN_REQUEST:
			return orderOpenRequest(state, action);
		case ORDER_OPEN_RECEIVE:
			return orderOpenReceive(state, action);
		case ORDER_PRODUCT_SELECTION_CHANGE_SORTING:
			return orderProductSelectionChangeSorting(state, action);
		case ORDER_UPDATE_ORDER_PRODUCTS:
			return orderUpdateOrderProducts(state, action);
		case ORDER_SET_ANSWERS:
			return {
				...state,
				review: {
					...state.review,
					answers: action.payload
				}
			};
		case ORDER_UPDATE_LAST_VISITED_STEP:
			return {
				...state,
				lastVisitedStep: action.payload
			};
		case ORDER_EOB_NET_REQUEST:
		case ORDER_EOB_NET_RECEIVE:
		case ORDER_EOB_NET_REJECT:
		case ORDER_CONFIRM_MAD_QUANTITY:
		case APP_LOADED:
		case ORDER_MODULE_LOADED:
		case ORDER_DELIVERY_DATES_REQUEST:
		case ORDER_DELIVERY_DATES_RECEIVE:
		case ORDER_DELIVERY_DATES_INVALIDATE:
		case ORDER_DELIVERY_DATES_REJECT:
		case ORDER_DELIVERY_DATE_SELECT:
		case ORDER_UPDATE_PRODUCT_QUANTITY:
		case ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION:
		case ORDER_PRODUCT_SELECTION_HANDLE_SEARCH:
		case ORDER_PRODUCT_SELECTION_TOGGLE_FILTER:
		case ORDER_PRODUCT_SELECTION_SET_FILTER:
		case ORDER_PRODUCT_SELECTION_CLEAR_FILTERS:
		case ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED:
		case ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT:
		case ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER:
		case ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE:
		case PRODUCTS_CAMPAIGNS_RECEIVE:
		case ORDER_DELETE_PRODUCT_FROM_ORDER:
		default:
			return {
				...state,
				productSelection: productSelection(state.productSelection, action),
				deliveryDates: deliveryDates(state.deliveryDates, action),
				quantities: quantities(state.quantities, { ...action, value: action.quantity }),
				inOrderProductIds: inOrderProductIds(state.inOrderProductIds, action),
				quantityTimestamps: quantities(state.quantityTimestamps, { ...action, value: action.timestamp }),
				ignoreMadQuantity: ignoreMadQuantity(state.ignoreMadQuantity, action),
				lastUpdated: lastUpdated(state.lastUpdated, action),
				eob: eob(state.eob, action)
			};
	}
}

function eob(state = {}, action) {
	switch (action.type) {
		case ORDER_EOB_NET_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case ORDER_EOB_NET_RECEIVE:
			return {
				...state,
				isFetching: false,
				items: {
					...state.items ?? {},
					...action.payload
				}
			};
		case ORDER_EOB_NET_REJECT:
			return {
				...state,
				isFetching: false,
				error: action.payload
			};
		default:
			return state;
	}
}

export function quantities (
	state = {},
	action
) {
	switch (action.type) {
		case ORDER_DELIVERY_DATE_SELECT:
		case ORDER_PRODUCT_SELECTION_CHANGE_SORTING:
		case ORDER_PRODUCT_SELECTION_CLEAR_FILTERS:
		case ORDER_PRODUCT_SELECTION_HANDLE_SEARCH:
		case ORDER_PRODUCT_SELECTION_SET_FILTER:
		case ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER:
		case ORDER_PRODUCT_SELECTION_TOGGLE_FILTER:
			// When one of these actions fires,
			// we can clean up the quantities list
			return cleanup(state, action);
		case ORDER_UPDATE_PRODUCT_QUANTITY:
			return {
				...state,
				[action.product.id]: action.value
			};
		case ORDER_DELETE_PRODUCT_FROM_ORDER:
			return deleteQuantity(state, action);
		default:
			return state;
	}

	function cleanup(state, action) {
		const newState = { ...state };
		Object.keys(state).forEach(productId => {
			if (!state[productId]) delete newState[productId];
		});
		return { ...newState };
	}

	function deleteQuantity(state, action) {
		const newState = state;
		delete newState[action.payload.id];
		return { ...newState };
	}
}

export function inOrderProductIds (
	state = [],
	action
) {
	switch (action.type) {
		case ORDER_UPDATE_PRODUCT_QUANTITY:
			const inOrder = action.quantity > 0;
			const inState = state.some(productId => productId === action.product.id);
			if (inOrder && !inState) return [...state, action.product.id];
			if (!inOrder && inState) return state.filter(productId => productId !== action.product.id);
			return state;
		case ORDER_DELETE_PRODUCT_FROM_ORDER:
			return state.filter(productId => productId !== action.payload.id);
		default:
			return state;
	}
}

export function ignoreMadQuantity(
	state = {},
	action
) {
	switch (action.type) {
		case ORDER_UPDATE_PRODUCT_QUANTITY:
			// We want to remove the ignored mqa warning when the new
			// quantity is higher than the already confirmed mad quantity
			if (state[action.product.id] && action.quantity > state[action.product.id]) {
				const newState = { ...state };
				delete newState[action.product.id];
				return newState;
			}
			return state;
		case ORDER_CONFIRM_MAD_QUANTITY:
			return {
				...state,
				[action.product.id]: action.product.quantity
			};
		default:
			return state;
	}
}

export function lastUpdated(
	state = 0,
	action
) {
	switch (action.type) {
		case ORDER_UPDATE_PRODUCT_QUANTITY:
		case ORDER_DELIVERY_DATE_SELECT:
			return Date.now();
		default:
			return state;
	}
}

function orderRemarksUpdateReference(state, action) {
	return {
		...state,
		remarks: {
			...state.remarks,
			reference: action.reference
		},
		lastUpdated: Date.now()
	};
}

function orderRemarksUpdateComment(state, action) {
	return {
		...state,
		remarks: {
			...state.remarks,
			comment: action.comment
		},
		lastUpdated: Date.now()
	};
}

function orderProductSelectionAwardPromoIfEligible(state, action) {
	const { product, quantity, promo } = action;
	if (!promo.trigger || (!promo.trigger.barrel && String(promo.trigger.id) !== String(product.id))) {
		return state;
	}

	let newState = { ...state };

	let totalQuantity = quantity; // Add colli products if applicable
	let colliNumOfUniqueProducts = 0;
	if (promo.trigger.barrel) {
		totalQuantity = 0;
		promo.trigger.barrel.products.forEach(id => {
			const productQuantity = state.quantities[id];
			if (productQuantity > 0) {
				colliNumOfUniqueProducts++;
				totalQuantity += productQuantity;
			}
		});
	}

	if (promo.trigger.barrel && promo.trigger.barrel.num > colliNumOfUniqueProducts) {
		newState = removeAwardedPromo(newState, promo);
		return newState;
	}

	if (totalQuantity >= promo.trigger.num) {
		// Promo toekennen
		if (promo.type === promoTypes.FREE_BY_UNIT) {
			newState = awardFreeByUnitPromo(newState, promo, totalQuantity, product);
		} else {
			newState = awardOtherPromo(newState, promo);
		}
	} else {
		newState = removeAwardedPromo(newState, promo);
	}

	return newState;
}

export function removeAwardedPromo(state, promo) {
	if (!promo.target || !promo.trigger) {
		return state;
	}

	if (promo.trigger?.barrel) {
		promo.trigger.barrel.products.forEach(id => {
			delete state.awardedPromos[id];
		});
	}

	const targetId = promo.target?.id || promo.trigger?.id;
	delete state.awardedPromos[targetId];
	delete state.awardedPromos[promo.trigger.id];
	return state;
}

export function awardOtherPromo(state, promo) {
	if (!promo.target || !promo.trigger) {
		return state;
	}

	if (promo.trigger?.barrel) {
		promo.trigger.barrel.products.forEach(id => {
			state.awardedPromos[id] = {
				id: promo.id
			};
		});
	}

	const targetId = promo.target?.id || promo.trigger?.id;
	return {
		...state,
		awardedPromos: {
			...state.awardedPromos,
			[promo.trigger.id]: {
				id: promo.id
			},
			[targetId]: {
				id: promo.id,
			}
		}
	};
}

export function awardFreeByUnitPromo(state, promo, quantity, product) {
	if (!promo.target || !promo.trigger) {
		return state;
	}

	if (promo.trigger.barrel) {
		promo.trigger.barrel.products.forEach(id => {
			if (!state.awardedPromos) {
				state.awardedPromos = {};
			}

			state.awardedPromos[id] = {
				id: promo.id
			};
		});
	}

	const targetId = promo.target?.id || promo.trigger?.id;
	return {
		...state,
		awardedPromos: {
			...state.awardedPromos,
			[promo.trigger.id]: {
				id: promo.id
			},
			[targetId]: {
				id: promo.id,
				quantity: Math.floor(quantity / promo.trigger.num) * parseInt(promo.target.num)
			}
		}
	};
}

function orderLoadExistingOrder(state, action) {
	return {
		...state,
		orderId: action.payload.orderId,
		deliveryDates: deliveryDates(state.deliveryDates, action),
		quantities: action.payload.products,
		inOrderProductIds: Object.entries(action.payload.products).filter(([, quantity]) => quantity > 0).map(([id]) => id),
		remarks: action.payload.remarks,
		companyId: action.payload.companyId,
		fromServer: !action.payload.emptyOrder,
		modifiedBy: action.payload.modifiedBy
	};
}


function orderContinueWithOrderFromServer(state, action) {
	return {
		...state,
		fromServer: false
	};
}

function orderNewOrder(state, action) {
	return {
		...state,
		deliveryDates: deliveryDates(state.deliveryDates, action),
		quantities: selectOrderQuantities(initialState),
		remarks: initialState.order.remarks,
		orderId: undefined,
		companyId: undefined,
		fromServer: false,
		isFetchingOrderId: true,
		// submitSuccess: false,
	};
}

function orderNewOrderDetails(state, action) {
	return {
		...state,
		orderId: action.orderId,
		companyId: action.companyId,
		isFetchingOrderId: false,
		newOrderAttempts: 0
	};
}

function orderResetSubmitSuccess(state, action) {
	return {
		...state,
		submitSuccess: false,
	};
}

function orderNewReject(state, action) {
	return {
		...state,
		newOrderAttempts: (state.newOrderAttempts ?? 0) + 1,
		isFetchingOrderId: false,
		lastNewOrderIdTry: Date.now()
	};
}

function orderSaveRequest(state, action) {
	return {
		...state,
		isSaving: true,
		finalSave: action.payload
	};
}

function orderSaveReceive(state, action) {
	const issues = !!action.payload.warnings;

	return {
		...state,
		lastSaved: action.payload.saveTime || Date.now(),
		isSaving: false,
		didSaveFail: false,
		offline: false,
		review: {
			...state.review,
			error: '',
			issues: action.payload.issues || [],
			warnings: action.payload.warnings ?? {},
			questions: action.payload.questions ?? [],
			info: action.payload.info ?? []
		},
		reminders: action.payload.reminders ?? [],
		submitSuccess: issues ? false : action.payload.questions ? false : action.payload.submit,
		failedAttempts: 0
	};
}

function orderSaveReject(state, action) {
	if (action.response.message === 'Network request failed') {
		logger.info('Network request failed');
		return {
			...state,
			didSaveFail: true,
			lastSaved: Date.now(),
			isSaving: false,
			offline: true,
			failedSaveAttempts: selectFailedSaveAttempts(state) + 1,
			review: {
				error: {
					code: 600
				}
			}
		};
	}

	const statusCode = action?.response?.result?.status;
	const errorCode = action?.response?.body?.error_code;
	const error = {
		type: statusCode >= 500 ? 'server': 'client',
		code: errorCode
	};

	return {
		...state,
		didSaveFail: true,
		lastSaved: Date.now(),
		isSaving: false,
		offline: action.error === 600,
		failedSaveAttempts: selectFailedSaveAttempts(state) + 1,
		review: {
			...state.review,
			error
		}
	};
}

function orderReset(state, action) {
	return {
		...state,
		deliveryDates: deliveryDates(state.deliveryDates, action),
		productSelection: productSelection(state.productSelection, action),
		review: {
			reviewProducts: [],
			warnings: {},
			questions: []
		},
		remarks: {
			comment: '',
			reference: ''
		},
		quantities: {},
		inOrderProductIds: [],
		isSaving: false,
		lastUpdated: 0,
		lastSaved: 0,
		reminders: [],
		ignoreMadQuantity: {},
		awardedPromos: {}
	};
}

function orderToggleShowScores(state, action) {
	return {
		...state,
		showScores: !state.showScores
	};
}

function orderOpenRequest(state, action) {
	return {
		...state,
		isOpenOrdersFetching: true
	};
}

function orderOpenReceive(state, action) {
	return {
		...state,
		isOpenOrdersFetching: false
	};
}

function orderProductSelectionChangeSorting(state, action) {
	let direction = action.payload.direction;
	if (action.payload.sortKey !== state.productSelection.sortDirection) direction = 'ascending';

	// TODO: Remove this (no side effects in reducers)
	UserPreferences.set(UserPreferences.PREFERENCES.PRODUCT_SORT_KEY, action.payload.sortKey);
	UserPreferences.set(UserPreferences.PREFERENCES.PRODUCT_SORT_DIRECTION, direction);

	return {
		...state,
		productSelection: productSelection(state.productSelection, action),
		quantities: quantities(state.quantities, action)
	};
}

const orderUpdateOrderProducts = (state, action) => {
	// TODO: Finish and test the promo calcuation on init for orders
	// also check the promo for the one with "num: 25" on target
	const availablePromos = [...new Set(action.products.filter(product => product.promo && state.quantities[product.id]).map(product => product.promo))];


	const awardedPromos = availablePromos.reduce((awarded, promo) => {
		// let colliNumOfUniqueProducts = 0;
		let totalQuantity = 0;
		console.log('AV PRO', promo, totalQuantity);

		if (promo.trigger?.barrel) {
			// totalQuantity = 0;
			promo.trigger.barrel.products.forEach(id => {
				const productQuantity = state.quantities[id];

				if (productQuantity > 0) {
					// colliNumOfUniqueProducts++;
					totalQuantity += productQuantity;
				}
			});
		} else {
			totalQuantity = state.quantities[promo.trigger.id];
		}

		if (totalQuantity >= promo.trigger.num) {
			if (promo.type === promoTypes.FREE_BY_UNIT) {
				awarded[promo.target.id] = {
					id: promo.id,
					quantity: (awarded[promo.target.id]?.quantity || 0) + Math.floor(totalQuantity / promo.trigger.num) * parseInt(promo.target.num)
				};
			} else {
				// TODO(' -- awardOtherPromo, does nothing but add the ids to the awardedPromos, but not the quantity');
				// Doesn't need to add the id's to the awardedPromos list, remove in future
				// newState = awardOtherPromo(newState, product.promo);
			}
		}

		return awarded;
	}, {});

	return {
		...state,
		orderProducts: action.products,
		awardedPromos
	};
};
