import {
	MARGINS_REQUEST,
	MARGINS_REQUEST_RECEIVE,
	MARGINS_REQUEST_REJECT,
	MARGINS_METADATA_REQUEST,
	MARGINS_METADATA_REQUEST_RECEIVE,
	MARGINS_METADATA_REQUEST_REJECT,
	SET_MARGIN,
	MARGINS_SAVE_REQUEST,
	MARGINS_SAVE_REQUEST_RECEIVE,
	MARGINS_SAVE_REQUEST_REJECT,
	MARGINS_DETAIL_REQUEST_REJECT,
	MARGINS_DETAIL_REQUEST_RECEIVE,
	MARGINS_DETAIL_REQUEST,
	MARGINS_CANCEL,
	MARGINS_DETAIL_SAVE_REQUEST,
	MARGINS_DETAIL_SAVE_REQUEST_RECEIVE,
	MARGINS_DETAIL_SAVE_REQUEST_REJECT,
	SET_DETAIL_MARGIN,
	SET_DETAIL_MARGIN_BY_PRICE
} from './MarginsActions';

export default function margins(
	state = {
		isFetching: false,
		items: null,
		didFail: false,
		unsavedItems: [],
		isSaving: false,
		didSaveFail: false,
		metadata: {
			isFetching: false,
			categories: null,
			didFail: false,
		},
		detail: {},
	},
	action
) {
	switch (action.type) {
		case MARGINS_REQUEST:
		case MARGINS_REQUEST_RECEIVE:
		case MARGINS_REQUEST_REJECT:
			return fetchMargins(state, action);
		case MARGINS_METADATA_REQUEST:
		case MARGINS_METADATA_REQUEST_RECEIVE:
		case MARGINS_METADATA_REQUEST_REJECT:
			return {
				...state,
				metadata: metadata(state.metadata, action)
			};
		case MARGINS_DETAIL_REQUEST:
		case MARGINS_DETAIL_REQUEST_RECEIVE:
		case MARGINS_DETAIL_REQUEST_REJECT:
			return {
				...state,
				detail: {
					...state.detail,
					...fetchDetailMargins(state.detail, action)
				}

			};
		case MARGINS_SAVE_REQUEST:
		case MARGINS_SAVE_REQUEST_RECEIVE:
		case MARGINS_SAVE_REQUEST_REJECT:
		case MARGINS_DETAIL_SAVE_REQUEST:
		case MARGINS_DETAIL_SAVE_REQUEST_RECEIVE:
		case MARGINS_DETAIL_SAVE_REQUEST_REJECT:
			return save(state, action);
		case SET_MARGIN:
		case SET_DETAIL_MARGIN:
		case SET_DETAIL_MARGIN_BY_PRICE:
			return setUnsaved(state, action);
		case MARGINS_CANCEL:
			return {
				...state,
				unsavedItems: [],
				detail: Object.fromEntries(Object.entries(state.detail).map(([key, value]) => {
					const { unsavedItems, ...newValue } = value;
					return [key, newValue];
				}))
			};
		default:
			return { ...state };
	}
}

function fetchMargins (
	state = {},
	action
) {
	switch (action.type) {
		case MARGINS_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case MARGINS_REQUEST_RECEIVE:
			return {
				...state,
				isFetching: false,
				lastUpdated: Date.now(),
				items: action.data.margins,
				didFail: false
			};
		case MARGINS_REQUEST_REJECT:
			return {
				...state,
				isFetching: false,
				didFail: true,
				error: action.error
			};
		default:
			return state;
	}
}

function metadata (
	state = {},
	action
) {
	switch (action.type) {
		case MARGINS_METADATA_REQUEST:
			return {
				...state,
				isFetching: true
			};

		case MARGINS_METADATA_REQUEST_RECEIVE:
			return {
				...state,
				...action.data,
				isFetching: false,
				lastUpdated: Date.now(),
				didFail: false
			};

		case MARGINS_METADATA_REQUEST_REJECT:
			return {
				...state,
				isFetching: false,
				didFail: true,
				error: action.error
			};
		default:
			return state;
	}


}

function save ( // TODO: handle detail actions
	state = {},
	action
) {
	switch (action.type) {
		case MARGINS_SAVE_REQUEST:
			return {
				...state,
				isSaving: true
			};

		case MARGINS_SAVE_REQUEST_RECEIVE:
			return {
				...state,
				isSaving: false,
				didSaveFail: false,
				unsavedItems: [], // todo: show errors
				items: [
					...state.items.map(item => {
						const mutator = state.unsavedItems.find(({
							category,
							marginType,
							productType
						}) => (item.category === category && item.marginType === marginType && item.productType === productType));
						const mutation = mutator ? {
							value: +mutator.value,
							version: +mutator.value // TODO: fetch new version here
						} : {};
						return {
							...item,
							...mutation
						};
					}),
				]
			};

		case MARGINS_SAVE_REQUEST_REJECT:
			return {
				...state,
				isSaving: false,
				didSaveFail: true, // todo save actions show errors
			};
		case MARGINS_DETAIL_SAVE_REQUEST:
			return saveDetailRequest(state, action.data);
		case MARGINS_DETAIL_SAVE_REQUEST_RECEIVE:
			return saveDetailReceive(state, action.data);
		case MARGINS_DETAIL_SAVE_REQUEST_REJECT:
			return saveDetailReject(state, action.data);
		default:
			return state;
	}
}

function saveDetailRequest(state, { category, marginType, productType }){
	return {
		...state,
		detail: {
			...state.detail,
			[`${category}${marginType ?? '-'}${productType}`]: {
				...state.detail[`${category}${marginType ?? '-'}${productType}`],
				isSaving: true,
			}
		}
	};
}
function saveDetailReceive(state, action){
	const {
		category,
		marginType,
		productType,
		// response
	} = action;
	const detailKey = `${category}${marginType ?? '-'}${productType}`;
	const detail = state.detail[detailKey];

	return {
		...state,
		detail: {
			...state.detail,
			[detailKey]: {
				...detail,
				isSaving: false,
				didSaveFail: false,
				unsavedItems: [], // todo: show errors,
				articles: {
					...detail.articles,
					...mutateArticles(detail)
				},
				fresh: false
			}
		}

	};
}

function saveDetailReject(state, action){
	const {
		category,
		marginType,
		productType,
		// response
	} = action;

	return {
		...state,
		detail: {
			...state.detail,
			[`${category}${marginType ?? '-'}${productType}`]: {
				...state.detail[`${category}${marginType ?? '-'}${productType}`],
				isSaving: false,
				didSaveFail: true, // todo save actions show errors
			},
			fresh: false
		}

	};
}

function mutateArticles(detail){
	return Object.fromEntries(detail.unsavedItems.map(({
		article: articleId,
		value
	}) => {
		return [ articleId, {
			...detail.articles[articleId],
			value: +value,
			version: +value // TODO: fetch new version here
		}];
	}));
}


function setUnsaved (
	state = {},
	action
) {
	switch (action.type) {
		case SET_MARGIN:
			return setMargin(state, action.data);
		case SET_DETAIL_MARGIN:
			return setDetailMargin(state, action.data);
		case SET_DETAIL_MARGIN_BY_PRICE:
			return setDetailMarginByPrice(state, action.data);
		default:
			return state;
	}
}

function setMargin(state, action){
	const {
		value,
		category,
		marginType,
		productType
	} = action;
	const otherUnsavedMargins = state.unsavedItems.filter(item => {
		return ( item.category !== category || item.marginType !== marginType || item.productType !== productType );
	});

	return {
		...state,
		unsavedItems: [
			...otherUnsavedMargins,
			{
				value,
				category,
				marginType,
				productType
			}
		]
	};
}

function setDetailMargin(state, action){
	const {
		value,
		category,
		marginType,
		productType,
		article
	} = action;

	const detail = state.detail[`${category}${marginType ?? '-'}${productType}`];

	const otherUnsavedMargins = detail?.unsavedItems?.filter(item => {
		return ( item.article !== article );
	}) ?? [];

	return {
		...state,
		detail: {
			...state.detail,
			[`${category}${marginType ?? '-'}${productType}`]: {
				...detail,
				unsavedItems: [
					...otherUnsavedMargins,
					{
						value,
						article
					}
				]
			}

		}
	};
}

function setDetailMarginByPrice(state, action){
	const {
		value: consumerPrice,
		category,
		marginType,
		productType,
		article: articleId
	} = action;

	const getPurchasePrice = (pUnit, sUnit, price, weight, amount) => {
		if (pUnit === 'K' && sUnit === 'K') {
			return price;
		}

		if (pUnit === 'S' && sUnit === 'S') {
			return price / amount;
		}

		if (pUnit === 'K' && sUnit === 'S') {
			return price * weight / amount;
		}

		if (pUnit === 'S' && sUnit === 'K') {
			return price / weight;
		}
	};

	const detail = state.detail[`${category}${marginType ?? '-'}${productType}`];

	const otherUnsavedMargins = detail?.unsavedItems?.filter(item => {
		return ( item.article !== articleId );
	}) ?? [];


	const article = detail?.articles?.[articleId];

	const vatMultiplier = 1 + ( article?.sales?.vat / 100 );
	const purchasePrice = getPurchasePrice(article.purchase.unit, article.sales.unit, article.purchase.price, article.purchase.weight.net, article.consumerPackageGoods.quantity);

	const margin = ((consumerPrice / (purchasePrice * vatMultiplier)) * 100).toFixed(2);

	return {
		...state,
		detail: {
			...state.detail,
			[`${category}${marginType ?? '-'}${productType}`]: {
				...detail,
				unsavedItems: [
					...otherUnsavedMargins,
					{
						value: margin,
						article: articleId
					}
				]
			}

		}
	};
}

function fetchDetailMargins (
	state = {},
	action
) {
	const key = `${action.category}${action.marginType ?? '-'}${action.productType}`;
	switch (action.type) {
		case MARGINS_DETAIL_REQUEST:
			return {
				[key]: {
					...(state?.[key] ?? {}),
					isFetching: true
				}
			};
		case MARGINS_DETAIL_REQUEST_RECEIVE:
			return {
				[key]: {
					...(state?.[key] ?? {}),
					isFetching: false,
					lastUpdated: Date.now(),
					articles: action.data.articles,
					category: action.data.category,
					didFail: false,
					fresh: true
				}
			};
		case MARGINS_DETAIL_REQUEST_REJECT:
			return {
				[key]: {
					...(state?.[key] ?? {}),
					isFetching: false,
					didFail: true
				}
			};
		default:
			return state;
	}
}
