import React, { useState } from 'react';
import './OrderHistory.scss';
import {
	List,
	DateRangeFilter,
	TextFilter,
	Spinner
} from '@abm-international/react-components';
import {
	FaChevronDown,
	FaChevronUp,
	FaFilePdf,
	FaForward,
	FaShoppingBasket,
	FaTruck
} from 'react-icons/fa';
import { t } from '../../libraries/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectDeliveryDate,
	selectIsOpenOrdersFetching,
	selectReviewOrderProducts,
	selectOrderQuantities,
	selectPromos,
	selectCustomerId
} from './../selectors';
import ProductsSummary from './../../components/productsViews/productsSummary/productsSummary';
import DateHelper from './../../libraries/dateHelper';
import { Button } from '@abm-international/react-components';
import { continueWithOrderFromServer } from './../order/orderActions';
import { useHistory } from 'react-router-dom';
import { OrderHistoryItem } from '../../types/orders/orderHistory';
import useOrderHistory from './../../api/orders/useOrderHistory';
import PdfModal from '../../components/PdfModal/PdfModal';

const isDateFilterActive = (state: Array<Date>) => state && !state.every(d => d === null || d === undefined);

const getDateTimeTranslations = () => ({
	days: [
		t('datetime.dayOfWeekShort1'),
		t('datetime.dayOfWeekShort2'),
		t('datetime.dayOfWeekShort3'),
		t('datetime.dayOfWeekShort4'),
		t('datetime.dayOfWeekShort5'),
		t('datetime.dayOfWeekShort6'),
		t('datetime.dayOfWeekShort7'),
	],
	months: [
		t('datetime.month1'),
		t('datetime.month2'),
		t('datetime.month3'),
		t('datetime.month4'),
		t('datetime.month5'),
		t('datetime.month6'),
		t('datetime.month7'),
		t('datetime.month8'),
		t('datetime.month9'),
		t('datetime.month10'),
		t('datetime.month11'),
		t('datetime.month12'),
	]
});

export default function OrderHistory() {
	const dispatch = useDispatch();
	const history = useHistory();
	const isOpenOrdersFetching = useSelector(selectIsOpenOrdersFetching);
	const orderProducts = useSelector(selectReviewOrderProducts);
	const promos = useSelector(selectPromos);
	const deliveryDate = useSelector(selectDeliveryDate);
	const quantities = useSelector(selectOrderQuantities);
	const customerId = useSelector(selectCustomerId);

	const [showOpenOrder, setShowOpenOrder] = useState(false);
	const [pdfModal, setPdfModal] = useState<{ open: boolean, url?: string, downloadUrl?: string }>({ open: false });

	const { orderHistory, error } = useOrderHistory();

	const openPdfModal = (id?: string) => {
		if (!id) return null;
		const url = `${process.env.REACT_APP_PORTAL_URL}/api/customer.order.history/${customerId}/orders/${id}/pdf`;

		setPdfModal({
			open: true,
			url: url,
			downloadUrl: `${url}?download`
		});
	};

	const renderOpenOrder = () => {
		if (isOpenOrdersFetching) return null;
		if (Object.keys(quantities).length <= 0) return null;

		const continueWithOpenOrder = () => {
			dispatch(continueWithOrderFromServer());
			history.push('/order/products');
		};

		return (
			<div className='open-order'>
				<div className='open-order__description'>
					{t('orders.label_open_order', DateHelper.getDateString(deliveryDate))}
				</div>

				{showOpenOrder && (
					<div className='summary'>
						<ProductsSummary
							products={orderProducts}
							promos={promos}
							deliveryDate={deliveryDate}
							quantities={quantities}
						/>
					</div>
				)}

				<div className='actions'>
					<Button
						onClick={() => setShowOpenOrder(!showOpenOrder)}
						icon={showOpenOrder ? <FaChevronUp /> : <FaChevronDown />}
					>
						{showOpenOrder ? t('orders.label_open_order_details_hide') : t('orders.label_open_order_details_show')}
					</Button>
					<Button
						className='green'
						onClick={continueWithOpenOrder}
						icon={<FaForward />}
					>
						{t('orders.label_open_order_continue')}
					</Button>
				</div>
			</div>
		);
	};

	const renderCreateOrder = () => (
		<div className='new-order'>
			<Button
				className='green'
				onClick={() => history.push('/order/delivery-date')}
				icon={<FaShoppingBasket />}
				primary
			>
				{t('orders.label_new_order')}
			</Button>
		</div>
	);

	const renderDeliveryDate = (date?: Date) => {
		const dateString = DateHelper.getDateString(date);
		if (dateString === '01-01-2500') return t('orders.label_asap');
		return dateString;
	};

	const renderHistory = () => (
		<List
			header={[
				{
					title: t('orders.label_delivery_date'),
					key: 'deliveryDate',
					render: (item: OrderHistoryItem) => (
						<span>
							<span className='date'>{renderDeliveryDate(item.deliveryDate)}</span>
							{DateHelper.isToday(item.deliveryDate) && <FaTruck />}
						</span>
					),
					sort: (a: OrderHistoryItem, b: OrderHistoryItem) => (a.deliveryDate?.getTime() ?? 0) - (b.deliveryDate?.getTime() ?? 0),
					filter: {
						test: (item: OrderHistoryItem, value: Array<Date>) => isDateFilterActive(value) && DateHelper.isInRange(item?.deliveryDate, value?.[0], value?.[1]),
						render: (value: any, setValue: any) =>
							<DateRangeFilter
								value={value}
								setValue={setValue}
								translations={getDateTimeTranslations()}
							/>,
						isFilterActive: isDateFilterActive
					}
				},
				{
					title: t('orders.label_submitted_at'),
					key: 'submittedAt',
					render: (item: OrderHistoryItem) => {
						if (!item.submittedAt) return null;
						const dayOfMonth = `00${item.submittedAt.getDate()}`.slice(-2);
						const month = `00${item.submittedAt.getMonth() + 1}`.slice(-2);
						const year = item.submittedAt.getFullYear();

						const hour = `00${item.submittedAt.getHours()}`.slice(-2);
						const minutes = `00${item.submittedAt.getMinutes()}`.slice(-2);
						return `${dayOfMonth}-${month}-${year} ${hour}:${minutes}`;
					},
					sort: (a: OrderHistoryItem, b: OrderHistoryItem) => (a.submittedAt?.getTime() ?? 0) - (b.submittedAt?.getTime() ?? 0),
					filter: {
						test: (item: OrderHistoryItem, value: Array<Date>) => isDateFilterActive(value) && DateHelper.isInRange(item?.submittedAt, value?.[0], value?.[1]),
						render: (value: any, setValue: any) =>
							<DateRangeFilter
								value={value}
								setValue={setValue}
								translations={getDateTimeTranslations()}
							/>,
						isFilterActive: isDateFilterActive
					}
				},
				{
					title: t('orders.label_customer_ref'),
					key: 'customerRef',
					render: (item: OrderHistoryItem) => item.customerRef,
					sort: (a: OrderHistoryItem, b: OrderHistoryItem) => a?.customerRef?.localeCompare(b?.customerRef ?? ''),
					filter: {
						test: (item: OrderHistoryItem, value: string) => item.customerRef?.toString().startsWith(value),
						component: TextFilter
					}
				},
				{
					title: t('orders.label_reference'),
					key: 'reference',
					render: (item: OrderHistoryItem) => item.reference,
					sort: (a: OrderHistoryItem, b: OrderHistoryItem) => parseInt(a?.reference ?? '0') - parseInt(b?.reference ?? '0'),
					filter: {
						test: (item: OrderHistoryItem, value: string) => item.reference?.toString().startsWith(value),
						component: TextFilter
					}
				},
				{
					title: t('orders.label_origin'),
					key: 'origin',
					render: (item: OrderHistoryItem) => t(`orders.label_origin_${item.origin}`),
					sort: (a: OrderHistoryItem, b: OrderHistoryItem) => a?.origin?.localeCompare(b?.origin ?? '')
				},
				{
					title: t('orders.label_pdf'),
					key: 'pdf',
					render: (item: OrderHistoryItem) => (
						<FaFilePdf onClick={() => openPdfModal(item.id)} />
					)
				},
				{
					title: '',
					key: 'status',
					render: (item: OrderHistoryItem) => t(`orders.label_status_${item.status?.code}`, item.status?.value?.data?.eta),
					//sort: (a: OrderHistoryItem, b: OrderHistoryItem) => a?.status?.localeCompare(b?.status ?? '')
				}
			]}
			content={orderHistory}
			gridTemplateColumns={'22rem 18rem 19rem 20rem 17rem 5rem 1fr'}
		/>
	);

	const renderError = () => (
		<div className={'error-message'}>
			{/* <span role='img' aria-label='error emoji'>💥</span> */}
			{t('orders.label_error_loading_overview')}
		</div>
	);

	const renderPdfModal = () => (
		<PdfModal
			file={pdfModal.url}
			downloadUrl={pdfModal.downloadUrl}
			close={() => setPdfModal({ open: false })}
		/>
	);

	return (
		<div className='OrderHistory'>
			{renderOpenOrder()}
			{renderCreateOrder()}
			{orderHistory && renderHistory()}
			{!orderHistory && error && renderError()}
			{!orderHistory && !error && <Spinner />}
			{pdfModal.open && renderPdfModal()}
		</div>
	);
}
