import React from 'react';
import './datePicker.scss';
import { setDeliveryDate } from '../../modules/order/orderActions';
import { t } from '../../libraries/i18n';
import dateHelper from '../../libraries/dateHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeliveryDates, selectIsDeliveryDatesFetching, selectIsOpenOrdersFetching } from '../../modules/selectors';

const DeliveryDatePicker = (props) => {
	const dispatch = useDispatch();
	const dates = useSelector(selectDeliveryDates);
	const isFetching = useSelector(selectIsDeliveryDatesFetching);
	const isOpenOrdersFetching = useSelector(selectIsOpenOrdersFetching);
	const isLoading = isFetching || isOpenOrdersFetching;

	const getDaysOfMonth = (year, month) => {

		const date = new Date(year, month, 1);
		const dates = [];

		while (date.getMonth() === month) {
			dates.push(new Date(date));
			date.setDate(date.getDate() + 1);
		}

		return dates;
	};

	const getDaysForMonthView = (year, month) => {
		const firstDayOfMonth = new Date(year, month, 1);
		const currMonth = getDaysOfMonth(year, month);

		const lastDayOfPrevMonth = new Date(firstDayOfMonth.setDate(0));

		let prevMonth = getDaysOfMonth(lastDayOfPrevMonth.getFullYear(), lastDayOfPrevMonth.getMonth());
		prevMonth = prevMonth.slice(prevMonth.length - firstDayOfMonth.getDay(), prevMonth.length);

		return prevMonth.concat(currMonth);
	};

	const isToday = (date) => {
		return date.toDateString() === new Date(Date.now()).toDateString();
	};

	const handleDateClick = (date) => {
		if (!props.disabled) dispatch(setDeliveryDate(date));
	};

	const renderWeekdays = () => {
		const daysOfWeek = [
			t('datetime.dayOfWeekShort1'),
			t('datetime.dayOfWeekShort2'),
			t('datetime.dayOfWeekShort3'),
			t('datetime.dayOfWeekShort4'),
			t('datetime.dayOfWeekShort5'),
			t('datetime.dayOfWeekShort6'),
			t('datetime.dayOfWeekShort7')
		];

		return (
			daysOfWeek.map(day => (
				<span key={day}>{day}</span>
			))
		);
	};

	const renderDate = (date, month) => {
		const isCurrentMonth = date.getMonth() === month - 1;
		const deliveryDate = dates.find(deliveryDate => deliveryDate.date === dateHelper.getDateString(date));

		let classNames = 'calendar__day';

		if (!isCurrentMonth) {
			classNames += ' calendar__day--hidden';
			return (<span key={dateHelper.toReduxDateString(date)} className={classNames}>&nbsp;</span>);
		} else {
			if (isToday(date)) classNames += ' calendar__day--today';
			if (!deliveryDate) classNames += ' calendar__day--disabled';
			if (deliveryDate?.isDefault) classNames += ' calendar__day--default-delivery';
			if (dateHelper.getDateString(date) === dateHelper.getDateString(props.selectedDate)) classNames += ' calendar__day--selected';
		}

		if (deliveryDate) {
			return (
				<span key={dateHelper.toReduxDateString(date)} className={classNames} onClick={() => handleDateClick(date)}>{date.getDate()}</span>
			);
		} else {
			return (
				<span key={dateHelper.toReduxDateString(date)} className={classNames}>{date.getDate()}</span>
			);
		}
	};

	const renderMonthView = (monthString, key) => {
		const year = parseInt(monthString.substr(0, 4));
		const month = parseInt(monthString.substr(5, 2));

		const daysOfMonth = getDaysForMonthView(year, month - 1);
		const monthNames = [
			t('datetime.month1'),
			t('datetime.month2'),
			t('datetime.month3'),
			t('datetime.month4'),
			t('datetime.month5'),
			t('datetime.month6'),
			t('datetime.month7'),
			t('datetime.month8'),
			t('datetime.month9'),
			t('datetime.month10'),
			t('datetime.month11'),
			t('datetime.month12')
		];

		return (
			<div key={key} className='calendar__month'>
				<div className='month__name'>{monthNames[month - 1]}</div>

				<div className='calendar__weekdays'>
					{renderWeekdays()}
				</div>

				<div className='calendar__days'>
					{daysOfMonth.map(date => renderDate(date, month))}
				</div>
			</div>
		);
	};

	const renderCalendar = () => {
		const months = [];

		dates.forEach(({ date }) => {
			const month = dateHelper.getMonthOfYearFromDateString(date);
			if (months.indexOf(month) === -1) months.push(month);
		});

		return (
			<div className='calendar'>
				{months.sort().map((month, key) => (
					renderMonthView(month, key)
				))}
			</div>
		);
	};

	const renderLoadingCalendar = () => {
		return (
			<div className={'loading__calendar'}>
				{[...Array(2)].map((i, key) => (
					<div key={key} className={'loading__month'}>
						<div className={'loading__month-name breathing'} />
						<div className={'loading__weekdays'}>
							{[...Array(7)].map((i, key) => (
								<span key={key} className={'breathing'} />
							))}
						</div>
						<div className={'loading__days'}>
							{[...Array(3)].map((i, key) => (
								<span key={key} className={'loading__day--invisible'}>

								</span>
							))}
							{[...Array(31)].map((i, key) => (
								<div key={key} className={'loading__day breathing'}>

								</div>
							))}
							<span className={'loading__day--invisible'}>

							</span>
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<div className={`OGDatePicker ${props.disabled ? 'disabled' : ''}`}>
			{isLoading ? (
				renderLoadingCalendar()
			) : (
				renderCalendar()
			)}
		</div>
	);
};

export default DeliveryDatePicker;
