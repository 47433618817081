import { t } from '../../../libraries/i18n';

export const getFields = () => ([
	{
		name: 'customerIdInvoices',
		title: `${t('settings.customer_id')}`,
		type: 'text',
		disabled: true
	},
	{
		name: 'vatNumberInvoices',
		title: `${t('settings.vat_number')}`,
		type: 'text',
		disabled: true
	},
	{
		name: 'nameInvoices',
		title: `${t('settings.name')}*`,
		type: 'text',
	},
	{
		name: 'addressInvoices',
		title: `${t('settings.adress')}*`,
		type: 'text',
	},
	{
		name: 'postalCodeInvoices',
		title: `${t('settings.postal_code')}*`,
		type: 'text',
	},
	{
		name: 'cityInvoices',
		title: `${t('settings.city')}*`,
		type: 'text',
	},
	{
		name: 'nameDelivery',
		title: `${t('settings.name')}*`,
		type: 'text',
	},
	{
		name: 'addressDelivery',
		title: `${t('settings.adress')}*`,
		type: 'text',
	},
	{
		name: 'postalCodeDelivery',
		title: `${t('settings.postal_code')}*`,
		type: 'text',
	},
	{
		name: 'cityDelivery',
		title: `${t('settings.city')}*`,
		type: 'text',
	},
	{
		name: 'namePortal',
		title: t('settings.name'),
		type: 'text',
	},
	{
		name: 'phone1Portal',
		title: t('settings.phone1'),
		type: 'text',
	},
	{
		name: 'phone2Portal',
		title: t('settings.phone2'),
		type: 'text',
	},
	{
		name: 'emailPortal',
		title: `${t('settings.email')}*`,
		type: 'email',
	},
	{
		name: 'accounting_sameAsPortalContact',
		title: t('settings.same_as_portal_contact'),
		type: 'checkbox'
	},
	{
		name: 'accounting_name',
		title: t('settings.name'),
		type: 'text',
	},
	{
		name: 'accounting_phone1',
		title: t('settings.phone1'),
		type: 'text',
	},
	{
		name: 'accounting_phone2',
		title: t('settings.phone2'),
		type: 'text',
	},
	{
		name: 'accounting_fax',
		title: t('settings.fax'),
		type: 'text',
	},
	{
		name: 'accounting_email',
		title: t('settings.email'),
		type: 'email',
	},
	{
		name: 'sales_sameAsPortalContact',
		title: t('settings.same_as_portal_contact'),
		type: 'checkbox'
	},
	{
		name: 'sales_name',
		title: t('settings.name'),
		type: 'text',
	},
	{
		name: 'sales_phone1',
		title: t('settings.phone1'),
		type: 'text',
	},
	{
		name: 'sales_phone2',
		title: t('settings.phone2'),
		type: 'text',
	},
	{
		name: 'sales_fax',
		title: t('settings.fax'),
		type: 'text',
	},
	{
		name: 'sales_email',
		title: t('settings.email'),
		type: 'email',
	},
	{
		name: 'marketing_sameAsPortalContact',
		title: t('settings.same_as_portal_contact'),
		type: 'checkbox'
	},
	{
		name: 'marketing_name',
		title: t('settings.name'),
		type: 'text',
	},
	{
		name: 'marketing_phone1',
		title: t('settings.phone1'),
		type: 'text',
	},
	{
		name: 'marketing_phone2',
		title: t('settings.phone2'),
		type: 'text',
	},
	{
		name: 'marketing_fax',
		title: t('settings.fax'),
		type: 'text',
	},
	{
		name: 'marketing_email',
		title: t('settings.email'),
		type: 'email',
	},
]);

