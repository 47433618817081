import * as fromInvoices from './Invoices/invoicesSelectors';
import * as fromTransportDocuments from './TransportDocuments/transportDocumentsSelectors';

// fromInvoices
export const selectInvoicesData = state => fromInvoices.selectData(state.invoices);
export const selectIsInvoicesFetching = state => fromInvoices.selectIsFetching(state.invoices);
export const selectInvoices = state => fromInvoices.selectItems(state.invoices);

// fromTransportDocuments
export const selectTransportDocuments = state => fromTransportDocuments.selectItems(state.transportDocuments);
export const selectIsFetching = state => fromTransportDocuments.selectIsFetching(state.transportDocuments);
export const selectDidTransportDocumentsFail = state => fromTransportDocuments.selectDidFail(state.transportDocuments);
export const selectTransportdocumentsQuery = state => fromTransportDocuments.selectQuery(state.transportDocuments);
