import Api from './../../libraries/api';
import {
	selectIsPriceChangesFetching
} from './../selectors';

export const PRICE_CHANGES_REQUEST = 'PRICE_CHANGES_REQUEST';
export const PRICE_CHANGES_RECEIVE = 'PRICE_CHANGES_RECEIVE';
export const PRICE_CHANGES_REJECT = 'PRICE_CHANGES_REJECT';
export const PRICE_CHANGES_HANDLE_SEARCH = 'PRICE_CHANGES_HANDLE_SEARCH';

export function handleSearch(query) {
	return {
		type: PRICE_CHANGES_HANDLE_SEARCH,
		query
	};
}

function fetchPriceChanges(customerId) {
	return async dispatch => {
		dispatch({
			type: PRICE_CHANGES_REQUEST
		});

		let data;

		try {
			data = await Api.getPricechanges(customerId);
		} catch (error) {
			return dispatch({
				type: PRICE_CHANGES_REJECT,
				error
			});
		}

		return dispatch({
			type: PRICE_CHANGES_RECEIVE,
			data
		});
	};
}

const shouldFetchPriceChanges = state => {
	const isFetching = selectIsPriceChangesFetching(state);

	if (isFetching) return false;
	return true;
};

export const fetchPriceChangesIfNeeded = (customerId) => {
	return (dispatch, getState) => {
		if (shouldFetchPriceChanges(getState())) {
			dispatch(fetchPriceChanges(customerId));
		}
	};
};
