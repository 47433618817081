import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './TransportDocuments.scss';
import {
	selectTransportDocuments,
	selectIsTransportDocumentsFetching,
	selectDidTransportDocumentsFail,
	selectCustomerId,
	selectTransportdocumentsQuery,
	selectCanViewTransportDocuments
} from './../../selectors';
import {
	fetchTransportDocumentsIfNeeded,
	handleSearch
} from './transportDocumentsActions';
import Search from '../../../components/Search/Search';
import { t } from './../../../libraries/i18n';
import * as DateHelper from './../../../libraries/dateHelper';
import Highlight from '../../../components/highlight/highlight';
import { FaDownload } from 'react-icons/fa';
import * as Api from './../../../libraries/api';
import Spinner from '../../../components/spinner/spinner';
import Filter from './../../../libraries/filter';
import PdfModal from '../../../components/PdfModal/PdfModal';
import { useRestrictedContent } from './../../../libraries/hooks';
import ForbiddenError from '../../../components/Error/ForbiddenError/ForbiddenError';

function TransportDocuments(props) {
	const dispatch = useDispatch();
	const [pdfModal, setPdfModal] = useState({ open: false });
	const {
		customerId,
		documents,
		query,
		isFetching,
		didFail
	} = props;
	const [RestrictedContent, RestrictedContentError] = useRestrictedContent(props.requiredPermission);

	useEffect(() => {
		dispatch(fetchTransportDocumentsIfNeeded(customerId));
	}, [dispatch, customerId]);

	const openPdfModal = document => {
		setPdfModal({
			open: true,
			url: Api.getTransportDocumentPdfUrl(props.customerId, document.number, document.date),
			downloadUrl: Api.getTransportDocumentPdfUrl(props.customerId, document.number, document.date, true)
		});
	};

	const renderPdfModal = () => (
		<PdfModal
			file={pdfModal.url}
			downloadUrl={pdfModal.downloadUrl}
			close={() => setPdfModal({ open: false })}
		/>
	);

	const renderSearch = () => (
		<Search
			handleSearch={query => dispatch(handleSearch(query))}
			query={query}
			placeholder={t('transportDocuments.searchPlaceholder')}
		/>
	);

	const renderDocument = document => (
		<div key={`${document.number}-${document.date}`} className={'document'} onClick={() => openPdfModal(document)}>
			<div className={'item__date'}>
				<Highlight text={document.date} string={query}>{document.date}</Highlight>
			</div>
			<div className={'item__number'}>
				<Highlight text={document.number} string={query}>{document.number}</Highlight>
			</div>
			<div className={'item__order-number'}>
				<Highlight text={document.orderNumber} string={query} >{document.orderNumber}</Highlight>
			</div>
			<div className={'item__download'}>
				<a href={Api.getTransportDocumentPdfUrl(props.customerId, document.number, document.date, true)} onClick={e => e.stopPropagation()}>
					<FaDownload />
				</a>
			</div>
		</div>
	);

	const getDocumentGroup = (document, locale) => {
		const date = new Date(document.date);
		const month = DateHelper.getMonthName(date.getMonth(), locale);
		const year = date.getFullYear();
		return `${month} ${year}`;
	};

	const getGroupedDocuments = (documents) => {
		const groupedDocuments = {};
		documents.forEach(document => {
			if (!groupedDocuments[document.group]) groupedDocuments[document.group] = [];
			groupedDocuments[document.group].push(document);
		});
		return groupedDocuments;
	};

	const renderDocumentsList = documents => (
		<div className={'documents__list'}>
			{documents.map(renderDocument)}
		</div>
	);

	const renderDocumentGroup = documents => (
		<div key={documents[0].group} className={'documents__group'}>
			<div className={'group__header'}>
				<Highlight text={documents[0].group} string={query}>{documents[0].group} </Highlight>
				<span className={'group__num'}>
					({documents?.length})
				</span>
			</div>

			{renderDocumentsList(documents)}
		</div>
	);

	const renderDocuments = (documents, locale) => {
		documents = documents.map(document => ({
			...document,
			group: getDocumentGroup(document, locale)
		}));

		documents = Filter.filterItemsByQuery(
			query,
			documents,
			[
				{ name: 'date' },
				{ name: 'number' },
				{ name: 'orderNumber' },
				{ name: 'group' }
			],
			true,
			'AND'
		);

		documents = documents.sort((a, b) => {
			const dateDiff = new Date(b.date) - new Date(a.date);
			if (dateDiff > 0) return 1;
			if (dateDiff < 0) return -1;
			return b.number - a.number;
		});

		const groupedDocuments = getGroupedDocuments(documents);

		return (
			<div className={'documents'}>
				<div className={'documents__details'}>
					{t('transportDocuments.numberOfDocumentsFound', documents.length)}
				</div>

				<div className={'documents__header'}>
					<div className={'header__id'}>
						{t('transportDocuments.dateLabel')}
					</div>
					<div className={'header__description'}>
						{t('transportDocuments.documentNumberLabel')}
					</div>
					<div className={'header__order-number'}>
						{t('transportDocuments.ourOrderReferenceLabel')}
					</div>
				</div>

				<div className={'documents__list'}>
					{Object.keys(groupedDocuments).map(group => renderDocumentGroup(groupedDocuments[group]))}
				</div>
			</div>
		);
	};

	return (
		<div className={'TransportDocuments'}>
			<RestrictedContentError>
				<ForbiddenError permissionName={t('transportDocuments.viewTransportDocumentsPermission')} />
			</RestrictedContentError>

			<RestrictedContent>
				{renderSearch()}

				{isFetching && (
					<Spinner />
				)}

				{didFail && (
					<p>{t('transportDocuments.genericError')}</p>
				)}

				{!documents && !isFetching && !didFail && (
					<p>{t('transportDocuments.noInvoicesFound')}</p>
				)}

				{documents && !isFetching && !didFail && (
					renderDocuments(documents)
				)}

				{pdfModal.open && renderPdfModal()}
			</RestrictedContent>
		</div>
	);
}

const mapStateToProps = state => ({
	documents: selectTransportDocuments(state),
	isFetching: selectIsTransportDocumentsFetching(state),
	didFail: selectDidTransportDocumentsFail(state),
	query: selectTransportdocumentsQuery(state),
	customerId: selectCustomerId(state),
	hasPermission: selectCanViewTransportDocuments(state)
});

export default connect(mapStateToProps)(TransportDocuments);
