import {
	ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION,
	ORDER_PRODUCT_SELECTION_HANDLE_SEARCH,
	ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE,
	ORDER_PRODUCT_SELECTION_CLEAR_FILTERS,
	ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED,
	ORDER_PRODUCT_SELECTION_TOGGLE_FILTER,
	ORDER_PRODUCT_SELECTION_SET_FILTER,
	ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT,
	ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER,
	ORDER_PRODUCT_SELECTION_CHANGE_SORTING,
	ORDER_RESET
} from './orderActions';
import initialState from './../initialState';
import { PRODUCTS_CAMPAIGNS_RECEIVE, PRODUCTS_RECEIVE } from './../products/productsActions';
import UserPreferences from './../../libraries/userPreferences';

export default function productSelection(
	state = initialState.order.productSelection,
	action
) {
	switch (action.type) {
		case ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION:
			return {
				...state,
				currentPage: action.page
			};
		case ORDER_PRODUCT_SELECTION_HANDLE_SEARCH:
			return {
				...state,
				currentPage: 1,
				query: action.query
			};
		case ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE:
			UserPreferences.set(UserPreferences.PREFERENCES.PRODUCTS_VIEW, action.view);
			return {
				...state,
				view: action.view,
				currentPage: 1
			};
		case ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED:
			return {
				...state,
				currentPage: state.currentPage + 1
			};
		case ORDER_PRODUCT_SELECTION_CLEAR_FILTERS:
		case ORDER_PRODUCT_SELECTION_TOGGLE_FILTER:
		case ORDER_PRODUCT_SELECTION_SET_FILTER:
		case ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER:
			return {
				...state,
				filters: filters(state.filters, action, state.defaultFilters)
			};
		case ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT:
			return {
				...state,
				focused: state.focused !== action.id ? action.id : null
			};
		case ORDER_PRODUCT_SELECTION_CHANGE_SORTING:
			return {
				...state,
				sortKey: action.payload.sortKey,
				sortDirection: action.payload.direction,
				currentPage: 1
			};
		case PRODUCTS_CAMPAIGNS_RECEIVE:
			return generateCampaignsFilters(state, action);
		case PRODUCTS_RECEIVE:
			return generateBrandsFilters(state, action);
		case ORDER_RESET:
			return {
				...state,
				query: '',
				filters: {
					main: {},
					campaigns: {},
					allergens: {},
					shopcodes: {},
					buylist: {}
				}
			};
		default:
			return state;
	}
}

function filters(state, action, defaultFilters) {
	switch (action.type) {
		case ORDER_PRODUCT_SELECTION_CLEAR_FILTERS:
			return clearFilters(state, action, defaultFilters);
		case ORDER_PRODUCT_SELECTION_TOGGLE_FILTER:
			return changeFilterState(state, defaultFilters, action.filter);
		case ORDER_PRODUCT_SELECTION_SET_FILTER:
			return changeFilterState(state, defaultFilters, action.filter, action.state);
		case ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER:
			return {
				...state,
				shopcodes: {
					...state.shopcode,
					code: action.payload
				}
			};
		default:
			return state;
	}
}

function clearFilters(state, action, defaultFilters) {
	if (action.group) {
		return {
			...state,
			[action.group]: {
				...defaultFilters[action.group]
			}
		};
	} else {
		return {
			...initialState.order.productSelection.filters
		};
	}
}

function nextIncludeExcludeState(state) {
	switch (state) {
		case 'off':
			return 'include';
		case 'include':
			return 'exclude';
		case 'exlude':
		default:
			return 'off';
	}
}

function changeFilterState(state, defaultFilters, filter, newFilterState) {
	// Get filter group
	filter = filter.split('.');
	const filterGroup = filter[0];
	filter.shift();
	const filterName = filter.join('.');

	// get current filter state
	const currentFilterState = state[filterGroup]?.[filterName] ?? defaultFilters[filterGroup]?.[filterName];

	// get next filter state
	let nextFilterState = newFilterState ?? !currentFilterState;
	if ((filterGroup === 'main' || filterGroup === 'campaigns') && filterName !== 'buylist') {
		nextFilterState = newFilterState || nextIncludeExcludeState(currentFilterState);
	}

	// set state
	return {
		...state,
		[filterGroup]: {
			...state[filterGroup],
			[filterName]: nextFilterState
		}
	};
}

function generateCampaignsFilters(state, action) {
	const campaignsWithFilters = action.campaigns.filter(campaign => campaign.articles?.filter !== undefined && campaign.articles?.filter !== null);
	const campaignsFilters = {};
	campaignsWithFilters.forEach(campaign => campaignsFilters[campaign.id] = 'off');
	return {
		...state,
		defaultFilters: {
			...state.defaultFilters,
			campaigns: campaignsFilters
		}
	};
}

function generateBrandsFilters(state, action) {

	const brandsFilters = Object.values(action?.products).reduce((acc, obj) => {
		const { brand } = obj;
		acc[brand] = false;
		return acc;
	}, {});

	return {
		...state,
		defaultFilters: {
			...state.defaultFilters,
			brands: brandsFilters
		},
	};
}
