import React from 'react';
import './disclaimer.scss';
import { selectLocale } from '../selectors';
import { useSelector } from 'react-redux';

const Disclaimer = () => {
	const locale = useSelector(selectLocale);
	const renderDutch = () => {
		return (
			<div className={'disclaimer__text'}>
				<h2>ALGEMENE VOORWAARDEN - DISCLAIMER EN PRIVACY POLICY KLANTENPORTAAL</h2>
				<p>Het gebruik van het klantenportaal is onderworpen aan de hierna opgenomen algemene voorwaarden. Door gebruik te maken van het klantenportaal wordt u geacht van deze algemene voorwaarden kennis te hebben genomen en de algemene voorwaarden zonder enig voorbehoud te aanvaarden.</p>
				<p>Op bestellingen dewelke worden geplaatst via het klantenportaal zijn de algemene verkoop voorwaarden van <a href={`${process.env.REACT_APP_PORTAL_URL}/resources/pdf/disclaimers/ALGEMENE_VERKOOP-_EN_LEVERINGSVOORWAARDEN_COCKS_VLEESWAREN_NV.pdf`}>Cock’s Vleeswaren NV</a> en <a href={`${process.env.REACT_APP_PORTAL_URL}/resources/pdf/disclaimers/ALGEMENE_VERKOOPS-_EN_LEVERINGSVOORWAARDEN_CHARLES_DISTRIBUTIE_NV.pdf`}>Charles Distributie NV</a> van toepassing. Door een bestelling te plaatsen via het klantenportaal wordt u geacht van deze algemene verkoop voorwaarden kennis te hebben genomen en de algemene verkoop voorwaarden zonder enig voorbehoud te aanvaarden.</p>
				<p>Het klantenportaal wordt beheerd door de besloten vennootschap “<span className={'bold'}>CHARLES BRANDING</span>”, met zetel te 9100 Sint-Niklaas, Industriepark-Noord 14, BTW BE 0664.642.515, RPR Gent, afdeling Dendermonde.</p>

				<h3>Doel van het klantenportaal</h3>
				<p>
					Het klantenportaal heeft onder meer de hiernavolgende functies:
					<ul>
						<li>
							Het ter beschikking stellen van:
							<ul>
								<li>technische gegevens van het Charles artikelassortiment</li>
								<li>foto’s van artikelen </li>
								<li>assortimentslijsten</li>
								<li>informatie over promoties</li>
								<li>rapporteringen</li>
							</ul>
						</li>
						<li>Online bestelmodule</li>
						<li>Het doorsturen van publiciteit en marketing acties</li>
						<li>Het ter beschikking stellen van de Algemene Verkoopvoorwaarden</li>
						<li>Contactgegevens en contacteer mogelijkheden</li>
						<li>…</li>
					</ul>
				</p>

				<h3>Gebruik van het klantenportaal</h3>
				<p>CHARLES BRANDING besteedt veel aandacht en zorg aan het klantenportaal en streeft ernaar dat alle informatie zo volledig, juist, begrijpelijk, nauwkeurig en actueel mogelijk is. Ondanks alle voortdurend geleverde inspanningen kan CHARLES BRANDING niet garanderen dat de ter beschikking gestelde informatie volledig, juist, nauwkeurig of bijgewerkt is. Als de op (of via) het klantenportaal verstrekte informatie tekortkomingen vertoont, zal CHARLES BRANDING de vereiste inspanningen leveren om dat zo snel mogelijk te corrigeren.</p>
				<p>Met betrekking tot de technische gegevens van de producten wordt benadrukt dat deze onder de uitsluitende verantwoordelijkheid vallen van de betrokken leverancier. De leveranciers stellen immers rechtstreeks product specificaties beschikbaar waarin technische gegevens van de producten inbegrepen zijn.</p>
				<p>De technische gegevens zoals zij door de leveranciers ter beschikking worden gesteld in het leveranciersportaal worden door CHARLES BRANDING niet gevalideerd, niet gewijzigd, niet geredigeerd en niet gecontroleerd naar conformiteit t.o.v. geldende wetgeving en/of reglementering.</p>
				<p>
					CHARLES BRANDING beperkt zich tot het registreren/inventariseren van de technische gegevens en/of het melden aan de leveranciers van het ontbreken van de technische gegevens.<br />
					De technische gegevens worden in real time – één op één – ter beschikking gesteld van de gebruikers van het klantenportaal, hetzij door middel van een document, hetzij door middel van gegevensvelden. De leverancier is ten allen tijden verantwoordelijk voor het tijdig up-to-date houden van de technische gegevens.
				</p>
				<p>De prijzen van producten die getoond worden op het klantenportaal zijn exclusief BTW, zijn exclusief promoties en/of kortingen van welke aard ook, zijn indicatief en zijn geenszins bindend voor Charles Distributie NV / Cock’s Vleeswaren NV. De door de klant te betalen prijzen komen tot stand op het ogenblik van de facturatie. Artikel 5 (Prijzen en Kosten) van de Algemene Verkoops- en Leveringsvoorwaarden Charles Distributie NV / Cock’s Vleeswaren NV is van toepassing.</p>
				<p>De inhoud van het klantenportaal (hyperlinks inbegrepen) kan steeds zonder aankondiging of kennisgeving aangepast, gewijzigd, aangevuld of verwijderd worden.</p>
				<p>Als u onjuistheden vaststelt, kunt u contact opnemen. U kan hiervoor gebruik maken van het contactformulier.</p>
				<p>CHARLES BRANDING spant zich in om onderbrekingen van technische aard zo veel mogelijk te voorkomen. CHARLES BRANDING kan echter niet garanderen dat zijn website volledig vrij van onderbreking is niet door andere technische problemen wordt getroffen.</p>

				<h3>Afwijzing van aansprakelijkheid</h3>
				<p>CHARLES BRANDING wijst elke aansprakelijkheid af voor de keuzes en de ermee samenhangende gevolgen die de gebruiker van het klantenportaal maakt op basis van gegevens die deze terugvindt op het klantenportaal.</p>
				<p>CHARLES BRANDING kan niet aansprakelijk gesteld worden voor rechtstreekse of onrechtstreekse schade die ontstaat uit het gebruik van het klantenportaal of van de op of via het portaal ter beschikking gestelde informatie, met inbegrip, zonder beperking, van alle verliezen, werkonderbrekingen, beschadiging van uw programma's of andere gegevens op het computersysteem, of van uw apparatuur of programma's.</p>
				<p>CHARLES BRANDING kan niet aansprakelijk gesteld worden voor rechtstreekse of onrechtstreekse schade die eventueel zou ontstaan uit het niet beschikbaar zijn van het klantenportaal op enig moment.</p>
				<p>CHARLES BRANDING kan evenmin aansprakelijk worden gesteld voor eventuele technische vergissingen, fouten of onvolledigheden, dewelke een invloed kunnen hebben op eventuele acties van Cocks Vleeswaren NV en Charles Distributie NV. Zij verbindt er zich evenwel toe de technische fouten zo snel mogelijk te corrigeren, van zodra zij hiervan in kennis werd gesteld.</p>

				<h3>Algemeen beleid op het vlak van gegevensverwerking en -bescherming</h3>
				<p>CHARLES BRANDING respecteert uw rechten bij de verwerking van uw persoonsgegevens. In deze verklaring vindt u terug hoe we uw persoonsgegevens verzamelen, verwerken en gebruiken. Tijdens uw contacten met CHARLES BRANDING worden bepaalde gegevens van u verzameld en verwerkt. Aangezien u als persoon te identificeren bent aan de hand van deze gegevens, zijn deze gegevens ‘persoonsgegevens’.</p>
				<p>
					Alle info over welke persoonsgegevens CHARLES BRANDING verwerkt en hoe CHARLES BRANDING hier op een correcte manier mee omgaat, kan u hieronder terugvinden.
					<ol>
						<li>Deze Privacy statement & policy</li>
						<li>Persoonsgegevens in het bezit van CHARLES BRANDING</li>
						<li>Verwerking van de persoonsgegevens door CHARLES BRANDING</li>
						<li>Delen van de persoonsgegevens door CHARLES BRANDING</li>
						<li>Uw rechten</li>
						<li>Veiligheidsbeleid van CHARLES BRANDING</li>
						<li>Minderjarigen</li>
						<li>Contactgegevens</li>
					</ol>
				</p>

				<ol className={'main__list'}>
					<li>
						<h3>Deze Privacy statement & policy </h3>
						<p>Deze Privacy statement & policy is van toepassing op alle persoonsgegevens die CHARLES BRANDING van u verzamelt en verwerkt.</p>
						<p>Door gebruik te maken van de (mobiele) website, portalen, apps, webshops en andere producten en diensten van CHARLES BRANDING, gaat u ermee akkoord dat deze Privacy statement & policy van toepassing is op de door CHARLES BRANDING verwerkte persoonsgegevens.</p>
						<p>CHARLES BRANDING behoudt zich het recht voor om deze Privacy statement & policy te allen tijde te wijzigen.</p>
					</li>

					<li>
						<h3>Persoonsgegevens in het bezit van CHARLES BRANDING</h3>
						<p>Volgende gegevens kunnen op verschillende manieren door CHARLES BRANDING verzameld en verwerkt worden. Dit zijn onder meer:</p>

						<h4>Gegevens die u rechtstreeks aan CHARLES BRANDING verstrekt</h4>
						<ul>
							<li>Identificatie- en contactinformatie: naam, voornaam, adres en woonplaats, e-mailadres, telefoon- of gsm-nummer, geboortedatum, leeftijd, geslacht, …;</li>
							<li>Financiële informatie: bankrekeningnummer, BIC code, naam van de rekeninghouder, …;</li>
							<li>Inloggegevens gerelateerd aan een gebruikersaccount: (gebruikers)naam, e-mailadres, wachtwoord, veiligheidsvraag en –antwoord, verbindingsmomenten, …;</li>
							<li>Informatie betreffende de producten en/of diensten die u wenst te bestellen, favoriete producten of diensten, interesses, voorkeuren die u doorgeeft via onze (mobiele) websites, apps, webshops, … ;</li>
							<li>Klachten of feedback via onze support kanalen omtrent uw ervaring met CHARLES BRANDING, opmerkingen, suggesties, getuigenissen en alle andere feedback; Andere gegevens en inhoud die door u zelf uitgewisseld, gecommuniceerd en gedeeld wordt via de CHARLES BRANDING producten, (mobiele) websites, apps, webshops, … zoals onder meer tekst, beelden, geluidsopnamen, …</li>
						</ul>

						<h4>Gegevens die u verstrekt door het gebruik van de (mobiele) websites, apps en webshops</h4>
						<p>Wanneer u gebruik maakt van de (mobiele) websites of apps van CHARLES BRANDING, kan volgende informatie verwerkt worden: bezochte pagina’s, websites en zoekopdrachten door middel van cookies, gegevens die u zelf invoert op de websites of in de apps en andere gegevens waar u CHARLES BRANDING toegang toe verleent heeft (zoals bijvoorbeeld locatiegegevens, …).</p>

						<h4>Gegevens uit andere bronnen</h4>
						<p>
							CHARLES BRANDING kan tevens gegevens via andere bronnen verzamelen en verwerken, zoals onder meer het aankopen of huren van gegevens die verzameld zijn door leveranciers die zich hierin specialiseren, partners van CHARLES BRANDING, openbare bronnen, sociale media, … . Op het gebruik van sociale mediakanalen zullen de regels en voorkeurinstellingen van deze aanbieders van toepassing zijn, zowel op u als op CHARLES BRANDING.<br />
							Op de CHARLES BRANDING websites kunnen links naar andere websites staan. Deze websites worden wellicht niet beheerd door CHARLES BRANDING en kunnen een eigen privacyverklaring of –beleid hebben. CHARLES BRANDING raadt u nadrukkelijk aan deze te lezen en kan geen verantwoordelijkheid nemen voor deze websites van derden.
						</p>
						<p>U bent niet verplicht uw gegevens te verstrekken aan CHARLES BRANDING of akkoord te gaan met de verwerkingen. Niettemin zal het in bepaalde gevallen noodzakelijk zijn om persoonsgegevens mee te delen aan CHARLES BRANDING (zoals onder andere naam, adres, e-mailadres, …), zodat een correcte dienstverlening kan plaatsvinden en de van toepassing zijnde wetgeving nageleefd kan worden.</p>
					</li>

					<li>
						<h3>Verwerking van de persoonsgegevens door CHARLES BRANDING</h3>
						<p>Persoonsgegevens worden door CHARLES BRANDING voor volgende doeleinden verwerkt:</p>
						<ul>
							<li>De totstandkoming en uitvoering van een overeenkomst met u (het bestellen van producten en/of diensten, het aanmaken van een gebruikersaccount, …), alsook het factureren en het opvolgen en innen van deze facturen, al dan niet via derden;</li>
							<li>Het mogelijk maken om bepaalde informatie uit te wisselen, te creëren en te verwijderen en/of contact op te nemen met andere gebruikers op (mobiele) websites, apps, webshops, …;</li>
							<li>Helpdesk</li>
							<li>De continue verbetering, het onderhoud en de optimalisatie van onze producten, diensten, (mobiele) websites, apps en de beveiliging daarvan, alsook de optimalisatie van algemene, commerciële en marketing strategieën van CHARLES BRANDING;</li>
							<li>Het personaliseren van de aan u te leveren producten en/of diensten en de bijbehorende ondersteunende diensten, gebruiksinformatie, service berichten of andere hieraan gerelateerde (elektronische) berichten;</li>
							<li>Het gericht en optimaal op u afgestemd versturen van advertenties, nieuwsbrieven en informatie. Hiertoe kan CHARLES BRANDING uw voorkeuren en interesses bundelen in een profiel. Voor het gebruiken van geavanceerde profileringstechnieken zal CHARLES BRANDING steeds voorafgaand uw toestemming vragen; </li>
							<li>De naleving van de op CHARLES BRANDING van toepassing zijnde regelgeving in de meest brede zin;</li>
							<li>Andere specifieke doeleinden waar afzonderlijk toestemming voor gevraagd kan worden.</li>
						</ul>
					</li>

					<li>
						<h3>Delen van de persoonsgegevens door CHARLES BRANDING</h3>
						<p>CHARLES BRANDING kan de persoonsgegevens die verwerkt worden uitwisselen met moeder-, zuster- en dochtervennootschappen binnen de eigen groep in België.</p>
						<p>Voorts kunnen persoonsgegevens tevens gedeeld worden met partners, leveranciers of andere aangestelden van CHARLES BRANDING wanneer de verwerking door deze derden noodzakelijk is voor de totstandkoming, de uitvoering, het gebruik, de afname, de toegang of de afhandeling met betrekking tot producten, diensten, (mobiele) websites, apps, webshops, … . Met deze partners, leveranciers of andere aangestelden zijn steeds de nodige overeenkomsten afgesloten die het gebruik en het verwerken van uw persoonsgegevens beperken en die een voldoende bescherming van uw persoonsgegevens garanderen.</p>
						<p>Indien uw toestemming wettelijk is vereist of wij van mening zijn dat uw toestemming afhankelijk van de omstandigheden gepast is, zullen we deze toestemming steeds vragen voordat we uw persoonsgegevens delen of doorsturen naar een land buiten de Europese Economische Regio.</p>
						<p>Uw persoonsgegevens worden uitsluitend vrijgegeven in overeenstemming met deze Privacy statement & policy en/of indien dit wettelijk is vereist. In zeldzame gevallen kan het voorkomen dat CHARLES BRANDING uw persoonsgegevens ingevolge een gerechtelijk bevel of om te voldoen aan andere dwingende wet- of regelgeving moet onthullen. CHARLES BRANDING zal in redelijkheid pogen u van tevoren dienaangaande te informeren, tenzij een en ander aan wettelijke beperkingen onderhevig is.</p>
						<p>Onze partners, leverancier of andere aangestelden kunnen uw persoonsgegevens enkel gebruiken om u aanbiedingen, nieuwsbrieven en andere commerciële informatie toe te sturen, indien u hiervoor afzonderlijk toestemming verleend hebt. Indien u dit niet langer wenst te ontvangen, kan u deze derden steeds contacteren om dit gebruik van de persoonsgegevens stop te laten zetten (zie punt 8 van deze Privacy statement & policy).</p>
						<p>Tot slot kunnen persoonsgegevens ook steeds geanonimiseerd worden en gedeeld worden met derden. In dit geval zal u aan de hand van deze geanonimiseerde gegevens nooit geïdentificeerd kunnen worden.</p>
					</li>

					<li>
						<h3>Uw rechten </h3>
						<p>U heeft steeds recht op inzage in en verbetering van de persoonsgegevens die CHARLES BRANDING van u verwerkt. U heeft tevens recht op wissing van uw persoonsgegevens, beperking van de werking van uw persoonsgegevens en de overdraagbaarheid van uw persoonsgegevens, in zoverre de toepasselijke regelgeving hierin voorziet.</p>
						<p>U kan op ieder moment uw gegeven toestemming voor bepaalde verwerkingen intrekken en bezwaar maken tegen de verwerking van uw persoonsgegevens om ernstige en legitieme redenen. Tevens hebt u het recht om u te verzetten tegen het gebruik voor direct marketing van uw persoonsgegevens, tegen de doorgifte van uw persoonsgegevens aan derden (wanneer deze doorgifte niet noodzakelijk is voor de CHARLES BRANDING dienstverlening) en tegen het opstellen van uw profiel.</p>
						<p>Hiertoe kan u contact opnemen met de beheerder via het contactformulier.</p>
						<p>U heeft tevens het recht om klacht in te dienen bij de toezichthoudende autoriteit: gegevensbeschermingsautoriteit.be.</p>
					</li>

					<li>
						<h3>Veiligheidsbeleid van CHARLES BRANDING </h3>
						<h4>Beveiliging van de gegevens</h4>
						<p>
							Om uw persoonsgegevens op de best mogelijke manier te beschermen, neemt CHARLES BRANDING alle redelijke maatregelen en worden best practices toegepast om verlies, misbruik, openbaarmaking, ongeautoriseerde toegang of wijziging van deze persoonsgegevens te voorkomen. Zowel op technisch als op organisatorisch vlak worden de nodige maatregelen getroffen om een voldoende beveiligingsniveau te voorzien.<br />
							Betalingsgegevens worden steeds beschermd conform de standaard versleutelingen die gebruikelijk zijn om gevoelige financiële informatie te beschermen.
						</p>

						<h4>Bewaring van gegevens</h4>
						<p>CHARLES BRANDING zal uw gegevens niet langer bewaren dan noodzakelijk voor de verwerking ervan, rekening houdend met de contractuele en wettelijke verplichtingen van CHARLES BRANDING met betrekking tot deze gegevens en met de missie van CHARLES BRANDING om correct vragen van klanten te kunnen beantwoorden, de kwaliteiten van de producten en dienstverlening te kunnen verbeteren en de eigen wettelijke verplichtingen na te leven.</p>
					</li>

					<li>
						<h3>Minderjarigen</h3>
						<p>CHARLES BRANDING verzamelt of verwerkt nooit bewust persoonsgegevens van minderjarigen zonder toestemming van een ouder of voogd, die gegeven moet worden om de producten, diensten, (mobiele) websites, apps, webshops, … van CHARLES BRANDING te gebruiken en om daarna de rechten met betrekking tot de gegevens van de minderjarige uit te oefenen. Indien persoonsgegevens van minderjarigen te goeder trouw toch verwerkt zouden worden, zal CHARLES BRANDING deze zo snel mogelijk na kennisname hiervan wissen uit haar bestanden.</p>
					</li>

					<li>
						<h3>Contactgegevens</h3>
						<p className={'address'}>
							<span className={'bold'}>CHARLES BRANDING BV</span>
							<span>Industriepark-Noord 14</span>
							<span>9100 Sint-Niklaas</span>
							<span>BTW BE 0664.642.515</span>
							<span>e-mailadres: info@charles.eu</span>
							<span>telefoonnummer: +3237601250</span>
						</p>
					</li>
				</ol>

				<h3>Algemene informatie</h3>
				<p>We hebben het recht om het beleid te wijzigen en aan te passen. Wijzigingen en aanpassingen melden we altijd via de website.</p>

				<h3>Geschillen</h3>
				<p>Het klantenportaal en haar inhoud en alle geschillen en vorderingen die voortkomen uit het gebruik van deze site of enig gegeven dat erop staat, vallen onder de toepassing van het Belgisch recht. Raadplegingen en of gebruik van het klantenportaal houdt in dat u zich onderwerpt aan de rechtspraak van de rechtbanken van de maatschappelijke zetel van CHARLES BRANDING, België, en dat u aanvaardt om alle gedingen alleen voor die rechtbank te brengen.</p>
			</div>
		);
	};

	const renderFrench = () => {
		return (
			<div className={'disclaimer__text'}>
				<h2>CONDITIONS GÉNÉRALES – DISCLAIMER ET POLITIQUE DE CONFIDENTIALITÉ DU PORTAIL CLIENTS</h2>
				<p>L’utilisation du portail clients est soumise aux conditions générales décrites ci-après. En utilisant le portail clients, vous êtes présumé(e) avoir pris connaissance de ces conditions générales et les avoir acceptées sans aucune réserve.</p>
				<p>Les conditions générales de vente de <a href={`${process.env.REACT_APP_PORTAL_URL}/resources/pdf/disclaimers/ALGEMENE_VERKOOP-_EN_LEVERINGSVOORWAARDEN_COCKS_VLEESWAREN_NV.pdf`}>Cock’s Vleeswaren NV</a> et <a href={`${process.env.REACT_APP_PORTAL_URL}/resources/pdf/disclaimers/ALGEMENE_VERKOOPS-_EN_LEVERINGSVOORWAARDEN_CHARLES_DISTRIBUTIE_NV.pdf`}>Charles Distributie NV</a> sont applicables aux commandes introduites via le portail clients. En introduisant une commande via le portail clients, vous êtes présumé(e) avoir pris connaissance de ces conditions générales de vente et les avoir acceptées sans aucune réserve.</p>
				<p>Le portail clients est géré par la entreprise privée « <span className={'bold'}>CHARLES BRANDING</span> », dont le siège social est établi à 9100 Saint-Nicolas, Industriepark-Noord 14, TVA BE 0664.642.515, RPM Gand, division Termonde.</p>

				<h3>But du portail clients</h3>
				<p>
					Les fonctions du portail clients sont, entre autres, les suivantes :
					<ul>
						<li>
							La mise à disposition de :
							<ul>
								<li>données techniques de la gamme de produits Charles</li>
								<li>photos d’articles </li>
								<li>listes d’assortiments</li>
								<li>informations relatives aux promotions</li>
								<li>rapports</li>
							</ul>
						</li>
						<li>Module de commande en ligne</li>
						<li>La communication de publicité et d’actions de marketing</li>
						<li>La mise à disposition des Conditions générales de vente</li>
						<li>Coordonnées et possibilités de contact</li>
						<li>…</li>
					</ul>
				</p>

				<h3>Utilisation du portail clients</h3>
				<p>CHARLES BRANDING accorde beaucoup d’attention et prend grand soin du portail clients. Elle aspire à cet égard à ce que toutes les informations soient les plus complètes, correctes, intelligibles, précises et actuelles possible. Malgré les efforts permanents fournis à cette fin, CHARLES BRANDING ne peut pas garantir que les informations mises à disposition sont complètes, correctes, précises ou à jour. Si les informations fournies sur (ou via) le portail clients présentent des manquements, CHARLES BRANDING mettra tout en œuvre pour les corriger le plus vite possible. </p>
				<p>En ce qui concerne les données techniques des produits, il est souligné que celles-ci relèvent de la seule responsabilité du fournisseur concerné. En effet, les fournisseurs mettent directement à disposition les spécifications des produits qui englobent les données techniques des produits.</p>
				<p>Les données techniques telles qu’elles sont mises à disposition par les fournisseurs sur le portail fournisseurs ne sont pas validées, modifiées, éditées ou contrôlées par CHARLES BRANDING pour vérifier leur conformité avec la législation et/ou la réglementation en vigueur. </p>
				<p>
					CHARLES BRANDING se limite à enregistrer/inventorier les données techniques et/ou à signaler aux fournisseurs l’absence de ces données.<br />
					Les données techniques sont mises à la disposition des utilisateurs du portail clients en temps réel, individuellement, soit au moyen d’un document, soit par le biais de champs de données. Le fournisseur est à tout moment responsable de la mise à jour des données techniques en temps utile.
				</p>
				<p>Les prix des produits indiqués sur le portail client s'entendent hors TVA, hors promotions et/ou réductions de toute nature, à titre indicatif et n'engagent en aucun cas Charles Distributie NV / Cock’s Vleeswaren NV. Les prix à payer par le client sont établis au moment de la facturation. L'article 5 (Prix et frais) des Conditions générales de vente et de livraison Charles Distributie NV / Cock’s Vleeswaren NV est applicable.</p>
				<p>Le contenu du portail clients (hyperliens compris) peut à tout moment être adapté, complété ou supprimé sans préavis ni notification.</p>
				<p>Si vous constatez des erreurs, n’hésitez pas à nous contacter. Pour ce faire, vous pouvez utiliser le formulaire de contact.</p>
				<p>CHARLES BRANDING déploie un maximum d’efforts pour éviter autant que possible des interruptions de nature technique. CHARLES BRANDING ne peut cependant pas garantir que son site web ne sera jamais affecté par des interruptions ou d’autres problèmes techniques.</p>

				<h3>Clause de non-responsabilité</h3>
				<p>CHARLES BRANDING décline toute responsabilité pour les choix effectués par l’utilisateur du portail clients, ou les conséquences y afférentes, sur la base des informations se trouvant sur le portail clients.</p>
				<p>CHARLES BRANDING ne peut être tenue pour responsable des dommages directs ou indirects découlant de l’utilisation du portail clients ou des informations mises à disposition sur ou via le portail clients, en ce compris, mais sans s’y limiter, l’ensemble des pertes, interruptions de travail, dommages de vos programmes ou d’autres données sur le système informatique, ou de vos appareils ou programmes.</p>
				<p>CHARLES BRANDING ne peut être tenue pour responsable des dommages directs ou indirects pouvant découler de l’indisponibilité du portail clients à un moment donné.</p>
				<p>CHARLES BRANDING ne peut pas non plus être tenue responsable des éventuelles erreurs techniques, fautes ou lacunes qui peuvent affecter les éventuelles actions de Cocks Vleeswaren NV et Charles Distributie NV. La société s'engage toutefois à corriger les erreurs techniques au plus vite, dès qu'elle en est informée.</p>

				<h3>Politique générale relative au traitement et à la protection des données</h3>
				<p>CHARLES BRANDING respecte vos droits dans le cadre du traitement de vos données à caractère personnel. La présente déclaration vous explique comment nous collectons, traitons et utilisons vos données à caractère personnel. Lors de vos contacts avec CHARLES BRANDING, certaines de vos données sont collectées et traitées. Comme ces données permettent de vous identifier en tant que personne, elles sont appelées « données à caractère personnel ».</p>
				<p>
					Vous trouverez ci-dessous toutes les informations sur les types de données à caractère personnel traitées par CHARLES BRANDING et sur leur mode de traitement légitime.
					<ol>
						<li>La présente Déclaration et politique de confidentialité</li>
						<li>Données à caractère personnel en possession d’CHARLES BRANDING </li>
						<li>Traitement des données à caractère personnel par CHARLES BRANDING</li>
						<li>Partage des données à caractère personnel par CHARLES BRANDING</li>
						<li>Vos droits</li>
						<li>Politique de sécurité d’CHARLES BRANDING</li>
						<li>Mineurs</li>
						<li>Coordonnées</li>
					</ol>
				</p>

				<ol className={'main__list'}>
					<li>
						<h3>La présente Déclaration et politique de confidentialité</h3>
						<p>La présente Déclaration et politique de confidentialité s’applique à toutes les données à caractère personnel vous concernant qui sont collectées et traitées par CHARLES BRANDING.</p>
						<p>En utilisant le site web (mobile), les portails, les applis, les boutiques en ligne et les autres produits et services d’CHARLES BRANDING, vous acceptez que la présente Déclaration et politique de confidentialité s’applique aux données à caractère personnel traitées par CHARLES BRANDING.</p>
						<p>CHARLES BRANDING se réserve le droit de modifier à tout moment la présente Déclaration et politique de confidentialité.</p>
					</li>

					<li>
						<h3>Données à caractère personnel en possession d’CHARLES BRANDING</h3>
						<p>Les données suivantes peuvent être collectées et traitées par CHARLES BRANDING de différentes manières. Il s’agit notamment de celles-ci :</p>

						<h4>Données que vous fournissez directement à CHARLES BRANDING</h4>
						<ul>
							<li>Informations d’identification et de contact : nom, prénom, adresse et localité, adresse e-mail, numéro de téléphone ou de GSM, date de naissance, âge, sexe, etc. ;</li>
							<li>Informations financières : numéro de compte bancaire, code BIC, nom du titulaire du compte, etc. ;</li>
							<li>Données de connexion liées à un compte d’utilisateur : nom (d’utilisateur), adresse e-mail, mot de passe, question et réponse de sécurité, moments de connexion, etc. ;</li>
							<li>Informations relatives aux produits et/ou services que vous souhaitez commander, produits ou services favoris, centres d’intérêt, préférences que vous communiquez par le biais de nos sites web (mobiles), applis, boutiques en ligne, etc. ;</li>
							<li>Réclamations ou feed-back par le biais de nos canaux de support à propos de votre expérience relative à CHARLES BRANDING, mais aussi remarques, suggestions, témoignages et tout autre feed-back ; Autre(s) données et contenu que vous avez vous-même échangé(es) et partagé(es) par le biais des produits, sites web (mobiles), applis, boutiques en ligne CHARLES BRANDING, etc., tels que, sans s’y limiter, du texte, des images, des prises de son, etc.</li>
						</ul>

						<h4>Données que vous communiquez en utilisant les sites web (mobiles), applis et boutiques en ligne</h4>
						<p>Lorsque vous utilisez les sites web (mobiles) ou applis d’CHARLES BRANDING, les informations suivantes peuvent être traitées : pages visitées, sites web visités et recherches au moyen de cookies, données que vous introduisez vous-même sur les sites web ou dans les applis et autres données auxquelles vous permettez à CHARLES BRANDING d’accéder (telles que, par exemple, les données de localisation, etc.).</p>

						<h4>Données émanant d’autres sources</h4>
						<p>
							CHARLES BRANDING peut également collecter et traiter des données émanant d’autres sources, comme notamment l’achat ou la location de données qui ont été collectées par des fournisseurs spécialisés en la matière, des partenaires d’CHARLES BRANDING, des sources publiques, des réseaux sociaux, etc. S’agissant de l’utilisation des canaux des réseaux sociaux, les règles et paramètres de préférence de ces prestataires s’appliquent à vous comme à CHARLES BRANDING.<br />
							Les sites web d’CHARLES BRANDING peuvent contenir des liens vers d’autres sites web. Ces sites web ne sont sans doute pas gérés par CHARLES BRANDING. Ils peuvent dès lors être régis par leur propre déclaration ou politique de confidentialité. CHARLES BRANDING vous recommande expressément de la lire. CHARLES BRANDING rejette en tout cas toute responsabilité à l’égard de ces sites web de tiers.
						</p>
						<p>Vous n’avez pas l’obligation de fournir vos données à CHARLES BRANDING ou de consentir à leur traitement. Néanmoins, il sera nécessaire dans certains cas de communiquer vos données à caractère personnel à CHARLES BRANDING (telles que, entre autres, votre nom, votre adresse, votre adresse e-mail, etc.) afin de pouvoir vous assurer une prestation de service correcte et de respecter la législation en vigueur.</p>
					</li>

					<li>
						<h3>Traitement des données à caractère personnel par CHARLES BRANDING</h3>
						<p>CHARLES BRANDING traite les données à caractère personnel aux fins suivantes :</p>
						<ul>
							<li>Établir et exécuter un contrat avec vous (la commande de produits et/ou de services, la création d’un compte d’utilisateur, etc.), ainsi que permettre la facturation et le suivi et l’encaissement de ces factures, par le biais de tiers ou non ;</li>
							<li>Rendre possibles l’échange, la création et la suppression de certaines informations et/ou contacter d’autres utilisateurs sur des sites web (mobiles), applis, boutiques en ligne, etc. ;</li>
							<li>Helpdesk</li>
							<li>Améliorer en permanence, assurer la maintenance et l’optimisation de nos produits, services, sites web (mobiles), applis et leur protection, mais aussi optimiser les stratégies générales, commerciales et de marketing d’CHARLES BRANDING ;</li>
							<li>Personnaliser les produits qui vous sont livrés et/ou les services qui vous sont fournis, ainsi que les services de support y afférents, les informations sur leur utilisation, les messages sur les services ou d’autres communications (électroniques) qui y sont liées ;</li>
							<li>Envoyer des publicités, newsletters et informations ciblées adaptées à votre profil. Pour ce faire, CHARLES BRANDING peut créer un profil vous concernant sur la base de vos préférences et de vos centres d’intérêt. Avant d’utiliser des techniques de profilage avancées, CHARLES BRANDING vous demandera toujours au préalable votre consentement ;</li>
							<li>Respecter la réglementation applicable à CHARLES BRANDING dans le sens le plus large ;</li>
							<li>Poursuivre d’autres finalités pour lesquelles votre consentement spécifique vous sera demandé.</li>
						</ul>
					</li>

					<li>
						<h3>Partage des données à caractère personnel par CHARLES BRANDING</h3>
						<p>CHARLES BRANDING peut échanger les données à caractère personnel qui sont traitées avec des sociétés (mère, sœur, filiale) appartenant à son groupe en Belgique.</p>
						<p>Les données à caractère personnel peuvent également être partagées avec des partenaires, des fournisseurs ou des préposés d’CHARLES BRANDING si leur traitement par ces tiers s’avère nécessaire pour l’établissement, l’exécution, l’utilisation, l’achat, l’accès ou le traitement des produits, services, sites web (mobiles), applis, boutiques en ligne, etc. Avec ces partenaires, fournisseurs ou préposés, CHARLES BRANDING conclut toujours des accords limitant l’utilisation et le traitement de vos données à caractère personnel et leur garantissant une protection suffisante.</p>
						<p>Si la loi requiert votre consentement ou si nous estimons que votre consentement est souhaitable au vu des circonstances, nous vous le demanderons toujours avant de partager ou de transmettre vos données à caractère personnel vers un pays situé en dehors de l’Espace économique européen.</p>
						<p>Vos données à caractère personnel ne sont communiquées que dans les conditions prévues par la présente Déclaration et politique de confidentialité et/ou si la loi l’exige. Dans de rares cas, il peut arriver qu’CHARLES BRANDING doive divulguer vos données à caractère personnel pour donner suite à une ordonnance du tribunal ou pour respecter une autre législation ou réglementation en vigueur. CHARLES BRANDING tentera, dans la mesure du raisonnable, de vous en informer au préalable, sauf si l’une ou l’autre restriction légale l'en empêche.</p>
						<p>Nos partenaires, fournisseurs ou préposés ne peuvent utiliser vos données à caractère personnel que pour envoyer des offres promotionnelles, des newsletters et d’autres informations commerciales, si vous y avez donné votre consentement spécifique. Si vous ne souhaitez plus recevoir ces messages, vous pouvez toujours contacter ces tiers afin de faire cesser cette utilisation de vos données à caractère personnel (voir le point 8 de la présente Déclaration et politique de confidentialité).</p>
						<p>Enfin, les données à caractère personnel peuvent également être rendues anonymes avant d’être communiquées à des tiers. En pareil cas, il ne sera jamais possible de vous identifier sur la base de ces données anonymisées.</p>
					</li>

					<li>
						<h3>Vos droits</h3>
						<p>Vous avez à tout moment le droit de consulter et de corriger les données à caractère personnel vous concernant qui sont traitées par CHARLES BRANDING. Vous avez également le droit d’effacer vos données à caractère personnel, de limiter leur traitement et de les transférer, dans la mesure où la réglementation en vigueur le prévoit.</p>
						<p>Vous pouvez à tout moment retirer le consentement que vous avez donné pour certains traitements et vous opposer au traitement de vos données à caractère personnel pour des raisons sérieuses et légitimes. Vous avez également le droit de vous opposer à l’utilisation de vos données à caractère personnel à des fins de marketing direct, à la transmission de vos données à caractère personnel à des tiers (si CHARLES BRANDING n’a pas besoin de les transmettre pour assurer sa prestation de service) et à l’établissement de votre profil.</p>
						<p>Pour exercer ces droits, vous pouvez vous adresser au gestionnaire au moyen du formulaire de contact.</p>
						<p>Vous avez également le droit d’introduire une plainte auprès de l’autorité de contrôle : autoriteprotectiondonnees.be.</p>
					</li>

					<li>
						<h3>Politique de sécurité d’CHARLES BRANDING</h3>
						<h4>Protection des données</h4>
						<p>
							Pour protéger vos données à caractère personnel de la meilleure manière possible, CHARLES BRANDING prend toutes les mesures raisonnables et applique des pratiques d’excellence pour prévenir la perte, l’abus, la divulgation, l’accès illégitime ou la modification de ces données à caractère personnel. Sur le plan technique comme au niveau organisationnel, les mesures nécessaires sont prises pour assurer un niveau de protection suffisant.<br />
							Les données relatives aux paiements sont toujours protégées conformément aux verrouillages standards, utilisés habituellement pour protéger les informations financières sensibles.
						</p>

						<h4>Conservation des données</h4>
						<p>CHARLES BRANDING ne conservera pas vos données plus longtemps que nécessaire pour leur traitement, en prenant en compte les obligations contractuelles et légales d’CHARLES BRANDING relatives à ces données, ainsi que la mission d’CHARLES BRANDING qui consiste à pouvoir répondre correctement aux questions des clients, à améliorer la qualité des produits et services et à respecter ses propres obligations légales.</p>
					</li>

					<li>
						<h3>Mineurs</h3>
						<p>CHARLES BRANDING ne collecte ni ne traite jamais délibérément des données à caractère personnel d’un mineur sans le consentement de l’un de ses parents ou de son tuteur. Ce consentement doit être donné pour pouvoir utiliser les produits, services, sites web (mobiles), applis, boutiques en ligne, etc. d’CHARLES BRANDING et pour pouvoir ensuite exercer les droits relatifs aux données à caractère personnel du mineur. Si des données à caractère personnel devaient tout de même être traitées de bonne foi, CHARLES BRANDING les effacera le plus vite possible de ses fichiers après en avoir pris connaissance.</p>
					</li>

					<li>
						<h3>Coordonnées</h3>
						<p className={'address'}>
							<span className={'bold'}>CHARLES BRANDING BV</span>
							<span>Industriepark-Noord 14</span>
							<span>9100 Saint-Nicolas</span>
							<span>TVA BE 0421.316.629</span>
							<span>adresse e-mail : info@charles.eu</span>
							<span>numéro de téléphone : +3237601250</span>
						</p>
					</li>
				</ol>

				<h3>Informations générales</h3>
				<p>Nous avons le droit de modifier et d’adapter la politique. Nous mentionnons toujours les modifications et les adaptations par le biais du site web.</p>

				<h3>Litiges</h3>
				<p>Le portail clients et son contenu, ainsi que tous les litiges et actions découlant de l’utilisation de ce portail ou de toute donnée qui s’y trouve, sont régis par le droit belge. La consultation et/ou l’utilisation du portail clients implique(nt) que vous vous soumettiez à la jurisprudence des tribunaux du siège social d’CHARLES BRANDING, en Belgique, et que vous acceptiez de présenter tous les contentieux à ces seuls tribunaux.</p>
			</div>
		);
	};


	return (
		<div className={'Disclaimer'}>
			{locale === 'nl' && renderDutch()}
			{locale === 'fr' && renderFrench()}
		</div>
	);

};

export default Disclaimer;
