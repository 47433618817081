import React from 'react';
import BuylistArticle from '../BuylistArticle';


function BuylistArticleContainer(props) {
	return (
		<BuylistArticle
			{...props}
		/>
	);
}

export default React.memo(BuylistArticleContainer, (a, b) => {
	if (a.product.id !== b.product.id) return false;
	if (a.view !== b.view) return false;
	if (a.sizes.length !== b.sizes.length) return false;
	return true;
});
