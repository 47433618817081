import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
	selectCustomerId,
	selectDetailMargins,
	selectIsDetailMarginsFetching,
	selectIsDetailMarginsSaving,
	selectIsMarginsMetadataFetching,
	selectMarginsCategories,
	selectUnsavedDetailMargins,
} from '../selectors';

import {
	cancelUnsavedMargins,
	fetchDetailMarginsIfNeeded,
	fetchMarginsMetadataIfNeeded,
	saveDetailMargins,
} from './MarginsActions';

import {
	getLocale,
	t
} from '../../libraries/i18n';

import {
	Button,
	List,
	Spinner,
	TextFilter
} from '@abm-international/react-components';

import {
	FaLongArrowAltLeft,
	FaPencilAlt,
	FaSave,
	FaTrash
} from 'react-icons/fa';
import {
	getArticleRenderer,
	getEditableSalesPriceRenderer,
	getImgRenderer,
	getPurchasePriceRenderer,
	getQuantitiesRenderer,
	getSalesPriceRenderer,
	getUsedMarginRenderer,
	getUserMarginRenderer,
	getVatRenderer
} from './ColumnRenderers';
import { useHistory } from 'react-router';

function DetailOneArticle() {
	const match = useRouteMatch();
	const { articleId } = match.params;
	const locale = getLocale();
	const dispatch = useDispatch();

	const customerId = useSelector(selectCustomerId);

	const isMetadataFetching = useSelector(selectIsMarginsMetadataFetching);
	const categories = useSelector(selectMarginsCategories);

	const isFetching = useSelector(state => selectIsDetailMarginsFetching(state, 'article', articleId));
	const { category: groupList, articles } = useSelector(state => selectDetailMargins(state, 'article', articleId));
	const group = groupList?.[0];
	const productType = group?.productType;
	const marginType = group?.marginType;
	const unsavedMargins = useSelector(state => selectUnsavedDetailMargins(state, 'article', articleId));
	const isSaving = useSelector(state => selectIsDetailMarginsSaving(state, 'article', articleId));

	const [isEditingState, setIsEditing] = useState(false);
	const isEditing = unsavedMargins.length > 0 || isEditingState;

	const [errors, setErrors] = useState({});

	const history = useHistory();

	const setErrorsById = (id, error) => {
		setErrors({
			...errors,
			[id]: error
		});
	};

	const BY_UNIT = {
		S: t('margins.by_piece'),
		K: t('margins.by_weight')
	};

	useEffect(() => {
		dispatch(fetchDetailMarginsIfNeeded(customerId, 'article', articleId));
		dispatch(fetchMarginsMetadataIfNeeded(customerId));
	}, [customerId, dispatch, articleId, isSaving]);


	const getUserDefinedMargin = (articleId, fallback) => {
		const unsavedMargin = unsavedMargins.find(item => {
			return (item.article === articleId);
		});
		if (unsavedMargin) return unsavedMargin.value;
		if (fallback) return fallback;
	};

	const getProductMargin = (articleId, fallback) => {
		const userMargin = getUserDefinedMargin(articleId, fallback);

		if (userMargin) return userMargin;
		return ''; // make sure that the input value is never undefined/uncontrolled.

	};

	const getFinalMargin = (articleId, fallback) => {
		const userMargin = getUserDefinedMargin(articleId, fallback);

		if (userMargin) return userMargin;
		if (group.value) return group.value;
		if (group.proposed) return group.proposed;
		return ''; // make sure that the input value is never undefined/uncontrolled.
	};

	// TODO: abstract this to a helper
	const getConsumerPrice = ({
		id,
		value,
		purchase,
		sales,
		amount
	}) => {
		const margin = getFinalMargin(id, value);
		const marginMultiplier = margin / 100;
		const vatMultiplier = 1 + (sales?.vat / 100);

		const getPurchasePrice = (pUnit, sUnit, price, weight, amount) => {
			if (pUnit === 'K' && sUnit === 'K') {
				return price;
			}

			if (pUnit === 'S' && sUnit === 'S') {
				return price / amount;
			}

			if (pUnit === 'K' && sUnit === 'S') {
				return price * weight / amount;
			}

			if (pUnit === 'S' && sUnit === 'K') {
				return price / weight;
			}
		};

		return (getPurchasePrice(purchase?.unit, sales?.unit, purchase?.price, purchase?.weight?.net, amount) * marginMultiplier * vatMultiplier).toFixed(2);
	};

	const header = [
		{
			render: getImgRenderer(),
			key: 1
		},
		{
			render: getArticleRenderer(),
			title: t('margins.product_title'),
			key: 2,
			sort: (a, b) => a.id - b.id,
			filter: {
				test: ({ article, id }, value) => article.desc.includes(value.toUpperCase()) || id.includes(value),
				component: TextFilter
			}
		},
		{
			render: getPurchasePriceRenderer(BY_UNIT),
			title: t('margins.purchase_price_title'),
			key: 3
		},
		{
			render: getVatRenderer(),
			title: t('margins.vat_title'),
			key: 4
		},
		{
			render: getQuantitiesRenderer(),
			title: t('margins.sales_packaged_goods_title'),
			key: 5
		}
	];
	const editHeader = [

		{
			render: getUserMarginRenderer(getProductMargin, dispatch, 'article', articleId, undefined, setErrorsById, unsavedMargins),
			title: t('margins.your_margin_title'),
			key: 6
		},
		{
			render: getEditableSalesPriceRenderer(getConsumerPrice, dispatch, 'article', articleId, undefined, BY_UNIT, group, unsavedMargins),
			title: t('margins.sales_price_title'),
			key: 7
		},
		// {
		// 	render: ({id}) => (errors?.[id]?.length ? <ul className={'error'}>{errors?.[id]?.map(e => <li>{e}</li>)}</ul> : null),
		// 	title: '',
		// 	key: 8
		// }
	];
	const displayHeader = [
		{
			render: getUsedMarginRenderer(getProductMargin, unsavedMargins),
			title: t('margins.your_margin_title'),
			key: 6
		},
		{
			render: getSalesPriceRenderer(getConsumerPrice, BY_UNIT, group, unsavedMargins),
			title: t('margins.sales_price_title'),
			key: 7
		},
	];

	const category = categories.find(c => c.id === group?.category);

	const renderNav = () => {
		if (!isEditing) {
			return (
				<nav className={'ProductNavigator'}>
					<Button
						className='back'
						icon={<FaLongArrowAltLeft />}
						onClick={history.goBack}
					>
						{t('margins.go_back')}
					</Button>
				</nav>
			);
		}

		return (
			<nav className={'ProductNavigator'}>
				<Button
					className='back'
					icon={<FaTrash />}
					onClick={() => {
						setIsEditing(false);
						dispatch(cancelUnsavedMargins());
						history.goBack();
					}}
				>
					{t('margins.cancel_and_go_back')}
				</Button>
			</nav>
		);
	};

	const renderTitle = () => (
		<>
			<h3 style={{ fontSize: '2.5rem' }}>
				<span>{category?.title[locale]}</span>
				<span>{'>'}</span>
				<span>{category?.productTypes[productType][locale]}</span>
				<span>{'>'}</span>
				<span>{category?.marginTypes[marginType][locale]}</span>
			</h3>
			<h4>
				<span>
					{group?.value ?
						`${t('margins.your_margin_label')}: ${group.value}%`
						:
						`${t('margins.charles_margin_label')}: ${group?.proposed}%`
					}
				</span>
			</h4>
		</>
	);

	const renderActions = () => (
		<div className={'actions'}>
			<Button
				icon={<FaTrash />}
				onClick={() => {
					setIsEditing(false);
					dispatch(cancelUnsavedMargins());
				}}
			>
				{t('margins.cancel')}
			</Button>
			<Button
				icon={<FaSave />}
				onClick={() => {
					setIsEditing(false);
					dispatch(saveDetailMargins(customerId, 'article', articleId));
				}}
				disabled={!unsavedMargins.length}
			>
				{t('margins.save')}
			</Button>
		</div>
	);

	return (
		<>
			{renderNav()}

			<div className={'Detail'}>

				{(isMetadataFetching || isFetching) && <Spinner />}

				{!isMetadataFetching && !isFetching && Object.keys(articles ?? {}).length <= 0 && (
					<div>
						{t('margins.noArticles')}
					</div>
				)}
				{Object.keys(articles ?? {}).length > 0 && (
					<section className={'categoryArticles'}>
						{renderTitle()}


						{!isEditing && (
							<Button
								onClick={() => setIsEditing(true)}
								icon={<FaPencilAlt />}
							>
								{t('margins.edit')}
							</Button>
						)}

						<List
							header={[...header, ...(isEditing || isSaving ? editHeader : displayHeader)]}
							content={Object.values(articles)}
							gridTemplateColumns='6rem 5fr 2fr 1fr 2fr 2fr 2fr'
						/>
						{isEditing && renderActions()}
					</section>
				)}
			</div>
		</>
	);
}

export default DetailOneArticle;
