
import {
	APP_LOADED,
	APP_OFFLINE,
	APP_ONLINE,
	APP_NAVIGATION_TOGGLE_COLLAPSE,
	APP_TRANSLATIONS_REQUEST,
	APP_TRANSLATIONS_RECEIVE,
	APP_TRANSLATIONS_REJECT,
	FETCH_RESOURCES_LIST_START,
	FETCH_RESOURCES_LIST_SUCCESS,
	FETCH_RESOURCES_LIST_FAILED
} from './appActions';
import {
	CUSTOMER_RECEIVE
} from './customer/customerActions';
import UserPreferences from './../libraries/userPreferences';
import { ORDER_DELIVERY_DATES_RECEIVE } from './order/orderActions';

const initialState = {
	offline: false,
	navigation: {
		collapsed: UserPreferences.get(UserPreferences.PREFERENCES.NAVIGATION_COLLAPSED) || false
	},
	loadedTranslations: [],
	fetchingTranslations: [],
	holidays: [],
	settings: {}
};

export default function app(state = initialState, action) {
	switch (action.type) {
		case APP_OFFLINE:
			return appOffline(state, action);
		case APP_ONLINE:
			return appOnline(state, action);
		case APP_NAVIGATION_TOGGLE_COLLAPSE:
			return appNavigationToggleCollapse(state, action);
		case APP_TRANSLATIONS_REQUEST:
			return {
				...state,
				fetchingTranslations: translations(state.fetchingTranslations, { type: 'ADD', locale: action.locale })
			};
		case APP_TRANSLATIONS_RECEIVE:
			return {
				...state,
				fetchingTranslations: translations(state.fetchingTranslations, { type: 'REMOVE', locale: action.locale }),
				loadedTranslations: translations(state.loadedTranslations, { type: 'ADD', locale: action.locale })
			};
		case APP_TRANSLATIONS_REJECT:
			return {
				...state,
				fetchingTranslations: translations(state.fetchingTranslations, { type: 'REMOVE', locale: action.locale })
			};
		case CUSTOMER_RECEIVE:
			return {
				...state,
				latestClientVersion: action.customer.latestClientVersion,
				routes: action.customer.routes,
				settings: action.customer.settings
			};
		case ORDER_DELIVERY_DATES_RECEIVE:
			return {
				...state,
				holidays: action.data.holidays
			};
		case APP_LOADED:
		default:
			return {
				...state,
				resources: resources(state.resources, action)
			};
	}
}

export const resources = (
	state = {
		isFetching: false,
		items: null,
		didFail: false
	},
	action
) => {
	switch (action.type) {
		case FETCH_RESOURCES_LIST_START:
			return {
				...state,
				isFetching: true
			};
		case FETCH_RESOURCES_LIST_SUCCESS:
			return {
				...state,
				isFetching: false,
				items: action.payload
			};
		case FETCH_RESOURCES_LIST_FAILED:
			return {
				...state,
				isFetching: false,
				didFail: true
			};
		default:
			return {
				...state
			};
	}
};

function translations(state, action) {
	switch (action.type) {
		case 'ADD':
			return [
				...state,
				action.locale
			];
		case 'REMOVE':
			const index = state.indexOf(action.locale);
			return [
				...state.slice(0, index),
				...state.slice(index + 1)
			];
		default:
			return [...state];
	}
}

function appOffline(state, action) {
	return {
		...state,
		offline: true,
		offlineSince: action.payload,
		onlineSince: undefined
	};
}

function appOnline(state, action) {
	return {
		...state,
		offline: false,
		offlineSince: undefined,
		onlineSince: action.payload
	};
}

function appNavigationToggleCollapse(state, action) {
	const collapsedState = action.payload !== undefined ? action.payload : !state.navigation.collapsed;
	UserPreferences.set(UserPreferences.PREFERENCES.NAVIGATION_COLLAPSED, collapsedState);

	return {
		...state,
		navigation: {
			...state.navigation,
			collapsed: collapsedState
		}
	};
}
