import React, { useCallback } from 'react';
import { Article } from '@abm-international/react-components';
import DateHelper from '../../../libraries/dateHelper';
import './BuylistArticle.scss';

const ProductHelper = {
	getPendingForDeliveryWeek: (pending, deliveryDate) => {
		if (!pending) return 0;
		if (!deliveryDate) deliveryDate = new Date();
		const pendingByWeek = {};
		Object.keys(pending).forEach(datestamp => {
			const date = DateHelper.getDateFromReversedDateString(datestamp);
			const week = DateHelper.getWeekNumber(date)[1];
			pendingByWeek[week] = pending[datestamp] + (pendingByWeek[week] || 0);
		});

		const deliveryWeek = DateHelper.getWeekNumber(new Date(deliveryDate))[1];
		return pendingByWeek[deliveryWeek] || 0;
	}
};

export const getHeight = (article) => {
	// if no promos and no reserved or pending and no quantity or awardedPromo -> return 56
	if (article.promo) return 80;
	if (article.pending && ProductHelper.getPendingForDeliveryWeek(article.pending, article.deliveryDate) > 0) return 80;
	if (article.reserved) return 80;
	if (article.campaigns && article.campaigns.length > 0) return 80;
	return 52;
};

const OrderArticleCompact = (props) => {
	const {
		sizes,
		showModal,
		product,
		query,
		locale,
		t,
		...restProps
	} = props;


	const getDerivedClasses = ({
		hasPromo,
		isPending,
		hasReserved,
		hasCampaigns
	}) => {
		if (hasPromo || isPending || hasReserved || hasCampaigns) return '';
		return 'without-bottom';
	};

	const openModal = useCallback(() => showModal(product.id), [product.id, showModal]);

	const isTiny = sizes.includes('size630');

	return (
		<Article
			{...restProps}
			product={product}
			t={t}
			query={query}
			locale={locale}
		>
			{(A, a) => (
				<div
					className={`Article list-compact ${getDerivedClasses(a)} ${sizes.join(' ')}`}
				>
					<div className={'detail'}>
						<div className={'top'}>
							<A.Id /><A.Brand /><A.Badges size={isTiny ? 'small' : ''} />
						</div>
						<div className={'middle'}>
							<A.BuylistIndicator /><A.Name onClick={openModal} />
						</div>
						<A.Allergens compact />
					</div>
					<div className={'order'}>
						<A.OrderHistory size={'compact'} />
						<A.BuylistAction />
					</div>
					<div className={'bottom'}>
						<div>
							{!isTiny && <A.Campaigns size={'small'} />}
							<A.Promo hideRibbon />
						</div>
						<div>
							<A.Pending size={'small'} />
							<A.Reserved size={'small'} />
						</div>
					</div>
				</div>
			)}
		</Article>
	);
};


export default OrderArticleCompact;
