import React from 'react';
import PropTypes from 'prop-types';

import './BuylistArticle.scss';
import BuylistArticleList, { getHeight as getArticleHeight } from './list';
import BuylistArticleCompact from './compactList';
import BuylistArticleGrid from './grid';

const VIEWS = {
	GRID: 'grid',
	COMPACT: 'list-compact',
	LIST: 'list'
};

export const getHeight = (view, size, article) => {
	return getArticleHeight(view, size, article);
};

const BuylistArticle = ({ view, ...props }) => {
	if (view === VIEWS.LIST) return <BuylistArticleList {...props} />;
	if (view === VIEWS.COMPACT) return <BuylistArticleCompact {...props} />;
	if (view === VIEWS.GRID) return <BuylistArticleGrid {...props} />;
	return `view ${view} is not implemented`;
};


// {renderItem(view, product, images, totalQuantity, !product.canOrder, history, isHistoryFetching)}
// {buylistActionModalVisible && renderBuylistActionModal()}
// {buylistActionSuccessModalVisible && renderBuylistActionSuccessModal()}
// {buylistActionFailModalVisible && renderBuylistActionFailModal()}

BuylistArticle.propTypes = {
	showModal: PropTypes.func,
	product: PropTypes.shape({
		id: PropTypes.string,
		code: PropTypes.string,
		brand: PropTypes.string,
		canOrder: PropTypes.bool,
		name: PropTypes.string,
		promo: PropTypes.any,
		eob: PropTypes.any,
		season: PropTypes.any,
		start: PropTypes.any,
		end: PropTypes.any,
		inBuylist: PropTypes.bool,
		canRemoveFromBuylist: PropTypes.bool,
		canAddToBuylist: PropTypes.bool,
		campaigns: PropTypes.arrayOf(PropTypes.any),
		madQuantity: PropTypes.number,
		images: PropTypes.shape({
			PS: PropTypes.arrayOf(PropTypes.string),
			SF: PropTypes.arrayOf(PropTypes.string)
		}),
		allergens: PropTypes.arrayOf(PropTypes.oneOf([1, 2, 3, 4, 5, 6, '7.1', '7.2', 8, 9, 10, 11, 12, 13, 14 ])),
	}),
	view: PropTypes.oneOf(['list', 'list-compact', 'grid']),
	query: PropTypes.string,
	locale: PropTypes.string,
	removeProductFromBuylist: PropTypes.func,
	addProductToBuylist: PropTypes.func,
	t: PropTypes.func,
	imageSource: PropTypes.string,
	history: PropTypes.objectOf(PropTypes.number),
	isHistoryFetching: PropTypes.bool,
	sizes: PropTypes.any,
};

export default BuylistArticle;
