import React, {
    ChangeEvent, FormEvent, MouseEvent, useEffect, useMemo, useState
} from 'react';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';
import { t } from '../../libraries/i18n';
import { useSelector } from 'react-redux';
import { selectCustomerId, selectLocale } from '../selectors';
import { CharlesTitle } from '@abm-international/react-components';
import { Button, Input } from '@abm-international/react-components';
import { MenuItem, Select } from '@mui/material';
import HeaderNlImage from './charles_koerse_NL.jpg';
import HeaderFrImage from './charles_koerse_FR.jpg';
import { get, post } from '../../libraries/api';
import { Spinner } from '@abm-international/react-components';
import './TradeFairRegistrations.scss';
import { useParams } from 'react-router-dom';
import { TradeFairInfo, TradeFairRegistration } from '~/types/trade-fairs.types';
import { toast } from 'react-toastify';
import { emailRegex } from '../../libraries/helpers/regex';
import { a } from 'msw/lib/glossary-c690f512';
import { getPrettyDateStartEnd } from '../../libraries/dateHelper';



const URL = '/api/v1/trade-fairs';
const MAX_FALLBACK = 10;

export default function TradeFairRegistrations() {
    const [attendees, setAttendees] = useState(0);
    const [attendeesExtra, setAttendeesExtra] = useState(0);
    const [attendeesExtra2, setAttendeesExtra2] = useState(0);
    const [attendanceDateStart, setAttendanceDateStart] = useState('');
    const [comments, setComments] = useState('');
    const [email, setEmail] = useState('');
    const [tradeFairInfo, setTradeFairInfo] = useState<TradeFairInfo>();
    const [loading, setLoading] = useState(false);
    const params = useParams<{ name: string }>();

    const [registration, setRegistration] = useState<TradeFairRegistration>();
    const [fetching, setFetching] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [canEdit, setCanEdit] = useState(true);


    useCustomerRoute();
    const customerId = useSelector(selectCustomerId);
    const locale = useSelector(selectLocale);

    const dateOptions: { value: string, valueEnd: string, display: string }[] = useMemo(() => {
        if (!tradeFairInfo) return [];

        return tradeFairInfo.dates?.map((d: string) => {
            const [start, end] = d.split('/');
            return {
                value: new Date(start).toUTCString(),
                valueEnd: new Date(end).toUTCString(),
                display: `${getPrettyDateStartEnd(new Date(start), new Date(end), locale)}`,
                // day: '23/04/2024',
                // hourFrom: '18:00',
                // hourTo: '20:00',
            };
        });
    }, [tradeFairInfo]);

    // Fetch trade fair info
    useEffect(() => {
        if (tradeFairInfo || loading || !params.name) return;
        setLoading(true);

        (async () => {
            try {
                const response = await get(`${URL}/${params.name}`);
                if (!response.success) return false;
                setTradeFairInfo(response.data);
                setLoading(false);
            } catch (e) {
                return false;
            }
        })();

    }, [tradeFairInfo, loading, params.name]);

    // Fetch trade fair registration
    useEffect(() => {
        (async () => {
            if (tradeFairInfo || fetching || !params.name || !customerId) return;
            setFetching(true);
            try {
                const response = await get(`${URL}/${params.name}/registration/${customerId}`);
                if (!response.success || !response?.data) return false;
                if (!response?.data?.id && response?.data?.email) {
                    setEmail(response?.data?.email || '');
                    return false;
                }
                let reg = response.data as TradeFairRegistration;
                const _attendanceDateStart = reg.attendanceDateStart ? new Date(reg.attendanceDateStart) : new Date();
                const userTimezoneOffset = _attendanceDateStart.getTimezoneOffset() * 60000;

                reg = {
                    ...reg,
                    attendanceDateStart: new Date(_attendanceDateStart.getTime() + userTimezoneOffset),
                    attendanceDateEnd: new Date(_attendanceDateStart.getTime() + userTimezoneOffset),
                };
                setRegistration(reg);
                setAttendees(reg.attendees || 0);
                setAttendeesExtra(reg.attendeesExtra || 0);
                setAttendanceDateStart(reg.attendanceDateStart ? new Date(reg.attendanceDateStart).toUTCString() : '');
                setComments(reg.comments || '');
                setEmail(reg.email || '');
                setFetching(false);
                setCanEdit(false);
            } catch (e) {
                return false;
            }
        })();

    }, [tradeFairInfo, fetching, customerId, params.name]);

    // const editable = useMemo(() => {
    // 	return submitting || (prefetchedData && prefetchedData.attendees === amountOfAdults && new Date(prefetchedData?.attendenceDate as string)?.toUTCString() === new Date(eventDate).toUTCString()) || amountOfAdults < 1 || !amountOfAdults;
    // }, [amountOfAdults, eventDate, prefetchedData, submitting]);

    // const updateDate = (e: SelectChangeEvent) => {
    // 	setEventDate(e.target.value);
    // };

    const updateCount = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (e.target.name === 'extra2') {
            if (value <= (tradeFairInfo?.attendeesExtra?.[1] || MAX_FALLBACK)) {
                setAttendeesExtra2(parseInt(e.target.value));
            }
        } else if (e.target.name === 'email') {
            setEmail(e.target.value);
        } else if (e.target.name === 'extra') {
            if (value <= (tradeFairInfo?.attendeesExtra?.[1] || MAX_FALLBACK)) {
                setAttendeesExtra(parseInt(e.target.value));
            }
        } else {
            if (value <= (tradeFairInfo?.attendees?.[1] || MAX_FALLBACK)) {
                setAttendees(parseInt(e.target.value));
            }
        }
    };

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        const attDates = dateOptions.find(d => d.value === attendanceDateStart);
        if (!attDates) return;

        if (!emailRegex.test(email)) {
            toast.error(t('events.form_email_error'));
            return;
        }
        if (attendeesExtra > 0 && !comments) {
            toast.error(t('events.form_comments_error'));
            return;
        }

        const _attendanceDateStart = new Date(attDates.value);
        const _attendanceDateEnd = new Date(attDates.valueEnd);
        const userTimezoneOffsetStart = _attendanceDateStart.getTimezoneOffset() * 60000;
        const userTimezoneOffsetEnd = _attendanceDateEnd.getTimezoneOffset() * 60000;

        const formData = {
            attendanceDateStart: new Date(_attendanceDateStart.getTime() - userTimezoneOffsetStart),
            attendanceDateEnd: new Date(_attendanceDateEnd.getTime() - userTimezoneOffsetEnd),
            attendees: attendees,
            attendeesExtra: attendeesExtra,
            attendeesExtra2: attendeesExtra2,
            comments: comments,
            email: email,
        };



        setSubmitting(true);
        setSubmitted(false);

        try {
            const response = await post(`${URL}/${params.name}/registration/${customerId}`, JSON.stringify(formData));
            if (response.success && response.data) {
                setRegistration(response.data);
            }
            setSubmitting(false);
            setSubmitted(true);
            setCanEdit(false);
            setDeleted(false);
            toast.success(t('events.form_success_text'));
        } catch (err) {
            toast.error(t('events.form_error_text'));
            console.log(err);
            setSubmitting(false);
        }
    };

    const deleteEvent = async (e: MouseEvent<HTMLButtonElement>) => {
        setSubmitting(true);

        try {
            await get(`${URL}/${params.name}/registration/${customerId}/delete`);
            setSubmitting(false);
            setSubmitted(true);
            setDeleted(true);
            setAttendees(0);
            setAttendeesExtra(0);
            setAttendanceDateStart('');
            setComments('');
            setRegistration(undefined);
            setCanEdit(false);
        } catch (err) {
            console.log(err);
            setSubmitting(false);
        }
    };

    const cancelForm = () => {

        setAttendees(registration?.attendees || attendees || 0);
        setAttendeesExtra(registration?.attendeesExtra || attendeesExtra || 0);
        setAttendeesExtra2(registration?.attendeesExtra2 || attendeesExtra2 || 0);
        setAttendanceDateStart(registration?.attendanceDateStart ? new Date(registration.attendanceDateStart).toUTCString() : new Date(attendanceDateStart).toUTCString() || '0');
        setCanEdit(false);
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='Campaigns'>
            <div className='campaign-header'>
                <img src={locale === 'nl' ? HeaderNlImage : HeaderFrImage} alt='chalres_koerse' />

                <CharlesTitle alt>{t('events.title')}</CharlesTitle>
                <div className='intro' dangerouslySetInnerHTML={{ __html: t('events.intro') }}></div>

            </div>
            <div className='intro_second' dangerouslySetInnerHTML={{ __html: t('events.intro_second') }}></div>
            {(!tradeFairInfo) && <>OEPS <Spinner /></>}

            {tradeFairInfo && <>
                <div className='simulate'>
                    <h2>{((submitted && !deleted) || registration) ? t('events.form_title_has_rgistration') : t('events.form_title')}</h2>
                    {((submitted && !deleted) || registration) && <p className='desc'>{t('events.form_has_registration')}</p>}

                    <form noValidate onSubmit={submitForm}>
                        {!!tradeFairInfo?.attendees?.[1] && <div className='input-group'>
                            <label>{t('events.form_input_quantity', tradeFairInfo.attendees[1])}</label>

                            <Input
                                className='input'
                                type='number'
                                value={attendees}
                                onChange={updateCount}
                                disabled={!canEdit || submitting}
                                min={tradeFairInfo.attendees[0]}
                                max={tradeFairInfo.attendees[1]}
                            />
                        </div>}

                        {!!tradeFairInfo?.attendeesExtra?.[1] && <div className='input-group'>
                            <label>{t('events.form_input_quantity_second', tradeFairInfo.attendeesExtra[1])}</label>

                            <Input
                                className='input'
                                type='number'
                                value={attendeesExtra}
                                onChange={updateCount}
                                name='extra'
                                disabled={!canEdit || submitting}
                                min={tradeFairInfo.attendeesExtra[0]}
                                max={tradeFairInfo.attendeesExtra[1]}
                            />
                        </div>}

                        {(!tradeFairInfo.disableComments && attendeesExtra > 0) && <div className='input-group'>
                            <label>{t('events.form_comments')}</label>

                            <textarea
                                className='Input input'
                                value={comments}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComments(e.target.value)}
                                disabled={!canEdit || submitting}
                                maxLength={100}
                                rows={3}
                            />
                        </div>}

                        {!!tradeFairInfo?.attendeesExtra2?.[1] && <div className='input-group'>
                            <label>{t('events.form_input_quantity', tradeFairInfo.attendeesExtra2[1])}</label>

                            <Input
                                className='input'
                                type='number'
                                value={attendeesExtra2}
                                onChange={updateCount}
                                name='extra2'
                                disabled={!canEdit || submitting}
                                min={tradeFairInfo.attendeesExtra2[0]}
                                max={tradeFairInfo.attendeesExtra2[1]}
                            />
                        </div>}

                        <div className='input-group'>
                            <label>{t('events.form_input_email')}</label>

                            <Input
                                className='input'
                                type='text'
                                value={email}
                                onChange={updateCount}
                                name='email'
                                disabled={!canEdit || submitting}
                            />
                        </div>

                        <div className='input-group'>
                            <label>{t('events.form_input_date')}</label>

                            <Select
                                className='input'
                                value={attendanceDateStart}
                                onChange={(e) => setAttendanceDateStart(new Date(e.target.value).toUTCString())}
                                disabled={!canEdit || submitting}
                            >
                                {dateOptions.map((dateObject: any) => {
                                    return <MenuItem key={dateObject.value} value={dateObject.value}>{dateObject.display}</MenuItem>;
                                })}
                            </Select>
                        </div>



                        <div className='control-group'>
                            {!canEdit && <Button
                                type="button"
                                onClick={() => setCanEdit(true)}
                                disabled={submitting}
                            >
                                {t('events.form_button_edit')}
                            </Button>}

                            {(canEdit) && <>
                                {registration && <>
                                    <Button
                                        type="button"
                                        disabled={submitting}
                                        onClick={deleteEvent}
                                        className='danger'
                                    >
                                        {t('events.form_button_delete')}
                                    </Button>
                                </>}

                                <Button
                                    className="cancel"
                                    type="button"
                                    onClick={cancelForm}
                                    disabled={submitting}
                                >
                                    {t('events.form_button_cancel')}
                                </Button>

                                <Button
                                    type='submit'
                                    iconPosition='right'
                                    disabled={!attendees || !attendanceDateStart}
                                    primary
                                >
                                    {t('events.form_button_submit')}
                                </Button>
                            </>}
                        </div>
                    </form>

                    {submitted && <div>
                        <p className='desc green'>
                            {/* {t((deleted ? 'events.form_deleted_text' : 'events.form_success_text'), dateOptions.find(d => d.value === eventDate)?.day, dateOptions.find(d => d.value === eventDate)?.hourFrom, dateOptions.find(d => d.value === eventDate)?.hourTo)} */}
                        </p>
                    </div>}

                </div>

            </>}
        </div>
    );
}
