import { createSelector } from 'reselect';
import DateHelper from '../../libraries/dateHelper';
import { selectFilteredOrderProducts } from '../selectors';

export const selectItems = state => state.items;
export const selectCustomerProducts = state => state.customerProducts;

export const selectProducts = state => {
	if (!state.items || !state.customerProducts) return [];
	const productsCommon = state.items;

	return Object.keys(state.customerProducts).map(key => {
		const customerProduct = state.customerProducts[key];
		return {
			...customerProduct,
			...productsCommon[customerProduct.id]
		};
	});
};

export const selectProductById = (state, id, force) => {
	const products = selectItems(state);
	const customerProducts = selectCustomerProducts(state);

	if ((!products || !customerProducts) && !force) return undefined;

	const product = {
		...(products?.[String(id)] || {}),
		...(customerProducts?.[String(id)] || {})
	};

	if (product.start) product.start = DateHelper.fromReduxDateString(product.start);
	if (product.end) product.end = DateHelper.fromReduxDateString(product.end);

	return Object.keys(product)?.length ? product : undefined;
};

export const selectProductPointsById = (state, id) => {
	const points = state.points.items;
	return points[id];
};

export const selectProductPoints = state => state.points.items;

export const selectUniqueFilteredBrands = createSelector(
	(state) => selectFilteredOrderProducts(state),
	(products) => {
		const BRANDS = products.reduce((acc, { brand }) => {
			if (brand !== null && brand !== undefined && brand !== '' && brand !== 'X' && brand !== 'VAGRO') {
				const existingBrand = acc.find(item => item.id === brand);
				if (existingBrand) {
					existingBrand.amount++;
				} else {
					acc.push({ id: brand, amount: 1 });
				}
			}
			return acc;
		}, []).sort((a, b) => b.amount - a.amount);

		//move charles brand to top
		const charlesIndex = BRANDS.findIndex(item => item.id === 'CHARLES');
		if (charlesIndex !== -1) {
			const charlesBrand = BRANDS.splice(charlesIndex, 1);
			BRANDS.unshift(...charlesBrand);
		}
		return BRANDS;
	}
);


export const selectProductsById = createSelector(
	selectItems,
	selectCustomerProducts,
	(products, customerProducts) => {
		if (!products || !customerProducts) return {};
		const items = {};
		Object.values(customerProducts).forEach(p => {
			items[p.id.toString()] = {
				...p,
				...products[p.id]
			};
		});
		return items;
	}
);

export const selectCampaignsByProductId = (state, id) => {
	const campaigns = selectCampaigns(state).filter(campaign => {
		if (!campaign.articles) return false;
		const includes = campaign.articles?.ids?.includes(parseInt(id));
		return includes;
	});

	return campaigns.length === 0 ? false : campaigns;
};

export const selectPromos = state => state.promos.items;
export const selectShopcodes = state => state.metadata.shopcodes;
export const selectGroups = state => state.metadata.groups;
export const selectIsProductsFetching = state => state.isFetching;
export const selectIsPromosFetching = state => state.promos.isFetching;
export const selectIsCustomerProductsFetching = state => state.isCustomerProductsFetching;
export const selectIsMetadataFetching = state => state.metadata.isFetching;
export const selectCampaigns = state => state.campaigns.items || false;
export const selectEobDetails = state => state.eobDetails.items || [];

export const selectPromoById = (state, id) => {
	const promos = selectPromos(state);
	return promos[id];
};
export const selectEobDetailsById = (state, id) => {
	const eobDetails = selectEobDetails(state);
	return eobDetails[id];
};

export const selectEobDetailsByIdAndOrderdDate = (state, id, orderDate, amount) => {
	const eobDetails = selectEobDetails(state);
	return eobDetails[id]?.find((obj) => obj.amount === amount && obj.orderDate === orderDate);
};

export const selectProductsLastUpdated = state => state.lastUpdated;
export const selectError = state => state.error;
export const selectIsCampaignsFetching = state => state.campaigns.isFetching;
export const selectProductPromos = state => state.productPromos;
export const selectProductPromosById = (state, id) => {
	const promos = selectProductPromos(state);
	if (!promos) return null;
	if (!promos[id]) return false;
	return promos[id];
};
export const selectProductPromosTargets = state => state.productPromosTargets;
export const selectProductPromosTargetsById = (state, id) => {
	const promos = selectProductPromosTargets(state);
	if (!promos) return null;
	if (!promos[id]) return false;
	return promos[id];
};
export const selectIsProductHistoriesFetching = state => state.history.isFetching;
export const selectProductHistories = state => state.history.items ?? {};
export const selectProductHistoryById = (state, id) => selectProductHistories(state)[id];
