
import React from 'react';
import { t } from '../../../libraries/i18n';
import { useSelector } from 'react-redux';
import { selectEobDetailsByIdAndOrderdDate } from '../../selectors';

interface Props {
	orderDate: string;
	amount: number;
	productId: string;
}

export const DetailOrderdProducts = ({
	orderDate, amount, productId
}: Props) => {
	const eobDetail = useSelector(state => selectEobDetailsByIdAndOrderdDate(state, productId, orderDate, amount));
	if (!eobDetail) return <div>{t('order.OrderdOn', orderDate)}</div>;
	return (
		<div>{t('order.OrderdOnAndDeliverOn', orderDate, eobDetail?.deliveryDate)}</div>
	);
};

