import React, { useEffect, useState } from 'react';
import usePermissions from './../../../api/settings/usePermissions';
import { t } from '../../../libraries/i18n';
import CRUDHandler from './CRUD/CRUDHandler';
import { CRUDConfig, CRUDFieldTypes } from './CRUD/crud';
import useCustomer from '../../../libraries/hooks/useCustomer';
import useSWR from 'swr';
import { fetcher } from '../../../api';
import { DynamicForm, Button } from '@abm-international/react-components';
import api from '../../../libraries/api';

function getPermissionsTranslation(translationCode: string) {
	const code = translationCode.replace('permissions.', '');
	const newCode = code.split('.').join('_');
	return t(`permissions.${newCode}`);
}



const useData = (shouldFetch: boolean) => {
	const { id } = useCustomer();
	const { data } = useSWR(shouldFetch ? `/api/v1/customers/${id}/settings/permissions/` : null, fetcher);

	return {
		data
	};
};

export default function Permissions({ currentUserData }: any) {

	const { data } = useData(currentUserData?.isMaster);
	const [allCustomerNumbers, setAllCustomerNumbers] = useState([]);
	const [customersNamesWithNumbers, setCustomersNamesWithNumbers]: any = useState([]);


	useEffect(() => {
		setCustomersNamesWithNumbers(data?.customersNamesWithNumbers || []);
		setAllCustomerNumbers(data?.allCustomerNumbers || []);
	}, [data]);

	const { id } = useCustomer();

	const customerBoxes = customersNamesWithNumbers.map((data: any, index: number) => ({
		name: customersNamesWithNumbers[index]?.klt_rek,
		title: `${customersNamesWithNumbers[index]?.klt_rek}: ${customersNamesWithNumbers[index]?.klt_nam}`,
		type: 'checkbox',
	}));

	const getConfig = (id: any): CRUDConfig => {
		return {
			fields: [
				{
					name: 'id',
					title: 'ID',
					type: CRUDFieldTypes.Text,
					disabled: true
				},
				{
					name: 'account',
					title: t('settings.description'),
					type: CRUDFieldTypes.Text,
				},
				{
					name: 'lastLoggedIn',
					title: t('settings.last_logged_in'),
					type: CRUDFieldTypes.Text,
				},
				{
					name: 'description',
					title: t('settings.description'),
					type: CRUDFieldTypes.Text,
				},
				{
					name: 'name',
					title: t('settings.user_name'),
					type: CRUDFieldTypes.Text,
				},
				{
					name: 'username',
					title: t('settings.email'),
					type: CRUDFieldTypes.Text,
				},
				{
					name: 'password',
					title: t('settings.password'),
					type: CRUDFieldTypes.Text,
				},
				{
					name: 'loginEnabled',
					title: t('settings.login_enabled'),
					type: 'checkbox',
				},
				...customerBoxes,
				{
					name: 'permissions',
					title: t('permissions'),
					type: 'permissions',
					options: {
						t: getPermissionsTranslation,
						usePermissions: () => usePermissions(`/api/v1/customers/${id}/settings/permissions/metadata`),
						noLabel: true
					},
				},
			],
			list: {
				fetch: `/api/v1/customers/${id}/settings/permissions`,
				columns: ['username', 'name', 'account', 'lastLoggedIn']
			},
			detail: {
				location: `/api/v1/customers/${id}/settings/permissions/{id}`,
				fields: [
					'id',
					'username',
					'name',
					'password',
					// 'language',
					'loginEnabled',
					'permissions',
					'description',
					...allCustomerNumbers
				],
				sections: [
					{
						title: 'Algemeen',
						fields: [
							// 'id',
							'username',
							'name',
							'password',
							'description',
							// 'language',
							'loginEnabled',

						]
					},
					{
						title: 'Toegestane klant-nummers',
						fields: allCustomerNumbers,
					},
					{
						title: 'Rechten',
						fields: ['permissions']
					}
				],
				defaultNewValue: {
					permissions: []
				}
			},
			path: '/settings/permissions'
		};

	};

	//for sublogin
	const [newData, setNewData] = useState<any>();
	const [subloginErrors, setSubloginErrors] = useState<{}>({});
	const [sublogSaveButton, setSublogSaveButton] = useState<boolean>(false);
	const [succesSave, setSuccesSave] = useState<boolean>(false);

	const getFields = () => {
		const fields = [
			{
				name: 'settings_old_password_sublogin',
				title: t('settings.old_password_sublogin'),
				type: 'text'
			},
			{
				name: 'settings_new_password_sublogin',
				title: t('settings.new_password_sublogin'),
				type: 'text'
			},
			{
				name: 'settings_new_password_sublogin_confirm',
				title: t('settings.new_password_sublogin_confirm'),
				type: 'text'
			}
		];
		return fields;
	};

	const save = async () => {

		setSublogSaveButton(true);
		setSuccesSave(false);
		const resSave = await api.post(`/api/v1/customers/${id}/settings/changePassword/`, JSON.stringify(
			{
				oldPassword: newData?.settings_old_password_sublogin,
				newPassword: newData?.settings_new_password_sublogin,
				newPasswordConfirm: newData?.settings_new_password_sublogin_confirm,
			}
		));
		if (resSave.result) {
			setNewData({});
			setSuccesSave(true);
		}
		setSubloginErrors(resSave?.errors);
		setSublogSaveButton(false);

	};

	return currentUserData.isMaster ? <CRUDHandler {...getConfig(id)} /> :
		<div className='ContactAccounting card' id='accounting'>
			<h2>{t('settings.title_password_change_sublogin')}</h2>
			<DynamicForm
				fields={getFields()}
				values={newData ?? {}}
				onChange={(d: any) => {
					setNewData(d);
				}}
			/>
			<div className='SaveAndChanges'>
				<Button primary disabled={sublogSaveButton} onClick={save}>{t('settings.save')}</Button>
				{succesSave ? <div className='green'>
					<p >{t('settings.saved_succes')}</p>
				</div> : <>
					{Object.values(subloginErrors)?.map((value: any, index) => {
						return (
							<p key={index}>{t(value)}</p>
						);
					})}</>}

			</div>
		</div>;
}
