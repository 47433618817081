import { useState } from 'react';

const baseKey = 'preferences';
export default function usePreference(key, defaultValue) {
	const fullKey = `${baseKey}.${key}`;
	const initialValue = JSON.parse(localStorage.getItem(fullKey)) ?? defaultValue;
	const [value, setValue] = useState(initialValue);

	const set = newValue => {
		try {
			localStorage.setItem(fullKey, JSON.stringify(newValue));
		} catch (error) {}

		setValue(newValue);
	};

	return [value, set];
}
