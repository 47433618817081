import useSWR from 'swr';
import { fetcher } from '../index';

export default function useCustomers() {
	const { data, error } = useSWR('/api/customers/', fetcher);

	const customers = data?.map(customer => ({ ...customer, id: customer.code }));
	return {
		customers,
		isLoading: !customers && !error,
		error: error
	};
}
