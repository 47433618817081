import React from 'react';
import './navigation.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
	FaAngleDoubleLeft,
	FaAngleDoubleRight,
	FaUserCircle,
	FaCog,
	FaUsers,
	FaExchangeAlt,
	FaSignOutAlt,
	FaPhone,
	FaEnvelope,
	FaCommentAlt,
	FaInfoCircle,
	FaChevronLeft,
	FaChevronRight,
	FaChevronUp,
	FaChevronDown,
	FaTh,
	FaThLarge,
	FaThList,
	FaCheck,
	FaClock,
	FaPlus,
	FaMinus,
	FaTimes,
	FaShoppingCart,
	FaShoppingBasket,
	FaClipboard,
	FaUtensils,
	FaTruck,
	FaColumns,
	FaBullseye,
	FaGift,
	FaCoins,
	FaReceipt,
	FaStar,
	FaChartBar,
	FaCashRegister,
	FaBullhorn,
	FaTasks,
	FaTag,
	FaVial,
	FaUserShield,
	FaHistory,
	FaUser,
	FaEye,
	FaLanguage
} from 'react-icons/fa';
import CharlesLogo from './../../images/CharlesLogoWhite.png';
import { t } from './../../libraries/i18n';
import { useIsLoginAPIAvailable } from './../../api';
import { USE_NEW_SETTINGS } from '../Settings/Settings';

function Navigation(props) {
	const location = useLocation();
	const isNewLogout = useIsLoginAPIAvailable();

	const isActive = (path, match, location) => {
		if (path === '/order' && location.pathname.startsWith('/orders')) return false;
		return !!(match || location.pathname.startsWith(path));
	};

	const closeNav = () => {
		const isMobile = window.innerWidth <= 650;
		if (isMobile) props.toggleCollapse(true);
	};

	const renderHeader = () => {
		return (
			<div className={'nav__brand'}>
				<div className={'spacer'} />

				{/*<a className={'brand__image'} href={process.env.REACT_APP_PORTAL_URL}>*/}
				<span className={'brand__image'}>
					<img src={CharlesLogo} alt="Charles logo" />
				</span>
				{/*</a>*/}

				<span className={'nav__icon nav__collapse'} onClick={props.toggleCollapse}>
					{props.collapsed ? (
						<FaAngleDoubleRight />
					) : (
						<FaAngleDoubleLeft />
					)}
				</span>

				<div className='hamburger-menu' onClick={props.toggleCollapse}>
					<span className='line--1' />
					<span className='line--2' />
					<span className='line--3' />
				</div>
			</div>
		);
	};

	const renderUser = () => {
		const renderUserSwitcher = (children) => {
			if (location.pathname === '/customers'){
				return (
					<div className={'user__item user__customer'}>
						{children}
					</div>
				);
			}
			return (
				<NavLink className={'user__item user__customer'} to={`/customers?from=${location.pathname}`}>
					{children}
				</NavLink>
			);
		};
		return (
			<div className={'nav__user'}>
				{!USE_NEW_SETTINGS &&(
					<a className={'user__item user__login'} href={`${process.env.REACT_APP_PORTAL_URL}/#klant/gegevens`}>
						<div className={'item__description'}>
							<div className={'decsription__icon'}>
								<FaUserCircle />
							</div>
							{props.username}
						</div>
						<div className={'nav__icon'}>
							<FaCog />
						</div>
					</a>
				)}
				{USE_NEW_SETTINGS &&(
					<NavLink className={'user__item user__customer'} to={'/settings/contact'}>
						<div className={'item__description'}>
							<div className={'decsription__icon'}>
								<FaUserCircle />
							</div>
							{props.username}
						</div>
						<div className={'nav__icon'}>
							<FaCog />
						</div>
					</NavLink>
				)}

				{props.multipleUsers ? renderUserSwitcher(
					<>
						<div className={'item__description'}>
							<div className={'decsription__icon'}>
								<FaUsers />
							</div>
							{props.customer.code} {props.customer.name}
						</div>
						<div className={'nav__icon'}>
							<FaExchangeAlt />
						</div>
					</>
				) : null}

				{!isNewLogout && (
					<a className={'user__item user__logout'} href={`${process.env.REACT_APP_PORTAL_URL}/index.php/login/logout`}>
						<div className={'item__description'}>
							{t('nav.logout')}
						</div>
						<div className={'nav__icon'}>
							<FaSignOutAlt />
						</div>
					</a>
				)}

				{isNewLogout && (
					<Link className={'user__item user__logout'} to={'/auth/logout'}>
						<div className={'item__description'}>
							{t('nav.logout')}
						</div>
						<div className={'nav__icon'}>
							<FaSignOutAlt />
						</div>
					</Link>
				)}


			</div>
		);
	};
	const icons = {
		'chevron-left': FaChevronLeft,
		'chevron-right': FaChevronRight,
		'chevron-up': FaChevronUp,
		'chevron-down': FaChevronDown,
		'th': FaTh,
		'th-large': FaThLarge,
		'th-list': FaThList,
		'check': FaCheck,
		'clock': FaClock,
		'plus': FaPlus,
		'minus': FaMinus,
		'times': FaTimes,
		'shopping-cart': FaShoppingCart,
		'shopping-basket': FaShoppingBasket,
		'clipboard': FaClipboard,
		'utensils': FaUtensils,
		'truck': FaTruck,
		'columns': FaColumns,
		'bullseye': FaBullseye,
		'gift': FaGift,
		'coins': FaCoins,
		'receipt': FaReceipt,
		'star': FaStar,
		'chart-bar': FaChartBar,
		'cash-register': FaCashRegister,
		'bullhorn': FaBullhorn,
		'tasks': FaTasks,
		'tag': FaTag,
		'vial': FaVial,
		'user-shield': FaUserShield,
		'history': FaHistory,
		'user': FaUser,
		'eye': FaEye,
		'language': FaLanguage
	};

	const renderNavItemIcon = icon => {
		if (icon.split(' ')[0] === 'fas') {
			const Icon = icons[icon.split('fa-')[1]] ?? (() => null);
			return <Icon />;
		} else if (icon.split(' ')[0] === 'custom') {
			const name = icon.split('custom-')[1];
			switch (name) {
				/*
				case 'thisCustomName':
					return (
						<img src={CustomImportedImageSource} alt={'Custom and descriptive name'} />
					);
				 */
				default:
					return null;
			}
		} else {
			return null;
		}
	};

	const renderNavItem = item => {
		if (item.href.startsWith('http')) {
			return (
				<li className={'nav__item'} key={item.title}>
					<a href={item.href} className={'nav__link'}>
						<div className={'item__description'}>
							{t(`nav.${item.title}`)}
						</div>
						<div className={'nav__icon'}>
							{renderNavItemIcon(item.icon)}
						</div>
					</a>
				</li>
			);
		}

		return (
			<li className={'nav__item'} key={item.title}>
				<NavLink
					to={item.href}
					className={'nav__link'}
					activeClassName={'nav__link--active'}
					isActive={(match, location) => isActive(item.href, match, location)}
					onClick={closeNav}
				>
					<div className={'item__description'}>
						{t(`nav.${item.title}`)}
					</div>
					<div className={'nav__icon'}>
						{renderNavItemIcon(item.icon)}
					</div>
				</NavLink>
			</li>
		);
	};

	const renderNavItems = navItems => {
		if (!navItems) return null;

		return (
			<ul className={'nav__items'}>
				{navItems.map(renderNavItem)}
			</ul>
		);
	};

	const renderContact = () => {
		return (
			<div className={'nav__contact'}>
				{props.company && (
					<div className={'contact__address'}>
						<div>{props.company.name}</div>
						<div>{props.company['address.street']}</div>
						<div>{props.company['address.postalcode']} {props.company['address.city']}</div>
					</div>
				)}

				<div className={'contact__details'}>
					{props.company && (
						<>
							<div className={'detail__telephone'}>
								<div className={'contact__icon'}>
									<FaPhone />
								</div>
								{props.company['sales.phone']}
							</div>

							<a className={'detail__email'} href={`mailto:${props.company['sales.email']}`}>
								<div className={'contact__icon'}>
									<FaEnvelope />
								</div>
								{props.company['sales.email']}
							</a>
						</>
					)}

					<div className={'detail__version'}>
						<div className={'contact__icon'}>
							<FaInfoCircle />
						</div>
						Portal v{process.env.REACT_APP_APP_VERSION}
					</div>

					<a className={'detail__message'} href={`${process.env.REACT_APP_PORTAL_URL}#klant/contact`}>
						<div className={'contact__icon'}>
							<FaCommentAlt />
						</div>
						{t('nav.contact')}
					</a>
				</div>
			</div>
		);
	};

	return (
		<div className={`OGNavigation ${props.collapsed ? 'OGNavigation--collapsed' : ''}`}>
			<div className={'nav__top'}>
				{renderHeader()}

				<div className={'nav__breaker'} />

				{renderUser()}

				{renderNavItems(props.navItems)}
			</div>

			<div className={'nav__bottom'}>
				{renderContact()}

				<div className={'nav__terms'}>
					<NavLink
						to='/disclaimer'
						className={'terms__link'}
						activeClassName={'terms__link--active'}
						isActive={(match, location) => isActive('/buylist', match, location)}
						onClick={closeNav}
					>
						{t('nav.terms')}
					</NavLink>
				</div>
			</div>
		</div>
	);
}

export default Navigation;
