import React, { useEffect, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateLastVisitedOrderStep } from './../orderActions';

function OrderRoute(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const { path } = props;
	const { component: Component, ...restProps } = props;

	const rest = useMemo(() => {
		return restProps;
	}, [restProps]);

	useEffect(() => {
		dispatch(updateLastVisitedOrderStep(path));
	}, [dispatch, path, location]);

	return (
		<Route {...rest} >
			<Component />
		</Route>
	);
}

export default OrderRoute;
