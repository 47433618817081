import { Booth } from '~/types/tradeFair';
import { Link, useRouteMatch } from 'react-router-dom';
import { t } from './../../libraries/i18n';
import { Badge } from '@abm-international/react-components';
import { FaTruck } from 'react-icons/fa';

type Props = {
    booth: Booth & { totals: number[], pending: number[] }
};

export default function TradeFairOverviewBooth({ booth }: Props) {
    const match = useRouteMatch();

    const hasItemsInOrder = booth.totals[0] !== 0 || booth.totals[1] !== 0;

    const hasPending = booth.pending[0] !== 0;

    return (
        <div className='TradeFairOverviewBooth'>
            <Link key={booth.boothId} to={`${match.path}/${booth.boothId}`}>
                <div className='stand'>stand {booth.boothId}</div>
                <h3>
                    {booth.name}
                </h3>

                <div className='bottom'>
                    <div className='totals'>
                        {hasItemsInOrder && (
                            <>
                                <span>
                                    {booth.totals[0]}{' '}
                                    {booth.totals[2] !== 0 && <span className='free'>+ {booth.totals[2]}</span>}
                                    {t('tradefair.unitsLabel')}
                                </span>
                                <span>{t('tradefair.articlesLabel', booth.totals[1])}</span>
                            </>
                        )}
                    </div>

                    {hasPending && (
                        <Badge
                            secondary={`${booth.pending[0]}${booth.pending[1] !== 0 ? `+${booth.pending[1]}` : ''}`}
                            className={'pending'}
                            color={'#373735'}
                        >
                            <FaTruck />
                        </Badge>
                    )}
                </div>

            </Link>
        </div>
    );
}
