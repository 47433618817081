import useSWR from 'swr';
import { fetcher } from '../index';
import { Permission } from '../../types/settings/accounts';

const getPermissionTypes = (permissions: Array<Permission>, groupName: string) => {
	const groupPermissions = permissions.filter(p => p.group === groupName);

	const typeNames = groupPermissions.reduce((names: Array<string>, p) => {
		if (!names.includes(p.type)) return [...names, p.type];
		return names;
	}, []);

	return typeNames
		.map(typeName => ({
			type: typeName,
			permissions: groupPermissions
				.filter(p => p.type === typeName)
				.sort((a, b) => a.level - b.level)
		}));
};

const getPermissionsGroups = (permissions: Array<Permission>) => {
	const groups = permissions.reduce((groups: Array<string>, permission: Permission) => {
		if (groups.indexOf(permission.group) === -1) return [...groups, permission.group];
		return groups;
	}, []);

	return groups.map(groupName => ({
		group: groupName,
		types: getPermissionTypes(permissions, groupName)
	}));
};

export default function usePermissions(url: string) {
	const { data, error } = useSWR(url, fetcher);

	return {
		permissions: data?.permissions,
		groupedPermissions: data && getPermissionsGroups(data.permissions),
		isLoading: !data && !error,
		error
	};
}
