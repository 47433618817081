import React, { useState } from 'react';
import './customers.scss';
import {
	List,
	TextFilter,
	CharlesTitle,
	AutoSuggestFilter
} from '@abm-international/react-components';
import { t } from '../../libraries/i18n';
import useCustomers from '../../api/customers/useCustomers';
import Api from '../../libraries/api';
import useSearchParams from '../../libraries/hooks/useSearchParams';
import useWindowDimensions from '../../libraries/hooks/useWindowDimensions';


export default function Customers() {
	const { customers, isLoading, error } = useCustomers();
	const { from } = useSearchParams();

	const [page, setPage] = useState(0);
	const { height } = useWindowDimensions();
	const itemsPerPage = Math.floor((height - 330) / 55);

	const listHeader = [
		{
			key: 'code',
			title: t('customers.label_code'),
			render: ({ code }) => code,
			sort: (a, b) => a.code - b.code,
			filter: {
				test: ({ code }, value) => `${code}`.startsWith(value),
				component: TextFilter
			}
		},
		{
			key: 'name',
			title: t('customers.label_name'),
			render: ({ name }) => name,
			filter: {
				test: ({ name }, value) => name.toUpperCase().includes(value.toUpperCase()),
				component: TextFilter
			},
			sort: (a, b) => a.name.localeCompare(b.name),
		},
		{
			key: 'zip',
			title: t('customers.label_zip'),
			render: ({ address: { postalCode } }) => postalCode,
			filter: {
				test: ({ address: { postalCode } }, value) => postalCode.toUpperCase().includes(value.toUpperCase()),
				component: TextFilter
			},
			sort: (a, b) => a.address.postalCode - b.address.postalCode,
		},
		{
			key: 'city',
			title: t('customers.label_city'),
			render: ({ address: { city } }) => city,
			filter: {
				test: ({ address: { city } }, value) => city.toUpperCase().includes(value.toUpperCase()),
				component: ({ value, setValue }) => <AutoSuggestFilter value={value ?? ''} setValue={setValue} uniques={[ ...new Set(customers.map(({ address: { city } }) => city))]} />
			},
			sort: (a, b) => a.address.city.localeCompare(b.address.city),
		},
		{
			key: 'street',
			title: t('customers.label_street'),
			render: ({ address: { street } }) => street,
			filter: {
				test: ({ address: { street } }, value) => street.toUpperCase().includes(value.toUpperCase()),
				component: ({ value, setValue }) => <AutoSuggestFilter value={value ?? ''} setValue={setValue} uniques={[ ...new Set(customers.map(({ address: { street } }) => street))]} />
			},
			sort: (a, b) => a.address.street.localeCompare(b.address.street),
		},
		{
			key: 'open_order',
			title: t('customers.label_open_orders'),
			render: ({ openOrders }) => openOrders,
			sort: (a, b) => b.openOrders - a.openOrders,
		}
	];

	const renderList = () => {
		if (!isLoading && customers.length <= 0) return <div className={'message'}>{t('customers.label_no_customers')}</div>;
		//	const highlighted = ['W'];

		return (
			<List
				header={listHeader}
				content={customers}
				action={async (_, customer) => {
					await Api.setCustomer(customer.id);
					document.location = from ?? '/order'; // TODO: first route
					/*
					 * reloading is a quicker solution than preparing the portal for real customer invalidation
					 * which could happen like this:
					 *
					 * dispatch(invalidateCustomer());
					 * dispatch(fetchCustomerIfNeeded());
					 */
				}}
				pagination={{
					itemsPerPage,
					page,
					setPage
				}}
				gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr'}
				// highlight={[
				// 	{
				// 		className: 'highlight',
				// 		filter: ({ site }) => highlighted.includes(site)
				// 	}
				// ]}
			/>
		);
	};

	return (
		<div className="Customers">
			<CharlesTitle alt>{t('customers.customers_title')}</CharlesTitle>
			{error && (
				<div className={'message'}>
					<span role='img' aria-label='error emoji'>💥</span>
					{t('customers.label_error_loading_overview')}
				</div>
			)}
			{!error && renderList()}
		</div>
	);
}
