import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './../../libraries/hooks/redux';
import { fetchPreOrdersDataIfNeeded } from '../../modules/PreOrders/preOrdersActions';
import { selectIsPreOrdersDataFetching, selectPreOrdersActions } from '../../modules/selectors';
import useCustomer from './../../libraries/hooks/useCustomer';
import { Booth } from '~/types/tradeFair';
import { PreOrderAction } from '~/types/preOrders';

export const BEURS_2022_PREORDER_TYPE = '6';
export const BEURS_2024_PREORDER_TYPE = '6';

const ONLY_SHOW_BOOTHS_WITH_ARTICLES = true;

export default function useBooths(): { isFetching: boolean, booths: Booth[] } {
    const preOrdersActions = useAppSelector(state => selectPreOrdersActions(state, BEURS_2024_PREORDER_TYPE));
    const isFetching = useAppSelector(state => selectIsPreOrdersDataFetching(state, BEURS_2024_PREORDER_TYPE));
    const dispatch = useDispatch();
    const { locale } = useCustomer();

    useEffect(() => {
        dispatch(fetchPreOrdersDataIfNeeded(BEURS_2024_PREORDER_TYPE));
    }, [dispatch]);

    const actionsWithBooth = preOrdersActions.filter(a => typeof a.boothId === 'string') as Array<PreOrderAction & { boothId: string }>;
    const booths = actionsWithBooth
        .reduce((booths, action) => {
            if (ONLY_SHOW_BOOTHS_WITH_ARTICLES && action.articles && action.articles.length === 0) return booths;
            if (booths.find(b => b.boothId === action.boothId)) return booths;
            return [
                ...booths,
                {
                    boothId: action.boothId,
                    name: action.description?.[locale as 'nl' | 'fr'] ?? '',
                    deliveryWeek: action.delivery
                }
            ];
        }, [] as Booth[])
        .sort((a, b) => Number(a.boothId) - Number(b.boothId));

    return {
        isFetching,
        booths
    };
}
