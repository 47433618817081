
import './Settings.scss';
import { ButtonLink, CharlesTitle } from '@abm-international/react-components';
import {
	Route,
	Switch, useLocation,
	useRouteMatch
} from 'react-router-dom';
import { t } from '../../libraries/i18n';
import Vacation from './Vacation/Vacation';
import Contact from './Contact/Contact';
import Communication from './Communication/Communication';
import Permissions from './Permissions/Permissions';
import useCustomer from '../../libraries/hooks/useCustomer';
import useSWR from 'swr';
import { fetcher } from '../../api';
import ForbiddenError from '../../components/Error/ForbiddenError/ForbiddenError';
import { Spinner } from '@abm-international/react-components';

export const USE_NEW_SETTINGS = true;

const useCurrentUserData = () => {
	const { id } = useCustomer();
	const { data: currentUserData, error } = useSWR(`/api/v1/customers/${id}/settings/currentUserData/`, fetcher);

	return { currentUserData, error };
};

export default function Settings() {
	const match = useRouteMatch();
	const location = useLocation();


	const pages = [
		{
			title: 'settings.title_contact',
			url: '/contact',
			Component: Contact
		},
		{
			title: 'settings.title_vacation',
			url: '/vacation',
			Component: Vacation
		},
		{
			title: 'settings.title_communication',
			url: '/communication',
			Component: Communication
		},
		{
			title: 'settings.title_logins_sublogins',
			url: '/permissions',
			Component: Permissions
		},
	];

	const { currentUserData, error } = useCurrentUserData();

	if (error) return <ForbiddenError permissionName={t('settings.viewSettings')} />;
	return currentUserData ? (
		<div className={'Settings'}>
			<div className='container'>
				<CharlesTitle alt>{t('settings.title_settings')}</CharlesTitle>

				<nav className={'tabs'}>
					{!USE_NEW_SETTINGS && (
						<>
							<li className={'tabs__item'}>
								<a className='item__link' href={`${process.env.REACT_APP_PORTAL_URL}/#klant/gegevens/index/contact-tab`}>
									{t('settings.title_contact')}
								</a>
							</li>
							<li className={'tabs__item'}>
								<a className='item__link' href={`${process.env.REACT_APP_PORTAL_URL}/#klant/gegevens/index/comm-tab`}>
									{t('settings.title_communication')}
								</a>
							</li>
							<li className={'tabs__item'}>
								<a className='item__link' href={`${match?.path}${'/vacation'}`}>
									{t('settings.title_vacation')}
								</a>
							</li>
							<li className={'tabs__item'}>
								<a className='item__link' href={`${process.env.REACT_APP_PORTAL_URL}/#klant/gegevens/index/sublogin-tab`}>
									{t('settings.title_logins_sublogins')}
								</a>
							</li>
						</>
					)}
					{USE_NEW_SETTINGS && (
						pages.map((page) => (
							<ButtonLink
								key={page.url}
								to={`${match?.path}${page.url}`}
								textOnly
								className={location.pathname.includes(page.url) ? 'active' : ''}
							>
								{t(page.title)}
							</ButtonLink>
						))
					)}


				</nav>

				<Switch>
					{pages.map((page) => (
						<Route path={`${match?.path}${page.url}`} key={page.url}>
							<page.Component currentUserData={currentUserData} />
						</Route>
					))}
					{pages.map((page) => (
						<Route path={`${match?.path}${page.url}/:id`} key={`${page.url}id`}>
							<page.Component currentUserData={currentUserData} />
						</Route>
					))}
				</Switch>
			</div>
		</div>
	) :
		<Spinner></Spinner>;
}
