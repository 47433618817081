import {
	differenceWith, fromPairs, isEqual, toPairs
} from 'lodash-es';

export const objectDiff = (oldObj: Record<string, unknown>, newObj: Record<string, unknown>) => {
	const diff = differenceWith(toPairs(newObj), toPairs(oldObj), isEqual);
	return fromPairs(diff);
};

export const createOldNewObject = (changedValues: Record<string, unknown>, oldValues: Record<string, unknown>) => {
	return Object.keys(changedValues).reduce((acc, key) => {
		if (!oldValues[key] && !changedValues[key]) return acc;
		return {
			...acc,
			[key]: {
				old: oldValues[key] || '/',
				new: changedValues[key],
			}
		};
	}, {});
};
