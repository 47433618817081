import { ReduxAction } from '../../types/redux';
import { PreOrderAction, PreOrderReduxAction, PreOrdersState } from '../../types/preOrders';
import {
    PREORDERS_DATA_FETCH,
    PREORDERS_DATA_RECEIVE,
    PREORDERS_DATA_REJECT,
    PREORDERS_ACTION_ARTICLE_QUANTITY_UPDATE,
    PREORDERS_ACTION_SUBMIT_START,
    PREORDERS_ACTION_SUBMIT_SUCCESS,
    PREORDERS_ACTION_SUBMIT_REJECT,
    PREORDERS_TYPE_SUBMIT_START,
    PREORDERS_TYPE_SUBMIT_SUCCESS,
    PREORDERS_TYPE_SUBMIT_REJECT,
    PREORDERS_ACTION_SUBMITTED_WARNING_DISMISS,
    PREORDERS_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE
} from './preOrdersActions';

import * as DateHelper from './../../libraries/dateHelper';
import promoTypes from '../products/promoTypes';

const initialState: PreOrdersState = {
    isFetching: false,
    error: undefined,
    showSubmittedWarning: false,
    actions: []
};

const preOrderAction = (state: PreOrderAction, action: ReduxAction) => {

    if ((action?.payload?.group !== state?.group) || (action.payload.delivery && action.payload.delivery !== state.delivery) || (action.payload.orderId && action.payload.orderId !== state.orderId)) return state;


    switch (action.type) {
        case PREORDERS_ACTION_ARTICLE_QUANTITY_UPDATE:
            return {
                ...state,
                quantities: {
                    ...state.quantities,
                    [action.payload.articleId]: action.payload.quantity
                },
                lastChanged: DateHelper.toReduxDateString(new Date())
            };
        case PREORDERS_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE:
            const { quantity, promo } = action.payload;
            return calculateAwardedPromosForPreOrderProduct(state, quantity, promo);

        case PREORDERS_ACTION_SUBMIT_START:
            return {
                ...state,
                isSaving: true,
                didSaveFail: false
            };
        case PREORDERS_ACTION_SUBMIT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                lastSaved: DateHelper.toReduxDateString(action.payload.lastSaved),
                finalSaved: action.payload.final,
                saveError: false,
                exponentialBackoff: 0
            };
        case PREORDERS_ACTION_SUBMIT_REJECT:
            return {
                ...state,
                isSaving: false,
                didSaveFail: true,
                saveError: action.payload.error,
                exponentialBackoff: (state.exponentialBackoff ?? 0) + 1,
                lastAttempt: DateHelper.toReduxDateString(action.payload.lastAttempt)
            };
        default:
            return state;
    }
};

export default function preOrders(state: Record<string, PreOrdersState> = {}, action: PreOrderReduxAction) {
    if (!action.preOrderType) return state;

    return {
        ...state,
        [action.preOrderType]: preOrder(state[action.preOrderType] ?? initialState, action)
    };
}

const preOrder = (state: PreOrdersState, action: ReduxAction) => {
    switch (action.type) {
        case PREORDERS_DATA_FETCH:
            return {
                ...state,
                isFetching: true
            };
        case PREORDERS_DATA_REJECT:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        case PREORDERS_DATA_RECEIVE:
            const payload = action.payload.map((action: PreOrderAction) => {
                action.awardedPromos = {};

                const newState = action.articles?.reduce((acc, article) => {
                    return calculateAwardedPromosForPreOrderProduct(acc as PreOrderAction, action.quantities[article.id] || 0, article.promo, true);
                }, action);

                return newState;
            });

            return {
                ...state,
                isFetching: false,
                error: undefined,
                actions: payload
            };

        case PREORDERS_ACTION_SUBMIT_SUCCESS:
            return {
                ...state,
                showSubmittedWarning: action.payload.final && action.payload.group,
                actions: state.actions.map(a => preOrderAction(a, action)),
            };
        case PREORDERS_ACTION_SUBMITTED_WARNING_DISMISS:
            return {
                ...state,
                showSubmittedWarning: false
            };
        case PREORDERS_TYPE_SUBMIT_START:
            return {
                ...state,
                didFail: false,
                isSubmittingAll: true
            };
        case PREORDERS_TYPE_SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmittingAll: false,
                hasSubmittedAll: true,
                lastSaved: DateHelper.toReduxDateString(action.payload.lastSaved)
            };
        case PREORDERS_TYPE_SUBMIT_REJECT:
            return {
                ...state,
                didFail: true,
                isSubmittingAll: false,
            };
        case PREORDERS_ACTION_ARTICLE_QUANTITY_UPDATE:
        case PREORDERS_ACTION_SUBMIT_START:
        case PREORDERS_ACTION_SUBMIT_REJECT:
        case PREORDERS_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE:
            return {
                ...state,
                actions: state.actions.map(a => preOrderAction(a, action)),
            };
        default:
            return state;
    }
};

// TODO: Fix typing and interfaces
export function removeAwardedPromo(state: any, promo: any) {
    if (!promo.target || !promo.trigger) {
        return state;
    }

    if (promo.trigger?.barrel) {
        promo.trigger.barrel.products.forEach((id: string) => {
            delete state.awardedPromos[id];
        });
    }

    const targetId = promo.target?.id || promo.trigger?.id;
    delete state.awardedPromos[targetId];
    delete state.awardedPromos[promo.trigger.id];
    return state;
}

export function awardOtherPromo(state: any, promo: any) {
    if (!promo.target || !promo.trigger) {
        return state;
    }

    if (promo.trigger?.barrel) {
        promo.trigger.barrel.products.forEach((id: string) => {
            state.awardedPromos[id] = {
                id: promo.id
            };
        });
    }

    const targetId = promo.target?.id || promo.trigger?.id;

    return {
        ...state,
        awardedPromos: {
            ...state.awardedPromos,
            [promo.trigger.id]: {
                id: promo.id
            },
            [targetId]: {
                id: promo.id,
            }
        }
    };
}

export function awardFreeByUnitPromo(state: any, promo: any, quantity: number) {
    if (!promo.target || !promo.trigger) {
        return state;
    }

    if (promo.trigger.barrel) {
        promo.trigger.barrel.products.forEach((id: string) => {
            if (!state.awardedPromos) {
                state.awardedPromos = {};
            }

            state.awardedPromos[id] = {
                id: promo.id
            };
        });
    }

    const targetId = promo.target?.id || promo.trigger?.id;

    const newState = {
        ...state,
        awardedPromos: {
            ...state.awardedPromos,
            [promo.trigger.id]: {
                id: promo.id
            },
            [targetId]: {
                id: promo.id,
                quantity: Math.floor(quantity / promo.trigger.num) * parseInt(promo.target.num)
            }
        }
    };
    return newState;
}


export const calculateAwardedPromosForPreOrderProduct = (state: PreOrderAction, quantity: number, promo: any, onInit?: boolean) => {
    if (!promo?.trigger) {
        return state;
    }

    let newState = { ...state };
    let totalQuantity = quantity; // Add colli products if applicable
    let colliNumOfUniqueProducts = 0;
    if (promo.trigger.barrel) {
        totalQuantity = 0;
        promo.trigger.barrel.products.forEach((id: string) => {
            const productQuantity = state.quantities[id];
            if (productQuantity > 0) {
                colliNumOfUniqueProducts++;
                totalQuantity += productQuantity;
            }
        });
    }

    if (promo.trigger.barrel && promo.trigger.barrel.num > colliNumOfUniqueProducts) {
        newState = removeAwardedPromo(newState, promo);
        return newState;
    }

    if (totalQuantity >= promo.trigger.num) {
        if (promo.type === promoTypes.FREE_BY_UNIT) {
            newState = awardFreeByUnitPromo(newState, promo, totalQuantity);
        } else {
            newState = awardOtherPromo(newState, promo);
        }
    } else {
        if (!onInit) {
            newState = removeAwardedPromo(newState, promo);
        }
    }

    return newState;
};
