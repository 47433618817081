import { rest } from 'msw';

export const getCampaigns = [
	rest.get('http://localhost:4000/api/products/campaigns', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json(

				{
					'id': '01f6e99a-fa19-4cf9-8d11-cf3f30bb65d0',
					'badge': {
						'color': {
							'text': '#000000'
						}
					},
					'filter': {
						'nl': 'TAVOLA 2022 mocking',
						'fr': 'TAVOLA 2022 mocking'
					},
					'articles': [
						108,
						206,
						264,
						265,
						271,
						8596,
						30714
					],
					'start': '2022-03-13T23:00:00.000Z',
					'end': '2022-03-26T23:00:00.000Z',
					'active': true,
					'weight': 0,
					'version': '2.1.0',
					'version2': {
						'id': '01f6e99a-fa19-4cf9-8d11-cf3f30bb65d0',
						'name': {
							'nl': 'Tavola Silver Coin 4 mocking',
							'fr': 'Tavola Silver Coin 4 mocking'
						},
						'start': '2022-03-13T23:00:00.000Z',
						'end': '2022-03-26T23:00:00.000Z',
						'version': '2.1.0',
						'active': true,
						'articles': {
							'filter': {
								'text': {
									'nl': 'TAVOLA 2022 mocking',
									'fr': 'TAVOLA 2022 mocking'
								}
							},
							'badge': {
								'color': {
									'text': '#000000'
								},
								'image': {
									'multilingual': true,
									'nl': 'https://portal.charles.eu/resources/campaigns/01f6e99a-fa19-4cf9-8d11-cf3f30bb65d0/ywhkcfnlgotdmjuqarxs19792.Coin_silver_4.jpg'
								}
							},
							'ids': [
								108,
								206,
								264,
								265,
								271,
								8596,
								30714
							]
						}
					}
				},
			),
		);
	}),
];
